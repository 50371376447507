import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Observer, observer } from 'mobx-react';
import { t } from 'i18next';
import ConfigProvider from 'antd/lib/config-provider';
import Icon from 'src/components/Icon';
import IconButton from '@mui/material/IconButton';
import Input from 'antd/es/input';
import iconSearch from 'src/assets/iconSearch.svg';

function SearchInput(props) {
  const {
    className,
    onChange,
    onSearch,
    value,
    onClear,
    placeholder
  } = props;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#414EE1',
          colorPrimaryHover: '#414EE1',
          colorPrimaryTextHover: '#333'
        }
      }}
    >
      <Input.Search
        placeholder={placeholder}
        className={clsx('searchInput', className)}
        onChange={onChange}
        onSearch={onSearch}
        value={value}
        size="large"
        prefix={(
          <Icon
            size={24}
            svg
            style={{ marginRight: 5 }}
          >
            {iconSearch}
          </Icon>
        )}
        suffix={!!value && (
          <IconButton
            aria-label="clear search input"
            onClick={onClear}
            sx={{
              width: 12,
              height: 12,
              fontSize: 12
            }}
          >
            x
          </IconButton>
        )}
      />
    </ConfigProvider>
  );
}

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

SearchInput.defaultProps = {
  className: '',
  onChange: () => {},
  onSearch: () => {},
  onClear: () => {},
  value: null,
  placeholder: t('search')
};

export default observer(SearchInput);
