export const AUTH_TOKEN_KEY = 'token';
export const TABLE_LIMIT = 12;
export const TABLE_LIMIT_MOBILE = 9;

export const USER_TYPES = {
  Personal: 'personal',
  Organization: 'organization'
};

export const RESOURCE_TYPES = {
  Service: 'service',
  Funds: 'funds',
  Space: 'space'
};

export const RESOURCE_MODE = {
  Edit: 'edit',
  Add: 'add'
};

export const RESOURCE_STEPS = {
  Intro: 'intro',
  Detail: 'detail',
  Extra: 'extra',
  Success: 'success'
};

export const FILTERS_TYPES = {
  SDGs: 'sdgs',
  Regions: 'regions',
  ServiceTypes: 'serviceTypes',
  Seniority: 'seniority',
  Jobs: 'jobs',
  FundsTypes: 'fundsTypes',
  Usages: 'usages'
};

export const NOTIFICATION_TYPES = {
  Event: 'event',
  System: 'system'
};

export const BLOCK_REASONS = {
  Login: 'login',
  LoginOnHomePage: 'loginOnHomePage',
  EmailValidate: 'emailValidate',
  PhoneValidate: 'phoneValidate',
  Unreviewed: 'unreviewed',
  ReviewPending: 'reviewPending',
  ReviewFailed: 'reviewFailed',
  ReviewRejected: 'reviewRejected',
  EventCreator: 'eventCreator'
};

export const VERIFY_STATUS = {
  Verified: 'verified',
  Pending: 'pending'
};

export const REGISTER_STEPS = {
  Identity: 'identity',
  Account: 'account',
  Sdgs: 'sdgs',
  Cities: 'cities',
  // personal only
  Motto: 'motto',
  // organization only
  Info: 'info',
  Instruction: 'instruction',
  Contact: 'contact',
  Influence: 'influence',
  // validation
  ValidateEmail: 'validateEmail',
  ValidateEnterPhoneNumber: 'validateEnterPhoneNumber',
  ValidatePhone: 'validatePhone',
  ValidateSuccess: 'validateSuccess',
  ModifyEmail: 'modifyEmail',

  AllSuccess: 'allSuccess'
};

export const SOCIALS_TYPE = {
  Influence: 'influence',
  Website: 'website',
  Linkedin: 'linkedin',
  Facebook: 'facebook',
  Instagram: 'instagram',
  Youtube: 'youtube',
  Line: 'line'
};

export const ORG_REVIEW_TYPES = {
  // 待審核
  Pending: 'pending',
  // 通過
  Accepted: 'accepted',
  // 失敗 -> 補件
  Failed: 'failed',
  // 不通過 (不能補件)
  Rejected: 'rejected'
};

export const TERMS_AND_POLICY_TABS = {
  Terms: 'terms',
  Policy: 'policy'
};

export const ORG_TYPES_DONT_NEED_PROPS = {
  Staff: ['6', '7'], // -> organization type 6 & 7 don't need the "staff" property
  CapitalAmount: ['5', '6', '7']
};

export const LIST_MODEL_TYPE = {
  Event: 'event',
  EventJoined: 'eventJoined',
  Resource: 'resource',
  Application: 'application',
  Purchase: 'purchase',
  Order: 'order',
  Notification: 'notification',
  Belong: 'belong'
};

export const CREATE_EVENT_STEPS = {
  Basic: 'basic', // 行動設定
  KeyFinding: 'keyFinding', // 議題重點
  Target: 'target', // 目標對象
  Vision: 'vision', // 行動願景
  KeyItem: 'keyItem', // 行動項目
  Features: 'features', // 行動特色
  Demand: 'demand', // 資源需求
  Faqs: 'faqs', // 常見問答
  Registration: 'registration', // 報名表單
  Submit: 'submit' // 發布準備
};

export const CREATE_EVENT_STEPS_PROPORTION = {
  [CREATE_EVENT_STEPS.Basic]: 5,
  [CREATE_EVENT_STEPS.KeyFinding]: 10,
  [CREATE_EVENT_STEPS.Target]: 10,
  [CREATE_EVENT_STEPS.Vision]: 15,
  [CREATE_EVENT_STEPS.KeyItem]: 15,
  [CREATE_EVENT_STEPS.Features]: 15,
  [CREATE_EVENT_STEPS.Demand]: 15,
  [CREATE_EVENT_STEPS.Faqs]: 5,
  [CREATE_EVENT_STEPS.Registration]: 5,
  [CREATE_EVENT_STEPS.Submit]: 5
};

export const CREATE_EVENT_PROGRESS = {
  0: 0,
  1: 5,
  2: 25,
  3: 55,
  4: 85,
  5: 100
};

export const CREATE_STEP_STATUS = {
  Active: 'active',
  Done: 'done',
  Pending: 'pending',
  Disable: 'disable'
};

export const EVENT_FIELD = {
  // 行動設定
  CustomId: 'customId',
  Name: 'name',
  Slogan: 'slogan',
  Contact: 'contact',
  // 議題重點
  Sdgs: 'sdgs',
  KeyFinding: 'keyFinding',
  // 目標對象
  Targets: 'targets',
  // 行動願景
  Vision: 'vision',
  // 行動項目
  KeyItem: 'keyItem',
  // 行動特色
  Features: 'features',
  // 資源需求
  Demand: 'demand',
  // 常見問答
  Faqs: 'faqs',
  // 報名表單
  RegistrationRequires: 'registrationRequires',
  RegistrationFields: 'registrationFields',
  // 發布準備
  Banner: 'banner',
  Keywords: 'keywords',
  RelatedUsers: 'relatedUsers',
  CoInitiators: 'coInitiators', // 共同主辦
  Collaborators: 'collaborators', // 合作單位
  CoOrganiser: 'coOrganiser', // 協辦單位
  Privacy: 'privacy',
  Regions: 'regions',
  StartAt: 'startAt', // 招募開始時間
  EndAt: 'endAt', // 招募結束時間
  ReleaseAt: 'releaseAt' // 上架時間
};

export const EVENT_ELEMENT_ID = {
  [CREATE_EVENT_STEPS.Basic]: 'event-basic',
  [CREATE_EVENT_STEPS.KeyFinding]: 'event-keyFinding',
  [CREATE_EVENT_STEPS.Target]: 'event-target',
  [CREATE_EVENT_STEPS.Vision]: 'event-vision',
  [CREATE_EVENT_STEPS.KeyItem]: 'event-keyItem',
  [CREATE_EVENT_STEPS.Features]: 'event-features',
  [CREATE_EVENT_STEPS.Demand]: 'event-demand',
  [CREATE_EVENT_STEPS.Faqs]: 'event-faq',
  [CREATE_EVENT_STEPS.Registration]: 'event-registration',
  [CREATE_EVENT_STEPS.Submit]: 'event-submit'
};

export const SIGNUP_FORM_FIELDS = {
  socials: 'socials',
  birthday: 'birthday',
  gender: 'gender',
  jobs: 'jobs',
  company: 'company',
  jobTitle: 'jobTitle',
  cities: 'cities',
  cityOfResidence: 'cityOfResidence',
  cityOfBirth: 'cityOfBirth',

  foundedIn: 'foundedIn',
  job: 'job',
  type: 'type',
  location: 'location'
};

export const KEYWORD_TYPE = {
  Sdg: 's',
  Target: 't',
  KeyItem: 'k',
  Custom: 'c'
};

export const EVENT_RELEASE_METHOD = {
  Auto: 'auto',
  Manual: 'manual',
  Scheduled: 'scheduled'
};

export const EVENT_STATUS = {
  /**
   * 尚未送審
   */
  Draft: 'draft',
  /**
   * 被駁回 退件不可再送件
   */
  Rejected: 'rejected',
  /**
   * 送審
   */
  Submitted: 'submitted',
  /**
   * 上架
   */
  Active: 'active',
  /**
   * 下架
   */
  Inactive: 'inactive',

  /**
   * 待補件
   */
  Failed: 'failed'
};

export const EVENT_REVIEW_STATUS = {
  /**
   * 送審通過
   */
  Accepted: 'accepted',
  /**
   * 待審核
   */
  Submitted: 'submitted',
  /**
   * 待補件
   */
  Failed: 'failed',
  /**
   * 被駁回 退件不可再送件
   */
  Rejected: 'rejected'
};

export const EVENT_TYPE = {
  Draft: 'draft',
  Submitted: 'submitted',
  Sample: 'sample'
};

export const TAX_TYPE = {
  /**
   * 應稅
   */
  Taxable: 'taxable',
  /**
   * 零稅率
   */
  Zero: 'zero',
  /**
   * 免稅
   */
  Free: 'free'
};

export const INVOICE_TYPE = {
  /**
   * 紙本 (自己手動列印，並自己寄出)（目前無）
   */
  Paper: 'paper',
  /**
   * 手機條碼
   */
  MobileBarcode: 'mobileBarcode',
  /**
   * 會員
   */
  Member: 'member',
  /**
   * 自然人憑證
   */
  CDC: 'cdc'
};

export const EVENT_PRIVACY = {
  Listed: 'listed',
  Unlisted: 'unlisted'
};

export const EVENTCARD_TYPE = {
  Created: 'created',
  Joined: 'joined'
};

export const EVENT_TIPS = {
  Name: 'name',
  Slogan: 'slogan',
  KeyFindingDesc: 'keyFinding_description',
  KeyFindingTitle: 'keyFinding_title',
  TargetTitle: 'targets_title',
  TargetDesc: 'targets_description',
  Vision: 'vision',
  KeyItem: 'keyItem',
  Features: 'features',
  DserviceName: 'demand_service_name',
  DfundsName: 'demand_funds_name',
  DspaceName: 'demand_space_name',
  DserviceDesc: 'demand_service_description',
  DfundsDesc: 'demand_funds_description',
  DspaceDesc: 'demand_space_description',
  DemandKeyItemDesc: 'demand_keyItemDescriptions',
  DemandAwardDesc: 'demand_award_description',
  DemandRules: 'demand_rules'
};

export const QUOTA_TYPE = {
  // Point: 'point', // 目前沒有
  ActiveEvent: 'activeEvent'
};

export const PRODUCT_TYPE = {
  // Point: 'point', // 目前沒有
  ActiveEvent: 'activeEvent'
};

export const PURCHASE_SOURCE = {
  /**
   * 手動調整 (+/-)
   *
   * 來自 admin 手動調整
   */
  Manual: 'manual',
  /**
   * 儲值 (+)
   *
   * 來自交易
   */
  Trade: 'trade',
  /**
   * 消耗 (-)
   *
   * 來自用戶 or 系統 (worker) 消耗商品
   */
  Consume: 'consume',
  /**
   * 回復 (+)
   *
   * 來自系統 (ex. 被駁回審查)
   */
  Restore: 'restore'
};

