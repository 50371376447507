import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { throttle } from 'throttle-debounce';
import { t } from 'i18next';
import ConfigProvider from 'antd/es/config-provider';
import Tabs from 'antd/es/tabs';
import scrollDetector from 'src/stores/scrollDetector';
import { Observer } from 'mobx-react';
import styles from './styles.module.scss';

/**
 * @param {number} currentPosition Current Scroll position
 * @param {Array} sectionPositionArray Array of positions of all sections
 * @param {number} startIndex Start index of array
 * @param {number} endIndex End index of array
 * @return {number} Current Active index
 */

const nearestIndex = (
  currentPosition,
  sectionPositionArray,
  startIndex,
  endIndex
) => {
  if (!sectionPositionArray[startIndex].headerRef.current) return startIndex;
  if (!sectionPositionArray[endIndex].headerRef.current) return startIndex;

  if (startIndex === endIndex) return startIndex;
  if (startIndex === endIndex - 1) {
    if (
      Math.abs(
        sectionPositionArray[startIndex].headerRef.current.offsetTop
        - currentPosition
      )
      < Math.abs(
        sectionPositionArray[endIndex].headerRef.current.offsetTop
        - currentPosition
      )
    ) return startIndex;
    return endIndex;
  }
  // eslint-disable-next-line no-bitwise
  const nextNearest = ~~((startIndex + endIndex) / 2);
  const a = Math.abs(
    sectionPositionArray[nextNearest].headerRef.current.offsetTop
    - currentPosition
  );
  const b = Math.abs(
    sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop
    - currentPosition
  );
  // console.log(a, b, currentPosition);
  if (a < b) {
    return nearestIndex(
      currentPosition,
      sectionPositionArray,
      startIndex,
      nextNearest
    );
  }
  return nearestIndex(
    currentPosition,
    sectionPositionArray,
    nextNearest,
    endIndex
  );


};

const scrollEffect = (targetRef) => {
  const targetTop = targetRef.current.offsetTop;
  window.scrollTo({
    top: scrollDetector.isScrollingDown ? (targetTop - 95) : (targetTop - 120),
    behavior: 'smooth'
  });
};

export default function ScrollHighlightNavbar({ anchorHeader }) {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const handleScroll = (e) => {
      const index = nearestIndex(
        window.scrollY,
        anchorHeader,
        0,
        anchorHeader.length - 1
      );
      setActiveIndex(index);
    };
    document.addEventListener('scroll', throttle(400, handleScroll));
    return () => {
      document.removeEventListener('scroll', throttle(400, handleScroll));
    };
  }, [anchorHeader]);

  return (
    <Observer>
      {() => (
        <div className={clsx(styles.navContainer, !scrollDetector.isScrollingDown && styles.lower)}>
          <div className={clsx(styles.nav)}>
            {anchorHeader.map((header, index) => (
              <div
                key={header.headerID}
                className={clsx(styles.navlink, activeIndex === index && styles.active)}
                onClick={(e) => {
                  e.preventDefault();
                  scrollEffect(header.headerRef);
                }}
              >
                {header.headerTitle}
              </div>
            ))}
          </div>
        </div>
      )}
    </Observer>
  );
}

ScrollHighlightNavbar.propTypes = {
  anchorHeader: PropTypes.arrayOf(
    PropTypes.shape({
      headerID: PropTypes.string,
      headerRef: PropTypes.object.isRequired,
      headerTitle: PropTypes.string.isRequired
    })
  ).isRequired
};
