import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Success from 'src/components/Success';

@observer
class ValidateSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <Success
        title={t('register_validate_success_title')}
        texts={[t('register_validate_success_subtitle')]}
        buttonText={t('register_validate_success_nextButton')}
        onClickButton={this.vm.onNextClick}
        containerStyle={{ paddingTop: 0 }}
        illustration="greenCheck"
      />
    );
  }
}

ValidateSuccess.propTypes = {
  vm: PropTypes.object
};

ValidateSuccess.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(ValidateSuccess)), false);
