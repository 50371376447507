import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import withErrorInput from 'src/components/withErrorInput';
import { SOCIALS_TYPE, TERMS_AND_POLICY_TABS } from 'src/constants';

import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import styles from '../../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class Influence extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <div className={styles.onePageContainer}>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('register_influence_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('register_influence_subtitle')}
          </h3>
        </div>

        <div className={clsx(styles.inputGroup, styles.inputGroupAuto)} style={{ alignItems: 'flex-start' }}>
          <div className={clsx(styles.inputs, styles.flexCol, styles.inputs50)}>
            <div className={styles.inputTitle}>
              {t('register_influence_introduction_title')}
            </div>

            <ErrorTextInput
              showError={this.vm.showError}
              key="introduction"
              label={t('register_influence_introduction_placeholder')}
              onChange={(e) => this.vm.onChange('introduction', e.target.value)}
              value={this.vm.introduction}
              multiline
              required
              validValue={this.vm.isIntroductionValid}
              errorMessage={this.vm.introduction ? t('register_introduction_errorMessage') : t('this_is_required')}
            />

            <div className={styles.inputTitle}>
              {t('register_influence_motto_title')}
            </div>
            <ErrorTextInput
              showError={this.vm.showError}
              key="motto"
              label={t('register_influence_motto_placeholder')}
              value={this.vm.motto ?? ''}
              onChange={(e) => this.vm.onChange('motto', e.target.value)}
              required
              validValue={this.vm.isMottoValid}
              errorMessage={this.vm.motto ? t('register_motto_errorMessage') : t('this_is_required')}
            />

            <div className={styles.inputTitle}>
              {t('register_influence_result_title')}
            </div>
            <ErrorTextInput
              key="result"
              label={t('register_influence_result_placeholder')}
              value={this.vm.getSocialValue(SOCIALS_TYPE.Influence)}
              onChange={(e) => this.vm.onSocialsChange(SOCIALS_TYPE.Influence, e.target.value)}
              showError={this.vm.hasInfluence && this.vm.showError}
              validValue={this.vm.isInfluenceValid}
              errorMessage={t('url_is_not_valid')}
            />
          </div>

          <div className={clsx(styles.marginBottom, styles.inputs, styles.flexCol)}>
            <div className={styles.inputTitle}>
              {t('register_influence_socials_title')}
            </div>
            <ErrorTextInput
              key="website"
              label={t('website_placeholder')}
              value={this.vm.getSocialValue(SOCIALS_TYPE.Website)}
              onChange={(e) => this.vm.onSocialsChange(SOCIALS_TYPE.Website, e.target.value)}
              showError={this.vm.hasWebsite && this.vm.showError}
              validValue={this.vm.isWebsiteValid}
              errorMessage={t('url_is_not_valid')}
            />

            <ErrorTextInput
              key="linkedin"
              label={t('linkedin_placeholder')}
              value={this.vm.getSocialValue(SOCIALS_TYPE.Linkedin)}
              onChange={(e) => this.vm.onSocialsChange(SOCIALS_TYPE.Linkedin, e.target.value)}
              showError={this.vm.hasLinkedin && this.vm.showError}
              validValue={this.vm.isLinkedinValid}
              errorMessage={t('url_is_not_valid')}
            />

            <ErrorTextInput
              key="facebook"
              label={t('facebook_placeholder')}
              value={this.vm.getSocialValue(SOCIALS_TYPE.Facebook)}
              onChange={(e) => this.vm.onSocialsChange(SOCIALS_TYPE.Facebook, e.target.value)}
              showError={this.vm.hasFacebook && this.vm.showError}
              validValue={this.vm.isFacebookValid}
              errorMessage={t('url_is_not_valid')}
            />

            <ErrorTextInput
              key="instagram"
              label={t('instagram_placeholder')}
              value={this.vm.getSocialValue(SOCIALS_TYPE.Instagram)}
              onChange={(e) => this.vm.onSocialsChange(SOCIALS_TYPE.Instagram, e.target.value)}
              showError={this.vm.hasInstagram && this.vm.showError}
              validValue={this.vm.isInstagramValid}
              errorMessage={t('url_is_not_valid')}
            />

            <ErrorTextInput
              key="youtube"
              label={t('youtube_placeholder')}
              value={this.vm.getSocialValue(SOCIALS_TYPE.Youtube)}
              onChange={(e) => this.vm.onSocialsChange(SOCIALS_TYPE.Youtube, e.target.value)}
              showError={this.vm.hasYoutube && this.vm.showError}
              validValue={this.vm.isYoutubeValid}
              errorMessage={t('url_is_not_valid')}
            />

            <ErrorTextInput
              key="line"
              label={t('line_placeholder')}
              value={this.vm.getSocialValue(SOCIALS_TYPE.Line)}
              onChange={(e) => this.vm.onSocialsChange(SOCIALS_TYPE.Line, e.target.value)}
            />
          </div>
        </div>

        <div className={clsx(styles.bottomContainer2, styles.withBottomText)}>
          <div className={clsx(styles.buttonsRow2, styles.twoButtons)}>
            <Button
              onClick={this.vm.onPreviousClick}
              style={{ width: '100%' }}
              className={styles.nextButton}
              type="default"
              withLeftArrow
            >
              {t('previous_step')}
            </Button>
            <Button
              onClick={this.vm.onFinishOrgInfluence}
              style={{ width: '100%' }}
              className={styles.nextButton}
              disabled={this.vm.isButtonDisabled}
              withRightArrow
            >
              {t('register_organization_step3_nextButton')}
            </Button>
          </div>

          <div className={styles.bottomText}>
            <h5>
              <span className={styles.text}>
                {`${t('register_agreement')} `}
              </span>
              <Link
                className={styles.link}
                to="/terms-and-policy#terms"
                state={{ tab: TERMS_AND_POLICY_TABS.Terms }}
                target="_blank"
              >
                {t('terms_of_use')}
              </Link>
              <span>{` ${t('and')} `}</span>
              <Link
                className={styles.link}
                to="/terms-and-policy#policy"
                state={{ tab: TERMS_AND_POLICY_TABS.Policy }}
                target="_blank"
              >
                {t('privacy_poliicy')}
              </Link>
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

Influence.propTypes = {
  vm: PropTypes.object
};

Influence.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Influence)), false);
