import {
  makeObservable, computed, observable, action, runInAction, toJS
} from 'mobx';
import diff from 'deep-diff';
import { t } from 'i18next';
import { LIST_MODEL_TYPE, EVENT_STATUS, EVENT_TYPE } from 'src/constants';
import ErrorService from 'src/services/errors';
import AnchoredList from 'src/services/anchoredList';
import EventService from 'src/services/event';

class CreateEventOverviewPageViewModel {
  @observable userId = null;
  @observable profile = null;

  @observable samples = [];

  @observable AnchoredLists = {
    [EVENT_TYPE.Draft]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Event,
      path: 'v1/user/{uid}/event/created'
    }),

    [EVENT_TYPE.Submitted]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Event,
      path: 'v1/user/{uid}/event/created'
    })
  };

  @observable isAwait = false;

  constructor(props) {
    makeObservable(this);
  }

  @computed get lists() {
    return {
      [EVENT_TYPE.Draft]: this.AnchoredLists[EVENT_TYPE.Draft]?.list ?? [],

      [EVENT_TYPE.Submitted]: this.AnchoredLists[EVENT_TYPE.Submitted]?.list ?? [],

      [EVENT_TYPE.Sample]: this.samples
    };
  }

  @computed get noEventToCopy() {
    return this.lists[EVENT_TYPE.Draft].length === 0 && this.lists[EVENT_TYPE.Submitted].length === 0;
  }

  @action didMount = async (props) => {
    console.log('CreateEventOverview.didMount, params', props.router.params);
    console.log(props);
    this.props = props;
    this.profile = props.profile;
    this.userId = props.profile?.id;

    if (!this.profile.isEventCreator) {
      ErrorService.onCustomError(
        t('error_not_event_creator'),
        null,
        () => this.props.router.navigate('/')
      );
      return;
    }

    if (this.userId) {
      this.setAnchoredListsPaths();
      await Promise.all([
        this.getDrafts(),
        this.getSubmitteds(),
        this.getSamples()
      ]);
    }
  };

  @action setAnchoredListsPaths = () => {
    Object.values(this.AnchoredLists).forEach((l) => l.setPath(`v1/user/${this.userId}/event/created`));
  };

  getDrafts = async () => {
    const params = {
      limit: 3,
      order: 'desc'
    };
    const body = {
      statuses: [
        EVENT_STATUS.Draft,
        EVENT_STATUS.Failed
      ]
    };

    await this.AnchoredLists[EVENT_TYPE.Draft].getList(body, params);
  };

  getSubmitteds = async () => {
    const params = {
      limit: 3,
      order: 'desc'
    };
    const body = {
      statuses: [
        EVENT_STATUS.Active,
        EVENT_STATUS.Inactive
      ]
    };

    await this.AnchoredLists[EVENT_TYPE.Submitted].getList(body, params);
  };

  getSamples = async () => {
    const params = {
      limit: 5
    };

    const filter = {
      types: ['sample']
    };

    try {
      const res = await EventService.getList(params, filter);
      runInAction(() => {
        const { list } = res;
        console.log(list);
        this.samples = list ?? [];
      });
    } catch (error) {
      if (error.response?.status === 401) { // when token is expired
        ErrorService.onCustomError(
          t('general_error_content_401'),
          null,
          () => this.props.router.navigate(0)
        );
      } else {
        ErrorService.onDefaultError(error);
      }
    }
  };

  onClickDraft = (id) => {
    this.toCreateEvent(id);
    window.gtag('event', 'Create_Event_Continue_Draft', { Event_ID: id });
  };

  onClickSubmitted = async (id) => {
    const newId = await this.onCopy(id);
    if (newId) {
      window.gtag(
        'event',
        'Create_Event_Copy_Existing',
        {
          Event_ID: id,
          Copied_Event_ID: newId
        }
      );
    }
  };

  onViewSample = async (id) => {
    const { navigate } = this.props.router;
    navigate(`/events/${id}`, { state: { isFromCreateEvent: true } });
  };

  onCopySample = async (id) => {
    const newId = await this.onCopy(id);
    if (newId) {
      window.gtag(
        'event',
        'Create_Event_Copy_Sample',
        {
          Event_ID: id,
          Quoted_Event_ID: newId
        }
      );
    }
  };

  onCopy = async (id) => {
    this.isAwait = true;

    let newId = null;
    try {
      const event = await EventService.copy(id);
      this.toCreateEvent(event.id);
      newId = event.id;
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }

    return newId;
  };

  // ////////////////////////////////////////

  toCreateEvent = (id) => {
    const { navigate } = this.props.router;
    navigate(`/create-event/${id}`);
  };

  toCreateEventIntro = () => {
    const { navigate } = this.props.router;
    navigate('/create-event-intro');
  };

  toList = (panel) => {
    const { navigate } = this.props.router;
    navigate(`/create-event-overview/${panel}`);
  };

}

export default CreateEventOverviewPageViewModel;
