import { request, getHost, getHeader } from './utils';

export const rechargeText = (data) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/common/recharge-text`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const productText = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/common/product-text`,
    headers: getHeader()
  };
  return request(options);
};
