import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Spin from 'antd/es/spin';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ProductCard from 'src/components/ProductCard';

import illustrationPainter from 'src/assets/illustrationPainter.svg';

import ProductPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class ProductPage extends React.Component {
  constructor() {
    super();

    this.vm = new ProductPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderProductList() {
    if (this.vm.list.length === 0) {
      return this.renderEmpty();
    }

    return (
      <div className={styles.list}>
        {this.vm.list?.map((item) => (
          <ProductCard
            key={item.id}
            item={item}
            buttonProps={{
              onClick: () => this.vm.toCheckout(item.id, item)
            }}
          />
        ))}
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className={styles.emptyContainer}>
        {
        this.vm.isFetching
          ? <Spin />
          : (
            <div className={styles.msg}>
              {t('product_empty')}
            </div>
          )
        }
      </div>
    );
  }

  render() {

    return (
      <div className={styles.productPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_credit_history'), path: '/user/order-history' },
              { label: t('breadcrumb_buy_plan'), path: '' }
            ]}
            right={null}
            onBackPress={this.vm.toOrderHistory}
            withHeader
          />
          <div className={styles.topArea}>
            <img
              alt="painter"
              src={illustrationPainter}
              className={styles.painter}
            />
            <div className={styles.titles}>
              <div className={styles.title}>
                {t('product_page_title_1')}
              </div>
              <div className={styles.title}>
                {t('product_page_title_2')}
              </div>
            </div>
          </div>

          <div className={styles.mainContainer}>
            <div className={styles.promoText}>
              {this.vm.promotionText}
            </div>

            {this.renderProductList()}
          </div>

        </MainLayout>
      </div>
    );
  }
}

ProductPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(ProductPage)), false);
