import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Icon from 'src/components/Icon';
import iconDoubleCheck from 'src/assets/iconDoubleCheckFill.svg';
import Button from '../Button';
import styles from './styles.module.scss';

export default function ProductCard(props) {
  const { item, onClick, buttonProps, className } = props;

  if (!item) return null;

  return (
    <div
      className={clsx(styles.card, !!onClick && styles.clickable, className)}
      onClick={onClick}
      key={item.id}
    >
      <div className={styles.mainContent}>
        <div className={styles.text}>
          {`/ ${item.name} /`}
        </div>

        <div className={clsx(styles.days, styles.text)}>
          <Icon
            size={20}
            svg
            className={styles.icon}
          >
            {iconDoubleCheck}
          </Icon>
          <span>
            {t('quota_activeEvents')}
          </span>
          <span>
            {item.count}
          </span>
          <span>
            {t('quota_unit')}
          </span>
        </div>

        {buttonProps && (
          <Button
            className={styles.button}
            {...buttonProps}
          >
            {item.priceForDisplay}
          </Button>
        )}
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  onClick: PropTypes.func,
  showButton: PropTypes.bool,
  buttonProps: PropTypes.object
};

ProductCard.defaultProps = {
  className: null,
  item: null,
  onClick: null,
  showButton: false,
  buttonProps: null
};
