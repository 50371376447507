import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed, action, makeObservable } from 'mobx';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import Checkbox from '@mui/material/Checkbox';
import Input from 'antd/es/input';
import Checkbox from '../Checkbox';


const ListItemInput = observer((props) => {
  const { vm } = props;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{vm.label}</span>
      <Input
        maxLength={9}
        onClick={(e) => e.stopPropagation()}
        onChange={vm.onInputChange}
        value={vm.inputValue}
        style={{ flex: 1, marginLeft: 8, fontSize: 16 }}
      />
    </div>
  );
});

@observer
class ChildNode extends React.Component {
  constructor(props) {
    super(props);
  }

  @computed
  get showInput() {
    const { vm, showInputForOthers } = this.props;
    return showInputForOthers && vm.shouldUseInputValue;
  }

  render() {

    const { vm, searchText } = this.props;

    if (searchText && vm.label.search(searchText) < 0) return null;
    return (
      <div key={vm.idValue} style={{ marginLeft: 25 }}>
        <ListItem
          onClick={(e) => {
            e.stopPropagation();
            vm.onChange();
          }}
          sx={{ cursor: 'pointer' }}
        >
          <ListItemIcon sx={{ minWidth: 45 }}>
            <Checkbox
              edge="start"
              checked={vm.checked ?? false}
            />
          </ListItemIcon>
          { this.showInput
            ? <ListItemInput vm={vm} />
            : <ListItemText>{vm.label}</ListItemText>}
        </ListItem>
      </div>
    );
  }
}

ChildNode.propTypes = {
  vm: PropTypes.object,
  showInputForOthers: PropTypes.bool,
  searchText: PropTypes.string
};

ChildNode.defaultProps = {
  vm: {},
  showInputForOthers: false,
  searchText: null
};

export default ChildNode;
