import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import pretendTo from 'src/stores/pretendTo';
import ConstantsStore from 'src/stores/constants';
import UserService from 'src/services/user';
import { TABLE_LIMIT, TABLE_LIMIT_MOBILE, LIST_MODEL_TYPE } from 'src/constants';
import RegisterService from 'src/services/register';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';
import FilterViewModel from 'src/components/EventFilter/vm';
import ErrorService from 'src/services/errors';
import tabsState from 'src/stores/tabsState';
import WithdrawApplicationModalViewModel from 'src/components/Modals/WithdrawApplicationModal/vm';
import AnchoredList from 'src/services/anchoredList';

export const TABS = {
  Accepted: 'accepted',
  Applied: 'applied'
};

class EventJoinedPageViewModel {
  regionsSelectViewModel = new TreeSelectViewModel(ConstantsStore.regionsOptions);
  sdgsSelectViewModel = new TreeSelectViewModel(ConstantsStore.sdgsOptions);

  @observable demandTypes = [];

  @observable isMobile = false;

  @observable filterVM;

  @observable isWithdrawModalOpen = false;
  withdrawApplicationVM = new WithdrawApplicationModalViewModel();

  @observable AnchoredLists = {
    [TABS.Accepted]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.EventJoined,
      path: 'v1/user/{uid}/event/joined'
    }),
    [TABS.Applied]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.EventJoined,
      path: 'v1/user/{uid}/event/joined'
    })
  };

  constructor(props) {
    makeObservable(this);
    console.log(props);
    this.props = props;
    this.profile = props.profile;
  }

  @computed get currentTab() {
    return tabsState.eventJoinedTab;
  }

  @action didMount = async (props) => {
    console.log('EventJoinedPage.didMount, params', props.router.params);
    console.log(props);
    this.filterVM = new FilterViewModel({
      getList: this.getLists
    });

    await this.init(props);
  };

  @action didUpdate = async (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile) || (UserService.isLogin && !this.profile)) {
      await this.init(props);
    }
  };

  @action init = async (props) => {
    this.isMobile = props.context.state.isMobile;

    const uid = pretendTo.id ?? this.props.profile.id;
    if (!uid) return;

    Object.values(this.AnchoredLists).forEach((l) => l.setPath(`v1/user/${uid}/event/joined`));
    await this.getLists();
  };

  @computed get list() {
    return this.lists[this.currentTab];
  }

  @computed get anchor() {
    return this.AnchoredLists[this.currentTab].anchor;
  }

  @computed get isAwait() {
    return this.AnchoredLists[this.currentTab].isAwait;
  }

  @computed get lists() {
    return {
      [TABS.Accepted]: this.AnchoredLists[TABS.Accepted].list,
      [TABS.Applied]: this.AnchoredLists[TABS.Applied].list
    };
  }

  @action getLists = async () => {
    await Promise.all([
      this.getList(TABS.Accepted),
      this.getList(TABS.Applied)
    ]);
  };

  @action getList = async (tab) => {
    const params = {
      limit: this.isMobile ? TABLE_LIMIT_MOBILE : TABLE_LIMIT,
      ...this.filterVM.listParams
    };

    await this.AnchoredLists[tab].getList(
      { ...this.filterVM.listFilter, isAccepted: tab === TABS.Accepted },
      params
    );
  };

  @action switchTab = (value) => {
    tabsState.updateEventJoinedTab(value);

    if (this.lists[this.currentTab].length === 0) {
      this.getList(this.currentTab);
    }
  };

  withdrawApplication = async (id, eventName) => {
    this.withdrawApplicationVM.open({
      eventName,
      onConfirm: async () => {
        try {
          const res = await RegisterService.withdraw(id);
          runInAction(() => {
            this.getList(TABS.Applied);
          });
        } catch (error) {
          ErrorService.onDefaultError(error);
        }
      }
    });
  };

  onScrollEnd = async () => {
    console.log('on scroll end');
    await this.AnchoredLists[this.currentTab].getNext();
  };

  // ///////////////////////////////////////////////////////////

  toEventListPage = () => {
    const { navigate } = this.props.router;
    navigate('/events');
  };

  toEvent = (id) => {
    const { navigate } = this.props.router;
    navigate(`/events/${id}`);
  };

  toApplication = (id) => {
    const { navigate } = this.props.router;
    navigate(`/application/${id}`);
  };

}

export default EventJoinedPageViewModel;
