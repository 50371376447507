import * as constants from './constants';
import * as user from './user';
import * as resource from './resource';
import * as event from './event';
import * as demand from './demand';
import * as google from './google';
import * as notification from './notification';
import * as register from './register';
import * as banner from './banner';
import * as admin from './admin';
import * as belong from './belong';
import * as common from './common';
import * as product from './product';
import * as purchase from './purchase';
import * as topic from './topic';

export default {
  constants,
  user,
  resource,
  event,
  demand,
  google,
  notification,
  register,
  banner,
  admin,
  belong,
  common,
  product,
  purchase,
  topic
};
