import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { t } from 'i18next';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Success from './components/Success';
import Fail from './components/Fail';

import CheckoutResultPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class CheckoutResultPage extends React.Component {
  constructor() {
    super();

    this.vm = new CheckoutResultPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderContent() {
    if (!this.vm.detail) {
      return null;
    }


    return this.vm.isSuccess
      ? <Success vm={this.vm} />
      : <Fail vm={this.vm} />;
  }

  render() {

    return (
      <div className={styles.checkoutResultPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideFooter
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_buy_plan'), path: '/product' },
              { label: t('breadcrumb_plan_purchase'), path: '' }
            ]}
            right={null}
            withHeader
          />
          <div className={styles.mainContainer}>
            {this.renderContent()}
          </div>

        </MainLayout>
      </div>
    );
  }
}

CheckoutResultPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(CheckoutResultPage)), true);
