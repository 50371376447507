import { makeObservable, observable, action, computed } from 'mobx';
import { USER_TYPES } from 'src/constants';
import IS from 'src/utils/is';
import ConstantsStore from 'src/stores/constants';
import Organization from './Organization';
import Personal from './Personal';
import Belong from './Belong';

class Profile {
  @observable id;
  @observable type;
  @observable group;
  @observable status;
  @observable name;
  @observable avatar;
  @observable gender;
  @observable displayName;
  // //////////////////
  @observable phone;
  @observable phone9Digit = '';
  @observable countryCode = '+886';
  // /////////////////
  @observable phoneStatus;
  @observable email;
  @observable emailStatus;
  @observable blueTickAt;
  @observable isBlock;
  @observable createdAt;
  @observable reviews = [];
  @observable info;
  @observable belongs;
  @observable totalMembers;
  @observable permission = {};


  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }


  static fromRes(data) {
    return new Profile(data);
  }

  get isEventCreator() {
    return this.permission.event.slice(0, 1) === '1';
  }

  get identity() {
    return this.type;
  }

  @computed
  get isPhoneValid() {
    return IS.isTWPhone(this.countryCode + this.phone9Digit);
  }

  @computed
  get isCountryCodeValid() {
    return ConstantsStore.countryCodesOptions.some((opt) => opt.value === this.countryCode);
  }

  @action onChange = (field, value, limit = null) => {
    if (limit) {
      if (value.toString().length <= limit) {
        this[field] = value;
      }
    } else {
      this[field] = value;
    }
    console.log('profile onchange', this[field]);
  };

  @action deserialize = (data) => {
    const {
      profile,
      info,
      totalMembers,
      belongs,
      permission
    } = data ?? {};

    const {
      id,
      type,
      group,
      status,
      name,
      avatar,
      gender,
      displayName,
      phone,
      phoneStatus,
      email,
      emailStatus,
      blueTickAt,
      isBlock,
      createdAt,
      reviews
    } = profile ?? {};

    this.id = id;
    this.type = type;
    this.group = group;
    this.status = status;
    this.name = name;
    this.avatar = avatar;
    this.gender = gender;
    this.displayName = displayName;
    this.phone = phone;
    if (phone) {
      this.phone9Digit = phone.slice(-9);
      this.countryCode = phone.slice(0, phone.length - 9);
      if (this.countryCode === '+8860') {
        this.countryCode = '+886';
      }
    }
    this.phoneStatus = phoneStatus;
    this.email = email;
    this.emailStatus = emailStatus;
    this.blueTickAt = blueTickAt;
    this.isBlock = isBlock;
    this.createdAt = createdAt;
    this.reviews = reviews ?? [];

    if (info) {
      this.info = (this.type === USER_TYPES.Personal ? Personal.fromRes(info) : Organization.fromRes(info));
    } else {
      this.info = {};
    }

    // /////////////

    this.belongs = belongs?.map((item) => Belong.fromRes(item)) ?? [];
    this.totalMembers = totalMembers;

    // /////////////

    const {
      event,
      resource,
      volunteer
    } = permission ?? {};

    this.permission = {
      event: event ?? '',
      resource: resource ?? '',
      volunteer: volunteer ?? ''
    };
  };

  serialize = (isReviewed = false) => {
    const {
      displayName,
      gender,
      countryCode,
      phone9Digit
    } = this;

    const base = {
      displayName,
      gender,
      phone: countryCode + phone9Digit,
      ...this.info.serialize(isReviewed)
    };

    if (this.type === USER_TYPES.Organization && !isReviewed) {
      return {
        name: this.name,
        ...base
      };
    }

    return base;
  };
}

export default Profile;
