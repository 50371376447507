import API from 'src/apis';

export default class BelongService {
  static async create(body) {
    const { data } = await API.belong.create(body);
    return data;
  }

  static async delete(belongId) {
    const { data } = await API.belong.deleteBelong(belongId);
    return data;
  }
}
