import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AppStateContext } from 'src/stores';
import ErrorModal from 'src/components/Modals/ErrorModal';
import errorModalViewModel from 'src/components/Modals/ErrorModal/vm';
import pretendTo from 'src/stores/pretendTo';
import { Helmet } from 'react-helmet';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import ResetPasswordPage from './ResetPasswordPage';
import EventsPage from './Event/EventsPage';
import EventDetailPage from './Event/EventDetailPage';
import DemandsPage from './Event/DemandsPage';
import DemandDetailPage from './Event/DemandDetailPage';
import ResourcesPage from './Resource/ResourcesPage';
import ResourceDetailPage from './Resource/ResourceDetailPage';
import SignUpForEventPage from './Event/SignUpForEventPage';
import ApplicationPage from './Event/ApplicationPage';
import ProductPage from './ProductPage';
import ProfilePage from './User/ProfilePage';
import ProfileEditPage from './User/ProfileEditPage';
import EventHostedPage from './User/EventHostedPage';
import RecommendedResourcesPage from './User/RecommendedResourcesPage';
import EventDemandsPage from './User/EventDemandsPage';
import EventApplicationsPage from './User/EventApplicationsPage';
import EventJoinedPage from './User/EventJoinedPage';
import MyResourcePage from './User/MyResourcePage';
import OrderHistoryPage from './User/OrderHistoryPage';
import CheckoutPage from './Checkout/CheckoutPage';
import CheckoutResultPage from './Checkout/CheckoutResult';
import CreateEventPage from './CreateEvent/CreateEventPage';
import CreateEventOverviewPage from './CreateEvent/OverviewPage';
import CreateEventIntroPage from './CreateEvent/IntroPage';
import CreateEventListPage from './CreateEvent/ListPage';
import NotFoundPage from './NotFoundPage';
import MyResourceFormPage from './User/MyResourceFormPage';
import EmailValidateSuccessPage from './EmailValidateSuccessPage';
import EmailValidateFailPage from './EmailValidateFailPage';
import TermsAndPolicyPage from './TermsAndPolicyPage';
import ProposalPage from './ProposalPage';
import TopicsPage from './TopicsPage';


class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    console.log('------- page root construct');
    pretendTo.checkAndUpdateId();
  }


  componentDidMount() {

  }


  render() {
    return (
      <>
        <Helmet>
          <title>go action</title>
        </Helmet>
        <BrowserRouter basename="/">
          <Routes>
            <Route
              path="/login"
              element={<LoginPage context={this.context} />}
            />

            <Route
              path="/register"
              element={<RegisterPage context={this.context} />}
            />

            <Route
              path="/email-validate-success"
              element={<EmailValidateSuccessPage context={this.context} />}
            />

            <Route
              path="/email-validate-fail"
              element={<EmailValidateFailPage context={this.context} />}
            />

            <Route
              path="/reset-password"
              element={<ResetPasswordPage context={this.context} />}
            />

            <Route
              path="/"
              element={<HomePage context={this.context} appRef={this.props} />}
            />

            <Route
              path="/events"
              element={<EventsPage context={this.context} />}
            />

            <Route
              path="/events/:id"
              element={<EventDetailPage context={this.context} />}
            />

            <Route
              path="/events/:eid/demands"
              element={<DemandsPage context={this.context} />}
            />

            <Route
              path="/events/:eid/demands/:did"
              element={<DemandDetailPage context={this.context} />}
            />

            <Route
              path="/resources"
              element={<ResourcesPage context={this.context} />}
            />

            <Route
              path="/resources/:type/:id"
              element={<ResourceDetailPage context={this.context} />}
            />

            <Route
              path="/sign-up-for-event/:eid/:did?/:rid?"
              element={<SignUpForEventPage context={this.context} />}
            />

            <Route
              path="/application/:id"
              element={<ApplicationPage context={this.context} />}
            />

            <Route
              path="/user/profile/:id?"
              element={<ProfilePage context={this.context} />}
            />

            <Route
              path="/user/profile/edit"
              element={<ProfileEditPage context={this.context} />}
            />

            <Route
              path="/user/my-resource"
              element={<MyResourcePage context={this.context} />}
            />

            <Route
              path="/user/my-resource/form"
              element={<MyResourceFormPage context={this.context} />}
            />

            <Route
              path="/user/event-hosted"
              element={<EventHostedPage context={this.context} />}
            />

            <Route
              path="/user/event-hosted/:eid/recommend"
              element={<RecommendedResourcesPage context={this.context} />}
            />

            <Route
              path="/user/event-hosted/:eid/demands"
              element={<EventDemandsPage context={this.context} />}
            />

            <Route
              path="/user/event-hosted/:eid/:did/applications"
              element={<EventApplicationsPage context={this.context} />}
            />

            <Route
              path="/user/event-joined"
              element={<EventJoinedPage context={this.context} />}
            />

            <Route
              path="/user/order-history"
              element={<OrderHistoryPage context={this.context} />}
            />

            <Route
              path="/product"
              element={<ProductPage context={this.context} />}
            />

            <Route
              path="/product/:id/checkout"
              element={<CheckoutPage context={this.context} />}
            />

            <Route
              path="/order/:id"
              element={<CheckoutResultPage context={this.context} />}
            />

            <Route
              path="/create-event-overview"
              element={<CreateEventOverviewPage context={this.context} />}
            />

            <Route
              path="/create-event-intro"
              element={<CreateEventIntroPage context={this.context} />}
            />

            <Route
              path="/create-event-overview/:type"
              element={<CreateEventListPage context={this.context} />}
            />

            <Route
              path="/create-event/:id?"
              element={<CreateEventPage context={this.context} />}
            />

            <Route
              path="/proposal"
              element={<ProposalPage context={this.context} />}
            />

            <Route
              path="/terms-and-policy"
              element={<TermsAndPolicyPage context={this.context} />}
            />

            <Route
              path="/topics/:main/:sub"
              element={<TopicsPage context={this.context} />}
            />

            <Route path="*" element={<NotFoundPage context={this.context} />} />
          </Routes>
        </BrowserRouter>

        <ErrorModal vm={errorModalViewModel} isMobile={this.context.state.isMobile} />
      </>
    );
  }
}

export default PagesRoot;
