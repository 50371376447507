export default class Applicant {
  constructor(params) {
    const {
      name,
      birthday,
      cities,
      cityOfBirth,
      cityOfResidence,
      company,
      email,
      phone,
      gender,
      socials,
      jobs,
      jobTitle,

      foundedIn,
      job,
      type,
      location
    } = params ?? {};

    this.name = name;
    this.birthday = birthday;
    this.cities = cities ?? [];
    this.cityOfBirth = cityOfBirth;
    this.cityOfResidence = cityOfResidence;
    this.company = company;
    this.email = email;
    this.phone = phone;
    this.gender = gender;
    this.socials = socials ?? [];
    this.jobs = jobs ?? [];
    this.jobTitle = jobTitle;

    this.foundedIn = foundedIn;
    this.job = job;
    this.type = type;
    this.location = location;
  }

  static fromRes(data) {
    return new Applicant(data);
  }
}



