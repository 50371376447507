import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Constants from 'src/stores/constants';
import Avatar from 'src/components/Avatar';

import styles from './styles.module.scss';

function BelongCard(props) {
  const { item, avatarSize, onClick, className } = props;

  const { owner, member, startAt, endAt } = item;

  const user = owner || member;

  if (!user) return null;

  const period = () => {
    if (Constants.language === 'zh-TW') {
      return (
        <>
          {time(startAt)}
          <span>-</span>
          {endAt ? time(endAt) : <span>現在</span>}
        </>
      );
    }

    return (
      <>
        {dayjs(startAt).format('YYYY.MM')}
        <span>-</span>
        {endAt ? dayjs(endAt).format('YYYY.MM') : <span>Now</span>}
      </>
    );
  };

  const time = (isoString) => (
    <>
      <span>{dayjs(isoString).year()}</span>
      <span>年</span>
      <span>{dayjs(isoString).month() + 1}</span>
      <span>月</span>
    </>
  );

  return (
    <div
      key={item.id}
      className={clsx(styles.belongCard, onClick ? styles.clickable : '', className)}
      onClick={onClick}
    >
      <div className={styles.cardContent}>
        <Avatar
          url={user.avatar}
          size={avatarSize || 30}
          showBlueTick={!!user.blueTickAt}
          className={styles.avatar}
        />
        <div className={styles.textContent}>
          <div className={styles.name}>
            {user.displayName || user.name}
          </div>
          <div className={styles.period}>
            {period()}
          </div>
        </div>
      </div>
    </div>
  );
}

BelongCard.propTypes = {
  item: PropTypes.object,
  owner: PropTypes.object,
  member: PropTypes.object,
  startAt: PropTypes.string,
  endAt: PropTypes.string,
  avatarSize: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string
};

BelongCard.defaultProps = {
  item: null,
  owner: null,
  member: null,
  startAt: null,
  endAt: null,
  avatarSize: 30,
  onClick: null,
  className: null
};

export default BelongCard;
