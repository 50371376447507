import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { Waypoint } from 'react-waypoint';
import { USER_TYPES } from 'src/constants';
import ConfigProvider from 'antd/lib/config-provider';
import Spin from 'antd/es/spin';
import Dropdown from 'antd/es/dropdown';
import Icon from 'src/components/Icon';
import Select from 'src/components/Select';
import DatePicker from 'src/components/DatePicker';
import Checkbox from 'src/components/Checkbox';
import Button from 'src/components/Button';
import withErrorInput from 'src/components/withErrorInput';
import BaseModal from 'src/components/Modals/BaseModal';
import Avatar from 'src/components/Avatar';
import BelongCard from 'src/components/BelongCard';

import iconThreeDots from 'src/assets/iconThreeDots.svg';
import iconTrashCan from 'src/assets/iconTrashCan.svg';
import iconAdd from 'src/assets/iconAddPurple.svg';

import styles from '../../styles.module.scss';
import BelongVM, { MODAL_TYPE } from './vm';

const ErrorSelect = withErrorInput(Select);

@observer
class Belong extends React.Component {

  constructor(props) {
    super(props);
    this.vm = new BelongVM(props);
    console.log(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderBelongEmpty() {
    return (
      <div className={styles.emptyContainer}>
        <div className={styles.emptyMessage}>
          <div className={styles.title}>
            {t(`belongs_${this.vm.identity}_empty_1`)}
          </div>
          <div className={styles.text}>
            {t(`belongs_${this.vm.identity}_empty_2`)}
          </div>
        </div>
      </div>
    );
  }

  // personal //////////////////////////////////

  renderBelongModal() {
    const { isMobile } = this.props;
    return (
      <BaseModal
        onClose={() => this.vm.toggleBelongModal()}
        open={this.vm.isBelongModalOpen}
        isMobile={isMobile}
        height="70%"
      >
        <div className={styles.belongModal}>
          <div className={styles.title}>
            {this.vm.isBelongModalForAdding ? t('add_belong') : t('edit_belong')}
          </div>

          {this.renderBelongModalContent()}

          <Button
            onClick={this.vm.isBelongModalForAdding ? this.vm.onAddBelong : this.vm.onSaveBelong}
            className={styles.addBelongBtnInModal}
          >
            {this.vm.isBelongModalForAdding ? t('button_add') : t('save')}
          </Button>
        </div>
      </BaseModal>
    );
  }

  renderBelongModalContent = () => {
    if ((this.vm.showLabelWithIcon || !this.vm.isBelongModalForAdding) && this.vm.selectedUser.value) {
      return this.renderSelectedUser();
    }

    return (
      <ErrorSelect
        key="belong_select"
        placeholder={t('org_name_placeholder')}
        filterOption={false}
        showSearch
        labelInValue
        onSearch={this.vm.onUserSearch}
        notFoundContent={this.vm.isFetching ? <Spin size="small" /> : null}
        options={this.vm.userOptions?.map((opt) => {
          const label = this.renderSelectUserOption(opt);
          return {
            value: opt.value,
            key: opt.value,
            label
          };
        })}
        value={this.vm.searchUserInput}
        onSelect={(e) => this.vm.onSelectUser(e)}
        onDropdownVisibleChange={this.vm.resetUserOptions}
        onBlur={this.vm.onBlurUserSelect}
        showError={this.vm.showBelongError.user}
        validValue={!this.vm.showBelongError.user}
      />
    );
  };

  renderSelectedUser = () => {
    const opt = this.vm.selectedUser;
    return (
      <div className={styles.selectedUserContainer} key={this.vm.selectedUser.value}>
        {this.renderSelectUserOption(opt, this.vm.onClickSelectedUser, true)}
        <DatePicker
          key="belong_start_month"
          label={t('start_month')}
          picker="month"
          placeholder={t('start_month')}
          onChange={(date, dateString) => {
            this.vm.onChangeBelongDate('startAt', date);
          }}
          style={{ width: '100%' }}
          required
          showError={this.vm.showBelongError.startAt}
          validValue={!this.vm.showBelongError.startAt}
          defaultValue={this.vm.selectedUser?.startAt ? dayjs(this.vm.selectedUser?.startAt) : null}
          allowClear={false}
        />

        <DatePicker
          key="belong_end_month"
          label={t('end_month')}
          picker="month"
          placeholder={t('end_month')}
          onChange={(date, dateString) => {
            this.vm.onChangeBelongDate('endAt', date);
          }}
          style={{ width: '100%' }}
          showError={this.vm.showBelongError.endAt}
          validValue={!this.vm.showBelongError.endAt}
          defaultValue={this.vm.selectedUser?.endAt ? dayjs(this.vm.selectedUser?.endAt) : null}
          errorMessage={t('end_time_is_invalid')}
        />
      </div>
    );
  };

  renderSelectUserOption = (opt, onClick, isForDisplay = false) => {
    const labelProps = opt.label?.props ?? {};
    return (
      <div
        data-name={opt.label}
        data-avatar={opt.avatar}
        className={clsx(styles.userOption, isForDisplay && styles.forDisplay)}
        onClick={this.vm.isBelongModalForAdding ? onClick : () => {}}
      >
        <Avatar
          url={opt.avatar || labelProps['data-avatar']}
          isRound={false}
          size={40}
        />
        <div className={styles.label}>
          {labelProps['data-name'] || opt.label}
        </div>
      </div>
    );
  };

  renderPersonal() {
    return (
      <div className={clsx(styles.flexCol, styles.center, styles.marginTop30, styles.belongListWrapper)}>
        <div
          className={styles.addBelongBtn}
          onClick={() => this.vm.toggleBelongModal(MODAL_TYPE.Add)}
        >
          <Icon
            size={28}
            svg
          >
            {iconAdd}
          </Icon>
          {t('button_add_belong')}
        </div>

        {this.vm.belongs?.length !== 0
          ? (
            <div className={styles.belongsList}>
              {this.vm.belongs?.map((item) => (
                <div className={styles.belongItemContainer} key={item.id}>
                  <BelongCard item={item} />
                  <Dropdown
                    overlayClassName="moreDropdownSm"
                    menu={{
                      items: [
                        {
                          key: 'edit',
                          label: (
                            <div
                              onClick={(e) => this.vm.onEditBelong(item)}
                            >
                              {t('edit')}
                            </div>
                          )
                        },
                        {
                          key: 'delete',
                          label: (
                            <div
                              onClick={(e) => this.vm.onDeleteBelong(item.id)}
                            >
                              {t('delete')}
                            </div>
                          )
                        }
                      ]
                    }}
                  >
                    <Icon
                      size={24}
                      svg
                    >
                      {iconThreeDots}
                    </Icon>
                  </Dropdown>
                </div>
              ))}
            </div>
          )
          : this.renderBelongEmpty()}

        {this.renderBelongModal()}

      </div>
    );
  }

  // organization //////////////////////////////////

  renderDeleteButtons() {
    if (this.vm.showBatchDelete) {
      return (
        <div className={styles.orgBelongButtons}>
          <Button
            type="default"
            className={styles.button}
            onClick={() => {
              this.vm.onToggleBatchDelete();
              this.vm.resetSelectedItems();
            }}
          >
            {t('cancel')}
          </Button>

          <Button
            type={this.vm.selectedIds?.length !== 0 ? 'primary' : 'default'}
            className={clsx(styles.button, this.vm.selectedIds?.length !== 0 && styles.buttonPrimary)}
            onClick={this.vm.onClickBatchDeleteMembers}
          >
            {t('remove')}
          </Button>
        </div>
      );
    }

    return (
      <Button
        type="default"
        className={styles.orgBatchDeleteButton}
        onClick={this.vm.onToggleBatchDelete}
      >
        {t('belongs_batch_remove')}
      </Button>
    );
  }

  renderItemForOrg(item) {
    return (
      <div className={styles.belongItemContainer} key={item.id}>
        {this.vm.showBatchDelete && (
          <Checkbox onChange={(e) => this.vm.onCheckDeleteItem(e, item.id)} />
        )}
        <BelongCard
          item={item}
          avatarSize={50}
        />

        {!this.vm.showBatchDelete && (
          <Icon
            size={24}
            svg
            onClick={() => this.vm.onDeleteMember(item.id)}
          >
            {iconTrashCan}
          </Icon>
        )}
      </div>
    );
  }

  renderOrganization() {
    return (
      <div className={clsx(styles.flexCol, styles.center, styles.marginTop30, styles.belongListWrapper)}>
        {this.vm.members?.length !== 0
          ? (
            <div className={styles.belongsList}>
              {this.renderDeleteButtons()}
              {this.vm.members?.map((item) => this.renderItemForOrg(item))}

              {this.vm.anchoredMemberList?.anchor && (
                <>
                  {this.vm.anchoredMemberList?.isAwait && <Spin />}
                  <Waypoint
                    scrollableAncestor={window}
                    onEnter={this.vm.anchoredMemberList?.getNext}
                  />
                </>
              )}
            </div>
          )
          : this.renderBelongEmpty()}

      </div>
    );
  }

  render() {
    if (this.vm.isLoading) {
      return (
        <div className={clsx(styles.flexCol, styles.center, styles.marginTop30)}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#414EE1',
                controlHeight: 66,
                fontSize: 16
              }
            }}
          >
            <Spin />
          </ConfigProvider>
        </div>
      );
    }

    if (this.vm.identity === USER_TYPES.Organization) {
      return this.renderOrganization();
    }

    return this.renderPersonal();

  }
}

export default Belong;

Belong.propTypes = {
  isMobile: PropTypes.bool
};

Belong.defaultProps = {
  isMobile: false
};
