import API from 'src/apis';
import Resource from 'src/models/response/Resource';

export default class ResourceService {
  static async getList(type, params, body) {
    const { data } = await API.resource.resourceList(type, params, body);
    return {
      list: data.list.map((el) => Resource.fromRes(el)),
      count: data.count,
      limit: data.limit,
      page: data.page
    };
  }

  static async getDetail(id) {
    const { data } = await API.resource.resourceDetail(id);
    return Resource.fromRes(data);
  }

  static async create(type, body) {
    const { data } = await API.resource.create(type, body);
    return data.id;
  }

  static async update(id, body) {
    const res = await API.resource.update(id, body);
    return res;
  }

  static async updateActive(id, isActive) {
    const res = await API.resource.updateActive(id, isActive);
    return res;
  }

  static async genFilesPresignedUrls(id, files) {
    const res = await API.resource.genFilesPresignedUrls(id, files);
    return res;
  }
}
