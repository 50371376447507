import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import dayjs from 'dayjs';
import qs from 'qs';
import EventService from 'src/services/event';
import { TABLE_LIMIT, TABLE_LIMIT_MOBILE } from 'src/constants';
import FilterViewModel from 'src/components/EventFilter/vm';
import ErrorService from 'src/services/errors';
import tabsState from 'src/stores/tabsState';
import { t } from 'i18next';
import IS from 'src/utils/is';

export const TABS = {
  Ongoing: 'ongoing',
  Incoming: 'incoming',
  Past: 'past'
};

export const SORT = {
  Popularity: 'popularity', // 熱門
  Id: 'id' // 最新
};

class EventPageViewModel {
  @observable lists = {
    [TABS.Ongoing]: [],
    [TABS.Incoming]: [],
    [TABS.Past]: []
  };
  @observable isFetching = false;

  @observable isMobile = false;
  @observable isModalOpen = false;

  @observable startAt = {};
  @observable endAt = {};

  // pagination
  @observable counts = {
    [TABS.Ongoing]: 0,
    [TABS.Incoming]: 0,
    [TABS.Past]: 0
  };
  @observable pages = {
    [TABS.Ongoing]: 1,
    [TABS.Incoming]: 1,
    [TABS.Past]: 1
  };
  // sort
  @observable sort = SORT.Id;
  @observable order = 'desc'; // asc / desc

  @observable filterVM;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @computed get currentTab() {
    return tabsState.eventsTab;
  }

  @computed get list() {
    return this.lists[this.currentTab];
  }

  @computed get page() {
    return this.pages[this.currentTab];
  }

  @computed get count() {
    return this.counts[this.currentTab];
  }

  @computed get limit() {
    return this.isMobile ? TABLE_LIMIT_MOBILE : TABLE_LIMIT;
  }

  @action
    didMount = async (props) => {
      console.log('EventPage.didMount, params', props.router.params);
      this.isMobile = props.context.state.isMobile;
      this.filterVM = new FilterViewModel({
        getList: this.getList
      });

      const { state } = props.router.location;
      if (state) {
        if (state.filter) {
          console.log('state.filter', state.filter);
          this.setFilter(state.filter);
        }
      }

      const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      this.pages[this.currentTab] = IS.numeric(values.page) ? values.page : 1;
      this.sort = Object.values(SORT).includes(values.sort) ? values.sort : SORT.Id;

      this.setFilterTime();
      await this.getList(true);
    };

  @computed
  get listFilter() {
    return {
      startAt: this.startAt,
      endAt: this.endAt,
      ...this.filterVM.listFilter
    };
  }

  @computed
  get filterCount() {
    return this.filterVM.filterCount;
  }

  @action
    getList = async (isInit = false) => {
      this.resetUrlByPageAndSort();
      const params = {
        limit: this.limit,
        page: this.page,
        sort: this.sort,
        order: this.order
      };

      this.isFetching = true;

      try {
        const { list, count, page } = await EventService.getList(params, this.listFilter);

        if (isInit && page > Math.ceil(count / this.limit)) {
          await this.onPagingChange(1);
          return;
        }

        runInAction(() => {
          this.lists[this.currentTab] = list;
          this.counts[this.currentTab] = count;
          this.pages[this.currentTab] = page;
        });
      } catch (error) {
        if (error.response?.status === 401) { // when token is expired
          ErrorService.onCustomError(
            t('general_error_content_401'),
            null,
            () => this.props.router.navigate(0)
          );
        } else {
          ErrorService.onDefaultError(error);
        }
      } finally {
        runInAction(() => {
          this.isFetching = false;
        });
      }
    };

  @action
    onPagingChange = async (page) => {
      this.pages[this.currentTab] = page;
      await this.getList();
      window.scrollTo({
        top: 120,
        behavior: 'smooth'
      });
    };

  @action
    onChange = (field, value) => {
      this[`${field}`] = value;
    };

  @action
    switchTab = async (value) => {
      if (window.scrollY > 120) {
        window.scrollTo({
          top: 120,
          behavior: 'smooth'
        });
      }

      tabsState.updateEventsTab(value);
      this.setFilterTime();
      await this.getList();
    };

  @action
    setFilter = ({ type, sdgs, regions, keyword }) => {
      this.filterVM.setFilter({ type, sdgs, regions, keyword });
    };

  @action
    setFilterTime = () => {
      switch (this.currentTab) {
        case TABS.Ongoing:
          this.startAt = {
            lte: dayjs().toISOString()
          };
          this.endAt = {
            gte: dayjs().toISOString()

          };
          break;
        case TABS.Incoming:
          this.startAt = {
            gt: dayjs().toISOString()
          };
          this.endAt = {};
          break;
        case TABS.Past:
        default:
          this.startAt = {};
          this.endAt = {
            lt: dayjs().toISOString()
          };
          break;
      }
    };

  @action handleSort = async (sort) => {
    this.sort = sort;
    this.order = 'desc';
    this.pages = {
      [TABS.Ongoing]: 1,
      [TABS.Incoming]: 1,
      [TABS.Past]: 1
    };
    await this.getList();
  };

  sendGAEvent(eventName) {
    switch (eventName) {
      case 'FindEvent_Filter':
        window.gtag('event', 'FindEvent_Filter', {
          SDGs: this.listFilter.sdgs ? this.listFilter.sdgs.join(',') : '',
          Locations: this.listFilter.regions ? this.listFilter.regions.join(',') : '',
          Resource_type: this.listFilter.demandTypes ? this.listFilter.demandTypes.join(',') : ''
        });
        break;
      default:
    }
  }

  // ///////////////////////////////////////////
  resetUrlByPageAndSort = () => {
    const { navigate } = this.props.router;
    navigate(`/events?page=${this.page}&sort=${this.sort}`, { replace: true });
  };

  toDetail = (id) => {
    const { navigate } = this.props.router;
    navigate(`/events/${id}`, { state: { page: this.page, sort: this.sort } });
  };
}

export default EventPageViewModel;
