import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { EVENT_TYPE, RESOURCE_TYPES } from 'src/constants';
import Dropdown from 'antd/es/dropdown';
import ConfigProvider from 'antd/lib/config-provider';
import Icon from 'src/components/Icon';
import Button from 'src/components/Button';
import BaseModal from 'src/components/Modals/BaseModal';
import IconButton from '@mui/material/IconButton';
import Input from 'antd/es/input';

import iconFilter from 'src/assets/iconFilter.svg';
import iconFilterOn from 'src/assets/iconFilterOn.svg';
import iconSort from 'src/assets/iconSort.svg';
import iconSearch from 'src/assets/iconSearch.svg';
import iconResourceService from 'src/assets/resourceService.svg';
import iconResourceFunds from 'src/assets/resourceMoney.svg';
import iconResourceSpace from 'src/assets/resourceSpace.svg';

import TreeSelect from '../TreeSelect';
import styles from './styles.module.scss';

const resourceIcons = {
  service: iconResourceService,
  funds: iconResourceFunds,
  space: iconResourceSpace
};

@observer
class EventFilter extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.vm = this.props.vm;

  }

  renderFilterModal() {
    return (
      <BaseModal
        onClose={this.vm.toggleModal}
        open={this.vm.isModalOpen}
        isMobile={this.vm.isMobile}
        centered
        height={420}
        showCloseIcon
      >
        <div className={styles.filterModal}>
          <div className={clsx(styles.flexRow, styles.top)}>
            <Icon
              size={24}
              svg
              style={{ filter: 'invert(1)' }}
            >
              {iconFilter}
            </Icon>
            <div className={styles.title}>Filter by</div>
          </div>
          <div className={clsx(styles.flexRow, styles.demandFilter)}>
            <div className={styles.subtitle}>
              {t('resources_demand')}
            </div>
            <div className={clsx(styles.flexRow, styles.resourceTypes)}>
              {Object.values(RESOURCE_TYPES).map((type) => (
                <div
                  key={type}
                  className={clsx(styles.resourceType, this.vm.resourceTypes.includes(type) && styles.selected)}
                  onClick={() => this.vm.handleResourceTypeChange(type)}
                >
                  {t(`resources_${type}`)}
                  <Icon
                    size={24}
                    svg
                    style={!this.vm.resourceTypes.includes(type) && { filter: 'invert(1)', opacity: '0.8' }}
                  >
                    {resourceIcons[type]}
                  </Icon>
                </div>
              ))}
            </div>
          </div>
          <Observer>
            {() => (
              <div>
                <TreeSelect
                  placeholder={t('filter_regions')}
                  type="regions"
                  vm={this.vm.regionsSelectViewModel}
                />
                <div style={{ height: 20 }} />
                <TreeSelect
                  placeholder="SDGs"
                  type="sdgs"
                  vm={this.vm.sdgsSelectViewModel}
                />

                <div className={clsx(styles.flexRow, styles.buttons)}>
                  <Button
                    type="default"
                    className={styles.resetBtn}
                    onClick={() => this.vm.resetFilter()}
                  >
                    {t('clear_filter')}
                  </Button>

                  <Button
                    className={styles.applyBtn}
                    onClick={this.vm.applyFilter}
                    withRightArrow
                  >
                    {t('apply_filter')}
                  </Button>
                </div>
              </div>
            )}
          </Observer>
        </div>
      </BaseModal>
    );
  }

  draftSort = [
    {
      key: '1',
      label: (
        <div
          onClick={() => this.vm.handleTimeSort('desc')}
        >
          {t('sort_create_desc')}
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div
          onClick={() => this.vm.handleTimeSort('asc')}
        >
          {t('sort_create_asc')}
        </div>
      )
    }
  ];

  eventSort = [
    {
      key: '1',
      label: (
        <div
          onClick={() => this.vm.handleSort('popularity')}
        >
          {t('sort_popular')}
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div
          onClick={() => this.vm.handleSort('id')}
        >
          {t('sort_new')}
        </div>
      )
    }
  ];


  renderFilterArea() {
    const { hideFilter, className, containerClassName, placeholder, color, sortMenuItems } = this.props;

    return (
      <div className={clsx(styles.filterArea, className)}>
        <div className={clsx(styles.container, containerClassName)}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#414EE1',
                colorPrimaryHover: '#414EE1',
                colorPrimaryTextHover: '#333'
              }
            }}
          >
            <Input.Search
              placeholder={placeholder || t('search_title_keyword')}
              className="searchInput"
              onChange={this.vm.onKeywordChange}
              onSearch={this.vm.onSearchKeyword}
              value={this.vm.filter.keyword}
              size="large"
              prefix={(
                <Icon
                  size={24}
                  svg
                  style={{ marginRight: 5 }}
                >
                  {iconSearch}
                </Icon>
              )}
              suffix={this.vm.filter.keyword && (
                <IconButton
                  aria-label="clear search input"
                  onClick={() => {
                    this.vm.onKeywordChange({ target: { value: '' } });
                    this.vm.getList();
                  }}
                  sx={{
                    width: 12,
                    height: 12,
                    fontSize: 12
                  }}
                >
                  x
                </IconButton>
              )}
            />
          </ConfigProvider>

          {!hideFilter && (
            <div className={styles.filterIconContainer}>
              <Icon
                size={24}
                svg
                onClick={this.vm.toggleModal}
                className={color !== 'white' ? styles.invertIcon : ''}
              >
                {this.vm.hasAppliedFilter ? iconFilterOn : iconFilter}
              </Icon>
              {this.vm.hasAppliedFilter && (
                <span className={styles.filterCount}>
                  {this.vm.filterCount}
                </span>
              )}
            </div>
          )}

          <Dropdown
            overlayClassName="sortDropdown"
            menu={{
              items: sortMenuItems || (this.vm.type === EVENT_TYPE.Sample ? this.eventSort : this.draftSort)
            }}
          >
            <Icon
              size={24}
              svg
              className={clsx(color !== 'white' && styles.invertIcon, styles.sortIcon)}
            >
              {iconSort}
            </Icon>
          </Dropdown>
        </div>
      </div>
    );
  }

  render() {
    const { hideFilter } = this.props;
    return (
      <>
        {this.renderFilterArea()}
        {!hideFilter && this.renderFilterModal()}
      </>
    );
  }
}

export default (withTranslation()(EventFilter));

EventFilter.propTypes = {
  vm: PropTypes.object,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  placeholder: PropTypes.string,
  color: PropTypes.string,
  hideFilter: PropTypes.bool,
  sortMenuItems: PropTypes.array
};

EventFilter.defaultProps = {
  vm: {},
  className: null,
  containerClassName: null,
  placeholder: null,
  color: 'black',
  hideFilter: false,
  sortMenuItems: null
};
