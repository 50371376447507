import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { ORG_REVIEW_TYPES } from 'src/constants';
import Button from 'src/components/Button';
import illustration from 'src/assets/illustrationHand.jpg';
import searching from 'src/assets/illustrationSearching.svg';
import applicationReject from 'src/assets/illustrationReject.svg';
import BaseModal from '../BaseModal';

import styles from './styles.module.scss';

@observer
class OrganizationAuthModal extends React.Component {

  constructor(props) {
    super(props);

    this.props = props;
  }

  renderIllustration() {
    const { status } = this.props;
    let src = illustration;

    switch (status) {
      case ORG_REVIEW_TYPES.Pending:
        src = searching;
        break;
      case ORG_REVIEW_TYPES.Failed:
        src = applicationReject;
        break;
      case ORG_REVIEW_TYPES.Rejected:
        src = applicationReject;
        break;
      default:
        return null;
    }

    return (
      <img
        className={clsx(styles.marginBottom, styles.illustration)}
        src={src}
        alt="organization authentication"
      />
    );
  }

  renderContent() {
    const { status, review } = this.props;
    switch (status) {
      case ORG_REVIEW_TYPES.Pending:
        return (
          <div className={styles.orgAuthContent}>
            <div>
              {t('profile_org_auth_modal_pending_content1')}
              {dayjs(review?.createdAt).format('MM/DD')}
              {t('profile_org_auth_modal_pending_content2')}
            </div>
          </div>
        );
      case ORG_REVIEW_TYPES.Failed:
        return (
          <div className={styles.orgAuthContent}>
            <div>
              {t('profile_org_auth_modal_failed_content1')}
            </div>
            <div className={styles.wordBreak}>
              {review?.reason}
            </div>
            <br />
            <div>
              {t('profile_org_auth_modal_failed_content2')}
            </div>
          </div>
        );
      case ORG_REVIEW_TYPES.Rejected:
        return (
          <div className={styles.orgAuthContent}>
            <div>
              <div>
                {t('profile_org_auth_modal_rejected_content1')}
              </div>
              <div className={styles.wordBreak}>
                {review?.reason}
              </div>
              <br />
              <div>
                {t('profile_org_auth_modal_rejected_content2')}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  renderTitle() {
    const { status } = this.props;
    let title;
    switch (this.props.status) {
      case ORG_REVIEW_TYPES.Pending:
        title = t('profile_org_auth_modal_pending_title');
        break;
      case ORG_REVIEW_TYPES.Failed:
        title = t('profile_org_auth_modal_failed_title');
        break;
      case ORG_REVIEW_TYPES.Rejected:
        title = t('profile_org_auth_modal_rejected_title');
        break;
      case ORG_REVIEW_TYPES.Accepted:
      default:
    }

    return (
      <h2 className={clsx(styles.title, styles.marginBottom)}>
        {title}
      </h2>
    );
  }

  renderButton() {
    const { status, toProfileEditPage, onClose } = this.props;
    let buttonText;
    switch (this.props.status) {
      case ORG_REVIEW_TYPES.Failed:
        buttonText = t('profile_org_auth_modal_failed_button');
        break;
      case ORG_REVIEW_TYPES.Pending:
      case ORG_REVIEW_TYPES.Rejected:
      case ORG_REVIEW_TYPES.Accepted:
        buttonText = t('back');
        break;
      default:
    }

    const handleButtonClick = status === ORG_REVIEW_TYPES.Failed
      ? toProfileEditPage
      : onClose;

    return (
      <Button
        onClick={handleButtonClick}
        className={styles.button}
      >
        {buttonText}
      </Button>
    );
  }

  render() {
    const { onClose, open, isMobile } = this.props;
    return (
      <BaseModal
        onClose={onClose}
        open={open}
        isMobile={isMobile}
        centered
        height={450}
      >
        <div className={styles.modal}>
          {this.renderIllustration()}

          {this.renderTitle()}

          {this.renderContent()}

          <div style={{ flexGrow: 1 }} />

          {this.renderButton()}
        </div>
      </BaseModal>
    );
  }
}

OrganizationAuthModal.propTypes = {
  status: PropTypes.string,
  toProfileEditPage: PropTypes.func,
  review: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isMobile: PropTypes.bool
};

OrganizationAuthModal.defaultProps = {
  status: '',
  toProfileEditPage: () => {},
  review: {},
  onClose: () => {},
  open: false,
  isMobile: false
};

export default OrganizationAuthModal;
