import {
  makeObservable,
  observable,
  action
} from 'mobx';
import qs from 'qs';

class PretendTo {
    @observable id = undefined;

    constructor(props) {
      this.props = props;
      makeObservable(this);
    }

    @action updateId = (id) => {
      this.id = id;
      console.log('pretendTo', this.id);
    };

    @action checkAndUpdateId = () => {
      const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const newId = values?.pretendTo;
      if (newId) {
        console.log('check and update pretendTo id', newId);
        this.id = newId;
      }
    };
}

const pretendTo = new PretendTo();
export default pretendTo;


