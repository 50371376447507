import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Waypoint } from 'react-waypoint';
import { RESOURCE_TYPES } from 'src/constants';

import Breadcrumb from 'src/components/Breadcrumb';
import MainLayout from 'src/components/MainLayout';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ApplicationCard from 'src/components/ApplicationCard';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import BaseModal from 'src/components/Modals/BaseModal';
import Dropdown from 'antd/es/dropdown';
import Spin from 'antd/es/spin';
import iconResourceService from 'src/assets/advocateAvatar.svg';
import iconResourceFunds from 'src/assets/resourceMoneyFill.svg';
import iconResourceSpace from 'src/assets/resourceSpaceFill.svg';
import purpleCheck from 'src/assets/purpleCheck.svg';
import eyeEmoji from 'src/assets/eyeEmoji.png';
import iconFilter from 'src/assets/iconFilter.svg';
import iconFilterOn from 'src/assets/iconFilterOnFill.svg';
import iconSort from 'src/assets/iconSort.svg';
import iconDownload from 'src/assets/iconDownload.svg';

import ConstantsStore from 'src/stores/constants';
import EventApplicationsPageViewModel from './vm';

import styles from './styles.module.scss';

const demandIcons = {
  service: iconResourceService,
  funds: iconResourceFunds,
  space: iconResourceSpace
};

@observer
class EventApplicationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new EventApplicationsPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderDemandContent() {
    const demand = this.vm.demand;
    const meta = demand.meta;

    const bottom = () => (
      <div className={clsx(styles.flexRow, styles.demandBottom)}>
        <div className={clsx(styles.number, styles.flexbasis60)}>{this.vm.list?.length}</div>
        <div className={styles.separator}>|</div>
        <div className={clsx(styles.flexRow, styles.flexbasis60)}>
          <img
            alt="check"
            src={purpleCheck}
            className={clsx(styles.check, !this.vm.acceptedCount && styles.grayFilter)}
          />
          <div className={clsx(styles.number, this.vm.acceptedCount && styles.numberActive)}>
            {this.vm.list?.filter((item) => item.acceptedAt).length}
          </div>
          <div className={clsx(styles.slash)}>/</div>
          <div className={styles.number}>
            {this.vm.demand.type === RESOURCE_TYPES.Service ? this.vm.demand.meta.count : '∞'}
          </div>
        </div>
      </div>
    );

    switch (this.vm.type) {
      case RESOURCE_TYPES.Service:
        return (
          <>
            <img
              className={clsx(styles.demandIcon, styles.service)}
              src={demandIcons[demand.type]}
              alt="demand"
            />
            <div className={styles.space} />
            <div className={styles.tag}>
              {ConstantsStore.getServiceTypeTag(meta.type)}
            </div>
            <div className={styles.title}>
              {demand.name}
            </div>
            <hr className="dashedPurple" style={{ margin: '15px 0' }} />
            {bottom()}
          </>
        );

      case RESOURCE_TYPES.Funds:
        return (
          <>
            <div className={clsx(styles.demandIcon, styles.other)}>
              <img
                src={demandIcons[demand.type]}
                alt="demand"
              />
            </div>
            <div className={styles.space} />
            <div className={styles.tag}>
              <span className={styles.mark}>$</span>
              {meta.quota}
            </div>
            <div className={styles.title}>
              {demand.name}
            </div>
            <hr className="dashedPurple" style={{ margin: '15px 0' }} />
            {bottom()}
          </>
        );

      case RESOURCE_TYPES.Space:
        return (
          <>
            <div className={clsx(styles.demandIcon, styles.other)}>
              <img
                src={demandIcons[demand.type]}
                alt="demand"
              />
            </div>
            <div className={styles.space} />
            <div className={styles.tag}>
              {meta.capacity}
              <span>{t('demand_people_unit')}</span>
            </div>
            <div className={styles.title}>
              {demand.name}
            </div>
            <hr className="dashedPurple" style={{ margin: '15px 0' }} />
            {bottom()}
          </>
        );

      default:
        return null;
    }
  }

  renderNoContentHint() {
    if (this.vm.isAwait) {
      return (
        <div className={styles.noContentMessage}>
          <Spin />
        </div>
      );
    }

    const recommendBox = () => (
      <div
        className={styles.box}
        onClick={this.vm.toRecommendedResources}
      >
        <div className={styles.content}>
          <div>
            <span>{t('event_demands_recommended_1')}</span>
          </div>
          <img
            alt="eye"
            src={eyeEmoji}
            className={styles.eye}
          />
        </div>
      </div>
    );

    return (
      <div className={styles.noContentMessage}>
        <div className={styles.oops}>
          {this.vm.hasAppliedFilter ? t('empty_message_title') : t('application_no_content_1')}
        </div>
        {this.vm.hasAppliedFilter
          ? (
            <div className={styles.text}>
              {t('filter_no_result')}
            </div>
          )
          : (
            <>
              {this.event?.startAt !== null && (
              <div className={styles.text}>
                <span>{t('application_no_content_2-1')}</span>
                <span className={styles.day}>{this.vm.startDayToNow}</span>
                <span>{t('application_no_content_2-2')}</span>
              </div>
              )}
              <div className={styles.text}>
                {t('application_no_content_3')}
              </div>
            </>
          )}

        {this.vm.recommendedResourcesCount !== 0 && recommendBox()}
      </div>
    );
  }

  renderFilterModal() {
    return (
      <BaseModal
        onClose={this.vm.toggleModal}
        open={this.vm.isModalOpen}
        isMobile={this.vm.isMobile}
        centered
        width={400}
      >
        <div className={styles.filterModal}>
          <div className={clsx(styles.flexRow, styles.top)}>
            <Icon
              size={24}
              svg
              style={{ filter: 'invert(1)' }}
            >
              {iconFilter}
            </Icon>
            <div className={styles.title}>Filter by</div>
          </div>
          <div className={clsx(styles.flexRow, styles.filterRow)}>
            <div className={styles.subtitle}>
              {t('application_accept_status')}
            </div>
            <div className={clsx(styles.flexRow, styles.optContainer)}>
              <div
                className={clsx(styles.opt, this.vm.filter.isAccepted === null && styles.selected)}
                onClick={() => this.vm.setFilter('isAccepted', null)}
              >
                {t('application_accept_status_all')}
              </div>
              <div
                className={clsx(styles.opt, this.vm.filter.isAccepted && styles.selected)}
                onClick={() => this.vm.setFilter('isAccepted', true)}
              >
                {t('application_accept_status_accepted')}
              </div>
              <div
                className={clsx(styles.opt, this.vm.filter.isAccepted === false && styles.selected)}
                onClick={() => this.vm.setFilter('isAccepted', false)}
              >
                {t('application_accept_status_to_reply')}
              </div>
            </div>
          </div>
          <div className={clsx(styles.flexRow, styles.filterRow)}>
            <div className={styles.subtitle}>
              {t('application_read_status')}
            </div>
            <div className={clsx(styles.flexRow, styles.optContainer)}>
              <div
                className={clsx(styles.opt, this.vm.filter.isReaded === null && styles.selected)}
                onClick={() => this.vm.setFilter('isReaded', null)}
              >
                {t('application_accept_status_all')}
              </div>
              <div
                className={clsx(styles.opt, this.vm.filter.isReaded && styles.selected)}
                onClick={() => this.vm.setFilter('isReaded', true)}
              >
                {t('application_read_status_read')}
              </div>
              <div
                className={clsx(styles.opt, this.vm.filter.isReaded === false && styles.selected)}
                onClick={() => this.vm.setFilter('isReaded', false)}
              >
                {t('application_read_status_unread')}
              </div>
            </div>
          </div>

          <div className={clsx(styles.flexRow, styles.buttons)}>
            <Button
              type="default"
              className={styles.resetBtn}
              onClick={this.vm.resetFilter}
            >
              {t('clear_filter')}
            </Button>

            <Button
              className={styles.applyBtn}
              onClick={this.vm.applyFilter}
              withRightArrow
            >
              {t('apply_filter')}
            </Button>
          </div>
        </div>
      </BaseModal>
    );
  }

  render() {
    return (
      <div className={clsx(styles.eventApplicationsPage, 'page')}>
        <MainLayout
          {...this.props}
          hideHeader
          footerStyle={{ marginBottom: 100 }}
        >
          <Breadcrumb
            routes={[
              { label: t('manage_applications'), path: `/user/event-hosted/${this.vm.eid}/demands` },
              { label: '', path: '' }
            ]}
            right={(
              <div
                className={styles.viewDemandBtn}
                onClick={this.vm.toDemandPage}
              >
                {t('view_demand')}
              </div>
            )}
            colorType="purple"
          />
          <div className={styles.top}>
            <div className={styles.demandContainer}>
              {this.vm.demand && this.renderDemandContent()}
            </div>
          </div>
          <div className={styles.mainContainer}>
            {this.vm.list?.length === 0
              ? this.renderNoContentHint()
              : (
                <div className={styles.list}>
                  {this.vm.list?.map((item) => (
                    <ApplicationCard
                      key={item.id}
                      item={item}
                      type={this.vm.type}
                      onUpdateRegister={this.vm.updateRegister}
                      onToApplicationPage={this.vm.toApplicationPage}
                      onClick={() => this.vm.toResourcePage(item)}
                      isMobile={this.vm.isMobile}
                    />
                  ))}
                </div>
              )}

            {
              this.vm.anchor
                ? (
                  <>
                    {this.vm.isAwait && <Spin style={{ margin: '30px auto 0px' }} />}
                    <Waypoint
                      scrollableAncestor={window}
                      onEnter={this.vm.onScrollEnd}
                    />
                  </>
                )
                : null
            }
          </div>

          {(this.vm.list?.length === 0 && !this.vm.hasAppliedFilter)
            ? (
              <Button
                className={styles.ctaButton}
                onClick={this.vm.toResourceListPage}
                withRightArrow
              >
                {t('application_find_resource')}
              </Button>
            )
            : (
              <div className={styles.filterBox}>
                <div className={styles.filterIconContainer}>
                  <Icon
                    size={24}
                    svg
                    onClick={this.vm.toggleModal}
                    className={!this.vm.hasAppliedFilter && styles.icon}
                  >
                    {this.vm.hasAppliedFilter ? iconFilterOn : iconFilter}
                  </Icon>
                  {this.vm.hasAppliedFilter && (
                    <span className={styles.filterCount}>
                      {this.vm.filterCount}
                    </span>
                  )}
                </div>
                <Dropdown
                  overlayClassName="sortDropdown"
                  placement="top"
                  menu={{
                    items: [
                      {
                        key: 'desc',
                        label: (
                          <div
                            onClick={() => this.vm.handleSort('desc')}
                          >
                            {t('application_sort_desc')}
                          </div>
                        )
                      },
                      {
                        key: 'asc',
                        label: (
                          <div
                            onClick={() => this.vm.handleSort('asc')}
                          >
                            {t('application_sort_asc')}
                          </div>
                        )
                      }
                    ]
                  }}
                >
                  <Icon
                    size={24}
                    svg
                    className={styles.icon}
                  >
                    {iconSort}
                  </Icon>
                </Dropdown>
                <Icon
                  size={24}
                  svg
                  className={styles.download}
                  onClick={this.vm.handleDownload}
                >
                  {iconDownload}
                </Icon>
              </div>
            )}

          {this.renderFilterModal()}

        </MainLayout>
      </div>
    );
  }
}

EventApplicationsPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(EventApplicationsPage)), true);

