import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { TERMS_AND_POLICY_TABS } from 'src/constants';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import TreeSelect from 'src/components/TreeSelect';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import styles from '../../styles.module.scss';

@observer
class Step1 extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('register_personal_step1_title')}
          </h2>

          <h3 className={styles.subtitle} style={{ marginBottom: 20 }}>
            {t('register_personal_step1_subtitle')}
          </h3>
        </div>

        <div className={styles.inputGroup}>

          <div className={clsx(styles.flexCol, styles.inputs, styles.inputs1)}>
            <TextInput
              label={t('register_personal_step1_name')}
              className={styles.marginBottom}
              onChange={(e) => this.vm.onChange('name', e.target.value)}
              defaultValue={this.vm.name}
              required
            />

            <TreeSelect
              key="job"
              className={clsx(styles.marginBottom)}
              placeholder={t('register_personal_step1_job')}
              type="jobs"
              vm={this.vm.jobsSelectViewModel}
              value={this.vm.jobsSelectViewModel?.selectedItems}
              required
            />
            <div className={styles.marginBottom} />

            <TextInput
              label={t('register_personal_step1_company')}
              className={styles.marginBottom}
              onChange={(e) => this.vm.onChange('company', e.target.value)}
              defaultValue={this.vm.company}
              required
            />
            <TextInput
              label={t('register_personal_step1_jobTitle')}
              className={styles.marginBottom}
              onChange={(e) => this.vm.onChange('jobTitle', e.target.value)}
              defaultValue={this.vm.jobTitle}
              required
            />
          </div>

          <div className={clsx(styles.flexCol, styles.inputs)}>
            <TextInput
              placeholder={t('register_personal_step1_email')}
              label={!this.vm.email ? null : t('register_organization_step1_email_label')}
              onChange={(e) => this.vm.onChange('email', e.target.value)}
              onBlur={this.vm.onCheckEmail}
              defaultValue={this.vm.email}
              required
              error={this.vm.emailError}
            />
            <div className={styles.errorMessage}>
              {this.vm.emailError ? this.vm.emailError : ''}
            </div>
            <TextInput
              placeholder={t('set_password_placeholder')}
              label={!this.vm.password ? null : t('register_organization_step1_password_label')}
              type="password"
              onChange={(e) => this.vm.onChange('password', e.target.value)}
              onBlur={this.vm.onCheckPassword}
              required
              error={this.vm.passwordError}
            />
            <div className={styles.errorMessage}>
              {this.vm.passwordError ? this.vm.passwordError : ''}
            </div>
            <TextInput
              placeholder={t('register_personal_step1_passwordConfirm')}
              label={!this.vm.passwordConfirm ? null : t('register_organization_step1_passwordConfirm_label')}
              type="password"
              onChange={(e) => this.vm.onChange('passwordConfirm', e.target.value)}
              onBlur={this.vm.onCheckPasswordConfirm}
              required
              error={this.vm.passwordConfirmError}
            />
            <div className={styles.errorMessage}>
              {this.vm.passwordConfirmError ? this.vm.passwordConfirmError : ''}
            </div>
          </div>

        </div>

        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.onPersonalSignUp}
            style={{ width: '100%', maxWidth: 335 }}
            disabled={this.vm.isButtonDisabled}
            className={styles.buttonPosition}
            loading={this.vm.isLoading}
          >
            {t('register_personal_step1_nextButton')}
          </Button>

          <div className={styles.bottomText}>
            <h5>
              <span className={styles.text}>{`${t('register_agreement')} `}</span>
              <Link
                className={styles.link}
                to="/terms-and-policy#terms"
                state={{ tab: TERMS_AND_POLICY_TABS.Terms }}
                target="_blank"
              >
                {t('terms_of_use')}
              </Link>
              <span>{` ${t('and')} `}</span>
              <Link
                className={styles.link}
                to="/terms-and-policy#policy"
                state={{ tab: TERMS_AND_POLICY_TABS.Policy }}
                target="_blank"
              >
                {t('privacy_poliicy')}
              </Link>
            </h5>
          </div>
        </div>

      </>
    );
  }
}

Step1.propTypes = {
  vm: PropTypes.object
};

Step1.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Step1)), false);
