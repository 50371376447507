import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ConstantsStore from 'src/stores/constants';
import ChildNodeViewModel from './components/ChildNode/vm';
import ParentNodeViewModel from './components/ParentNode/vm';

class TreeSelectViewModel {
    @observable open = false;
    @observable searchText = '';
    @observable data = [];
    @observable appliedData = [];

    constructor(props) {
      makeObservable(this);
      this.didMount(props);
    }

    @computed get isEmpty() {
      return this.selectedItems.length === 0;
    }

    @computed get selectedItems() {
      const list = [];
      this.appliedData.forEach((el) => {
        if (el.children) {
          list.push(...el.selectedItems);
        } else if (el.checked) {
          list.push(el);
        }
      });
      return list;
    }

    @computed get selectedValue() {
      return this.selectedItems?.map((el) => el.value) ?? [];
    }

    @computed get groupedItems() {
      const groupedData = {};
      this.selectedItems.forEach((el) => {
        if (!groupedData[el.parentLabel]) {
          groupedData[el.parentLabel] = [];
        }
        groupedData[el.parentLabel].push(el);
      });
      return groupedData;
    }

    @action didMount = (props) => {
      this.data = props.map((el) => {
        if (el.children) {
          return new ParentNodeViewModel(el);
        }
        return new ChildNodeViewModel(el, '');
      });
    };

    @action handleClick = (e) => {
      if (e) { // triggered by UI
        e.stopPropagation();
      }
      this.open = !this.open;
    };

    @action onInputChange = (ev) => {
      this.searchText = ev.target.value;
    };

    @action selectAll = () => {
      this.data.forEach((el) => {
        el.toggleChecked(true);
      });
    };

    @action clearAll = () => {
      this.data.forEach((el) => {
        el.toggleChecked(false);
      });
    };

    @action applyFilter = () => {
      this.appliedData = this.data.map((el) => {
        if (el.children) {
          return new ParentNodeViewModel(el);
        }
        return new ChildNodeViewModel(el, '');
      });
      this.handleClick();
    };

    @action resetAll = () => {
      this.clearAll();
      this.appliedData = [];
    };

    @action initCheckState = (arr) => {
      this.data.forEach((el) => {
        if (el.children) {
          el.initCheckState(arr);
        }
        el.initCheckState(arr);
      });

      this.appliedData = this.data.map((el) => {
        if (el.children) {
          return new ParentNodeViewModel(el);
        }
        return new ChildNodeViewModel(el, '');
      });
    };

    getSdgTypeKey = (label) => {
      return ConstantsStore.getSdgTypeKey(label);
    };

}

export default TreeSelectViewModel;



