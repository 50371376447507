import API from 'src/apis';
import Topic from 'src/models/response/Topic';

export default class TopicService {
  static async getList(params) {
    const { data } = await API.topic.getList(params);
    return Topic.fromRes(data);
  }

}
