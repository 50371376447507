import Jobs from 'src/constants/gens/Jobs.gen';
import SDGs from 'src/constants/gens/SDGs.gen';
import ServiceTypes from 'src/constants/gens/ServiceTypes.gen';
import store from 'src/stores/constants';

export default class Service {
  constructor(params) {
    const {
      type,
      jobs,
      sdgs,
      regions,
      certs,
      creations,
      seniority,
      photos
    } = params ?? {};

    this.certs = certs ?? [];
    this.creations = creations ?? [];
    this.seniority = seniority;
    this.photos = photos ?? [];
    this.jobs = jobs ?? [];
    this.sdgs = sdgs ?? [];
    this.regions = regions ?? [];
    this.type = type;
  }

  get convertedJobs() {
    return this.jobs.map((el) => {
      return Jobs[store.language][el] ?? el;
    });
  }

  get convertedSdgs() {
    const Obj = this.sdgs.reduce((acc, val) => {
      const [key, value] = val.split('-');
      if (acc[key]) {
        acc[key].push(val);
      } else {
        acc[key] = [val];
      }
      return acc;
    }, {});

    const list = [];
    Object.entries(Obj).forEach((el) => {
      if (Object.keys(SDGs[store.language][el[0]].childs).length === el[1].length) {
        return list.push(el[0]);
      }
      return list.push(...el[1]);
    });

    return list;
  }

  get convertedRegions() {
    return store.getCombinedRegionsValuesArr(this.regions);
  }

  get convertedType() {
    const arr = this.type?.split('-');
    return this.type ? ServiceTypes[store.language][arr[0]]?.childs[this.type] : this.type;
  }

  get convertedSeniority() {
    const item = store.seniorityOptions.find((el) => el.value === this.seniority);
    return item ? item.label : '-';
  }

  static fromRes(data) {
    return new Service(data);
  }
}
