import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import IS from 'src/utils/is';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import i18n from 'src/i18n';
import MainLayout from 'src/components/MainLayout';
import Button from 'src/components/Button';
import ImageCarousel from 'src/components/ImageCarousel';
import Icon from 'src/components/Icon';
import blueTick from 'src/assets/blueTick.png';
import centerIcon from 'src/assets/center.png';
import addressIcon from 'src/assets/address.png';
import serviceIcon from 'src/assets/resourceServiceFill.svg';
import fundsIcon from 'src/assets/resourceMoneyFill.svg';
import spaceIcon from 'src/assets/resourceSpaceFill.svg';
import arrowIcon from 'src/assets/arrow.png';
import iconBack from 'src/assets/iconHeaderBack.svg';
import { FILTERS_TYPES, RESOURCE_TYPES } from 'src/constants';
import ResourceAddModal from 'src/components/Modals/ResourceAddModal';
import fakeAvatar from 'src/assets/fakeAvatar.png';
import Breadcrumb from 'src/components/Breadcrumb';

import artImg from 'src/assets/Resource/art.svg';
import businessImg from 'src/assets/Resource/business.svg';
import designImg from 'src/assets/Resource/design.svg';
import policyImg from 'src/assets/Resource/policy.svg';
import publicImg from 'src/assets/Resource/public.svg';
import manufactureImg from 'src/assets/Resource/manufacture.svg';

import placeholder from 'src/assets/Resource/funds.svg';

import ConstantsStore from 'src/stores/constants';
import Usages from 'src/constants/gens/Usages.gen';
import Jobs from 'src/constants/gens/Jobs.gen';
import FundsTypes from 'src/constants/gens/FundsTypes.gen';


import ResourceDetailPageViewModel from './vm';
import styles from './styles.module.scss';
import InviteModal from './components/InviteModal';
import ResourceSuccess from '../ResourceSuccess';


const resourceIcons = {
  service: serviceIcon,
  funds: fundsIcon,
  space: spaceIcon
};


const convertedFundsTypes = (el) => {
  return FundsTypes[ConstantsStore.language][el] ?? '-';
};

const convertedJobs = (el) => {
  return Jobs[ConstantsStore.language][el] ?? '-';
};

const convertedUsages = (el) => {
  return Usages[ConstantsStore.language][el] ?? '-';
};



@observer
class ResourceDetailPage extends React.Component {

  descRef = React.createRef();

  constructor(props) {
    super(props);

    this.vm = new ResourceDetailPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
    this.vm.checkIsTextClamped(this.descRef, 'Desc');
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
    this.vm.checkIsTextClamped(this.descRef, 'Desc'); // call here prevent more missing
  }

  componentWillUnmount() {
    this.vm.willUnmount(this.props);
  }

  renderTag() {
    switch (this.vm.detail?.type) {
      case RESOURCE_TYPES.Service:
        return this.vm.detail?.meta?.convertedType?.tag;
      case RESOURCE_TYPES.Space:
        return `${this.vm.detail?.meta?.capacity} ${i18n.t('people')}`;
      case RESOURCE_TYPES.Funds:
      default:
        return `$ ${this.vm.detail?.meta?.displayQuota}`;
    }
  }

  renderImg() {
    if (this.vm.detail?.type === RESOURCE_TYPES.Funds) {
      return <img src={placeholder} className={styles.defaultImg} alt="" />;
    }
    if (this.vm.detail?.type === RESOURCE_TYPES.Service) {
      switch (this.vm.detail?.meta?.convertedType?.tag) {
        case '設計行銷':
          return <img src={artImg} className={styles.defaultImg} alt="" />;
        case '技術開發':
          return <img src={designImg} className={styles.defaultImg} alt="" />;
        case '商業營運':
          return <img src={businessImg} className={styles.defaultImg} alt="" />;
        case '政府治理':
          return <img src={policyImg} className={styles.defaultImg} alt="" />;
        case '大眾服務':
          return <img src={publicImg} className={styles.defaultImg} alt="" />;
        case '生產製造':
        default:
          return <img src={manufactureImg} className={styles.defaultImg} alt="" />;
      }
    }
    return null;
  }

  renderSubInfo() {
    return (
      <div className={styles.infoContainer}>

        {
          this.vm.detail?.type === RESOURCE_TYPES.Space && (
            <div className={clsx(styles.info, styles.subInfo)}>
              <div className={styles.title}>
                {i18n.t('myresource_detail_location')}
              </div>
              <div className={styles.content}>
                {this.vm.detail?.meta?.convertedLocation}
              </div>
              <div className={styles.separator} />
              <div className={styles.title}>
                {i18n.t('myresource_detail_size')}
              </div>
              <div className={styles.content}>
                {`${this.vm.detail?.meta?.size}坪`}
              </div>
            </div>
          )
        }

        {
          this.vm.detail?.type === RESOURCE_TYPES.Funds && (
            <div className={styles.info}>
              <div className={styles.title}>
                {i18n.t('myresource_detail_funds_type')}
              </div>
              <div className={styles.content}>
                <span
                  className={styles.hashTag}
                  onClick={() => this.vm.toResoursePageWithKey(FILTERS_TYPES.FundsTypes, this.vm.detail?.meta?.type)}
                >
                  {convertedFundsTypes(this.vm.detail?.meta?.type)}
                </span>
              </div>
            </div>
          )
        }

        {
          this.vm.detail?.type === RESOURCE_TYPES.Service && (
            <div className={styles.info}>
              <div className={styles.title}>
                {i18n.t('myresource_detail_service_type')}
              </div>
              <div className={styles.content}>
                {this.vm.detail?.meta?.jobs.map((j, i) => (
                  <span
                    key={j}
                    className={styles.hashTag}
                    onClick={() => this.vm.toResoursePageWithKey(FILTERS_TYPES.Jobs, j)}
                  >
                    {convertedJobs(j)}
                    {i < this.vm.detail.meta.jobs.length - 1 && (
                      <span className={styles.dot}>•</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          )
        }

        <div className={styles.info}>
          <div className={styles.title}>
            {i18n.t('myresource_detail_regions')}
          </div>
          <div className={styles.content}>
            {this.vm.detail?.meta?.convertedRegions.map((r, i) => (
              <span
                key={r}
                className={styles.hashTag}
                onClick={() => {
                  const values = ConstantsStore.reverseCombinedRegionValue(r);
                  this.vm.toResoursePageWithKey(FILTERS_TYPES.Regions, values);
                }}
              >
                {ConstantsStore.getRegionLabelByValue(r)}
                {i < this.vm.detail.meta.convertedRegions.length - 1 && (
                  <span className={styles.dot}>•</span>
                )}
              </span>
            ))}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.title}>
            {i18n.t('myresource_detail_sdgs')}
          </div>
          <div className={clsx(styles.flexRow, styles.content, styles.flexWrap)}>
            {this.vm.detail?.meta?.convertedSdgs.map((j, i) => {
              const arr = j.split('-');
              return (
                <div
                  className={styles.flexRow}
                  key={j}
                  onClick={() => {
                    const values = ConstantsStore.convertedSDGsValues(j);
                    this.vm.toResoursePageWithKey(FILTERS_TYPES.SDGs, values);
                  }}
                >
                  <span className={styles.hashTag}>{ConstantsStore.convertedSDGs(j)}</span>
                  <span className={clsx(`sdgHeart${arr[0]}`)}>♥</span>
                  {i < this.vm.detail.meta.convertedSdgs.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderVision() {
    return (
      <>
        <div className={clsx(styles.sloganTitle)}>
          {i18n.t('myresource_detail_my_visions')}
        </div>
        <div className={clsx(styles.titlePurple)}>
          {this.vm.detail?.claim}
        </div>
        <div
          className={clsx(styles.text, !this.vm.hasClickedDescMore && styles.clamp)}
          ref={this.descRef}
        >
          {this.vm.detail?.expectance}
        </div>

        {this.vm.isDescClamped && !this.vm.hasClickedDescMore && (
          <div
            className={styles.more}
            onClick={this.vm.clickDescMore}
          >
            {i18n.t('myresource_detail_more')}
          </div>
        )}
      </>
    );
  }

  renderAvatar() {
    return (
      <div
        className={clsx(styles.flexRow, styles.userArea, styles.marginTop20)}
        onClick={this.vm.toProfile}
      >
        <div
          style={{ backgroundImage: `url(${this.vm.detail?.user?.avatar ?? fakeAvatar})` }}
          className={styles.avatar}
        >
          {this.vm.detail?.user?.blueTickAt && (
            <img
              src={blueTick}
              alt="bluetick"
              className={styles.blueTick}
            />
          )}
        </div>
        <div className={styles.normalText}>{this.vm.detail?.user?.displayName ?? this.vm.detail?.user?.name}</div>
      </div>
    );
  }

  renderMeta() {
    return (
      <div className={styles.metaContainer}>
        {
          this.vm.detail?.type === RESOURCE_TYPES.Service && (
            <>
              <div className={styles.marginTop20}>
                <div className={styles.text}>{i18n.t('myresource_detail_seniority')}</div>
                <div className={styles.boldText}>
                  {this.vm.detail?.meta?.convertedSeniority}
                </div>
              </div>
              {
                 !!this.vm.detail.meta.creations.length && (
                 <div className={styles.marginTop20}>
                   <div className={styles.text}>{i18n.t('myresource_detail_links')}</div>
                   {this.vm.detail?.meta?.creations.map((el, i) => (
                     <span key={el.name}>
                       <a className={styles.link} key={el.name} href={el.url} target="_blank" rel="noreferrer">
                         {el.name}
                       </a>
                       {i < this.vm.detail.meta.creations.length - 1 && (
                       <span className={styles.x}>•</span>
                       )}
                     </span>
                   ))}
                 </div>
                 )
              }

              {
                !!this.vm.detail.meta.certs.length && (
                  <div className={styles.marginTop20}>
                    <div className={styles.text}>{i18n.t('myresource_detail_certs')}</div>
                    {this.vm.detail?.meta?.certs.map((el, i) => (
                      <span key={el.name}>
                        <a className={styles.link} key={el.name} href={el.url} target="_blank" rel="noreferrer">
                          {el.name}
                        </a>
                        {i < this.vm.detail.meta.certs.length - 1 && (
                        <span className={styles.x}>•</span>
                        )}
                      </span>
                    ))}
                  </div>
                )
              }
            </>
          )
        }

        {
          this.vm.detail?.type === RESOURCE_TYPES.Funds && !!this.vm.detail.meta.links.length && (
            <div className={styles.marginTop20}>
              <div className={styles.text}>{i18n.t('myresource_detail_links')}</div>
              {this.vm.detail?.meta?.links.map((el, i) => (
                <span key={el.name}>
                  <a className={styles.link} key={el.name} href={el.url} target="_blank" rel="noreferrer">
                    {el.name}
                  </a>
                  {i < this.vm.detail.meta.links.length - 1 && (
                  <span className={styles.x}>•</span>
                  )}
                </span>
              ))}
            </div>
          )
        }

        {
          this.vm.detail?.type === RESOURCE_TYPES.Space && (
            <div>
              <div className={styles.marginTop20}>
                <div className={styles.text}>{i18n.t('myresource_detail_usages')}</div>
                {this.vm.detail?.meta?.usages.map((tag, i) => (
                  <span
                    key={tag}
                    className={clsx(styles.boldText, styles.hashTag)}
                    onClick={() => this.vm.toResoursePageWithKey(FILTERS_TYPES.Usages, tag)}
                  >
                    {convertedUsages(tag)}
                    {i < this.vm.detail.meta.usages.length - 1 && (
                      <span className={styles.y}>•</span>
                    )}
                  </span>
                ))}
              </div>
              <div className={styles.marginTop20}>
                <div className={styles.text}>{i18n.t('myresource_detail_equipment')}</div>
                {this.vm.detail?.meta?.convertedEquiments.map((tag, i) => (
                  <span key={tag} className={styles.boldText}>
                    {tag}
                    {i < this.vm.detail.meta.convertedEquiments.length - 1 && (
                      <span className={styles.y}>•</span>
                    )}
                  </span>
                ))}
              </div>
              <div className={styles.marginTop20}>
                <div className={styles.text}>{i18n.t('myresource_detail_opening')}</div>
                {this.vm.detail?.meta?.convertedOpenings.map((tag, i) => (
                  <span key={tag} className={styles.boldText}>
                    {tag}
                    {i < this.vm.detail.meta.convertedOpenings.length - 1 && (
                      <span className={styles.y}>•</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          )
        }

        {this.vm.detail?.description && (
          <div className={styles.marginTop20}>
            <div className={styles.text}>{i18n.t('myresource_detail_more_intro')}</div>
            <div className={styles.normalText}>
              {this.vm.detail?.description}
            </div>
          </div>
        )}

        {
          this.vm.detail?.type === RESOURCE_TYPES.Space && (
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.vm.detail?.meta?.address)}`}
              rel="noreferrer"
            >
              <div
                className={clsx(styles.marginTop20, styles.map)}
              >
                <img src={centerIcon} alt="center" width={66} height={66} />
                <div className={styles.address}>
                  <img src={addressIcon} alt="address" className={styles.addressIcon} />
                  {`${this.vm.detail?.meta?.address}`}
                </div>
              </div>
            </a>
          )
        }
      </div>
    );
  }

  renderContent() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.topBackground} />
        <div className={styles.banner}>

          {
            this.vm.detail?.meta?.photos.length ? (
              <div className={styles.carouselWrapper}>
                <ImageCarousel photos={this.vm.detail?.meta?.photos} />
              </div>
            ) : (
              <div className={styles.imgWrapper}>
                {this.renderImg()}
              </div>
            )
          }
          <div className={styles.tag}>
            {this.renderTag()}
          </div>
        </div>


        <div className={styles.contentBox}>
          <div className={styles.titleAndBlueTick}>
            <div className={styles.title}>
              {this.vm.detail?.name}
            </div>
          </div>

          {/* V3 */}
          <div className={clsx(styles.flexRow, styles.marginTop10)}>
            <img
              src={resourceIcons[this.vm.detail?.type]}
              alt="resource"
              className={styles.icon}
            />
            {/* <div className={styles.separator} />
            <div className={styles.text}>
              已支持
              <span className={styles.boldText} style={{ color: 'black' }}>x</span>
              場行動發生
            </div> */}
          </div>

          {this.renderSubInfo()}
          {this.renderVision()}
          {this.renderAvatar()}
          {this.renderMeta()}
          {/* {this.renderInterest()} */}
        </div>

        <InviteModal
          onToggle={this.vm.toggleModal}
          isOpen={this.vm.isModal}
          isMobile={this.props.context.state.isMobile}
          isPretend={this.vm.isPretend}
          userId={this.vm.profile?.id}
          // list={this.vm.createdEventList}
          onConfirm={this.vm.onModalConfirmed}
          resourceId={this.vm.id}
        />

        <ResourceAddModal
          isOpen={this.vm.isOpen}
          onModalToggle={this.vm.onModalToggle}
          isMobile={this.props.context.state.isMobile}
          vm={this.vm}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={clsx(styles.detailPage, 'page')}>
        <Helmet>
          <title>{ this.vm.detail ? `${this.vm.detail?.name ?? ''} | go action` : 'go action'}</title>
        </Helmet>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          footerStyle={{ marginBottom: 100 }}
          hideHeader={this.vm.isSuccess}
        >
          {
            !this.vm.isSuccess ? (
              <div>
                <Breadcrumb
                  routes={[
                    { label: '', path: '/resources' }
                  ]}
                  right={null}
                  colorType="black"
                  withHeader
                />
                {!IS.empty(toJS(this.vm.detail)) && this.renderContent()}
                <Button
                  className={styles.ctaButton}
                  onClick={this.vm.onCtaPressed}
                  withRightArrow
                >
                  <span className={styles.marginRight3}>{this.vm.buttonStatus}</span>
                </Button>

              </div>
            ) : (
              <div>
                <Breadcrumb
                  right={(
                    <div
                      style={{ marginLeft: 10, cursor: 'pointer' }}
                      onClick={this.vm.closeSuccess}
                    >
                      {i18n.t('back')}
                    </div>
                  )}
                  onBackPress={this.vm.closeSuccess}
                />

                <ResourceSuccess
                  item={this.vm.selectedItem}
                  onBtnClick={this.vm.toResoursePageWithKey}
                />

              </div>
            )
          }
        </MainLayout>

      </div>
    );
  }
}

ResourceDetailPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(ResourceDetailPage)), false);
