import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import withRouter from 'src/components/withRouter';
import decImg from 'src/assets/illustrationCongrats.svg';

import styles from './styles.module.scss';

@observer
class EmailValidateSuccessPage extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className={styles.successContainer}>
        <div className={styles.wrapper}>
          <div className={styles.titleGroup}>
            <h2 className={styles.title}>
              {t('email_validate_success_title')}
            </h2>

            <h3>
              {t('email_validate_success_desc')}
            </h3>
          </div>

          <div className={styles.imgContainer}>
            <img
              src={decImg}
              className={styles.mainImg}
              alt="illustration"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(EmailValidateSuccessPage));
