import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable, runInAction, toJS } from 'mobx';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Navigation } from 'swiper';

import { RESOURCE_TYPES, FILTERS_TYPES } from 'src/constants';
import MainLayout from 'src/components/MainLayout';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import logoPurple from 'src/assets/logoPurple.svg';
import CardCarousel from 'src/components/CardCarousel';
import Tabs from 'src/components/Tabs';
import Button from 'src/components/Button/ButtonWithImg';
import MemberOnlyFeatureModal from 'src/components/Modals/MemberOnlyFeatureModal';

// import image
// import logo from 'src/assets/logo.svg';

import homeServiceTypeTagDesign from 'src/assets/homeServiceTypeTagDesign.svg';
import homeServiceTypeTagBusiness from 'src/assets/homeServiceTypeTagBusiness.svg';
import homeServiceTypeTagDevelopment from 'src/assets/homeServiceTypeTagDevelopment.svg';
import homeServiceTypeTagManufacture from 'src/assets/homeServiceTypeTagManufacture.svg';
import homeServiceTypeTagService from 'src/assets/homeServiceTypeTagService.svg';
import homeServiceTypeTagGovernment from 'src/assets/homeServiceTypeTagGovernment.svg';

import iconArrowRight from 'src/assets/iconCarouselArrowRight.png';
import iconArrowLeft from 'src/assets/iconCarouselArrowLeft.png';

import findEventImg from 'src/assets/imgFindEvent.svg';
import findResourceImg from 'src/assets/imgFindResource.svg';

import RegionBubble from '../../components/Bubble/RegionBubble';
import SDGsBubble from '../../components/Bubble/SDGsBubble';
import HeroImage from './component/HeroImage/index';
import HeroImagePopover from './component/HeroImagePopover';
import HomePageViewModel from './vm';

import styles from './styles.module.scss';

SwiperCore.use([EffectCoverflow, Navigation]);


const serviceTagName = ['Business', 'Design', 'Development', 'Service', 'Government', 'Manufacture'];

const serviceTagImg = {
  Design: homeServiceTypeTagDesign,
  Development: homeServiceTypeTagDevelopment,
  Service: homeServiceTypeTagService,
  Government: homeServiceTypeTagGovernment,
  Manufacture: homeServiceTypeTagManufacture,
  Business: homeServiceTypeTagBusiness
};

const getServiceTypeTagNameForGA = (activeServiceTypeTag) => {
  switch (activeServiceTypeTag) {
    case 'Design':
      return '設計行銷';
    case 'Business':
      return '商業營運';
    case 'Development':
      return '技術開發';
    case 'Service':
      return '大眾服務';
    case 'Government':
      return '政府治理';
    case 'Manufacture':
      return '生產製造';
    default:
      return '';
  }
};

@observer
class HomePage extends React.Component {
  @observable activeTabLabel = 'sdgs'; // sdgs / region
  @observable activeServiceTypeTag = 'Development';
  // @observable hoveredImg = null; // image1 / image2 / image3 / image4
  actionRef = React.createRef();
  resourceRef = React.createRef();

  constructor() {
    super();
    makeObservable(this);
    this.vm = new HomePageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  componentWillUnmount() {
    this.vm.willUnmount(this.props);
  }

  // @action
  //   setHoveredImage = (v) => {
  //     this.hoveredImg = v;
  //   };

  @action
    switchEventTab = (v) => {
      this.activeTabLabel = v;
    };

  @action
    onSliderChange = (swiper) => {
      this.activeServiceTypeTag = serviceTagName[swiper.realIndex];
    };

  handleHeroImageClick = (e, type) => {
    e.stopPropagation();

    switch (type) {
      case 'goToAction': // 4
        // scroll to action section
        if (this.actionRef?.current) {
          this.actionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'addResource': // 2
        this.vm.onClickAddResource();
        break;
      case 'goToResource': // 1
        // scroll to resource section
        if (this.resourceRef?.current) {
          this.resourceRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'addAction': // 4
        this.vm.onClickAddAction();
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className={clsx(styles.homePage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          isMobileShowFooter
        >
          <div className={clsx(styles.mainContainer)}>
            <div className={clsx(styles.homeSection1)}>
              <div className={clsx(styles.heroImgContainer)}>
                <HeroImagePopover
                  number={1}
                  colorMode={2}
                  className={clsx(styles.heroImagePopover, styles.popover1)}
                  onClick={(e) => this.handleHeroImageClick(e, 'goToResource')}
                />
                <HeroImagePopover
                  number={2}
                  colorMode={2}
                  className={clsx(styles.heroImagePopover, styles.popover2)}
                  onClick={(e) => this.handleHeroImageClick(e, 'addResource')}
                />
                <HeroImagePopover
                  number={3}
                  colorMode={1}
                  className={clsx(styles.heroImagePopover, styles.popover3)}
                  onClick={(e) => this.handleHeroImageClick(e, 'addAction')}
                />
                <HeroImagePopover
                  number={4}
                  colorMode={1}
                  className={clsx(styles.heroImagePopover, styles.popover4)}
                  onClick={(e) => this.handleHeroImageClick(e, 'goToAction')}
                />
                <HeroImage
                  // setHoveredImage={this.setHoveredImage}
                  handleHeroImageClick={this.handleHeroImageClick}
                />
              </div>
            </div>
            <div className={clsx(styles.homeSection2)}>
              <div className={clsx(styles.homeLogoContainer)}>
                <img
                  src={logoPurple}
                  alt="logo"
                  className={styles.logo}
                />
                <h1 className={clsx(styles.catchCopy)}>
                  {t('home_catch_copy')}
                </h1>
              </div>
              {
                this.vm.bannerList.length > 0 && (
                  <>
                    <div className={clsx(styles.latestNewsBackground)} />
                    <div className={clsx(styles.widthConstraintContainer)}>
                      <div className={clsx(styles.latestNewsContainer)}>
                        <div className={clsx(styles.latestNewsTxt)}>{t('home_latest_news')}</div>
                        <div className={clsx(styles.carouselContainer)}>
                          <CardCarousel
                            className={clsx(styles.cardCarousel)}
                            spaceBetween={30}
                            paginationAlignWithImage
                            slideClassName={styles.carouselImageContainer}
                            items={this.vm.bannerList?.map((target) => (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className={clsx(styles.carouselImageContainer)}
                                href={target.link}
                                onClick={() => {
                                  // can not move to vm => won't be fired
                                  window.gtag('event', 'Home_Banner_Click', {
                                    Ad_ID: target.code
                                  });
                                }}
                              >
                                <img src={target.thumbnail} alt={target.description} width="100%" className={styles.imgCarousel} />
                                <div className={clsx(styles.homeCarouselMask)} />
                              </a>
                            ))}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
            <div className={clsx(styles.homeSection3)} ref={this.actionRef}>
              <div className={clsx(styles.introContainer)}>
                <div className={clsx(styles.introTxtContainer)}>
                  <div className={clsx(styles.homeSubTitleContainer)}>
                    <h2 className={clsx(styles.homeSubTitle)}>{t('home_action_title1')}</h2>
                    <h2 className={clsx(styles.homeSubTitle)}>{t('home_action_title2')}</h2>
                  </div>
                  <div>
                    <div className={clsx(styles.homeSubContent)}>{t('home_action_content1')}</div>
                    <div className={clsx(styles.homeSubContent)}>{t('home_action_content2')}</div>
                  </div>
                </div>
                <Button
                  imgStyle={{
                    bottom: 10
                  }}
                  imgSrc={findEventImg}
                  className={clsx(styles.CTAButton, styles.homeActionBtn)}
                  containerClassName={clsx(styles.rowBtn)}
                  onClick={() => {
                    this.vm.sendGAEvent('Home_FindEvent_Click');
                    this.props.router.navigate('/events', {
                      state: {
                        filter: {
                          sdgs: toJS(this.vm.sdgs),
                          regions: toJS(this.vm.cities)
                        }
                      }
                    });

                  }}
                >
                  {t('home_action_CTA')}
                </Button>
              </div>
              <div className={styles.tabsContainer}>
                <Tabs
                  colorMode={2}
                  className={styles.labelContainer}
                  items={[
                    {
                      value: 'sdgs',
                      label: t('home_action_filter_title1'),
                      content: (
                        <div className={clsx(styles.tabContent)}>
                          <div className={clsx(styles.sdgsBubbleContainer)}>
                            <SDGsBubble
                              sdgsDataKey="sdgsForHP"
                              handleToggleSdg={this.vm.handleToggleSdg}
                              sdgs={this.vm.sdgs}
                            />
                          </div>
                        </div>
                      )
                    },
                    {
                      value: 'region',
                      label: t('home_action_filter_title2'),
                      content: (
                        <div className={clsx(styles.tabContent, styles)}>
                          <RegionBubble vm={this.vm} regionDataKey="regionsForHP" />
                        </div>
                      )
                    }
                  ]}
                  onSwitchTab={this.switchEventTab}
                  currentValue={this.activeTabLabel}
                />
                <Button
                  imgStyle={{
                    bottom: 10
                  }}
                  imgSrc={findEventImg}
                  className={clsx(styles.CTAButton, styles.homeActionBtn)}
                  containerClassName={clsx(styles.columnBtn)}
                  onClick={() => {
                    this.vm.sendGAEvent('Home_FindEvent_Click');
                    this.props.router.navigate('/events', {
                      state: {
                        filter: {
                          sdgs: toJS(this.vm.sdgs),
                          regions: toJS(this.vm.cities)
                        }
                      }
                    });

                  }}
                >
                  {t('home_action_CTA')}
                </Button>
              </div>
            </div>
            <div className={clsx(styles.homeSection4)} ref={this.resourceRef}>
              <div className={clsx(styles.widthConstraintContainer)}>
                <div className={clsx(styles.homeResourceCarouselContainer)}>
                  <Swiper
                    className={clsx(styles.homeResourceCarousel, 'homeResourceSwiper')}
                    loop
                    centeredSlides
                    slideToClickedSlide
                    initialSlide={1}
                    slidesPerView={1.4}
                    onSlideChange={this.onSliderChange}
                    spaceBetween={100}
                    effect="coverflow"
                    modules={[Navigation]}
                    navigation={{
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev'
                    }}
                    coverflowEffect={{
                      rotate: 10,
                      stretch: -30,
                      depth: 500,
                      modifier: 1.5,
                      slideShadows: false
                    }}
                    breakpoints={{
                      350: {
                        slidesPerView: 1.4,
                        spaceBetween: 80,
                        coverflowEffect: {
                          rotate: 10,
                          stretch: -30,
                          depth: 500,
                          modifier: 1.5,
                          slideShadows: false
                        }
                      },
                      480: {
                        slidesPerView: 1.5,
                        spaceBetween: 100,
                        coverflowEffect: {
                          rotate: 10,
                          stretch: 10,
                          depth: 250,
                          modifier: 2,
                          slideShadows: false
                        }
                      },
                      540: {
                        slidesPerView: 2.0,
                        spaceBetween: 0,
                        coverflowEffect: {
                          rotate: 10,
                          stretch: -30,
                          depth: 250,
                          modifier: 2,
                          slideShadows: false
                        }
                      },
                      576: {
                        slidesPerView: 2.5,
                        spaceBetween: 100,
                        coverflowEffect: {
                          rotate: 10,
                          stretch: -20,
                          depth: 250,
                          modifier: 1.6,
                          slideShadows: false
                        }
                      },
                      660: {
                        slidesPerView: 2.5,
                        spaceBetween: 200,
                        coverflowEffect: {
                          rotate: 10,
                          stretch: -35,
                          depth: 300,
                          modifier: 1.6,
                          slideShadows: false
                        }
                      },
                      1025: {
                        slidesPerView: 2.3,
                        spaceBetween: 200,
                        coverflowEffect: {
                          rotate: 10,
                          stretch: -30,
                          depth: 250,
                          modifier: 1.5,
                          slideShadows: false
                        }
                      },
                      1200: {
                        slidesPerView: 2.3,
                        spaceBetween: 180,
                        coverflowEffect: {
                          rotate: 10,
                          stretch: -20,
                          depth: 250,
                          modifier: 2,
                          slideShadows: false
                        }
                      },
                      1300: {
                        slidesPerView: 2.3,
                        spaceBetween: 200,
                        coverflowEffect: {
                          rotate: 10,
                          stretch: -20,
                          depth: 300,
                          modifier: 2,
                          slideShadows: false
                        }
                      }
                    }}
                  >
                    {serviceTagName.map((tag, i) => (
                      <SwiperSlide key={tag} className={clsx(styles.homeResourceSwiperSlide)}>
                        <div className={clsx(styles.homeResourceSlide)}>
                          <img src={serviceTagImg[tag]} className={clsx(styles.homeResourceSlideImg)} alt={tag} />
                        </div>
                      </SwiperSlide>
                    ))}
                    <div className="swiper-button-prev">
                      <img src={iconArrowLeft} alt="swiper-button-prev" />
                    </div>
                    <div className="swiper-button-next">
                      <img src={iconArrowRight} alt="swiper-button-next" />
                    </div>
                  </Swiper>
                  <span className={clsx(styles.homeResourceSlideTxt)}>{t(`home_resource_Tag_${this.activeServiceTypeTag}`)}</span>
                  <Button
                    imgSrc={findResourceImg}
                    className={clsx(styles.CTAButton, styles.homeActionBtn)}
                    containerClassName={clsx(styles.columnBtn)}
                    onClick={() => {
                      this.vm.sendGAEvent('Home_FindResource_Click', { skill: getServiceTypeTagNameForGA(this.activeServiceTypeTag) });
                      this.vm.updateServiceTypesByTag(t(`home_resource_Tag_${this.activeServiceTypeTag}`));
                      this.props.router.navigate('/resources', {
                        state: {
                          filter: {
                            [`${FILTERS_TYPES.ServiceTypes}`]: toJS(this.vm.targetServiceTypes)
                          },
                          type: RESOURCE_TYPES.Service
                        }
                      });
                    }}
                  >
                    {t('home_resource_CTA')}
                  </Button>
                </div>
                <div className={clsx(styles.introContainer)}>
                  <div className={clsx(styles.introTxtContainer)}>
                    <div className={clsx(styles.homeSubTitleContainer)}>
                      <h2 className={clsx(styles.homeSubTitle)}>{t('home_resource_title1')}</h2>
                      <h2 className={clsx(styles.homeSubTitle)}>{t('home_resource_title2')}</h2>
                    </div>
                    <div className={clsx(styles.homeSubContent)}>{t('home_resource_content')}</div>
                  </div>
                  <Button
                    imgSrc={findResourceImg}
                    className={clsx(styles.CTAButton, styles.homeActionBtn, styles.rowBtn)}
                    containerClassName={clsx(styles.rowBtn)}
                    onClick={() => {
                      this.vm.sendGAEvent('Home_FindResource_Click', { skill: getServiceTypeTagNameForGA(this.activeServiceTypeTag) });
                      this.vm.updateServiceTypesByTag(t(`home_resource_Tag_${this.activeServiceTypeTag}`));
                      this.props.router.navigate('/resources', {
                        state: {
                          filter: {
                            [`${FILTERS_TYPES.ServiceTypes}`]: toJS(this.vm.targetServiceTypes)
                          },
                          type: RESOURCE_TYPES.Service
                        }
                      });
                    }}
                  >
                    {t('home_resource_CTA')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ flexGrow: 1 }} />

          {this.vm.memberOnlyFeatureModalVM && (
            <MemberOnlyFeatureModal
              vm={this.vm.memberOnlyFeatureModalVM}
              isMobile={this.props.context?.state?.isMobile}
              centered
              showCloseIcon
            />
          )}
        </MainLayout>
      </div>
    );
  }
}

HomePage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  router: PropTypes.object,
  vm: PropTypes.object
};

HomePage.defaultProps = {
  router: {},
  vm: {}
};


export default withProfile(withRouter(withTranslation()(HomePage)), false);
