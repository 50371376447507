import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { t } from 'i18next';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Button from 'src/components/Button';
import ProductCard from 'src/components/ProductCard';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import withErrorInput from 'src/components/withErrorInput';

import CheckoutPageViewModel, { invoiceTypeOptions, itemNameOptions, INVOICE_OPTIONS } from './vm';

import styles from './styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);

@observer
class CheckoutPage extends React.Component {
  constructor() {
    super();

    this.vm = new CheckoutPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderProduct() {
    if (!this.vm.product) {
      return null;
    }
    return (
      <div className={styles.productCardContainer}>
        <ProductCard
          item={this.vm.product}
        />
      </div>
    );
  }
  renderContent() {
    return (
      <div className={styles.content}>
        <section className={styles.formSection}>
          <div className={styles.title}>
            {`/ ${t('buyer_info')} /`}
          </div>
          <ErrorTextInput
            key="name"
            label={t('name')}
            onChange={(e) => this.vm.onChange('name', e.target.value.trim())}
            value={this.vm.name ?? ''}
            required
            showError={this.vm.showError}
            validValue={this.vm.isValid.name}
          />

          <ErrorTextInput
            key="phone"
            label={t('phone_for_contact')}
            onChange={(e) => this.vm.onChange('phone', e.target.value)}
            value={this.vm.phone ?? ''}
            required
            showError={this.vm.showError}
            validValue={this.vm.isValid.phone}
            errorMessage={!this.vm.phone ? t('this_is_required') : t('format_is_wrong')}
          />

          <ErrorTextInput
            key="email"
            label={t('email_for_contact')}
            onChange={(e) => this.vm.onChange('email', e.target.value)}
            value={this.vm.email ?? ''}
            required
            showError={this.vm.showError}
            validValue={this.vm.isValid.email}
            errorMessage={!this.vm.email ? t('this_is_required') : t('format_is_wrong')}
          />
        </section>

        <section className={styles.formSection}>
          <div className={styles.title}>
            {`/ ${t('invoice_info')} /`}
          </div>

          <ErrorSelect
            key="invoice_item_name"
            placeholder={t('invoice_item_name')}
            value={this.vm.itemName}
            onChange={(value) => this.vm.onChange('itemName', value)}
            options={itemNameOptions}
          />

          <ErrorSelect
            key="invoice_type"
            placeholder={t('invoice_type')}
            value={this.vm.invoiceType}
            showSearch
            onChange={(value) => this.vm.onChange('invoiceType', value)}
            options={invoiceTypeOptions}
            showError={this.vm.showError}
            validValue={this.vm.isValid.invoiceType}
            required
          />

          {this.renderCodeInput()}

        </section>

        <section className={styles.formSection}>
          <div className={styles.title}>
            {`/ ${t('purchase_remark')} /`}
          </div>

          <ErrorTextInput
            key="remark"
            label={t('purchase_remark_placeholder')}
            onChange={(e) => this.vm.onChange('remark', e.target.value)}
            value={this.vm.remark ?? ''}
            limit={17}
          />
        </section>
      </div>
    );
  }

  renderCodeInput() {
    switch (this.vm.invoiceType) {
      case INVOICE_OPTIONS.CDC:
      case INVOICE_OPTIONS.MobileBarcode:
        return (
          <ErrorTextInput
            key="code"
            label={t(`${this.vm.invoiceType}_code`)}
            onChange={(e) => this.vm.onChange('code', e.target.value)}
            value={this.vm.code ?? ''}
            required
            showError={this.vm.showError}
            validValue={this.vm.isValid.code}
            errorMessage={this.vm.code ? t('format_is_wrong') : t('this_is_required')}
          />
        );
      case INVOICE_OPTIONS.Tax:
        return (
          <ErrorTextInput
            key="taxId"
            label={t('register_organization_step2_taxId')}
            onChange={(e) => this.vm.onChange('taxId', e.target.value)}
            value={this.vm.taxId ?? ''}
            showError={!this.vm.isValid.taxId && this.vm.showError}
            validValue={this.vm.isValid.taxId}
            errorMessage={this.vm.taxId ? t('format_is_wrong') : t('this_is_required')}
            limit={8}
            hideLimit
            required
          />
        );
      case INVOICE_OPTIONS.LoveCode:
        return (
          <ErrorTextInput
            key="loveCode"
            label={t('label_loveCode')}
            onChange={(e) => this.vm.onChange('loveCode', e.target.value)}
            value={this.vm.loveCode ?? ''}
            showError={!this.vm.isValid.loveCode && this.vm.showError}
            validValue={this.vm.isValid.loveCode}
            errorMessage={t('this_is_required')}
            required
          />
        );
      case INVOICE_OPTIONS.Member:
      default:
        return null;
    }
  }

  render() {

    return (
      <div className={styles.checkoutPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideFooter
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_buy_plan'), path: '/product' },
              { label: t('breadcrumb_plan_purchase'), path: '' }
            ]}
            right={null}
            withHeader
          />
          <div className={styles.mainContainer}>
            {this.renderProduct()}

            {this.renderContent()}

            <div className={clsx(styles.totalAmount)}>
              <div>
                {t('total_amount')}
              </div>

              <div>
                {this.vm.priceForDisplay}
              </div>
            </div>

            <Button
              onClick={this.vm.onConfirm}
              className={styles.button}
              loading={this.vm.isAwait}
            >
              {t('checkout_button')}
            </Button>
          </div>

        </MainLayout>
      </div>
    );
  }
}

CheckoutPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(CheckoutPage)), true);
