import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { TERMS_AND_POLICY_TABS } from 'src/constants';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import DatePicker from 'src/components/DatePicker';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import UploadPhoto from 'src/components/UploadPhoto';
import withErrorInput from 'src/components/withErrorInput';

import ConstantsStore from 'src/stores/constants';

import styles from '../../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);
const ErrorDatePicker = withErrorInput(DatePicker);

@observer
class Step2 extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('register_organization_step2_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('register_organization_step2_subtitle')}
          </h3>
        </div>
        <div className={styles.inputGroup}>
          <div className={clsx(styles.flexCol, styles.inputs, styles.inputs1)}>
            <ErrorTextInput
              key="displayname"
              label={t('register_organization_step2_displayName')}
              onChange={(e) => this.vm.onChange('displayName', e.target.value)}
              value={this.vm.displayName}
              required
            />

            <ErrorTextInput
              key="name"
              label={t('register_organization_step2_name')}
              onChange={(e) => this.vm.onChange('name', e.target.value)}
              value={this.vm.name}
              required
              readOnly={!this.vm.isAllowedToEditAll}
            />

            <ErrorTextInput
              key="taxId"
              showError={this.vm.showError}
              label={t('register_organization_step2_taxId')}
              onChange={(e) => this.vm.onChange('taxId', e.target.value, 8)}
              value={this.vm.taxId ?? ''}
              required
              validValue={this.vm.isTaxIdValid}
              type="number"
              errorMessage={this.vm.taxId ? t('format_is_wrong') : t('this_is_required')}
              readOnly={!this.vm.isAllowedToEditAll}
            />

            <ErrorTextInput
              key="personInCharge"
              showError={this.vm.showError}
              label={t('register_organization_step2_personInCharge')}
              onChange={(e) => this.vm.onPersonInChargeChange('name', e.target.value)}
              value={this.vm.personInCharge?.name}
              required
            />

            <ErrorSelect
              key="industry"
              placeholder={t('register_organization_step2_industry')}
              onChange={(value) => this.vm.onChange('job', value)}
              showSearch
              options={ConstantsStore.jobsOptionsForUser}
              value={this.vm.job}
              required
            />

            <ErrorSelect
              key="type"
              placeholder={t('register_organization_step2_type')}
              onChange={(value) => this.vm.onChange('type', value)}
              options={ConstantsStore.organizationTypesOptions}
              value={this.vm.type}
              required
            />
          </div>

          <div className={clsx(styles.flexCol, styles.inputs, styles.inputs1)}>
            {this.vm.needStaff && this.vm.needCapitalAmount
              ? (
                <div className={clsx(styles.row)}>
                  <ErrorSelect
                    key="staff"
                    placeholder={t('register_organization_step2_staff')}
                    onChange={(value) => this.vm.onChange('staff', value)}
                    options={ConstantsStore.staffOptions}
                    style={{ flexGrow: 1, width: 155, maxWidth: 160, marginRight: 10 }}
                    value={this.vm.staff}
                    required
                  />
                  <ErrorSelect
                    key="capitalAmount"
                    placeholder={t('register_organization_step2_capitalAmount')}
                    onChange={(value) => this.vm.onChange('capitalAmount', value)}
                    options={ConstantsStore.capitalAmount}
                    style={{ flexGrow: 1 }}
                    value={this.vm.capitalAmount}
                    required
                  />
                </div>
              ) : null}
            {this.vm.needStaff && !this.vm.needCapitalAmount && (
              <ErrorSelect
                key="staff"
                placeholder={t('register_organization_step2_staff')}
                onChange={(value) => this.vm.onChange('staff', value)}
                options={ConstantsStore.staffOptions}
                value={this.vm.staff}
                required
              />
            )}
            <div className={clsx(styles.row)}>
              <ErrorDatePicker
                key="foundedIn"
                label={t('register_organization_step2_foundedIn')}
                placeholder={t('register_organization_step2_foundedIn')}
                onChange={(date, dateString) => {
                  this.vm.onChange('foundedIn', dayjs(date).toISOString());
                }}
                style={{ flexGrow: 1, minWidth: 140, marginRight: 10 }}
                value={this.vm.foundedIn ? dayjs(this.vm.foundedIn) : null}
                isMobile={this.props.context.state.isMobile}
                required
                readOnly={!this.vm.isAllowedToEditAll}
              />
              <div style={{ width: 25 }} />
              <ErrorSelect
                key="location"
                placeholder={t('register_organization_step2_location')}
                onChange={(value) => this.vm.onChange('location', value)}
                style={{ flexGrow: 1 }}
                options={ConstantsStore.flattenedRegionsOptions.filter((item) => item.value.slice(0, 1) !== '2')}
                value={this.vm.location}
                required
                readOnly={!this.vm.isAllowedToEditAll}
              />
            </div>

            <ErrorTextInput
              key="address"
              label={t('register_organization_step2_address')}
              onChange={(e) => this.vm.onChange('address', e.target.value)}
              value={this.vm.address}
              required
              readOnly={!this.vm.isAllowedToEditAll}
            />

            {this.vm.uploadCertsVM && (
            <UploadPhoto
              placeholder={t('register_organization_step2_upload_certs')}
              label={t('register_organization_step2_certificate')}
              required
              className={styles.marginBottom}
              vm={this.vm.uploadCertsVM}
              onUploadFileInvalid={() => this.vm.toggleErrorMessageModal(t('profile_image_not_valid_modal_title'))}
              acceptPDF
              multiple
              readOnly={!this.vm.isAllowedToEditAll}
            />
            )}

            {this.vm.uploadLogoVM && (
            <UploadPhoto
              placeholder={t('register_organization_step2_upload_logo')}
              label={t('register_organization_step2_logo')}
              required
              vm={this.vm.uploadLogoVM}
              onUploadFileInvalid={() => this.vm.toggleErrorMessageModal(t('profile_image_not_valid_modal_title'))}
            />
            )}
          </div>
        </div>

        <div className={clsx(styles.bottomContainer)}>
          <Button
            onClick={this.vm.onFinishOrganizationInfo}
            disabled={this.vm.isButtonDisabled}
            loading={this.vm.isLoading}
            className={styles.nextButton}
            withRightArrow
          >
            {t('register_organization_step2_nextButton')}
          </Button>

          <div className={styles.bottomText}>
            <h5>
              <span className={styles.text}>
                {`${t('register_agreement')} `}
              </span>
              <Link
                className={styles.link}
                to="/terms-and-policy#terms"
                state={{ tab: TERMS_AND_POLICY_TABS.Terms }}
                target="_blank"
              >
                {t('terms_of_use')}
              </Link>
              <span>{` ${t('and')} `}</span>
              <Link
                className={styles.link}
                to="/terms-and-policy#policy"
                state={{ tab: TERMS_AND_POLICY_TABS.Policy }}
                target="_blank"
              >
                {t('privacy_poliicy')}
              </Link>
            </h5>
          </div>
        </div>
      </>
    );
  }
}

Step2.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  vm: PropTypes.object
};

Step2.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Step2)), false);
