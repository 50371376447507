import {
  makeObservable, observable, action, computed
} from 'mobx';
import { parseLanguage } from 'src/i18n/locales/util';
import i18n from 'src/i18n';

const othersFieldName = {
  'en-US': 'others',
  'zh-TW': '其他'
};

class ChildNodeViewModel {
  type = 'child';
  @observable checked;
  @observable label;
  @observable idValue;
  @observable inputValue; // only label = others can use this field

  constructor(data, parentLabel) {
    makeObservable(this);

    this.init(data);
    this.parentLabel = parentLabel;
  }

  @computed
  get isFieldNamedOthers() {
    if (othersFieldName[parseLanguage(i18n.language)] === this.label) {
      return true;
    }
    return false;
  }

  @computed
  get shouldUseInputValue() {
    if (this.isFieldNamedOthers) {
      if (this.checked) return true;
    }
    return false;
  }

  @computed
  get value() {
    if (this.inputValue && this.shouldUseInputValue) return `#${this.inputValue}`;
    return this.idValue;
  }


  @action init = (data) => {
    const {
      value,
      label,
      checked
    } = data;

    this.label = label;
    this.checked = checked;
    this.idValue = value;
  };

  @action onChange = () => {
    this.checked = !this.checked;
  };

  @action onInputChange = (e) => {
    this.inputValue = e.target.value;
  };

  @action toggleChecked = (bool) => {
    this.checked = bool;
  };

  @action initCheckState = (arr) => {
    if (arr.includes(this.value)) {
      this.checked = true;
    }

    if (this.isFieldNamedOthers) {
      const inputValue = arr.filter((value) => value[0] === '#')[0];
      if (inputValue) {
        this.inputValue = inputValue?.slice(1);
        this.checked = true;
      }
    }
  };

}

export default ChildNodeViewModel;

