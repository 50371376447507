import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { t } from 'i18next';
import UserService from 'src/services/user';
import { USER_TYPES, LIST_MODEL_TYPE } from 'src/constants';
import ErrorService from 'src/services/errors';
import BelongService from 'src/services/belong';
import AnchoredList from 'src/services/anchoredList';

dayjs.extend(isSameOrBefore);

export const MODAL_TYPE = {
  Add: 'add',
  Edit: 'edit'
};


class BelongVM {
  @observable userId = null;
  @observable identity = USER_TYPES.Personal;
  @observable isLoading = false;

  @observable belongs = [];
  @observable anchoredMemberList;

  // search user to add belong
  @observable isFetching = false;
  @observable userOptions = [];
  @observable searchUserInput = null;
  @observable selectedUser = {};
  @observable showLabelWithIcon = false;
  @observable showBelongError = {
    user: false,
    startAt: false,
    endAt: false
  };

  @observable showBatchDelete = false;
  @observable selectedIds = [];

  // modals
  @observable belongModalType = MODAL_TYPE.Add; // add | edit
  @observable isBelongModalOpen = false;
  @observable generalModalViewModel;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props) => {
    console.log(props);
    this.isLoading = true;
    this.identity = props.identity;
    this.userId = props.userId;
    this.generalModalViewModel = props.generalModalViewModel;

    if (this.identity === USER_TYPES.Organization) {
      this.anchoredMemberList = new AnchoredList({
        modelType: LIST_MODEL_TYPE.Belong,
        path: `v1/user/${this.userId}/members`,
        method: 'get'
      });
      await this.getMembers();
    } else {
      await this.getBelongs();
    }

    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action getBelongs = async () => {
    try {
      const res = await UserService.getDetail();
      runInAction(() => {
        this.belongs = res.belongs;
      });

    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  getMembers = async () => {
    const params = {
      limit: 10
    };

    await this.anchoredMemberList?.getList(null, params);
  };

  @computed get members() {
    return this.anchoredMemberList?.list ?? [];
  }

  @action validateBelongData = () => {
    this.showBelongError.user = false;
    this.showBelongError.startAt = false;
    this.showBelongError.endAt = false;

    if (!this.selectedUser || !this.selectedUser.value) {
      this.showBelongError.user = true;
      return false;
    }

    if (!this.selectedUser.startAt) {
      this.showBelongError.startAt = true;
      return false;
    }

    if (this.selectedUser.endAt && dayjs(this.selectedUser.endAt).isSameOrBefore(this.selectedUser.startAt)) {
      this.showBelongError.endAt = true;
      return false;
    }

    return true;
  };

  @action onAddBelong = async () => {
    console.log('add');
    const isValid = this.validateBelongData();
    if (!isValid) return;

    const data = this.serializeBelong();
    console.log(data);
    try {
      const res = await BelongService.create(data);
      console.log(res);
      this.generalModalViewModel.open({
        title: t('add_success'),
        onConfirm: () => {}
      });

      await this.getBelongs();
      this.toggleBelongModal();
    } catch (error) {
      switch (error.response?.status) {
        case 409:
          ErrorService.onCustomError(t('belongs_conflict'));
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  @action onEditBelong = (item) => {
    console.log('edit', item);
    this.toggleBelongModal(MODAL_TYPE.Edit);
    const owner = item.owner;
    const userToEdit = {
      value: owner.id,
      startAt: item.startAt,
      endAt: item.endAt ?? undefined,
      label: owner.displayName,
      avatar: owner.avatar,
      belongId: item.id
    };
    this.selectedUser = userToEdit;
    this.showLabelWithIcon = true;
  };

  @action onDeleteBelong = async (id) => {
    console.log('delete', id);
    this.generalModalViewModel.open({
      title: t('belongs_delete_confirm'),
      onConfirm: () => this.deleteBelong(id),
      showCancelButton: true,
      buttonText: t('remove'),
      buttonProps: {
        withRightArrow: true
      }
    });
  };

  @action onDeleteMember = async (id) => {
    console.log('delete', id);
    this.generalModalViewModel.open({
      title: t('belongs_member_delete_confirm'),
      onConfirm: () => this.deleteBelong(id),
      showCancelButton: true,
      buttonText: t('remove'),
      buttonProps: {
        withRightArrow: true
      }
    });
  };

  @action onClickBatchDeleteMembers = async () => {
    if (this.selectedIds.length === 0) {
      return;
    }

    this.generalModalViewModel.open({
      title: t('belongs_batch_delete_confirm_title'),
      content: `${t('belongs_batch_delete_confirm_1')} \\#${this.selectedIds.length}\\ ${t('belongs_batch_delete_confirm_2')}`,
      onConfirm: this.batchDeleteMembers,
      showCancelButton: true,
      buttonText: t('remove'),
      buttonProps: {
        withRightArrow: true
      }
    });
  };

  @action onSaveBelong = async () => { // first delete and then re-create
    const isValid = this.validateBelongData();
    if (!isValid) return;

    const data = this.serializeBelong();
    console.log('save', this.selectedUser.belongId, data);
    try {
      await this.deleteBelong(this.selectedUser.belongId);
      const res = await BelongService.create(data);
      console.log(res);
      this.generalModalViewModel.open({
        title: t('save_success'),
        onConfirm: () => {}
      });

      await this.getBelongs();
      this.toggleBelongModal();
    } catch (error) {
      switch (error.response?.status) {
        default:
          ErrorService.onDefaultError(error);
      }
    }

  };

  @action onUserSearch = async (e) => {
    this.showBelongError.user = false;
    this.searchUserInput = e;
    if (e.length > 1) {
      this.isFetching = true;
      await this.getUserOptions(e);
      runInAction(() => {
        this.isFetching = false;
      });
    }
  };

  @action onSelectUser = (value) => {
    this.searchUserInput = value.label?.props['data-name'];
    this.selectedUser = value;
    this.showLabelWithIcon = true;
  };

  @action getUserOptions = async (keyword) => {
    try {
      const res = await UserService.suggest({ keyword, type: 'organization' });
      if (res?.length > 0) {
        const list = res.map((user) => ({
          label: user.displayName,
          value: user.id,
          key: user.id,
          avatar: user.avatar
        }));

        runInAction(() => {
          this.userOptions = list;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action resetUserOptions = () => {
    this.userOptions = [];
  };

  @action onClickSelectedUser = () => {
    this.showLabelWithIcon = false;
  };

  @action onBlurUserSelect = () => {
    if (this.selectedUser.value) {
      this.showLabelWithIcon = true;
    }
  };

  @action onChangeBelongDate = (key, date) => {
    this.showBelongError[key] = false;
    let dayjsObj;
    if (key === 'startAt') {
      dayjsObj = dayjs(date).startOf('month');
    } else if (key === 'endAt') {
      dayjsObj = dayjs(date).endOf('month');
    }

    this.selectedUser[key] = date ? dayjsObj.toISOString() : null;
  };

  deleteBelong = async (id) => {
    try {
      const res = await BelongService.delete(id);
      console.log(res);

      if (this.identity === USER_TYPES.Personal) {
        runInAction(() => {
          this.belongs = this.belongs.filter((item) => item.id !== id);
        });
      } else {
        await this.getMembers();
      }
    } catch (error) {
      ErrorService.onDefaultError(error);
      console.log(error);
    }
  };

  batchDeleteMembers = async () => {
    let res;
    try {
      res = await Promise.allSettled(this.selectedIds.map((id) => BelongService.delete(id)));
    } catch (error) {
      console.log(error);
    } finally {
      console.log(res);
      await this.getMembers();
      runInAction(() => {
        this.selectedIds = this.selectedIds.filter((id) => !!this.members.find((m) => m.id === id));
      });

      const failedUsersString = () => {
        let str = '';
        this.selectedIds.forEach((id, i) => {
          const member = this.members.find((m) => m.id === id);
          if (member) {
            if (str.length !== 0) {
              str += '、';
            }
            str += `${member.member.displayName || member.member.name}`;
          }
        });
        return str;
      };

      if (this.selectedIds?.length !== 0) {
        ErrorService.onCustomErrorAdvanced({
          title: t('belongs_batch_delete_fail_title'),
          content: `\\#${failedUsersString()}\\ ${t('belongs_batch_delete_fail_desc')}`,
          buttonText: t('belongs_batch_delete_fail_button'),
          onClose: () => {},
          buttonProps: {
            withRightArrow: true
          }
        });
      }
    }
  };

  @action onCheckDeleteItem = (e, id) => {
    console.log(e.target.checked, id);
    if (e.target.checked) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds = this.selectedIds.filter((selected) => selected !== id);
    }
    console.log(this.selectedIds);
  };

  @action resetSelectedItems = () => {
    this.selectedIds = [];
  };

  // ///////////////////

  @action onToggleBatchDelete = () => {
    this.showBatchDelete = !this.showBatchDelete;
  };

  @action toggleBelongModal = (type = null) => {
    this.isBelongModalOpen = !this.isBelongModalOpen;
    if (type) {
      this.belongModalType = type;
    } else {
      this.showBelongError.user = false;
      this.showBelongError.startAt = false;
      this.showBelongError.endAt = false;
      this.selectedUser = {};
      this.searchUserInput = null;
    }
  };

  @computed
  get isBelongModalForAdding() {
    return this.belongModalType === MODAL_TYPE.Add;
  }

  // ///////////////////////

  serializeBelong = () => {
    console.log('serialize', this.selectedUser);
    const data = {
      targetId: this.selectedUser.value,
      startAt: dayjs(this.selectedUser.startAt).toISOString(),
      endAt: this.selectedUser.endAt ? dayjs(this.selectedUser.endAt).toISOString() : undefined
    };
    return data;
  };

}

export default BelongVM;
