/* eslint-disable import/no-cycle */
import { makeObservable, observable, action, runInAction, computed, toJS } from 'mobx';
import UploadPhotoVM from 'src/components/UploadPhoto/vm';
import IS from 'src/utils/is';
import ConstantsStore, { KEY_OF_OTHER } from 'src/stores/constants';

class Target {
  @observable description;
  @observable _option;
  @observable _optionOther;
  @observable title;
  @observable photos = [];
  @observable videos = [];

  @observable uploadPhotoVM;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);

    runInAction(() => {
      this.uploadPhotoVM = new UploadPhotoVM({ maxCount: 1, items: toJS(this.photos), type: 'event' });
    });
  }

  @action updatePhotos = (data) => {
    this.photos = data;
  };

  @computed get video() {
    return this.videos[0];
  }

  set video(value) {
    if (value) {
      this.videos = [value];
    } else {
      this.videos = [];
    }
  }

  @computed get isVideoValid() {
    return IS.youtube(this.video) || !this.video;
  }

  @computed get option() {
    return this._option;
  }

  set option(value) {
    if (value !== KEY_OF_OTHER.ServiceTargets) {
      this.optionOther = null;
    }
    this._option = value;
  }

  @computed get optionKey() {
    if (this._optionOther) {
      return KEY_OF_OTHER.ServiceTargets;
    }
    return this._option;
  }

  @computed get optionOther() {
    return this._optionOther;
  }

  set optionOther(value) {
    this._optionOther = value;
  }

  @computed get optionName() {
    if (this._optionOther) {
      return this._optionOther;
    }
    return ConstantsStore.serviceTargetsOptions.find((opt) => opt.value === this._option)?.label ?? '-';
  }

  @computed get isComplete() {
    return !!this.option
      && !!this.title
      && !!this.description;
    // && (this.photos.length !== 0 || !!this.video);
  }

  @computed get isEmpty() {
    return !this.option
      && !this.title
      && !this.description
      && this.photos.length === 0
      && !this.video;
  }

  @computed get isHalfComplete() {
    return !this.isComplete && !this.isEmpty;
  }

  @action onChange = (value, key) => {
    this[key] = value;
  };

  // //////////////////////////

  @action deserialize = (data) => {
    const {
      description,
      option,
      photos,
      title,
      videos
    } = data ?? {};

    this.description = description;
    this._option = option;
    if (IS.customValue(option)) {
      this._optionOther = option.replace('#', '');
    }
    this.photos = photos ?? [];
    this.title = title;
    this.videos = videos ?? [];
  };

  serialize = () => {
    const data = {
      description: this.description,
      option: this._optionOther ? `#${this._optionOther}` : this.option,
      photos: this.photos,
      title: this.title,
      videos: this.videos
    };

    return data;
  };


  static fromRes(data) {
    return new Target(data);
  }
}

export default Target;
