/* eslint-disable */
const FundsTypes = {
  "zh-TW": {
    "1": "政府補助",
    "2": "創業投資",
    "3": "民間支持",
    "4": "競賽獎項",
    "5": "企業捐款"
  },
  "en-US": {
    "1": "政府補助",
    "2": "創業投資",
    "3": "民間支持",
    "4": "競賽獎項",
    "5": "企業捐款"
  }
}

export const FundsTypesOrder = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5
}

export default FundsTypes
