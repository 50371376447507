import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import { t } from 'i18next';
import EventService from 'src/services/event';
import { RESOURCE_TYPES, TABLE_LIMIT } from 'src/constants';
import ErrorService from 'src/services/errors';
import UserService from 'src/services/user';

class RecommendedResourcesPageViewModel {
  @observable eventId;
  @observable userId;
  @observable list = [];
  @observable currentEventIndex;

  @observable isMobile = false;

  // for event carousel
  @observable anchor = null;
  @observable isAwait = false;
  @observable eventList = [];
  @observable initialEvent;

  // for resources
  @observable resourceAnchor = null;
  @observable isResourceAwait = false;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props) => {
    console.log('RecommendedResourcesPage.didMount, params', props.router.params);
    console.log(props);

    await this.initData(props);
  };

  @action didUpdate = async (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile)) {
      await this.initData(props);
    }
  };

  @action initData = async (props) => {
    const { profile, router, context } = props;
    this.eventId = router.params.eid;
    this.isMobile = context.state.isMobile;
    this.userId = profile.id;
    this.filter = router.location?.state?.filter ? JSON.parse(router.location.state.filter) : {};
    await this.getEventDetail();
    await this.getMyEvents();
    await this.getList(true);
  };

  @computed get myEvents() {
    return this.eventList;
  }

  @computed get eventAnchor() {
    return this.eventsAnchoredList.anchor;
  }

  getEventDetail = async () => {
    try {
      const res = await EventService.detail(this.eventId);
      runInAction(() => {
        this.initialEvent = res;
      });

    } catch (error) {
      const { navigate } = this.props.router;
      switch (error.response?.status) {
        case 401:
          ErrorService.onCustomError(
            t('general_error_content_401'),
            null,
            () => navigate(0)
          );
          break;
        case 403:
        case 404:
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  getList = async (init = false, isRecalled = false) => {
    if (this.isResourceAwait) return;
    this.isResourceAwait = true;

    const params = {
      limit: TABLE_LIMIT,
      anchor: this.resourceAnchor
    };

    try {
      const res = await EventService.recommendResourcesList(this.eventId, params);
      console.log(res);
      runInAction(() => {
        if (init) {
          this.list = res.list;
        } else {
          this.list.push(...res.list);
        }
        this.resourceAnchor = res.anchor;
      });
    } catch (error) {
      console.log('Error fetching list', error);
      switch (error?.response?.status) {
        case 403:
        case 404:
          if (!isRecalled) {
            runInAction(() => {
              this.initialEvent = null;
            });
            await this.getMyEvents(true);
            await this.onEventSwiperChange({ realIndex: 0, isEnd: false });
            ErrorService.onCustomError(
              error?.response?.status === 403 ? t('error_create_event_detail_403') : t('error_create_event_detail_404'),
              null,
              () => this.props.router.navigate(0)
            );
          }
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    } finally {
      runInAction(() => {
        this.isResourceAwait = false;
      });
    }
  };

  onScrollEnd = () => {
    if (!this.resourceAnchor) return;
    this.getList();
  };

  @action getMyEvents = async (isFirstFetch = true) => {
    if (this.isAwait) { return; }

    const params = {
      limit: TABLE_LIMIT,
      anchor: isFirstFetch ? null : this.anchor
    };

    this.isAwait = true;

    if (isFirstFetch) {
      if (this.initialEvent) {
        this.eventList = [this.initialEvent]; // the initial event is always at the begining of the list
      } else {
        this.eventList = [];
      }
    }

    try {
      const { count, list, anchor } = await UserService.createdEvents(this.userId, params, this.filter);
      runInAction(() => {
        const filtered = list.filter((item) => item.id !== this.initialEvent?.id);
        this.anchor = anchor;
        this.eventList.push(...filtered);
      });

    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action onEventScrollEnd = async () => {
    if (this.isAwait || !this.anchor) { return; }
    await this.getMyEvents(false);
  };

  @computed
  get resourceTypes() {
    const event = this.myEvents.find((item) => item.id === this.eventId);
    console.log('===', event, event?.demandTypes);
    return event?.demandTypes ?? [];
  }

  toApplications = (did) => {
    const { navigate } = this.props.router;
    navigate(`/user/event-hosted/${this.eventId}/${did}/applications`);
  };

  toResourceDetail = (type, id) => {
    const { navigate } = this.props.router;
    navigate(`/resources/${type}/${id}`);
  };

  @action onEventSwiperChange = async ({ realIndex, isEnd }) => {
    console.log(realIndex, this.myEvents, this.myEvents[0]);
    this.currentEventIndex = realIndex;
    this.eventId = this.myEvents[realIndex]?.id ?? this.eventId;
    this.props.router.navigate(`/user/event-hosted/${this.eventId}/recommend`, { replace: true });
    await this.getList(true, true);

    if (isEnd) {
      await this.onEventScrollEnd();
    }
  };

  @computed
  get hasService() {
    return this.list.some((item) => item.resource.type === RESOURCE_TYPES.Service);
  }

  @computed
  get hasFunds() {
    return this.list.some((item) => item.resource.type === RESOURCE_TYPES.Funds);
  }

  @computed
  get hasSpace() {
    return this.list.some((item) => item.resource.type === RESOURCE_TYPES.Space);
  }

}

export default RecommendedResourcesPageViewModel;
