export const delay = (ms) => new Promise((r) => { setTimeout(r, ms); });

/**
 *
 * @param {string} value
 * @param {(string|RegExp)[]} patterns
 * @returns {boolean}
 */
export const some = (value, patterns = []) => {
  return patterns.some((p) => {
    const reg = Object.prototype.toString.call(value) === '[object String]' ? new RegExp(p) : p;
    return reg.test(value);
  });
};

/**
 * array 1D to 2D
 * @param {any[]} arr
 * @param {number} size group size
 * @returns
 */
export const group = (arr, size) => Array.from(
  { length: Math.ceil(arr.length / size) },
  (_, idx) => arr.slice(idx * size, (idx + 1) * size)
);

export const handleLineBreaks = (str) => {
  if (str?.includes('\\n')) {
    const arr = str.split('\\n');
    return arr.map((s, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={i}>
        {s}
        {i !== arr.length - 1 && <br />}
      </span>
    ));
  }

  if (str?.includes('\n')) {
    const arr = str.split('\n');
    return arr.map((s, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={i}>
        {s}
        {i !== arr.length - 1 && <br />}
      </span>
    ));
  }
  return str;
};

export const handleBoldTag = (str) => {
  const result = str.split(/(<b>[^<]+<\/b>)/).filter(Boolean);
  return result?.map((t) => {
    const m = t.match(/<b>([^<]+)<\/b>/);
    if (m) {
      return <span className="bold">{m[1]}</span>;
    }

    return t;
  });
};

export const handleLineBreaksAndBoldTags = (str) => {
  if (str?.includes('\\n')) {
    const arr = str.split('\\n');
    return arr.map((s, i) => {
      return (
      // eslint-disable-next-line react/no-array-index-key
        <span key={i}>
          {handleBoldTag(s)}
          {i !== arr.length - 1 && <br />}
        </span>
      );
    });
  }
  return handleBoldTag(str);
};

export const getVideoId = (url) => {
  const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regex);
  return (match && match[7].length === 11) ? match[7] : false;
};

export const getYoutubeEmbedUrl = (url) => {
  if (/\/embed\//.test(url)) {
    return url;
  }

  const vid = getVideoId(url);
  const regex = /[?&]t=(\d+)/;
  const match = url.match(regex);

  if (match) {
    const time = match[1];
    return `https://www.youtube.com/embed/${vid}?start=${time}`;
  }

  return `https://www.youtube.com/embed/${vid}`;
};

export const cleanseSpecialCharacters = (value) => {
  // keeps English characters, Chinese characters, numbers and underscore
  const regex = /[^\w\d_\u4e00-\u9fff]/g;
  return value?.trim().replace(regex, '');
};

export const shuffle = (array) => {
  const arr = [...array];
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};
