import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Button from 'src/components/Button';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import illus from 'src/assets/illustrationEmail.svg';

import styles from '../../styles.module.scss';

@observer
class ValidateEmail extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }


  render() {
    return (
      <div className={styles.onePageContainer}>
        <div className={styles.titleGroup}>
          {this.vm.identity === 'personal'
            ? (
              <h2 className={styles.title}>
                {t('register_validate_personal_step1_title1')}
                <span className={styles.purple}>{` ${t('register_validate_personal_step1_title2')} `}</span>
                {t('register_validate_personal_step1_title3')}
              </h2>
            )
            : (
              <h2 className={styles.title}>
                {t('register_validate_organization_title')}
              </h2>
            )}
          <h3 className={styles.subtitle} style={{ marginBottom: 20 }}>
            <span>{t('register_validate_email_subtitle1')}</span>
            <span className={styles.purple}>{` ${this.vm.email} `}</span>
            <span>{t('register_validate_email_subtitle2')}</span>
          </h3>
        </div>

        <img
          src={illus}
          alt="personal"
          className={styles.mainImg}
        />

        <div className={styles.bottomContainer} style={{ marginTop: 10 }}>
          <Button
            onClick={this.vm.onSendAuthEmail}
            className={styles.nextButton}
            disabled={this.vm.timerCountdown !== null}
          >
            {t('register_validate_resendValidateButton')}
            {this.vm.timerCountdown !== null && (
              <span className={styles.timer}>
                {`(${this.vm.timerCountdown})`}
              </span>
            )}
          </Button>

          <div className={styles.bottomText}>
            <span className={styles.text}>{`${t('register_validate_stillNotReceive')} `}</span>

            <div
              className={styles.purple}
              style={{ fontWeight: 700, display: 'inline', cursor: 'pointer' }}
              onClick={this.vm.onClickModifyEmail}
            >
              {t('register_validate_modifyEmail')}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

ValidateEmail.propTypes = {
  vm: PropTypes.object
};

ValidateEmail.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(ValidateEmail)), false);
