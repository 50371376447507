

import loadingIcon from 'src/assets/loading.svg';

export default function Loading(props) {
  return (
    <div className="loadingMask">
      <img src={loadingIcon} width={100} height={100} style={{ borderRadius: 100, boxShadow: '0px 0px 20px gray' }} alt="" />
    </div>
  );
}
