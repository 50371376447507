import { makeObservable, observable, action } from 'mobx';
import { TERMS_AND_POLICY_TABS } from 'src/constants';

class TermsAndPolicyPageViewModel {
  @observable currentTab = TERMS_AND_POLICY_TABS.Terms;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = (props) => {
    console.log('TermsAndPolicyPage.didMount, params', props.router.params);
    console.log(props.router.location);
    const { hash } = props.router.location;
    if (hash.split('#')[1]) {
      this.currentTab = hash.split('#')[1];
    }
  };

  switchTab = (value) => {
    this.currentTab = value;
  };

  toPreviousPage = () => {
    const { navigate } = this.props.router;
    navigate(-1);
  };

  toHomePage = () => {
    const { navigate } = this.props.router;
    navigate('/');
  };

  closeTab = () => {
    if (window.history.length === 1) {
      window.close();
    } else {
      this.toHomePage();
    }
  };
}

export default TermsAndPolicyPageViewModel;
