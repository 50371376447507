import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import qs from 'qs';
import { t } from 'i18next';

import store from 'src/stores/constants';
import { FILTERS_TYPES, RESOURCE_TYPES, TABLE_LIMIT, TABLE_LIMIT_MOBILE } from 'src/constants';
import ResourceService from 'src/services/resource';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';
import ErrorService from 'src/services/errors';
import tabsState from 'src/stores/tabsState';
import IS from 'src/utils/is';

class ResourcesPageViewModel {
  @observable lists = {
    [RESOURCE_TYPES.Service]: [],
    [RESOURCE_TYPES.Funds]: [],
    [RESOURCE_TYPES.Space]: []
  };
  @observable filter = {};
  @observable isFetching = false;

  // pagination
  @observable counts = {
    [RESOURCE_TYPES.Service]: 0,
    [RESOURCE_TYPES.Funds]: 0,
    [RESOURCE_TYPES.Space]: 0
  };
  @observable pages = {
    [RESOURCE_TYPES.Service]: 1,
    [RESOURCE_TYPES.Funds]: 1,
    [RESOURCE_TYPES.Space]: 1
  };
  @observable order = 'desc'; // asc | desc

  // filter
  @observable isMobile = false;
  @observable isModalOpen = false;
  @observable hasAppliedFilter = false;


  // service
  jobsSelectViewModel = new TreeSelectViewModel(store.jobsOptionsForUser);
  servicesTypesSelectViewModel = new TreeSelectViewModel(store.serviceTypesSelectOptions);
  senioritySelectViewModel = new TreeSelectViewModel(store.seniorityOptions);

  // funds
  fundsTypeSelectViewModel = new TreeSelectViewModel(store.fundsTypesOptions);

  // space
  usagesSelectViewModel = new TreeSelectViewModel(store.usagesOptions);

  // general
  regionsSelectViewModel = new TreeSelectViewModel(store.regionsOptions);
  sdgsSelectViewModel = new TreeSelectViewModel(store.sdgsOptions);

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @computed get type() {
    return tabsState.resourcesTab;
  }

  @computed get list() {
    return this.lists[this.type];
  }

  @computed get page() {
    return this.pages[this.type];
  }

  @computed get count() {
    return this.counts[this.type];
  }

  @computed get limit() {
    return this.isMobile ? TABLE_LIMIT_MOBILE : TABLE_LIMIT;
  }

  @computed get postFilters() {
    // data transform
    const subFilter = this.filter[this.type];

    // service
    const jobs = this.jobsSelectViewModel.selectedItems?.map((el) => el.value);
    const serviceTypes = this.servicesTypesSelectViewModel.selectedItems?.map((el) => el.value);
    const expandedServiceTypes = () => {
      return serviceTypes.flatMap((id) => {
        const found = store.serviceTypesSelectOptions.find((el) => el.value === id);
        return found.options.map((el) => el.value);
      });
    };
    const seniority = this.senioritySelectViewModel.selectedItems?.map((el) => el.value);

    // funds
    const fundsTypes = this.fundsTypeSelectViewModel.selectedItems?.map((el) => el.value);

    // space
    const usages = this.usagesSelectViewModel.selectedItems?.map((el) => el.value);

    // general
    const sdgs = this.sdgsSelectViewModel.selectedItems?.map((el) => el.value);
    const regions = this.regionsSelectViewModel.selectedItems?.map((el) => el.value);

    switch (this.type) {
      case RESOURCE_TYPES.Service:
        return {
          keyword: this.filter.keyword ? this.filter.keyword : undefined,
          [this.type]: {
            sdgs: sdgs.length ? sdgs : undefined,
            regions: regions.length ? regions : undefined,
            jobs: jobs?.length ? jobs : undefined,
            seniority: seniority?.length ? seniority : undefined,
            types: serviceTypes?.length ? expandedServiceTypes() : undefined
          }
        };

      case RESOURCE_TYPES.Space:
        return {
          keyword: this.filter.keyword ? this.filter.keyword : undefined,
          [this.type]: {
            sdgs: sdgs.length ? sdgs : undefined,
            regions: regions.length ? regions : undefined,
            usages: usages.length ? usages : undefined,
            size: {
              gte: subFilter?.minSize ?? undefined,
              lte: subFilter?.maxSize ?? undefined
            },
            capacity: {
              gte: subFilter?.minCap ?? undefined,
              lte: subFilter?.maxCap ?? undefined
            }
          }
        };

      case RESOURCE_TYPES.Funds:
      default:
        return {
          keyword: this.filter.keyword ? this.filter.keyword : undefined,
          [this.type]: {
            sdgs: sdgs.length ? sdgs : undefined,
            regions: regions.length ? regions : undefined,
            types: fundsTypes.length ? fundsTypes : undefined,
            quota: {
              gte: subFilter?.minQuota ?? undefined,
              lte: subFilter?.maxQuota ?? undefined
            }
          }
        };
    }
  }

  @computed
  get filterCount() {
    let count = 0;
    // service
    const jobs = this.jobsSelectViewModel.selectedItems?.map((el) => el.value);
    const serviceTypes = this.servicesTypesSelectViewModel.selectedItems?.map((el) => el.value);
    const seniority = this.senioritySelectViewModel.selectedItems?.map((el) => el.value);

    // funds
    const fundsTypes = this.fundsTypeSelectViewModel.selectedItems?.map((el) => el.value);

    // space
    const usages = this.usagesSelectViewModel.selectedItems?.map((el) => el.value);

    // general
    const sdgs = this.sdgsSelectViewModel.selectedItems?.map((el) => el.value);
    const regions = this.regionsSelectViewModel.selectedItems?.map((el) => el.value);

    if (sdgs?.length) count += 1;
    if (regions?.length) count += 1;
    switch (this.type) {
      case RESOURCE_TYPES.Service:
        if (jobs?.length) count += 1;
        if (serviceTypes?.length) count += 1;
        if (seniority?.length) count += 1;
        break;
      case RESOURCE_TYPES.Funds:
        if (fundsTypes?.length) count += 1;
        if (this.postFilters[this.type].quota?.gte && this.postFilters[this.type].quota?.lte) count += 1;
        break;
      case RESOURCE_TYPES.Space:
        if (this.postFilters[this.type].size?.gte && this.postFilters[this.type].size?.lte) count += 1;
        if (this.postFilters[this.type].capacity?.gte && this.postFilters[this.type].capacity?.lte) count += 1;
        if (usages?.length) count += 1;
        break;
      default:
    }
    return count;
  }

  @action
    didMount = async (props) => {
      this.isMobile = props.context.state.isMobile;
      const { state } = props.router.location;

      if (state) {
        this.setFilter(state);

        // replace history for one time used
        this.props.router.navigate(this.props.router.location.pathname, { replace: true });
      }

      const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      this.pages[this.type] = IS.numeric(values.page) ? values.page : 1;
      this.order = ['asc', 'desc'].includes(values.order) ? values.order : 'desc';

      await this.getList(true);
    };

  @action didUpdate = (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile)) {
      this.name = cur.profile?.name;
    }
  };

  willUnmount = (props) => {
    console.log('ResourcesPage.willUnmount');
  };


  @action onPagingChange = async (value) => {
    this.pages[this.type] = value;
    await this.getList();

    window.scrollTo({
      top: 140,
      behavior: 'smooth'
    });
  };

  @action
    getList = async (isInit = false) => {
      this.resetUrlByPageAndOrder();
      const params = {
        limit: this.limit,
        page: this.page,
        sort: this.sort,
        order: this.order
      };

      this.isFetching = true;

      try {
        const { list, count, page } = await ResourceService.getList(this.type, params, this.postFilters);

        if (isInit && page > Math.ceil(count / this.limit)) {
          await this.onPagingChange(1);
          return;
        }

        runInAction(() => {
          this.lists[this.type] = list;
          this.counts[this.type] = count;
          this.pages[this.type] = page;
        });
      } catch (error) {
        if (error.response?.status === 401) { // when token is expired
          ErrorService.onCustomError(
            t('general_error_content_401'),
            null,
            () => this.props.router.navigate(0)
          );
        } else {
          ErrorService.onDefaultError(error);
        }
      } finally {
        runInAction(() => {
          this.isFetching = false;
        });
      }
    };

  @action
    setFilter = ({ filter, type }) => {
      const arr = Object.entries(filter)[0];

      // get filter column
      switch (arr[0]) {
        case FILTERS_TYPES.Regions:
          this.regionsSelectViewModel.initCheckState(arr[1]);
          break;
        case FILTERS_TYPES.SDGs:
          this.sdgsSelectViewModel.initCheckState(arr[1]);
          break;
        case FILTERS_TYPES.ServiceTypes:
          this.servicesTypesSelectViewModel.initCheckState(arr[1]);
          break;
        case FILTERS_TYPES.Jobs:
          this.jobsSelectViewModel.initCheckState(arr[1]);
          break;
        case FILTERS_TYPES.FundsTypes:
          this.fundsTypeSelectViewModel.initCheckState(arr[1]);
          break;
        case FILTERS_TYPES.Usages:
          this.usagesSelectViewModel.initCheckState(arr[1]);
          break;

        default:
          break;

      }
      // this.type = type;
      tabsState.updateResourcesTab(type);

      this.hasAppliedFilter = this.filterCount !== 0;

    };

  @action
    switchType = async (value) => {
      const { navigate } = this.props.router;
      tabsState.updateResourcesTab(value);
      navigate(`/resources?page=${this.page}`, { replace: true });
      await this.getList();
    };

  @action
    toggleModal = () => {
      this.isModalOpen = !this.isModalOpen;
    };

  @action
    onKeywordChange = (ev) => {
      this.filter = { ...this.filter, keyword: ev.target.value };

      if (ev.target.value?.length === 0) {
        this.getList();
      }
    };

  @action
    onFilterChange = (field, value) => {
      const temp = this.filter[this.type];
      this.filter[this.type] = { ...temp, [`${field}`]: value };

      console.log('filter', JSON.stringify(this.filter, null, 2));
    };


  sendGAEvent() {
    let filter;
    switch (this.type) {
      case RESOURCE_TYPES.Service:
        filter = this.postFilters[RESOURCE_TYPES.Service];
        window.gtag('event', 'FindResource_Service_Filter', {
          Skill_Type: filter.types ? filter.types.join(',') : '',
          Specialize_In: filter.jobs ? filter.jobs.join(',') : '',
          Seniority: filter.seniority ? filter.seniority.join(',') : '',
          SDGs: filter.sdgs ? filter.sdgs.join(',') : '',
          Locations: filter.regions ? filter.regions.join(',') : ''
        });
        break;
      case RESOURCE_TYPES.Funds:
        filter = this.postFilters[RESOURCE_TYPES.Funds];
        window.gtag('event', 'FindResource_Fund_Filter', {
          Fund_Type: filter.types ? filter.types.join(',') : '',
          Range: (filter.quota.gte || filter.quota.lte) ? `${filter.quota.gte ? filter.quota.gte : 'min'} to ${filter.quota.lte ? filter.quota.lte : 'max'}` : '',
          SDGs: filter.sdgs ? filter.sdgs.join(',') : '',
          Locations: filter.regions ? filter.regions.join(',') : ''
        });
        break;
      case RESOURCE_TYPES.Space:
        filter = this.postFilters[RESOURCE_TYPES.Space];
        window.gtag('event', 'FindResource_Space_Filter', {
          Usage: filter.usages ? filter.usages.join(',') : '',
          Size: (filter.size.gte || filter.size.lte) ? `${filter.size.gte ? filter.size.gte : 'min'} to ${filter.size.lte ? filter.size.lte : 'max'}` : '',
          Capacity: (filter.capacity.gte || filter.capacity.lte) ? `${filter.capacity.gte ? filter.capacity.gte : 'min'} to ${filter.capacity.lte ? filter.capacity.lte : 'max'}` : '',
          SDGs: filter.sdgs ? filter.sdgs.join(',') : '',
          Locations: filter.regions ? filter.regions.join(',') : ''
        });
        break;
      default:
    }
  }


  @action
    applyFilter = async () => {
      this.sendGAEvent();
      await this.getList();

      runInAction(() => {
        this.toggleModal();
        this.hasAppliedFilter = this.filterCount !== 0;
      });
    };

  @action
    resetFilter = () => {
      this.sdgsSelectViewModel.resetAll();
      this.regionsSelectViewModel.resetAll();

      switch (this.type) {
        case RESOURCE_TYPES.Service:
          this.servicesTypesSelectViewModel.resetAll();
          this.jobsSelectViewModel.resetAll();
          this.senioritySelectViewModel.resetAll();
          break;
        case RESOURCE_TYPES.Funds:
          this.fundsTypeSelectViewModel.resetAll();
          break;
        case RESOURCE_TYPES.Space:
          this.usagesSelectViewModel.resetAll();
          break;
        default:

      }
      this.resourceTypes = [];
      this.filter = {};
    };

  @action handleOrder = async (order) => {
    this.order = order;
    this.pages = {
      [RESOURCE_TYPES.Service]: 1,
      [RESOURCE_TYPES.Funds]: 1,
      [RESOURCE_TYPES.Space]: 1
    };
    await this.getList();
  };

  resetUrlByPageAndOrder = () => {
    const { navigate } = this.props.router;
    navigate(`/resources?page=${this.page}&order=${this.order}`, { replace: true });
  };


  toDetail = (type, id) => {
    const { navigate } = this.props.router;
    navigate(`/resources/${type}/${id}`);
  };

}

export default ResourcesPageViewModel;
