import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import User from '../../User';

class RelatedUser {
  @observable type;
  @observable name;
  @observable user;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get nameForDisplay() {
    return this.name || this.user.displayName || this.user.name;
  }

  @computed get value() {
    return this.name || this.user.id;
  }

  // /////////////////////////////////////

  @action deserialize = (data) => {
    const {
      type,
      name,
      user
    } = data ?? {};

    this.type = type;
    this.name = name;
    this.user = User.fromRes(user);
  };


  static fromRes(data) {
    return new RelatedUser(data);
  }

}

export default RelatedUser;

