/* eslint-disable */
const Tips = {
  "zh-TW": {
    "name": {
      "title": "行動名稱下標 Tips",
      "description": "一個 Powerful 又吸引人的行動，取一個打動人心又好記的名字很重要！將更能促使潛在合作夥伴忍不住點擊瞧瞧！\n以下分享「行動下標 7大技巧」：",
      "aboutContents": {
        "1": "明確表示正在「關注的議題」\\n\n<b>範例</b>：河作淡生新鮮事：淡海流動創生設計行動",
        "2": "給一個強而有力的「理由」作標題\\n\n<b>範例</b>：永續的最後一哩路：教育。一本影響教育界的永續教科書",
        "3": "傳達「急迫感」或一個「打開希望」的意念\\n\n<b>範例</b>：<永續教育的即時雨> 打造一本帶入100家企業資源的永續教科書",
        "4": "標註「特定群眾」，對「該看到的人」說話\\n\n<b>範例</b>：品牌擴大影響力！永續長一年一度必參與的「永續教科書」設計行動",
        "5": "加入吸引人的「特定主題」，促使對議題有感的參與者期待加入並產生貢獻\\n\n<b>範例</b>：為108個部落原住民孩子打造數位教育解方",
        "6": "表示「預期成果」，激發參與者的興趣及期待\\n\n<b>範例</b>：打造第一本引導老師設計永續教案的工具書",
        "7": "使用疑問句，運用「如果、如何、怎麼樣可以」來造句\\n\n<b>範例</b>：如何把賣場變食物轉型教室？邀請您一起打造台灣第一本永續教科書。"
      },
      "aboutTexts": {
        "1": "精簡、白話又有關鍵字的標題，效果最佳",
        "2": "可以找出 1-2 個動詞加入標題，表達行動意圖",
        "3": "獨特的字詞，請放置前方文字，讓大家記住",
        "4": "使用關鍵數字或數據"
      },
      "examples": [
        {
          "name": "「河作淡生新鮮事：淡海流動創生設計行動」",
          "url": ""
        },
        {
          "name": "「第一本引導老師設計永續教案的工具書」",
          "url": ""
        }
      ]
    },
    "slogan": {
      "title": "影響力金句 精彩說",
      "description": "在發起行動時，都想賦予行動重要的價值。讓我們試著用「一句話」來描述本行動「最想創造」及「最希望被記住」的影響力吧！",
      "aboutContents": {
        "1": "明確表示「正在發起的行動」\\n\n<b>範例</b>：數位人，快來轉動鯖魚故鄉的產業轉型/升級：共創下世代的漁村文化。",
        "2": "在本次行動中，最想被這社會「記住的觀點」是什麼？\\n\n<b>範例</b>：不只了解，更成為實踐者與貢獻者",
        "3": "在本次行動中，你們正為目標對象「創造什麼價值」？\\n\n<b>範例</b>：成為為孩子們帶來真正改變的老師！",
        "4": "在未來行動中，你們有什麼方式或策略能促使「參與者持續投入」該行動？\\n\n<b>範例</b>：將行走里程數轉化成愛的綠色數據"
      },
      "aboutTexts": {
        "1": "藉由上述內容引導，可先提出 3-4 個關鍵字",
        "2": "可加入『動詞』，表示在行動最重要的意圖",
        "3": "透過這些關鍵字，寫成一個口號或標題，能感動自已及參與者",
        "4": "讓激發參與者能與你們一起行動"
      },
      "examples": [
        {
          "name": "「建構淡水成為創生實踐場域，吸引人才成為下一波的力量」",
          "url": ""
        }
      ]
    },
    "keyFinding_description": {
      "title": "重點描述  Tips",
      "description": "從上述選擇的 SDGs 目標中簡述本次行動。同時檢查與回頭看看，讓行動家了解進而投入的原因，與你們合作的價值。透過「重點內容描敘 4項關鍵原則」，想一想，如何在你的行動簡述之中表達：",
      "aboutContents": {
        "1": "本次行動的「背景」與「目標」是什麼？\\n\n現今全球及台灣環境的變化，你們觀察到了什麼？對人、社會或環境產生什麼影響？預期目標是什麼？",
        "2": "目標背後的問題「非解不可」的「重要性與急迫性」為何？\\n\n這個非解不可的缺口，對人發生了改變？對人、社會或環境的重要性與急迫性？",
        "3": "行動背後預計的「策略及作法」為何？\\n\n你的組織為什麼要投入這個議題？如何透過資源及能力，找到解決缺口的機會點，未來策略會如何延續？",
        "4": "行動預期帶來的「改變與關鍵產出」為何？\\n\n為了朝向長期目標邁進一步，你們優先解決的關鍵問題是什麼？如何做到？"
      },
      "aboutTexts": {
        "1": "『全球問題及趨勢的背景』，在本次行動中的『預期目標』，『非解不可的缺口』對『人、社會或環境』產生了『行為及需求影響』，企業將透過『資源及能力』找到解決缺口的方式，預期並在短中長期帶來「改變與關鍵產出」\\n\n\\n\n\n<b>範例</b>\\n\n針對全球推動永續發展趨勢、以及台灣在推動下一階段118課綱的過程中，本次行動目標在打造一本給全國教育工作者使用的「永續教科書」。這本教科書，將會解決過往教師推動永續教育上，在銜接「產業資源」與「創新教案設計」上整合串接的不足。預計透過「地球解方」年會每年選出的100家代表企業案例及背後的資源盤點，短期1年先對接60位K12校長，第2-3年對接到全國1,000校長與背後師生，每年創造100+的產學永續教案與行動。"
      },
      "examples": [
        {
          "name": "",
          "url": ""
        }
      ]
    },
    "keyFinding_title": {
      "title": "永續議題的標題下標 Tips ",
      "description": "從上述永續議題描述，下標一個「打動人心又好記的標題」，並說明重要缺口，將有機會吸引潛在夥伴並創造深刻記憶！參考以下7大技巧：\n",
      "aboutContents": {
        "1": "明確表示正在「關注的永續議題」，顯示「目前缺口」\\n\n<b>範例</b>：河作淡生新鮮事：淡海流動創生設計行動",
        "2": "設計一個強而有力的「理由」作標題\\n\n<b>範例</b>：永續的最後一哩路：教育。一本影響教育界的永續教科書\n",
        "3": "傳達「急迫感」或一個「打開希望」的意念\\n\n<b>範例</b>：<永續教育的即時雨> 打造一本帶入100家企業資源的永續教科書",
        "4": "標註「特定群眾」，對「該看到的人」說話\\n\n<b>範例</b>：品牌擴大影響力！永續長每年不能不參與的「永續教科書」設計行動",
        "5": "加入吸引人的「特定主題」，促使對議題有感的參與者期待加入並產生貢獻\\n\n<b>範例</b>：數位人快來轉動鯖魚故鄉的轉型升級",
        "6": "表示「預期成果」，激發參與者的興趣及期待\\n <b>範例</b>：打造第一本引導老師設計永續教案的工具書",
        "7": "使用疑問句，運用「如果、如何、怎麼樣可以」來造句\\n <b>範例</b>：如何把賣場變食物轉型教室？邀請您一起打造台灣第一本永續教科書。"
      },
      "aboutTexts": {
        "1": "精簡、白話又有關鍵字的標題，效果最佳",
        "2": "可以找出 1-2 個動詞加入標題，表達行動意圖",
        "3": "獨特的字詞，請放置前方文字，讓大家記住",
        "4": "使用關鍵數字或數據"
      },
      "examples": [
        {
          "name": "「結合新世代的力量，打造地方的永續城鄉發展」",
          "url": ""
        },
        {
          "name": "「賦能老師，培育主導未來的孩子」",
          "url": ""
        }
      ]
    },
    "targets_title": {
      "title": "目標對象的標題下標 Tips",
      "description": "針對你們所關注的議題，請試著描述本次期待參與的「目標對象」。參考以下3大原則：",
      "aboutContents": {
        "1": "要與「永續議題」直接相關\\n\n<b>範例</b>：想實踐理想永續教育的夥伴",
        "2": "思考對象指的是「使用者」、「提案支持對象」或「服務提供者」\\n\n<b>範例</b>：期待推動永續教育的國小高年級老師（使用者）\\n\n<b>範例</b>：推動永續扎根未來人才的教育基金會、公部門代表（提案支持對象）\\n\n<b>範例</b>：期盼與教育界合作的企業ESG部門（服務提供者）",
        "3": "在目標對象的「廣度」與「精確度」中找到平衡點\\n\n<b>範例</b>：針對全國所有中小小學科任老師（量大廣泛，但可能不夠聚焦）\\n\n<b>範例</b>：針對阿里山鄒族國小的社團老師（聚焦清晰，但可能影響範疇有限）"
      },
      "aboutTexts": {
        "1": "精簡又有關鍵字的標題，效果最佳",
        "2": "可以找出 1-2 個動詞加入標題，表達行動意圖",
        "3": "利用「形容詞」的堆疊讓對象「更明確化」"
      },
      "examples": [
        {
          "name": "「推動區域永續發展的地方團隊」",
          "url": ""
        },
        {
          "name": "「想參與在地議題的跨域夥伴」",
          "url": ""
        }
      ]
    },
    "targets_description": {
      "title": "需求描述  Tips",
      "description": "設計的本質在於「解決問題」與「創造新的價值」，從3個層面來思考：",
      "aboutContents": {
        "1": "銜接目標對象，說明該「需求」背後的「重要性」\\n\n<b>範例</b>：全球已有超過50個國家訂出未來實踐淨零的具體目標，但在教育體制內的永續課綱仍未得到滿足，最新報告也顯示「永續人才」大缺工是未來轉型關鍵。",
        "2": "嘗試說明上述需求為何得不到滿足的「痛點」\\n\n<b>範例</b>：體制內到教師投入永續教育質與量的提升，問題不在意願，而在「時間與資源」。如何能給予老師立即可用且容易理解的教案教材與校外資源，則是實質可提升的關鍵。",
        "3": "思考若需求為「真」，過往滿足需求的「作法」是什麼？\\n\n<b>範例</b>：老師過往教導所需的資源，往往需額外花費很多時間搜尋，但也因對議題不一定熟悉，往往事倍功半，且不見得能得到學校的支持。"
      },
      "aboutTexts": {
        "1": "『目標對象的需求及其重要性』，但因『需求未能被滿足的痛點』，請說明「過往滿足需求的『作法』」是什麼？"
      },
      "examples": [
        {
          "name": "「如何新舊融合與對話，並且延續產業特色與自然生態的足跡，讓更多新世代關注與理解？ 我們將透過「跨領域開放式創新」方式，探討淡海區域團隊產業特色與環境生態，被更多人看見，透過與在地團隊共創梳理與了解所面臨缺口，與社會設計師投入專業，重新設計與重新定義，讓淡水地方特色、產業價值提升，朝永續城鄉邁進」",
          "url": ""
        }
      ]
    },
    "vision": {
      "title": "行動願景  精彩說",
      "description": "以終為始地來思考，當行動執行完畢，期待達到什麼樣的階段目標？期待能替社會創造什麼價值？或是創造什麼樣的理想未來？透過「4大關鍵內容原則」，想一想，在行動簡述之中如何清楚說明：",
      "aboutContents": {
        "1": "緊密扣合與回應行動之初所鎖定的「目標對象」、「需求」與「痛點」\\n\n<b>範例</b>：隨著高齡社會化的來臨，人們平均壽命延長，但不健康餘命及長照需求也跟著增加。輔具跟照顧是一體的，缺一不可。",
        "2": "有明確時間說明「多遠」的願景\\n\n<b>範例</b>：小南人市集已經邁入第三年，今年將收斂前兩年計畫的經驗，轉化為可複製的教育模型與教案，透過教育計畫的過程，種下觸動行為改變的種子。",
        "3": "有夥伴及專業的投入，配搭「可衡量的指標」，說明行動目標「有做到」、「有做好」的情境，描述讓你感到振奮與期待的理想樣貌吧\\n\n<b>範例</b>：邀請格上提供GO SMART共享車服務，以雙北服務點作為示範驗場域，增加10 家里程數服務捐贈企業與100 位個人捐贈者。",
        "4": "長期投入後，如何運用「行動成果」，持續「發揮社會影響力」\\n\n<b>範例</b>：提供企業參與捐贈、累績減碳效益驗證媒合30 個社會團體成為合作夥伴加入，使用愛的里程數服務，解決交通問題、創造服務能量。"
      },
      "aboutTexts": {
        "1": "說明行動的『目標對象』、『需求』及『痛點』，明確說明『預計達成願景的時間』，因為有『什麼樣貌夥伴及專業的投入』的加入，可一起『做到並達成可衡量指標的情境』，未來如何持續「發揮社會影響力」。"
      },
      "examples": [
        {
          "name": "「針對淡水在地團隊在永續發展上面臨的議題，強化服務/產品的獨特性與在地性，並用設計轉譯產業與文化價值，有效與新世代對話。我們期待號召各式身懷專業的跨領域人才，透過在地團隊、企業的能量與跨域設計師共創合作的加入，打造能讓在地團隊運作且持續發展的設計創新提案，並有效推動地方落實，持續推動地方永續發展。」",
          "url": ""
        }
      ]
    },
    "keyItem": {
      "title": "整體簡介  精彩說",
      "description": "針對本次行動，簡要說明預計規劃哪些策略與作法，以及預期達成的目標與成果各是什麼？",
      "aboutContents": {
        "1": "銜接要達成上述行動願望的「策略」與「作法」\\n\n<b>舉例</b>：延續冠德企業集團所推出的大健康全齡宅，我們將以「永久售後服務」為基礎，建立一個平台。",
        "2": "可以思考分階段論述，並盡可能的具體化\\n\n<b>舉例</b>：輔具就像電腦的硬體，照顧則是軟體，兩個做結合才是恢復或維持長輩生活自理能力(復能)的最佳解方。",
        "3": "對應前述設定的「目標」與「關鍵產出」\\n\n<b>舉例</b>：將捐贈者的愛心貢獻可視化為有形數據，提供捐贈憑證和減碳排效益書，並以區塊鏈技術保障數據安全及透明。"
      },
      "aboutTexts": {
        "1": ""
      },
      "examples": [
        {
          "name": "「2022年，林堉璘宏泰教育基金會與 5% Design Action社會設計平台發起「淡海流動創生設計行動」。從淡水面臨的課題出發，號召各式身懷專業的跨領域人才，透過在地團隊、企業的能量與跨域設計師共創合作，一起為「淡水」未來的發展翻轉思考、重新定義，拼構出與時俱進的地方創新發展。」",
          "url": ""
        }
      ]
    },
    "features": {
      "title": "行動特色描述  精彩說",
      "description": "請清楚說明本次行動參與的行動家將能獲得的價值，包含承接與認識什麼樣的過去作為、接下來將創造什麼樣的影響，並能將這些價值分享、推薦給其他朋友。",
      "aboutContents": {
        "1": "<b>開頭</b>：在這次行動中會帶入什麼樣的過去成果或資源",
        "2": "<b>中間</b>：這些成果要透過行動來接觸的目標對象、問題，並創造什麼價值",
        "3": "<b>收尾</b>：如果行動成果將透過大型媒體宣傳曝光，你會用什麼句子來明確展現該行動與其成果的「獨特性」"
      },
      "aboutTexts": {
        "1": ""
      },
      "examples": [
        {
          "name": "「奠基南方澳鯖魚故鄉的產業生態，與在地夥伴共創永續漁業，為我們的海島建構下世代的漁村文化原型」 ",
          "url": ""
        }
      ]
    },
    "demand_service_name": {
      "title": "服務需求名稱 Tips",
      "description": "透過清晰描述「服務需求」，將更有效招募到適合的行動家參與！",
      "aboutContents": {
        "1": "為了達成行動目標，明確表示正找尋什麼「擁有資源的夥伴」\\n\n<b>範例</b>：綠色影響力數據專家",
        "2": "描述所需要服務的「關鍵字」\\n\n<b>範例</b>：擅長教案設計的老師、具有插畫能力的設計師"
      },
      "aboutTexts": {
        "1": "『可以提供什麼樣特定專業』的『夥伴名稱』"
      },
      "examples": [
        {
          "name": "「想參與地方議題的專業者」",
          "url": ""
        },
        {
          "name": "「想參與永續活動的師生」",
          "url": ""
        }
      ]
    },
    "demand_service_description": {
      "title": "服務需求描述 精彩說",
      "description": "為了達成行動的目標，你期待找到的理想夥伴，應該具備哪些特質及能力？",
      "aboutContents": {
        "1": "理想夥伴需具備哪些專業能力？",
        "2": "有沒有對於理想夥伴特質的要求？",
        "3": "期待理想夥伴能如何與團隊協作？",
        "4": "預計的關鍵產出是什麼？"
      },
      "aboutTexts": {
        "1": "『夥伴的專業能力』能替行動解決『需求』或是發展『關鍵產出』。"
      },
      "examples": [
        {
          "name": "「願意為地方文化帶來可持續創新之專業人士/大專以上師生」",
          "url": ""
        }
      ]
    },
    "demand_keyItemDescriptions": {
      "title": "重點合作項目 Tips",
      "description": "在行動中，請簡要說明有哪些大大小小的項目，需要透過合作夥伴才能共同完成。",
      "aboutContents": {
        "1": "期待與夥伴合作促成哪些「關鍵成果及效益」\\n\n<b>範例</b>：透過設計培訓方案1套，促使更多教師獲得 DFC 種子教師認證證書",
        "2": "使用關鍵數字或數據表示\\n\n<b>範例</b>：《日日挑戰》教材1套和參與培訓計畫3場",
        "3": "合作模式是「共同開發」或是「委託設計」？\\n\n<b>範例</b>：歡迎共同討論「聯名」開發及合作策略細節與收穫"
      },
      "aboutTexts": {
        "1": "可用 3-5 句話，條列式重點摘要的合作項目、關鍵成果及效益。"
      },
      "examples": [
        {
          "name": "「打造適合全齡共感友善寵物創新農場體驗/服務」",
          "url": ""
        }
      ]
    },
    "demand_award_description": {
      "title": "收獲描述 Tips",
      "description": "在行動完成後，合作夥伴將能獲得那些益處及收獲呢？透過「3大關鍵內容原則」想一想，在你的「合作收獲描述」中如何表達，將有助於吸引更多強者報名參與：\n",
      "aboutContents": {
        "1": "明確且能「公開表示」的合作展現方式\\n\n<b>範例</b>：與 5% Design Action 展開永續新生活策略合作、推動聯名開發，以及獲取廣告宣傳協助。",
        "2": "具體「可計算」的合作成果\\n\n<b>範例</b>：go action 年度電子報告書。",
        "3": "能夠實現夥伴的「理想目標」\\n\n<b>範例</b>：找到夥伴一起開創在地事業的機會。"
      },
      "aboutTexts": {
        "1": "可用 3-5 句話，摘要合作夥伴能獲得的益處"
      },
      "examples": [
        {
          "name": "「找到夥伴一起開創在地事業的機會」",
          "url": ""
        },
        {
          "name": "「歡迎共同討論聯名開發及策略合作細節與收穫」",
          "url": ""
        }
      ]
    },
    "demand_rules": {
      "title": "行動相關規範 Tips",
      "description": "在合作之前，為了讓本次合作夥伴能夠彼此相信、安心合作，可提出你們在乎相關規範。資訊清楚透明，將會找到更精準及適合的夥伴，創造理想的合作關係\n\n\n",
      "aboutContents": {
        "1": "是否需要簽訂「合約」？",
        "2": "明確定義需要「保密」的部份？",
        "3": "「可量化」的合作參與程度",
        "4": "明確表示「智財權」的歸屬"
      },
      "aboutTexts": {
        "1": "可用 3-5 句話，摘要合作夥伴需注意的行動規範"
      },
      "examples": [
        {
          "name": "「個人資料會去識別化處理，不會公開資料」",
          "url": ""
        }
      ]
    },
    "demand_funds_name": {
      "title": "資金需求名稱 Tips",
      "description": "透過清晰描述「資金需求」，將更有效招募到適合的參與者！",
      "aboutContents": {
        "1": "為了達成行動目標，明確表示正找尋什麼「擁有資金的夥伴」\\n\n<b>範例</b>：地方資源的串聯專家\n\n",
        "2": "描述所需要資金的「關鍵字」\\n\n<b>範例</b>：永續教育基金的投資人、樂於支持新創的天使投資人"
      },
      "aboutTexts": {
        "1": "『可以提供資金用於什麼樣的標的』的『夥伴名稱』"
      },
      "examples": [
        {
          "name": "「偏鄉教育的造夢者」",
          "url": ""
        },
        {
          "name": "「永續教育基金的投資人」",
          "url": ""
        }
      ]
    },
    "demand_space_name": {
      "title": "空間需求名稱 Tips",
      "description": "透過清晰描述「空間需求」，將更有效招募到適合的參與者！",
      "aboutContents": {
        "1": "為了達成行動目標，明確表示正找尋什麼樣的「空間」\\n\n<b>範例</b>：可實驗創新農作的農地",
        "2": "描述所需要空間的「關鍵字」\\n\n<b>範例</b>：開放創意的室內場域、重視世代共融的咖啡館"
      },
      "aboutTexts": {
        "1": "『可以提供什麼樣特定服務』的『空間名稱』"
      },
      "examples": [
        {
          "name": "「可長期耕作的農地」",
          "url": ""
        },
        {
          "name": "「開放學校的實驗場域」",
          "url": ""
        }
      ]
    },
    "demand_funds_description": {
      "title": "資金需求描述 精彩說",
      "description": "為了達成行動的目標，你期待找到擁有理想資金的夥伴，應該具備哪些特質及能力？",
      "aboutContents": {
        "1": "有沒有對於理想夥伴特質的要求？",
        "2": "期待理想夥伴能如何與團隊協作？",
        "3": "預計的關鍵產出是什麼？"
      },
      "aboutTexts": {
        "1": "『夥伴的資金能力』能替行動解決『需求』或是發展『關鍵產出』"
      },
      "examples": [
        {
          "name": "「永續教育基金的投資人，能替發展永續教育的夥伴，找到穩定的資源來源」",
          "url": ""
        }
      ]
    },
    "demand_space_description": {
      "title": "空間需求描述 精彩說",
      "description": "為了達成行動的目標，你期待找到的理想空間，應該具備哪些特點？",
      "aboutContents": {
        "1": "理想空間中需要能提供什麼專業服務？",
        "2": "認為理想的空間，必要條件是什麼呢？",
        "3": "預計的關鍵產出是什麼？"
      },
      "aboutTexts": {
        "1": "『理想的空間環境』能替行動解決『需求』或是發展『關鍵產出』。"
      },
      "examples": [
        {
          "name": "「開放學校的實驗場域，使學生及教師能夠自由發展，共同打造一個永續創意平台，」",
          "url": ""
        }
      ]
    }
  },
  "en-US": {
    "name": {
      "title": "行動名稱下標 Tips",
      "description": "一個 Powerful 又吸引人的行動，取一個打動人心又好記的名字很重要！將更能促使潛在合作夥伴忍不住點擊瞧瞧！\n以下分享「行動下標 7大技巧」：",
      "aboutContents": {
        "1": "明確表示正在「關注的議題」\\n\n<b>範例</b>：河作淡生新鮮事：淡海流動創生設計行動",
        "2": "給一個強而有力的「理由」作標題\\n\n<b>範例</b>：永續的最後一哩路：教育。一本影響教育界的永續教科書",
        "3": "傳達「急迫感」或一個「打開希望」的意念\\n\n<b>範例</b>：<永續教育的即時雨> 打造一本帶入100家企業資源的永續教科書",
        "4": "標註「特定群眾」，對「該看到的人」說話\\n\n<b>範例</b>：品牌擴大影響力！永續長一年一度必參與的「永續教科書」設計行動",
        "5": "加入吸引人的「特定主題」，促使對議題有感的參與者期待加入並產生貢獻\\n\n<b>範例</b>：為108個部落原住民孩子打造數位教育解方",
        "6": "表示「預期成果」，激發參與者的興趣及期待\\n\n<b>範例</b>：打造第一本引導老師設計永續教案的工具書",
        "7": "使用疑問句，運用「如果、如何、怎麼樣可以」來造句\\n\n<b>範例</b>：如何把賣場變食物轉型教室？邀請您一起打造台灣第一本永續教科書。"
      },
      "aboutTexts": {
        "1": "精簡、白話又有關鍵字的標題，效果最佳",
        "2": "可以找出 1-2 個動詞加入標題，表達行動意圖",
        "3": "獨特的字詞，請放置前方文字，讓大家記住",
        "4": "使用關鍵數字或數據"
      },
      "examples": [
        {
          "name": "「河作淡生新鮮事：淡海流動創生設計行動」",
          "url": ""
        },
        {
          "name": "「第一本引導老師設計永續教案的工具書」",
          "url": ""
        }
      ]
    },
    "slogan": {
      "title": "影響力金句 精彩說",
      "description": "在發起行動時，都想賦予行動重要的價值。讓我們試著用「一句話」來描述本行動「最想創造」及「最希望被記住」的影響力吧！",
      "aboutContents": {
        "1": "明確表示「正在發起的行動」\\n\n<b>範例</b>：數位人，快來轉動鯖魚故鄉的產業轉型/升級：共創下世代的漁村文化。",
        "2": "在本次行動中，最想被這社會「記住的觀點」是什麼？\\n\n<b>範例</b>：不只了解，更成為實踐者與貢獻者",
        "3": "在本次行動中，你們正為目標對象「創造什麼價值」？\\n\n<b>範例</b>：成為為孩子們帶來真正改變的老師！",
        "4": "在未來行動中，你們有什麼方式或策略能促使「參與者持續投入」該行動？\\n\n<b>範例</b>：將行走里程數轉化成愛的綠色數據"
      },
      "aboutTexts": {
        "1": "藉由上述內容引導，可先提出 3-4 個關鍵字",
        "2": "可加入『動詞』，表示在行動最重要的意圖",
        "3": "透過這些關鍵字，寫成一個口號或標題，能感動自已及參與者",
        "4": "讓激發參與者能與你們一起行動"
      },
      "examples": [
        {
          "name": "「建構淡水成為創生實踐場域，吸引人才成為下一波的力量」",
          "url": ""
        }
      ]
    },
    "keyFinding_description": {
      "title": "重點描述  Tips",
      "description": "從上述選擇的 SDGs 目標中簡述本次行動。同時檢查與回頭看看，讓行動家了解進而投入的原因，與你們合作的價值。透過「重點內容描敘 4項關鍵原則」，想一想，如何在你的行動簡述之中表達：",
      "aboutContents": {
        "1": "本次行動的「背景」與「目標」是什麼？\\n\n現今全球及台灣環境的變化，你們觀察到了什麼？對人、社會或環境產生什麼影響？預期目標是什麼？",
        "2": "目標背後的問題「非解不可」的「重要性與急迫性」為何？\\n\n這個非解不可的缺口，對人發生了改變？對人、社會或環境的重要性與急迫性？",
        "3": "行動背後預計的「策略及作法」為何？\\n\n你的組織為什麼要投入這個議題？如何透過資源及能力，找到解決缺口的機會點，未來策略會如何延續？",
        "4": "行動預期帶來的「改變與關鍵產出」為何？\\n\n為了朝向長期目標邁進一步，你們優先解決的關鍵問題是什麼？如何做到？"
      },
      "aboutTexts": {
        "1": "『全球問題及趨勢的背景』，在本次行動中的『預期目標』，『非解不可的缺口』對『人、社會或環境』產生了『行為及需求影響』，企業將透過『資源及能力』找到解決缺口的方式，預期並在短中長期帶來「改變與關鍵產出」\\n\n\\n\n\n<b>範例</b>\\n\n針對全球推動永續發展趨勢、以及台灣在推動下一階段118課綱的過程中，本次行動目標在打造一本給全國教育工作者使用的「永續教科書」。這本教科書，將會解決過往教師推動永續教育上，在銜接「產業資源」與「創新教案設計」上整合串接的不足。預計透過「地球解方」年會每年選出的100家代表企業案例及背後的資源盤點，短期1年先對接60位K12校長，第2-3年對接到全國1,000校長與背後師生，每年創造100+的產學永續教案與行動。"
      },
      "examples": [
        {
          "name": "",
          "url": ""
        }
      ]
    },
    "keyFinding_title": {
      "title": "永續議題的標題下標 Tips ",
      "description": "從上述永續議題描述，下標一個「打動人心又好記的標題」，並說明重要缺口，將有機會吸引潛在夥伴並創造深刻記憶！參考以下7大技巧：\n",
      "aboutContents": {
        "1": "明確表示正在「關注的永續議題」，顯示「目前缺口」\\n\n<b>範例</b>：河作淡生新鮮事：淡海流動創生設計行動",
        "2": "設計一個強而有力的「理由」作標題\\n\n<b>範例</b>：永續的最後一哩路：教育。一本影響教育界的永續教科書\n",
        "3": "傳達「急迫感」或一個「打開希望」的意念\\n\n<b>範例</b>：<永續教育的即時雨> 打造一本帶入100家企業資源的永續教科書",
        "4": "標註「特定群眾」，對「該看到的人」說話\\n\n<b>範例</b>：品牌擴大影響力！永續長每年不能不參與的「永續教科書」設計行動",
        "5": "加入吸引人的「特定主題」，促使對議題有感的參與者期待加入並產生貢獻\\n\n<b>範例</b>：數位人快來轉動鯖魚故鄉的轉型升級",
        "6": "表示「預期成果」，激發參與者的興趣及期待\\n <b>範例</b>：打造第一本引導老師設計永續教案的工具書",
        "7": "使用疑問句，運用「如果、如何、怎麼樣可以」來造句\\n <b>範例</b>：如何把賣場變食物轉型教室？邀請您一起打造台灣第一本永續教科書。"
      },
      "aboutTexts": {
        "1": "精簡、白話又有關鍵字的標題，效果最佳",
        "2": "可以找出 1-2 個動詞加入標題，表達行動意圖",
        "3": "獨特的字詞，請放置前方文字，讓大家記住",
        "4": "使用關鍵數字或數據"
      },
      "examples": [
        {
          "name": "「結合新世代的力量，打造地方的永續城鄉發展」",
          "url": ""
        },
        {
          "name": "「賦能老師，培育主導未來的孩子」",
          "url": ""
        }
      ]
    },
    "targets_title": {
      "title": "目標對象的標題下標 Tips",
      "description": "針對你們所關注的議題，請試著描述本次期待參與的「目標對象」。參考以下3大原則：",
      "aboutContents": {
        "1": "要與「永續議題」直接相關\\n\n<b>範例</b>：想實踐理想永續教育的夥伴",
        "2": "思考對象指的是「使用者」、「提案支持對象」或「服務提供者」\\n\n<b>範例</b>：期待推動永續教育的國小高年級老師（使用者）\\n\n<b>範例</b>：推動永續扎根未來人才的教育基金會、公部門代表（提案支持對象）\\n\n<b>範例</b>：期盼與教育界合作的企業ESG部門（服務提供者）",
        "3": "在目標對象的「廣度」與「精確度」中找到平衡點\\n\n<b>範例</b>：針對全國所有中小小學科任老師（量大廣泛，但可能不夠聚焦）\\n\n<b>範例</b>：針對阿里山鄒族國小的社團老師（聚焦清晰，但可能影響範疇有限）"
      },
      "aboutTexts": {
        "1": "精簡又有關鍵字的標題，效果最佳",
        "2": "可以找出 1-2 個動詞加入標題，表達行動意圖",
        "3": "利用「形容詞」的堆疊讓對象「更明確化」"
      },
      "examples": [
        {
          "name": "「推動區域永續發展的地方團隊」",
          "url": ""
        },
        {
          "name": "「想參與在地議題的跨域夥伴」",
          "url": ""
        }
      ]
    },
    "targets_description": {
      "title": "需求描述  Tips",
      "description": "設計的本質在於「解決問題」與「創造新的價值」，從3個層面來思考：",
      "aboutContents": {
        "1": "銜接目標對象，說明該「需求」背後的「重要性」\\n\n<b>範例</b>：全球已有超過50個國家訂出未來實踐淨零的具體目標，但在教育體制內的永續課綱仍未得到滿足，最新報告也顯示「永續人才」大缺工是未來轉型關鍵。",
        "2": "嘗試說明上述需求為何得不到滿足的「痛點」\\n\n<b>範例</b>：體制內到教師投入永續教育質與量的提升，問題不在意願，而在「時間與資源」。如何能給予老師立即可用且容易理解的教案教材與校外資源，則是實質可提升的關鍵。",
        "3": "思考若需求為「真」，過往滿足需求的「作法」是什麼？\\n\n<b>範例</b>：老師過往教導所需的資源，往往需額外花費很多時間搜尋，但也因對議題不一定熟悉，往往事倍功半，且不見得能得到學校的支持。"
      },
      "aboutTexts": {
        "1": "『目標對象的需求及其重要性』，但因『需求未能被滿足的痛點』，請說明「過往滿足需求的『作法』」是什麼？"
      },
      "examples": [
        {
          "name": "「如何新舊融合與對話，並且延續產業特色與自然生態的足跡，讓更多新世代關注與理解？ 我們將透過「跨領域開放式創新」方式，探討淡海區域團隊產業特色與環境生態，被更多人看見，透過與在地團隊共創梳理與了解所面臨缺口，與社會設計師投入專業，重新設計與重新定義，讓淡水地方特色、產業價值提升，朝永續城鄉邁進」",
          "url": ""
        }
      ]
    },
    "vision": {
      "title": "行動願景  精彩說",
      "description": "以終為始地來思考，當行動執行完畢，期待達到什麼樣的階段目標？期待能替社會創造什麼價值？或是創造什麼樣的理想未來？透過「4大關鍵內容原則」，想一想，在行動簡述之中如何清楚說明：",
      "aboutContents": {
        "1": "緊密扣合與回應行動之初所鎖定的「目標對象」、「需求」與「痛點」\\n\n<b>範例</b>：隨著高齡社會化的來臨，人們平均壽命延長，但不健康餘命及長照需求也跟著增加。輔具跟照顧是一體的，缺一不可。",
        "2": "有明確時間說明「多遠」的願景\\n\n<b>範例</b>：小南人市集已經邁入第三年，今年將收斂前兩年計畫的經驗，轉化為可複製的教育模型與教案，透過教育計畫的過程，種下觸動行為改變的種子。",
        "3": "有夥伴及專業的投入，配搭「可衡量的指標」，說明行動目標「有做到」、「有做好」的情境，描述讓你感到振奮與期待的理想樣貌吧\\n\n<b>範例</b>：邀請格上提供GO SMART共享車服務，以雙北服務點作為示範驗場域，增加10 家里程數服務捐贈企業與100 位個人捐贈者。",
        "4": "長期投入後，如何運用「行動成果」，持續「發揮社會影響力」\\n\n<b>範例</b>：提供企業參與捐贈、累績減碳效益驗證媒合30 個社會團體成為合作夥伴加入，使用愛的里程數服務，解決交通問題、創造服務能量。"
      },
      "aboutTexts": {
        "1": "說明行動的『目標對象』、『需求』及『痛點』，明確說明『預計達成願景的時間』，因為有『什麼樣貌夥伴及專業的投入』的加入，可一起『做到並達成可衡量指標的情境』，未來如何持續「發揮社會影響力」。"
      },
      "examples": [
        {
          "name": "「針對淡水在地團隊在永續發展上面臨的議題，強化服務/產品的獨特性與在地性，並用設計轉譯產業與文化價值，有效與新世代對話。我們期待號召各式身懷專業的跨領域人才，透過在地團隊、企業的能量與跨域設計師共創合作的加入，打造能讓在地團隊運作且持續發展的設計創新提案，並有效推動地方落實，持續推動地方永續發展。」",
          "url": ""
        }
      ]
    },
    "keyItem": {
      "title": "整體簡介  精彩說",
      "description": "針對本次行動，簡要說明預計規劃哪些策略與作法，以及預期達成的目標與成果各是什麼？",
      "aboutContents": {
        "1": "銜接要達成上述行動願望的「策略」與「作法」\\n\n<b>舉例</b>：延續冠德企業集團所推出的大健康全齡宅，我們將以「永久售後服務」為基礎，建立一個平台。",
        "2": "可以思考分階段論述，並盡可能的具體化\\n\n<b>舉例</b>：輔具就像電腦的硬體，照顧則是軟體，兩個做結合才是恢復或維持長輩生活自理能力(復能)的最佳解方。",
        "3": "對應前述設定的「目標」與「關鍵產出」\\n\n<b>舉例</b>：將捐贈者的愛心貢獻可視化為有形數據，提供捐贈憑證和減碳排效益書，並以區塊鏈技術保障數據安全及透明。"
      },
      "aboutTexts": {
        "1": ""
      },
      "examples": [
        {
          "name": "「2022年，林堉璘宏泰教育基金會與 5% Design Action社會設計平台發起「淡海流動創生設計行動」。從淡水面臨的課題出發，號召各式身懷專業的跨領域人才，透過在地團隊、企業的能量與跨域設計師共創合作，一起為「淡水」未來的發展翻轉思考、重新定義，拼構出與時俱進的地方創新發展。」",
          "url": ""
        }
      ]
    },
    "features": {
      "title": "行動特色描述  精彩說",
      "description": "請清楚說明本次行動參與的行動家將能獲得的價值，包含承接與認識什麼樣的過去作為、接下來將創造什麼樣的影響，並能將這些價值分享、推薦給其他朋友。",
      "aboutContents": {
        "1": "<b>開頭</b>：在這次行動中會帶入什麼樣的過去成果或資源",
        "2": "<b>中間</b>：這些成果要透過行動來接觸的目標對象、問題，並創造什麼價值",
        "3": "<b>收尾</b>：如果行動成果將透過大型媒體宣傳曝光，你會用什麼句子來明確展現該行動與其成果的「獨特性」"
      },
      "aboutTexts": {
        "1": ""
      },
      "examples": [
        {
          "name": "「奠基南方澳鯖魚故鄉的產業生態，與在地夥伴共創永續漁業，為我們的海島建構下世代的漁村文化原型」 ",
          "url": ""
        }
      ]
    },
    "demand_service_name": {
      "title": "服務需求名稱 Tips",
      "description": "透過清晰描述「服務需求」，將更有效招募到適合的行動家參與！",
      "aboutContents": {
        "1": "為了達成行動目標，明確表示正找尋什麼「擁有資源的夥伴」\\n\n<b>範例</b>：綠色影響力數據專家",
        "2": "描述所需要服務的「關鍵字」\\n\n<b>範例</b>：擅長教案設計的老師、具有插畫能力的設計師"
      },
      "aboutTexts": {
        "1": "『可以提供什麼樣特定專業』的『夥伴名稱』"
      },
      "examples": [
        {
          "name": "「想參與地方議題的專業者」",
          "url": ""
        },
        {
          "name": "「想參與永續活動的師生」",
          "url": ""
        }
      ]
    },
    "demand_service_description": {
      "title": "服務需求描述 精彩說",
      "description": "為了達成行動的目標，你期待找到的理想夥伴，應該具備哪些特質及能力？",
      "aboutContents": {
        "1": "理想夥伴需具備哪些專業能力？",
        "2": "有沒有對於理想夥伴特質的要求？",
        "3": "期待理想夥伴能如何與團隊協作？",
        "4": "預計的關鍵產出是什麼？"
      },
      "aboutTexts": {
        "1": "『夥伴的專業能力』能替行動解決『需求』或是發展『關鍵產出』。"
      },
      "examples": [
        {
          "name": "「願意為地方文化帶來可持續創新之專業人士/大專以上師生」",
          "url": ""
        }
      ]
    },
    "demand_keyItemDescriptions": {
      "title": "重點合作項目 Tips",
      "description": "在行動中，請簡要說明有哪些大大小小的項目，需要透過合作夥伴才能共同完成。",
      "aboutContents": {
        "1": "期待與夥伴合作促成哪些「關鍵成果及效益」\\n\n<b>範例</b>：透過設計培訓方案1套，促使更多教師獲得 DFC 種子教師認證證書",
        "2": "使用關鍵數字或數據表示\\n\n<b>範例</b>：《日日挑戰》教材1套和參與培訓計畫3場",
        "3": "合作模式是「共同開發」或是「委託設計」？\\n\n<b>範例</b>：歡迎共同討論「聯名」開發及合作策略細節與收穫"
      },
      "aboutTexts": {
        "1": "可用 3-5 句話，條列式重點摘要的合作項目、關鍵成果及效益。"
      },
      "examples": [
        {
          "name": "「打造適合全齡共感友善寵物創新農場體驗/服務」",
          "url": ""
        }
      ]
    },
    "demand_award_description": {
      "title": "收獲描述 Tips",
      "description": "在行動完成後，合作夥伴將能獲得那些益處及收獲呢？透過「3大關鍵內容原則」想一想，在你的「合作收獲描述」中如何表達，將有助於吸引更多強者報名參與：\n",
      "aboutContents": {
        "1": "明確且能「公開表示」的合作展現方式\\n\n<b>範例</b>：與 5% Design Action 展開永續新生活策略合作、推動聯名開發，以及獲取廣告宣傳協助。",
        "2": "具體「可計算」的合作成果\\n\n<b>範例</b>：go action 年度電子報告書。",
        "3": "能夠實現夥伴的「理想目標」\\n\n<b>範例</b>：找到夥伴一起開創在地事業的機會。"
      },
      "aboutTexts": {
        "1": "可用 3-5 句話，摘要合作夥伴能獲得的益處"
      },
      "examples": [
        {
          "name": "「找到夥伴一起開創在地事業的機會」",
          "url": ""
        },
        {
          "name": "「歡迎共同討論聯名開發及策略合作細節與收穫」",
          "url": ""
        }
      ]
    },
    "demand_rules": {
      "title": "行動相關規範 Tips",
      "description": "在合作之前，為了讓本次合作夥伴能夠彼此相信、安心合作，可提出你們在乎相關規範。資訊清楚透明，將會找到更精準及適合的夥伴，創造理想的合作關係\n\n\n",
      "aboutContents": {
        "1": "是否需要簽訂「合約」？",
        "2": "明確定義需要「保密」的部份？",
        "3": "「可量化」的合作參與程度",
        "4": "明確表示「智財權」的歸屬"
      },
      "aboutTexts": {
        "1": "可用 3-5 句話，摘要合作夥伴需注意的行動規範"
      },
      "examples": [
        {
          "name": "「個人資料會去識別化處理，不會公開資料」",
          "url": ""
        }
      ]
    },
    "demand_funds_name": {
      "title": "資金需求名稱 Tips",
      "description": "透過清晰描述「資金需求」，將更有效招募到適合的參與者！",
      "aboutContents": {
        "1": "為了達成行動目標，明確表示正找尋什麼「擁有資金的夥伴」\\n\n<b>範例</b>：地方資源的串聯專家\n\n",
        "2": "描述所需要資金的「關鍵字」\\n\n<b>範例</b>：永續教育基金的投資人、樂於支持新創的天使投資人"
      },
      "aboutTexts": {
        "1": "『可以提供資金用於什麼樣的標的』的『夥伴名稱』"
      },
      "examples": [
        {
          "name": "「偏鄉教育的造夢者」",
          "url": ""
        },
        {
          "name": "「永續教育基金的投資人」",
          "url": ""
        }
      ]
    },
    "demand_space_name": {
      "title": "空間需求名稱 Tips",
      "description": "透過清晰描述「空間需求」，將更有效招募到適合的參與者！",
      "aboutContents": {
        "1": "為了達成行動目標，明確表示正找尋什麼樣的「空間」\\n\n<b>範例</b>：可實驗創新農作的農地",
        "2": "描述所需要空間的「關鍵字」\\n\n<b>範例</b>：開放創意的室內場域、重視世代共融的咖啡館"
      },
      "aboutTexts": {
        "1": "『可以提供什麼樣特定服務』的『空間名稱』"
      },
      "examples": [
        {
          "name": "「可長期耕作的農地」",
          "url": ""
        },
        {
          "name": "「開放學校的實驗場域」",
          "url": ""
        }
      ]
    },
    "demand_funds_description": {
      "title": "資金需求描述 精彩說",
      "description": "為了達成行動的目標，你期待找到擁有理想資金的夥伴，應該具備哪些特質及能力？",
      "aboutContents": {
        "1": "有沒有對於理想夥伴特質的要求？",
        "2": "期待理想夥伴能如何與團隊協作？",
        "3": "預計的關鍵產出是什麼？"
      },
      "aboutTexts": {
        "1": "『夥伴的資金能力』能替行動解決『需求』或是發展『關鍵產出』"
      },
      "examples": [
        {
          "name": "「永續教育基金的投資人，能替發展永續教育的夥伴，找到穩定的資源來源」",
          "url": ""
        }
      ]
    },
    "demand_space_description": {
      "title": "空間需求描述 精彩說",
      "description": "為了達成行動的目標，你期待找到的理想空間，應該具備哪些特點？",
      "aboutContents": {
        "1": "理想空間中需要能提供什麼專業服務？",
        "2": "認為理想的空間，必要條件是什麼呢？",
        "3": "預計的關鍵產出是什麼？"
      },
      "aboutTexts": {
        "1": "『理想的空間環境』能替行動解決『需求』或是發展『關鍵產出』。"
      },
      "examples": [
        {
          "name": "「開放學校的實驗場域，使學生及教師能夠自由發展，共同打造一個永續創意平台，」",
          "url": ""
        }
      ]
    }
  }
}

export default Tips
