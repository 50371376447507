import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import dayjs from 'dayjs';
import TextInput from 'src/components/TextInput';
import TreeSelect from 'src/components/TreeSelect';
import Select from 'src/components/Select';
import DatePicker from 'src/components/DatePicker';
import withErrorInput from 'src/components/withErrorInput';

import { SOCIALS_TYPE } from 'src/constants';
import ConstantsStore from 'src/stores/constants';

import Base from './Base';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);
const ErrorTreeSelect = withErrorInput(TreeSelect);

@observer
class Personal extends Base {

  renderBasic() {
    return (
      <div className={styles.wrapper}>
        <div className={clsx(styles.flexCol)}>
          {this.renderCommonField('displayName', this.vm.profile?.type)}

          {this.renderCommonField('motto', this.vm.profile?.type)}

          <ErrorTreeSelect
            key="job"
            showError={this.vm.showError}
            placeholder={t('register_personal_step1_job')}
            type="jobs"
            vm={this.vm.jobsSelectViewModel}
            value={this.vm.jobsSelectViewModel.selectedItems}
            required
          />

          {this.renderInterests()}
        </div>
      </div>
    );
  }

  renderSocial() {
    const socials = this.vm.profile?.info?.socialsObj;
    if (!socials) { return null; }

    return (
      <div className={clsx(styles.flexCol, styles.center, styles.marginTop30)}>
        <ErrorTextInput
          key="facebook"
          label={t('facebook_placeholder')}
          value={socials.facebook}
          onChange={(e) => this.vm.onSocialChange('facebook', e.target.value)}
          showError={!!socials.facebook && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Facebook]}
          errorMessage={t('url_is_not_valid')}
        />

        <ErrorTextInput
          key="instagram"
          label={t('instagram_placeholder')}
          value={socials.instagram}
          onChange={(e) => this.vm.onSocialChange('instagram', e.target.value)}
          showError={!!socials.instagram && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Instagram]}
          errorMessage={t('url_is_not_valid')}
        />

        <TextInput
          key="line"
          className={styles.marginBottom}
          label={t('profile_line_placeholder')}
          onChange={(e) => this.vm.onSocialChange('lineId', e.target.value)}
          value={socials.lineId}
        />

        <ErrorTextInput
          key="linkedIn"
          label={t('linkedin_placeholder')}
          value={socials.linkedin}
          onChange={(e) => this.vm.onSocialChange('linkedin', e.target.value)}
          showError={!!socials.linkedin && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Linkedin]}
          errorMessage={t('url_is_not_valid')}
        />
      </div>
    );
  }

  renderPrivate() {
    const profile = this.vm.profile ?? {};
    const info = this.vm.info ?? {};

    return (
      <div className={styles.wrapper}>
        <div className={clsx(styles.flexCol)}>

          {this.renderCommonField('email')}

          <div className={clsx(styles.phoneArea, styles.personal)}>
            <div className={clsx(styles.countryCode)}>
              <ErrorSelect
                placeholder={t('register_validate_personal_step2_countryCodePlaceholder')}
                value={profile.countryCode}
                showSearch
                onChange={(value) => this.vm.onChange('countryCode', value)}
                options={ConstantsStore.countryCodesOptions}
                showError={this.vm.showError}
                required
                validValue={profile.isCountryCodeValid}
                errorMessage={profile.countryCode ? t('format_is_wrong') : t('this_is_required')}
              />
            </div>
            <div style={{ width: 12 }} />
            <ErrorTextInput
              showError={this.vm.showError}
              key="phone 9 digit"
              label={t('profile_personal_phone')}
              onChange={(e) => this.vm.onChange('phone9Digit', e.target.value)}
              value={profile.phone9Digit}
              required
              type="number"
              validValue={profile.isPhoneValid}
              errorMessage={profile.phone9Digit ? t('format_is_wrong') : t('this_is_required')}
            />
          </div>

          {this.renderCommonField('password')}

          <h3 className={clsx(styles.profileSubtitle)}>{`/ ${t('profile_info')} /`}</h3>

          <TextInput
            key="name"
            className={styles.marginBottom}
            label={t('profile_info_name')}
            defaultValue={profile.name}
            readOnly
            required
          />

          <DatePicker
            key="birthday"
            label={t('profile_info_birthday')}
            className={styles.marginBottom}
            placeholder={t('profile_info_birthday')}
            onChange={(date, dateString) => {
              this.vm.onChange('birthday', date ? dayjs(date).toISOString() : null);
            }}
            style={{ width: '100%' }}
            value={info.birthday ? dayjs(info.birthday) : null}
          />

          <Select
            key="gender"
            placeholder={t('profile_info_gender')}
            className={clsx(styles.marginBottom, styles.select)}
            onChange={(value) => this.vm.onChange('gender', value)}
            options={ConstantsStore.gender}
            value={profile.gender}
          />

          <Select
            key="cityOfBirth"
            placeholder={t('profile_info_cityOfBirth')}
            className={styles.marginBottom}
            onChange={(value) => this.vm.onChange('cityOfBirth', value)}
            style={{ flexGrow: 1 }}
            options={ConstantsStore.flattenedRegionsOptions.filter((item) => item.value.slice(0, 1) !== '2')}
            value={info.cityOfBirth}
          />

          <Select
            key="cityOfResidence"
            placeholder={t('profile_info_cityOfResidence')}
            className={styles.marginBottom}
            onChange={(value) => this.vm.onChange('cityOfResidence', value)}
            style={{ flexGrow: 1 }}
            options={ConstantsStore.flattenedRegionsOptions.filter((item) => item.value.slice(0, 1) !== '2')}
            value={info.cityOfResidence}
          />

          <ErrorTextInput
            showError={this.vm.showError}
            key="company"
            label={t('profile_info_company')}
            value={info.company}
            onChange={(e) => this.vm.onChange('company', e.target.value)}
            required
          />
          <ErrorTextInput
            showError={this.vm.showError}
            key="jobTitle"
            label={t('profile_info_jobTitle')}
            value={info.jobTitle}
            onChange={(e) => this.vm.onChange('jobTitle', e.target.value)}
            required
          />
        </div>
      </div>
    );
  }
}

export default Personal;
