import { makeObservable, observable, action, runInAction } from 'mobx';
import diff from 'deep-diff';
import i18n from 'src/i18n';

import IS from 'src/utils/is';
import UserService from 'src/services/user';
import ErrorService from 'src/services/errors';
import ForgetPasswordModalVM from 'src/components/Modals/ForgetPasswordModal/vm';
import { USER_TYPES, VERIFY_STATUS } from 'src/constants';

class LoginPageViewModel {
  @observable name = null;
  @observable version = null;

  @observable email = '';
  @observable password = '';

  @observable emailError = null;
  @observable passwordError = null;
  @observable isAwait = false;

  @observable forgetPasswordEmail = '';
  @observable forgetPasswordModalVM;

  @observable isAccountNotExistModalOpen = false;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = (props) => {
    console.log('LoginPage.didMount, params', props.router.params);
  };

  @action didUpdate = (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile)) {
      this.name = cur.profile?.name;
    }

    if (cur.profile) {
      this.redirect();
    }
  };

  willUnmount = (props) => {
    console.log('WelcomePage.willUnmount');
  };

  checkEmail = () => {
    if (this.email.replace(/\s+/g, '').length === 0) {
      this.emailError = i18n.t('login_enter_email');
    } else if (!IS.email(this.email)) {
      this.emailError = i18n.t('login_email_error');
    } else {
      this.emailError = null;
    }
  };

  @action onEmailChange = (e) => {
    this.email = e.target.value;
    this.emailError = null;
  };

  checkPassword = () => {
    if (this.password.length === 0) {
      this.passwordError = i18n.t('login_enter_password');
    } else if (this.password.length < 8) {
      this.passwordError = i18n.t('login_password_error');
    } else {
      this.passwordError = null;
    }
  };

  @action onPasswordChange = (e) => {
    this.password = e.target.value;
    this.passwordError = null;
  };

  @action onLoginClick = async () => {
    this.checkEmail();
    this.checkPassword();
    if (this.emailError || this.passwordError) {
      return;
    }

    this.isAwait = true;

    try {
      const { data } = await UserService.login(this.email, this.password);
      console.log(data);
      const { profile } = data;
      switch (profile.type) {
        case USER_TYPES.Personal:
          if (profile.emailStatus !== VERIFY_STATUS.Verified || profile.phoneStatus !== VERIFY_STATUS.Verified) {
            this.toRegister();
            return;
          }
          break;
        case USER_TYPES.Organization:
          if (profile.emailStatus !== VERIFY_STATUS.Verified) {
            this.toRegister();
            return;
          }
          break;
        default:
      }

      try {
        const { info } = await UserService.getDetail();
        console.log(info);
        if (info.sdgs.length === 0 || info.cities.length === 0 || !info.motto) {
          this.toRegister();
          return;
        }
      } catch (err) {
        ErrorService.onDefaultError(err);
      } finally {
        this.redirect();
      }

    } catch (err) {
      if (err.response?.status === 404) {
        this.toggleAccountNotExistModal();
      } else if (err.response?.status === 401) {
        this.passwordError = i18n.t('login_password_error');
      } else {
        ErrorService.onDefaultError(err);
      }
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action clickForgetPassword = () => {
    if (this.forgetPasswordModalVM) {
      this.forgetPasswordModalVM.isForgetPasswordModalOpen = true;
    } else {
      this.forgetPasswordModalVM = new ForgetPasswordModalVM(this.props);
    }
  };

  @action onForgetPasswordEmailChange = (e) => {
    this.forgetPasswordEmail = e.target.value;
  };

  redirect = () => {
    const { navigate, location } = this.props.router;
    console.log(this.props.router);
    const source = location?.state?.source;
    if (source) {
      navigate(source, { replace: true });
    } else {
      this.toHomePage();
    }
  };

  toHomePage = () => {
    const { navigate } = this.props.router;
    navigate('/', { replace: true });
  };

  toRegister = () => {
    const { navigate } = this.props.router;
    navigate('/register', { state: { email: this.email } });
  };

  @action toggleAccountNotExistModal = () => {
    this.isAccountNotExistModalOpen = !this.isAccountNotExistModalOpen;
  };
}

export default LoginPageViewModel;
