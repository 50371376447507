import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import scrollDetector from 'src/stores/scrollDetector';
import { EVENTCARD_TYPE } from 'src/constants';

import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Tabs from 'src/components/Tabs';
import WithdrawApplicationModal from 'src/components/Modals/WithdrawApplicationModal';
import EventCardForManagement from 'src/components/EventCard/ForManagement';
import EventFilter from 'src/components/EventFilter';
import Spin from 'antd/es/spin';

import eyeEmoji from 'src/assets/eyeEmoji.png';
import EventJoinedPageViewModel, { TABS } from './vm';

import styles from './styles.module.scss';

@observer
class EventJoinedPage extends React.Component {
  constructor(props) {
    super(props);

    this.vm = new EventJoinedPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  getMenuItemsInMore(item) {
    const Options = {
      ViewEvent: {
        key: 'viewEvent',
        label: (
          <div onClick={() => this.vm.toEvent(item.event.id)}>
            {t('event_joined_view_event')}
          </div>
        )
      },
      ViewApplication: {
        key: 'viewApplication',
        label: (
          <div onClick={() => this.vm.toApplication(item.id)}>
            {t('event_joined_view_application')}
          </div>
        )
      },
      Withdraw: {
        key: 'withdraw',
        label: (
          <div onClick={() => this.vm.withdrawApplication(item.id, item.event?.name)}>
            {t('event_joined_withdraw')}
          </div>
        )
      }
    };
    if (!item) return [];

    switch (this.vm.currentTab) {
      case TABS.Accepted:
        return [
          Options.ViewEvent,
          Options.ViewApplication
        ];

      case TABS.Applied:
        return [
          Options.ViewEvent,
          Options.ViewApplication,
          Options.Withdraw
        ];
      default:
        return [];
    }
  }

  renderTopArea() {
    return (
      <>
        <div className={styles.topArea}>
          <div className={styles.topContainer}>
            <div className={styles.title}>
              <div>Actions</div>
              <div>{t('event_joined_title')}</div>
            </div>
          </div>
        </div>

        {this.vm.filterVM && (
          <EventFilter
            isMobile={this.vm.isMobile}
            vm={this.vm.filterVM}
            className={clsx(styles.filterArea, !scrollDetector.isScrollingDown && styles.lower)}
            containerClassName={styles.container}
            placeholder={t('search')}
            color="white"
            sortMenuItems={[
              {
                key: 'desc',
                label: (
                  <div
                    onClick={() => this.vm.filterVM.handleOrder('desc')}
                  >
                    {t('event_joined_sort_desc')}
                  </div>
                )
              },
              {
                key: 'asc',
                label: (
                  <div
                    onClick={() => this.vm.filterVM.handleOrder('asc')}
                  >
                    {t('event_joined_sort_asc')}
                  </div>
                )
              }
            ]}
          />
        )}
      </>
    );
  }

  renderEventList() {
    return (
      <div className={styles.list}>
        {this.vm.lists[this.vm.currentTab]?.map((item) => (
          <EventCardForManagement
            type={EVENTCARD_TYPE.Joined}
            className={styles.card}
            item={item.event}
            acceptedAt={item.acceptedAt}
            createdAt={item.createdAt}
            key={item.id}
            colorMode="light"
            dropdownMenu={{ items: this.getMenuItemsInMore(item) }}
          />
        ))}
      </div>
    );
  }

  renderNoContentHint() {
    if (this.vm.AnchoredLists[this.vm.currentTab].isAwait) {
      return (
        <div className={styles.emptyContainer}>
          <Spin />
        </div>
      );
    }

    return (
      <div className={styles.emptyContainer}>
        <div className={styles.emptyMessage}>
          <div className={styles.title}>
            {t('empty_message_title')}
          </div>
          <div className={styles.text}>
            {(this.vm.filterVM?.hasAppliedFilter || this.vm.filterVM?.filter?.keyword)
              ? t('filter_no_result')
              : (
                <>
                  {t('event_joined_no_content_text')}
                  <div
                    className={styles.viewEventsBox}
                    onClick={this.vm.toEventListPage}
                  >
                    <div>
                      {t('event_joined_view_events_1')}
                      <br />
                      {t('event_joined_view_events_2')}
                    </div>
                    <img
                      alt="eye"
                      src={eyeEmoji}
                      className={styles.eye}
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={clsx(styles.eventJoinedPage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
        >
          <Breadcrumb
            routes={[
              { label: t('my_account'), path: `/user/profile/${this.vm.profile?.id ?? ''}` },
              { label: '', path: '' }
            ]}
            right={null}
            colorType="purple"
            withHeader
          />
          {this.renderTopArea()}
          <div className={styles.mainContainer}>
            <Tabs
              colorMode={2}
              items={[
                {
                  value: TABS.Accepted,
                  label: t('event_joined_tab_accepted'),
                  content: this.vm.lists[TABS.Accepted]?.length ? this.renderEventList() : this.renderNoContentHint()
                },
                {
                  value: TABS.Applied,
                  label: t('event_joined_tab_applied'),
                  content: this.vm.lists[TABS.Applied]?.length ? this.renderEventList() : this.renderNoContentHint()
                }
              ]}
              onSwitchTab={this.vm.switchTab}
              currentValue={this.vm.currentTab}
              className={styles.eventTabs}
            />
            {
              this.vm.anchor
                ? (
                  <>
                    {this.vm.isAwait && <Spin style={{ margin: '0 auto' }} />}
                    <Waypoint
                      scrollableAncestor={window}
                      onEnter={this.vm.onScrollEnd}
                    />
                  </>
                )
                : null
            }
          </div>

        </MainLayout>

        {this.vm.withdrawApplicationVM && (
          <WithdrawApplicationModal vm={this.vm.withdrawApplicationVM} isMobile={this.vm.isMobile} />
        )}
      </div>
    );
  }
}

EventJoinedPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(EventJoinedPage)), true);
