import {
  observable, action, computed, makeObservable, runInAction
} from 'mobx';
import { request, getHost, getHeader } from 'src/apis/utils';
import pretendTo from 'src/stores/pretendTo';
import { LIST_MODEL_TYPE } from 'src/constants';
import ErrorService from 'src/services/errors';
import Application from 'src/models/response/Application';
import Event from 'src/models/response/Event';
import EventJoined from 'src/models/response/EventJoined';
import Resource from 'src/models/response/Resource';
import Purchase from 'src/models/response/Purchase';
import Order from 'src/models/response/Order';
import Notification from 'src/models/response/Notification';
import Belong from 'src/models/response/Profile/Belong';

class AnchoredList {
  @observable filter;
  @observable otherParams = {};
  @observable path;
  @observable anchor = null;
  @observable count = 0;
  @observable isAwait = false;
  @observable method = 'post';

  @observable list = [];

  modelType;

  constructor(props) {
    makeObservable(this);
    runInAction(() => {
      this.props = props;
      this.modelType = props.modelType;
      this.path = props.path;
      this.method = props.method || 'post';
    });
  }

  @computed get params() {
    return {
      ...this.otherParams,
      anchor: this.anchor
    };
  }

  @computed get dataModel() {
    switch (this.modelType) {
      case LIST_MODEL_TYPE.Event:
        return Event;
      case LIST_MODEL_TYPE.EventJoined:
        return EventJoined;
      case LIST_MODEL_TYPE.Resource:
        return Resource;
      case LIST_MODEL_TYPE.Application:
        return Application;
      case LIST_MODEL_TYPE.Purchase:
        return Purchase;
      case LIST_MODEL_TYPE.Order:
        return Order;
      case LIST_MODEL_TYPE.Notification:
        return Notification;
      case LIST_MODEL_TYPE.Belong:
        return Belong;
      default:
        return {};
    }
  }

  @action getList = async (filter, otherParams, shouldReset = true) => {
    if (this.isAwait) return;
    this.filter = filter;
    this.otherParams = otherParams || this.otherParams;

    if (shouldReset) {
      this.reset();
    }

    try {
      this.isAwait = true;
      const { data } = await this.getListAPI();
      const list = data.list?.map((d) => this.dataModel.fromRes(d));
      console.log('get list', list);

      runInAction(() => {
        this.list.push(...list);
        this.anchor = data.anchor;
        this.count = data.count ?? this.count;
      });
    } catch (error) {
      ErrorService.onDefaultError(error);
      console.log('Error fetching list', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  getListAPI = async () => {
    const options = {
      method: this.method,
      url: `${getHost()}/${this.path}`,
      headers: getHeader(),
      params: this.params
    };

    if (this.filter) {
      options.data = this.filter;
    }

    return request(options);
  };

  @action getNext = async () => {
    console.log('====get next', this.anchor);
    if (this.anchor) {
      await this.getList(this.filter, null, false);
    }
  };

  @action reset = () => {
    this.anchor = null;
    this.list = [];
  };

  @action setPath = (path) => {
    this.path = path;
  };

  @action filterList = (filterFuc) => {
    this.list = this.list.filter(filterFuc);
  };

  @action updateItem = (newItem) => {
    const index = this.list.findIndex((obj) => obj.id === newItem.id);
    if (index > -1) {
      this.list[index] = newItem;
      this.list = [...this.list];
    }
  };

}

export default AnchoredList;
