import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD, EVENT_TIPS } from 'src/constants';
import TextInput from 'src/components/TextInput';
import UploadPhoto from 'src/components/UploadPhoto';
import withErrorInput from 'src/components/withErrorInput';
import SDGsBubble from 'src/components/Bubble/SDGsBubble';
import StickyGroupContainer from 'src/pages/CreateEvent/CreateEventPage/components/StickyGroupContainer';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
@observer
class KeyFinding extends StepBase {
  constructor(props) {
    super();
    this.props = props;
  }

  renderContent() {
    const { vm } = this.props;
    const event = vm.event ?? {};
    const fieldName = EVENT_FIELD.KeyFinding;
    const keyFinding = event[fieldName];
    const isSticky = !vm.showPreview;

    if (!keyFinding) return null;


    return (
      <>
        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>
            <div className={styles.fieldDesc}>
              {t('create_event_sdg_desc')}
            </div>
            <div className={styles.sdgsOuterWrapper}>
              <div className={clsx(styles.sdgsBubbleWrapper, 'bubbleWrapper')}>
                <SDGsBubble
                  containerClassName={clsx(styles.sdgBubblesContainer, 'sdgsBubblesXSContainer')}
                  sdgsDataKey="sdgsOptions"
                  handleToggleSdg={vm.handleChangeSdg}
                  sdgs={event[EVENT_FIELD.Sdgs]}
                />
              </div>
            </div>
          </div>
          <Tips isHidden />
        </section>

        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={vm.tipHeight[EVENT_TIPS.KeyFindingDesc]}
            topOffset={vm.titleAreaHeight}
          >
            <ErrorTextInput
              key="keyFinding_desc"
              label={t(`event_field_${fieldName}_description`)}
              onChange={(e) => vm.onChange(fieldName, e.target.value, 'description')}
              value={keyFinding.description ?? ''}
              limit={300}
              multiline
              required
              onBlur={() => vm.onSave(fieldName)}
            />
          </StickyGroupContainer>

          <Tips
            fieldName={EVENT_TIPS.KeyFindingDesc}
            isHidden={vm.showPreview}
            setTipHeight={vm.setTipHeight}
          />
        </section>

        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={vm.tipHeight[EVENT_TIPS.KeyFindingTitle]}
            topOffset={vm.titleAreaHeight}
          >
            <ErrorTextInput
              key="keyFinding_title"
              label={t(`event_field_${fieldName}_title`)}
              onChange={(e) => vm.onChange(fieldName, e.target.value, 'title')}
              value={keyFinding.title ?? ''}
              limit={20}
              multiline
              required
              onBlur={() => vm.onSave(fieldName)}
            />
          </StickyGroupContainer>

          <Tips
            fieldName={EVENT_TIPS.KeyFindingTitle}
            isHidden={vm.showPreview}
            setTipHeight={vm.setTipHeight}
          />
        </section>

        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>
            {keyFinding.uploadPhotoVM && (
              <UploadPhoto
                placeholder={t('event_field_photo')}
                label={t('event_field_photo')}
                className={clsx(styles.uploadPhoto, vm.showError && vm.uploadPhotoVM[fieldName].postList?.length === 0 && 'redBorder', 'uploadFullWidth')}
                vm={keyFinding.uploadPhotoVM}
                customUpload={() => vm.onUploadPhoto(keyFinding, fieldName)}
              />
            )}

            <ErrorTextInput
              key="keyFinding_video"
              label={t('event_field_youtube_link')}
              onChange={(e) => vm.onChange(fieldName, e.target.value, 'video')}
              value={keyFinding.video}
              showError={!keyFinding.isVideoValid}
              validValue={keyFinding.isVideoValid}
              errorMessage={t('url_is_not_valid')}
              onBlur={() => vm.onSave(fieldName)}
            />
          </div>
          <Tips isHidden />
        </section>
      </>
    );
  }

  render() {
    const {
      vm
    } = this.props;

    return !!vm && this.renderContent();
  }
}

KeyFinding.propTypes = {
  vm: PropTypes.object
};

KeyFinding.defaultProps = {
  vm: {}
};

export default KeyFinding;
