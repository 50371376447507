import {
  makeObservable, observable, action, runInAction, reaction, toJS
} from 'mobx';
import diff from 'deep-diff';
import BannerService from 'src/services/banner';
import ServiceTypes from 'src/constants/gens/ServiceTypes.gen';
import i18n from 'src/i18n';
import { t } from 'i18next';
import ErrorService from 'src/services/errors';
import { SDGsOrder } from 'src/constants/gens/SDGs.gen';
import { RegionsOrder } from 'src/constants/gens/Regions.gen';
import MemberOnlyFeatureModalVM, { getBlockReason } from 'src/components/Modals/MemberOnlyFeatureModal/vm';
import { BLOCK_REASONS } from 'src/constants';
import ConstantsStore from 'src/stores/constants';

export default class HomePageViewModel {
  @observable name = null;
  @observable version = null;
  @observable bannerList = [];
  @observable sdgs = [];
  @observable cities = [];
  @observable targetServiceTypes = [];
  @observable currentArea = null;
  // bubble selected count
  @observable count = {};
  autoCountSelected = null;

  @observable memberOnlyFeatureModalVM;
  @observable blockReason = null;


  constructor(props) {
    makeObservable(this);
    this.props = props;

  }

  @action didMount = (props) => {
    this.props = props;
    this.getBannerList();
    this.autoCountSelected = reaction(() => this.cities.length, () => {
      this.areaSelectedCitiesCount();
    });
  };

  @action didUpdate = async (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile)) {
      this.props = props;
    }
  };

  willUnmount = (props) => {
    console.log('HomePage.willUnmount');
    this.autoCountSelected();
  };

  getBannerList = async () => {
    try {
      const { list } = await BannerService.getBannerList();
      runInAction(() => {
        this.bannerList = list;
      });

    } catch (error) {
      if (error.response?.status === 401) { // when token is expired
        ErrorService.onCustomError(
          i18n.t('general_error_content_401'),
          null,
          () => this.props.router.navigate(0)
        );
      } else {
        ErrorService.onDefaultError(error);
      }
    }
  };

  @action.bound
  areaSelectedCitiesCount() {
    this.count = {};

    this.cities.forEach((city) => {
      const area = city.split('-')[1];
      if (this.count[area]) {
        this.count[area] += 1;
      } else {
        this.count[area] = 1;
      }
    });
  }


  sendGAEvent(eventName, context) {
    switch (eventName) {
      case 'Home_FindEvent_Click':
        window.gtag('event', 'Home_FindEvent_Click', {
          SDGs: this.sdgs.sort((a, b) => SDGsOrder[a] - SDGsOrder[b]).join(','),
          Location: this.cities.sort((a, b) => RegionsOrder[a] - RegionsOrder[b]).join(',')
        });
        break;
      case 'Home_FindResource_Click':
        window.gtag('event', 'Home_FindResource_Click', {
          Skill: context.skill
        });
        break;
      default:
    }
  }

  @action
    handleToggleSdg = (value, add) => {
      if (add) {
        this.sdgs.push(value);
      } else {
        this.sdgs = this.sdgs.filter((sdg) => sdg !== value);
      }
    };

  @action
    updateServiceTypesByTag = (type) => {
      this.targetServiceTypes = [];
      const serviceTypes = ServiceTypes[ConstantsStore.language];
      Object.keys(serviceTypes).forEach((key) => {
        Object.keys(serviceTypes[key].childs).forEach((childId) => {
          const childContent = serviceTypes[key].childs[childId];
          if (childContent.tag === type) {
            this.targetServiceTypes.push(childId);
          }
        });
      });
    };


  @action handleSwitchArea = (value) => {
    if (this.currentArea === value) {
      this.currentArea = null;
    } else {
      this.currentArea = value;
    }
  };


  @action
    handleToggleCity = (value) => {
      if (!this.cities.includes(value)) {
        this.cities.push(value);
      } else {
        this.cities = this.cities.filter((city) => city !== value);
      }
    };

  @action onClickAddResource = () => {
    const { router, profile } = this.props;

    const blockReason = getBlockReason(profile);
    if (!blockReason) {
      router.navigate('user/my-resource', { state: { openAddResourceModel: true } });
      return;
    }

    this.toggleModal(blockReason);
  };

  @action onClickAddAction = () => {
    const { router, profile } = this.props;

    const blockReason = getBlockReason(profile);
    if (!blockReason) {
      if (profile?.isEventCreator) {
        router.navigate('create-event-overview');
      } else {
        this.toggleModal(BLOCK_REASONS.EventCreator);
      }
      return;
    }

    this.toggleModal(blockReason);
  };

  @action toggleModal = (blockReason) => {
    const reason = blockReason === BLOCK_REASONS.Login ? BLOCK_REASONS.LoginOnHomePage : blockReason;
    if (this.blockReason === reason) {
      this.memberOnlyFeatureModalVM.openModal();
    } else {
      this.blockReason = reason;
      this.memberOnlyFeatureModalVM = new MemberOnlyFeatureModalVM({ blockReason: reason, router: this.props.router });
    }
  };

  @action closeModal = () => {
    this.memberOnlyFeatureModalVM.closeModal();
  };
}
