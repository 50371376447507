import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Success from 'src/components/Success';

@observer
class Instruction extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <Success
        title={t('register_organization_instruction_title')}
        texts={[t('register_organization_instruction_text1')]}
        textNewLine={t('register_organization_instruction_text2')}
        buttonText={t('register_organization_instruction_button')}
        onClickButton={this.vm.onNextClick}
        bottomText={t('register_organization_instruction_bottom_text')}
        bottomLinkText={t('register_organization_instruction_goToHomePage')}
        bottomLinkHref="/"
        containerStyle={{ padding: '0 20px' }}
        illustration="phone"
      />
    );
  }
}

Instruction.propTypes = {
  vm: PropTypes.object
};

Instruction.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Instruction)), false);
