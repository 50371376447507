import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';
import { RESOURCE_TYPES } from 'src/constants';
import TextInput from 'src/components/TextInput';
import withErrorInput from 'src/components/withErrorInput';
import IS from 'src/utils/is';

import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class Step2 extends React.Component {
  constructor(props) {
    super(props);
  }

  renderInput() {
    const { vm } = this.props;

    return (
      <div className={styles.flexCol}>
        <ErrorTextInput
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_expectance_placeholder')}
          label={i18n.t('myresource_form_expectance_tag')}
          value={vm.data?.expectance}
          onChange={(e) => vm.onDataChange('expectance', e.target.value)}
          errorMessage={(vm.data?.expectance && !vm.isExpectanceValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
          validValue={vm.isExpectanceValid}
          multiline
          required
          limit={300}
          caption={i18n.t('myresource_form_expectance_info')}
        />
      </div>
    );
  }


  renderLinks(key) {

    const { vm } = this.props;

    let _placeholder1;
    let _placeholder2;

    switch (key) {
      case 'creations':
        _placeholder1 = i18n.t('myresource_form_links_creations_url');
        _placeholder2 = i18n.t('myresource_form_links_creations');
        break;
      case 'certs':
        _placeholder1 = i18n.t('myresource_form_links_certs_url');
        _placeholder2 = i18n.t('myresource_form_links_certs');
        break;
      case 'links':
      default:
        _placeholder1 = i18n.t('myresource_form_links_links_url');
        _placeholder2 = i18n.t('myresource_form_links_links');
    }

    return (
      <div className={styles.flexCol} style={{ marginTop: 20 }}>
        {
          vm.data[key]?.map((el, index) => {

            const linkValidation = (item) => {
              if (!item.name && !item.url) return true;
              if (!!item.name && IS.url(item.url)) return true;
              return false;
            };

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <ErrorTextInput
                  value={el.url}
                  showError={!linkValidation(el)}
                  validValue={IS.url(el.url)}
                  errorMessage={(el.url && !IS.url(el.url)) ? i18n.t('format_is_wrong') : i18n.t('this_is_required')}
                  placeholder={_placeholder1}
                  onChange={(e) => vm.onLinksChange('url', e.target.value, key, index)}
                />
                <ErrorTextInput
                  value={el.name}
                  showError={!linkValidation(el)}
                  placeholder={_placeholder2}
                  onChange={(e) => vm.onLinksChange('name', e.target.value, key, index)}
                />
              </div>
            );
          })
        }

        {
          (vm.data[key]?.length < 5 || !vm.data[key]) && (
            <div className={styles.newBtn} onClick={() => vm.onLinksAdd(key)}>
              + new
            </div>
          )
        }
      </div>
    );

  }

  render() {
    const { type } = this.props;

    return (
      <div className={styles.step}>
        <div className={styles.block}>
          <div className={styles.title}>{i18n.t('myresource_detail_title')}</div>
          <div className={styles.desc}>{i18n.t('myresource_detail_subtitle')}</div>
        </div>
        {this.renderInput()}
        {
          type !== RESOURCE_TYPES.Space && (
            <>
              <div className={styles.linkTitle}>
                {i18n.t('myresource_form_links_seperator')}
              </div>
              {type === RESOURCE_TYPES.Service && this.renderLinks('creations')}
              {type === RESOURCE_TYPES.Service && this.renderLinks('certs')}
              {type === RESOURCE_TYPES.Funds && this.renderLinks('links')}
            </>
          )
        }
      </div>
    );
  }
}

export default Step2;

Step2.propTypes = {
  vm: PropTypes.object,
  type: PropTypes.string
};

Step2.defaultProps = {
  vm: {},
  type: null
};
