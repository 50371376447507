import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Mousewheel } from 'swiper';

import ConstantsStore from 'src/stores/constants';
import Tooltip from 'antd/es/tooltip';
import Dropdown from 'antd/es/dropdown';
import Spin from 'antd/es/spin';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import BaseModal from 'src/components/Modals/BaseModal';
import MainLayout from 'src/components/MainLayout';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import EventCard from 'src/components/EventCard';
import ResourceCard from 'src/components/ResourceCard';
import ResourceAddModal from 'src/components/Modals/ResourceAddModal';
import MemberOnlyFeatureModal from 'src/components/Modals/MemberOnlyFeatureModal';
import OrganizationAuthModal from 'src/components/Modals/OrganizationAuthModal';
import BelongCard from 'src/components/BelongCard';
import { RESOURCE_TYPES, USER_TYPES, ORG_REVIEW_TYPES, SOCIALS_TYPE } from 'src/constants';
import { Waypoint } from 'react-waypoint';

// assets
import facebookSvg from 'src/assets/facebook.svg';
import instagramSvg from 'src/assets/instagram.svg';
import lineSvg from 'src/assets/line.svg';
import linkedinSvg from 'src/assets/linkedin.svg';
import websiteSvg from 'src/assets/iconWebsite.svg';
import Tabs from 'src/components/Tabs';
import fakeAvatar from 'src/assets/fakeAvatar.png';
import quotationMark from 'src/assets/quotationMark.png';
import iconEdit from 'src/assets/iconEdit.svg';
import blueTick from 'src/assets/blueTick.png';
import iconUser from 'src/assets/iconUser.svg';
import iconMoney from 'src/assets/iconMoney.svg';
import iconMap from 'src/assets/iconMap.svg';
import iconClockThick from 'src/assets/iconClockThick.svg';
import iconNotice from 'src/assets/iconNotice.svg';
import iconAdd from 'src/assets/iconAdd.svg';
import iconEditWhite from 'src/assets/iconEditWhite.svg';

import ProfilePageViewModel, { PANEL, LIST_TYPE } from './vm';

import styles from './styles.module.scss';

// install Swiper modules
SwiperCore.use([Mousewheel]);

@observer
class ProfilePage extends React.Component {
  createdEventsRef = React.createRef();
  joinedEventsRef = React.createRef();
  serviceRef = React.createRef();
  spaceRef = React.createRef();
  fundsRef = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new ProfilePageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
    // fix Safari 100% !== 100vh
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  renderProfile() {
    if (this.vm.identity === 'personal') {
      return this.renderPersonalProfile();
    }

    return this.renderOrganizationProfile();
  }

  renderEvents() {
    const items = [
      {
        key: '1',
        label: (
          <div onClick={this.vm.clickDropdownEventHosted}>
            {t('profile_arrange_events_I_hosted')}
          </div>
        )
      },
      {
        key: '2',
        label: (
          <Link to="/user/event-joined">
            {t('profile_arrange_events_I_joined')}
          </Link>
        )
      }
    ];

    return (
      <div className={clsx(styles.listContainer, styles.eventList, this.vm.actives.panel === PANEL.Event && styles.active)}>
        <div
          className={clsx(styles.bar, styles.flexRow)}
          onClick={(e) => this.vm.onPanelClick(e, PANEL.Event)}
        >
          <div className={styles.barTitle}>{t('profile_tab_actions')}</div>
          {(this.vm.isMe)
            ? (
              <div className={clsx(styles.flexRow)}>
                {this.vm.profile.isEventCreator && (
                <Icon
                  size={28}
                  svg
                  className={styles.iconButton}
                  onClick={this.vm.addEvent}
                >
                  {iconAdd}
                </Icon>
                )}
                <Dropdown
                  placement="bottomRight"
                  menu={{ items }}
                >
                  <Icon
                    size={28}
                    svg
                    className={styles.iconButton}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {iconEditWhite}
                  </Icon>
                </Dropdown>
              </div>
            )
            : (
              <div className={styles.number}>
                {`/ ${this.vm.eventsTotalCount}`}
              </div>
            )}
        </div>
        <div className={styles.mainContentWrapper} onClick={this.vm.stopPropagation}>
          <Tabs
            items={[
              {
                value: LIST_TYPE.CreatedEvents,
                label: t('profile_tab_event_I_hosted'),
                badge: this.vm.AnchoredLists[LIST_TYPE.CreatedEvents].count,
                content: (
                  <>
                    <hr className="dashed" style={{ margin: '15px 0' }} />
                    <div className={styles.content}>
                      {this.renderEventIHosted()}
                    </div>
                  </>
                )
              },
              {
                value: LIST_TYPE.JoinedEvents,
                label: t('profile_tab_event_I_joined'),
                badge: this.vm.AnchoredLists[LIST_TYPE.JoinedEvents].count,
                content: (
                  <>
                    <hr className="dashed" style={{ margin: '15px 0' }} />
                    <div className={styles.content}>
                      {this.renderEventIJoined()}
                    </div>
                  </>
                )
              }
            ]}
            onSwitchTab={this.vm.switchEventTab}
            currentValue={this.vm.actives.event}
          />
        </div>
      </div>
    );

  }

  renderResources() {

    return (
      <div className={clsx(styles.listContainer, styles.resourceList, this.vm.actives.panel === PANEL.Resource && styles.active)}>
        <div
          className={clsx(styles.bar, styles.flexRow, styles.resourceBar)}
          onClick={(e) => this.vm.onPanelClick(e, PANEL.Resource)}
        >
          <div className={styles.barTitle}>{t('profile_tab_resources')}</div>
          {(this.vm.isMe)
            ? (
              <div className={clsx(styles.flexRow)}>
                <Icon
                  size={28}
                  svg
                  className={styles.iconButton}
                  onClick={this.vm.addResource}
                >
                  {iconAdd}
                </Icon>
                <Icon
                  size={28}
                  svg
                  className={styles.iconButton}
                  onClick={() => this.props.router.navigate('/user/my-resource')}
                >
                  {iconEditWhite}
                </Icon>
              </div>
            ) : (
              <div className={styles.number}>
                {/* TODO: phase 3 */}
                {/* {`/ ${this.vm.resourcesTotalCount}`} */}
              </div>
            )}
        </div>

        <div style={{ padding: '0 20px', height: '100%' }} onClick={this.vm.stopPropagation}>
          <Tabs
            items={[
              {
                value: LIST_TYPE.Service,
                label: t('profile_tab_service'),
                // badge: this.vm.service.length,
                content: !this.vm.service.length
                  ? this.renderEmpty(LIST_TYPE.Service)
                  : (
                    <div className={styles.content}>
                      {this.renderService()}
                    </div>
                  )
              },
              {
                value: LIST_TYPE.Space,
                label: t('profile_tab_space'),
                // badge: this.vm.space.length,
                content: !this.vm.space.length
                  ? this.renderEmpty(LIST_TYPE.Space)
                  : (
                    <div className={styles.content}>
                      {this.renderSpace()}
                    </div>
                  )
              },
              {
                value: LIST_TYPE.Funds,
                label: t('profile_tab_funds'),
                // badge: this.vm.funds.length,
                content: !this.vm.funds.length
                  ? this.renderEmpty(LIST_TYPE.Funds)
                  : (
                    <div className={styles.content}>
                      {this.renderFunds()}
                    </div>
                  )
              }
            ]}
            onSwitchTab={this.vm.switchResourceTab}
            currentValue={this.vm.actives.resource}
          />
        </div>
      </div>
    );

  }

  // ////////////////////////////////
  renderTopArea() {
    const { profile, info } = this.vm;
    const shouldShrink = this.vm.isMobile && !!this.vm.actives.panel;

    return (
      <div className={clsx(styles.topArea, shouldShrink && styles.smallTop)}>
        <div className={styles.top}>
          <div
            style={{ backgroundImage: `url(${profile?.avatar ?? fakeAvatar})` }}
            className={clsx(styles.avatar)}
          />
          <div className={clsx(styles.buttons, shouldShrink && styles.hide)}>
            {this.vm.totalMembers !== 0 && (
              <span className={styles.memberCountText}>
                {`${t('profile_org_members_count_1')} ${this.vm.totalMembers} ${t('profile_org_members_count_2')}`}
              </span>
            )}

            {this.vm.shouldShowBelongButton && (
              <Button
                className={clsx(styles.moreButton, styles.belongButton)}
                type="default"
                onClick={this.vm.toggleBelongModal}
              >
                {t('profile_belong')}
              </Button>
            )}

            {this.vm.shouldShowMoreInfoButton && (
              <Button
                className={styles.moreButton}
                type="default"
                onClick={this.vm.toggleMoreInfoModal}
              >
                {t('more')}
              </Button>
            )}

            {(this.vm.isMe)
            && (
            <Icon
              size={24}
              svg
              onClick={this.vm.clickEdit}
              className={styles.editProfileBtn}
            >
              {iconEdit}
            </Icon>
            )}
          </div>
          <div className={clsx(styles.flexRow, styles.motto, shouldShrink && styles.hide)}>
            <img
              style={{ width: 24 }}
              src={quotationMark}
              alt="quotation mark"
            />
            <h3 className={styles.mottoText}>{info?.motto}</h3>
          </div>
        </div>
        <div className={clsx(styles.flexRow, styles.nameArea)} style={{ marginBottom: shouldShrink ? 0 : 5 }}>
          <h2 className={styles.displayName}>
            {profile?.displayName ?? profile?.name}
          </h2>
          {profile?.blueTickAt && (
            <img
              style={{ width: 24, marginLeft: 5 }}
              src={blueTick}
              alt="blue tick"
            />
          )}
        </div>
      </div>
    );
  }

  renderPersonalProfile() {
    const { profile, info } = this.vm;
    return (
      <>
        {this.renderTopArea()}

        <div className={clsx(styles.jobs, styles.marginBottom)}>
          {info?.jobs.map((job, i) => (
            <div key={job}>
              <span>{ConstantsStore.jobsOptions.find((el) => el.value === job)?.label ?? ''}</span>
              {i < info.jobs.length - 1 && (
                <span className={styles.slash}>/</span>
              )}
            </div>
          ))}
        </div>

        {this.renderInterests()}
      </>
    );
  }

  renderOrganizationProfile() {
    const { profile, info } = this.vm;

    return (
      <>
        {this.renderTopArea()}

        {info?.job && info?.type && (
          <h2 className={clsx(styles.marginBottom, styles.subtitle)}>
            {`${ConstantsStore.jobsOptions.find((el) => el.value === info?.job)?.label}
            /
            ${ConstantsStore.organizationTypesOptions.find((el) => el.value === info?.type)?.label}`}
          </h2>
        )}
        <div className={clsx(styles.flexRow, styles.ogProfile)} style={{ marginBottom: 5 }}>
          {(info?.needStaff && info?.staff) && (
            <>
              <Icon
                size={18}
                svg
                style={{ marginRight: 5 }}
              >
                {iconUser}
              </Icon>
              <h5>{ConstantsStore.staffOptions.find((el) => el.value === info.staff)?.label ?? `${info.staff} ${t('people')}`}</h5>
            </>
          )}
          {(info?.needCapitalAmount && info?.capitalAmount) && (
            <>
              <Icon
                size={18}
                svg
                style={{ marginLeft: 20, marginRight: 5 }}
              >
                {iconMoney}
              </Icon>
              <h5>{ConstantsStore.capitalAmount.find((el) => el.value === info.capitalAmount)?.label ?? info.capitalAmount}</h5>
            </>
          )}
        </div>

        <div className={clsx(styles.flexRow, styles.ogProfile)} style={{ marginBottom: 15 }}>
          {info?.location && (
            <>
              <Icon
                size={18}
                svg
                style={{ marginRight: 5 }}
              >
                {iconMap}
              </Icon>
              <h5>
                {ConstantsStore.getCityLabel(info.location)}
              </h5>
            </>
          )}
          {info?.foundedIn && (
            <>
              <Icon
                size={18}
                svg
                style={{ marginLeft: 20, marginRight: 5 }}
              >
                {iconClockThick}
              </Icon>
              <h5>{dayjs(info?.foundedIn).format('YYYY.MM.DD')}</h5>
            </>
          )}
        </div>

        {this.renderInterests()}
      </>
    );
  }

  renderInterests() {
    const { info } = this.vm;
    return (
      <div>
        <h5 className={styles.interestTitle}>
          {t('profile_sdgs_label')}
        </h5>
        <div className={clsx(styles.flexRow, styles.wrap)}>
          {info?.convertedSdgs?.map((sdg, i) => (
            <div className={styles.flexRow} key={sdg}>
              <h5
                className={styles.blackBold}
              >
                {ConstantsStore.convertedSDGs(sdg)}
              </h5>
              <div className={`sdgHeart${sdg.split('-')[0]}`}>♥</div>

              {i < info.convertedSdgs.length - 1
              && <h5 className={clsx(styles.blackBold, styles.slash)}>•</h5>}
            </div>
          ))}
        </div>

        <div className={clsx(styles.flexRow, styles.marginTop10)}>
          <h5 className={styles.interestTitle}>
            {t('profile_related_cities')}
          </h5>
          <Tooltip
            placement="top"
            title={this.vm.isMe ? t('profile_cities_notice') : t('profile_cities_notice_other')}
            color="#0C0E17"
            overlayInnerStyle={{
              borderRadius: 20,
              fontWeight: 700,
              fontSize: 12,
              padding: 10
            }}
          >
            <Icon
              size={12}
              svg
              className={styles.iconNotice}
            >
              {iconNotice}
            </Icon>
          </Tooltip>
        </div>

        <div className={clsx(styles.flexRow, styles.wrap)}>
          <h5 className={styles.blackBold}>
            {this.vm.combinedRelatedRegionsString}
          </h5>
        </div>
      </div>
    );
  }

  renderEventIHosted() {
    if (!this.vm.created.length) {
      return this.renderEmpty(LIST_TYPE.CreatedEvents);
    }

    return (
      <div
        className={clsx(styles.innerContent, styles.eventContent)}
        ref={this.createdEventsRef}
      >
        <Swiper
          className={clsx(styles.listSwiper, 'profileCardSwiper')}
          onReachEnd={() => this.vm.onScrollEnd(LIST_TYPE.CreatedEvents)}
          mousewheel
          slidesPerView="auto"
          spaceBetween={40}
          direction={this.vm.isMobile ? 'vertical' : 'horizontal'}
        >
          {this.vm.created.map((item) => (
            <SwiperSlide key={item.id}>
              <EventCard
                key={item.id}
                item={item}
                onClick={() => this.vm.toEventDetail(item.id)}
                colorMode="dark"
                className={styles.card}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }

  renderEventIJoined() {
    if (!this.vm.joined.length) {
      return this.renderEmpty(LIST_TYPE.JoinedEvents);
    }

    return (
      <div
        className={clsx(styles.innerContent, styles.eventContent)}
        ref={this.joinedEventsRef}
      >
        <Swiper
          className={clsx(styles.listSwiper, 'profileCardSwiper')}
          onReachEnd={() => this.vm.onScrollEnd(LIST_TYPE.JoinedEvents)}
          mousewheel
          slidesPerView="auto"
          spaceBetween={40}
          direction={this.vm.isMobile ? 'vertical' : 'horizontal'}
        >
          {this.vm.joined.map((item) => (
            <SwiperSlide key={item.id}>
              <EventCard
                key={item?.id}
                item={item?.event}
                onClick={() => this.vm.toEventDetail(item?.event.id)}
                colorMode="dark"
                className={styles.card}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }

  renderService() {
    return (
      <div className={styles.innerContent} ref={this.serviceRef}>
        <Swiper
          className={clsx(styles.listSwiper, 'profileCardSwiper')}
          onReachEnd={() => this.vm.onScrollEnd(LIST_TYPE.Service)}
          mousewheel
          slidesPerView="auto"
          spaceBetween={20}
          direction={this.vm.isMobile ? 'vertical' : 'horizontal'}
        >
          {this.vm.service.map((item) => (
            <SwiperSlide key={item.id}>
              <ResourceCard
                className={clsx(styles.card)}
                type={item.type}
                item={item}
                key={item.id}
                onClick={() => this.vm.toResourceDetail(RESOURCE_TYPES.Service, item.id)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }

  renderSpace() {
    return (
      <div className={styles.innerContent} ref={this.spaceRef}>
        <Swiper
          className={clsx(styles.listSwiper, 'profileCardSwiper')}
          onReachEnd={() => this.vm.onScrollEnd(LIST_TYPE.Space)}
          mousewheel
          slidesPerView="auto"
          spaceBetween={20}
          direction={this.vm.isMobile ? 'vertical' : 'horizontal'}
        >
          {this.vm.space.map((item) => (
            <SwiperSlide key={item.id}>
              <ResourceCard
                className={clsx(styles.card)}
                type={item.type}
                item={item}
                key={item.id}
                onClick={() => this.vm.toResourceDetail(RESOURCE_TYPES.Space, item.id)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }

  renderFunds() {
    return (
      <div className={styles.innerContent} ref={this.fundsRef}>
        <Swiper
          className={clsx(styles.listSwiper, 'profileCardSwiper')}
          onReachEnd={() => this.vm.onScrollEnd(LIST_TYPE.Funds)}
          mousewheel
          slidesPerView="auto"
          spaceBetween={20}
          direction={this.vm.isMobile ? 'vertical' : 'horizontal'}
        >
          {this.vm.funds.map((item) => (
            <SwiperSlide key={item.id}>
              <ResourceCard
                className={clsx(styles.card)}
                type={item.type}
                item={item}
                key={item.id}
                onClick={() => this.vm.toResourceDetail(RESOURCE_TYPES.Funds, item.id)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }

  renderEmpty(listType) {
    if (this.vm.AnchoredLists[listType]?.isAwait) {
      return (
        <div className={styles.emptyContainer}>
          <Spin />
        </div>
      );
    }

    const emptyMessage = () => {
      switch (listType) {
        case LIST_TYPE.CreatedEvents:
          return this.vm.profile.isEventCreator ? t('profile_empty_message_event_hosted') : t('profile_empty_message_event_hosted_public');
        case LIST_TYPE.JoinedEvents:
          return t('list_empty_message_event_joined');
        case LIST_TYPE.Service:
        case LIST_TYPE.Funds:
        case LIST_TYPE.Space:
          return this.vm.isMe ? t('profile_empty_message_resources') : t('profile_empty_message_resources_public');
        default:
          return '';
      }
    };

    return (
      <div className={styles.emptyContainer}>
        <div className={styles.emptyMessage}>
          <div className={styles.title}>
            {t('empty_message_title')}
          </div>
          <div className={styles.text}>
            {emptyMessage()}
          </div>
        </div>
      </div>
    );
  }

  renderSocialLinks() {
    const socialLogo = (name) => {
      let logo;
      switch (name) {
        case SOCIALS_TYPE.Facebook:
          logo = facebookSvg;
          break;
        case SOCIALS_TYPE.Instagram:
          logo = instagramSvg;
          break;
        case SOCIALS_TYPE.Line:
          logo = lineSvg;
          break;
        case SOCIALS_TYPE.Linkedin:
          logo = linkedinSvg;
          break;
        default:
          logo = websiteSvg;
      }
      return (
        <img
          className={styles.logo}
          src={logo}
          alt="social website logo"
        />
      );
    };

    if (!this.vm.hasSocialLink) {
      return (
        <div className={styles.links}>
          <div className={styles.moreInfoEmptyMsg}>
            {t('profile_org_socials_empty')}
          </div>
        </div>
      );
    }

    return this.vm.socials?.filter((s) => s?.url)?.map((item) => (
      <div className={styles.links} key={item.name}>
        <a
          className={styles.link}
          target="_blank"
          href={item.url}
          rel="noreferrer"
        >
          {socialLogo(item.name)}
          <span>{t(`${item.name}`)}</span>
        </a>
      </div>
    ));
  }

  renderBelongsList() {
    const drawerRef = document.querySelector('.ant-drawer-body');

    if (this.vm.belongs?.length === 0) {
      if (this.vm.identity === USER_TYPES.Organization && this.vm.anchoredMemberList?.isAwait) {
        return (
          <div className={styles.moreInfoEmptyMsg}>
            <Spin />
          </div>
        );
      }

      return (
        <div className={styles.moreInfoEmptyMsg}>
          {this.vm.identity === USER_TYPES.Organization
            ? t('profile_org_members_empty')
            : t('profile_personal_belongs_empty')}
        </div>
      );
    }

    return (
      <div className={styles.belongsList}>
        {this.vm.belongs?.map((item) => (
          <BelongCard
            item={item}
            key={item.id}
            onClick={() => {
              if (this.vm.isBelongModalOpen) {
                this.vm.toggleBelongModal();
              }
              if (this.vm.isMoreInfoModalOpen) {
                this.vm.toggleMoreInfoModal();
              }
              this.vm.toProfile(item.user.id);
            }}
            className={styles.belongCard}
          />
        ))}

        {(this.vm.identity === USER_TYPES.Organization && this.vm.anchoredMemberList?.anchor)
          ? (
            <>
              <Waypoint
                scrollableAncestor={drawerRef}
                onEnter={this.vm.anchoredMemberList?.getNext}
              />

              {this.vm.anchoredMemberList?.isAwait && <Spin />}
            </>
          )
          : null}
      </div>
    );
  }

  // modals //////////////////
  renderMoreInfoModal() {
    const content = () => {
      switch (this.vm.identity) {
        case USER_TYPES.Organization:
          return (
            <Tabs
              items={[
                {
                  value: LIST_TYPE.Socials,
                  label: t('reg_socials'),
                  content: (
                    <div className={styles.moreInfoTabContent}>
                      {this.renderSocialLinks()}
                    </div>
                  )
                },
                {
                  value: LIST_TYPE.Belongs,
                  label: t('profile_member'),
                  content: (
                    <div className={styles.moreInfoTabContent}>
                      {this.renderBelongsList()}
                    </div>
                  )
                }
              ]}
              onSwitchTab={this.vm.switchMoreModalTab}
              currentValue={this.vm.actives.moreModal}
              colorMode={2}
              centered
            />
          );
        case USER_TYPES.Personal:
          return (
            <div className={styles.moreInfoTabContent}>
              {this.renderSocialLinks()}
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <BaseModal
        onClose={this.vm.toggleMoreInfoModal}
        open={this.vm.isMoreInfoModalOpen}
        isMobile={this.vm.isMobile}
        height="80%"
      >
        <div className={styles.moreInfoModal}>
          <div className={styles.title}>{t('profile_know_me_more')}</div>
          {content()}
        </div>

      </BaseModal>
    );
  }

  renderOrgAuthModal() {
    return (
      <OrganizationAuthModal
        onClose={this.vm.toggleOrgAuthModal}
        open={this.vm.isOrgAuthModalOpen}
        isMobile={this.props.context.state.isMobile}
        status={this.vm.status}
        review={this.vm.profile?.reviews[0]}
        toProfileEditPage={this.vm.toProfileEditPage}
      />
    );
  }

  renderBelongModal() {
    return (
      <BaseModal
        onClose={this.vm.toggleBelongModal}
        open={this.vm.isBelongModalOpen}
        isMobile={this.vm.isMobile}
        height="80%"
      >
        <div className={styles.moreInfoModal}>
          <div className={styles.title}>{t('profile_belong')}</div>
          {this.renderBelongsList()}
        </div>
      </BaseModal>
    );
  }

  // ///////////////////////

  render() {
    return (
      <div
        className={clsx(styles.profilePage, 'page')}
        onClick={(e) => this.vm.onPanelClick(e, null)}
      >
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideFooter
          hideScrollToTopButton
        >
          <div className={clsx(styles.mainContainer)}>
            {this.vm.profile && this.renderProfile()}
          </div>

          {this.vm.profile && (
            <>
              <div className={styles.lists}>
                {this.renderEvents()}
                {this.renderResources()}
              </div>

              <ResourceAddModal
                isOpen={this.vm.isAddResourceModalOpen}
                onModalToggle={this.vm.toggleAddResourceModal}
                isMobile={this.vm.isMobile}
                vm={this.vm}
              />

              {this.vm.memberOnlyFeatureModalVM && (
                <MemberOnlyFeatureModal
                  vm={this.vm.memberOnlyFeatureModalVM}
                  isMobile={this.props.context.state.isMobile}
                  centered
                />
              )}

              {this.vm.shouldShowMoreInfoButton && this.renderMoreInfoModal()}
              {this.vm.identity === USER_TYPES.Personal && this.renderBelongModal()}
              {this.vm.identity === USER_TYPES.Organization && this.renderOrgAuthModal()}
            </>
          )}
        </MainLayout>
      </div>
    );
  }
}

ProfilePage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  router: PropTypes.object.isRequired
};

export default withProfile(withRouter(withTranslation()(ProfilePage)), false);
