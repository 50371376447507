import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Bubble from 'src/components/Bubble';

import ConstantsStore from 'src/stores/constants';

@observer
class Region extends React.Component {
  constructor(props) {
    super(props);
  }


  renderBubbles() {
    const { containerClassName, regionDataKey, vm } = this.props;
    return (
      <div className={clsx(`active${vm.currentArea}`, containerClassName)}>
        {ConstantsStore[regionDataKey || 'regionsForHP'].map((item) => item.children.map((area) => (
          <Bubble
            key={area.value}
            value={area.value}
            label={area.label}
            count={vm.count[area.value.split('-')[1]]}
            className={`area${area.value}`}
            isVisible
            onClick={() => {
              vm.handleSwitchArea(area.value);
            }}
          />
        )))}
        {ConstantsStore[regionDataKey || 'regionsForHP'].map((item) =>
          item.children.map((area) =>
            area.children.map((city) =>
              city.value.slice(0, 3) === vm.currentArea && (
              <Bubble
                key={city.value}
                value={city.value}
                label={city.label}
                className={`city${city.value.split('-')[1]} city${city.value} ${vm.cities.includes(city.value) ? '' : 'cityInactive'}`}
                isVisible
                onClick={() => { vm.handleToggleCity(city.value); }}
              />
              ))))}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderBubbles()}
      </>
    );
  }
}


export default (withTranslation()(Region));

Region.propTypes = {
  containerClassName: PropTypes.string,
  regionDataKey: PropTypes.string,
  vm: PropTypes.object
};

Region.defaultProps = {
  containerClassName: '',
  regionDataKey: null,
  vm: {}
};
