import {
  makeObservable, computed, observable, action
} from 'mobx';
import diff from 'deep-diff';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import UserService from 'src/services/user';
import scrollDetector from 'src/stores/scrollDetector';
import NotificationListViewModel from '../NotificationList/vm';

export const MENU = {
  Main: 'main',
  Avatar: 'avatar'
};

export const TABS = {
  Account: 'account',
  Notification: 'notification'
};

class HeaderViewModel {
  @observable profile = null;
  @observable avatar = null;
  @observable notifications = [];
  @observable unreadCount = 0;

  @observable isMobile = false;
  @observable currentTab = TABS.Account;
  @observable activeMenu = null;
  @observable containerRef = null;

  notificationListViewModel = new NotificationListViewModel();

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props, containerRef) => {
    window.addEventListener('scroll', scrollDetector.onListenScrollDirection);
    this.isMobile = props.context.state.isMobile;
    this.updateData(props);
    this.containerRef = containerRef;
  };

  @action didUpdate = (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile) || (UserService.isLogin && !this.profile)) {
      this.updateData(props);
    }

    if (!UserService.isLogin && this.profile) {
      this.resetData();
    }

    // close menu when params(url) change
    if (prevProps.router?.params !== props.router?.params) {
      this.toggleMenu();
    }
  };

  willUnmount = () => {
    clearAllBodyScrollLocks();
  };

  @action updateData = async (props) => {
    if (!UserService.isLogin) { return; }

    if (props.profile) {
      this.profile = props.profile;
      this.avatar = props.profile?.avatar;
      await this.notificationListViewModel.updateUnreadCount();
    }
  };

  @action resetData = () => {
    this.profile = null;
    this.avatar = null;
    this.notifications = [];
    this.unreadCount = 0;
  };

  @action logout = async () => {
    const { navigate } = this.props.router;
    try {
      await UserService.logout();
      this.resetData();
      navigate(0);
    } catch (err) {
      console.log(err);
    }
  };

  @action toggleMenu = (menu) => {
    if (Object.values(MENU).includes(menu) && this.activeMenu !== menu) {
      this.activeMenu = menu;
      disableBodyScroll(this.containerRef?.current);

      if (menu === MENU.Avatar) {
        this.notificationListViewModel.init();
      }
    } else {
      this.activeMenu = null;
      enableBodyScroll(this.containerRef?.current);
    }
  };

  @computed get isMainMenuOpen() {
    return this.activeMenu === MENU.Main;
  }

  @computed get isAvatarMenuOpen() {
    return this.activeMenu === MENU.Avatar;
  }

  @action handleChangeTabs = (key) => {
    this.currentTab = key;
    if (key === TABS.Notification) {
      this.onReadAllNotifications();
    }
  };

  onReadAllNotifications = () => {
    if (this.notificationListViewModel.unreadCount !== 0) {
      this.notificationListViewModel.readAll();
    }
  };

}

export default HeaderViewModel;
