import { makeObservable, observable, action, runInAction, computed, toJS } from 'mobx';
import ConstantsStore, { KEY_OF_OTHER } from 'src/stores/constants';
import IS from 'src/utils/is';

class DemandMeta {
  @observable name;
  @observable description;
  @observable keyItemDescriptions;
  @observable dates;
  @observable regions;
  @observable awards;
  @observable awardDescriptions;
  @observable rules;

  constructor(params) {
    makeObservable(this);
    const {
      name,
      description,
      keyItemDescriptions,
      dates,
      regions,
      awards,
      awardDescriptions,
      rules
    } = params ?? {};

    runInAction(() => {
      this.name = name;
      this.description = description;
      this.keyItemDescriptions = keyItemDescriptions ?? [];
      this.dates = dates ?? [];
      this.regions = regions ?? [];
      this.awards = awards ?? [];
      this.awardDescriptions = awardDescriptions ?? [];
      this.rules = rules ?? [];
    });
  }

  @action initTemplateToEdit = () => {
    const keysNeedEmptyStr = ['keyItemDescriptions', 'dates', 'awardDescriptions', 'rules'];
    keysNeedEmptyStr.forEach((key) => {
      if (this[key]?.length === 0) {
        this[key] = [''];
      }
    });
  };

  @action onChange = (value, key) => {
    this[key] = value;
  };

  @action onChangeStringArray = (value, key, index) => {
    this[key][index] = value;
  };

  @action addEmptyItemInArray = (key) => {
    this[key]?.push('');
  };

  @action removeItemInArray = (key, index) => {
    this[key] = this[key].filter((item, i) => i !== index);
  };

  @computed get isCommonAttrComplete() {
    return !!this.name
    && !!this.description
    && this.keyItemDescriptions?.length !== 0 && this.keyItemDescriptions?.some((v) => !!v)
    && this.dates?.length !== 0 && this.dates?.some((v) => !!v)
    && this.regions?.length !== 0
    && this.awards?.length !== 0
    && this.awardDescriptions?.length !== 0 && this.awardDescriptions?.some((v) => !!v)
    && this.rules?.length !== 0 && this.rules?.some((v) => !!v);
  }

  @computed get isCommonAttrEmpty() {
    return !this.name
    && !this.description
    && (this.keyItemDescriptions?.length === 0 || this.keyItemDescriptions?.every((v) => !v))
    && (this.dates?.length === 0 || this.dates?.every((v) => !v))
    && this.regions?.length === 0
    && this.awards?.length === 0
    && (this.awardDescriptions?.length === 0 || this.awardDescriptions?.every((v) => !v))
    && (this.rules?.length === 0 || this.rules?.every((v) => !v));
  }

  filterEmptyStringInArr = (arr) => {
    return arr.filter((str) => !!str) ?? [];
  };

  @computed get awardsLabels() {
    return this.awards?.map((item) => {
      if (IS.customValue(item)) {
        return item.replace('#', '');
      }

      const label = ConstantsStore.awardsOptions.find((opt) => opt.value === item)?.label;
      return label ?? '-';
    });
  }

  @computed get regionsLabels() {
    return this.regions?.map((item) => ConstantsStore.flattenedRegionsOptions.find((opt) => opt.value === item)?.label ?? '-') ?? [];
  }

  @computed get combinedRegionsLabels() {
    const regions = ConstantsStore.getCombinedRegionsValuesArr(this.regions);
    return regions?.map((r) => ConstantsStore.getRegionLabelByValue(r)) ?? [];
  }

  @computed get regionsString() {
    return ConstantsStore.getCombinedRegionsLabelString(this.regions);
  }

  serializeCommonAttr = () => {
    return {
      name: this.name,
      description: this.description,
      keyItemDescriptions: toJS(this.filterEmptyStringInArr(this.keyItemDescriptions)),
      dates: toJS(this.filterEmptyStringInArr(this.dates)),
      regions: toJS(this.regions),
      awards: toJS(this.awards),
      awardDescriptions: toJS(this.filterEmptyStringInArr(this.awardDescriptions)),
      rules: toJS(this.filterEmptyStringInArr(this.rules))
    };
  };
}

export default DemandMeta;




