import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  makeObservable, computed
} from 'mobx';
import clsx from 'clsx';
import { t } from 'i18next';
import { CREATE_STEP_STATUS } from 'src/constants';
import StepIcon from '../StepIcon';
import styles from './styles.module.scss';

@observer
class MenuItem extends React.Component {
  constructor(props) {
    super();
    makeObservable(this);
    this.props = props;
  }

  @computed get status() {
    const { isActive, isDisabled, isDone } = this.props;

    if (isDisabled) {
      return CREATE_STEP_STATUS.Disable;
    }

    if (isActive) {
      return CREATE_STEP_STATUS.Active;
    }

    if (isDone) {
      return CREATE_STEP_STATUS.Done;
    }

    return CREATE_STEP_STATUS.Pending;
  }

  render() {
    const {
      label,
      onClick,
      className,
      isDone
    } = this.props;

    return (
      <div
        className={clsx(styles.menuItem, styles[this.status], className)}
        onClick={() => onClick(label)}
      >
        <StepIcon
          step={label}
          status={this.status}
          className={styles.icon}
          withGreenDot={!isDone}
        />

        <div className={styles.label}>
          {t(`create_step_${label}`)}
        </div>
      </div>
    );
  }
}

MenuItem.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isDone: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string
};

MenuItem.defaultProps = {
  label: '',
  onClick: null,
  isActive: false,
  isDone: false,
  isDisabled: false,
  className: ''
};

export default MenuItem;
