import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { handleLineBreaks, getYoutubeEmbedUrl } from 'src/utils';

import styles from './styles.module.scss';

function EventItemCard(props) {
  const {
    item,
    title,
    description,
    option,
    className,
    hasOnlyOne
  } = props;

  return (
    <div className={clsx(styles.card, hasOnlyOne && styles.oneCardWrapper, className)}>
      {
        item.videos && item.videos[0]
          ? (
            <div
              className={styles.photoContainer}
            >
              <iframe
                title={item.videos[0]}
                width="100%"
                height="100%"
                src={getYoutubeEmbedUrl(item.videos[0])}
                allow="fullscreen"
                allowFullScreen
                style={{ borderRadius: 16 }} // to fix iphone border-radius disappear problem
              />
            </div>
          )
          : (
            <div
              className={clsx(styles.photoContainer, styles.photo)}
              key={item.photos[0]?.name}
              style={{ backgroundImage: `url(${item.photos[0]?.url})` }}
            />
          )
      }
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.title}>
            {title}
          </div>
        </div>
        <div className={styles.desc}>
          {handleLineBreaks(description)}
        </div>
        <div className={styles.flexEnd}>
          {item.option && (
            <div className={styles.option}>
              {option}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

EventItemCard.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  option: PropTypes.string,
  className: PropTypes.string,
  hasOnlyOne: PropTypes.bool
};

EventItemCard.defaultProps = {
  item: {},
  title: null,
  description: '',
  option: null,
  className: null,
  hasOnlyOne: false
};

export default EventItemCard;
