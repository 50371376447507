export default class Notification {
  constructor(params) {
    const {
      content,
      createdAt,
      id,
      payload,
      readedAt,
      title,
      type
    } = params ?? {};

    const {
      event,
      target
    } = payload ?? {};

    this.content = content;
    this.createdAt = createdAt;
    this.id = id;
    this.payload = {
      event: {
        customId: event?.customId,
        name: event?.name,
        user: {
          avatar: event?.user?.avatar
        }
      },
      target
    };
    this.readedAt = readedAt;
    this.title = title;
    this.type = type;
  }

  static fromRes(data) {
    return new Notification(data);
  }
}
