import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import blueTick from 'src/assets/blueTick.png';
import styles from './styles.module.scss';
import 'swiper/css';

function EventCarousel(props) {
  const { items, initialIndex, onChange } = props;
  const swiperRef = useRef();
  useEffect(() => {
    swiperRef.current.swiper.slideTo(initialIndex);
  }, [initialIndex]);

  return (
    <div className={styles.eventsContainer}>
      <Swiper
        ref={swiperRef}
        className={clsx(styles.eventsSwiper, 'myEventsSwiper')}
        centeredSlides
        // loop
        initialSlide={initialIndex}
        onActiveIndexChange={onChange}
        slidesPerView={1.2}
        spaceBetween={10}
        breakpoints={{
          500: {
            slidesPerView: 1.4,
            spaceBetween: 15
          },
          768: {
            slidesPerView: 1.3,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10
          }
        }}
      >
        {items?.map((item) => (
          <SwiperSlide key={item.id}>
            <div className={clsx(styles.card, 'card')}>
              <div
                style={{ backgroundImage: `url(${item.banner})` }}
                className={styles.banner}
              />
              <div className={styles.title}>
                {item.name}
              </div>
              {item.blueTick && (
                <img
                  className={styles.bluetick}
                  src={blueTick}
                  alt="bluetick"
                  width={24}
                  height={24}
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

EventCarousel.propTypes = {
  items: PropTypes.array,
  initialIndex: PropTypes.number,
  onChange: PropTypes.func
};

EventCarousel.defaultProps = {
  items: [],
  initialIndex: 0,
  onChange: () => {}
};

export default EventCarousel;
