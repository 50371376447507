import {
  makeObservable,
  observable,
  action,
  runInAction
} from 'mobx';

class BusyCursor {
  @observable isAwait = false;

  @observable isEventDetailLoading = false;

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  @action onBusy = () => {
    this.isAwait = true;
  };

  @action onEventDetailBusy = () => {
    this.isEventDetailLoading = true;
  };

  @action onFinish = () => {
    setTimeout(() => {
      runInAction(() => {
        this.isAwait = false;
      });
    }, 1000);
  };

  @action onEventDetailFinish = () => {
    this.isEventDetailLoading = false;
    console.log('event detail loading', this.isEventDetailLoading);
  };
}

const busyCusor = new BusyCursor();
export default busyCusor;


