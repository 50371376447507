import { makeObservable, observable, action, computed } from 'mobx';
import { t } from 'i18next';

class GeneralModalViewModel {

  @observable content = null;
  @observable title = 'title';
  @observable onConfirm = () => {};
  @observable onClose = () => {};
  @observable isShow = false;
  @observable showCancelButton = false;
  @observable buttonText = t('ok');
  @observable buttonProps = {};
  @observable illustrationType = null;


  constructor() {
    makeObservable(this);
  }

  @action open = ({ title, content, onClose, onConfirm, showCancelButton = false, buttonProps, buttonText, illustrationType }) => {
    this.reset();
    this.title = title;
    this.content = content;
    this.onConfirm = onConfirm || this.onConfirm;
    this.onClose = onClose || this.onClose;
    this.showCancelButton = showCancelButton;
    this.illustrationType = illustrationType;

    this.buttonText = buttonText || t('ok');
    if (buttonProps) {
      this.buttonProps = buttonProps;
    }

    this.isShow = true;
  };

  @action confirm = () => {
    this.onConfirm();
    this.close();
    this.isShow = false;
  };

  @action close = () => {
    this.onClose();
    this.isShow = false;
  };

  @action reset = () => {
    this.title = '';
    this.content = null;
    this.showCancelButton = false;
    this.onConfirm = () => {};
    this.onClose = () => {};
  };
}

export default GeneralModalViewModel;

