import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ConstantsStore from 'src/stores/constants';
import TreeSelectViewModel from '../TreeSelect/vm';

class FilterViewModel {
  @observable isMobile = false;
  @observable type;

  // sort
  @observable sort = 'id'; // id / popularity
  @observable order = 'desc'; // asc / desc
  @observable filter = {};
  @observable resourceTypes = [];
  @observable hasAppliedFilter = false;

  @observable isModalOpen = false;

  regionsSelectViewModel = new TreeSelectViewModel(ConstantsStore.regionsOptions);
  sdgsSelectViewModel = new TreeSelectViewModel(ConstantsStore.sdgsOptions);

  constructor(props) {
    makeObservable(this);
    this.props = props;
    this.getList = props.getList;
    this.type = props.type;
  }

  @computed
  get listParams() {
    return {
      sort: this.sort,
      order: this.order
    };
  }

  @computed
  get listFilter() {
    const sdgs = this.sdgsSelectViewModel.selectedItems?.map((el) => el.value);
    const regions = this.regionsSelectViewModel.selectedItems?.map((el) => el.value);
    return {
      keyword: this.filter.keyword ? this.filter.keyword : undefined,
      regions: regions?.length ? regions : undefined,
      sdgs: sdgs?.length ? sdgs : undefined,
      demandTypes: this.filter.demandTypes?.length ? this.filter.demandTypes : undefined
    };
  }

  @computed
  get filterCount() {
    let count = 0;
    if (this.listFilter.demandTypes?.length) count += 1;
    if (this.listFilter.sdgs?.length) count += 1;
    if (this.listFilter.regions?.length) count += 1;
    return count;
  }

  @computed
  get keyword() {
    return this.filter.keyword;
  }

  @action setFilter = ({ type, sdgs, regions, keyword }) => {
    if (type) this.resourceTypes.push(type);
    if (sdgs) this.sdgsSelectViewModel.initCheckState(sdgs);
    if (regions) this.regionsSelectViewModel.initCheckState(regions);
    if (keyword) this.filter.keyword = keyword;
    this.hasAppliedFilter = this.filterCount !== 0;
  };

  @action toggleModal = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  @action onKeywordChange = (e) => {
    this.filter = { ...this.filter, keyword: e.target.value };
    console.log('keyword change', e.target.value);
    if (e.target.value?.trim()?.length === 0) {
      this.getList();
    }
  };

  @action onSearchKeyword = () => {
    console.log('on search');
    if (this.filter.keyword?.trim()?.length !== 1) {
      this.getList();
    }
  };

  @action onFilterChange = (field, value) => {
    const obj = {
      [`${field}`]: value
    };

    this.filter = { ...this.filter, ...obj };
  };

  @action applyFilter = async () => {
    await this.getList();
    runInAction(() => {
      this.toggleModal();
      this.hasAppliedFilter = this.filterCount !== 0;
    });
  };

  @action resetFilter = () => {
    this.regionsSelectViewModel.resetAll();
    this.sdgsSelectViewModel.resetAll();
    this.resourceTypes = [];
    this.filter = {};
  };

  @action handleResourceTypeChange = (value) => {
    if (this.resourceTypes.includes(value)) {
      this.resourceTypes = this.resourceTypes.filter((type) => type !== value);
    } else {
      this.resourceTypes.push(value);
    }

    this.onFilterChange('demandTypes', this.resourceTypes);
  };

  @action handleSort = (sort) => {
    console.log('handle sort', sort);
    this.sort = sort;
    this.order = 'desc';
    this.getList();
  };

  @action handleOrder = (order) => {
    console.log('handle order', order);
    this.order = order;
    this.getList();
  };

  @action handleTimeSort = (order) => {
    this.sort = 'id';
    this.order = order;
    this.getList();
  };

}

export default FilterViewModel;
