import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import ForgetPasswordModal from 'src/components/Modals/ForgetPasswordModal';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Breadcrumb from 'src/components/Breadcrumb';
import MainLayout from 'src/components/MainLayout';
import ModalWithIllustration from 'src/components/Modals/ModalWithIllustration';

import LoginPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.vm = new LoginPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  onLoginClick = () => {
    this.vm.onLoginClick(this.props);
  };

  render() {
    return (
      <div className={clsx(styles.LoginPage, 'page')}>
        <MainLayout
          {...this.props}
          hideHeader
          hideScrollToTopButton
          footerStyle={{ marginTop: -30 }}
        >
          <Breadcrumb
            routes={[
              { label: 'go action', path: '/' },
              { label: t('login'), path: '' }
            ]}
            right={null}
          />
          <div className={styles.container}>
            <div className={styles.main}>
              <div className={styles.greet}>
                { t('login_welcomeTitle') }
              </div>
              <div className={styles.greetSub}>{t('login_welcomeSubtitle')}</div>

              <TextInput
                label={this.vm.email ? null : t('login_email')}
                error={!!this.vm.emailError}
                onChange={this.vm.onEmailChange}
              />
              <div className={styles.errorMessage}>
                {this.vm.emailError ? this.vm.emailError : ''}
              </div>
              <TextInput
                label={this.vm.password ? null : t('login_password')}
                error={!!this.vm.passwordError}
                onChange={this.vm.onPasswordChange}
                type="password"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.onLoginClick();
                  }
                }}
              />
              <div className={styles.errorMessage}>
                {this.vm.passwordError ? this.vm.passwordError : ''}
              </div>

              <div
                className={styles.text}
                style={{ width: 'fit-content', cursor: 'pointer' }}
                onClick={this.vm.clickForgetPassword}
              >
                <h5>{t('login_forgetPassword')}</h5>
              </div>

              <div className={styles.space} />

              <Button
                onClick={this.onLoginClick}
                style={{ width: '100%', maxWidth: 335 }}
                loading={this.vm.isAwait}
              >
                {t('login_button')}
              </Button>

              <div className={styles.bottomText}>
                <span className={styles.text}>{t('login_noAcccount')}</span>
                <a
                  className={styles.highlightText}
                  href="/register"
                >
                  <h4 style={{ display: 'inline' }}>{t('login_joinUs')}</h4>
                </a>
              </div>
            </div>
          </div>

          {this.vm.forgetPasswordModalVM && (
            <ForgetPasswordModal
              vm={this.vm.forgetPasswordModalVM}
              isMobile={this.props.context.state.isMobile}
            />
          )}

          <ModalWithIllustration
            onClose={this.vm.toggleAccountNotExistModal}
            open={this.vm.isAccountNotExistModalOpen}
            isMobile={this.props.context.state.isMobile}
            centered
            width={500}
            title={t('login_popup_accountDoesntExist')}
            onButtonClick={() => {
              this.vm.toRegister();
              this.vm.toggleAccountNotExistModalOpen();
            }}
            buttonText={t('login_popup_goToRegister')}
          />

        </MainLayout>
      </div>
    );
  }
}

export default withProfile(withRouter(withTranslation()(LoginPage)), false);

LoginPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};
