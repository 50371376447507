import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { CREATE_EVENT_STEPS } from 'src/constants';
import styles from './styles.module.scss';

function Subtitle({ step }) {
  return (
    <div className={styles.subtitle}>
      {t(`create_event_subtitle_${step}`)}
    </div>
  );
}

Subtitle.propTypes = {
  step: PropTypes.string
};

Subtitle.defaultProps = {
  step: CREATE_EVENT_STEPS.Basic
};

export default Subtitle;
