import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import Button from 'src/components/Button';
import Select from 'src/components/Select';
import withErrorInput from 'src/components/withErrorInput';
import TextInput from 'src/components/TextInput';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import illustrationMail from 'src/assets/illustrationEmail.svg';
import illustrationPhone from 'src/assets/illustrationPhone.svg';
import illustrationOneBoy from 'src/assets/illustrationOneBoy.svg';
import illustrationHand from 'src/assets/illustrationHand.jpg';

import ConstantsStore from 'src/stores/constants';
import { BLOCK_REASONS } from 'src/constants';

import BaseModal from '../BaseModal';
import styles from './styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);

@observer
class MemberOnlyFeatureModal extends React.Component {

  constructor(props) {
    super(props);

    this.vm = props.vm;
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderIllustration() {
    let src;

    switch (this.vm.blockReason) {
      case BLOCK_REASONS.EmailValidate:
        src = illustrationMail;
        break;
      case BLOCK_REASONS.PhoneValidate:
        src = illustrationPhone;
        break;
      case BLOCK_REASONS.LoginOnHomePage:
        src = illustrationHand;
        break;
      default:
        src = illustrationOneBoy;
    }

    return (
      <img
        className={clsx(styles.marginBottom, styles.illustration)}
        src={src}
        alt="member only modal illustration"
      />
    );
  }

  renderContent() {
    const needPhoneValidate = this.vm.blockReason === BLOCK_REASONS.PhoneValidate;

    return (
      <div className={styles.memberOnlyFeatureModal}>
        {this.renderIllustration()}
        <h2 className={clsx(styles.title, styles.marginBottom)}>
          {this.vm.titleString}
        </h2>
        <h3 className={clsx(styles.subtitle, styles.marginBottom)}>
          {this.vm.contentString}
        </h3>

        {needPhoneValidate && (
          <div className={styles.phoneArea}>
            <div className={clsx(styles.countryCode)}>
              <ErrorSelect
                placeholder={t('register_validate_personal_step2_countryCodePlaceholder')}
                value={this.vm.countryCode}
                showSearch
                onChange={(value) => this.vm.onChange('countryCode', value)}
                options={ConstantsStore.countryCodesOptions}
                showError={this.vm.showError}
                validValue={this.vm.isCountryCodeValid}
                required
                errorMessage={this.vm.countryCode ? t('format_is_wrong') : t('this_is_required')}
              />
            </div>

            <ErrorTextInput
              label={t('register_validate_personal_step2_phoneInputPlaceholder')}
              sx={{
                flexGrow: 1
              }}
              onChange={(e) => this.vm.onChange('phone9Digit', e.target.value)}
              type="number"
              value={this.vm.phone9Digit ?? ''}
              showError={this.vm.showError}
              required
              validValue={this.vm.isPhoneValid}
              errorMessage={this.vm.phone9Digit ? t('format_is_wrong') : t('this_is_required')}
            />
          </div>
        )}

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={this.vm.onClickButton}
          style={{ width: 180 }}
          disabled={this.vm.isButtonDisable || this.vm.timerCountdown !== null}
          loading={this.vm.isLoading}
        >
          {this.vm.buttonString}
          {this.vm.timerCountdown !== null && (
            <span className={styles.timer}>
              {`(${this.vm.timerCountdown})`}
            </span>
          )}
        </Button>
      </div>
    );
  }

  render() {
    const { isMobile, showCloseIcon } = this.props;
    return (
      <BaseModal
        onClose={this.vm.closeModal}
        open={this.vm.isModalOpen}
        isMobile={isMobile}
        centered
        height={this.vm.blockReason === BLOCK_REASONS.PhoneValidate ? 480 : null}
        showCloseIcon={showCloseIcon}
      >
        {this.renderContent()}
      </BaseModal>
    );
  }
}

MemberOnlyFeatureModal.propTypes = {
  isMobile: PropTypes.bool,
  vm: PropTypes.object,
  showCloseIcon: PropTypes.bool
};

MemberOnlyFeatureModal.defaultProps = {
  isMobile: false,
  vm: {},
  showCloseIcon: false
};

export default withProfile(withRouter(withTranslation()(MemberOnlyFeatureModal)), false);
