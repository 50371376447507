import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Success from 'src/components/Success';

@observer
class ResourceSuccess extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { item, onBtnClick } = this.props;
    return (
      <Success
        title={t('resource_detail_success_title')}
        texts={[t('resource_detail_success_info1'), item?.name, t('resource_detail_success_info2')]}
        buttonText={t('resource_detail_success_btn1')}
        bottomText={t('resource_detail_success_btn2')}
        bottomLinkText={t('resource_detail_success_btn3')}
        bottomLinkHref="/"
        onClickButton={onBtnClick}
      />
    );
  }
}

ResourceSuccess.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  item: PropTypes.object,
  onBtnClick: PropTypes.func
};

ResourceSuccess.defaultProps = {
  item: {},
  onBtnClick: () => {}
};

export default withProfile(withRouter(withTranslation()(ResourceSuccess)), false);
