import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import i18n from 'src/i18n';
import TextInput from 'src/components/TextInput';
import withErrorInput from 'src/components/withErrorInput';

import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class Step3 extends React.Component {

  constructor(props) {
    super(props);
  }

  renderInput() {

    const { vm } = this.props;

    return (
      <div className={styles.flexCol}>
        <ErrorTextInput
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_desc')}
          onChange={(e) => vm.onDataChange('description', e.target.value)}
          value={vm.data?.description}
          multiline
          errorMessage={(vm.data?.description && !vm.isDescriptionValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
          validValue={vm.isDescriptionValid}
        />

        <div className={styles.flexSpaceBetween}>
          <div className={styles.note} />
          <div className={styles.exp_length}>
            <span className={vm.data?.description?.length > 300 && styles.alert}>{vm.data?.description?.length ?? 0}</span>
            <span>/300</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.step}>
        <div className={styles.block}>
          <div className={styles.title}>{i18n.t('myresource_extra_title')}</div>
          <div className={styles.desc}>{i18n.t('myresource_extra_subtitle')}</div>
        </div>
        {this.renderInput()}
      </div>
    );
  }
}

export default Step3;

Step3.propTypes = {
  vm: PropTypes.object
};

Step3.defaultProps = {
  vm: {}
};
