import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { handleLineBreaks } from 'src/utils';
import { Waypoint } from 'react-waypoint';
import { isPro } from 'src/stores';
import Spin from 'antd/es/spin';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Button from 'src/components/Button';
import Tabs from 'src/components/Tabs';
import BaseModal from 'src/components/Modals/BaseModal';
import SearchInput from 'src/components/SearchInput';
import DatePicker from 'src/components/DatePicker';
import TreeSelect from 'src/components/TreeSelect';
import Icon from 'src/components/Icon';
import OrderCard from 'src/components/OrderCard';
import PurchaseCard from 'src/components/PurchaseCard';

import iconFilter from 'src/assets/iconFilter.svg';
import iconFilterOn from 'src/assets/iconFilterOn.svg';
import iconDoubleCheck from 'src/assets/iconDoubleCheckGreen.svg';
import errorSvg from 'src/assets/error.svg';

import OrderHistoryPageViewModel, { TABS } from './vm';

import styles from './styles.module.scss';

@observer
class OrderHistoryPage extends React.Component {
  constructor(props) {
    super();
    this.props = props;
    this.vm = new OrderHistoryPageViewModel(this.props);
    this.isMobile = props?.context?.state?.isMobile;
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  renderTopArea = () => {
    return (
      <div className={styles.topAreaContainer}>
        <div className={clsx(styles.promoAndButton)}>
          <div className={clsx(styles.flexGrow, styles.promoText)}>
            {this.vm.promotionText}
          </div>
          {/* phase 2 only on dev */}
          {!isPro && (
            <Button
              onClick={this.vm.toggleIntroModal}
              withRightArrow
              className={styles.buyPlanButton}
            >
              {t('credits_buy_plan_button')}
            </Button>
          )}
        </div>
        <div className={styles.days}>
          <Icon
            svg
            size={20}
          >
            {iconDoubleCheck}
          </Icon>
          <div className={styles.text}>
            {t('current_quota_activeEvents')}
          </div>
          <div className={clsx(styles.bold, styles.marginLeft20)}>
            {this.vm.activeEventCount}
          </div>
          <div className={styles.bold}>
            {t('quota_unit')}
          </div>
        </div>
      </div>
    );
  };

  renderTabs = () => {

    return (
      <Tabs
        colorMode={2}
        items={[
          {
            value: TABS.Order,
            label: t('credit_tab_order'),
            content: (
              <div className={styles.tabContent}>
                {this.renderFilterBar()}
                {this.renderList()}

                {
                  this.vm.anchoredLists[TABS.Order]?.anchor
                    ? (
                      <>
                        {this.vm.anchoredLists[TABS.Order]?.isAwait && <Spin />}
                        <Waypoint
                          scrollableAncestor={window}
                          onEnter={this.vm.anchoredLists[TABS.Order].getNext}
                        />
                      </>
                    )
                    : null
                }
              </div>
            )
          },
          {
            value: TABS.Purchase,
            label: t('credit_tab_purchase'),
            content: (
              <div className={styles.tabContent}>
                {this.renderFilterBar()}
                {this.renderList()}

                {
                  this.vm.anchoredLists[TABS.Purchase]?.anchor
                    ? (
                      <>
                        {this.vm.anchoredLists[TABS.Purchase]?.isAwait && <Spin />}
                        <Waypoint
                          scrollableAncestor={window}
                          onEnter={this.vm.anchoredLists[TABS.Purchase].getNext}
                        />
                      </>
                    )
                    : null
                }
              </div>
            )
          }
        ]}
        onSwitchTab={this.vm.onSwitchTab}
        currentValue={this.vm.currentTab}
        centered
      />
    );
  };

  renderFilterBar = () => {
    return (
      <div className={styles.filterBar}>
        <SearchInput
          onChange={this.vm.onKeywordChange}
          onSearch={this.vm.onSearch}
          onClear={this.vm.onClearKeyword}
          value={this.vm.keyword}
          placeholder={t('purchase_history_search_placeholder')}
        />

        <div className={styles.filterIconContainer}>
          <Icon
            size={20}
            svg
            onClick={this.vm.toggleFilterModal}
            className={styles.invertIcon}
          >
            {this.vm.hasAppliedFilter ? iconFilterOn : iconFilter}
          </Icon>
          {this.vm.hasAppliedFilter && (
            <span className={styles.filterCount}>
              {this.vm.filterCount}
            </span>
          )}
        </div>

        {this.renderFilterModal()}
      </div>
    );
  };

  renderFilterModal() {
    return (
      <BaseModal
        onClose={this.vm.toggleFilterModal}
        open={this.vm.isFilterModalOpen}
        isMobile={this.isMobile}
        centered
        height={420}
      >
        <div className={styles.filterModal}>
          <div className={clsx(styles.filterRow, styles.top)}>
            <Icon
              size={24}
              svg
              style={{ filter: 'invert(1)' }}
            >
              {iconFilter}
            </Icon>
            <div className={styles.title}>Filter by</div>
          </div>

          <div className={styles.filterRow}>
            <DatePicker
              key="event_start_date"
              label={t('event_start_date')}
              containerClassName={styles.fullWidth}
              placeholder={t('event_start_date')}
              onChange={(date, dateString) => {
                this.vm.setFilterTime('startAt', date);
              }}
              style={{ width: '100%' }}
              value={this.vm.filter?.startAt?.gte ? this.vm.startAtDayJs : null}
            />
            <div className={styles.rangeDash} />
            <DatePicker
              key="event_end_date"
              label={t('event_end_date')}
              containerClassName={styles.fullWidth}
              placeholder={t('event_end_date')}
              onChange={(date, dateString) => {
                this.vm.setFilterTime('endAt', date);
              }}
              style={{ width: '100%' }}
              value={this.vm.filter?.endAt?.lte ? this.vm.endAtDayJs : null}
            />
          </div>

          <TreeSelect
            placeholder={t('purchase_type')}
            type="type"
            vm={this.vm.typeTreeSelectVM}
          />

          <div className={clsx(styles.buttons, styles.marginTop20)}>
            <Button
              type="default"
              className={styles.resetBtn}
              onClick={() => this.vm.resetFilter()}
            >
              {t('clear_filter')}
            </Button>

            <Button
              className={styles.applyBtn}
              onClick={this.vm.applyFilter}
              withRightArrow
            >
              {t('apply_filter')}
            </Button>
          </div>
        </div>
      </BaseModal>
    );
  }

  renderList = () => {
    const listObj = this.vm[`${this.vm.currentTab}ListByDate`] ?? {};

    if (Object.keys(listObj).length === 0) {
      return this.renderEmpty();
    }

    return (
      <div className={styles.list}>
        {Object.entries(listObj).map((item) => {
          const date = item[0];
          const list = item[1];

          return (
            <section className={styles.section} key={item[0]}>
              <div className={styles.date}>{date}</div>
              <div className={styles.cardList}>
                {list.map((data) => this.vm.currentTab === TABS.Order
                  ? (
                    <OrderCard
                      item={data}
                      key={data.id}
                    />
                  ) : (
                    <PurchaseCard
                      item={data}
                      key={data.id}
                    />
                  ))}
              </div>
            </section>
          );
        })}
      </div>
    );
  };

  renderEmpty = () => {
    return (
      <div className={styles.emptyContainer}>
        {this.vm.anchoredLists[this.vm.currentTab]?.isAwait
          ? <Spin />
          : (
            <>
              <img
                className={clsx(styles.illustration)}
                src={errorSvg}
                alt="man looking at phone"
              />
              <div className={styles.msg}>
                {this.vm.isFiltered ? t('no_history_filtered') : t('no_history')}
              </div>
            </>
          )}
      </div>
    );
  };

  renderIntroModal() {
    return (
      <BaseModal
        onClose={this.vm.toggleIntroModal}
        open={this.vm.isIntroModalOpen}
        isMobile={this.isMobile}
        height="80%"
      >
        <div className={styles.introModal}>
          <div className={styles.title}>
            {t('buy_plan_desc_modal_title')}
          </div>

          <div className={styles.text}>
            {handleLineBreaks(t('buy_plan_desc_modal_content_1'))}
          </div>
          <div className={styles.textBold}>
            {t('buy_plan_desc_modal_content_subtitle')}
          </div>
          <div className={styles.text}>
            {handleLineBreaks(t('buy_plan_desc_modal_content_2'))}
          </div>

          <Button
            onClick={this.vm.toProduct}
            className={styles.button}
            withRightArrow
          >
            {t('buy_plan_desc_modal_button')}
          </Button>
        </div>
      </BaseModal>
    );
  }

  render() {
    return (
      <div className={styles.orderHistoryPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
        >
          <Breadcrumb
            routes={[
              { label: t('my_account'), path: `/user/profile/${this.vm.userId ?? ''}` },
              { label: t('breadcrumb_credit_history'), path: '' }
            ]}
            right={null}
            withHeader
          />
          <div className={styles.mainContainer}>
            {this.renderTopArea()}
            {this.renderTabs()}
          </div>
        </MainLayout>

        {this.renderIntroModal()}
      </div>
    );
  }
}

OrderHistoryPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(OrderHistoryPage)), true);
