import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import scrollDetector from 'src/stores/scrollDetector';
import loadingIcon from 'src/assets/loading.svg';
import styles from './styles.module.scss';


@observer
class Preview extends React.Component {

  constructor(props) {
    super();
    this.props = props;
    this.vm = props.vm;
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  render() {

    return (
      <div
        className={clsx(styles.previewContainer, !scrollDetector.isScrollingDown && styles.lower)}
      >
        {!this.vm.isReady && (
          <div className={styles.previewLoadingMask}>
            <img
              src={loadingIcon}
              width={50}
              height={50}
              className={styles.loadingImg}
              alt="loading img"
            />
          </div>
        )}

        <iframe
          key={this.vm.key}
          id="event-preview-iframe"
          title="event preview"
          width="100%"
          height="100%"
          src={this.vm.src}
          onLoad={this.vm.onLoad}
        />
      </div>
    );
  }
}

Preview.propTypes = {
  vm: PropTypes.object
};

Preview.defaultProps = {
  vm: {}
};

export default Preview;
