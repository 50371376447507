import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';


class ProposalPageViewModel {
  @observable isLoading = false;

  constructor(props) {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    console.log('ProposalPage.didMount, params', props.router.params);
    console.log(props);
    const { router } = props;
    this.props = props;
    this.isLoading = true;
  };

  @action onLoad = () => {
    console.log('load');
    this.isLoading = false;
  };

}

export default ProposalPageViewModel;
