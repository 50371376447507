import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { t } from 'i18next';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import EventService from 'src/services/event';
import DemandService from 'src/services/demand';
import { KEYWORD_TYPE, RESOURCE_TYPES } from 'src/constants';
import ErrorService from 'src/services/errors';
import tabsState from 'src/stores/tabsState';
import busyCursor from 'src/stores/busyCursor';

dayjs.extend(isSameOrBefore);

class EventDetailPageViewModel {
  @observable isMobile = false;
  @observable id = null;
  @observable event = null;

  @observable demands = {
    [RESOURCE_TYPES.Service]: [],
    [RESOURCE_TYPES.Funds]: [],
    [RESOURCE_TYPES.Space]: []
  };

  @observable similarEvents = [];

  @observable hasClickedDescMore = false;
  @observable hasClickedKeyFindingMore = false;
  @observable isDescClamped = false;
  @observable isKeyFindingClamped = false;

  @observable isFromCreateEvent = false;
  @observable isLoading = false;

  @computed get service() {
    return this.demands[RESOURCE_TYPES.Service];
  }

  @computed get space() {
    return this.demands[RESOURCE_TYPES.Space];
  }

  @computed get funds() {
    return this.demands[RESOURCE_TYPES.Funds];
  }

  @computed get currentDemandTab() {
    return tabsState.demandsTab;
  }

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props) => {
    console.log('EventDetailPage.didMount, params', props.router.params);
    await this.initData(props);
  };

  @action didUpdate = async (prevProps, props) => {
    this.id = props.router.params.id;
    const prevId = prevProps.router.params.id;
    if (prevId !== this.id) {
      await this.initData(props);
    }
  };

  @action initData = async (props) => {
    busyCursor.onEventDetailBusy();
    const { router, context } = props;
    this.id = router.params.id;
    this.isMobile = context?.state?.isMobile;
    this.isFromCreateEvent = router.location?.state?.isFromCreateEvent;
    await Promise.all([
      this.getDetail(),
      this.getSimilarEvents()
    ]);
    await this.getDemands();

    busyCursor.onEventDetailFinish();
  };

  @computed
  get remainedTime() {
    const diff = dayjs(this.event.endAt) - dayjs();
    const cd = 24 * 60 * 60 * 1000;
    return Math.floor(diff / cd).toString();
  }

  @action checkIsTextClamped = (ref, keyName) => {
    const el = ref.current;
    if (el) {
      this[`is${keyName}Clamped`] = el.scrollHeight > el.clientHeight;
      return;
    }
    this[`is${keyName}Clamped`] = false;
  };

  getDetail = async () => {
    try {
      const res = await EventService.detail(this.id);
      runInAction(() => {
        this.event = res;
        tabsState.updateDemandsTab(Object.values(RESOURCE_TYPES).find((type) => this.event.demandTypes?.includes(type)) ?? RESOURCE_TYPES.Service);
      });
      console.log(res);
    } catch (error) {
      const { navigate } = this.props.router;
      switch (error.response?.status) {
        case 403:
          ErrorService.onCustomError(
            t('event_detail_error_403'),
            null,
            () => navigate(-1)
          );
          break;
        case 401: // when token is expired
          ErrorService.onCustomError(
            t('general_error_content_401'),
            null,
            () => navigate(0)
          );
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  getDemands = async () => {
    if (!this.event?.demandTypes?.length) { return; }

    const res = await Promise.all(
      this.event.demandTypes.map(async (type) => {
        try {
          const list = await DemandService.getList(type, this.id);
          runInAction(() => {
            this.demands[type] = list ?? [];
          });
          return list;
        } catch (error) {
          ErrorService.onDefaultError(error);
          return null;
        }
      })
    );
  };

  getSimilarEvents = async () => {
    try {
      const res = await EventService.similarEvents(this.id);
      runInAction(() => {
        this.similarEvents = res;
      });
      console.log(this.similarEvents);
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  @computed
  get serviceTypesTags() {
    return this.event?.serviceTypesTags ?? [];
  }

  @action handleClickJoin = (ref) => {
    this.scrollEffect(ref);
  };

  scrollEffect = (targetRef) => {
    const targetTop = targetRef.current.offsetTop;
    window.scrollTo({
      top: targetTop - 120,
      behavior: 'smooth'
    });
  };

  @action clickDescMore = () => {
    this.hasClickedDescMore = true;
  };

  @action clickKeyFindingMore = () => {
    this.hasClickedKeyFindingMore = true;
  };

  @action changeDemandTab = (key) => {
    tabsState.updateDemandsTab(key);
  };

  @action onCopySample = async () => {
    this.isLoading = true;
    let newId = null;
    try {
      const event = await EventService.copy(this.id);
      newId = event?.id;
      if (newId) {
        window.gtag(
          'event',
          'Create_Event_Copy_Sample',
          {
            Event_ID: this.id,
            Quoted_Event_ID: newId
          }
        );
        window.scroll(0, 0);
        this.toCreateEvent(event.id);
      }
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @computed
  get isRecruiting() {
    return this.event.enableRecruit && this.isStarted && !this.isEnded;
  }

  @computed
  get isStarted() {
    return !!this.event.startAt && dayjs(this.event.startAt).isSameOrBefore(dayjs());
  }

  @computed
  get isEnded() {
    return !!this.event.endAt && dayjs(this.event.endAt).isBefore(dayjs());
  }

  onHashTagSearch = (key, value) => {
    const { navigate } = this.props.router;
    const filter = {};
    if (key === KEYWORD_TYPE.Sdg) {
      filter.sdgs = [value.split('_')[1]];
    } else {
      filter.keyword = value;
    }

    navigate('/events', {
      state: {
        filter
      }
    });
  };

  toEventPageWithKey = (key, value) => {
    const { router } = this.props;
    const { navigate } = router;
    navigate('/events', {
      state: {
        filter: {
          [key]: value.length ? value : [value]
        }
      }
    });
  };

  toAllDemands = () => {
    const { navigate } = this.props.router;
    navigate('./demands');
  };

  toDemand = (id) => {
    const { navigate } = this.props.router;
    navigate(`./demands/${id}`);
  };

  toEventDetail = (id) => {
    const { navigate } = this.props.router;
    navigate(`/events/${id}`);
    window.scrollTo(0, 0);
  };

  toAllEvents = () => {
    const { navigate } = this.props.router;
    navigate('/events');
  };

  toProfile = (userId) => {
    const { navigate } = this.props.router;
    navigate(`/user/profile/${userId}`);
  };

  toCreateEvent = (id) => {
    const { navigate } = this.props.router;
    navigate(`/create-event/${id}`);
  };

}

export default EventDetailPageViewModel;

