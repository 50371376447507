import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ConfigProvider from 'antd/es/config-provider';
import AntButton from 'antd/es/button';
import rightArrow from 'src/assets/iconRightArrow.svg';
import Icon from '../Icon';

import './styles.scss';

function ButtonWithImg(props) {
  const { children, containerClassName, imgSrc, imgStyle, ...buttonProps } = props;
  const invertStyle = {
    filter: 'invert(1)',
    opacity: 0.7
  };

  return (
    <div className={clsx('buttonWithImage', containerClassName)}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#414EE1',
            fontSize: 16,
            colorPrimaryHover: '#303999'
          }
        }}
      >
        <AntButton
          type="primary"
          {...buttonProps}
        >

          { children }

          <Icon
            size={18}
            svg
            style={{
              height: 20,
              transform: 'translate(6px, -3px)',
              ...props.type === 'default' && invertStyle
            }}
          >
            {rightArrow}
          </Icon>
          <img src={imgSrc} alt="button" className={clsx('buttonImage')} style={imgStyle} />
        </AntButton>
      </ConfigProvider>
    </div>
  );
}

ButtonWithImg.propTypes = {
  children: PropTypes.any,
  imgSrc: PropTypes.any,
  imgStyle: PropTypes.object,
  containerClassName: PropTypes.string,
  type: PropTypes.string
};

ButtonWithImg.defaultProps = {
  children: null,
  imgSrc: null,
  imgStyle: {},
  containerClassName: '',
  type: 'primary'
};

export default ButtonWithImg;
