import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import i18n from 'src/i18n';
import EventService from 'src/services/event';
import ResourceService from 'src/services/resource';
import { RESOURCE_TYPES } from 'src/constants';
import ErrorService from 'src/services/errors';
import pretendTo from 'src/stores/pretendTo';

class ResourceDetailPageViewModel {
  @observable id = null;
  @observable type = null;
  @observable detail = {};
  @observable profile;
  @observable hasClickedDescMore = false;
  @observable isDescClamped = false;

  // invite modal
  @observable isModal = false;

  // add modal
  @observable isOpen = false;
  @observable modalType = RESOURCE_TYPES.Service;

  // success
  @observable isSuccess = false;
  @observable selectedItem = null;

  @observable createdEventList = [];
  @observable isFromApplication = false;
  @observable applicationId;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }


  @computed get isOwner() {
    return this.profile?.id === this.detail?.user?.id;
  }

  @computed get isEventCreater() {
    return this.profile?.permission?.event[0] === '1';
  }

  @computed get isResourceCreater() {
    return this.profile?.permission?.resource[0] === '1';
  }

  @computed get isPretend() {
    return !!pretendTo.id;
  }


  @computed get buttonStatus() {
    if (this.isFromApplication) {
      return i18n.t('resource_detail_btn_view_application');
    }
    if (this.isOwner) {
      return i18n.t('resource_detail_btn_3');
    }

    if (this.isEventCreater && this.isPretend) {
      return i18n.t('resource_detail_suggest_btn');
    }

    if (this.isEventCreater) {
      return i18n.t('resource_detail_btn_2');
    }
    return i18n.t('resource_detail_btn_1');
  }


  @action
    checkIsTextClamped = (ref, keyName) => {
      const el = ref.current;
      if (el) {
        console.log(el.scrollHeight, el.clientHeight);
        this[`is${keyName}Clamped`] = el.scrollHeight > el.clientHeight;
        return;
      }
      this[`is${keyName}Clamped`] = false;
    };

  @action clickDescMore = () => {
    this.hasClickedDescMore = true;
  };

  @action
    didMount = async (props) => {
      console.log('ResourceDetailPage.didMount, params', props.router.params);
      console.log(props);
      this.id = props.router.params.id;
      this.type = props.router.params.type;
      this.profile = props.profile;

      const { state } = props.router.location;
      console.log(state);
      this.isFromApplication = state?.isFromApplication;
      this.applicationId = state?.applicationId;

      await this.getDetail(this.id);
    };

  @action didUpdate = (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile)) {
      this.name = cur.profile?.name;
      this.profile = cur.profile;
    }
  };

  @action
    getDetail = async () => {
      try {
        const res = await ResourceService.getDetail(this.id);
        runInAction(() => {
          console.log('res', res);
          this.detail = res;
        });
      } catch (error) {
        const { navigate } = this.props.router;
        if (error.response?.status === 403) {
          ErrorService.onCustomError(
            i18n.t('resource_detail_error_403'),
            null,
            () => navigate(-1)
          );
        } else if (error.response?.status === 401) { // when token is expired
          ErrorService.onCustomError(
            i18n.t('general_error_content_401'),
            null,
            () => navigate(0)
          );
        } else {
          ErrorService.onDefaultError(error);
        }
      }
    };


  @action toggleModal = () => {
    this.isModal = !this.isModal;
  };

  @action onModalToggle = () => {
    this.isOpen = !this.isOpen;
  };

  @action onModalChange = (type) => {
    this.modalType = type;
  };

  @action onModalConfirmed = async (item) => {
    console.log('item', item);

    const eid = item.id;

    this.selectedItem = item;
    this.isModal = false;
    this.sendGAEvent(`Invite_${this.type.charAt(0).toUpperCase() + this.type.slice(1)}_Complete`, eid);

    try {
      if (this.isPretend) {
        await EventService.recommentd(eid, this.id);
      } else {
        await EventService.invite(eid, this.id);
      }

      runInAction(() => {
        this.isSuccess = true;
      });

    } catch (error) {
      switch (error.response?.status) {
        case 409:
          ErrorService.onCustomError(i18n.t('error_resource_invite_409'));
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  @action closeSuccess = () => {
    this.isSuccess = false;
  };

  onCtaPressed = () => {
    if (!this.profile) {
      this.toLogin();
      return;
    }
    if (this.isFromApplication) {
      this.toApplication();
      return;
    }
    if (this.isOwner) {
      this.onModalToggle();
      return;
    }

    if (this.isEventCreater && this.isPretend) {
      this.sendGAEvent(`Recommend_${this.type.charAt(0).toUpperCase() + this.type.slice(1)}_Click`);
      this.toggleModal();
      return;
    }

    if (this.isEventCreater) {
      this.sendGAEvent(`Invite_${this.type.charAt(0).toUpperCase() + this.type.slice(1)}_Click`);
      this.toggleModal();
      return;
    }

    this.onModalToggle();
  };

  sendGAEvent(eventName, eid) {
    const eventMiddleName = this.type.charAt(0).toUpperCase() + this.type.slice(1);
    switch (eventName) {
      // Recommend_Service_Click, Recommend_Funds_Click, Recommend_Space_Click
      case `Recommend_${eventMiddleName}_Click`:
        window.gtag('event', eventName, {
          Resource_Name: this.detail.name
        });
        break;
      // Invite_Service_Click, Invite_Funds_Click, Invite_Space_Click
      case `Invite_${eventMiddleName}_Click`:
        window.gtag('event', eventName, {
          Resource_Name: this.detail.name
        });
        break;
      // Invite_Service_Complete, Invite_Funds_Complete, Invite_Space_Complete
      case `Invite_${eventMiddleName}_Complete`:
        window.gtag('event', eventName, {
          Event_ID: eid,
          Resource_Name: this.detail.name
        });
        break;
      default:
    }
  }

  willUnmount = (props) => {
    console.log('ResourceDetailPage.willUnmount');
  };

  toApplication = () => {
    const { navigate } = this.props.router;
    if (this.applicationId) {
      navigate(`/application/${this.applicationId}`);
    }
  };

  toLogin = () => {
    const { navigate, location } = this.props.router;
    navigate('/login', { state: { source: location?.pathname } });
  };

  toFormPage = () => {
    const { navigate } = this.props.router;
    navigate('/user/my-resource/form', { state: { type: this.modalType } });
  };

  toProfile = () => {
    const { navigate } = this.props.router;
    navigate(`/user/profile/${this.detail.user?.id}`);
  };

  toResoursePageWithKey = (key, value) => {
    const { router } = this.props;
    const { navigate } = router;
    navigate('/resources', {
      state: {
        filter: {
          [key]: value?.length ? value : [value]
        },
        type: this.detail.type
      }
    });
  };



}

export default ResourceDetailPageViewModel;
