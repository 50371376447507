import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { t } from 'i18next';
import PurchaseService from 'src/services/Purchase';
import ErrorService from 'src/services/errors';

class CheckoutResultPageViewModel {
  @observable detail = null;
  @observable orderId = null;
  @observable isSuccess = true;
  @observable eventId = null;

  constructor(props) {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    console.log('CheckoutResultPage.didMount, params', props.router.params);
    console.log(props);
    const { router } = props;
    this.props = props;
    this.orderId = props.router?.params?.id;
    this.eventId = localStorage.getItem('fromCreateEventId');

    if (this.orderId) {
      await this.getPurchaseDetail();
    }
  };

  getPurchaseDetail = async () => {
    try {
      const detail = await PurchaseService.detail(this.orderId);
      console.log('detail', detail);
      runInAction(() => {
        this.detail = detail;
        this.isSuccess = detail.isSuccess;
      });
    } catch (error) {
      switch (error?.response.status) {
        case 400:
        case 404:
          ErrorService.onCustomError(
            t('error_order_not_found'),
            null,
            this.toOrderHistory
          );
          break;
        case 403:
          ErrorService.onCustomError(
            t('error_order_forbidden'),
            null,
            this.toOrderHistory
          );
          break;
        default:
      }
    }
  };

  // //////////////////////////////////////

  toProduct = () => {
    const { navigate } = this.props.router;
    navigate('/product');
  };

  toOrderHistory = () => {
    const { navigate } = this.props.router;
    navigate('/user/order-history');
  };

  toCreateEvent = () => {
    localStorage.removeItem('fromCreateEventId');
    const { navigate } = this.props.router;
    navigate(`/create-event/${this.eventId}`);
  };

  @computed get isFromCreateEvent() {
    return !!this.eventId;
  }

}

export default CheckoutResultPageViewModel;
