import React from 'react';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Success from 'src/components/Success';

@observer
class StepSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.vm = this.props.vm;
  }

  render() {
    return (
      <Success
        title={t('signup_success_title')}
        texts={[t('signup_success_text1'), this.vm.event?.name, t('signup_success_text2')]}
        onClickButton={this.vm.onClickMoreEvents}
        buttonText={t('signup_success_button')}
        bottomText={t('signup_success_bottom_text')}
        bottomLinkText={t('signup_success_bottom_link')}
        bottomLinkHref="/events"
      />
    );
  }
}

export default StepSuccess;

StepSuccess.propTypes = {
  vm: PropTypes.object
};

StepSuccess.defaultProps = {
  vm: {}
};
