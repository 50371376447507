import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import { isPro } from 'src/stores';
import Button from 'src/components/Button';
import congrats from 'src/assets/illustrationCongrats.svg';

import styles from './styles.module.scss';

@observer
class Done extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  render() {
    const { point, eventName, releaseMethodUIString, onButtonClick } = this.props;

    return (
      <div className={styles.content}>
        <div className={styles.leftContainer}>
          <div className={styles.title}>
            {t('create_event_done_title')}
          </div>

          <div className={styles.subtitle}>
            <span>
              {t('create_event_done_desc_1')}
            </span>
            <span className={styles.primary}>
              {t('create_event_done_desc_2')}
            </span>
            <span>
              {t('create_event_done_desc_3')}
            </span>
          </div>

          <div className={styles.infos}>
            <div>
              <span className={styles.label}>
                {t('create_event_done_field_name')}
              </span>
              <span>
                {eventName}
              </span>
            </div>

            {!isPro && (
              <div>
                <span className={styles.label}>
                  {t('create_event_done_credit')}
                </span>
                <span className={styles.marginRight3}>
                  {point}
                </span>
                <span>
                  {t('create_event_done_credit_unit')}
                </span>
              </div>
            )}

            <div>
              <span className={styles.label}>
                {t('create_event_done_recruit_period')}
              </span>
              <span>
                {releaseMethodUIString}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.rightContainer}>
          <img
            src={congrats}
            alt="congrats"
            className={styles.img}
          />
          <Button
            className={styles.button}
            onClick={onButtonClick}
          >
            {t('create_event_done_button')}
          </Button>
        </div>
      </div>
    );
  }
}

Done.propTypes = {
  point: PropTypes.number,
  eventName: PropTypes.string,
  releaseMethodUIString: PropTypes.string,
  onButtonClick: PropTypes.func
};

Done.defaultProps = {
  point: 1,
  eventName: '-',
  releaseMethodUIString: '-',
  onButtonClick: null
};

export default Done;
