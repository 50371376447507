import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { CREATE_EVENT_STEPS, CREATE_STEP_STATUS } from 'src/constants';
import StepIcon from 'src/pages/CreateEvent/CreateEventPage/components/StepIcon';
import styles from './styles.module.scss';

function Title({ step }) {
  return (
    <div className={styles.titleContainer}>
      <StepIcon
        step={step}
        status={CREATE_STEP_STATUS.Active}
      />
      <div className={styles.title}>
        {t(`create_step_${step}`)}
      </div>
    </div>
  );
}

Title.propTypes = {
  step: PropTypes.string
};

Title.defaultProps = {
  step: CREATE_EVENT_STEPS.Basic
};

export default Title;
