import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import {
  makeObservable, computed
} from 'mobx';
import { CREATE_EVENT_STEPS } from 'src/constants';
import scrollDetector from 'src/stores/scrollDetector';
import progressImg0 from 'src/assets/createEvent/progress0.svg';
import progressImg5 from 'src/assets/createEvent/progress5.svg';
import progressImg25 from 'src/assets/createEvent/progress25.svg';
import progressImg55 from 'src/assets/createEvent/progress55.svg';
import progressImg85 from 'src/assets/createEvent/progress85.svg';
import progressImg100 from 'src/assets/createEvent/progress100.svg';
import styles from './styles.module.scss';
import MenuItem from '../MenuItem';

@observer
class SideMenu extends React.Component {
  constructor(props) {
    super();
    makeObservable(this);
    this.props = props;
  }

  @computed get progressImg() {
    switch (this.props.phase) {
      case 0:
        return progressImg0;
      case 1:
        return progressImg5;
      case 2:
        return progressImg25;
      case 3:
        return progressImg55;
      case 4:
        return progressImg85;
      case 5:
        return progressImg100;
      default:
        return progressImg0;
    }
  }

  @computed get rotateDeg() {
    const { progress } = this.props;
    return 180 + (progress * 1.8);
  }

  renderProgress() {
    const { progress, phase } = this.props;
    const isDone = progress === 100;
    return (
      <div className={styles.progressContainer}>
        <div className={styles.texts}>
          <div className={clsx(styles.number, phase > 2 && styles.green)}>
            {progress}
            <span className={styles.mark}>
              %
            </span>
          </div>
          <div>
            {t(`create_event_progress_${phase}`)}
          </div>
        </div>

        <div className={styles.progressBarContainer}>
          <span className={clsx(styles.corner, phase > 2 && styles.green, isDone && styles.done)} />
          <span className={clsx(styles.corner, styles.right, isDone && styles.done)} />
          <div className={clsx(styles.progressBarCircle)}>
            <div
              className={clsx(styles.progressBar, phase > 2 && styles.green, isDone && styles.done)}
              style={{ transform: `rotate(${this.rotateDeg}deg)` }}
            >
              <span className={clsx(styles.corner, styles.innerCorner, phase > 2 && styles.green, isDone && styles.done)} />
            </div>
          </div>

          <div className={styles.circleCenter} />
          <div className={styles.rect} />
        </div>

        <img
          className={styles.progressImg}
          alt="progress"
          src={this.progressImg}
        />
      </div>
    );
  }

  render() {
    const {
      currentStep,
      onClickItem,
      isDone,
      isEventCreated,
      isVisionDone
    } = this.props;

    const renderItems = () => Object.values(CREATE_EVENT_STEPS).map((item) => (
      <MenuItem
        label={item}
        key={item}
        isActive={currentStep === item}
        isDone={item === CREATE_EVENT_STEPS.Vision ? isVisionDone : isDone[item]}
        onClick={onClickItem}
        isDisabled={!isEventCreated && item !== CREATE_EVENT_STEPS.Basic}
      />
    ));

    return (
      <div className={clsx(styles.menuWrapper, !scrollDetector.isScrollingDown && styles.lower)}>
        <div
          className={styles.menuContainer}
        >
          {this.renderProgress()}

          <div className={styles.menuItems}>
            {renderItems()}
          </div>
        </div>
      </div>
    );
  }
}

SideMenu.propTypes = {
  currentStep: PropTypes.string,
  onClickItem: PropTypes.func,
  isDone: PropTypes.object,
  isEventCreated: PropTypes.bool,
  progress: PropTypes.number,
  phase: PropTypes.PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  isVisionDone: PropTypes.bool
};

SideMenu.defaultProps = {
  currentStep: null,
  onClickItem: () => {},
  isDone: {},
  isEventCreated: false,
  progress: 0,
  phase: 1,
  isVisionDone: false
};

export default SideMenu;
