import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import { AppStateContext } from 'src/stores';
import UserService from 'src/services/user';
import withRouter from 'src/components/withRouter';

const withProfile = (WrappedComponent, isRequired = true) => {
  class WithProfileHOC extends React.Component {
    static propTypes = {
      router: PropTypes.object.isRequired,
      forwardedRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ])
    };

    static defaultProps = {
      forwardedRef: null
    };

    async componentDidMount() {
      const { actions } = this.context ?? {};

      try {
        const p = await UserService.getProfile();
        actions.updateProfile(p);

      } catch (err) {
        actions.updateProfile(null);

        // only logout with 4xx error && has token
        if (String(err.response?.status)[0] === '4' && Cookies.get('auth')) {
          await UserService.logout();
        }

        if (isRequired) {
          const { navigate, location } = this.props.router;
          navigate('/login', { state: { source: location?.pathname } });
        }
      }
    }

    render() {
      const {
        forwardedRef,
        router,
        ...ps
      } = this.props;

      return isRequired && !this.context.state.profile ? null : (
        <WrappedComponent
          {...ps}
          profile={this.context.state.profile}
          ref={forwardedRef}
        />
      );
    }
  }

  WithProfileHOC.contextType = AppStateContext;

  const Component = withRouter(WithProfileHOC);

  return React.forwardRef((props, ref) => {
    return <Component {...props} forwardedRef={ref} />;
  });
};

export default withProfile;
