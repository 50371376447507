import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import i18n from 'src/i18n';
import { TABLE_LIMIT, TABLE_LIMIT_MOBILE, LIST_MODEL_TYPE } from 'src/constants';
import UserService from 'src/services/user';
import ErrorService from 'src/services/errors';
import CommonService from 'src/services/common';
import ProductService from 'src/services/product';

class ProductPageViewModel {
  @observable userId = null;
  @observable list = [];
  @observable isFetching = false;

  constructor(props) {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    console.log('ProductPage.didMount, params', props.router.params);
    console.log(props);
    this.props = props;
    await Promise.all([
      this.getPromotionText(),
      this.getProductList()
    ]);
  };

  @action getPromotionText = async () => {
    try {
      const text = await CommonService.productText();
      runInAction(() => {
        this.promotionText = text;
      });
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }
  };

  @action getProductList = async () => {
    this.isFetching = true;
    try {
      const { list } = await ProductService.list();
      console.log('products', list);
      runInAction(() => {
        this.list = list;
      });
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  };

  toCheckout = (id, item) => {
    const { navigate } = this.props.router;
    navigate(`/product/${id}/checkout`, { state: { price: item.priceForDisplay, product: item } });
  };

  toOrderHistory = () => {
    const { navigate } = this.props.router;
    navigate('/user/order-history');
  };


}

export default ProductPageViewModel;
