import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import DatePicker from 'antd/es/date-picker';
import locale from 'antd/locale/zh_TW';
import ConfigProvider from 'antd/es/config-provider';
import Constants from 'src/stores/constants';
import iconUneditable from 'src/assets/iconUneditable.svg';
import iconClock from 'src/assets/iconClock.svg';
import Icon from '../Icon';

import './styles.scss';

class CustomDatePicker extends React.Component {

  ref = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const props = this.props;
    const { showError, validValue, errorMessage, containerClassName, ...ps } = props;
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#414EE1',
            borderRadius: 25
          }
        }}
        locale={Constants.language === 'zh-TW' ? locale : null}
      >
        <div className={clsx(
          'customPicker',
          (!props.value && !props.defaultValue) && 'isEmpty',
          props.required && 'isRequired',
          (showError && !validValue) && 'error',
          containerClassName
        )}
        >
          {(props.value || props.defaultValue) && (
            <div
              className={clsx('pickerLabel', props.required ? 'custom-picker-required' : '')}
              ref={this.ref}
            >
              {props.required && (
                <span className="requireMark">*</span>
              )}
              {props.label}
            </div>
          )}
          <DatePicker
            format={props.picker === 'month' ? 'YYYY.MM' : 'YYYY.MM.DD'}
            className={props.className}
            {...ps}
            onFocus={props.value ? () => {
              this.ref.current.classList.add('labelPurple');
            } : null}
            onBlur={props.value ? () => {
              this.ref.current.classList.remove('labelPurple');
            } : null}
            suffixIcon={
              props.readOnly
                ? (
                  <Icon
                    size={24}
                    svg
                  >
                    {iconUneditable}
                  </Icon>
                )
                : (
                  <Icon
                    size={24}
                    svg
                  >
                    {iconClock}
                  </Icon>
                )
            }
            inputReadOnly={props.readOnly || props.isMobile}
            allowClear={!props.readOnly && props.allowClear}
            popupStyle={props.readOnly ? { display: 'none' } : null}
          />
          {(showError && !validValue) && (
            <div className="datePicker_errorMessage">
              {errorMessage || t('this_is_required')}
            </div>
          )}
        </div>
      </ConfigProvider>
    );
  }
}

CustomDatePicker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  validValue: PropTypes.bool,
  errorMessage: PropTypes.string,
  containerClassName: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  picker: PropTypes.string,
  isMobile: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  allowClear: PropTypes.bool
};

CustomDatePicker.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  showError: false,
  validValue: true,
  errorMessage: t('this_is_required'),
  containerClassName: '',
  required: false,
  readOnly: false,
  picker: 'date',
  isMobile: false,
  label: null,
  value: undefined,
  defaultValue: undefined,
  allowClear: true
};

export default CustomDatePicker;
