import React from 'react';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import iconRightArrow from 'src/assets/iconRightArrow.svg';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const HeroImagePopover = (props) => {
  const { number, top, left, colorMode, className, otherStyles, onClick } = props;

  return (
    <div
      className={clsx(className)}
      style={{ top, left, backgroundColor: colorMode === 1 ? '#414EE1' : '#0C0E17', ...otherStyles
      }}
      onClick={onClick}
    >
      <span className={clsx(styles.popOverTitle)}>{i18n.t(`hero_image_popover_${number}_title`)}</span>
      <span className={clsx(styles.popOverContent)}>
        <span>{i18n.t(`hero_image_popover_${number}_description`)}</span>
        <img src={iconRightArrow} alt="arrow right" />
      </span>
    </div>
  );
};

export default observer(HeroImagePopover);

HeroImagePopover.propTypes = {
  number: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  colorMode: PropTypes.number,
  className: PropTypes.string,
  otherStyles: PropTypes.object,
  onClick: PropTypes.func
};

HeroImagePopover.defaultProps = {
  number: 1,
  top: undefined,
  left: undefined,
  colorMode: 1,
  className: '',
  otherStyles: {},
  onClick: null
};
