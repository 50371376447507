import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { t } from 'i18next';
import dayjs from 'dayjs';
import DemandService from 'src/services/demand';
import UserService from 'src/services/user';
import EventService from 'src/services/event';
import MemberOnlyFeatureModalVM, { getBlockReason } from 'src/components/Modals/MemberOnlyFeatureModal/vm';
import { BLOCK_REASONS, RESOURCE_TYPES } from 'src/constants';
import ErrorService from 'src/services/errors';
import MyResourcesModalVM from 'src/components/Modals/MyResourceModal/vm';

class DemandDetailPageViewModel {
  @observable eid = null; // event id
  @observable id = null; // demand id
  @observable uid = null; // user id
  @observable demand = null;
  @observable event;
  @observable type;
  @observable resourceSelected = null;
  @observable profile = null;

  @observable isAddResourceModalOpen = false;

  @observable modalType = RESOURCE_TYPES.Service;

  @observable isFromApplication = false;

  @observable memberOnlyFeatureModalVM = null;
  @observable myResourcesModalVM = null;

  @observable isLoading = false;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props) => {
    console.log('DemandDetailPage.didMount, params', props.router.params);
    const { state } = props.router.location;
    this.props = props;
    this.eid = props.router.params.eid;
    this.id = props.router.params.did;
    this.isFromApplication = state?.isFromApplication;
    await this.getEventDetail();
    await this.getDetail();
  };

  @action getEventDetail = async () => {
    const { state } = this.props.router.location;
    console.log(state);
    try {
      const res = await EventService.detail(this.eid);
      runInAction(() => {
        this.event = res;
      });
    } catch (error) {
      if (error.response?.status === 401) { // when token is expired
        ErrorService.onCustomError(
          t('general_error_content_401'),
          null,
          () => this.props.router.navigate(0)
        );
      } else {
        ErrorService.onDefaultError(error);
      }
    }
  };

  @action getDetail = async () => {
    try {
      const res = await DemandService.detail(this.id);
      this.demand = res;
      this.type = res.type;
      console.log(res);
    } catch (error) {
      if (error.response?.status === 401) { // when token is expired
        ErrorService.onCustomError(
          t('general_error_content_401'),
          null,
          () => this.props.router.navigate(0)
        );
      } else {
        ErrorService.onDefaultError(error);
      }
    }
  };

  @action getProfile = async () => {
    if (this.props.profile) {
      this.uid = this.props.profile.id;
      this.profile = this.props.profile;
    } else {
      const res = await UserService.getProfile();
      runInAction(() => {
        this.profile = res;
        this.uid = res.id;
      });
    }
  };

  sendGAEvent(eventName, context) {
    switch (eventName) {
      case 'Event_Join_Click':
        window.gtag('event', 'Event_Join_Click', {
          Event_ID: this.eid,
          Requirement_Name: this.demand.name
        });
        break;
      case 'Event_Join_Click_Interrupted':
        if (context.blockReason === BLOCK_REASONS.EmailValidate) {
          window.gtag('event', 'Event_Join_Click_Interrupted', {
            Event_ID: this.eid,
            Requirement_Name: this.demand.name,
            Failed_Type: 'Email未認證'
          });
        } else if (context.blockReason === BLOCK_REASONS.PhoneValidate) {
          window.gtag('event', 'Event_Join_Click_Interrupted', {
            Event_ID: this.eid,
            Requirement_Name: this.demand.name,
            Failed_Type: '電話未認證'
          });
        } else if (context.blockReason === BLOCK_REASONS.Login) {
          window.gtag('event', 'Event_Join_Click_Interrupted', {
            Event_ID: this.eid,
            Requirement_Name: this.demand.name,
            Failed_Type: '未登入'
          });
        }
        break;
      default:
    }
  }

  onClickRegister = async () => {
    if (UserService.isLogin) {
      await this.getProfile();
    }

    const blockReason = getBlockReason(this.profile);

    if (!blockReason) {
      this.sendGAEvent('Event_Join_Click');
      this.myResourcesModalVM = new MyResourcesModalVM({
        eid: this.eid,
        did: this.demand.id,
        dName: this.demand.name,
        uid: this.uid,
        type: this.type,
        onAddResource: this.openAddResourceModal,
        router: this.props.router
      });
      return;
    }

    this.sendGAEvent('Event_Join_Click_Interrupted', { blockReason });

    if (this.memberOnlyFeatureModalVM) {
      this.memberOnlyFeatureModalVM.toggleModal();
    } else {
      this.memberOnlyFeatureModalVM = new MemberOnlyFeatureModalVM({ blockReason, router: this.props.router });
    }
  };

  @action onCloseMyResourcesModal = () => {
    this.myResourcesModalVM.closeModal();
    setTimeout(() => {
      this.myResourcesModalVM = null;
    }, 300);
  };

  // for Add Resource Modal
  @action onModalChange = (type) => {
    this.modalType = type;
  };

  @action toggleAddResourceModal = () => {
    this.isAddResourceModalOpen = !this.isAddResourceModalOpen;
  };

  @action openAddResourceModal = () => {
    this.modalType = this.type;
    this.isAddResourceModalOpen = true;
    this.onCloseMyResourcesModal();
  };

  toFormPage = () => {
    const { navigate } = this.props.router;
    navigate('/user/my-resource/form', { state: { type: this.modalType } });
  };

  @computed
  get shouldShowJoinButton() {
    return !this.isFromApplication && this.enableRecruit;
  }

  @computed
  get buttonString() {
    if (this.isNotStarted) { // 尚未開始
      return t('event_ctabutton_not_start');
    }

    if (this.isEnd) { // 結束招募
      return t('event_ctabutton_end');
    }

    if (this.enableRecruit && !this.isEnd) { // 招募中
      return t('demand_sign_up_button');
    }

    if (!this.enableRecruit && !this.isEnd) { // 暫停招募
      return t('event_ctabutton_disable');
    }

    return '';
  }

  @computed
  get isEnd() {
    if (this.event) {
      return dayjs(this.event.endAt).isBefore(dayjs());
    }
    return false;
  }

  @computed
  get isNotStarted() {
    if (this.event) {
      return !this.event.startAt || dayjs(this.event.startAt).isAfter(dayjs());
    }
    return false;
  }

  @computed
  get enableRecruit() {
    if (this.event) {
      return this.event.enableRecruit;
    }
    return false;
  }

  @computed
  get isButtonDisabled() {
    return !this.enableRecruit || this.isEnd || this.isNotStarted;
  }

}

export default DemandDetailPageViewModel;

