import User from 'src/models/response/User';

export default class Belong {
  constructor(params) {
    const {
      id,
      owner,
      member,
      createdAt
    } = params ?? {};

    this.id = id;

    if (owner) {
      this.owner = User.fromRes(owner);
      this.startAt = owner.startAt;
      this.endAt = owner.endAt;
    }

    if (member) {
      this.member = User.fromRes(member);
      this.startAt = member.startAt;
      this.endAt = member.endAt;
    }

    this.createdAt = createdAt;
  }

  get user() {
    return this.owner || this.member || {};
  }


  static fromRes(data) {
    return new Belong(data);
  }

}
