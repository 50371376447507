import { RESOURCE_TYPES } from 'src/constants';
import Funds from './Funds';
import Service from './Service';
import Space from './Space';

export default class Resource {
  constructor(params) {
    const {
      id,
      type,
      name,
      claim,
      description,
      expectance,
      isActive,
      meta,
      user
    } = params ?? {};

    this.id = id;
    this.type = type;
    this.name = name;
    this.claim = claim;
    this.description = description;
    this.expectance = expectance;
    this.isActive = isActive;
    this.meta = (() => {
      switch (type) {
        case RESOURCE_TYPES.Service:
          return Service.fromRes(meta);
        case RESOURCE_TYPES.Space:
          return Space.fromRes(meta);
        case RESOURCE_TYPES.Funds:
          return Funds.fromRes(meta);
        default:
          return null;
      }
    })();
    this.user = user;
  }

  static fromRes(data) {
    return new Resource(data);
  }
}



