import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import i18n from 'src/i18n';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import tabsState from 'src/stores/tabsState';
import DemandService from 'src/services/demand';
import EventService from 'src/services/event';
import { RESOURCE_TYPES } from 'src/constants';
import ErrorService from 'src/services/errors';
import MyResourcesModalVM from 'src/components/Modals/MyResourceModal/vm';
import MemberOnlyFeatureModalVM, { getBlockReason } from 'src/components/Modals/MemberOnlyFeatureModal/vm';

dayjs.extend(isSameOrBefore);

class DemandsPageViewModel {
  @observable eid = null; // event id
  @observable did = null; // demand id
  @observable uid = null; // user id
  @observable profile = null;
  @observable demands = null;
  @observable service = [];
  @observable funds = [];
  @observable space = [];
  @observable doIHaveResource = false;
  @observable resourceSelected = null;

  @observable isAddResourceModalOpen = false;
  @observable modalType = RESOURCE_TYPES.Service;

  @observable myResourcesModalVM = null;
  @observable memberOnlyFeatureModalVM = null;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @computed get currentTab() {
    return tabsState.demandsTab;
  }

  @action didMount = (props) => {
    console.log('DemandsPage.didMount, params', props.router.params);
    this.eid = props.router.params.eid;
    this.profile = props.profile;
    this.uid = props.profile?.id;
    this.getDemands();
  };

  @action didUpdate = (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile)) {
      this.profile = props.profile;
      this.uid = props.profile?.id;
    }
  };

  @action getDemands = async () => {
    const res = await Promise.all(
      Object.values(RESOURCE_TYPES).map(async (type) => {
        try {
          const resp = await DemandService.getList(type, this.eid);
          this[type] = resp;
          return resp;
        } catch (error) {
          if (error.response?.status === 401) { // when token is expired
            ErrorService.onCustomError(
              i18n.t('general_error_content_401'),
              null,
              () => this.props.router.navigate(0)
            );
          } else {
            ErrorService.onDefaultError(error);
          }
          return null;
        }
      })
    );
    this.demands = [].concat(...res);
    console.log(this.demands);
  };

  @action checkIfEventIsRecruiting = async () => {
    try {
      const res = await EventService.detail(this.eid);
      const isRecruiting = res.enableRecruit && (!!res.startAt && dayjs(res.startAt).isSameOrBefore(dayjs())) && (dayjs(res.endAt).isAfter(dayjs()));
      if (!isRecruiting) {
        ErrorService.onCustomError(i18n.t('demand_event_is_not_recruiting'));
      }
      return isRecruiting;
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
      return false;
    }
  };

  sendGAEvent(eventName, demandName) {
    switch (eventName) {
      case 'Event_Join_Click':
        window.gtag('event', 'Event_Join_Click', {
          Event_ID: this.eid,
          Requirement_Name: demandName
        });
        break;
      case 'Event_Join_Click_Interrupted':
        window.gtag('event', 'Event_Join_Click_Interrupted', {
          Event_ID: this.eid,
          Requirement_Name: demandName
        });
        break;
      default:
    }
  }

  onClickRegister = async (did, type, demandName) => {
    const isRecruiting = await this.checkIfEventIsRecruiting();
    if (!isRecruiting) {
      return;
    }

    const blockReason = getBlockReason(this.profile);

    if (!blockReason) {
      this.sendGAEvent('Event_Join_Click', demandName);
      this.myResourcesModalVM = new MyResourcesModalVM({
        eid: this.eid,
        did,
        uid: this.uid,
        type,
        onAddResource: this.openAddResourceModal,
        router: this.props.router
      });
      return;
    }

    if (this.memberOnlyFeatureModalVM) {
      this.memberOnlyFeatureModalVM.toggleModal();
    } else {
      this.memberOnlyFeatureModalVM = new MemberOnlyFeatureModalVM({ blockReason, router: this.props.router });
    }
  };

  @action onChangeResourceSelected = (selected) => {
    this.resourceSelected = selected;
  };

  @action toggleAddResourceModal = () => {
    this.isAddResourceModalOpen = !this.isAddResourceModalOpen;
  };

  // for Add Resource Modal
  @action onModalChange = (type) => {
    this.modalType = type;
  };

  @action onCloseMyResourcesModal = () => {
    this.myResourcesModalVM.closeModal();
    setTimeout(() => {
      this.myResourcesModalVM = null;
    }, 300);
  };

  @action openAddResourceModal = () => {
    this.isAddResourceModalOpen = true;
    this.onCloseMyResourcesModal();
  };

  @action switchTab = (value) => {
    tabsState.updateDemandsTab(value);
  };

  // ///////////////////////////////////////////////

  toDemand = (id) => {
    const { router } = this.props;
    router.navigate(`./${id}`);
  };

  toFormPage = () => {
    const { navigate } = this.props.router;
    navigate('/user/my-resource/form', { state: { type: this.modalType } });
  };
}

export default DemandsPageViewModel;

