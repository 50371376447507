import IS from 'src/utils/is';
import User from './User';
import Product from './Product';
import Invoice from './Invoice';

export default class Order {
  constructor(params) {
    const {
      id,
      type, // day | point | service
      platform, // ecpay
      transactionId,
      storeId,
      paymentType,
      amount,
      refund,
      tradeAt,
      remark,
      product,
      user,
      status,
      resultCode,
      resultMsg,
      createdAt,
      updatedAt,
      invoice
    } = params ?? {};

    this.id = id;
    this.type = type;
    this.platform = platform;
    this.transactionId = transactionId;
    this.storeId = storeId;
    this.paymentType = paymentType;
    this.amount = amount;
    this.refund = refund;
    this.tradeAt = tradeAt;
    this.remark = remark;
    this.product = Product.fromRes(product);
    this.user = User.fromRes(user);
    this.status = status;
    this.resultCode = resultCode;
    this.resultMsg = resultMsg;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.invoice = Invoice.fromRes(invoice);
  }

  static fromRes(data) {
    return new Order(data);
  }

  get isSuccess() {
    return this.status === '1';
  }

  get isFailed() {
    return !!this.status && this.status !== '1';
  }

  get priceForDisplay() {
    return IS.numeric(this.amount) ? `TWD ${Math.round(this.amount)}` : this.amount;
  }
}



