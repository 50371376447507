/* eslint-disable */
const OrganizationTypes = {
  "zh-TW": {
    "1": "企業",
    "2": "財團法人",
    "3": "社會團體",
    "4": "職業團體",
    "5": "政治團體",
    "6": "政府機關",
    "7": "學術機構"
  },
  "en-US": {
    "1": "企業",
    "2": "財團法人",
    "3": "社會團體",
    "4": "職業團體",
    "5": "政治團體",
    "6": "政府機關",
    "7": "學術機構"
  }
}

export const OrganizationTypesOrder = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7
}

export default OrganizationTypes
