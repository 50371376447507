import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { REGISTER_STEPS } from 'src/constants';
import Button from 'src/components/Button';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import styles from '../../styles.module.scss';

@observer
class ValidatePhone extends React.Component {
  ref = {
    1: React.createRef(),
    2: React.createRef(),
    3: React.createRef(),
    4: React.createRef(),
    5: React.createRef(),
    6: React.createRef()
  };

  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <div className={styles.validateContainer}>
        <h2 className={styles.title}>
          {t('register_validate_personal_step3_title')}
        </h2>

        <h3 className={styles.subtitle}>
          <span>{t('register_validate_personal_step3_subtitle1')}</span>
          <span className={styles.purple}>{` ${this.vm.phone} `}</span>
          <span>{t('register_validate_personal_step3_subtitle2')}</span>
        </h3>

        <div style={{ display: 'flex', alignSelf: 'center' }}>
          {Object.entries(this.vm.validationCode).map((item) => {
            const digit = item[0];
            const value = item[1];
            return (
              <input
                key={digit}
                className={clsx(styles.phoneInput, value.length !== 0 && styles.valid)}
                ref={this.ref[digit]}
                onKeyUp={(e) => {
                  if (e.key === 'Backspace' && !value && digit !== '1') {
                    const prev = parseInt(digit, 10) - 1;
                    this.ref[prev].current.focus();
                  }
                  if (value && digit !== '6') {
                    const next = parseInt(digit, 10) + 1;
                    this.ref[next].current.focus();
                  }
                }}
                onChange={(e) => this.vm.onCodeChange(e.target.value, item[0])}
                value={value}
                type="number"
              />
            );
          })}
        </div>

        <div style={{ flexGrow: 1 }} />

        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.onValidateCode}
            style={{ width: '100%', maxWidth: 335, alignSelf: 'center' }}
            disabled={Object.values(this.vm.validationCode).some((v) => !v)}
            loading={this.vm.isLoading}
          >
            {t('register_validate_personal_step3_nextButton')}
          </Button>

          <div className={styles.bottomText}>
            <div className={styles.validatePhoneBottomText}>
              <span>{`${t('register_validate_personal_step3_notReceive')} `}</span>

              <div className={styles.flex}>
                <div
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={this.vm.onSendAuthPhone}
                  className={this.vm.timerCountdown !== null ? styles.disable : styles.clickable}
                >
                  {t('register_validate_personal_step3_resend')}
                </div>
                <span style={{ margin: '0 5px' }}>or</span>
                <div
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => this.vm.onChangeStep(REGISTER_STEPS.ValidateEnterPhoneNumber)}
                  className={this.vm.timerCountdown !== null ? styles.disable : styles.clickable}
                >
                  {t('register_validate_personal_step3_modifyPhone')}
                </div>

                {this.vm.timerCountdown !== null && (
                  <span className={styles.timer}>
                    (
                    {this.vm.timerCountdown}
                    )
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ValidatePhone.propTypes = {
  vm: PropTypes.object
};

ValidatePhone.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(ValidatePhone)), false);
