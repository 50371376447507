import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import scrollDetector from 'src/stores/scrollDetector';
import Spin from 'antd/es/spin';
import MainLayout from 'src/components/MainLayout';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import EventCard from 'src/components/EventCard/index';
import Tabs from 'src/components/Tabs';
import EventFilter from 'src/components/EventFilter';
import Pagination from 'antd/es/pagination';

import EventPageViewModel, { TABS, SORT } from './vm';

import styles from './styles.module.scss';

@observer
class EventPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new EventPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderTopArea() {
    return (
      <>
        <div className={styles.topArea}>
          <div className={styles.topContainer}>
            <div className={styles.title}>
              <span>{t('events_title1')}</span>
              <span className={styles.green}>{t('events_title2')}</span>
              <span>{t('events_title3')}</span>
            </div>
          </div>
        </div>
        {this.vm.filterVM && (
          <EventFilter
            isMobile={this.vm.isMobile}
            vm={this.vm.filterVM}
            className={clsx(styles.filterArea, !scrollDetector.isScrollingDown && styles.lower)}
            containerClassName={styles.container}
            placeholder={t('search')}
            color="white"
            sortMenuItems={[
              {
                key: SORT.Popularity,
                label: (
                  <div
                    onClick={() => this.vm.handleSort(SORT.Popularity)}
                  >
                    {t('sort_popular')}
                  </div>
                )
              },
              {
                key: SORT.Id,
                label: (
                  <div
                    onClick={() => this.vm.handleSort(SORT.Id)}
                  >
                    {t('sort_new')}
                  </div>
                )
              }
            ]}
          />
        )}
        <div className={styles.fakeFilterBottomBar} />
      </>
    );
  }

  renderEventList() {
    return (
      <div className={styles.list}>
        {this.vm.list?.map((item) => (
          <EventCard
            className={styles.card}
            item={item}
            key={item.id}
            onClick={() => this.vm.toDetail(item.id)}
            colorMode="light"
          />
        ))}
      </div>
    );
  }

  renderNoContentHint() {
    return (
      <div className={styles.emptyContainer}>
        {
        this.vm.isFetching
          ? <Spin />
          : (
            <div className={styles.emptyMessage}>
              <div className={styles.title}>
                {t('empty_message_title')}
              </div>
              <div className={styles.text}>
                {(this.vm.filterVM?.hasAppliedFilter || this.vm.filterVM?.keyword)
                  ? t('filter_no_result')
                  : t('events_empty')}
              </div>
            </div>
          )
        }
      </div>
    );
  }

  render() {
    return (
      <div className={clsx(styles.eventPage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={3}
        >
          {this.renderTopArea()}
          <div className={styles.mainContainer}>
            <Tabs
              colorMode={2}
              className={clsx(styles.eventTabs, styles.tabLabelSticky, scrollDetector.isScrollingDown && styles.tabLabelStickyUp)}
              items={[
                {
                  value: TABS.Ongoing,
                  label: t('events_ongoing'),
                  content: this.vm.list?.length ? this.renderEventList() : this.renderNoContentHint()
                },
                {
                  value: TABS.Incoming,
                  label: t('events_incoming'),
                  content: this.vm.list?.length ? this.renderEventList() : this.renderNoContentHint()
                },
                {
                  value: TABS.Past,
                  label: t('events_past'),
                  content: this.vm.list?.length ? this.renderEventList() : this.renderNoContentHint()
                }
              ]}
              onSwitchTab={this.vm.switchTab}
              currentValue={this.vm.currentTab}
            />

            {!!this.vm.list?.length && (
              <div className={styles.center}>
                <Pagination
                  defaultCurrent={1}
                  pageSize={this.vm.limit}
                  current={this.vm.page}
                  total={this.vm.count}
                  onChange={this.vm.onPagingChange}
                />
              </div>
            )}
          </div>

        </MainLayout>
      </div>
    );
  }
}

EventPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(EventPage)), false);
