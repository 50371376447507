import { request, getHost, getHeader } from './utils';

export const bannerList = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/banner/list`,
    headers: getHeader()
  };
  return request(options);
};
