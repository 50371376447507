import FundsTypes from 'src/constants/gens/FundsTypes.gen';
import Regions from 'src/constants/gens/Regions.gen';
import SDGs from 'src/constants/gens/SDGs.gen';
import store from 'src/stores/constants';

export default class Funds {
  constructor(params) {
    const {
      quota,
      type,
      links,
      sdgs,
      regions,
      photos
    } = params ?? {};

    this.quota = quota ?? '';
    this.photos = photos ?? [];
    this.links = links ?? [];
    this.sdgs = sdgs ?? [];
    this.regions = regions ?? [];
    this.type = type;

    this.fundsTypes = FundsTypes[store.language];
    this.sdgsObj = SDGs[store.language];
    this.regionsObj = Regions[store.language];
  }

  get convertedSdgs() {
    const Obj = this.sdgs.reduce((acc, val) => {
      const [key, value] = val.split('-');
      if (acc[key]) {
        acc[key].push(val);
      } else {
        acc[key] = [val];
      }
      return acc;
    }, {});

    const list = [];
    Object.entries(Obj).forEach((el) => {
      if (Object.keys(SDGs[store.language][el[0]].childs).length === el[1].length) {
        return list.push(el[0]);
      }
      return list.push(...el[1]);
    });

    return list;
  }

  get convertedRegions() {
    return store.getCombinedRegionsValuesArr(this.regions);
  }

  get convertedType() {
    return FundsTypes[store.language][this.type] ?? this.type;
  }

  get displayQuota() {
    return Number(this.quota).toLocaleString('en-US');
  }

  static fromRes(data) {
    return new Funds(data);
  }
}




