import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './styles.scss';

@observer
class Bubble extends React.Component {

  // ref = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { label, onClick, isVisible, className, count } = this.props;
    return (
      <div
        className={clsx(className, 'bubble-option', !isVisible && 'hide')}
        onClick={isVisible ? onClick : null}
      >
        {label}
        {
          count && (
            <span style={{ fontSize: 10 }}>{`(${count})`}</span>
          )
        }
      </div>
    );
  }
}

export default Bubble;

Bubble.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  count: PropTypes.number
};

Bubble.defaultProps = {
  label: '',
  onClick: () => {},
  isVisible: true,
  className: '',
  count: null
};
