import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'src/components/Button';
import blueTick from 'src/assets/blueTick.png';
import blackGradient from 'src/assets/black-gradient.png';
import iconSpace from 'src/assets/resourceSpace.svg';
import iconSpaceFill from 'src/assets/resourceSpaceFill.svg';
import iconFunds from 'src/assets/resourceMoney.svg';
import iconFundsFill from 'src/assets/resourceMoneyFill.svg';
import iconService from 'src/assets/resourceService.svg';
import iconServiceFill from 'src/assets/resourceServiceFill.svg';
import styles from './styles.module.scss';

import EventCardViewModel from './vm';

@observer
class EventCard extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new EventCardViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderTopLeftCorner() {
    const { item, colorMode } = this.props;
    const fontColor = colorMode === 'dark' ? '#fff' : '';
    if (item.type === 'sample') {
      return (
        <div
          className={clsx(styles.sampleLabel, colorMode !== 'dark' ? styles.purple : '')}
          style={{ color: fontColor }}
        >
          {t('event_sample')}
        </div>
      );
    }

    if (!item.startAt) {
      return (
        <div
          className={clsx(styles.sampleLabel, colorMode !== 'dark' ? styles.purple : '')}
          style={{ color: fontColor }}
        >
          {t('events_incoming')}
        </div>
      );
    }

    return (
      <div
        className={clsx(styles.time, colorMode !== 'dark' ? styles.purple : '')}
        style={{ color: fontColor }}
      >
        {this.vm.daysFromNow < 0
          ? (
            <div className={styles.eventEnd}>
              {t('event_end')}
            </div>
          )
          : (
            <>
              <div className={styles.countdown}>
                {t('countdown')}
              </div>
              <div className={styles.number}>
                {this.vm.daysFromNow}
              </div>
              <div className={styles.day}>
                {t('day')}
              </div>
            </>
          )}
      </div>
    );
  }

  render() {
    const { item, onClick, colorMode, style, showCornerButton, cornerButtonProps } = this.props;
    const fontColor = colorMode === 'dark' ? '#fff' : '';

    if (!item) return null;

    return (
      <div
        className={clsx(styles.eventCard, onClick ? styles.clickable : '', this.props.className)}
        onClick={onClick}
        style={style}
      >
        <div className={styles.flexRow}>
          <div className={styles.flexCol}>
            {this.renderTopLeftCorner()}

            <div className={styles.flexCol2}>
              {this.vm.hasSpaceDemand && (
                <div className={styles.flexCol2__item}>
                  <img
                    src={colorMode === 'dark' ? iconSpace : iconSpaceFill}
                    alt="resource space"
                    className={styles.image6}
                  />
                </div>
              )}
              {this.vm.hasFundsDemand && (
                <div className={styles.flexCol2__item}>
                  <img
                    src={colorMode === 'dark' ? iconFunds : iconFundsFill}
                    alt="resource money"
                    className={styles.image6}
                  />
                </div>
              )}
              {this.vm.hasServiceDemand && (
                <div className={styles.flexCol2__item}>
                  <img
                    src={colorMode === 'dark' ? iconService : iconServiceFill}
                    alt="resource service"
                    className={styles.image6}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.flexRow__spacer} />

          <div className={styles.box3}>
            <div className={styles.content_box}>
              <img src={item.banner} alt="alt text" className={styles.img} />
              <div style={{ '--src': `url(${blackGradient})` }} className={styles.mask} />

              <div className={styles.flexCol3}>
                <div className={styles.title}>
                  {item.name}
                </div>

                <div className={clsx(styles.infoList, styles.cities)}>
                  {this.vm.citiesStr}
                </div>

                {this.vm.hasServiceDemand && (
                  <div className={clsx(styles.infoList, styles.serviceTypesTags)}>
                    {this.vm.serviceTypesTags.map((tag, i) => (
                      <span key={tag} className={styles.info}>
                        {tag}
                        {i < this.vm.serviceTypesTags.length - 1 && (
                        <span className={clsx(styles.info, styles.x)}>
                          x
                        </span>
                        )}
                      </span>
                    ))}
                  </div>
                )}
              </div>

              {item.blueTickAt && (
                <img
                  src={blueTick}
                  alt="blue tick"
                  className={styles.check}
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <div
            className={styles.hashtags}
            style={{ color: fontColor }}
          >
            {item.keywordsForDisplay?.map((word, i) => (
              <span
                className={clsx(styles.hashtag)}
                key={word}
              >
                {`#${word}`}
              </span>
            ))}
          </div>

          {showCornerButton && (
            <Button
              className={styles.cornerButton}
              {...cornerButtonProps}
            >
              {cornerButtonProps.text}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default EventCard;

EventCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  onClick: PropTypes.func,
  keywordsForDisplay: PropTypes.array,
  colorMode: PropTypes.string, // light | dark
  style: PropTypes.object,
  showCornerButton: PropTypes.bool,
  cornerButtonProps: PropTypes.object
};

EventCard.defaultProps = {
  className: null,
  item: null,
  onClick: null,
  keywordsForDisplay: [],
  colorMode: 'light',
  style: null,
  showCornerButton: false,
  cornerButtonProps: {}
};
