import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';

import styles from '../styles.module.scss';

@observer
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.vm = this.props.vm;
  }

  render() {
    return (
      <>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            { t('resetPassword_title') }
          </h2>
        </div>

        <div className={styles.inputContainer}>
          <TextInput
            placeholder={t('set_password_placeholder')}
            type="password"
            onChange={this.vm.onPasswordChange}
            onBlur={this.vm.onCheckPassword}
            value={this.vm.password}
            error={!!this.vm.passwordError}
            sx={{
              marginTop: 1
            }}
          />
          <div className={styles.errorMessage}>
            {this.vm.passwordError ? `＊ ${this.vm.passwordError}` : ''}
          </div>
          <TextInput
            placeholder={t('resetPassword_repeat_placeholder')}
            type="password"
            onChange={this.vm.onPasswordConfirmChange}
            onBlur={this.vm.onCheckPasswordConfirm}
            value={this.vm.passwordConfirm}
            error={!!this.vm.passwordConfirmError}
          />
          <div className={styles.errorMessage}>
            {this.vm.passwordConfirmError ? `＊ ${this.vm.passwordConfirmError}` : ''}
          </div>
        </div>

        <div style={{ flexGrow: 1 }} />

        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.resetPassword}
            className={styles.nextButton}
          >
            {t('resetPassword_confirm_button')}
          </Button>
        </div>

      </>
    );
  }
}

ResetPassword.propTypes = {
  vm: PropTypes.object
};

ResetPassword.defaultProps = {
  vm: {}
};

export default (withTranslation()(ResetPassword));
