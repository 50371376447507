import Trade from './Trade';
import User from './User';

export default class Purchase {
  constructor(params) {
    const {
      id,
      source, // [manual, trade, consume]
      type, // [point, service, activeEvent]
      count, // 產品數量 (根據 type 決定意義，例 point 的 count 指點數)
      price, // 產品價格 (可以是 "NT" 也可以是 "點數" 價值，視情況而定)
      remark, // 備註 (與 Trade.remark 相同，因手動建立無 Trade，故有此欄位)
      trade,
      user,
      createdAt,
      updatedAt
    } = params ?? {};

    this.id = id;
    this.source = source;
    this.type = type;
    this.count = count;
    this.price = price;
    this.remark = remark;
    this.trade = Trade.fromRes(trade);
    this.user = User.fromRes(user);
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  static fromRes(data) {
    return new Purchase(data);
  }
}



