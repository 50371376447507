import {
  makeObservable, computed, observable, action, runInAction, toJS
} from 'mobx';
import { t } from 'i18next';
import { LIST_MODEL_TYPE, EVENT_STATUS, EVENT_TYPE, TABLE_LIMIT, TABLE_LIMIT_MOBILE } from 'src/constants';
import ErrorService from 'src/services/errors';
import AnchoredList from 'src/services/anchoredList';
import EventService from 'src/services/event';
import FilterViewModel from 'src/components/EventFilter/vm';

class CreateEventListPageViewModel {
  @observable userId = null;
  @observable profile = null;
  @observable type = EVENT_TYPE.Draft;

  @observable samples = [];

  @observable AnchoredLists = {
    [EVENT_TYPE.Draft]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Event,
      path: 'v1/user/{uid}/event/created'
    }),

    [EVENT_TYPE.Submitted]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Event,
      path: 'v1/user/{uid}/event/created'
    })
  };

  @observable isCreatedEventsModalOpen = false;
  @observable filter = {};
  @observable sort = 'id';
  @observable order = 'desc';
  @observable resourceTypes = [];

  @observable filterVM;

  @observable isAwait = false; // for copying
  @observable isSamplesFetching = false;

  // pagination
  @observable count = 0;
  @observable page = 1;
  @observable limit = TABLE_LIMIT;

  constructor(props) {
    makeObservable(this);
    this.props = props;
    this.isMobile = props?.context?.state?.isMobile;
    runInAction(() => {
      this.limit = this.isMobile ? TABLE_LIMIT : TABLE_LIMIT_MOBILE;
    });
  }

  @computed get lists() {
    return {
      [EVENT_TYPE.Draft]: this.AnchoredLists[EVENT_TYPE.Draft]?.list ?? [],

      [EVENT_TYPE.Submitted]: this.AnchoredLists[EVENT_TYPE.Submitted]?.list ?? [],

      [EVENT_TYPE.Sample]: this.samples
    };
  }

  @computed get params() {
    return {
      sort: this.sort,
      order: this.order
    };
  }

  @action didMount = async (props) => {
    console.log('CreateEventList.didMount, params', props.router.params);
    console.log(props);
    const { params, navigate } = props.router;
    this.type = params.type;
    this.props = props;
    this.profile = props.profile;
    this.userId = props.profile?.id;

    if (!Object.values(EVENT_TYPE).includes(this.type)) {
      navigate('/create-event-overview');
    }

    if (!this.profile.isEventCreator) {
      ErrorService.onCustomError(
        t('error_not_event_creator'),
        null,
        () => navigate('/')
      );
      return;
    }

    this.filterVM = new FilterViewModel({
      getList: this.getList,
      type: this.type
    });
    if (this.userId) {
      this.setAnchoredListsPaths();
      this.getList();
    }

    if (this.type === EVENT_TYPE.Sample) {
      navigate(`/create-event-overview/sample?page=${this.page}`, { replace: true });
    }
  };

  @action setAnchoredListsPaths = () => {
    Object.values(this.AnchoredLists).forEach((l) => l.setPath(`v1/user/${this.userId}/event/created`));
  };

  @computed get getList() {
    switch (this.type) {
      case EVENT_TYPE.Draft:
        return this.getDrafts;
      case EVENT_TYPE.Submitted:
        return this.getSubmitteds;
      case EVENT_TYPE.Sample:
        return this.getSamples;
      default:
        return () => {};
    }
  }

  getDrafts = async () => {
    const params = {
      limit: this.isMobile ? 10 : 15,
      ...this.filterVM.listParams
    };
    const body = {
      statuses: [
        EVENT_STATUS.Draft, EVENT_STATUS.Failed
      ],
      ...this.filterVM.listFilter
    };

    await this.AnchoredLists[EVENT_TYPE.Draft].getList(body, params);
  };

  getSubmitteds = async () => {
    const params = {
      order: 'desc',
      limit: this.isMobile ? 10 : 15,
      ...this.filterVM.listParams
    };
    const body = {
      statuses: [
        EVENT_STATUS.Active,
        EVENT_STATUS.Inactive
      ],
      ...this.filterVM.listFilter
    };

    await this.AnchoredLists[EVENT_TYPE.Submitted].getList(body, params);
  };

  @action getSamples = async () => {
    const params = {
      limit: this.limit,
      page: this.page,
      ...this.filterVM.listParams
    };

    const filter = {
      types: ['sample'],
      ...this.filterVM.listFilter
    };

    try {
      this.isSamplesFetching = true;
      const res = await EventService.getList(params, filter);
      runInAction(() => {
        const { list, count, page } = res;
        this.samples = list ?? [];
        this.count = count;
        this.page = page;
      });
    } catch (error) {
      if (error.response?.status === 401) { // when token is expired
        ErrorService.onCustomError(
          t('general_error_content_401'),
          null,
          () => this.props.router.navigate(0)
        );
      } else {
        ErrorService.onDefaultError(error);
      }
    } finally {
      runInAction(() => {
        this.isSamplesFetching = false;
      });
    }
  };

  @action onPagingChange = async (ev) => {
    const { navigate } = this.props.router;
    this.page = ev;
    console.log(this.page);
    navigate(`/create-event-overview/sample?page=${this.page}`, { replace: true });
    await this.getSamples();
    window.scrollTo({
      top: 120,
      behavior: 'smooth'
    });
  };

  onClickDraft = (id) => {
    this.toCreateEvent(id);
    window.gtag('event', 'Create_Event_Continue_Draft', { Event_ID: id });
  };

  onClickSubmitted = async (id) => {
    const newId = await this.onCopy(id);
    if (newId) {
      window.gtag(
        'event',
        'Create_Event_Copy_Existing',
        {
          Event_ID: id,
          Copied_Event_ID: newId
        }
      );
    }
  };

  onViewSample = async (id) => {
    const { navigate } = this.props.router;
    navigate(`/events/${id}`, { state: { isFromCreateEvent: true } });
  };

  onCopySample = async (id) => {
    const newId = await this.onCopy(id);
    if (newId) {
      window.gtag(
        'event',
        'Create_Event_Copy_Sample',
        {
          Event_ID: id,
          Quoted_Event_ID: newId
        }
      );
    }
  };

  @action onCopy = async (id) => {
    this.isAwait = true;
    console.log(id);
    let newId = null;
    try {
      const event = await EventService.copy(id);
      this.toCreateEvent(event.id);
      newId = event.id;
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }

    runInAction(() => {
      this.isAwait = false;
    });

    return newId;
  };

  @computed get isListFetching() {
    switch (this.type) {
      case EVENT_TYPE.Draft:
      case EVENT_TYPE.Submitted:
        return this.AnchoredLists[this.type].isAwait;
      case EVENT_TYPE.Sample:
        return this.isSamplesFetching;
      default:
    }

    return false;
  }

  // //////////////////////////////////

  toCreateEvent = (id = null) => {
    const { navigate } = this.props.router;
    if (id) {
      navigate(`/create-event/${id}`);
    } else {
      navigate('/create-event');
    }
  };

}

export default CreateEventListPageViewModel;
