import { request, getHost, getHeader } from './utils';

export const create = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/belong/create`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const deleteBelong = (belongId) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/v1/belong/${belongId}`,
    headers: getHeader()
  };
  return request(options);
};
