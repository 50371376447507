import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import Modal from 'antd/es/modal';

import i18n from 'src/i18n';
import Drawer from 'src/components/Drawer';
import Button from 'src/components/Button';
import errorSvg from 'src/assets/error.svg';

import styles from './styles.module.scss';

function ErrorModal(props) {

  const { vm, isMobile } = props;

  const getTitle = () => {
    if (vm.title.includes('\\n')) {
      return (
        <>
          {vm.title.split('\\n')[0]}
          <br />
          {vm.title.split('\\n')[1]}
        </>
      );
    }
    return vm.title;
  };

  const getContent = () => {
    if (!vm.content) return null;

    const arr = vm.content.split('\\');
    return arr.map((str) => {
      if (str.slice(0, 1) === '#') {
        return <span key={str} className={styles.primary}>{str.slice(1)}</span>;
      }

      return <span key={str}>{str}</span>;
    });
  };

  const content = (
    <div className={styles.modal}>
      <img
        className={clsx(styles.marginBottom, styles.illustration)}
        src={errorSvg}
        alt="man looking at phone"
      />
      <h2 className={clsx(styles.title, styles.marginBottom)}>
        {getTitle()}
      </h2>
      {vm.content && (
        <div className={clsx(styles.subtitle, styles.marginBottom)}>
          {getContent()}
        </div>
      )}
      <div style={{ flexGrow: 1 }} />
      <Button
        onClick={vm.close}
        className={styles.button}
        {...vm.buttonProps}
      >
        {vm.buttonText}
      </Button>
    </div>
  );

  const ps = {
    width: 300,
    closable: false,
    open: vm.isShow,
    footer: null,
    maskClosable: true,
    centered: true,
    zIndex: 1500
  };

  return isMobile ? (
    <Drawer
      placement="bottom"
      onClose={vm.close}
      {...ps}
    >
      {content}
    </Drawer>
  ) : (
    <Modal
      onCancel={vm.close}
      {...ps}
    >
      {content}
    </Modal>
  );
}


ErrorModal.propTypes = {
  vm: PropTypes.object.isRequired,
  isMobile: PropTypes.bool
};

ErrorModal.defaultProps = {
  isMobile: false
};

export default observer(ErrorModal);
