// register for an event
import { request, getHost, getHeader } from './utils';

export const create = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/register/create`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const getList = (params, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/register/list`,
    headers: getHeader(),
    params,
    data
  };
  return request(options);
};

export const update = (id, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/v1/register/${id}`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const detail = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/register/${id}/detail`,
    headers: getHeader()
  };
  return request(options);
};

export const exportList = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/register/list?demand=${id}`,
    headers: getHeader()
  };
  return request(options);
};

export const withdraw = (id) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/v1/register/${id}`,
    headers: getHeader()
  };
  return request(options);
};
