import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { t } from 'i18next';

import fakePng from 'src/assets/fakePng.png';
import styles from './styles.module.scss';

@observer
class DraftCard extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { item, onClick, className } = this.props;

    if (!item) return null;

    return (
      <div
        className={clsx(styles.draftCard, onClick ? styles.clickable : '', className)}
        onClick={onClick}
      >
        <div
          className={styles.pic}
          style={{ backgroundImage: `url(${item.banner ? item.banner : fakePng})` }}
        />
        <div
          key={item.id}
          className={styles.textContent}
        >
          <div className={styles.title}>
            {item.name || t('untitled_event')}
          </div>

          <div className={styles.text}>
            {`${t('draft_card_created_at')}${dayjs(item.createdAt).format('YYYY.MM.DD')}`}
          </div>
        </div>
      </div>
    );
  }
}

DraftCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  onClick: PropTypes.func
};

DraftCard.defaultProps = {
  className: null,
  item: null,
  onClick: null
};

export default DraftCard;
