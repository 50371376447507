import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Upload from 'antd/es/upload';
import Tooltip from 'antd/es/tooltip';
import { ORG_REVIEW_TYPES, USER_TYPES } from 'src/constants';
import Icon from 'src/components/Icon';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Button from 'src/components/Button';
import Tabs from 'src/components/Tabs';
import GeneralModal from 'src/components/Modals/GeneralModal';
import Loading from 'src/components/Loading';
import fakeAvatar from 'src/assets/fakeAvatar.png';
import iconNotice from 'src/assets/iconNotice.svg';
import iconCamera from 'src/assets/iconCamera.svg';
import iconEdit from 'src/assets/iconEdit.svg';

import ProfileEditPageViewModel, { TABS } from './vm';

import Organization from './components/Organization';
import Personal from './components/Personal';

import styles from './styles.module.scss';

@observer
class EditProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ProfileEditPageViewModel(props);
    console.log(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  componentWillUnmount() {
    this.vm.disposeReactions();
  }

  renderTabs() {
    return (
      <div style={{ marginBottom: 20, width: '100%' }}>
        <Tabs
          colorMode={2}
          items={[
            {
              value: TABS.Basics,
              label: (
                <div className={clsx((this.vm.showError && this.vm.checkIfBasicError()) ? styles.error : '')}>
                  {t('profile_tab_basics')}
                </div>
              ),
              content: this.renderTabContent()
            },
            {
              value: TABS.Social,
              label: (
                <div className={clsx((this.vm.showError && this.vm.isSocialsError) ? styles.error : '')}>
                  {t('profile_tab_social')}
                </div>
              ),
              content: this.renderTabContent()
            },
            {
              value: TABS.Private,
              label: (
                <div className={clsx(styles.flexRow, (this.vm.showError && this.vm.isPrivateError) ? styles.error : '', styles.privateTabText)}>
                  {t('profile_tab_private')}
                  <Tooltip
                    title={t('profile_private_notice')}
                    color="#0C0E17"
                    overlayInnerStyle={{
                      borderRadius: 20,
                      fontWeight: 700,
                      fontSize: 12,
                      padding: 10
                    }}
                  >
                    <Icon
                      svg
                      className={styles.iconNotice}
                    >
                      {iconNotice}
                    </Icon>
                  </Tooltip>
                </div>
              ),
              content: this.renderTabContent()
            },
            {
              value: TABS.Belong,
              label: (
                <div>
                  {t('profile_tab_belong')}
                </div>
              ),
              content: this.renderTabContent()
            }
          ]}
          onSwitchTab={this.vm.switchTab}
          currentValue={this.vm.currentTab}
          centered
          className={styles.tabsWrapper}
          tabClassName={styles.tabText}
        />
      </div>
    );
  }

  renderTabContent() {
    if (!this.vm.profile) {
      return null;
    }

    if (this.vm.identity === USER_TYPES.Personal) {
      return (
        <Personal
          vm={this.vm}
          isMobile={this.props.context.state.isMobile}
        />
      );
    }
    return (
      <Organization
        vm={this.vm}
        isMobile={this.props.context.state.isMobile}
      />
    );
  }

  renderContent() {
    return (
      <>
        <div className={clsx(styles.avatarContainer)}>
          <div
            style={{ backgroundImage: `url(${this.vm.avatar ?? fakeAvatar})` }}
            className={styles.avatar}
          />
          <Upload
            className={styles.uploadButton}
            customRequest={this.vm.onUploadAvatar}
            accept="image/jpg,image/jpeg,image/png,image/bmp"
            showUploadList={false}
          >
            <Icon
              size={24}
              svg
              className={styles.iconCamera}
              onClick={() => {}}
            >
              {iconCamera}
            </Icon>
          </Upload>
        </div>
        <span className={clsx(styles.avatarGuideline)}>{t('profile_image_upload_guideline')}</span>
        {this.renderTabs()}
      </>
    );
  }

  render() {
    return (
      <div className={clsx(styles.page, 'page')}>
        <MainLayout
          {...this.props}
          hideHeader
        >
          <Breadcrumb
            routes={[
              { label: t('my_account'), path: `/user/profile/${this.vm.id ?? ''}` },
              { label: t('edit'), path: '' }
            ]}
            right={(
              <div
                onClick={this.vm.checkRequireAndSave}
                style={{ cursor: 'pointer' }}
                className={styles.saveArea}
              >
                <Icon
                  size={24}
                  svg
                >
                  {iconEdit}
                </Icon>
                {t('save')}
              </div>
            )}
            onBackPress={this.vm.onBackPress}
            shouldBlockRouting={this.vm.shouldSave}
            onClickRoute={this.vm.onClickRoute}
          />
          <div className={styles.mainContainer}>
            {this.vm.profile && this.renderContent()}
          </div>

          {(this.vm.identity === USER_TYPES.Organization && this.vm.status !== ORG_REVIEW_TYPES.Accepted) && (
            <Button
              onClick={this.vm.requestOrgAuth}
              className={styles.sendAuthButton}
            >
              {t('profile_org_auth_send_button')}
            </Button>
          )}

          {this.vm.generalModalViewModel && (
            <GeneralModal vm={this.vm.generalModalViewModel} isMobile={this.props.context.state.isMobile} />
          )}

          { this.vm.isAwait && <Loading /> }

        </MainLayout>
      </div>
    );
  }
}

EditProfilePage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(EditProfilePage)), true);
