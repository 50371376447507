import React from 'react';
import PropTypes from 'prop-types';

import ConfigProvider from 'antd/es/config-provider';
import AntButton from 'antd/es/button';
import rightArrow from 'src/assets/iconRightArrow.svg';
import leftArrow from 'src/assets/iconLeftArrow.svg';
import Icon from '../Icon';

import './styles.scss';

function Button(props) {
  const { children, withRightArrow, withLeftArrow, ...ps } = props;
  const invertStyle = {
    filter: 'invert(1)',
    opacity: 0.7
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#414EE1',
          fontSize: 16,
          colorPrimaryHover: '#303999'
        }
      }}
    >
      <AntButton
        type="primary"
        {...ps}
      >
        {withLeftArrow && (
          <Icon
            size={18}
            svg
            style={{
              height: 20,
              transform: 'translate(-6px, -3px)',
              ...props.type === 'default' && invertStyle
            }}
          >
            {leftArrow}
          </Icon>
        )}

        { children }

        {withRightArrow && (
          <Icon
            size={18}
            svg
            style={{
              height: 20,
              transform: 'translate(6px, -3px)',
              ...props.type === 'default' && invertStyle
            }}
          >
            {rightArrow}
          </Icon>
        )}
      </AntButton>
    </ConfigProvider>
  );
}

Button.propTypes = {
  children: PropTypes.any,
  withRightArrow: PropTypes.bool,
  withLeftArrow: PropTypes.bool,
  type: PropTypes.string
};

Button.defaultProps = {
  children: null,
  withRightArrow: false,
  withLeftArrow: false,
  type: 'primary'
};

export default Button;
