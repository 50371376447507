/* eslint-disable */
const Usages = {
  "zh-TW": {
    "1": "研討會議",
    "2": "講座論壇",
    "3": "大型展會",
    "4": "藝文演出",
    "5": "親子聚會",
    "6": "宴會派對",
    "7": "烹飪料理",
    "8": "運動舞蹈"
  },
  "en-US": {
    "1": "研討會議",
    "2": "講座論壇",
    "3": "大型展會",
    "4": "藝文演出",
    "5": "親子聚會",
    "6": "宴會派對",
    "7": "烹飪料理",
    "8": "運動舞蹈"
  }
}

export const UsagesOrder = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8
}

export default Usages
