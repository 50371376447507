import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { t } from 'i18next';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import iconClose from 'src/assets/iconCloseRed.svg';
import styles from './styles.module.scss';

function DeleteItemButton({ onClick, text, generalModalViewModel }) {
  const onClickButton = () => {
    if (generalModalViewModel) {
      generalModalViewModel.open({
        title: `${t('modal_remove_event_item_confirm_1')} ${text} ${t('modal_remove_event_item_confirm_2')}`,
        onConfirm: onClick,
        showCancelButton: true
      });
    } else {
      onClick();
    }
  };

  return (
    <Button
      type="text"
      onClick={onClickButton}
      size="small"
      className={styles.deleteItemButton}
    >
      <div className={clsx(styles.flexRowCenter, styles.borderlessBtnText)}>
        <Icon
          size={23}
          svg
        >
          {iconClose}
        </Icon>
        <span>
          {text}
        </span>
      </div>
    </Button>
  );
}

DeleteItemButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  generalModalViewModel: PropTypes.object
};

DeleteItemButton.defaultProps = {
  onClick: null,
  text: null,
  generalModalViewModel: null
};

export default DeleteItemButton;
