export default class Invoice {
  constructor(params) {
    const {
      type,
      customId, // 綠界自訂編號
      code,
      resultCode,
      resultMsg
    } = params ?? {};

    this.type = type;
    this.customId = customId;
    this.code = code;
    this.resultCode = resultCode;
    this.resultMsg = resultMsg;
  }

  static fromRes(data) {
    return new Invoice(data);
  }
}
