import API from 'src/apis';

export default class GoogleService {
  // upload file to google
  static async putGoogleStorage({ id, uid, url, mimetype, size, file }) {
    const res = await API.google.putGoogleStorage({
      url,
      mimetype,
      size,
      file
    });

    return {
      data: { id, uid, size, mimetype }
    };
  }

}
