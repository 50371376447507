import { makeObservable, observable, action, runInAction, computed } from 'mobx';

import UserService from 'src/services/user';
import ErrorService from 'src/services/errors';
import { RESOURCE_TYPES, LIST_MODEL_TYPE } from 'src/constants';
import AnchoredList from 'src/services/anchoredList';

class MyResourcesModalVM {
  @observable type = RESOURCE_TYPES.Service;
  @observable uid = null; // user id
  @observable eid = null; // event id
  @observable did = null; // demand id
  @observable resourceSelected = null;

  @observable isOpen = true;
  @observable isLoading = true;
  @observable hasResource = false;

  @observable router;

  @observable onAddResource = () => {};
  @observable onClose = () => {};

  @observable AnchoredList = new AnchoredList({
    modelType: LIST_MODEL_TYPE.Resource,
    path: 'v1/user/{uid}/resource/{type}/list',
    method: 'get'
  });

  dName = null; // for GA event

  constructor(props) {
    makeObservable(this);
    this.props = props;
    const { eid, did, uid, type, onAddResource, dName, router } = props;
    this.eid = eid;
    this.did = did || router.params.did;
    this.uid = uid;
    this.type = type;
    this.onAddResource = onAddResource;
    this.dName = dName;
    this.router = router;
  }

  @computed get myResources() {
    return this.AnchoredList.list;
  }

  @action didMount = async (props) => {
    this.onClose = props.onClose;

    await this.init();
  };

  @action init = async () => {
    this.isLoading = true;
    try {
      // check if the user has any resource
      const res = await UserService.getResourceList(this.uid, this.type, { limit: 1 });

      runInAction(() => {
        this.hasResource = res.list.length !== 0;
      });

    } catch (error) {
      ErrorService.onDefaultError(error);
      console.log('Error fetching list', error);
    }

    if (this.hasResource) {
      await this.getMyResources();
    } else {
      this.sendJoinInterruptedGAEvent();
    }

    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action getMyResources = async () => {
    this.AnchoredList.setPath(`v1/user/${this.uid}/resource/${this.type}/list`);
    const params = {
      limit: 10
    };

    await this.AnchoredList.getList(null, params);
  };

  @action onChangeSelected = (selected) => {
    this.resourceSelected = selected;
  };

  onConfirmSelect = () => {
    const { navigate } = this.router;
    if (!this.resourceSelected) return;
    navigate(`/sign-up-for-event/${this.eid}/${this.did}/${this.resourceSelected.id}`);
    this.closeModal();
  };

  onScrollEnd = async () => {
    if (!this.AnchoredList.anchor) return;
    await this.AnchoredList.getNext();
  };

  @action closeModal = () => {
    this.isOpen = false;
  };

  @action openModal = () => {
    this.isOpen = true;
  };

  sendJoinInterruptedGAEvent = async () => {
    window.gtag('event', 'Event_Join_Click_Interrupted', {
      Event_ID: this.eid,
      Requirement_Name: this.dName,
      Failed_Type: '資源未上架'
    });
  };
}

export default MyResourcesModalVM;
