import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import NotificationService from 'src/services/notification';
import ErrorService from 'src/services/errors';
import AnchoredList from 'src/services/anchoredList';
import { EVENT_STATUS, LIST_MODEL_TYPE } from 'src/constants';
import tabsState from 'src/stores/tabsState';


const ENavigateTarget = {
  Home: 'home',
  EditProfile: 'editProfile',
  MyEventList: 'myEventList',
  MyEventListDraftTab: 'myEventListDraftTab',
  EventDemandList: 'eventDemandList',
  EventDetail: 'eventDetail',
  RegistrationList: 'registrationList'
};

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('zh-tw', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1 min',
    mm: '%d mins',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
});


class NotificationListViewModel {
  @observable unreadCount = 0;
  @observable isAwait = false;

  @observable anchoredList = new AnchoredList({
    modelType: LIST_MODEL_TYPE.Notification,
    path: 'v1/notification/list',
    method: 'get'
  });

  constructor() {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    this.props = props;
  };

  @action init = async () => {
    await Promise.all([
      this.getList(),
      this.updateUnreadCount()
    ]);
  };

  @action getList = async () => {
    const params = {
      limit: 10
    };

    await this.anchoredList.getList(null, params);
  };

  updateUnreadCount = async () => {
    try {
      const res = await NotificationService.unreadCount();
      runInAction(() => {
        this.unreadCount = res.data.unread;
      });
      // console.log(this.unreadCount);
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  getTimeStr = (createdAt) => {
    return dayjs(createdAt).from(dayjs());
  };

  onScrollEnd = async () => {
    console.log('-----------on scroll end-----------');
    await this.anchoredList.getNext();
  };

  @computed
  get list() {
    // return this.anchoredList?.list ?? [];

    return this.anchoredList?.list.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.id === value.id
      ))) ?? [];
  }

  @computed
  get anchor() {
    return this.anchoredList?.anchor;
  }

  @computed
  get isFetching() {
    return this.anchoredList?.isAwait;
  }

  @computed
  get isEmpty() {
    return this.list.length === 0;
  }

  readAll = async () => {
    try {
      const res = await NotificationService.read('all');
      console.log(res);
      runInAction(() => {
        this.unreadCount = res.data.unread;
      });
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  toDetailPage = (item, context) => {
    const { navigate } = this.props.router;
    const { profile } = context.state;
    const { target } = item.payload;

    let targetLink = '';
    switch (target) {
      case ENavigateTarget.Home:
        targetLink = '/';
        break;
      case ENavigateTarget.EditProfile:
        targetLink = `/user/profile/${profile.id}`;
        break;
      case ENavigateTarget.MyEventList:
        targetLink = '/user/event-hosted';
        tabsState.updateEventHostedTab(EVENT_STATUS.Active);
        break;
      case ENavigateTarget.MyEventListDraftTab:
        targetLink = '/user/event-hosted';
        tabsState.updateEventHostedTab(EVENT_STATUS.Draft);
        break;
      case ENavigateTarget.EventDemandList:
        targetLink = `/user/event-hosted/${item.payload.event.customId}/demands`;
        break;
      case ENavigateTarget.EventDetail:
        targetLink = `/events/${item.payload.event.customId}`;
        break;
      default:
        targetLink = '/';
    }
    navigate(targetLink);
  };

}

export default NotificationListViewModel;

