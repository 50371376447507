import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import ConstantsStore from 'src/stores/constants';
import { RESOURCE_TYPES, EVENT_TIPS } from 'src/constants';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import TreeSelect from 'src/components/TreeSelect';
import withErrorInput from 'src/components/withErrorInput';
import StickyGroupContainer from 'src/pages/CreateEvent/CreateEventPage/components/StickyGroupContainer';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import styles from '../../styles.module.scss';
import Base from './Base';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);
const ErrorTreeSelect = withErrorInput(TreeSelect);

@observer
class Service extends Base {

  constructor(props) {
    super();
    this.props = props;
    this.index = props?.index;
    this.isNotFirstItem = this.index !== 0;
  }

  get type() {
    return RESOURCE_TYPES.Service;
  }

  // //////////

  render() {
    const {
      demandVM,
      index,
      isMobile,
      showPreview,
      tipHeight,
      titleAreaHeight
    } = this.props;

    const {
      demand
    } = demandVM;

    const isSticky = this.index === 0 && !showPreview;

    if (!demand) return null;

    return (
      <div
        key={`${this.type}_${index}`}
        className={styles.sectionWrapper}
      >
        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={tipHeight[EVENT_TIPS.DserviceName]}
            topOffset={titleAreaHeight}
          >
            <div className={styles.itemTitle}>
              {`/ ${t(`create_event_demand_${this.type}_title`)} 0${index + 1} /`}
            </div>

            <ErrorTextInput
              key="service_name"
              label={t(`demand_${this.type}_name`)}
              onChange={(e) => demandVM.onChange(e.target.value, 'name')}
              value={demand.name ?? ''}
              limit={10}
              required
              onBlur={demandVM.onSave}
            />

            {isMobile && (
              <Tips
                key="demand_service_name"
                fieldName={EVENT_TIPS.DserviceName}
                className={styles.demandNameTipContainerMobile}
              />
            )}

            <ErrorSelect
              key="service_type"
              placeholder={t(`demand_${this.type}_type_placeholder`)}
              onChange={(value) => demandVM.onChange(value, 'type')}
              options={ConstantsStore.serviceTypesSelectOptions}
              value={demand.type}
              required
              showSearch
              onSelect={demandVM.onSave}
            />

            <ErrorTextInput
              key="demand_count"
              label={t(`demand_${this.type}_count`)}
              onChange={(e) => demandVM.onChange(e.target.value, 'count')}
              value={demand.count ?? ''}
              required
              onBlur={demandVM.onSave}
              type="number"
              showError={!!demand.count}
              validValue={demand.isCountValid}
              errorMessage={t('error_message_positive_integer')}
            />
          </StickyGroupContainer>

          <Tips isHidden />
        </section>

        {this.renderDescriptionSection()}

        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>
            <ErrorSelect
              key="service_seniority"
              placeholder={t('demand_seniority')}
              onChange={(value) => demandVM.onChange(value, 'seniority')}
              options={ConstantsStore.seniorityOptions}
              value={demand.seniority}
              required
              onSelect={demandVM.onSave}
            />

            {!!demandVM?.treeSelectVM?.jobs && (
              <ErrorTreeSelect
                key="service_jobs"
                placeholder={t('demand_prefer_profession')}
                type="jobs"
                showSearch
                vm={demandVM.treeSelectVM.jobs}
                value={demandVM.treeSelectVM.jobs?.selectedItems}
                required
                onSelect={demandVM.onSave}
              />
            )}
          </div>
          <Tips isHidden />
        </section>

        {this.renderKeyItemDescriptionsSection()}

        {this.renderAwardsAndDescriptionSections()}

        {this.renderDatesSection()}

        {this.renderRegionsSection()}

        {this.renderRulesSection()}

        {this.renderDeleteButton()}

      </div>
    );
  }
}

export default Service;
