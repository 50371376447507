import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import fakeAvatar from 'src/assets/fakeAvatar.png';
import blueTick from 'src/assets/blueTick.png';
import styles from './styles.module.scss';

function Avatar(props) {
  const { onClick, url, size, isRound, showBlueTick, blueTickSize, className } = props;

  const sizeStyle = {
    width: size,
    height: size
  };

  return (
    <div
      className={clsx(styles.avatarContainer, onClick && styles.clickable, className)}
      onClick={onClick}
    >
      <div
        style={{ backgroundImage: `url(${url ?? fakeAvatar})`, ...sizeStyle }}
        className={clsx(styles.avatar, isRound ? '' : styles.notRound)}
      />
      {showBlueTick && (
        <img
          className={clsx(styles.bluetick)}
          src={blueTick}
          alt="bluetick"
          width={blueTickSize}
        />
      )}
    </div>
  );
}

Avatar.propTypes = {
  onClick: PropTypes.func,
  url: PropTypes.string,
  size: PropTypes.number,
  isRound: PropTypes.bool,
  showBlueTick: PropTypes.bool,
  blueTickSize: PropTypes.number,
  className: PropTypes.string
};

Avatar.defaultProps = {
  onClick: null,
  url: null,
  size: 25,
  isRound: true,
  showBlueTick: false,
  blueTickSize: 14,
  className: null
};

export default Avatar;
