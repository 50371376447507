import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import i18n from 'src/i18n';
import { RESOURCE_MODE, TERMS_AND_POLICY_TABS } from 'src/constants';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Button from 'src/components/Button';
import MainLayout from 'src/components/MainLayout';
import Loading from 'src/components/Loading';

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import ViewModel from './vm';
import styles from './styles.module.scss';

@observer
class MyResourceFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new ViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderStepMark() {
    if (this.vm.step === 4) {
      return (
        <div onClick={() => {
          this.props.router.navigate(-1);
        }}
        >
          返回
        </div>
      );
    }
    return (
      <>
        <div>{this.vm.step}</div>
        <div style={{ margin: '0 5px' }}>of</div>
        <div>3</div>
      </>
    );
  }

  renderContent() {
    if (!this.vm.isInit) return null;
    switch (this.vm.step) {
      case 1:
        return (
          <Step1
            vm={this.vm}
            mode={this.vm.mode}
            type={this.vm.type}
          />
        );
      case 2:
        return (
          <Step2
            vm={this.vm}
            mode={this.vm.mode}
            type={this.vm.type}
          />
        );
      case 3:
        return (
          <Step3
            vm={this.vm}
            mode={this.vm.mode}
            type={this.vm.type}
          />
        );
      case 4:
        return (
          <Step4
            vm={this.vm}
            mode={this.vm.mode}
            type={this.vm.type}
          />
        );
      default:
        return null;
    }
  }

  renderNextButton() {
    switch (this.vm.step) {
      case 1:
      case 2:
        return (
          <Button
            onClick={this.vm.nextStep}
            className={styles.nextButton}
          >
            <span>{i18n.t(`myresource_btn_next_${this.vm.type}`)}</span>
          </Button>
        );
      case 3:
        return (
          <Button
            onClick={this.vm.nextStep}
            className={styles.nextButton}
          >
            <span>{this.vm.mode === RESOURCE_MODE.Add ? i18n.t('myresource_btn_create') : i18n.t('myresource_btn_update')}</span>
          </Button>
        );
      case 4:
        return (
          <Button
            onClick={this.vm.toEventList}
            className={styles.nextButton}
          >
            <span>{i18n.t('myresource_success_btn')}</span>
          </Button>
        );
      default:
        return null;
    }
  }

  renderBottomText() {
    if (this.vm.step === 4) {
      return (
        <div className={styles.bottomText}>
          <div className={styles.textContainer}>
            <span className={styles.text}>
              {i18n.t('myresource_success_bottom_text')}
            </span>
            <span className={styles.add} onClick={this.vm.toMyResource}>
              {i18n.t('myresource_success_bottom_link')}
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.bottomText}>
        <div className={styles.textContainer}>
          <span className={styles.text}>
            {`${i18n.t('register_agreement')} `}
          </span>
          <Link
            className={styles.link}
            to="/terms-and-policy#terms"
            state={{ tab: TERMS_AND_POLICY_TABS.Terms }}
            target="_blank"
          >
            {i18n.t('terms_of_use')}
          </Link>
          <span>{` ${i18n.t('and')} `}</span>
          <Link
            className={styles.link}
            to="/terms-and-policy#policy"
            state={{ tab: TERMS_AND_POLICY_TABS.Policy }}
            target="_blank"
          >
            {i18n.t('privacy_poliicy')}
          </Link>
        </div>
      </div>
    );

  }

  render() {
    return (
      <div className={clsx(styles.formPage, 'page')}>
        <MainLayout
          {...this.props}
          hideHeader
          footerStyle={{ marginTop: 40 }}
        >
          {
            (this.vm.step === 1 || this.vm.step === 4) && (
              <Breadcrumb
                onBackPress={() => { this.props.router.navigate(-1); }}
                routes={[
                  { label: i18n.t('myresource_breadcrumb_root'), path: '/user/my-resource' },
                  { label: this.vm.mode === RESOURCE_MODE.Add ? i18n.t('myresource_breadcrumb_create') : i18n.t('myresource_breadcrumb_edit'), path: '' }
                ]}
                right={this.renderStepMark()}
              />
            )
          }
          {
            (this.vm.step === 2 || this.vm.step === 3) && (
              <Breadcrumb
                onBackPress={this.vm.backStep}
                right={this.renderStepMark()}
                routes={[
                  { label: i18n.t('previous_step'), path: '' }
                ]}
              />
            )
          }
          <div className={styles.mainContainer}>
            {this.renderContent()}
          </div>

          <div style={{ display: 'flex', flexGrow: 1 }} />

          <div className={styles.bottomContainer}>
            {this.renderNextButton()}
            {this.renderBottomText()}
          </div>

        </MainLayout>

        { this.vm.isAwait && <Loading /> }

      </div>
    );
  }
}

MyResourceFormPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  router: PropTypes.object.isRequired
};

export default withProfile(withRouter(withTranslation()(MyResourceFormPage)), true);
