import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';
import Info from 'src/models/response/Profile/Info';

class Personal extends Info {
  @observable birthday;
  @observable company;
  @observable jobs = [];
  @observable jobTitle;
  @observable cityOfResidence;
  @observable cityOfBirth;

  constructor(params) {
    super(params);
    makeObservable(this);
    this.deserialize(params);
  }

  static fromRes(data) {
    return new Personal(data);
  }

  @action deserialize = (params) => {
    const {
      birthday,
      company,
      jobs,
      jobTitle,
      cityOfResidence,
      cityOfBirth
    } = params ?? {};

    this.birthday = birthday ? dayjs(birthday).format('YYYY-MM-DD') : null;
    this.company = company;
    this.jobTitle = jobTitle;
    this.cityOfResidence = cityOfResidence;
    this.cityOfBirth = cityOfBirth;
    this.jobs = jobs ?? [];
  };

  serialize = () => {
    const {
      birthday,
      company,
      jobs,
      jobTitle,
      cityOfResidence,
      cityOfBirth,
      motto,
      sdgs,
      cities,
      socialsObj
    } = this;

    return {
      birthday,
      company,
      jobs,
      jobTitle,
      cityOfResidence,
      cityOfBirth,
      motto,
      sdgs,
      cities,
      socials: socialsObj.serialize()
    };
  };
}

export default Personal;
