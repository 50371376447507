import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { INVOICE_TYPE } from 'src/constants';
import { t } from 'i18next';
import ErrorService from 'src/services/errors';
import PurchaseService from 'src/services/Purchase';
import IS from 'src/utils/is';

export const INVOICE_OPTIONS = {
  Tax: 'tax', // 統編發票
  MobileBarcode: INVOICE_TYPE.MobileBarcode,
  CDC: INVOICE_TYPE.CDC,
  LoveCode: 'loveCode', // 捐贈發票
  Member: INVOICE_TYPE.Member
};

export const invoiceTypeOptions = [
  { value: INVOICE_OPTIONS.Tax, label: t('invoice_taxId') },
  { value: INVOICE_OPTIONS.MobileBarcode, label: t(`invoice_${INVOICE_TYPE.MobileBarcode}`) },
  { value: INVOICE_OPTIONS.CDC, label: t(`invoice_${INVOICE_TYPE.CDC}`) },
  { value: INVOICE_OPTIONS.LoveCode, label: t('invoice_loveCode') },
  { value: INVOICE_OPTIONS.Member, label: t(`invoice_${INVOICE_TYPE.Member}`) }
];

export const itemNameOptions = [
  { value: t('invoice_item_name_platform'), label: t('invoice_item_name_platform') },
  { value: t('invoice_item_name_consult'), label: t('invoice_item_name_consult') },
  { value: t('invoice_item_name_design'), label: t('invoice_item_name_design') },
  { value: t('invoice_item_name_training'), label: t('invoice_item_name_training') },
  { value: t('invoice_item_name_ad'), label: t('invoice_item_name_ad') }
];

class CheckoutPageViewModel {
  @observable userId = null;
  @observable product = null;
  @observable productId = null;

  // invoice
  @observable invoiceType; // for UI
  // @observable type;
  @observable code; // mobile barcode | cdc code
  @observable name;
  @observable phone; // 與 email 擇一必填
  @observable email; // 與 phone 擇一必填
  @observable taxId;
  @observable address;
  @observable loveCode; // donate love code
  @observable itemName; // 登錄品項

  @observable remark;

  @observable showError = false;

  @observable isAwait = false;

  constructor(props) {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    console.log('CheckoutPage.didMount, params', props.router.params);
    console.log(props);
    const { router } = props;
    this.props = props;
    this.productId = router?.params?.id;
    this.product = router?.location?.state?.product;
  };

  @action onChange = (key, value) => {
    this[key] = value;
  };

  @action onConfirm = async () => {
    this.showError = true;

    if (Object.values(this.isValid).some((v) => !v)) {
      ErrorService.onCustomError(t('error_invalid_data'));
      return;
    }

    this.showError = false;
    this.isAwait = true;
    const data = this.serialize();
    console.log('confirm', data);
    try {
      const res = await PurchaseService.buyProduct(this.productId, data);
      console.log(res);
      document.open();
      document.write(res.html);
      document.close();
    } catch (error) {
      console.log(error);
      switch (error?.response?.data?.code) {
        case '0xf001a':
          ErrorService.onCustomError(t('error_mobileBarcode'));
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @computed get isValid() {
    return {
      name: !!this.name,
      phone: !!this.phone && IS.phone(this.phone),
      email: !!this.email && IS.email(this.email),
      invoiceType: !!this.invoiceType,
      code: ![INVOICE_TYPE.MobileBarcode, INVOICE_TYPE.CDC].includes(this.invoiceType) || (!!this.code && this.isCodeValid),
      taxId: this.invoiceType !== INVOICE_OPTIONS.Tax || (!!this.taxId && this.isTaxIdValid),
      loveCode: this.invoiceType !== INVOICE_OPTIONS.LoveCode || !!this.loveCode
      // address
    };
  }

  @computed get isCodeValid() {
    if (this.invoiceType === INVOICE_TYPE.MobileBarcode) {
      return IS.mobileBarcode(this.code);
    }

    if (this.invoiceType === INVOICE_TYPE.CDC) {
      return IS.cdcCode(this.code);
    }

    return true;
  }

  @computed get isTaxIdValid() {
    return IS.taxId(this.taxId);
  }

  @computed get priceForDisplay() {
    if (!this.product?.price) return 0;
    return IS.numeric(this.product?.price) ? `TWD ${Math.round(this.product?.price)}` : this.product?.price;
  }

  // ////////////////////////////////////
  serialize = () => {
    const {
      invoiceType,
      code,
      taxId,
      name,
      phone,
      email,
      itemName,
      // address,
      loveCode,
      remark
    } = this;

    const invoice = {
      type: Object.values(INVOICE_TYPE).includes(invoiceType) ? invoiceType : INVOICE_TYPE.Member,
      name,
      phone,
      email
    };

    switch (invoiceType) {
      case INVOICE_OPTIONS.MobileBarcode:
      case INVOICE_OPTIONS.CDC:
        invoice.code = code;
        break;
      case INVOICE_OPTIONS.Tax:
        invoice.taxId = taxId;
        break;
      case INVOICE_OPTIONS.LoveCode:
        invoice.loveCode = loveCode;
        break;
      default:
    }

    return {
      itemName,
      invoice,
      remark
    };
  };


}

export default CheckoutPageViewModel;
