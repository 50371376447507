import {
  makeObservable,
  observable,
  action
} from 'mobx';
import { RESOURCE_TYPES } from 'src/constants';

class TabsState {

      // 資源列表
      @observable resourcesTab = RESOURCE_TYPES.Service;

      // 行動列表
      @observable eventsTab = 'ongoing';

      // 行動需求列表
      @observable demandsTab = RESOURCE_TYPES.Service;

      // 資源管理
      @observable myResourcesTab = RESOURCE_TYPES.Service;

      // 參與行動列表
      @observable eventJoinedTab = 'accepted';

      // 發起行動列表
      @observable eventHostedTab = 'active';

      // 發起行動需求列表
      @observable eventDemandsTab = RESOURCE_TYPES.Service;

      constructor(props) {
        this.props = props;
        makeObservable(this);
      }
      @action updateResourcesTab = (t) => {
        this.resourcesTab = t;
      };
      @action updateMyresourcesTab = (t) => {
        this.myResourcesTab = t;
      };
      @action updateEventsTab = (t) => {
        this.eventsTab = t;
      };
      @action updateDemandsTab = (t) => {
        this.demandsTab = t;
      };
      @action updateEventJoinedTab = (t) => {
        this.eventJoinedTab = t;
      };
      @action updateEventHostedTab = (t) => {
        this.eventHostedTab = t;
      };
      @action updateEventDemandsTab = (t) => {
        this.eventDemandsTab = t;
      };


}

const tabsState = new TabsState();
export default tabsState;



