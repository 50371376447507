import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import SDGs from 'src/constants/gens/SDGs.gen';
import store from 'src/stores/constants';
import Socials from 'src/models/response/Profile/Socials';
import IS from 'src/utils/is';

class Info {
  @observable motto;
  @observable sdgs = [];
  @observable cities = [];
  @observable socials; // string
  @observable socialsObj = {};

  constructor(params) {
    makeObservable(this);

    runInAction(() => {
      const {
        motto,
        sdgs,
        cities,
        socials
      } = params ?? {};

      this.motto = motto;
      this.sdgs = sdgs ?? [];
      this.cities = cities ?? [];
      this.socials = socials;
      this.socialsObj = Socials.fromRes(socials);
    });
  }

  get convertedSdgs() {
    const Obj = this.sdgs?.reduce((acc, val) => {
      const [key, value] = val.split('-');
      if (acc[key]) {
        acc[key].push(val);
      } else {
        acc[key] = [val];
      }
      return acc;
    }, {});

    const list = [];
    Object.entries(Obj)?.forEach((el) => {
      if (Object.keys(SDGs[store.language][el[0]].childs).length === el[1].length) {
        return list.push(el[0]);
      }
      return list.push(...el[1]);
    });

    return list;
  }

  get convertedRegions() {
    return store.getCombinedRegionsValuesArr(this.cities);
  }

  @action onChange = (field, value) => {
    this[field] = value;
  };

  onSocialChange = (key, value) => {
    if (this.socialsObj?.onChange) {
      this.socialsObj.onChange(key, value);
    }
  };

  @computed get socialsArr() {
    return this.socialsObj?.socialsArr;
  }

  @computed
  get isMottoValid() {
    return this.motto && IS.motto(this.motto);
  }

  // /////////////////////////////////////////////

  static fromRes(data) {
    return new Info(data);
  }
}

export default Info;
