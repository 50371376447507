import React from 'react';
import './icon.scss';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';

const CustomIcon = React.memo(React.forwardRef((props, ref) => {
  const { color, size, children, className, style, rotate, flip, fill, svg, ...otherProps } = props;
  if (svg) {
    return (
      <span
        className={`custom-icon ${className || ''}`}
        style={{
          display: 'inline-block',
          backgroundImage: `url(${children})`,
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: (size === 'small' ? 20 : size || 24),
          width: (size === 'small' ? 20 : size || 24),
          height: (size === 'small' ? 20 : size || 24),
          fontSize: (size === 'small' ? 20 : size || 24),
          transform: `${rotate ? `rotate(${rotate}deg)` : ''} ${flip ? 'scaleY(-1)' : ''}`,
          transition: 'transform .24s',
          verticalAlign: 'text-bottom',
          cursor: props.onClick ? 'pointer' : 'inherit',
          ...style
        }}
        ref={ref}
        {...otherProps}
      />
    );

  }
  return (
    <Icon
      icon={children}
      className={className || ''}
      style={{
        display: 'inline-block',
        fontSize: (size === 'small' ? 20 : size),
        transform: `${rotate ? `rotate(${rotate}deg)` : ''} ${flip ? 'scaleY(-1)' : ''}`,
        transition: 'transform .24s',
        fontVariationSettings: fill ? '\'FILL\' 1, \'wght\' 700, \'GRAD\' 0, \'opsz\' 48' : null,
        verticalAlign: 'text-bottom',
        color: color || null,
        ...style
      }}
      ref={ref}
    />
  );
}));

export default CustomIcon;

CustomIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  rotate: PropTypes.number,
  flip: PropTypes.bool,
  fill: PropTypes.bool,
  svg: PropTypes.bool,
  onClick: PropTypes.func
};

CustomIcon.defaultProps = {
  color: null,
  size: 24,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any
  ]),
  className: '',
  style: {},
  rotate: null,
  flip: null,
  fill: null,
  svg: true,
  onClick: null
};
