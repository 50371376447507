import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Breadcrumb from 'src/components/Breadcrumb';

import ValidateEmail from './components/ValidateEmail';
import ResetPassword from './components/ResetPassword';
import Success from './components/Success';


import ResetPasswordPageVM from './vm';

import styles from './styles.module.scss';

@observer
class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.vm = new ResetPasswordPageVM(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  render() {
    const content = () => {
      if (!this.vm.token) {
        return <ValidateEmail {...this.props} vm={this.vm} />;
      }
      if (this.vm.isSuccess) {
        return <Success {...this.props} vm={this.vm} />;
      }
      return <ResetPassword {...this.props} vm={this.vm} />;
    };

    return (
      <div className="page">
        <Breadcrumb
          routes={[
            { label: 'gether', path: '/' },
            { label: this.vm.token ? t('reset_password') : t('forget_password'), path: '' }
          ]}
          right={null}
        />
        <div className={styles.resetPasswordPage}>
          <div className={styles.container}>
            {content()}
          </div>
        </div>
      </div>
    );
  }
}

export default withProfile(withRouter(withTranslation()(ResetPasswordPage)), false);
