import { makeObservable, observable, action, runInAction, computed, toJS } from 'mobx';
import ConstantsStore from 'src/stores/constants';
import DemandMeta from './DemandMeta';

class Funds extends DemandMeta {
  @observable quota;
  @observable types;

  constructor(params) {
    super(params);
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get typesLabels() {
    return this.types?.map((type) => ConstantsStore.fundsTypesOptions.find((o) => o.value === type)?.label ?? '-');
  }

  @computed get isComplete() {
    return this.isCommonAttrComplete
      && !!this.quota && this.isQuotaValid
      && this.types?.length !== 0;
  }

  @computed get isEmpty() {
    return this.isCommonAttrEmpty
      && !this.quota
      && this.types?.length === 0;
  }

  @computed get isQuotaValid() {
    return /^\d+(.\d*)?$/.test(this.quota);
  }

  @action deserialize = (params) => {
    const {
      quota,
      types
    } = params ?? {};

    this.quota = quota;
    this.types = types ?? [];
  };

  serialize = () => {
    return {
      ...this.serializeCommonAttr(),
      quota: this.quota,
      types: toJS(this.types)
    };
  };

  get displayQuota() {
    return Number(this.quota).toLocaleString('en-US');
  }

  static fromRes(data) {
    return new Funds(data);
  }
}

export default Funds;




