import { request, getHost, getHeader } from './utils';

export const list = (params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/notification/list`,
    headers: getHeader(),
    params
  };
  return request(options);
};

export const unreadCount = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/notification/unread-count`,
    headers: getHeader()
  };
  return request(options);
};

export const read = (action, id) => {
  const options = {
    method: 'put',
    url: `${getHost()}/v1/notification/read`,
    headers: getHeader(),
    data: {
      action,
      id
    }
  };
  return request(options);
};
