import React, { useState, useEffect } from 'react';
import goToTop from 'src/assets/goToTop.png';
import styles from './styles.module.scss';

function ScrollToTopButton() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div
      className={styles.scrollToTopButton}
      onClick={handleClick}
    >
      <img
        alt="go to top button"
        src={goToTop}
        className={styles.img}
      />
    </div>
  );
}

export default ScrollToTopButton;
