/* eslint-disable */
const Equipments = {
  "zh-TW": {
    "1": "講台",
    "2": "麥克風",
    "3": "白板",
    "4": "桌椅",
    "5": "插座",
    "6": "延長線",
    "7": "廁所",
    "8": "飲水機",
    "9": "空調",
    "10": "Wi-Fi",
    "11": "舞台",
    "12": "投影設備",
    "13": "音響設備",
    "14": "燈光設備",
    "15": "樂器設備",
    "16": "影音人員協助",
    "17": "技術人員協助",
    "18": "場佈人員協助",
    "19": "導覽人員協助",
    "20": "照顧人員協助",
    "21": "無障礙電梯",
    "22": "無障礙廁所",
    "23": "無障礙通道",
    "24": "無障礙設施",
    "25": "餐點飲品",
    "26": "特色選物",
    "27": "圖書收藏",
    "28": "廚房",
    "29": "烹飪設備",
    "30": "運動器材",
    "31": "停車空間",
    "32": "圖書資料",
    "33": "緊急救護設備",
    "34": "遊樂設備",
    "35": "不指定",
    "36": "其他"
  },
  "en-US": {
    "1": "講台",
    "2": "麥克風",
    "3": "白板",
    "4": "桌椅",
    "5": "插座",
    "6": "延長線",
    "7": "廁所",
    "8": "飲水機",
    "9": "空調",
    "10": "Wi-Fi",
    "11": "舞台",
    "12": "投影設備",
    "13": "音響設備",
    "14": "燈光設備",
    "15": "樂器設備",
    "16": "影音人員協助",
    "17": "技術人員協助",
    "18": "場佈人員協助",
    "19": "導覽人員協助",
    "20": "照顧人員協助",
    "21": "無障礙電梯",
    "22": "無障礙廁所",
    "23": "無障礙通道",
    "24": "無障礙設施",
    "25": "餐點飲品",
    "26": "特色選物",
    "27": "圖書收藏",
    "28": "廚房",
    "29": "烹飪設備",
    "30": "運動器材",
    "31": "停車空間",
    "32": "圖書資料",
    "33": "緊急救護設備",
    "34": "遊樂設備",
    "35": "不指定",
    "36": "其他"
  }
}

export const EquipmentsOrder = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "13": 13,
  "14": 14,
  "15": 15,
  "16": 16,
  "17": 17,
  "18": 18,
  "19": 19,
  "20": 20,
  "21": 21,
  "22": 22,
  "23": 23,
  "24": 24,
  "25": 25,
  "26": 26,
  "27": 27,
  "28": 28,
  "29": 29,
  "30": 30,
  "31": 31,
  "32": 32,
  "33": 33,
  "34": 34,
  "35": 35,
  "36": 999
}

export default Equipments
