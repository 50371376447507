import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { t } from 'i18next';
import { TERMS_AND_POLICY_TABS } from 'src/constants';
import Icon from 'src/components/Icon';
import footerFacebook from 'src/assets/footerFacebook.svg';
import footerInstagram from 'src/assets/footerInstagram.svg';
import footerMail from 'src/assets/footerMail.svg';
import logoPurple from 'src/assets/logoPurple.svg';

import styles from './styles.module.scss';

function Footer(props) {
  const { className, footerWrapperClassName, style, isMobile, isInMenu, isDarkMode } = props;
  return (
    <div className={clsx(styles.footerWrapper, footerWrapperClassName)}>
      <div className={clsx(styles.footerContainer, (isMobile || isInMenu) && styles.col, className)} style={style}>
        <div className={styles.logoContainer}>
          {isInMenu
            ? (
              <div className={styles.title}>
                © 2023 gether
              </div>
            )
            : (
              <img
                alt="logo"
                src={logoPurple}
                className={styles.logo}
              />
            )}
        </div>
        <div className={styles.links}>
          <Link
            className={clsx(styles.link, isDarkMode && styles.darkMode)}
            to="/terms-and-policy#terms"
            state={{ tab: TERMS_AND_POLICY_TABS.Terms }}
            target="_blank"
          >
            {t('terms_of_use')}
          </Link>
          <span className={styles.separator}>|</span>
          <Link
            className={clsx(styles.link, isDarkMode && styles.darkMode)}
            to="/terms-and-policy#policy"
            state={{ tab: TERMS_AND_POLICY_TABS.Policy }}
            target="_blank"
          >
            {t('privacy_poliicy')}
          </Link>
        </div>
        <div className={clsx(styles.icons, isDarkMode && styles.darkMode)}>
          <a
            href="https://www.facebook.com/5DesignAction"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              size={24}
              className={styles.icon}
              svg
            >
              {footerFacebook}
            </Icon>
          </a>

          <a
            href="https://www.instagram.com/5designaction/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              size={24}
              className={styles.icon}
              svg
            >
              {footerInstagram}
            </Icon>
          </a>

          <a
            href="mailto:service@5designaction.com"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              size={24}
              className={styles.icon}
              svg
            >
              {footerMail}
            </Icon>
          </a>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  isMobile: PropTypes.bool,
  isInMenu: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  footerWrapperClassName: PropTypes.string
};

Footer.defaultProps = {
  className: '',
  style: {},
  isMobile: false,
  isInMenu: false,
  isDarkMode: false,
  footerWrapperClassName: ''
};

export default Footer;
