import React from 'react';
import clsx from 'clsx';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';

import i18n from 'src/i18n';
import { RESOURCE_TYPES } from 'src/constants';
import BaseModal from 'src/components/Modals/BaseModal';
import Button from 'src/components/Button';

import handshake from 'src/assets/handshake.png';
import serviceIcon from 'src/assets/resourceServiceBlue.svg';
import fundsIcon from 'src/assets/resourceMoneyBlue.svg';
import sapceIcon from 'src/assets/resourceSpaceBlue.svg';

import styles from './styles.module.scss';

const resourceIcons = {
  service: serviceIcon,
  funds: fundsIcon,
  space: sapceIcon
};

export default function ResourceAddModal(props) {

  const { vm, isOpen, onModalToggle, isMobile } = props;

  return (
    <Observer>
      {() => (
        <BaseModal
          onClose={() => {
            onModalToggle();
          }}
          open={isOpen}
          isMobile={isMobile}
          height={420}
        >
          <div className={styles.container}>
            <img
              src={handshake}
              alt="handshake"
              className={clsx(styles.illustration)}
            />
            <div className={styles.title}>
              {i18n.t('myresource_modal_title')}
            </div>
            <div className={styles.desc}>
              {i18n.t('myresource_modal_subtitle_1')}
            </div>
            <div className={styles.desc}>
              {i18n.t('myresource_modal_subtitle_2')}
            </div>

            <div className={styles.identityContainer}>
              {
            Object.values(RESOURCE_TYPES).map((type) => (
              <div
                key={type}
                className={clsx(styles.identityCard, vm.modalType === type && styles.identityCardSelected)}
                onClick={() => {
                  vm.onModalChange(type);
                }}
              >
                <img
                  src={resourceIcons[type]}
                  alt={type}
                  className={styles.identityImg}
                />
                <div className={styles.identityText}>
                  {i18n.t(`resources_${type}`)}
                </div>
              </div>
            ))
          }
            </div>

            <Button
              onClick={vm.toFormPage}
              className={styles.button}
              withRightArrow
            >
              {i18n.t('myresource_next_btn')}
            </Button>
          </div>
        </BaseModal>
      )}
    </Observer>
  );
}


ResourceAddModal.propTypes = {
  vm: PropTypes.object,
  isOpen: PropTypes.bool,
  onModalToggle: PropTypes.func,
  isMobile: PropTypes.bool
};

ResourceAddModal.defaultProps = {
  vm: {},
  isOpen: false,
  onModalToggle: () => {},
  isMobile: false
};
