import React, { useReducer, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'ismobilejs';
import { v4 as uuidv4 } from 'uuid';

import { actions } from './actions';
import { reducer } from './reducer';

const initialState = {
  isMobile: isMobile(window.navigator).any,
  profile: null,
  sessionId: uuidv4().replace(/-/g, '')
};

const isPro = /^goaction.5percent-design-action.com/.test(window.location.host);

const AppStateContext = React.createContext();
AppStateContext.displayName = 'AppState Context';

const AppStateProvider = (props) => {
  const [appState, dispatch] = useReducer(reducer, initialState);
  const drawerParentRef = useRef();

  const value = useMemo(
    () => ({
      state: appState,
      actions: actions(dispatch)
    }),
    [appState]
  );

  return (
    <AppStateContext.Provider
      value={value}
    >
      { props.children }
    </AppStateContext.Provider>
  );
};

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AppStateProvider, AppStateContext, isPro };
