import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { t } from 'i18next';
import IS from 'src/utils/is';
import { USER_TYPES } from 'src/constants';
import ConstantsStore from 'src/stores/constants';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import Button from 'src/components/Button';
import withProfile from 'src/components/withProfile';
import Icon from 'src/components/Icon';
import ConfirmAcceptModal from 'src/components/Modals/ConfirmAcceptModal';
import Tooltip from 'antd/es/tooltip';
import fakeAvatar from 'src/assets/fakeAvatar.png';
import blueTick from 'src/assets/blueTick.png';
import iconNotice from 'src/assets/iconNotice.svg';

import ApplicationPageViewModel from './vm';
import styles from './styles.module.scss';

@observer
class ApplicationPage extends React.Component {
  motivationRef = React.createRef();
  cognitionRef = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new ApplicationPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderAvatars() {
    return (
      <div className={styles.avatarContainer}>
        <div
          style={{ backgroundImage: `url(${this.vm.user?.avatar ?? fakeAvatar})` }}
          className={clsx(styles.avatar)}
        />
        <div
          style={{ backgroundImage: `url(${this.vm.eventBanner ?? fakeAvatar})` }}
          className={clsx(styles.avatar, styles.avatar2)}
        />
      </div>
    );
  }

  renderContent() {
    return (
      <>
        <div className={styles.name}>
          {this.vm.user?.displayName}
          {this.vm.user?.blueTickAt && (
            <img
              className={clsx(styles.bluetick)}
              src={blueTick}
              alt="bluetick"
              width={24}
              height={24}
            />
          )}
        </div>
        <div className={styles.pageTitle}>
          {t('application_title')}
        </div>

        {(this.vm.meta.job || this.vm.meta.jobs?.length !== 0) && (
          <>
            <div className={styles.title}>
              {t('application_industry')}
            </div>
            <div className={styles.text}>
              {this.vm.meta?.job && ConstantsStore.jobsOptions.find((el) => el.value === this.vm.meta.job)?.label}

              {this.vm.meta?.jobs && this.vm.meta.jobs.map((j, i) => (
                <span key={j}>
                  {ConstantsStore.jobsOptions.find((el) => el.value === j)?.label}
                  {i < this.vm.meta.jobs.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </>
        )}

        {(this.vm.identity === USER_TYPES.Organization && this.vm.meta?.type) && (
          <>
            <div className={styles.title}>
              {t('application_organization_type')}
            </div>
            <div className={styles.text}>
              {ConstantsStore.organizationTypesOptions.find((el) => el.value === this.vm.meta.type)?.label}
            </div>
          </>
        )}

        {this.vm.relatedRegions.length !== 0 && (
          <>
            <div className={clsx(styles.flexRow, styles.title)}>
              <div style={{ marginRight: 3 }}>
                {t('application_regions')}
              </div>
              <Tooltip
                placement="top"
                title={t('application_cities_notice')}
                color="#0C0E17"
                overlayInnerStyle={{
                  borderRadius: 20,
                  fontWeight: 700,
                  fontSize: 12,
                  padding: 10
                }}
              >
                <Icon
                  size={12}
                  svg
                  className={styles.iconNotice}
                >
                  {iconNotice}
                </Icon>
              </Tooltip>
            </div>
            <div className={styles.text}>
              {this.vm.relatedRegions?.map((r, i) => (
                <span key={r}>
                  {ConstantsStore.getRegionLabelByValue(r)}
                  {i < this.vm.relatedRegions.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </>
        )}

        {this.vm.socialLinks.length !== 0 && (
          <>
            <div className={styles.title}>
              {t('application_social_link')}
            </div>
            <div className={styles.text}>
              {this.vm.socialLinks.filter((s) => s.url)?.map((item, i) => (
                <span key={item.url}>
                  <a href={item.url} className={styles.socialLink}>
                    {t(`application_${item.name}`)}
                  </a>
                  {i < this.vm.socialLinks.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </>
        )}

        {this.vm.meta?.birthday && (
          <>
            <div className={styles.title}>
              {t('application_birthday')}
            </div>
            <div className={styles.text}>
              {dayjs(this.vm.meta.birthday).format('YYYY.MM.DD')}
            </div>
          </>
        )}

        {this.vm.meta?.foundedIn && (
          <>
            <div className={styles.title}>
              {t('application_foundedIn')}
            </div>
            <div className={styles.text}>
              {dayjs(this.vm.meta.foundedIn).format('YYYY.MM.DD')}
            </div>
          </>
        )}

        {this.vm.meta?.gender && (
          <>
            <div className={styles.title}>
              {t('application_gender')}
            </div>
            <div className={styles.text}>
              {t(`gender_${this.vm.meta.gender}`)}
            </div>
          </>
        )}

        <div className={styles.title}>
          {t('application_motivation')}
        </div>
        <div
          className={clsx(styles.textNormal, !this.vm.hasClickedMotivationMore && styles.clamp)}
          ref={this.motivationRef}
        >
          {this.vm.application.motivation}
        </div>
        {this.vm.isDescClamped(this.motivationRef) && !this.vm.hasClickedMotivationMore && (
          <div
            className={styles.more}
            onClick={this.vm.clickMotivationMore}
          >
            {t('expand_desc_more')}
          </div>
        )}

        {this.vm.application.cognition && (
        <>
          <div className={styles.title}>
            {t('application_cognition')}
          </div>
          <div
            className={clsx(styles.textNormal, !this.vm.hasClickedCognitionMore && styles.clamp)}
            ref={this.cognitionRef}
          >
            {this.vm.application.cognition}
          </div>
          {this.vm.isDescClamped(this.cognitionRef) && !this.vm.hasClickedCognitionMore && (
            <div
              className={styles.more}
              onClick={this.vm.clickCognitionMore}
            >
              {t('expand_desc_more')}
            </div>
          )}
        </>
        )}

        {Object.entries(this.vm.fields)?.map((item) => {
          const label = item[0];
          const value = item[1];
          return (
            <div key={label}>
              <div className={styles.title}>
                {label}
              </div>
              <div className={styles.text}>
                {IS.array(value) ? value?.map((opt, i) => (
                  <span key={opt}>
                    {opt}
                    {i < value.length - 1 && (
                      <span className={styles.dot}>•</span>
                    )}
                  </span>
                )) : value}
              </div>
            </div>
          );
        })}

        <div className={styles.sectionTitle}>
          {`/ ${t('application_contact')} /`}
        </div>
        <div className={styles.title}>
          {t('application_contact_name')}
        </div>
        <div className={styles.text}>
          {this.vm.user?.displayName}
        </div>

        {this.vm.meta?.company && (
          <>
            <div className={styles.title}>
              {t('application_contact_company')}
            </div>
            <div className={styles.text}>
              {this.vm.meta.company}
            </div>
          </>
        )}

        {this.vm.meta?.jobTitle && (
          <>
            <div className={styles.title}>
              {t('application_contact_jobTitle')}
            </div>
            <div className={styles.text}>
              {this.vm.meta.jobTitle}
            </div>
          </>
        )}

        <div className={styles.title}>
          {t('application_contact_phone')}
        </div>
        <div className={styles.text}>
          {this.vm.meta?.phone}
        </div>
        <div className={styles.title}>
          {t('application_contact_email')}
        </div>
        <div className={styles.text}>
          {this.vm.meta?.email}
        </div>
      </>
    );
  }

  render() {
    return (
      <div className={clsx(styles.signUpPage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideHeader
          footerStyle={{ marginBottom: 80 }}
        >
          <Breadcrumb
            routes={[
              { label: t('back'), path: '' }
            ]}
            right={null}
          />
          <div className={styles.mainContainer}>
            {this.renderAvatars()}
            {this.vm.application && this.renderContent()}
          </div>
          {this.vm.isMyEvent && (
          <Button
            className={styles.ctaButton}
            onClick={this.vm.toggleConfirmAcceptModal}
          >
            {this.vm.application?.acceptedAt ? t('application_deny') : t('application_accept')}
          </Button>
          )}

          <ConfirmAcceptModal
            onToggle={this.vm.toggleConfirmAcceptModal}
            open={this.vm.isConfirmAcceptModalOpen}
            isMobile={this.vm.isMobile}
            name={this.vm.application?.profile?.meta?.name}
            isToAccept={!this.vm.application?.acceptedAt}
            onButtonClick={this.vm.toggleAcceptStatus}
          />

        </MainLayout>
      </div>
    );
  }
}

ApplicationPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(ApplicationPage)), false);
