import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { t } from 'i18next';

import blueTick from 'src/assets/blueTick.png';
import styles from './styles.module.scss';

@observer
class RectangleEventCard extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { item, onClick, className } = this.props;

    if (!item) return null;

    return (
      <div
        className={clsx(styles.card, 'card', !!onClick && styles.clickable, className)}
        onClick={onClick}
      >
        <div
          style={{ backgroundImage: `url(${item.banner})` }}
          className={styles.banner}
        />
        <div className={styles.title}>
          {item.name}
        </div>
        {item.blueTickAt && (
          <img
            className={styles.bluetick}
            src={blueTick}
            alt="bluetick"
            width={24}
            height={24}
          />
        )}
      </div>
    );
  }
}

RectangleEventCard.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  onClick: PropTypes.func
};

RectangleEventCard.defaultProps = {
  className: null,
  item: null,
  onClick: null
};

export default RectangleEventCard;
