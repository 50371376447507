import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { USER_TYPES } from 'src/constants';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Button from 'src/components/Button';

import illustrationPersonal from 'src/assets/illustrationGirlHoldingAHouse.png';
import illustrationOrg from 'src/assets/illustrationBoysHoldingACommunity.png';

import styles from '../../styles.module.scss';

@observer
class Identity extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <div className={styles.step0Container}>
        <div className={clsx(styles.flexCol, styles.grow1)}>
          <h2 className={styles.title}>
            {t('register_step0_title')}
          </h2>

          <h3 className={styles.subtitle} style={{ marginBottom: 0 }}>
            {t('register_step0_subtitle')}
          </h3>

          <div className={styles.mainContent}>
            <div className={styles.identityContainer}>
              <div
                className={clsx(styles.identityCard, this.vm.identity === USER_TYPES.Personal && styles.identityCardSelected)}
                onClick={() => this.vm.onChange('identity', USER_TYPES.Personal)}
                style={{ marginRight: 15 }}
              >
                <img
                  src={illustrationPersonal}
                  alt="personal"
                  className={styles.identityImg}
                />
                <div className={styles.identityText}>
                  {t('register_step0_personal')}
                </div>
              </div>
              <div
                className={clsx(styles.identityCard, this.vm.identity === USER_TYPES.Organization && styles.identityCardSelected)}
                onClick={() => this.vm.onChange('identity', USER_TYPES.Organization)}
              >
                <img
                  src={illustrationOrg}
                  alt="organization"
                  className={styles.identityImg}
                />
                <div className={styles.identityText}>
                  {t('register_step0_organization')}
                </div>
              </div>
            </div>

            <p className={styles.notice}>
              <span className={clsx(styles.purple, styles.marginRight3)}>＊</span>
              {t('register_step0_notice')}
            </p>
          </div>
        </div>

        <div style={{ flexGrow: 1 }} />

        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.onNextClick}
            style={{ width: '100%', maxWidth: 335 }}
            disabled={!this.vm.identity}
          >
            {t('next_step')}
          </Button>

          <div className={styles.bottomText}>
            <span className={styles.text}>{t('register_step0_alreadyHasAcccount')}</span>
            <a href="./login">
              <h4 style={{ display: 'inline' }}>{t('register_step0_navigateToLogin')}</h4>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Identity.propTypes = {
  vm: PropTypes.object
};

Identity.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Identity)), false);
