import API from 'src/apis';
import Order from 'src/models/response/Order';

export default class PurchaseService {
  static async buyProduct(id, body) {
    const { data } = await API.purchase.buyProduct(id, body);
    return data;
  }

  static async detail(id) {
    const { data } = await API.purchase.detail(id);
    return Order.fromRes(data.trade);
  }
}
