import {
  makeObservable, observable, action, runInAction, computed, toJS
} from 'mobx';
import { t } from 'i18next';
import qs from 'qs';

import pretendTo from 'src/stores/pretendTo';
import { RESOURCE_TYPES, TABLE_LIMIT, TABLE_LIMIT_MOBILE, LIST_MODEL_TYPE } from 'src/constants';
import ResourceService from 'src/services/resource';
import MemberOnlyFeatureModalVM, { getBlockReason } from 'src/components/Modals/MemberOnlyFeatureModal/vm';
import GeneralModalViewModel from 'src/components/Modals/GeneralModal/vm';
import ErrorService from 'src/services/errors';
import tabsState from 'src/stores/tabsState';
import AnchoredList from 'src/services/anchoredList';

class MyResourcePageViewModel {
  // sort
  sort = 'id';
  @observable order; // asc / desc

  // modal
  @observable isOpen = false;
  @observable modalType = RESOURCE_TYPES.Service;

  @observable memberOnlyFeatureModalVM = null;
  @observable generalModalViewModel = new GeneralModalViewModel();

  @observable AnchoredLists = {
    [RESOURCE_TYPES.Service]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Resource,
      path: 'v1/user/{uid}/resource/service/list',
      method: 'get'
    }),
    [RESOURCE_TYPES.Funds]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Resource,
      path: 'v1/user/{uid}/resource/funds/list',
      method: 'get'
    }),
    [RESOURCE_TYPES.Space]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Resource,
      path: 'v1/user/{uid}/resource/space/list',
      method: 'get'
    })
  };


  @computed get type() {
    return tabsState.myResourcesTab;
  }

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props) => {
    console.log('MyResourcePage.didMount, params', props.router.params);
    console.log(props);
    this.isMobile = props.context.state.isMobile;
    this.profile = props.profile;

    const uid = pretendTo.id ?? this.profile.id;
    this.AnchoredLists[RESOURCE_TYPES.Service].setPath(`v1/user/${uid}/resource/service/list`);
    this.AnchoredLists[RESOURCE_TYPES.Funds].setPath(`v1/user/${uid}/resource/funds/list`);
    this.AnchoredLists[RESOURCE_TYPES.Space].setPath(`v1/user/${uid}/resource/space/list`);
    await this.getLists();
    if (props.router.location?.state) {
      if (props.router.location.state.openAddResourceModel) {
        this.onAddResource();
      }
    }
  };

  @action switchType = async (value) => {
    // this.type = value;
    tabsState.updateMyresourcesTab(value);
    this.onModalChange(value);
  };

  @computed get list() {
    return this.lists[this.type];
  }

  @computed get lists() {
    return {
      [RESOURCE_TYPES.Service]: this.AnchoredLists[RESOURCE_TYPES.Service].list,
      [RESOURCE_TYPES.Funds]: this.AnchoredLists[RESOURCE_TYPES.Funds].list,
      [RESOURCE_TYPES.Space]: this.AnchoredLists[RESOURCE_TYPES.Space].list
    };
  }

  @computed get anchor() {
    return this.AnchoredLists[this.type].anchor;
  }

  @computed get isAwait() {
    return this.AnchoredLists[this.type].isAwait;
  }

  @action getLists = async () => {
    const params = {
      limit: this.isMobile ? TABLE_LIMIT_MOBILE : TABLE_LIMIT,
      order: this.order
    };

    Object.values(this.AnchoredLists).forEach((list) => list.getList(null, params));
  };

  @action onModalToggle = () => {
    this.isOpen = !this.isOpen;
  };

  @action onModalChange = (type) => {
    this.modalType = type;
  };

  @action handleSort = async (sort) => {
    this.order = sort;
    await this.getLists();
  };

  onEditPressed = (data) => {
    const { navigate } = this.props.router;

    const blockReason = getBlockReason(this.profile);
    if (!blockReason) {
      navigate('/user/my-resource/form', { state: { type: data.type, data: toJS(data) } });
      return;
    }

    if (this.memberOnlyFeatureModalVM) {
      this.memberOnlyFeatureModalVM.toggleModal();
    } else {
      this.memberOnlyFeatureModalVM = new MemberOnlyFeatureModalVM({ blockReason, router: this.props.router });
    }
  };

  onTogglePressed = async (item) => {
    await this.updateActive(item, item.id, !item.isActive);
    this.generalModalViewModel.open({
      title: t(`myresource_toggle_status_${!item.isActive}`)
    });
  };

  updateActive = async (item, id, isActive) => {
    try {
      const newItem = { ...item };
      const res = await ResourceService.updateActive(id, isActive);
      newItem.isActive = !item.isActive;
      this.AnchoredLists[this.type].updateItem(newItem);
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }
  };

  onScrollEnd = async () => {
    await this.AnchoredLists[this.type].getNext();
  };

  onAddResource = () => {
    const blockReason = getBlockReason(this.profile);
    if (!blockReason) {
      this.isOpen = true;
      return;
    }
    if (this.memberOnlyFeatureModalVM) {
      this.memberOnlyFeatureModalVM.isModalOpen = true;
    } else {
      this.memberOnlyFeatureModalVM = new MemberOnlyFeatureModalVM({ blockReason, router: this.props.router });
    }
  };

  // /////////////////////

  toDetail = (type, id) => {
    const { navigate } = this.props.router;
    navigate(`/resources/${type}/${id}`);
  };

  toFormPage = () => {
    const { navigate } = this.props.router;
    navigate('/user/my-resource/form', { state: { type: this.modalType } });
  };

}

export default MyResourcePageViewModel;
