import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import illustration from 'src/assets/illustrationEmail.svg';
import BaseModal from '../BaseModal';

import styles from './styles.module.scss';

@observer
class ForgetPasswordModal extends React.Component {

  constructor(props) {
    super(props);

    this.vm = props.vm;
    this.isMobile = props.isMobile;
  }

  renderContent() {
    return (
      <div className={styles.forgetPwdModal}>
        <img
          className={clsx(styles.marginBottom, styles.illustration)}
          src={illustration}
          alt="man looking at phone"
        />
        <h2 className={clsx(styles.title, styles.marginBottom)}>
          {t('forgetPassword_enterEmail_title')}
        </h2>
        <h3 className={clsx(styles.subtitle, styles.marginBottom)}>
          {t('forgetPassword_enterEmail_subtitle')}
        </h3>
        <div className={styles.inputContainer}>
          <TextInput
            placeholder={t('forgetPassword_enterEmail_inputPlaceholder')}
            error={!!this.vm.emailError}
            onChange={this.vm.onEmailChange}
            onBlur={this.vm.checkEmail}
          />
          <div className={styles.errorMessage}>
            {this.vm.emailError ? `＊ ${this.vm.emailError}` : ''}
          </div>
        </div>

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={this.vm.onSendForgetPasswordEmail}
          style={{ width: 180 }}
          loading={this.vm.isLoading}
          disabled={this.vm.timerCountdown !== null}
        >
          {t('forgetPassword_enterEmail_nextButton')}
          {this.vm.timerCountdown !== null && (
            <span className={styles.timer}>
              {`(${this.vm.timerCountdown})`}
            </span>
          )}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <BaseModal
        onClose={this.vm.toggleForgetPasswordModal}
        open={this.vm.isForgetPasswordModalOpen}
        isMobile={this.isMobile}
      >
        {this.renderContent()}
      </BaseModal>
    );
  }
}

export default withTranslation()(ForgetPasswordModal);

ForgetPasswordModal.propTypes = {
  vm: PropTypes.object.isRequired,
  isMobile: PropTypes.bool
};

ForgetPasswordModal.defaultProps = {
  isMobile: false
};
