import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { t } from 'i18next';
import Icon from 'src/components/Icon';
import Tooltip from 'antd/es/tooltip';
import iconNotice from 'src/assets/iconNotice.svg';
import iconDoubleCheckGreen from 'src/assets/iconDoubleCheckGreen.svg';
import iconCopy from 'src/assets/iconCopy.svg';

import styles from './styles.module.scss';

function OrderCard(props) {
  const {
    item,
    className
  } = props;

  const {
    product
  } = item;

  const statusTag = () => {
    if (item.isSuccess) {
      return (
        <div className={clsx(styles.orderTag, styles.success)}>
          {t('checkout_success_title')}
        </div>
      );
    }

    if (item.isFailed) {
      return (
        <div className={clsx(styles.orderTag, styles.fail)}>
          {t('checkout_fail_title')}
        </div>
      );
    }

    return (
      <div className={clsx(styles.orderTag, styles.pending)}>
        {t('order_pending')}
      </div>
    );
  };

  return (
    <div
      className={clsx(styles.cardContainer, className)}
    >
      <div className={styles.infoRow}>
        <div className={styles.title}>
          {`${t('buy')} ${product.name}`}
        </div>
        <div>
          {item.priceForDisplay}
        </div>
      </div>
      <div>
        {item.remark}
      </div>

      <div className={clsx(styles.row)}>
        <div>
          {statusTag()}
        </div>
        <div className={clsx(styles.row, styles.alignRight)}>
          <Icon
            size={20}
            svg
            className={styles.icon}
          >
            {iconDoubleCheckGreen}
          </Icon>
          <div className={clsx(styles.days, styles.green)}>
            <span>
              {t('quota_activeEvents')}
            </span>
            <span>
              {product.count}
            </span>
            <span>
              {t('quota_unit')}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.infoContainer}>
        <div className={styles.infoRow}>
          <span className={styles.infoText}>
            {t('order_created_at')}
          </span>
          <span className={styles.infoText}>
            {dayjs(item.createdAt).format('YYYY/MM/DD HH:mm')}
          </span>
        </div>

        <div className={styles.infoRow}>
          <span className={styles.infoText}>
            {t('order_id')}
          </span>
          <div className={styles.row}>
            <span className={clsx(styles.infoText, styles.orderId)}>
              {item.id}
            </span>
            <Icon
              size={20}
              svg
              onClick={() => { navigator.clipboard.writeText(item.id); }}
              className={styles.iconCopy}
            >
              {iconCopy}
            </Icon>
          </div>
        </div>
      </div>
    </div>
  );
}

OrderCard.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string
};

OrderCard.defaultProps = {
  item: {},
  className: ''
};

export default OrderCard;
