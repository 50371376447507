import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import i18n from 'src/i18n';
import ErrorService from 'src/services/errors';
import GoogleService from 'src/services/google';
import ResourceService from 'src/services/resource';

class UploadResourcePhotoVM {
  type = 'resource';
  maxCount = 5;
  @observable certs = [];
  @observable fileList = [];
  @observable googleUrls = [];

  @observable isLoading = false;

  constructor(props) {
    makeObservable(this);
  }

  beforeUpload = async (file) => {
    const isSizeValid = await this.checkImageDimension(file);
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M || !isSizeValid) {
      ErrorService.onCustomError(i18n.t('myresource_success_upload_alert'));
    }
    return isLt2M && isSizeValid;
  };

  checkImageDimension = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement('img');
        image.src = _loadedImageUrl;

        image.addEventListener('load', () => {
          const { width, height } = image;

          if (width < 200 || height < 200) {
            resolve(false);
          }

          resolve(true);
        });
      });
    });
  };


  // 需要上傳的檔案.
  @computed
  get localFiles() {
    return this.fileList.filter((item) => item.origin === 'local');
  }

  @computed
  get postList() {
    return this.fileList.map((el) => {
      if (el.origin === 'server') {
        return {
          name: el.name,
          size: el.size
        };
      }
      const item = this.results.find((o) => o.data.uid === el.uid);
      return {
        name: item.data.id,
        size: item.data.size
      };
    });
  }

  unserializeCerts = () => {
    return this.certs.map((e) => {
      const suffix = e.name.split('.')[1];
      const mimetype = (suffix === 'jpeg' || suffix === 'png' || suffix === 'jpg') ? `image/${suffix}` : 'file';

      return {
        id: e.name,
        name: e.name,
        size: e.size,
        src: e.url, // for ui
        url: e.url,
        mimetype,
        origin: 'server'
      };
    });
  };

  serializeCerts = (list) => {
    return list.map((item) => ({
      name: item.name,
      size: item.size,
      url: item.url
    }));
  };


  @action updateCerts = async () => {
    const newfiles = this.localFiles.map((item, i) => ({
      name: this.googleUrls[i].id,
      size: item.size,
      url: this.googleUrls[i].url
    }));
    const fromServer = this.fileList.filter((item) => item.origin === 'server');
    const finalList = fromServer.concat(newfiles);
    this.props.onUpdateList(this.serializeCerts(finalList));

    console.log('update', this.fileList);
  };

  // 上傳照片, 給 ant ui 使用的事件函式.
  @action onUpload = async (event) => {
    const file = event.file;
    // 自用的索引.
    const uid = String(new Date().valueOf());
    // 將檔案下載為圖片, 顯示到 ui.
    const targetFile = new FileReader();

    targetFile.addEventListener('load', (onloadEvent) => {
      this.onDownloadFile(onloadEvent, uid);
    });

    targetFile.readAsDataURL(file);
    const newData = {
      id: null,
      src: null,
      // 上傳專用.
      origin: 'local',
      // 自用的索引.
      uid,
      // google 需要的檔案.
      file,
      // 檔案大小.
      size: file.size,
      // 檔案類型.
      mimetype: file.type,
      name: file.name
    };

    this.fileList.push(newData);

    console.log('this.fileList', this.fileList);
  };

  // download for ui preview
  @action onDownloadFile = (event, uid) => {
    this.fileList = this.fileList.map((item) => {
      return item.uid === uid ? { ...item, src: event.target.result } : item;
    });
  };

  @action onRemoveFile = async (target) => {
    this.fileList = this.fileList.filter((item) => item.id !== target.id || item.uid !== target.uid);
  };

  // deserilize data
  @action onFileListUpdate = (certs) => {
    console.log('certs', certs);
    this.fileList = certs?.map((e) => {
      const suffix = e.name.split('.')[1];
      const mimetype = (suffix === 'jpeg' || suffix === 'png' || suffix === 'jpg') ? `image/${suffix}` : 'file';

      return {
        id: e.name,
        name: e.name,
        size: e.size,
        src: e.url, // for ui
        url: e.url,
        mimetype,
        origin: 'server'
      };
    });
  };

  // 準備上傳
  @action onPhotosUpload = async (rid) => {
    await this.getUploadCertsUrl(rid);
    await this.putGoogleStorageAPI();
  };

  // 跟 server 要上傳至 google 的網址.
  @action getUploadCertsUrl = async (rid) => {
    this.isLoading = true;

    const filesToUpload = this.localFiles.map((item) => {
      return {
        size: item.size,
        mimetype: item.mimetype
      };
    });

    // 有新的照片, 才索取網址.
    if (filesToUpload.length) {
      try {
        const res = await Promise.all(
          filesToUpload.map(async (item, index) => {
            return ResourceService.genFilesPresignedUrls(rid, [item]);
          })
        );
        this.googleUrls = res.map((e) => e.data.urls[0]);
        console.log('googleUrls', this.googleUrls);

      } catch (error) {
        console.log(error);
        ErrorService.onDefaultError(error);
      }
    }
  };

  // 將照片上傳至 google storage.
  @action putGoogleStorageAPI = async () => {
    try {
      const res = await Promise.all(
        this.localFiles.map(async (item, index) => {
          const id = this.googleUrls[index].id;
          const url = this.googleUrls[index].url;
          const resGoogle = await GoogleService.putGoogleStorage({
            id,
            uid: item.uid,
            url,
            mimetype: item.mimetype,
            size: item.size,
            file: item.file,
            name: item.name
          });
          console.log('--uploaded', item.name);
          return resGoogle;
        })
      );

      this.results = res;

    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        console.log('putGoogleStorage', error);
      });
    }
  };
}

export default UploadResourcePhotoVM;
