/* eslint-disable import/no-cycle */
import API from 'src/apis';
import Event from 'src/models/response/Event';
import Resource from 'src/models/response/Resource';
import RecommendResource from 'src/models/response/RecommendResource';

export default class EventService {
  static async getList(params, body) {
    const { data } = await API.event.getList(params, body);
    return {
      list: data.list.map((d) => Event.fromRes(d)),
      count: data.count,
      limit: data.limit,
      page: data.page
    };
  }

  static async detail(id) {
    const { data } = await API.event.detail(id);
    return Event.fromRes(data);
  }

  static async invite(eid, rid) {
    const { data } = await API.event.invite(eid, rid);
    return data;
  }

  static async recommentd(eid, rid) {
    const { data } = await API.admin.recommend(eid, rid);
    return data;
  }


  static async recommendResourcesList(eid, params) {
    const { data } = await API.event.recommendResourcesList(eid, params);
    return {
      list: data.list.map((d) => RecommendResource.fromRes(d)),
      anchor: data.anchor
    };
  }

  static async update(eid, body) {
    const { data } = await API.event.update(eid, body);
    return Event.fromRes(data);
  }

  static async similarEvents(eid) {
    const { data } = await API.event.similarEvents(eid);
    return data.map((d) => Event.fromRes(d));
  }

  static async create(customId) {
    const { data } = await API.event.create(customId);
    return data;
  }

  static async copy(eventId) {
    const { data } = await API.event.copy(eventId);
    return Event.fromRes(data);
  }

  static async genFilesPresignedUrls(id, files) {
    try {
      return await API.event.genFilesPresignedUrls(id, files);
    } catch (err) {
      return err;
    }
  }

  static async submit(eventId, body) {
    const { data } = await API.event.submit(eventId, body);
    return data;
  }

  static async delete(eventId) {
    const { data } = await API.event.deleteEvent(eventId);
    return data;
  }

  static async updateEditing(eventId, body) {
    const { data } = await API.event.updateEditing(eventId, body);
    return data;
  }

  static async getEditing(eventId, params) {
    const { data } = await API.event.getEditing(eventId, params);
    return data;
  }


}
