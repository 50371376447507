import { request, getHost, getHeader } from './utils';

export const getList = (params, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/event/list`,
    headers: getHeader(),
    params,
    data
  };
  return request(options);
};

export const detail = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/event/${id}/detail`,
    headers: getHeader()
  };
  return request(options);
};

export const invite = (eid, rid) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/event/${eid}/invite`,
    headers: getHeader(),
    data: {
      resourceId: rid
    }
  };
  return request(options);
};

export const recommendResourcesList = (eid, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/event/${eid}/recommend`,
    headers: getHeader(),
    params
  };
  return request(options);
};

export const update = (eid, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/v1/event/${eid}`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const similarEvents = (eid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/event/${eid}/similar`,
    headers: getHeader()
  };
  return request(options);
};

export const create = (customId) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/event/create`,
    headers: getHeader(),
    data: {
      customId
    }
  };
  return request(options);
};

export const copy = (eventId) => {

  const options = {
    method: 'post',
    url: `${getHost()}/v1/event/${eventId}/copy`,
    headers: getHeader()
  };
  return request(options);
};

export const genFilesPresignedUrls = (eventId, files) => {

  const options = {
    method: 'post',
    url: `${getHost()}/v1/event/${eventId}/files`,
    headers: getHeader(),
    data: {
      files
    }
  };
  return request(options);
};

export const submit = (eventId, data) => {

  const options = {
    method: 'post',
    url: `${getHost()}/v1/event/${eventId}/submit`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const deleteEvent = (eventId) => {

  const options = {
    method: 'delete',
    url: `${getHost()}/v1/event/${eventId}`,
    headers: getHeader()
  };
  return request(options);
};

// 更新編輯中狀態
export const updateEditing = (eventId, data) => {

  const options = {
    method: 'put',
    url: `${getHost()}/v1/event/${eventId}/editing`,
    headers: getHeader(),
    data
  };
  return request(options);
};

// 取得編輯中狀態
export const getEditing = (eventId, params) => {

  const options = {
    method: 'get',
    url: `${getHost()}/v1/event/${eventId}/editing`,
    headers: getHeader(),
    params
  };
  return request(options);
};
