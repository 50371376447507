import React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import dayjs from 'dayjs';
import scrollDetector from 'src/stores/scrollDetector';

import { CREATE_EVENT_STEPS, RESOURCE_TYPES } from 'src/constants';
// icons
import iconVisible from 'src/assets/iconPreviewEye.svg';
import iconInvisible from 'src/assets/iconPreviewEyeClosed.svg';
import iconNewTab from 'src/assets/iconSignOut.svg';
//
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Button from 'src/components/Button';
import Loading from 'src/components/Loading';
import Icon from 'src/components/Icon';
import GeneralModal from 'src/components/Modals/GeneralModal';
import SideMenu from './components/SideMenu';
import BottomBar from './components/BottomBar';
import Basic from './components/Step/Basic';
import KeyFinding from './components/Step/KeyFinding';
import Target from './components/Step/Target';
import Vision from './components/Step/Vision';
import KeyItem from './components/Step/KeyItem';
import Features from './components/Step/Features';
import Demand from './components/Step/Demand';
import Faqs from './components/Step/Faqs';
import Registration from './components/Step/Registration';
import Submit from './components/Step/Submit';
import Title from './components/Title';
import Subtitle from './components/Subtitle';
import MenuSelect from './components/MenuSelect';
import Preview from './components/Preview';
import Done from './components/Done';
import ReviewTextModal from './components/ReviewTextModal';

import CreateEventPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class CreateEventPage extends React.Component {
  titleAreaRef = React.createRef();

  constructor() {
    super();

    this.vm = new CreateEventPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentWillUnmount() {
    this.vm.willUnMount();
  }

  componentDidUpdate() {
    setTimeout(() => {
      runInAction(() => {
        this.vm.titleAreaHeight = this.titleAreaRef.current?.offsetHeight;
      });
    }, 100);
  }

  renderContent() {
    if (this.vm.isSubmitted) {
      return (
        <div className={styles.contentWrapper}>
          <Done
            point={1}
            eventName={this.vm.event.name}
            releaseMethodUIString={this.vm.releaseMethodUIString}
            onButtonClick={this.vm.toEventHosted}
          />
        </div>
      );
    }

    return (
      <div className={styles.contentWrapper}>
        {!this.vm.isMobile && (
          <SideMenu
            currentStep={this.vm.step}
            onClickItem={this.vm.onSwitchStep}
            isDone={this.vm.isDone}
            isEventCreated={this.vm.isEventCreated}
            progress={this.vm.progress}
            phase={this.vm.progressPhase}
            isVisionDone={!!this.vm.event?.vision?.description}
          />
        )}
        {this.renderMainContent()}
      </div>
    );
  }

  renderMainContent() {

    return (
      <div className={styles.mainContent}>
        <div
          key={this.vm.step}
          className={styles.stepWrapper}
        >
          <div
            className={clsx(styles.stickyTitleAndSubtitle, !scrollDetector.isScrollingDown && styles.lower)}
            ref={this.titleAreaRef}
          >
            {this.vm.isMobile
              ? (
                <MenuSelect
                  step={this.vm.step}
                  onSwitchStep={this.vm.onSwitchStep}
                  isDone={this.vm.isDone}
                  isEventCreated={this.vm.isEventCreated}
                  isVisionDone={!!this.vm.event?.vision?.description}
                />
              )
              : <Title step={this.vm.step} />}

            {this.vm.editingPeopleCounts[this.vm.step]
              ? (
                <div className={styles.otherEditing}>
                  <span className={styles.underline}>{t('create_event_other_editing_hint_1')}</span>
                  <span>{t('create_event_other_editing_hint_2')}</span>
                </div>
              )
              : null}

            <Subtitle
              step={this.vm.step}
              key={this.vm.step}
            />
          </div>

          {this.renderStepContent()}
          {(this.vm.showPreview && this.vm.previewVM) && (
            <Preview
              vm={this.vm.previewVM}
              demandId={this.vm.anyDemandId}
            />
          )}
        </div>

        {this.vm.updatedAt ? (
          <div className={styles.autoSaveHintRow}>
            <div className={styles.autoSaveHint}>
              {`${t('create_event_auto_save_hint')} ${dayjs(this.vm.updatedAt).format('MM/DD HH:mm')}`}
            </div>
            <div className={styles.tipPlaceholder} />
          </div>
        ) : null}

      </div>
    );
  }

  renderStepContent = () => {
    const props = {
      vm: this.vm
    };

    switch (this.vm.step) {
      case CREATE_EVENT_STEPS.Basic:
        return <Basic {...props} />;
      case CREATE_EVENT_STEPS.KeyFinding:
        return <KeyFinding {...props} />;
      case CREATE_EVENT_STEPS.Target:
        return <Target {...props} />;
      case CREATE_EVENT_STEPS.Vision:
        return <Vision {...props} />;
      case CREATE_EVENT_STEPS.KeyItem:
        return <KeyItem {...props} />;
      case CREATE_EVENT_STEPS.Features:
        return <Features {...props} />;
      case CREATE_EVENT_STEPS.Demand:
        return <Demand {...props} />;
      case CREATE_EVENT_STEPS.Faqs:
        return <Faqs {...props} />;
      case CREATE_EVENT_STEPS.Registration:
        return <Registration {...props} />;
      case CREATE_EVENT_STEPS.Submit:
        return <Submit {...props} />;
      default:
        return null;
    }
  };

  renderBreadcrumbRightArea = () => {
    if (!this.vm.isEventCreated) return null;

    return (
      <div className={styles.rightAreaContainer}>
        {!this.vm.isMobile && (
          <Button
            type="link"
            className={clsx(styles.button, styles.togglePreviewButton)}
            icon={(
              <Icon
                size={24}
                svg
                style={{ cursor: 'pointer' }}
              >
                {this.vm.showPreview ? iconInvisible : iconVisible}
              </Icon>
            )}
            onClick={this.vm.togglePreview}
          >
            {this.vm.showPreview ? t('button_toggle_preview_off') : t('button_toggle_preview_on')}
          </Button>
        )}

        <Link
          to={this.vm.previewUrl}
          target="_blank"
          onClick={() => this.vm.sendGAEvent('Open_Event_Editing_Preview_Blank')}
        >
          <Button
            type="link"
            className={styles.button}
            icon={(
              <Icon
                size={24}
                svg
                style={{ cursor: 'pointer' }}
              >
                {iconNewTab}
              </Icon>
            )}
          >
            {this.vm.isMobile ? t('button_toggle_preview_on') : t('button_open_in_new_tab')}
          </Button>
        </Link>
      </div>
    );
  };

  render() {
    const rightArea = this.renderBreadcrumbRightArea();

    return (
      <div className={styles.createEventPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideFooter
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_event'), path: '/create-event-overview' },
              { label: t('breadcrumb_create_event'), path: '' }
            ]}
            right={this.vm.isSubmitted ? null : rightArea}
            withHeader
          />
          {this.renderContent()}

          {!this.vm.isSubmitted && (
            <BottomBar
              avatar={this.vm.profile?.avatar}
              sloganForDisplay={this.vm.sloganForDisplay}
              submitButtonProps={{
                disabled: this.vm.isSubmitButtonDisable,
                text: this.vm.submitButtonText,
                onClick: this.vm.onClickSubmitButton
              }}
              nextStepButtonProps={{
                disabled: !this.vm.isEventCreated,
                onClick: this.vm.onClickNextStep,
                isHidden: this.vm.isSubmitStep
              }}
            />
          )}

        </MainLayout>

        {(this.vm.event && Object.values(this.vm.event)?.length !== 0 && !this.vm.isSubmitButtonDisable) && (
          <ReviewTextModal
            event={this.vm.event}
            eventId={this.vm.customId}
            demands={{
              service: this.vm.demands[RESOURCE_TYPES.Service].map((dvm) => dvm.demand),
              funds: this.vm.demands[RESOURCE_TYPES.Funds].map((dvm) => dvm.demand),
              space: this.vm.demands[RESOURCE_TYPES.Space].map((dvm) => dvm.demand)
            }}
            toggleReviewTextModal={this.vm.toggleReviewTextModal}
            isReviewTextModalOpen={this.vm.isReviewTextModalOpen}
            isMobile={this.vm.isMobile}
            onSubmit={this.vm.onSubmit}
            onSwitchStep={this.vm.onSwitchStep}
            releaseMethodUIString={this.vm.releaseMethodUIString}
            isLoading={this.vm.isLoading}
          />
        )}

        { this.vm.isLoading && <Loading /> }

        {this.vm.generalModalViewModel && (
          <GeneralModal
            vm={this.vm.generalModalViewModel}
            isMobile={this.vm.isMobile}
            height={440}
          />
        )}
      </div>
    );
  }
}

CreateEventPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(CreateEventPage)), true);
