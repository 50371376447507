import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import clsx from 'clsx';
import { t } from 'i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'src/components/StrictModeDroppable';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import withErrorInput from 'src/components/withErrorInput';
import Icon from 'src/components/Icon';
import iconAdd from 'src/assets/iconAddPurple.svg';
import iconDrag from 'src/assets/iconDrag.svg';
import iconCloseRed from 'src/assets/iconCloseRed.svg';
import styles from '../../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);

@observer
class Field extends React.Component {

  @observable showError = {
    label: false,
    type: false,
    options: false
  };

  @observable isNew = true;

  constructor(props) {
    super();
    makeObservable(this);
    this.props = props;
  }

  fieldTypesOptions = [
    { value: 'text', label: t('application_other_text') },
    { value: 'single', label: t('application_single_select') },
    { value: 'multiple', label: t('application_multiple_select') }
  ];

  @action toggleShowError = (shouldShow) => {
    const { item } = this.props;
    if (shouldShow) {
      if (!item.label) {
        this.showError.label = true;
      }

      if (!item.type) {
        this.showError.type = true;
      }

      if (item.type !== 'text' && (item.options?.length === 0 || (item.options?.every((v) => !v)))) {
        this.showError.options = true;
      }
    } else {
      this.showError.label = false;
      this.showError.type = false;
      this.showError.options = false;
    }

  };

  renderOptions = (item) => {
    return (
      <div className={styles.field}>
        <h5>
          {t('options')}
        </h5>

        <DragDropContext
          onDragEnd={item.onDragEnd}
        >
          <StrictModeDroppable droppableId={`drop_${item.optionsWithId.length}`}>

            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {item.optionsWithId.map((opt, i) => (
                  <Draggable draggableId={`draggable_${opt.id}`} index={i} key={opt.id}>
                    {(providedDra) => (
                      <div
                        {...providedDra.draggableProps}
                        {...providedDra.dragHandleProps}
                        ref={providedDra.innerRef}
                        className={styles.draggableOpt}
                      >
                        {item.isEditing && (
                          <Icon
                            size={20}
                            svg
                            className={styles.iconDrag}
                          >
                            {iconDrag}
                          </Icon>
                        )}
                        <ErrorTextInput
                          key={`option_${opt.id}`}
                          label={t('option_name')}
                          onChange={(e) => item.onChangeOption(e.target.value, i)}
                          value={opt.value}
                          required
                          showError={this.showError.options && !opt.value}
                          readOnly={!item.isEditing}
                        />
                        {item.isEditing && (
                          <Icon
                            size={20}
                            svg
                            className={styles.iconClose}
                            onClick={() => item.onRemoveOption(opt.id)}
                          >
                            {iconCloseRed}
                          </Icon>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>

          {item.isEditing && (
            <Button
              type="text"
              onClick={() => item.addOption('')}
              size="small"
              className={styles.addOptionButton}
            >
              <div className={clsx(styles.flexRowCenter, styles.borderlessBtnText)}>
                <Icon
                  size={18}
                  svg
                >
                  {iconAdd}
                </Icon>
                {t('button_add_option')}
              </div>
            </Button>
          )}

        </DragDropContext>
      </div>
    );
  };

  onClickRemove = () => {
    const { item, onSave, onRemove, generalModalViewModel } = this.props;
    const onClick = () => {
      onRemove();
      onSave();
    };

    if (generalModalViewModel) {
      generalModalViewModel.open({
        title: item.label ? `${t('modal_remove_event_item_confirm_1')} ${item.label} ${t('modal_remove_event_item_confirm_2')}` : t('modal_remove_registration_field'),
        onConfirm: onClick,
        showCancelButton: true
      });
    } else {
      onClick();
    }
  };

  render() {
    const { item, onSave, onRemove } = this.props;
    const {
      type,
      label
    } = item;

    return (
      <div className={styles.fieldContainer}>
        <div className={styles.field}>
          <h5>{t('registrationField_label')}</h5>
          <ErrorTextInput
            key="text_field"
            label={label ? null : t('registrationField_label')}
            onChange={(e) => {
              this.toggleShowError(false);
              item.onChange(e.target.value, 'label');
            }}
            value={label ?? ''}
            required
            showError={this.showError.label}
            readOnly={!item.isEditing}
          />
        </div>

        <div className={styles.field}>
          <h5>{t('registrationField_type')}</h5>
          <ErrorSelect
            key="field_type"
            placeholder={t('registrationField_type')}
            onChange={(value) => {
              this.toggleShowError(false);
              item.onChange(value, 'type');
              if ((value === 'single' || value === 'multiple') && item.options.length === 0) {
                item.addOption();
              }
            }}
            options={this.fieldTypesOptions}
            value={type}
            required
            showSearch
            withoutLabel
            showError={this.showError.type}
            readOnly={!item.isEditing}
          />
        </div>

        {(type === 'single' || type === 'multiple') && this.renderOptions(item)}

        {item.isEditing
          ? (
            <div className={styles.fieldButtons}>
              <Button
                type="text"
                onClick={this.onClickRemove}
                size="small"
              >
                <div className={clsx(styles.flexRowCenter, styles.borderlessBtnText)}>
                  {this.isNew ? t('cancel') : t('remove')}
                </div>
              </Button>

              <Button
                type="text"
                onClick={() => {
                  this.toggleShowError(true);
                  console.log(Object.values(this.showError).find((v) => !!v), item.isComplete);
                  if (Object.values(this.showError).find((v) => !!v)) {
                    return;
                  }
                  if (item.isComplete) {
                    item.toggleEdit();
                    item.removeEmptyOptions();
                    onSave();
                  }
                }}
                size="small"
              >
                <div className={clsx(styles.flexRowCenter, styles.borderlessBtnText)}>
                  {this.isNew ? t('confirm_and_add') : t('save')}
                </div>
              </Button>
            </div>
          )
          : (
            <div className={styles.fieldButtons}>
              <Button
                type="text"
                onClick={this.onClickRemove}
                size="small"
              >
                <div className={clsx(styles.flexRowCenter, styles.borderlessBtnText)}>
                  {t('remove')}
                </div>
              </Button>

              <Button
                type="text"
                onClick={() => {
                  item.toggleEdit();
                  runInAction(() => {
                    this.isNew = false;
                  });
                }}
                size="small"
              >
                <div className={clsx(styles.flexRowCenter, styles.borderlessBtnText)}>
                  {t('edit')}
                </div>
              </Button>
            </div>
          )}
      </div>
    );
  }
}

Field.propTypes = {
  item: PropTypes.object,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  generalModalViewModel: PropTypes.object
};

Field.defaultProps = {
  item: {},
  onSave: () => {},
  onRemove: () => {},
  generalModalViewModel: null
};

export default Field;
