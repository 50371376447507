function parseLanguage(language) {
  switch (language) {
    case 'zh-TW':
    case 'zh':
    case 'zh_CN':
    case 'zh-HK':
      return 'zh-TW';
    default:
      return 'en-US';
  }
}


export {
  parseLanguage
};
