import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';
import { ORG_TYPES_DONT_NEED_PROPS, USER_TYPES } from 'src/constants';
import IS from 'src/utils/is';
import Info from 'src/models/response/Profile/Info';
import Contact from 'src/models/response/Profile/Contact';

class Organization extends Info {
  @observable type;
  @observable capitalAmount;
  @observable taxId;
  @observable job;
  @observable foundedIn;
  @observable staff;
  @observable location;
  @observable address;
  @observable contact;
  @observable certs = [];
  @observable introduction;
  @observable personInCharge = {};

  constructor(params) {
    super(params);
    makeObservable(this);
    this.deserialize(params);
  }

  get needStaff() {
    return !ORG_TYPES_DONT_NEED_PROPS.Staff.includes(this.type);
  }

  get needCapitalAmount() {
    return !ORG_TYPES_DONT_NEED_PROPS.CapitalAmount.includes(this.type);
  }

  @action onContactChange = (key, value) => {
    console.log('..on change contact');
    if (this.contact?.onChange) {
      this.contact?.onChange(key, value);
    }
  };

  @computed
  get isTaxIdValid() {
    return IS.taxId(this.taxId);
  }

  @computed
  get isIntroductionValid() {
    return this.introduction && IS.orgIntroduction(this.introduction);
  }

  // ///////////////////////////////////

  static fromRes(data) {
    return new Organization(data);
  }

  @action deserialize = (params) => {
    const {
      type,
      capitalAmount,
      taxId,
      job,
      foundedIn,
      staff,
      location,
      address,
      contact,
      certs,
      introduction,
      personInCharge
    } = params ?? {};

    this.capitalAmount = capitalAmount;
    this.taxId = taxId;
    this.job = job;
    this.foundedIn = foundedIn ? dayjs(foundedIn).format('YYYY-MM-DD') : null;
    this.staff = staff;
    this.contact = Contact.fromRes(contact);
    this.certs = certs ?? [];
    this.location = location;
    this.address = address;
    this.type = type;
    this.introduction = introduction;
    this.personInCharge = personInCharge ?? {};
  };

  serialize = (isReviewed = false) => {
    const {
      job,
      type,
      capitalAmount,
      staff,
      location,
      address,
      contact,
      certs,
      motto,
      sdgs,
      cities,
      socialsObj,
      personInCharge,
      introduction,
      foundedIn,
      taxId
    } = this;

    const extra = {
      location,
      address,
      certs,
      foundedIn,
      taxId
    };

    const base = {
      job,
      type,
      capitalAmount,
      staff,
      contact: contact.serialize(),
      motto,
      sdgs,
      cities,
      socials: socialsObj.serialize(),
      personInCharge,
      introduction
    };

    if (isReviewed) {
      return base;
    }

    return {
      ...extra,
      ...base
    };
  };
}

export default Organization;
