import API from 'src/apis';
import Demand from 'src/models/response/Demand';

export default class DemandService {
  static async getList(type, eventId) {
    const { data } = await API.demand.getList(type, eventId);
    return data.list.map((d) => Demand.fromRes(d));
  }

  static async detail(id) {
    const { data } = await API.demand.detail(id);
    return Demand.fromRes(data);
  }

  static async create(body) {
    const { data } = await API.demand.create(body);
    return Demand.fromRes(data);
  }

  static async update(id, body) {
    const { data } = await API.demand.update(id, body);
    return Demand.fromRes(data);
  }

  static async delete(id) {
    const res = await API.demand.deleteDemand(id);
    return res;
  }
}
