export default class User {
  constructor(params) {
    const {
      id,
      type,
      status,
      name,
      gender,
      avatar,
      displayName,
      blueTickAt
    } = params ?? {};

    this.id = id;
    this.type = type;
    this.status = status;
    this.name = name;
    this.avatar = avatar;
    this.gender = gender;
    this.displayName = displayName;
    this.blueTickAt = blueTickAt;
  }


  static fromRes(data) {
    return new User(data);
  }
}
