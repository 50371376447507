import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ConfigProvider from 'antd/lib/config-provider';
import AntCheckbox from 'antd/lib/checkbox';
import styles from './styles.module.scss';

function Checkbox(props) {
  const { size, onChange, children, className, ...ps } = props;


  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#414EE1',
          controlInteractiveSize: size,
          borderRadius: 20
        }
      }}
    >
      <AntCheckbox
        onChange={onChange}
        className={clsx('customCheckbox', styles.checkbox, className)}
        {...ps}
      >
        {children}
      </AntCheckbox>
    </ConfigProvider>
  );
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.any
  ]),
  size: PropTypes.number
};

Checkbox.defaultProps = {
  onChange: () => {},
  children: null,
  className: null,
  size: 20
};

export default Checkbox;
