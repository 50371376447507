import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import iconArrow from 'src/assets/iconArrow.svg';
import Icon from 'src/components/Icon';


import i18n from 'src/i18n';

import styles from './styles.module.scss';
import ParentNode from './components/ParentNode';
import ChildNode from './components/ChildNode';

const theme = createTheme({
  palette: {
    primary: {
      main: '#414EE1'
    }
  }
});

@observer
class TreeSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  renderTags() {
    const { placeholder, vm, required, type, showInputForOthers } = this.props;

    return (
      vm.isEmpty ? (
        <div className={clsx(required && styles.requiredMark)}>
          {placeholder}
        </div>
      ) : (
        <div>
          {Object.entries(vm.groupedItems).map((el) => {

            return (
              <div key={el[0]}>
                {!!el[0]
                  && (
                    <div className={styles.parentTag}>
                      {el[0]}
                      {type === 'sdgs' && (
                        <span className={`sdgHeart${vm.getSdgTypeKey(el[0])}`}>♥</span>
                      )}
                    </div>
                  )}

                {
                  el[1].map((child, index) => {
                    return (
                      <span key={child.value}>
                        <span key={child.value} className={styles.childLabel}>
                          {`${child.label}${child.isFieldNamedOthers && child.value[0] === '#' ? `(${child.value.slice(1)})` : ''}`}
                        </span>
                        {index < el[1].length - 1 && (
                          <span className={styles.dot}>, </span>
                        )}
                      </span>
                    );
                  })
                }
              </div>
            );
          })}
        </div>
      )
    );
  }

  render() {
    const { required, placeholder, vm, error, showInputForOthers } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <List
          className={clsx(styles.block, vm.open && styles.open, error && styles.errorBorder)}
        >
          {(vm.open || !vm.isEmpty)
            && (
              <div className={clsx(styles.tag, required && styles.labelRequiredMark)}>
                {placeholder}
              </div>
            )}

          <ListItem
            style={{ paddingLeft: 14, cursor: 'pointer' }}
            secondaryAction={(
              <Icon
                size={16}
                svg
                style={{
                  transition: 'all 0.3s',
                  transform: vm.open ? 'rotate(180deg)' : 'unset',
                  marginRight: 4,
                  marginTop: vm.open ? 0 : 5,
                  verticalAlign: 'top'
                }}
              >
                {iconArrow}
              </Icon>
            )}
            onClick={vm.applyFilter}
          >
            {!vm.open
              ? (this.renderTags())
              : (
                <TextField
                  variant="standard"
                  value={vm.searchText}
                  onChange={vm.onInputChange}
                  className={styles.searchText}
                  onClick={(e) => e.stopPropagation()}
                  InputProps={{
                    disableUnderline: true // <== added this
                  }}
                />
              )}
          </ListItem>
        </List>

        {vm.open
          && (
            <List
              style={{ marginTop: 20 }}
              className={clsx(styles.block)}
            >
              <div>
                {
                  vm.data.map((el) => {
                    if (el.children) {
                      return <ParentNode key={el.label} vm={el} searchText={vm.searchText} showInputForOthers={showInputForOthers} />;
                    }
                    return <ChildNode key={el.label} vm={el} searchText={vm.searchText} showInputForOthers={showInputForOthers} />;
                  })
                }

                <div
                  className={styles.btnContainer}
                >
                  <div className={styles.btnContainer}>
                    <div
                      className={styles.btn1}
                      onClick={vm.selectAll}
                    >
                      {i18n.t('tree_select_all')}
                    </div>

                    <div className={styles.separator} />

                    <div
                      className={styles.btn1}
                      onClick={vm.clearAll}
                    >
                      {i18n.t('tree_clear_all')}
                    </div>
                  </div>

                  <div
                    className={styles.btn2}
                    onClick={vm.applyFilter}
                  >
                    {i18n.t('tree_apply')}
                  </div>
                </div>
              </div>
            </List>
          )}
      </ThemeProvider>
    );
  }
}

export default TreeSelect;

TreeSelect.propTypes = {
  vm: PropTypes.object,
  showInputForOthers: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.bool
};

TreeSelect.defaultProps = {
  vm: {},
  showInputForOthers: false,
  required: false,
  placeholder: null,
  type: null,
  error: false
};
