import React from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import BaseModal from 'src/components/Modals/BaseModal';
import Icon from 'src/components/Icon';
import TreeSelect from 'src/components/TreeSelect';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import iconFilter from 'src/assets/iconFilter.svg';

import { RESOURCE_TYPES } from 'src/constants';

import i18n from 'src/i18n';
import { Observer } from 'mobx-react';
import styles from './styles.module.scss';

export default function ResourceFilter(props) {

  const { vm } = props;

  return (
    <Observer>
      {() => (
        <BaseModal
          onClose={vm.toggleModal}
          open={vm.isModalOpen}
          isMobile={vm.isMobile}
          centered
          height={420}
        >
          {/* <ResourceFilter vm={this.vm} filter={this.vm.filter} /> */}
          <div className={styles.filterModal}>
            <div className={clsx(styles.flexRow, styles.top)}>
              <Icon
                size={24}
                svg
                style={{ filter: 'invert(1)' }}
              >
                {iconFilter}
              </Icon>
              <div className={styles.title}>Filter by</div>
            </div>

            <div className={styles.inputs}>
              {
                vm.type === RESOURCE_TYPES.Service && (
                  <>
                    <TreeSelect
                      placeholder={t('resource_filter_service_type')}
                      type="type"
                      vm={vm.servicesTypesSelectViewModel}
                    />

                    <div style={{ height: 20 }} />

                    <TreeSelect
                      placeholder={t('resource_filter_jobs')}
                      type="jobs"
                      vm={vm.jobsSelectViewModel}
                    />

                    <div style={{ height: 20 }} />

                    <TreeSelect
                      placeholder={t('resource_filter_seniority')}
                      type="seniority"
                      vm={vm.senioritySelectViewModel}
                    />
                  </>
                )
              }
              {
                vm.type === RESOURCE_TYPES.Funds && (
                  <>
                    <TreeSelect
                      placeholder={t('resource_filter_funds_type')}
                      type="type"
                      vm={vm.fundsTypeSelectViewModel}
                    />

                    <div style={{ height: 20 }} />


                    <div className={styles.flexRow}>
                      <TextInput
                        type="number"
                        placeholder={t('resource_filter_funds_min_quota')}
                        onChange={(e) => vm.onFilterChange('minQuota', e.target.value)}
                        value={vm.filter.funds?.minQuota ?? ''}
                      />
                      <div className={styles.rangeIcon} />
                      <TextInput
                        type="number"
                        placeholder={t('resource_filter_funds_max_quota')}
                        onChange={(e) => vm.onFilterChange('maxQuota', e.target.value)}
                        value={vm.filter.funds?.maxQuota ?? ''}
                      />
                    </div>


                  </>
                )
              }
              {
                vm.type === RESOURCE_TYPES.Space && (
                  <>
                    <TreeSelect
                      placeholder={t('resource_filter_usage')}
                      type="usages"
                      vm={vm.usagesSelectViewModel}
                    />

                    <div style={{ height: 20 }} />

                    <div className={styles.flexRow}>
                      <TextInput
                        type="number"
                        placeholder={t('resource_filter_space_min_size')}
                        onChange={(e) => vm.onFilterChange('minSize', e.target.value)}
                        value={vm.filter.space?.minSize ?? ''}
                      />
                      <div className={styles.rangeIcon} />
                      <TextInput
                        type="number"
                        placeholder={t('resource_filter_space_max_size')}
                        onChange={(e) => vm.onFilterChange('maxSize', e.target.value)}
                        value={vm.filter.space?.maxSize ?? ''}
                      />
                    </div>

                    <div style={{ height: 20 }} />

                    <div className={styles.flexRow}>
                      <TextInput
                        type="number"
                        placeholder={t('resource_filter_space_min_capacity')}
                        onChange={(e) => vm.onFilterChange('minCap', e.target.value)}
                        value={vm.filter.space?.minCap ?? ''}
                      />
                      <div className={styles.rangeIcon} />
                      <TextInput
                        type="number"
                        placeholder={t('resource_filter_space_max_capacity')}
                        onChange={(e) => vm.onFilterChange('maxCap', e.target.value)}
                        value={vm.filter.space?.maxCap ?? ''}
                      />
                    </div>
                  </>
                )
              }

              <div style={{ height: 20 }} />

              <TreeSelect
                placeholder={t('filter_regions')}
                type="regions"
                vm={vm.regionsSelectViewModel}
              />

              <div style={{ height: 20 }} />

              <TreeSelect
                placeholder="SDGs"
                type="sdgs"
                vm={vm.sdgsSelectViewModel}
              />
            </div>


            <div className={clsx(styles.flexRow, styles.buttons)}>
              <Button
                type="default"
                className={styles.resetBtn}
                onClick={() => vm.resetFilter()}
              >
                {i18n.t('clear_filter')}
              </Button>

              <Button
                className={styles.applyBtn}
                onClick={vm.applyFilter}
                withRightArrow
              >
                {i18n.t('apply_filter')}
              </Button>
            </div>
          </div>
        </BaseModal>
      )}
    </Observer>
  );
}


ResourceFilter.propTypes = {
  vm: PropTypes.object
};

ResourceFilter.defaultProps = {
  vm: {}
};
