import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Upload from 'antd/es/upload';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';

import i18n from 'src/i18n';
import Icon from 'src/components/Icon';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import fakeFile from 'src/assets/file.png';
import iconUneditable from 'src/assets/iconUneditable.svg';
import iconDelete from 'src/assets/iconDelete.svg';
import iconUpload from 'src/assets/iconUpload.svg';

import styles from './styles.module.scss';

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '--- Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

@observer
class UploadedItem extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
  }

  renderThumbnail = (type, src) => {
    if (type?.includes('image')) {
      if (src) {
        return (
          <div
            style={{ backgroundImage: `url(${src})` }}
            className={styles.thumbnail}
          />
        );
      }

      return (
        <div className={clsx(styles.thumbnail, styles.thumbnailLoading)}>
          <Spin />
        </div>
      );
    }

    return (
      <div
        style={{ backgroundImage: `url(${fakeFile})` }}
        className={styles.thumbnail}
      />
    );
  };

  render() {
    const { readOnly, item, onRemoveFile } = this.props;
    if (!item) return null;

    return (
      <div
        className={styles.certItem}
        key={item.id || item.uid || 'fakeId'}
      >
        {this.renderThumbnail(item.mimetype, item.src)}
        <div className={styles.certContent}>
          <div className={styles.name}>
            {item.name}
          </div>
          <div className={styles.size}>
            {formatBytes(item.size)}
          </div>
        </div>

        {readOnly
          ? (
            <Icon
              size={24}
              svg
              className={styles.iconUneditable}
            >
              {iconUneditable}
            </Icon>
          )
          : (
            <Icon
              size={24}
              svg
              onClick={() => onRemoveFile(item)}
              className={styles.deleteIcon}
            >
              {iconDelete}
            </Icon>
          )}

      </div>
    );
  }
}

UploadedItem.propTypes = {
  readOnly: PropTypes.bool,
  item: PropTypes.object,
  onRemoveFile: PropTypes.func
};

UploadedItem.defaultProps = {
  readOnly: false,
  item: null,
  onRemoveFile: () => {}
};

export default UploadedItem;
