import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import dayjs from 'dayjs';
import IS from 'src/utils/is';
import ConstantsStore from 'src/stores/constants';
import UserService from 'src/services/user';
import RegisterService from 'src/services/register';
import EventService from 'src/services/event';
import DemandService from 'src/services/demand';
import { USER_TYPES, SOCIALS_TYPE } from 'src/constants';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';

import ErrorService from 'src/services/errors';
import i18n from 'src/i18n';

const lineUrl = 'https://line.me/ti/p/~';

class SignUpForEventPageViewModel {
  STEPS = {
    Intro: 'intro',
    Detail: 'detail',
    Success: 'success'
  };

  PersonalSocials = [
    SOCIALS_TYPE.Facebook,
    SOCIALS_TYPE.Instagram,
    SOCIALS_TYPE.Line,
    SOCIALS_TYPE.Linkedin
  ];
  OrganizationSocials = [
    SOCIALS_TYPE.Influence,
    SOCIALS_TYPE.Facebook,
    SOCIALS_TYPE.Instagram,
    SOCIALS_TYPE.Line,
    SOCIALS_TYPE.Linkedin,
    SOCIALS_TYPE.Website,
    SOCIALS_TYPE.Youtube
  ];

  SIGN_UP_PERSONAL = [
    'socials',
    'birthday',
    'gender',
    'jobs',
    'company',
    'jobTitle',
    'cities',
    'cityOfResidence',
    'cityOfBirth'
  ];

  SIGN_UP_ORG = [
    'socials',
    'foundedIn',
    'job',
    'type',
    'cities',
    'location'
  ];

  @observable step = this.STEPS.Intro;
  @observable eid = null; // event id
  @observable did = null; // demand id
  @observable rid = null; // resource id
  @observable user = null;
  @observable event = null;
  @observable demandRule = null;
  @observable demandName = null;

  @observable registrationRequires = {};

  @observable additionalFields = {};

  @observable identity;
  @observable displayName;
  @observable email;
  @observable countryCode = '+886';
  @observable phone9Digit = '';
  @observable phone;
  @observable motivation = null;
  @observable cognition = null;
  @observable cities = [];
  // personal
  @observable birthday;
  @observable gender;
  @observable jobs;
  @observable company;
  @observable jobTitle;
  @observable cityOfResidence;
  @observable cityOfBirth;
  // organization
  @observable job;
  @observable foundedIn;
  @observable type;
  @observable location;
  // social
  @observable socials = [];
  @observable facebook = null;
  @observable instagram = null;
  @observable lineId = null;
  @observable linkedin = null;
  @observable website = null;
  @observable influence = null;

  @observable emailError = null;
  @observable isLoading = false;
  @observable isChecked = false;
  @observable hasClickedRuleMore = false;
  @observable isMobile = false;

  @observable showError = false;

  // TreeSelect
  jobsSelectViewModel;
  citiesSelectViewModel;

  constructor(props) {
    makeObservable(this);
    this.props = props;
    runInAction(() => {
      this.isMobile = props.context.state.isMobile;
    });
  }

  @action
    didMount = async (props) => {
      window.scroll(0, 0);
      console.log('SignUpForEventPage.didMount, params', props.router.params);
      const { eid, did, rid } = props.router.params;
      this.eid = eid;
      this.did = did;
      this.rid = rid;
      await Promise.all([
        this.getUserDetail(),
        this.getEventDetail()
      ]);

      if (this.did) {
        await this.getDemandRule();
      }

      this.jobsSelectViewModel = new TreeSelectViewModel(ConstantsStore.jobsOptionsForUser);
      this.jobsSelectViewModel.initCheckState(this.jobs ?? []);

      this.citiesSelectViewModel = new TreeSelectViewModel(ConstantsStore.regionsOptions);
      this.citiesSelectViewModel.initCheckState(this.cities ?? []);
    };

  @action
    getUserDetail = async () => {
      try {
        const res = await UserService.getDetail();
        runInAction(() => {
          this.user = res;
          this.deserializeUser(res);
        });
      } catch (error) {
        ErrorService.onDefaultError(error);
      }
    };

  getEventDetail = async () => {
    const res = await EventService.detail(this.eid);
    console.log(res, res.registrationFields);
    runInAction(() => {
      this.event = res;
      this.registrationRequires = JSON.parse(res.registrationRequires) ?? {};
      // test
      // this.registrationRequires = {
      //   socials: true,
      //   birthday: true,
      //   gender: true,
      //   jobs: true,
      //   company: true,
      //   jobTitle: true,
      //   cities: true,
      //   cityOfResidence: true,
      //   cityOfBirth: true,

      //   foundedIn: true,
      //   job: true,
      //   type: true,
      //   location: true
      // };
    });
  };

  getDemandRule = async () => {
    try {
      const res = await DemandService.detail(this.did);
      runInAction(() => {
        this.demandRule = res.meta.rules.join('；');
        this.demandName = res.name;
      });
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  @action
    deserializeUser = (res) => {
      const {
        type,
        displayName,
        phone,
        email,
        info
      } = res;

      this.displayName = displayName;
      this.email = email;
      this.phone = phone;
      if (phone) {
        this.phone9Digit = phone.slice(-9);
        this.countryCode = phone.slice(0, phone.length - 9);
        if (this.countryCode === '+8860') {
          this.countryCode = '+886';
        }
      }
      this.identity = type;
      this.socials = this.deserializeSocials(info.socials);
      this.cities = info.cities;

      if (this.identity === USER_TYPES.Personal) {
        this.company = info.company;
        this.jobTitle = info.jobTitle;
        this.birthday = info.birthday;
        this.jobs = info.jobs;
        this.cityOfResidence = info.cityOfResidence;
        this.cityOfBirth = info.cityOfBirth;
        this.gender = res.gender;
      } else {
        this.job = info.job;
        this.type = info.type;
        this.foundedIn = info.foundedIn;
        this.location = info.location;
      }
    };

  @action
    deserializeSocials = (data) => {
      return data.map((s) => {
        try {
          const obj = JSON.parse(s);
          if (obj.name === SOCIALS_TYPE.Line) {
            this.lineId = obj.url?.split('~')[1] ?? '';
          } else {
            this[obj.name] = obj.url;
          }
          return obj;
        } catch (e) {
          console.log(e);
          return {};
        }
      });
    };

  serializeSocials = () => {
    const arr = [
      JSON.stringify({ name: SOCIALS_TYPE.Influence, url: this.influence }),
      JSON.stringify({ name: SOCIALS_TYPE.Facebook, url: this.facebook }),
      JSON.stringify({ name: SOCIALS_TYPE.Instagram, url: this.instagram }),
      JSON.stringify({ name: SOCIALS_TYPE.Line, url: this.lineId ? lineUrl + this.lineId : null }),
      JSON.stringify({ name: SOCIALS_TYPE.Linkedin, url: this.linkedin }),
      JSON.stringify({ name: SOCIALS_TYPE.Website, url: this.website }),
      JSON.stringify({ name: SOCIALS_TYPE.Youtube, url: this.youtube })
    ];

    return arr;
  };

  serializeData = () => {
    const jobs = this.jobsSelectViewModel.selectedItems?.map((el) => el.value);
    const cities = this.citiesSelectViewModel.selectedItems?.map((el) => el.value);
    let customProfile;
    if (this.identity === USER_TYPES.Personal) {
      customProfile = {
        company: this.company,
        jobTitle: this.jobTitle,
        birthday: this.birthday ? dayjs(this.birthday).toISOString() : null,
        gender: this.gender,
        jobs,
        cityOfResidence: this.cityOfResidence,
        cityOfBirth: this.cityOfBirth
      };
    } else {
      customProfile = {
        type: this.type,
        job: this.job,
        foundedIn: this.foundedIn ? dayjs(this.foundedIn).toISOString() : null,
        location: this.location
      };
    }

    const data = {
      demandId: this.did,
      resourceId: this.rid,
      profile: {
        type: this.identity,
        meta: {
          name: this.displayName,
          email: this.email,
          phone: this.countryCode + this.phone9Digit,
          cities,
          socials: this.registrationRequires.socials ? this.serializeSocials() : null,
          ...customProfile
        }
      },
      motivation: this.motivation,
      cognition: this.cognition,
      fields: JSON.stringify(this.additionalFields)
    };

    return data;
  };

  @computed
  get customFields() {
    const fields = Object.entries(this.registrationRequires).filter((v) => v[0] !== 'socials' && v[1]).map((f) => f[0]);
    if (this.identity === USER_TYPES.Personal) {
      const filteredFields = fields.filter((f) => this.SIGN_UP_PERSONAL.includes(f));
      if (this.registrationRequires.socials) {
        return filteredFields.concat(this.PersonalSocials);
      }
      return filteredFields;
    }

    if (this.identity === USER_TYPES.Organization) {
      const filteredFields = fields.filter((f) => this.SIGN_UP_ORG.includes(f));
      if (this.registrationRequires.socials) {
        return filteredFields.concat(this.OrganizationSocials);
      }
      return filteredFields;
    }

    return fields;
  }

  @computed
  get detailCustomCounts() {
    return this.customFields.length;
  }

  @action
    onChange = (field, value) => {
      this[field] = value;
      if (field === 'countryCode' || field === 'phone9Digit') {
        this.phone = this.countryCode + this.phone9Digit;
      }
    };

  @action onChangeAdditionalFields = (field, value) => {
    this.additionalFields[field] = value;
  };

  @action
    onCheckAgree = (e) => {
      this.isChecked = e.target.checked;
    };

  @action
    onClickNext = () => {
      window.scroll(0, 0);
      this.step = this.STEPS.Detail;
    };

  @action
    onIntroClickNext = () => {
      window.scroll(0, 0);
      this.showError = true;
      const isDisable = !this.motivation || !this.isMotivationValid || !this.isCognitionValid;

      if (!isDisable) {
        this.step = this.STEPS.Detail;
        this.showError = false;
      }
    };

  sendGAEvent(eventName) {
    switch (eventName) {
      case 'Event_Join_Complete':
        window.gtag('event', 'Event_Join_Complete', {
          Event_ID: this.eid,
          Requirement_Name: this.demandName // need to test!!!!
        });
        break;
      default:
    }
  }

  @action onSubmit = async () => {
    if (!this.did || !this.rid) {
      return;
    }

    this.isLoading = true;
    this.showError = true;
    const hasBasicInfo = this.displayName && this.email && this.phone9Digit;
    const isDisable = !hasBasicInfo
    || !this.isEmailValid
    || !this.isPhoneValid
    || !this.isCountryCodeValid
    || !this.isRequiredFieldsFilled
    || !this.isSocialsValid;

    if (isDisable) {
      this.isLoading = false;
      ErrorService.onCustomError(i18n.t('fill_fields_correctly'));
      return;
    }

    if (this.countryCode === '+886' && /^09\d{8}$/.test(this.phone9Digit)) {
      this.phone9Digit = this.phone9Digit.slice(1); // 09xxxxxxxx -> 9xxxxxxxx
    }

    const data = this.serializeData();
    try {
      await RegisterService.create(data);
      runInAction(() => {
        this.sendGAEvent('Event_Join_Complete');
        this.step = this.STEPS.Success;
      });
    } catch (error) {
      if (error.response?.status === 409) {
        ErrorService.onCustomError(i18n.t('this_resource_has_already_signed_for_this_event'));
      } else {
        ErrorService.onDefaultError(error);
      }
    }

    this.isLoading = false;
  };

  onClickMoreEvents = () => {
    const { router } = this.props;
    router.navigate('/events');
  };

  isClamped = (ref) => {
    const el = ref.current;
    if (el) {
      return el.scrollHeight > el.clientHeight;
    }
    return false;
  };

  clickRuleMore = () => {
    this.hasClickedRuleMore = true;
  };

  @computed
  get isRequiredFieldsFilled() {
    const requires = Object.entries(this.registrationRequires).filter((field) => field[1]);
    return requires.map((item) => item[0]).every((field) => {
      if (field === 'cities') {
        return !this.citiesSelectViewModel.isEmpty;
      }

      if (this.identity === USER_TYPES.Personal) {
        if (this.SIGN_UP_PERSONAL.includes(field)) {
          if (field === 'jobs') {
            return !this.jobsSelectViewModel.isEmpty;
          }

          return !!this[field];
        }
      }

      if (this.identity === USER_TYPES.Organization) {
        if (this.SIGN_UP_ORG.includes(field)) {
          return !!this[field];
        }
      }

      return true;
    });
  }

  @computed
  get isMotivationValid() {
    return this.motivation?.length <= 300;
  }

  @computed
  get isCognitionValid() {
    return !this.cognition || this.cognition?.length <= 300;
  }

  @computed
  get isEmailValid() {
    return IS.email(this.email);
  }

  @computed
  get isCountryCodeValid() {
    return ConstantsStore.countryCodesOptions.some((opt) => opt.value === this.countryCode);
  }

  @computed
  get isPhoneValid() {
    return IS.isTWPhone(this.countryCode + this.phone9Digit);
  }

  @computed
  get isIntroButtonDisable() {
    return !this.isChecked || !this.motivation || !this.isMotivationValid || !this.isCognitionValid;
  }

  @action handleBackPress = () => {
    if (this.step === this.STEPS.Detail) {
      this.step = this.STEPS.Intro;
    } else {
      const { navigate } = this.props.router;
      navigate(-1);
    }
  };

  @computed
  get isSocialsValid() {
    return this.isInfluenceValid && this.isFacebookValid && this.isInstagramValid && this.isLinkedinValid && this.isWebsiteValid && this.isYoutubeValid;
  }

  @computed
  get isInfluenceValid() {
    return !this.influence || IS.url(this.influence);
  }

  @computed
  get isFacebookValid() {
    return !this.facebook || IS.facebook(this.facebook);
  }

  @computed
  get isInstagramValid() {
    return !this.instagram || IS.instagram(this.instagram);
  }

  @computed
  get isLinkedinValid() {
    return !this.linkedin || IS.linkedin(this.linkedin);
  }

  @computed
  get isWebsiteValid() {
    return !this.website || IS.url(this.website);
  }

  @computed
  get isYoutubeValid() {
    return !this.youtube || IS.youtube(this.youtube);
  }
}

export default SignUpForEventPageViewModel;
