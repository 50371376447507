import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Waypoint } from 'react-waypoint';
import scrollDetector from 'src/stores/scrollDetector';
import { EVENTCARD_TYPE, EVENT_PRIVACY, EVENT_RELEASE_METHOD, EVENT_STATUS } from 'src/constants';

import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import BaseModal from 'src/components/Modals/BaseModal';
import DatePicker from 'src/components/DatePicker';
import EventCardForManagement from 'src/components/EventCard/ForManagement';
import GeneralModal from 'src/components/Modals/GeneralModal';
import Icon from 'src/components/Icon';
import Loading from 'src/components/Loading';
import MainLayout from 'src/components/MainLayout';
import MemberOnlyFeatureModal from 'src/components/Modals/MemberOnlyFeatureModal';
import Tabs from 'src/components/Tabs';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import IconButton from '@mui/material/IconButton';
import ConfigProvider from 'antd/es/config-provider';
import Dropdown from 'antd/es/dropdown';
import Input from 'antd/es/input';
import Spin from 'antd/es/spin';

import iconAdd from 'src/assets/iconAdd.svg';
import iconFilter from 'src/assets/iconFilter.svg';
import iconFilterOn from 'src/assets/iconFilterOn.svg';
import iconSort from 'src/assets/iconSort.svg';
import iconSearch from 'src/assets/iconSearch.svg';

import EventHostedPageViewModel, { MENU_MODAL, RECRUIT_STATUS, RELEASE_METHOD } from './vm';
import styles from './styles.module.scss';

dayjs.extend(isSameOrAfter);



@observer
class EventHostedPage extends React.Component {
  constructor(props) {
    super(props);

    this.vm = new EventHostedPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  getMenuItemsInMore(item) {
    const Options = {
      ManageApplications: {
        key: 'manageApplications',
        label: (
          <div
            onClick={(e) => {
              this.vm.toDemands(item.id);
            }}
          >
            {t('manage_applications')}
          </div>
        )
      },
      EditEvent: {
        key: 'editEvent',
        label: (
          <div
            onClick={(e) => this.vm.toEditEvent(item.id)}
          >
            {t('edit_event')}
          </div>
        )
      },
      EditDraft: {
        key: 'editDraft',
        label: (
          <div
            onClick={(e) => this.vm.toEditEvent(item.id)}
          >
            {t('edit_draft')}
          </div>
        )
      },
      CopyEvent: {
        key: 'copyEvent',
        label: (
          <div
            onClick={(e) => this.vm.onCopyEvent(item.id)}
          >
            {t('copy_event')}
          </div>
        )
      },
      CopyDraft: {
        key: 'copyDraft',
        label: (
          <div
            onClick={(e) => this.vm.onCopyEvent(item.id)}
          >
            {t('copy_draft')}
          </div>
        )
      },
      DeleteDraft: {
        key: 'deleteDraft',
        label: (
          <div
            onClick={(e) => this.vm.openMenuModal(MENU_MODAL.Delete, item)}
          >
            {t('delete_draft')}
          </div>
        )
      },
      StopRecruit: { // 停止招募
        key: 'stopRecruit',
        label: (
          <div
            onClick={(e) => {
              this.vm.openMenuModal(MENU_MODAL.StopRecruit, item);
            }}
          >
            {t('stop_recruit')}
          </div>
        )
      },
      RestartRecruit: { // 恢復招募
        key: 'restartRecruit',
        label: (
          <div
            onClick={(e) => {
              this.vm.onToggleRecruit(item);
            }}
          >
            {t('restart_recruit')}
          </div>
        )
      },
      ProlongRecruit: { // 延長招募
        key: 'prolongRecruit',
        label: (
          <div
            onClick={(e) => {
              this.vm.openMenuModal(MENU_MODAL.Prolong, item);
            }}
          >
            {t('prolong_recruit')}
          </div>
        )
      },
      Deactivate: { // 取消發布
        key: 'deactivate',
        label: (
          <div
            onClick={(e) => {
              this.vm.openMenuModal(MENU_MODAL.Deactivate, item);
            }}
          >
            {t('deactivate')}
          </div>
        )
      },
      Activate: { // 立即發布
        key: 'activate',
        label: (
          <div
            onClick={(e) => {
              this.vm.openMenuModal(MENU_MODAL.Activate, item);
            }}
          >
            {t('activate')}
          </div>
        )
      },
      TogglePrivacy: {
        key: 'privacy',
        label: (
          <div
            onClick={(e) => {
              this.vm.openMenuModal(MENU_MODAL.Privacy, item);
            }}
          >
            {t('filter_view_access')}
          </div>
        )
      }
    };
    if (!item) return [];

    switch (this.vm.currentTab) {
      case EVENT_STATUS.Active:
        if (item.enableRecruit && dayjs(item.endAt).isSameOrAfter(dayjs())) { // 招募中
          return [
            Options.ManageApplications,
            Options.EditEvent,
            Options.CopyEvent,
            Options.TogglePrivacy,
            Options.StopRecruit,
            Options.ProlongRecruit,
            Options.Deactivate
          ];
        }

        if (!item.enableRecruit && dayjs(item.endAt).isSameOrAfter(dayjs())) { // 暫停招募
          return [
            Options.ManageApplications,
            Options.EditEvent,
            Options.CopyEvent,
            Options.TogglePrivacy,
            Options.RestartRecruit,
            Options.ProlongRecruit,
            Options.Deactivate
          ];
        }

        if (dayjs(item.endAt).isBefore(dayjs())) { // 結束招募
          return [
            Options.ManageApplications,
            Options.EditEvent,
            Options.CopyEvent,
            Options.TogglePrivacy,
            Options.ProlongRecruit,
            Options.Deactivate
          ];
        }
        break;

        // ////////////////////////////////////////////////////////////////

      case EVENT_STATUS.Inactive:
        return [
          Options.Activate,
          Options.EditEvent,
          Options.CopyEvent,
          Options.TogglePrivacy
        ];

        // ////////////////////////////////////////////////////////////////

      case EVENT_STATUS.Draft:
        // 待補件
        if (item.status === EVENT_STATUS.Failed) {
          return [
            Options.EditDraft,
            Options.CopyDraft
          ];
        }

        // 待送審
        if (item.status === EVENT_STATUS.Draft) {
          return [
            Options.EditDraft,
            Options.CopyDraft,
            Options.DeleteDraft
          ];
        }

        // 審核中
        if (item.status === EVENT_STATUS.Submitted) {
          return [
            Options.CopyDraft
          ];
        }

        // 駁回
        if (item.status === EVENT_STATUS.Rejected) {
          return [
            Options.CopyDraft
          ];
        }

        return [
          Options.EditEvent
        ];
      default:
    }
    return [];
  }

  getSortingMenuItems() {
    const Options = {
      ReleaseDesc: {
        key: 'sort_release_desc',
        label: (
          <div onClick={() => this.vm.handleSort('release', 'desc')}>
            {t('sort_release_desc')}
          </div>
        )
      },
      ReleaseAsc: {
        key: 'sort_release_asc',
        label: (
          <div onClick={() => this.vm.handleSort('release', 'asc')}>
            {t('sort_release_asc')}
          </div>
        )
      },
      RegisterDesc: {
        key: 'sort_register_desc',
        label: (
          <div onClick={() => this.vm.handleSort('register', 'desc')}>
            {t('sort_register_desc')}
          </div>
        )
      },
      RegisterAsc: {
        key: 'sort_register_asc',
        label: (
          <div onClick={() => this.vm.handleSort('register', 'asc')}>
            {t('sort_register_asc')}
          </div>
        )
      },
      CreateDesc: {
        key: 'sort_create_desc',
        label: (
          <div onClick={() => this.vm.handleSort('id', 'desc')}>
            {t('sort_create_desc')}
          </div>
        )
      },
      CreateAsc: {
        key: 'sort_create_asc',
        label: (
          <div onClick={() => this.vm.handleSort('id', 'asc')}>
            {t('sort_create_asc')}
          </div>
        )
      }
    };

    switch (this.vm.currentTab) {
      case EVENT_STATUS.Active:
        return [
          Options.ReleaseDesc,
          Options.ReleaseAsc,
          Options.RegisterDesc,
          Options.RegisterAsc
        ];

        // ////////////////////////////////////////////////////////////////

      case EVENT_STATUS.Inactive:
        return [
          Options.ReleaseDesc,
          Options.ReleaseAsc
        ];

        // ////////////////////////////////////////////////////////////////

      case EVENT_STATUS.Draft:
        return [
          Options.CreateDesc,
          Options.CreateAsc
        ];
      default:
    }
    return [];
  }

  renderTopArea() {
    const sortItems = this.getSortingMenuItems();
    return (
      <>
        <div className={styles.topArea}>
          <div className={styles.topContainer}>
            <div className={styles.title}>
              <div>Actions</div>
              <div>{t('event_hosted_title')}</div>
            </div>
          </div>
        </div>
        <div className={clsx(styles.filterArea, !scrollDetector.isScrollingDown && styles.lower)}>
          <div className={styles.container}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#414EE1'
                }
              }}
            >
              <Input.Search
                placeholder={t('search')}
                className="searchInput"
                onChange={(e) => this.vm.setFilter('keyword', e.target.value)}
                onSearch={this.vm.filter.keyword?.length !== 1 ? this.vm.getLists : null}
                value={this.vm.filter.keyword}
                size="large"
                prefix={(
                  <Icon
                    size={24}
                    svg
                    style={{ marginRight: 5 }}
                  >
                    {iconSearch}
                  </Icon>
                )}
                suffix={this.vm.filter.keyword && (
                  <IconButton
                    aria-label="clear search input"
                    onClick={() => {
                      this.vm.setFilter('keyword', '');
                      this.vm.getLists();
                    }}
                    sx={{
                      width: 12,
                      height: 12,
                      fontSize: 12
                    }}
                  >
                    x
                  </IconButton>
                )}
              />
            </ConfigProvider>
            <div className={styles.filterIconContainer}>
              <Icon
                size={24}
                svg
                onClick={this.vm.toggleFilterModal}
              >
                {this.vm.hasAppliedFilter ? iconFilterOn : iconFilter}
              </Icon>
              {this.vm.hasAppliedFilter && (
                <span className={styles.filterCount}>
                  {this.vm.filterCount}
                </span>
              )}
            </div>
            <Dropdown
              overlayClassName="sortDropdown"
              menu={{
                items: sortItems
              }}
            >
              <Icon
                size={24}
                svg
                className={styles.icon}
              >
                {iconSort}
              </Icon>
            </Dropdown>
          </div>
        </div>
      </>
    );
  }

  renderEventList(status) {
    return (
      <div className={styles.list}>
        {this.vm.lists[status]?.map((item) => (
          <EventCardForManagement
            type={EVENTCARD_TYPE.Created}
            className={styles.card}
            item={item}
            key={item.id}
            colorMode="light"
            dropdownMenu={{ items: this.getMenuItemsInMore(item) }}
            onClick={() => this.vm.toEventDetail(item.id)}
          />
        ))}
      </div>
    );
  }

  renderFilterModal() {
    const options = () => {
      switch (this.vm.currentTab) {
        case EVENT_STATUS.Active:
          return (
            <div className={clsx(styles.secondRow)}>
              <div className={styles.subtitle}>
                {t('filter_recruiting_status')}
                ：
              </div>
              <div className={clsx(styles.flexRow, styles.filterOptions)}>
                <div
                  key="1"
                  className={clsx(styles.filterOpt, this.vm.recruitStatus === RECRUIT_STATUS.Ongoing && styles.selected)}
                  onClick={() => this.vm.setFilterRecruitStatus(RECRUIT_STATUS.Ongoing)}
                >
                  {t('filter_recruiting_status_ongoing')}
                </div>
                <div
                  key="2"
                  className={clsx(styles.filterOpt, this.vm.recruitStatus === RECRUIT_STATUS.Incoming && styles.selected)}
                  onClick={() => this.vm.setFilterRecruitStatus(RECRUIT_STATUS.Incoming)}
                >
                  {t('filter_recruiting_status_incoming')}
                </div>
                <div
                  key="3"
                  className={clsx(styles.filterOpt, this.vm.recruitStatus === RECRUIT_STATUS.End && styles.selected)}
                  onClick={() => this.vm.setFilterRecruitStatus(RECRUIT_STATUS.End)}
                >
                  {t('filter_recruiting_status_past')}
                </div>
              </div>
            </div>
          );
        case EVENT_STATUS.Inactive:
          return (
            <div className={clsx(styles.secondRow)}>
              <div className={styles.subtitle}>
                {t('filter_release_method')}
                ：
              </div>
              <div className={clsx(styles.flexRow, styles.filterOptions)}>
                <div
                  key="auto"
                  className={clsx(styles.filterOpt, this.vm.releaseMethod === RELEASE_METHOD.Auto && styles.selected)}
                  onClick={() => this.vm.setFilterReleaseMethod(RELEASE_METHOD.Auto)}
                >
                  {t('filter_release_method_auto')}
                </div>
                <div
                  key="manual"
                  className={clsx(styles.filterOpt, this.vm.releaseMethod === RELEASE_METHOD.Manual && styles.selected)}
                  onClick={() => this.vm.setFilterReleaseMethod(RELEASE_METHOD.Manual)}
                >
                  {t('filter_release_method_manual')}
                </div>
                {/* <div
                  key="cancel"
                  className={clsx(styles.filterOpt, this.vm.releaseMethod === RELEASE_METHOD.Cancel && styles.selected)}
                  onClick={() => this.vm.setFilterReleaseMethod(RELEASE_METHOD.Cancel)}
                >
                  {t('filter_release_method_cancel')}
                </div> */}
              </div>
            </div>
          );
        case EVENT_STATUS.Draft:
          return (
            <div className={clsx(styles.secondRow)}>
              <div className={styles.subtitle}>
                {t('filter_review_status')}
                ：
              </div>
              <div className={clsx(styles.flexRow, styles.filterOptions)}>
                <div
                  key="1"
                  className={clsx(styles.filterOpt, this.vm.draftsStatuses.includes(EVENT_STATUS.Draft) && styles.selected)}
                  onClick={() => this.vm.setFilterDraftStatus(EVENT_STATUS.Draft)}
                >
                  {t('filter_review_status_draft')}
                </div>
                <div
                  key="2"
                  className={clsx(styles.filterOpt, this.vm.draftsStatuses.includes(EVENT_STATUS.Submitted) && styles.selected)}
                  onClick={() => this.vm.setFilterDraftStatus(EVENT_STATUS.Submitted)}
                >
                  {t('filter_review_status_in_process')}
                </div>
                <div
                  key="3"
                  className={clsx(styles.filterOpt, this.vm.draftsStatuses.includes(EVENT_STATUS.Failed) && styles.selected)}
                  onClick={() => this.vm.setFilterDraftStatus(EVENT_STATUS.Failed)}
                >
                  {t('filter_review_status_failed')}
                </div>
              </div>
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <BaseModal
        onClose={this.vm.toggleFilterModal}
        open={this.vm.isModalOpen}
        isMobile={this.vm.isMobile}
        centered
        showCloseIcon
      >
        <div className={styles.filterModal}>
          <div className={clsx(styles.flexRow, styles.top)}>
            <Icon
              size={24}
              svg
              style={{ filter: 'invert(1)' }}
            >
              {iconFilter}
            </Icon>
            <div className={styles.title}>Filter by</div>
          </div>
          <div className={clsx(styles.flexRow, styles.marginBottom20, styles.filterRow)}>
            <div className={styles.subtitle}>
              {t('filter_view_access')}
              ：
            </div>
            <div className={clsx(styles.flexRow, styles.filterOptions)}>
              <div
                key="listed"
                className={clsx(styles.filterOpt, this.vm.filter.privacy === 'listed' && styles.selected)}
                onClick={() => this.vm.setFilterPrivacy('listed')}
              >
                {t('filter_view_access_public')}
              </div>
              <div
                key="unlisted"
                className={clsx(styles.filterOpt, this.vm.filter.privacy === 'unlisted' && styles.selected)}
                onClick={() => this.vm.setFilterPrivacy('unlisted')}
              >
                {t('filter_view_access_unpublic')}
              </div>
            </div>
          </div>

          {options()}

          <div style={{ flexGrow: 1, flexBasis: 30 }} />

          <div className={clsx(styles.flexRow, styles.buttons)}>
            <Button
              type="default"
              className={styles.resetBtn}
              onClick={() => this.vm.resetFilter()}
            >
              {t('clear_filter')}
            </Button>

            <Button
              className={styles.applyBtn}
              onClick={this.vm.applyFilter}
              withRightArrow
            >
              {t('apply_filter')}
            </Button>
          </div>
        </div>
      </BaseModal>
    );
  }

  renderNoContentHint() {
    if (this.vm.AnchoredLists[this.vm.currentTab].isAwait) {
      return (
        <div className={styles.emptyContainer}>
          <Spin />
        </div>
      );
    }

    return (
      <div className={styles.emptyContainer}>
        <div className={styles.emptyMessage}>
          <div className={styles.title}>
            {t('empty_message_title')}
          </div>
          <div className={styles.text}>
            {(this.vm.hasAppliedFilter || this.vm.filter?.keyword)
              ? t('filter_no_result')
              : t(`list_empty_message_event_hosted_${this.vm.currentTab}`)}
          </div>
        </div>
      </div>
    );
  }

  renderMenuModal() {
    // NOTE: endAt 最晚到 2025/04/22
    const disabledDatesForEnd = (current) => {
      const end = dayjs(this.vm.dataInMenuModal.item.releaseAt) || dayjs().endOf('day');
      const limit = dayjs('2025-04-23').startOf('day');
      if (this.vm.dataInMenuModal.item.releaseAt && this.vm.dataInMenuModal.item.endAt) { // prolong
        return current && (current < end.endOf('day') || current > limit || current < dayjs(this.vm.dataInMenuModal.item.endAt));
      }

      if (this.vm.dataInMenuModal.item.releaseAt) { // 提前發布
        return current && (current < end.endOf('day') || current > limit);
      }

      // activate for the first time
      return current && (current < dayjs().endOf('day') || current > limit);
    };

    const content = () => {
      switch (this.vm.menuModal) {
        case MENU_MODAL.Privacy:
          return (
            <>
              <div className={styles.title}>
                {t('event_menu_modal_title_privacy')}
              </div>

              <div className={clsx(styles.flexRow, styles.viewAccessOptions)}>
                <div className={styles.subtitle}>
                  {t('filter_view_access')}
                  ：
                </div>
                <div className={clsx(styles.flexRow, styles.filterOptions)}>
                  <div
                    key="1"
                    className={clsx(styles.filterOpt, this.vm.dataInMenuModal.privacy === EVENT_PRIVACY.Listed && styles.selected)}
                    onClick={() => this.vm.setDataInMenuModal(MENU_MODAL.Privacy, EVENT_PRIVACY.Listed)}
                  >
                    {t('filter_view_access_public')}
                  </div>
                  <div
                    key="2"
                    className={clsx(styles.filterOpt, this.vm.dataInMenuModal.privacy === EVENT_PRIVACY.Unlisted && styles.selected)}
                    onClick={() => this.vm.setDataInMenuModal(MENU_MODAL.Privacy, EVENT_PRIVACY.Unlisted)}
                  >
                    {t('filter_view_access_unpublic')}
                  </div>
                </div>
              </div>
            </>
          );

        case MENU_MODAL.StopRecruit:
          return (
            <div className={styles.title}>
              {t('event_menu_modal_title_stop_recruit')}
            </div>
          );

        case MENU_MODAL.Deactivate:
          return (
            <div className={styles.title}>
              {t('event_menu_modal_title_deactivate')}
            </div>
          );

        case MENU_MODAL.Activate:
          return (
            <>
              <div className={styles.title}>
                {t('event_menu_modal_title_activate')}
              </div>
              <div className={styles.marginBottom20}>
                {t('event_menu_modal_subtitle_activate')}
              </div>
              <DatePicker
                key="event_end_date"
                label={t('event_end_date')}
                placeholder={t('event_end_date')}
                onChange={(date, dateString) => {
                  this.vm.setDataInMenuModal('endAt', date);
                }}
                style={{ width: '100%' }}
                required
                disabledDate={disabledDatesForEnd}
                value={this.vm.dataInMenuModal.endAt}
                allowClear={false}
                readOnly={!!this.vm.dataInMenuModal.item.endAt}
              />
            </>
          );

        case MENU_MODAL.Prolong:
          return (
            <>
              <div className={styles.title}>
                {t('event_menu_modal_title_prolong')}
              </div>
              <div className={styles.marginBottom20}>
                {t('event_menu_modal_subtitle_prolong')}
              </div>
              <DatePicker
                key="event_end_date_prolong"
                label={t('datepicker_placeholder_prolonged_endAt')}
                placeholder={t('datepicker_placeholder_prolonged_endAt')}
                onChange={(date, dateString) => {
                  this.vm.setDataInMenuModal('endAt', date);
                }}
                style={{ width: '100%' }}
                required
                allowClear={false}
                disabledDate={disabledDatesForEnd}
              />
            </>
          );

        case MENU_MODAL.Delete:
          return (
            <div className={styles.title}>
              {t('event_menu_modal_title_delete_draft')}
            </div>
          );

        default:
          return null;
      }
    };

    const confirmButton = () => {
      switch (this.vm.menuModal) {
        case MENU_MODAL.Privacy:
          return (
            <Button
              className={styles.confirmButton}
              onClick={() => {
                this.vm.onChangePrivacy();
                this.vm.closeMenuModal();
              }}
              withRightArrow
            >
              {t('ok')}
            </Button>
          );

        case MENU_MODAL.StopRecruit:
          return (
            <Button
              className={styles.confirmButton}
              onClick={() => {
                this.vm.onToggleRecruit(this.vm.dataInMenuModal.item);
                this.vm.closeMenuModal();
              }}
              withRightArrow
            >
              {t('event_menu_modal_stop_recruit_yes')}
            </Button>
          );

        case MENU_MODAL.Deactivate:
          return (
            <Button
              className={clsx(styles.confirmButton, styles.deactivateButton)}
              onClick={() => {
                this.vm.onChangeStatus(this.vm.dataInMenuModal.item.id, EVENT_STATUS.Inactive);
                this.vm.closeMenuModal();
              }}
              withRightArrow
            >
              {t('event_menu_modal_deactivate_yes')}
            </Button>
          );

        case MENU_MODAL.Activate:
          return (
            <Button
              className={styles.confirmButton}
              disabled={!this.vm.dataInMenuModal.endAt}
              onClick={() => {
                this.vm.onActivateEvent(this.vm.dataInMenuModal.item.id);
              }}
              withRightArrow
            >
              {t('event_menu_modal_title_activate')}
            </Button>
          );

        case MENU_MODAL.Prolong:
          return (
            <Button
              className={styles.confirmButton}
              disabled={!this.vm.dataInMenuModal.endAt}
              onClick={() => {
                this.vm.onProlongEvent(this.vm.dataInMenuModal.item.id);
                this.vm.closeMenuModal();
              }}
              withRightArrow
            >
              {t('event_menu_modal_button_prolong')}
            </Button>
          );

        case MENU_MODAL.Delete:
          return (
            <Button
              className={styles.confirmButton}
              onClick={() => {
                this.vm.onDeleteDraft(this.vm.dataInMenuModal.item.id);
                this.vm.closeMenuModal();
              }}
              withRightArrow
            >
              {t('ok')}
            </Button>
          );

        default:
          return null;
      }
    };

    const modalType = this.vm.menuModal;
    const noButtonText = (modalType === MENU_MODAL.StopRecruit || modalType === MENU_MODAL.Deactivate || modalType === MENU_MODAL.Delete) ? t('no') : t('cancel');

    return (
      <BaseModal
        onClose={this.vm.closeMenuModal}
        open={!!this.vm.menuModal}
        isMobile={this.vm.isMobile}
        centered
      >
        <div className={styles.menuModal}>
          {content()}

          <div style={{ flexGrow: 1, flexBasis: 30 }} />

          <div className={clsx(styles.flexRow, styles.buttons)}>
            <Button
              type="default"
              className={styles.noButton}
              onClick={this.vm.closeMenuModal}
            >
              {noButtonText}
            </Button>

            {confirmButton()}
          </div>
        </div>
      </BaseModal>
    );
  }

  render() {
    return (
      <div className={clsx(styles.eventHostedPage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
        >
          <Breadcrumb
            routes={[
              { label: t('my_account'), path: `/user/profile/${this.vm.profile?.id ?? ''}` },
              { label: '', path: '' }
            ]}
            right={this.vm.profile.isEventCreator ? (
              <div
                className={clsx(styles.flexRow, styles.clickable)}
                onClick={this.vm.addEvent}
              >
                <Icon
                  size={24}
                  svg
                >
                  {iconAdd}
                </Icon>
                <div className={styles.addButton}>
                  {t('event_add_button')}
                </div>
              </div>
            ) : null}
            colorType="purple"
            withHeader
          />
          {this.renderTopArea()}
          <div className={styles.mainContainer}>
            <Tabs
              colorMode={2}
              items={[
                {
                  value: EVENT_STATUS.Active,
                  label: t('tab_events_posted'),
                  // badge: this.vm.AnchoredLists[EVENT_STATUS.Active]?.count, // phase 3
                  content: this.vm.lists[EVENT_STATUS.Active]?.length ? this.renderEventList(EVENT_STATUS.Active) : this.renderNoContentHint()
                },
                {
                  value: EVENT_STATUS.Inactive,
                  label: t('tab_events_pending'),
                  // badge: this.vm.AnchoredLists[EVENT_STATUS.Inactive]?.count, // phase 3
                  content: this.vm.lists[EVENT_STATUS.Inactive]?.length ? this.renderEventList(EVENT_STATUS.Inactive) : this.renderNoContentHint()
                },
                {
                  value: EVENT_STATUS.Draft,
                  label: t('tab_events_draft'),
                  // badge: this.vm.AnchoredLists[EVENT_STATUS.Draft]?.count, // phase 3
                  content: this.vm.lists[EVENT_STATUS.Draft]?.length ? this.renderEventList(EVENT_STATUS.Draft) : this.renderNoContentHint()
                }
              ]}
              onSwitchTab={this.vm.switchTab}
              currentValue={this.vm.currentTab}
              className={styles.eventTabs}
            />

            {
              this.vm.anchor
                ? (
                  <>
                    <Waypoint
                      scrollableAncestor={window}
                      onEnter={this.vm.onScrollEnd}
                    />
                    {this.vm.AnchoredLists[this.vm.currentTab]?.isAwait && <Spin style={{ margin: '0 auto' }} />}
                  </>
                )
                : null
            }
          </div>

          {this.renderFilterModal()}

          {this.renderMenuModal()}

        </MainLayout>

        {this.vm.generalModalViewModel && (
          <GeneralModal vm={this.vm.generalModalViewModel} isMobile={this.vm.isMobile} />
        )}

        {this.vm.memberOnlyFeatureModalVM && (
          <MemberOnlyFeatureModal
            vm={this.vm.memberOnlyFeatureModalVM}
            isMobile={this.props.context.state.isMobile}
            centered
          />
        )}

        { this.vm.isAwait && <Loading /> }
      </div>
    );
  }
}

EventHostedPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(EventHostedPage)), true);
