import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import i18n from 'src/i18n';
import Button from 'src/components/Button';
import celebrating from 'src/assets/illustrationCongrats.svg';
import illustrationOneBoy from 'src/assets/illustrationOneBoy.svg';

import styles from './styles.module.scss';
import BaseModal from '../BaseModal';

function GeneralModal(props) {

  const { vm, isMobile, height } = props;
  const getTitle = () => {
    if (vm.title?.includes('\\n')) {
      return (
        <>
          {vm.title.split('\\n')[0]}
          <br />
          {vm.title.split('\\n')[1]}
        </>
      );
    }
    return vm.title;
  };

  const getContent = () => {
    if (!vm.content) return null;

    const arr = vm.content.split('\\');
    return arr.map((str) => {
      if (str.slice(0, 1) === '#') {
        return <span key={str} className={styles.primary}>{str.slice(1)}</span>;
      }

      return <span key={str}>{str}</span>;
    });
  };

  return (
    <BaseModal
      closable={false}
      open={vm.isShow}
      onClose={vm.close}
      maskClosable
      isMobile={isMobile}
      centered
      height={height}
    >
      <div className={styles.modal}>
        <img
          className={clsx(styles.marginBottom, styles.illustration)}
          src={(vm.showCancelButton || vm.illustrationType === 1) ? illustrationOneBoy : celebrating}
          alt="illustration"
        />
        <h2 className={clsx(styles.title, styles.marginBottom)}>
          {getTitle()}
        </h2>
        {vm.content && (
          <div className={clsx(styles.subtitle, styles.marginBottom)}>
            {getContent()}
          </div>
        )}
        <div style={{ flexGrow: 1 }} />
        <div className={vm.showCancelButton ? styles.buttons : {}}>
          {vm.showCancelButton && (
            <Button
              onClick={vm.close}
              className={clsx(styles.button, styles.cancelButton)}
              type="default"
            >
              {i18n.t('confirm_modal_cancel')}
            </Button>
          )}

          <Button
            onClick={vm.confirm}
            className={styles.button}
            {...vm.buttonProps}
          >
            {vm.buttonText}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}


GeneralModal.propTypes = {
  vm: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  height: PropTypes.number
};

GeneralModal.defaultProps = {
  isMobile: false,
  height: undefined
};

export default observer(GeneralModal);
