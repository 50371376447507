import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import { RESOURCE_TYPES } from 'src/constants';
import { t } from 'i18next';

import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import Icon from 'src/components/Icon';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import DemandCard from 'src/components/DemandCard';
import Tabs from 'src/components/Tabs';
import EventCarousel from 'src/components/EventCarousel';


import iconResourceService from 'src/assets/resourceServiceFill.svg';
import iconResourceFunds from 'src/assets/resourceMoneyFill.svg';
import iconResourceSpace from 'src/assets/resourceSpaceFill.svg';
import iconDoubleCheck from 'src/assets/iconDoubleCheck.svg';
import handshake from 'src/assets/handshake.png';
import iconDoubleArrowRight from 'src/assets/iconDoubleArrowRight.svg';
import illustration from 'src/assets/illustrationPreservedFlower.svg';
import closeButton from 'src/assets/iconClose.svg';

import EventDemandsPageViewModel from './vm';
import styles from './styles.module.scss';
import 'swiper/css';

const resourceIcons = {
  service: iconResourceService,
  funds: iconResourceFunds,
  space: iconResourceSpace
};

@observer
class EventDemandsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new EventDemandsPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  renderMyEvents() {
    if (!this.vm.myEvents || this.vm.myEvents?.length === 0) return null;
    return (
      <EventCarousel
        items={this.vm.myEvents}
        initialIndex={this.vm.initialIndex}
        onChange={this.vm.onEventSwiperChange}
      />
    );
  }

  renderDemands(type) {
    const demands = this.vm[type];
    return (
      <div className={styles.background}>
        <div className={styles.demandsContainer}>
          <div className={styles.tips}>
            <div className={styles.title}>{t('event_demands_tips_for_selecting_title')}</div>
            <div className={styles.tip}>
              <Icon
                size={24}
                svg
                className={styles.doubleCheck}
              >
                {iconDoubleCheck}
              </Icon>
              <div>
                {t('event_demands_tips_for_selecting_1')}
              </div>
            </div>
            <div className={styles.tip}>
              <Icon
                size={24}
                svg
                className={styles.doubleCheck}
              >
                {iconDoubleCheck}
              </Icon>
              <div>
                {t('event_demands_tips_for_selecting_2')}
              </div>
            </div>
          </div>

          <div className={styles.listContainer}>
            {demands.map((d) => (
              <DemandCard
                key={d.id}
                type={d.type}
                onClick={() => this.vm.toApplications(d.id)}
                demand={d}
                className={styles.demandCard}
                footer="review"
                showAccepted
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {

    return (
      <div className={clsx(styles.eventDemandsPage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          footerStyle={{ marginTop: 40 }}
        >
          <Breadcrumb
            routes={[
              { label: t('event_hosted_title'), path: '/user/event-hosted' },
              { label: t('manage_applications'), path: '' }
            ]}
            right={null}
            withHeader
          />
          {this.renderMyEvents()}
          <div className={styles.mainContainer}>
            <img
              src={handshake}
              alt="handshake"
              className={clsx(styles.illustration, styles.marginTop10)}
            />
            <div className={clsx(styles.sectionTitles, styles.contentBox1)}>
              <span>{t('event_demands_title')}</span>
            </div>
            <div className={styles.tabsContainer}>
              <Tabs
                colorMode={2}
                items={Object.values(RESOURCE_TYPES).map((type) => {
                  return {
                    value: type,
                    label: (
                      <div className={styles.flexRow}>
                        <span>{t(`resources_${type}`)}</span>
                        <Icon
                          size={24}
                          svg
                          style={{ marginLeft: 2 }}
                        >
                          {resourceIcons[type]}
                        </Icon>
                      </div>
                    ),
                    content: this.renderDemands(type)
                  };
                })}
                onSwitchTab={this.vm.switchTab}
                currentValue={this.vm.currentTab}
                className={styles.tabs}
                centered
              />
            </div>
          </div>

          {this.vm.hasRecommendResource && this.vm.isRecommendPopupOpen && (
            <div
              className={styles.recommendedResourcesPopup}
              onClick={this.vm.toRecommendedResources}
            >
              <img
                alt="painter"
                src={illustration}
                className={styles.illustration}
              />
              <div className={styles.grow}>
                <div>{t('event_demands_recommended_1')}</div>
              </div>
              <img
                alt="arrow"
                src={iconDoubleArrowRight}
              />
              <div
                onClick={this.vm.closeRecommendPopup}
                className={styles.close}
              >
                <img
                  alt="close"
                  src={closeButton}
                />
              </div>
            </div>
          )}

        </MainLayout>
      </div>
    );
  }
}

EventDemandsPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(EventDemandsPage)), true);
