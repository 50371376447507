import API from 'src/apis';
import Product from 'src/models/response/Product';

export default class ProductService {
  static async list(type) {
    const { data } = await API.product.list(type);
    return {
      list: data.list.map((d) => Product.fromRes(d))
    };
  }
}
