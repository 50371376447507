import React from 'react';
import PropTypes from 'prop-types';

class StepBase extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  componentDidMount() {
    const { vm } = this.props;
    vm.startStepTiming();
  }

  componentWillUnmount() {
    const { vm } = this.props;
    vm.stopStepTiming();
  }
}

StepBase.propTypes = {
  vm: PropTypes.object
};

StepBase.defaultProps = {
  vm: {}
};

export default StepBase;
