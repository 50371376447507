import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { RESOURCE_TYPES, EVENT_TIPS } from 'src/constants';
import TextInput from 'src/components/TextInput';
import TreeSelect from 'src/components/TreeSelect';
import withErrorInput from 'src/components/withErrorInput';
import StickyGroupContainer from 'src/pages/CreateEvent/CreateEventPage/components/StickyGroupContainer';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import styles from '../../styles.module.scss';
import Base from './Base';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorTreeSelect = withErrorInput(TreeSelect);

@observer
class Space extends Base {

  constructor(props) {
    super(props);
    this.props = props;
    this.index = props?.index;
    this.isNotFirstItem = this.index !== 0;
  }

  get type() {
    return RESOURCE_TYPES.Space;
  }

  // //////////

  render() {
    const {
      demandVM,
      index,
      isMobile,
      showPreview,
      tipHeight,
      titleAreaHeight
    } = this.props;

    const {
      demand
    } = demandVM;

    if (!demand) return null;

    const isSticky = index === 0 && !showPreview;

    return (
      <div
        key={`space_${index}`}
        className={styles.sectionWrapper}
      >
        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={tipHeight[EVENT_TIPS.DspaceName]}
            topOffset={titleAreaHeight}
          >
            <div className={styles.itemTitle}>
              {`/ ${t(`create_event_demand_${this.type}_title`)} 0${index + 1} /`}
            </div>

            <ErrorTextInput
              key="space_name"
              label={t(`demand_${this.type}_name`)}
              onChange={(e) => demandVM.onChange(e.target.value, 'name')}
              value={demand.name ?? ''}
              limit={10}
              required
              onBlur={demandVM.onSave}
            />

            {isMobile && (
              <Tips
                key="demand_space_name"
                fieldName={EVENT_TIPS.DspaceName}
                className={styles.demandNameTipContainerMobile}
              />
            )}

            {!!demandVM?.treeSelectVM?.usages && (
              <ErrorTreeSelect
                key="space_usages"
                placeholder={t('demand_space_usages_placeholder')}
                type="usages"
                showSearch
                vm={demandVM.treeSelectVM.usages}
                value={demandVM.treeSelectVM.usages?.selectedItems}
                required
                onSelect={demandVM.onSave}
              />
            )}

            <ErrorTextInput
              key="demand_size"
              label={t('demand_size')}
              onChange={(e) => demandVM.onChange(e.target.value, 'size')}
              value={demand.size ?? ''}
              required
              onBlur={demandVM.onSave}
              type="number"
              showError={!!demand.size}
              validValue={demand.isSizeValid}
              errorMessage={t('error_message_positive_number')}
            />
          </StickyGroupContainer>

          <Tips isHidden />
        </section>

        {this.renderDescriptionSection()}

        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>
            <ErrorTextInput
              key="demand_capacity"
              label={t('demand_capacity')}
              onChange={(e) => demandVM.onChange(e.target.value, 'capacity')}
              value={demand.capacity ?? ''}
              required
              onBlur={demandVM.onSave}
              type="number"
              showError={!!demand.capacity}
              validValue={demand.isCapacityValid}
              errorMessage={t('error_message_positive_integer')}
            />

            {!!demandVM?.treeSelectVM?.equipments && (
              <ErrorTreeSelect
                key="space_equipments"
                placeholder={t('demand_equipments')}
                type="equipments"
                showSearch
                vm={demandVM.treeSelectVM.equipments}
                value={demandVM.treeSelectVM.equipments?.selectedItems}
                required
                onSelect={demandVM.onSave}
                showInputForOthers
              />
            )}
          </div>
          <Tips isHidden />
        </section>

        {this.renderKeyItemDescriptionsSection()}

        {this.renderAwardsAndDescriptionSections()}

        {this.renderDatesSection()}

        {this.renderRegionsSection()}

        {this.renderRulesSection()}

        {this.renderDeleteButton()}

      </div>
    );
  }
}

export default Space;
