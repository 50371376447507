import { makeObservable, observable, action, runInAction, computed, toJS } from 'mobx';
import ConstantsStore from 'src/stores/constants';
import DemandMeta from './DemandMeta';

class Space extends DemandMeta {
  @observable capacity;
  @observable equipments;
  @observable size;
  @observable usages;

  constructor(params) {
    super(params);
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get usagesLabels() {
    return this.usages?.map((u) => ConstantsStore.usagesOptions.find((el) => el.value === u)?.label ?? '-');
  }

  @computed get equipsLabels() {
    return this.equipments?.map((equip) => ConstantsStore.equipmentsOptions.find((item) => item.value === equip)?.label ?? '-');
  }

  @computed get isComplete() {
    return this.isCommonAttrComplete
      && !!this.capacity && this.isCapacityValid
      && this.equipments?.length !== 0
      && !!this.size && this.isSizeValid
      && this.usages?.length !== 0;
  }

  @computed get isEmpty() {
    return this.isCommonAttrEmpty
      && !this.capacity
      && this.equipments?.length === 0
      && !this.size
      && this.usages?.length === 0;
  }

  @computed get isSizeValid() {
    return /^\d+(.\d*)?$/.test(this.size);
  }

  @computed get isCapacityValid() {
    return /^\d+$/.test(this.capacity);
  }

  @action deserialize = (params) => {
    const {
      capacity,
      equipments,
      size,
      usages
    } = params ?? {};

    this.capacity = capacity;
    this.equipments = equipments ?? [];
    this.size = size;
    this.usages = usages ?? [];
  };

  serialize = () => {
    return {
      ...this.serializeCommonAttr(),
      capacity: this.capacity,
      equipments: toJS(this.equipments),
      size: this.size,
      usages: toJS(this.usages)
    };
  };

  static fromRes(data) {
    return new Space(data);
  }
}

export default Space;




