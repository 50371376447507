import React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import checkboxIcon from 'src/assets/checkboxSquare.svg';
import indeterminateIcon from 'src/assets/indeterminateCheckbox.svg';
import uncheckedbox from 'src/assets/uncheckedbox.svg';

function CustomCheckbox(props) {

  const UncheckedIcon = styled('span')(({ theme }) => ({
    borderRadius: 6,
    width: 20,
    height: 20,
    backgroundImage: `url(${uncheckedbox})`,
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
    }
  }));

  const CheckedIcon = styled(UncheckedIcon)({
    backgroundColor: '#414EE1',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: `url(${checkboxIcon})`,
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#414EE1'
    }
  });

  const IndeterminateIcon = styled(UncheckedIcon)({
    backgroundColor: '#414EE1',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: `url(${indeterminateIcon})`,
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#414EE1'
    }
  });


  return (
    <Checkbox
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      indeterminateIcon={<IndeterminateIcon />}
      {...props}
    />
  );
}

export default CustomCheckbox;
