import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import i18n from 'src/i18n';
import UserService from 'src/services/user';
import ErrorService from 'src/services/errors';
import { BLOCK_REASONS, REGISTER_STEPS, VERIFY_STATUS, USER_TYPES, ORG_REVIEW_TYPES } from 'src/constants';
import ConstantsStore from 'src/stores/constants';
import IS from 'src/utils/is';

export const getBlockReason = (profile) => {
  if (!profile) {
    return BLOCK_REASONS.Login;
  }

  if (profile.emailStatus !== VERIFY_STATUS.Verified) {
    return BLOCK_REASONS.EmailValidate;
  }

  if (profile.type === USER_TYPES.Personal && profile.phoneStatus !== VERIFY_STATUS.Verified) {
    return BLOCK_REASONS.PhoneValidate;
  }

  if (profile.type === USER_TYPES.Organization) {
    switch (profile.status) {
      case ORG_REVIEW_TYPES.Pending:
        return BLOCK_REASONS.ReviewPending;
      case ORG_REVIEW_TYPES.Failed:
        return BLOCK_REASONS.ReviewFailed;
      case ORG_REVIEW_TYPES.Rejected:
        return BLOCK_REASONS.ReviewRejected;
      case null:
        return BLOCK_REASONS.Unreviewed;
      default:
    }
  }

  return null;
};

class MemberOnlyFeatureModalVM {
  @observable isModalOpen = true;

  @observable countryCode = '+886';
  @observable phone9Digit = null;
  @observable emailError = null;
  @observable isMobile = false;
  @observable blockReason = null;

  @observable timerID = null;
  @observable timerCountdown = null;

  @observable isLoading = false;
  @observable showError = false;

  STEPS = {
    Login: 'login',
    EmailValidate: 'emailValidate',
    PhoneValidate: 'phoneValidate',
    Info: 'info' // tbd
  };

  constructor(props) {
    makeObservable(this);
    this.props = props;
    runInAction(() => {
      this.isModalOpen = true;
      this.blockReason = props.blockReason;
    });
  }

  @action didMount = async (props) => {
    console.log('modal.didMount', props, this.isModalOpen);
  };

  @action toggleModal = () => {
    this.isModalOpen = !this.isModalOpen;
    console.log('toggle', this.isModalOpen);
  };

  @action openModal = () => {
    this.isModalOpen = true;
  };

  @action closeModal = () => {
    this.isModalOpen = false;
    this.clearTimer();
  };

  @action onChange = (field, value) => {
    this[field] = value;
  };

  @computed
  get isButtonDisable() {
    if (this.blockReason === BLOCK_REASONS.PhoneValidate) {
      return !this.isPhoneValid;
    }
    return false;
  }

  @computed
  get titleString() {
    switch (this.blockReason) {
      case BLOCK_REASONS.EventCreator:
        return i18n.t('error_not_event_creator');
      default:
    }
    return i18n.t('memberOnlyModal_title');
  }

  @computed
  get contentString() {
    switch (this.blockReason) {
      case BLOCK_REASONS.Login:
        return i18n.t('memberOnlyModal_need_login');
      case BLOCK_REASONS.LoginOnHomePage:
        return i18n.t('ask_for_login_modal_content');
      case BLOCK_REASONS.EmailValidate:
        return i18n.t('memberOnlyModal_need_auth_email');
      case BLOCK_REASONS.PhoneValidate:
        return i18n.t('memberOnlyModal_need_auth_phone');
      case BLOCK_REASONS.Unreviewed:
        return i18n.t('memberOnlyModal_review_unreviewed');
      case BLOCK_REASONS.ReviewPending:
        return i18n.t('memberOnlyModal_review_pending');
      case BLOCK_REASONS.ReviewFailed:
        return i18n.t('memberOnlyModal_review_failed');
      case BLOCK_REASONS.ReviewRejected:
        return i18n.t('memberOnlyModal_review_rejected');
      default:
    }
    return '';
  }

  @computed
  get buttonString() {
    switch (this.blockReason) {
      case BLOCK_REASONS.Login:
        return i18n.t('memberOnlyModal_need_login_button');
      case BLOCK_REASONS.LoginOnHomePage:
        return i18n.t('ask_for_login_modal_btn_txt');
      case BLOCK_REASONS.EmailValidate:
        return i18n.t('memberOnlyModal_need_auth_email_button');
      case BLOCK_REASONS.PhoneValidate:
        return i18n.t('memberOnlyModal_need_auth_phone_button');
      case BLOCK_REASONS.Unreviewed:
      case BLOCK_REASONS.ReviewFailed:
        return i18n.t('memberOnlyModal_review_to_edit_button');
      case BLOCK_REASONS.ReviewPending:
      case BLOCK_REASONS.ReviewRejected:
        return i18n.t('memberOnlyModal_review_ok_button');
      default:
    }
    return i18n.t('ok');
  }

  @action onSendAuthPhone = async () => {
    this.isLoading = true;
    this.showError = true;
    if (!this.isCountryCodeValid || !this.isPhoneValid) {
      this.isLoading = false;
      return;
    }
    this.showError = false;

    if (this.countryCode === '+886' && /^09\d{8}$/.test(this.phone9Digit)) {
      this.phone9Digit = this.phone9Digit.slice(1); // 09xxxxxxxx -> 9xxxxxxxx
    }

    try {
      const res = await UserService.sendAuthPhone(this.countryCode + this.phone9Digit);
      ErrorService.onCustomError(i18n.t('register_validate_popup_success'));
      this.toRegisterPhoneValidate();
    } catch (error) {
      if (error.response?.status === 429) {
        ErrorService.onCustomError(i18n.t('too_many_requests_in_a_short_time'));
        const millis = error.response.data.data.limitedAt - Date.now();
        this.createTimer(Math.floor(millis / 1000));
      } else if (error.response?.status === 409) {
        ErrorService.onCustomError(i18n.t('register_validate_popup_phoneAlreadyExist'));
      } else {
        ErrorService.onDefaultError(error);
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action createTimer = (initial) => {
    this.timerCountdown = initial;
    const timer = setInterval(() => {
      if (this.timerCountdown - 1 < 0) {
        this.timerCountdown = null;
        this.clearTimer();
      } else {
        this.timerCountdown -= 1;
      }
    }, 1000);
    this.timerID = timer;
  };

  @action clearTimer = () => {
    clearInterval(this.timerID);
  };

  onClickButton = () => {
    if (this.blockReason === BLOCK_REASONS.Login) {
      this.toLogin();
    } else if (this.blockReason === BLOCK_REASONS.EmailValidate) {
      this.toRegisterEmailValidate();
    } else if (this.blockReason === BLOCK_REASONS.PhoneValidate) {
      this.onSendAuthPhone();
    }

    switch (this.blockReason) {
      case BLOCK_REASONS.Login:
      case BLOCK_REASONS.LoginOnHomePage:
        this.toLogin();
        break;
      case BLOCK_REASONS.EmailValidate:
        this.toRegisterEmailValidate();
        break;
      case BLOCK_REASONS.PhoneValidate:
        this.onSendAuthPhone();
        break;
      case BLOCK_REASONS.Unreviewed:
      case BLOCK_REASONS.ReviewFailed:
        this.toProfileEdit();
        break;
      case BLOCK_REASONS.ReviewPending:
      case BLOCK_REASONS.ReviewRejected:
        this.closeModal();
        break;
      default:
        this.closeModal();
    }
  };

  // validation ////////////////////////////////

  @computed
  get isCountryCodeValid() {
    return ConstantsStore.countryCodesOptions.some((opt) => opt.value === this.countryCode);
  }

  @computed
  get isPhoneValid() {
    return IS.isTWPhone(this.countryCode + this.phone9Digit);
  }

  // ////////////////////////////////////

  toRegisterEmailValidate = () => {
    console.log('go to email validate');
    const { navigate } = this.props.router;
    navigate('/register');
  };

  toRegisterPhoneValidate = () => {
    console.log('go to phone validate');
    const { navigate } = this.props.router;
    navigate('/register', { state: { step: REGISTER_STEPS.ValidatePhone, phone: this.countryCode + this.phone9Digit } });
  };

  toRegisterInfo = () => {
    console.log('go to info');
    const { navigate } = this.props.router;
    navigate('/register');
  };

  toLogin = () => {
    console.log('go to login', this.props.router?.location?.pathname);
    const { navigate, location } = this.props.router;
    navigate('/login', { state: { source: location?.pathname } });
  };

  toProfileEdit = () => {
    console.log('go to profile edit');
    const { navigate } = this.props.router;
    navigate('/user/profile/edit');
  };
}

export default MemberOnlyFeatureModalVM;
