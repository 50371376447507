import {
  makeObservable, computed, observable, action, runInAction, reaction
} from 'mobx';
import dayjs from 'dayjs';
import IS from 'src/utils/is';
import i18n from 'src/i18n';
import UserService from 'src/services/user';
import ErrorService from 'src/services/errors';
import ConstantsStore from 'src/stores/constants';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';
import UploadPhotoVM from 'src/components/UploadPhoto/vm';
import { USER_TYPES, VERIFY_STATUS, REGISTER_STEPS, SOCIALS_TYPE, ORG_TYPES_DONT_NEED_PROPS, ORG_REVIEW_TYPES } from 'src/constants';

import message from 'antd/es/message';

const lineUrl = 'https://line.me/ti/p/~';

class RegisterPageViewModel {
  @observable step = REGISTER_STEPS.Identity;

  personalStepMap = [
    [0, REGISTER_STEPS.Identity],
    [1, REGISTER_STEPS.Account],
    [1.5, REGISTER_STEPS.ModifyEmail],
    [2, REGISTER_STEPS.ValidateEmail],
    [3, REGISTER_STEPS.ValidateEnterPhoneNumber],
    [4, REGISTER_STEPS.ValidatePhone],
    [5, REGISTER_STEPS.ValidateSuccess],
    [6, REGISTER_STEPS.Sdgs],
    [7, REGISTER_STEPS.Cities],
    [8, REGISTER_STEPS.Motto],
    [9, REGISTER_STEPS.AllSuccess]
  ];

  organizationStepMap = [
    [0, REGISTER_STEPS.Identity],
    [1, REGISTER_STEPS.Account],
    [1.5, REGISTER_STEPS.ModifyEmail],
    [2, REGISTER_STEPS.ValidateEmail],
    [3, REGISTER_STEPS.Instruction],
    [4, REGISTER_STEPS.Info],
    [5, REGISTER_STEPS.Contact],
    [6, REGISTER_STEPS.Influence],
    [7, REGISTER_STEPS.Sdgs],
    [8, REGISTER_STEPS.Cities],
    [9, REGISTER_STEPS.AllSuccess]
  ];

  // common
  @observable id = null; // user id
  @observable identity;
  @observable email;
  @observable password;
  @observable passwordConfirm;
  @observable name;
  @observable displayName;
  @observable company;
  @observable countryCode = '+886';
  @observable phone9Digit; // partial; 9-digit
  @observable phone;
  @observable jobTitle;
  @observable sdgs = [];
  @observable area;
  @observable cities = [];
  @observable motto;
  @observable status;

  // personal
  @observable jobs;

  // org
  @observable avatar;
  @observable job;
  @observable taxId;
  @observable type;
  @observable foundedIn = dayjs().toISOString();
  @observable location;
  @observable address;
  @observable staff;
  @observable capitalAmount = null;
  @observable certs = [];
  @observable contact = {
    name: null,
    phone: null,
    email: null,
    jobTitle: null,
    jobUnit: null
  };
  @observable introduction;
  @observable personInCharge = {};
  @observable socials;

  // phone validate
  @observable validationCode = {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: ''
  };

  // error message
  @observable emailError = null;
  @observable passwordError = null;
  @observable passwordConfirmError = null;

  // validation status
  @observable emailStatus = null;
  @observable phoneStatus = null;

  @observable timerID = null;
  @observable timerCountdown = null;
  @observable listenID = null;

  @observable currentArea = null; // for selecting cities interested

  @observable isAwait = false;
  @observable isLoading = false;

  // view models
  @observable jobsSelectViewModel = new TreeSelectViewModel(ConstantsStore.jobsOptionsForUser);
  @observable uploadCertsVM;
  @observable uploadLogoVM;

  @observable showError = false;

  // bubble selected count (cities)
  @observable count = {};
  autoCountSelected = null;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props) => {
    console.log('RegisterPage.didMount, params', props.router.params);
    this.props = props;
    console.log(props);
    const { state } = props.router.location;

    this.autoCountSelected = reaction(() => this.cities.length, () => {
      this.areaSelectedCitiesCount();
    });

    if (UserService.isLogin) {
      try {
        const hasProfile = await this.getDetail();
        if (hasProfile) {
          this.setStep();
        }
      } catch (error) {
        ErrorService.onDefaultError(error);
      }
    }

    if (state) {
      runInAction(() => {
        // when login with unregistered email
        this.email = state.email ?? this.email;
        // from member-only-feature modal, when user tries to access member-only feature but hasn't validate their phone
        this.step = state.step ?? this.step;
        this.phone = state.phone ?? this.phone;
      });
    }

    runInAction(() => {
      this.jobsSelectViewModel.initCheckState(this.jobs ?? []);
      this.uploadCertsVM = new UploadPhotoVM({ maxCount: 3, items: this.certs });
      this.uploadLogoVM = new UploadPhotoVM({ maxCount: 1, items: this.avatar ? [{ name: 'avatar.png', url: this.avatar }] : [] });
    });

  };

  willUnmount = (props) => {
    console.log('RegisterPage.willUnmount');
    this.autoCountSelected();
    clearInterval();
  };

  getDetail = async () => {
    try {
      const res = await UserService.getDetail();
      console.log('user detail', res);
      if (res) {
        this.unserialize(res);
      }
      return true;
    } catch (error) {
      await UserService.logout();
      return false;
    }
  };

  @action setStep = () => {
    // personal
    if (this.identity === USER_TYPES.Personal) {
      if (this.emailStatus === VERIFY_STATUS.Verified) {
        if (this.phoneStatus === VERIFY_STATUS.Verified) {
          if (this.sdgs?.length === 0) {
            this.step = REGISTER_STEPS.Sdgs;
          } else if (this.cities?.length === 0) {
            this.step = REGISTER_STEPS.Cities;
          } else if (!this.motto) {
            this.step = REGISTER_STEPS.Motto;
          } else {
            this.step = REGISTER_STEPS.AllSuccess;
          }
        } else if (this.step !== REGISTER_STEPS.ValidatePhone) {
          this.step = REGISTER_STEPS.ValidateEnterPhoneNumber;
        }
      } else if (this.emailStatus === VERIFY_STATUS.Pending) {
        this.step = REGISTER_STEPS.ValidateEmail;
      } else if (this.step !== REGISTER_STEPS.Identity) {
        this.step = REGISTER_STEPS.Account;
      }

      console.log('personal', this.step);
      return;
    }

    // organization
    if (this.identity === USER_TYPES.Organization) {
      if (this.emailStatus === VERIFY_STATUS.Verified) {
        if (this.sdgs?.length === 0) {
          if (this.introduction) {
            // has finished info, contact, influence
            this.step = REGISTER_STEPS.Sdgs;
          } else {
            this.step = REGISTER_STEPS.Instruction;
          }
        } else if (this.cities?.length === 0) {
          this.step = REGISTER_STEPS.Cities;
        } else {
          this.step = REGISTER_STEPS.AllSuccess;
        }
      } else if (this.emailStatus === VERIFY_STATUS.Pending) {
        this.step = REGISTER_STEPS.ValidateEmail;

      } else if (!this.email) { // hasn't send auth email yet
        this.step = REGISTER_STEPS.Instruction;

      } else if (this.step !== REGISTER_STEPS.Identity) {
        this.step = REGISTER_STEPS.Account;
      }
      console.log('org', this.step);
    }

  };

  @action unserialize = (res) => {
    const {
      id,
      type,
      name,
      displayName,
      phone,
      phoneStatus,
      email,
      emailStatus,
      info,
      avatar,
      status
    } = res;
    this.id = id;
    this.emailStatus = emailStatus;
    this.phoneStatus = phoneStatus;
    this.identity = type;
    this.email = this.email ?? email;
    this.phone = this.phone ?? phone;
    if (phone) {
      this.phone9Digit = phone.slice(-9);
      this.countryCode = phone.slice(0, phone.length - 9);
      if (this.countryCode === '+8860') {
        this.countryCode = '+886';
      }
    }
    this.name = this.name ?? name;
    this.displayName = this.displayName ?? displayName;
    this.company = this.company ?? info.company;
    this.jobTitle = this.jobTitle ?? info.jobTitle;
    this.sdgs = this.sdgs.length === 0 ? info.sdgs : this.sdgs;
    this.cities = this.cities.length === 0 ? info.cities : this.cities;
    this.motto = this.motto ?? info.motto;
    this.status = status;

    if (this.identity === USER_TYPES.Organization) {
      this.job = this.job ?? info.job;
      this.type = this.type ?? info.type;
      this.capitalAmount = this.capitalAmount ?? info.capitalAmount;
      this.taxId = this.taxId ?? info.taxId;
      this.foundedIn = info.foundedIn;
      this.staff = this.staff ?? info.staff;
      this.location = this.location ?? info.location;
      this.address = this.address ?? info.address;
      this.contact.name = this.contact.name ?? info.contact?.name;
      this.contact.jobUnit = this.contact.jobUnit ?? info.contact?.jobUnit;
      this.contact.jobTitle = this.contact.jobTitle ?? info.contact?.jobTitle;
      this.contact.phone = this.contact.phone ?? info.contact?.phone;
      this.contact.email = this.contact.email ?? info.contact?.email;
      this.certs = this.certs.length === 0 ? info.certs : this.certs;
      this.personInCharge = info.personInCharge;
      this.introduction = this.introduction ?? info.introduction;
      this.socials = this.unserializeSocials(info.socials);
      this.avatar = this.avatar ?? avatar;
    } else {
      this.jobs = this.jobs ?? info.jobs;
    }
  };

  unserializeSocials = (data) => {
    return data.map((s) => {
      try {
        const obj = JSON.parse(s);
        this[obj.name] = obj.url;
        return obj;
      } catch (e) {
        console.log(e);
        return {};
      }
    });
  };

  getSocialValue = (type) => {
    if (type === SOCIALS_TYPE.Line) {
      return this.socials?.find((s) => s.name === type)?.url?.split('~')[1] ?? '';
    }
    return this.socials?.find((s) => s.name === type)?.url ?? '';
  };

  @computed
  get isOrgStep1Disable() {
    if (!this.emailError && !this.passwordError && !this.passwordConfirmError
      && this.email && this.password && this.passwordConfirm && this.displayName && this.contact.name) {
      return false;
    }

    return true;
  }

  @computed
  get isOrgStep3Disable() {
    if (this.contact.name && this.contact.phone && this.contact.email && this.contact.jobTitle && this.contact.jobUnit) {
      return false;
    }

    return true;
  }

  @action onChange = (field, value, limit = null) => {
    if (limit) {
      if (value.toString().length <= limit) {
        this[field] = value;
      }
    } else {
      this[`${field}`] = value;
    }

    if (field === 'email' || field === 'password' || field === 'passwordConfirm') {
      this[`${field}Error`] = null;
      if (this.emailError) {
        this.onCheckEmail();
      } else if (this.passwordError || this.passwordConfirmError) {
        this.onCheckPassword();
        this.onCheckPasswordConfirm();
      }
    }
  };

  @action onContactChange = (field, value) => {
    if (field === 'phone') {
      if (IS.phone(value) || !value) {
        this.contact.phone = value;
      }
    } else {
      this.contact[`${field}`] = value;
    }
  };

  @action onPersonInChargeChange = (field, value) => {
    this.personInCharge[`${field}`] = value;
  };

  @action onSocialsChange = (field, value) => {
    const index = this.socials.findIndex((s) => s.name === field);
    console.log(field, value, index);
    if (index < 0) {
      this.socials.push({ name: field, url: value });
    } else if (field === SOCIALS_TYPE.Line) {
      this.socials[index].url = lineUrl + value;
    } else {
      this.socials[index].url = value;
    }
    console.log(this.socials);
  };

  @action onChangeStep = (step) => {
    window.scroll(0, 0);
    this.step = step;
  };

  @action onNextClick = () => {
    window.scroll(0, 0);
    const current = this[`${this.identity}StepMap`].find((s) => s[1] === this.step);
    if (current[0] + 1 > 9) {
      return;
    }

    let nextNumber;
    if (current[0] === 1.5) {
      nextNumber = 2;
    } else {
      nextNumber = current[0] + 1;
    }
    this.step = this[`${this.identity}StepMap`].find((s) => s[0] === nextNumber)[1];
    console.log('next', current, this.step);
  };

  @action onPreviousClick = () => {
    window.scroll(0, 0);
    const { navigate } = this.props.router;
    const current = this[`${this.identity}StepMap`].find((s) => s[1] === this.step);
    if (current[0] < 1 || (this.step === REGISTER_STEPS.ValidateSuccess || this.step === REGISTER_STEPS.Instruction)) {
      navigate(-1);
      return;
    }

    let previousNumber;
    if (current[0] === 1.5) {
      previousNumber = 1;
    } else {
      previousNumber = current[0] - 1;
    }
    this.step = this[`${this.identity}StepMap`].find((s) => s[0] === previousNumber)[1];
    console.log('previous', current, this.step);
  };

  onCheckEmail = async () => {
    if (!IS.email(this.email)) {
      this.emailError = i18n.t('register_emailWrongFormat');
    } else {
      const res = await UserService.exists(this.email);
      if (res.status === 404) {
        this.emailError = null;
      } else {
        this.emailError = i18n.t('register_emailDuplicate');
      }
    }
  };

  @action onCheckPassword = () => {
    if (!IS.password(this.password)) {
      this.passwordError = i18n.t('register_passwordWrongFormat');
    } else {
      this.passwordError = null;
    }
  };

  onCheckPasswordConfirm = () => {
    if (this.password === this.passwordConfirm) {
      this.passwordConfirmError = null;
    } else {
      this.passwordConfirmError = i18n.t('register_passwordInconsistent');
    }
  };

  @action.bound
  areaSelectedCitiesCount() {
    this.count = {};

    this.cities.forEach((city) => {
      const area = city.split('-')[1];
      if (this.count[area]) {
        this.count[area] += 1;
      } else {
        this.count[area] = 1;
      }
    });
  }

  @action onPersonalSignUp = async () => {
    this.isAwait = true;

    this.onCheckEmail();
    this.onCheckPassword();
    this.onCheckPasswordConfirm();
    if (this.emailError || this.passwordError || this.passwordConfirmError) {
      return;
    }

    try {
      const res = await UserService.signUp(this.identity, this.email, this.password);
      runInAction(() => {
        this.id = res.data.profile.id;
      });
    } catch (error) {
      ErrorService.onDefaultError(error);
    }

    const newProfile = {
      name: this.name,
      displayName: this.displayName ?? this.name,
      jobs: this.jobsSelectViewModel.selectedValue,
      company: this.company,
      jobTitle: this.jobTitle
    };

    try {
      await UserService.updateProfile(newProfile);
      this.onSendAuthEmail();
      this.onNextClick();
    } catch (error) {
      ErrorService.onDefaultError(error);
    } finally {
      runInAction(() => {
        setTimeout(() => {
          this.isAwait = false;
        }, 500);
      });
    }

  };

  @action onOrganizationSignUp = async () => {
    this.isAwait = true;

    try {
      await UserService.signUp(this.identity, this.email, this.password);
    } catch (error) {
      ErrorService.onDefaultError(error);
    }

    const newProfile = {
      displayName: this.displayName,
      contact: this.contact
    };

    try {
      const res = await UserService.updateProfile(newProfile);
      runInAction(() => {
        this.id = res?.profile?.id;
      });
      this.onSendAuthEmail();
      this.onNextClick();
    } catch (error) {
      ErrorService.onDefaultError(error);
    } finally {
      runInAction(() => {
        setTimeout(() => {
          this.isAwait = false;
        }, 500);
      });
    }

  };

  onModifyEmail = async () => {
    await this.onCheckEmail();
    if (this.emailError) return;

    this.isLoading = true;
    const res = await this.onSendAuthEmail();
    if (res.status === 201) {
      this.onNextClick();
    }
    this.isLoading = false;
  };

  @action onSendAuthEmail = async () => {
    this.isLoading = true;
    const res = await UserService.sendAuthEmail(this.email);
    if (res.status === 429) {
      message.error(i18n.t('too_many_requests_in_a_short_time'));

      const millis = res.data.data.limitedAt - Date.now();
      this.createTimer(Math.floor(millis / 1000));

    } else {
      this.clearTimer();
      this.createTimer(180);
      this.onListenValidateEmail();
    }

    this.isLoading = false;
    return res;
  };

  @action onSendAuthPhone = async () => {
    this.showError = true;
    if (!this.isPhoneValid) {
      return;
    }

    if (this.countryCode === '+886' && /^09\d{8}$/.test(this.phone9Digit)) {
      this.phone9Digit = this.phone9Digit.slice(1); // 09xxxxxxxx -> 9xxxxxxxx
    }

    this.phone = this.countryCode + this.phone9Digit;

    this.isLoading = true;
    try {
      const res = await UserService.sendAuthPhone(this.countryCode + this.phone9Digit);
      runInAction(() => {
        this.step = REGISTER_STEPS.ValidatePhone;
      });
      this.clearTimer();
      this.createTimer(180);
    } catch (error) {
      if (error.response?.status === 429) {
        ErrorService.onCustomError(i18n.t('too_many_requests_in_a_short_time'));
        const millis = error.response.data.data.limitedAt - Date.now();
        this.createTimer(Math.floor(millis / 1000));
      } else if (error.response?.status === 409) {
        ErrorService.onCustomError(i18n.t('register_validate_popup_phoneAlreadyExist'));
      } else {
        ErrorService.onDefaultError(error);
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.showError = false;
      });
    }
  };

  @action onFinishOrganizationInfo = async () => {
    this.isAwait = true;

    this.showError = true;
    if (!this.isTaxIdValid) {
      this.isAwait = false;
      ErrorService.onCustomError(i18n.t('fill_fields_correctly_before_save'));
      return;
    }

    try {
      await this.uploadCertsVM.onPhotosUpload(this.id);
      await this.uploadLogoVM.onPhotosUpload(this.id);
    } catch (err) {
      console.log(err);
    }

    const isAvatarUpdated = !!this.uploadLogoVM?.postList[0].size;

    const newProfile = {
      name: this.name,
      displayName: this.displayName,
      personInCharge: this.personInCharge,
      taxId: this.taxId,
      job: this.job,
      type: this.type,
      foundedIn: this.foundedIn,
      location: this.location,
      address: this.address,
      certs: this.uploadCertsVM?.postList
    };

    if (this.needStaff) newProfile.staff = this.staff;
    if (this.needCapitalAmount) newProfile.capitalAmount = this.capitalAmount;

    if (isAvatarUpdated) {
      newProfile.avatar = this.uploadLogoVM?.postList[0];
    }

    try {
      const res = await UserService.updateProfile(newProfile);
      runInAction(() => {
        this.showError = false;
        this.onNextClick();
      });

    } catch (error) {
      ErrorService.onDefaultError(error);
    } finally {
      runInAction(() => {
        setTimeout(() => {
          this.isAwait = false;

        }, 500);
      });
    }
  };

  @action updateCerts = (list) => {
    this.certs = list;
  };

  @action onFinishContactInfo = async () => {
    this.isLoading = true;
    this.showError = true;
    if (!this.isContactPhoneValid || !this.isContactEmailValid) {
      this.isLoading = false;
      ErrorService.onCustomError(i18n.t('fill_fields_correctly_before_save'));
      return;
    }
    const newProfile = {
      contact: this.contact
    };

    const res = await UserService.updateProfile(newProfile);
    this.isLoading = false;
    this.showError = false;
    this.onNextClick();
  };

  @action onFinishOrgInfluence = async () => {
    this.isLoading = true;
    const hasError = !this.isSocialsValid || !this.isIntroductionValid || !this.isMottoValid;
    this.showError = hasError;
    if (hasError) {
      this.isLoading = false;
      ErrorService.onCustomError(i18n.t('fill_fields_correctly_before_save'));
      return;
    }

    const newProfile = {
      introduction: this.introduction,
      motto: this.motto,
      socials: this.socials.map((s) => JSON.stringify(s))
    };

    const res = await UserService.updateProfile(newProfile);
    this.isLoading = false;
    this.showError = false;
    this.onNextClick();
  };

  // //////////////////////////////////////////////////

  @action onCodeChange = (value, digit) => {
    if (value.toString().length !== 1) {
      this.validationCode[digit] = value.toString().slice(-1);
    } else {
      this.validationCode[digit] = value;
    }
  };

  @action onValidateCode = async () => {
    const code = Object.values(this.validationCode).join('');
    this.isLoading = true;
    try {
      const res = await UserService.authPhone(code);
      this.onNextClick();
    } catch (error) {
      if (error.response?.status === 403) {
        ErrorService.onCustomError(i18n.t('register_validate_personal_step3_codeExpire'));
      } else if (error.response?.status === 404) {
        ErrorService.onCustomError(i18n.t('register_validate_personal_step3_codeError'));
      } else {
        ErrorService.onDefaultError(error);
      }
    } finally {
      this.isLoading = false;
    }

  };

  @action onFinishSdgs = async () => {
    this.isLoading = true;
    await UserService.updateProfile({
      sdgs: this.sdgs
    });
    this.isLoading = false;
    this.onNextClick();
  };

  @action onFinishCities = async () => {
    this.isLoading = true;
    await UserService.updateProfile({
      cities: this.cities
    });

    if (this.identity === USER_TYPES.Organization) {
      await this.requestOrgAuth();
    }

    runInAction(() => {
      this.isLoading = false;
    });
    this.onNextClick();
  };

  @action requestOrgAuth = async () => {
    console.log('request');
    try {
      const res = await UserService.submit();
    } catch (error) {
      if (error.response?.status === 409) {
        ErrorService.onCustomError(i18n.t('org_send_auth_409'));
      } else {
        ErrorService.onDefaultError(error);
      }
    }
  };

  @action onFinishMotto = async () => {
    this.isLoading = true;
    await UserService.updateProfile({
      motto: this.motto
    });
    this.isLoading = false;
    this.onNextClick();
  };

  @action createTimer = (initial) => {
    this.timerCountdown = initial;
    const timer = setInterval(() => {
      if (this.timerCountdown - 1 < 0) {
        runInAction(() => {
          this.timerCountdown = null;
        });
        this.clearTimer();
      } else {
        runInAction(() => {
          this.timerCountdown -= 1;
        });
      }
    }, 1000);
    this.timerID = timer;
  };

  @action clearTimer = () => {
    clearInterval(this.timerID);
    this.timerCountdown = null;
  };

  onListenValidateEmail = () => {
    const timer = setInterval(async () => {
      await this.getDetail();
      if (this.emailStatus === VERIFY_STATUS.Verified) {
        console.log('---verified---');
        clearInterval(timer);
        this.clearTimer();
        this.onNextClick();
      }
    }, 5000);
    runInAction(() => {
      this.listenID = timer;
    });
  };

  @action handleToggleSdg = (value, add) => {
    if (add) {
      this.sdgs.push(value);
    } else {
      this.sdgs = this.sdgs.filter((sdg) => sdg !== value);
    }
  };

  @action handleToggleCity = (value) => {
    if (!this.cities.includes(value)) {
      this.cities.push(value);
    } else {
      this.cities = this.cities.filter((city) => city !== value);
    }
  };

  @action handleSwitchArea = (value) => {
    if (this.currentArea === value) {
      this.currentArea = null;
    } else {
      this.currentArea = value;
    }
  };

  onClickModifyEmail = () => {
    this.onChangeStep(REGISTER_STEPS.ModifyEmail);
    clearInterval(this.listenID);
  };

  // check if value is valid
  @computed
  get isTaxIdValid() {
    if (!this.taxId) return false;
    return IS.taxId(this.taxId);
  }

  @computed
  get isPhoneValid() {
    return IS.isTWPhone(this.countryCode + this.phone9Digit);
  }

  @computed
  get isContactPhoneValid() {
    return this.contact.phone?.toString()?.length > 5;
  }

  @computed
  get isContactEmailValid() {
    return IS.email(this.contact.email);
  }

  @computed
  get hasInfluence() {
    return !!this.getSocialValue(SOCIALS_TYPE.Influence);
  }

  @computed
  get isInfluenceValid() {
    const value = this.getSocialValue(SOCIALS_TYPE.Influence);
    return !this.hasInfluence || IS.url(value);
  }

  @computed
  get isMottoValid() {
    // return this.motto?.length <= 20;
    return IS.motto(this.motto);
  }

  @computed
  get isIntroductionValid() {
    // return this.introduction?.length <= 300;
    return IS.orgIntroduction(this.introduction);
  }

  @computed
  get hasWebsite() {
    return !!this.getSocialValue(SOCIALS_TYPE.Website);
  }

  @computed
  get isWebsiteValid() {
    const value = this.getSocialValue(SOCIALS_TYPE.Website);
    return !this.hasWebsite || IS.url(value);
  }

  @computed
  get hasFacebook() {
    return !!this.getSocialValue(SOCIALS_TYPE.Facebook);
  }

  @computed
  get isFacebookValid() {
    const value = this.getSocialValue(SOCIALS_TYPE.Facebook);
    return !this.hasFacebook || IS.facebook(value);
  }

  @computed
  get hasLinkedin() {
    return !!this.getSocialValue(SOCIALS_TYPE.Linkedin);
  }

  @computed
  get isLinkedinValid() {
    const value = this.getSocialValue(SOCIALS_TYPE.Linkedin);
    return !value || IS.linkedin(value);
  }

  @computed
  get hasInstagram() {
    return !!this.getSocialValue(SOCIALS_TYPE.Instagram);
  }

  @computed
  get isInstagramValid() {
    const value = this.getSocialValue(SOCIALS_TYPE.Instagram);
    return !value || IS.instagram(value);
  }

  @computed
  get hasYoutube() {
    return !!this.getSocialValue(SOCIALS_TYPE.Youtube);
  }

  @computed
  get isYoutubeValid() {
    const value = this.getSocialValue(SOCIALS_TYPE.Youtube);
    return !value || IS.youtube(value);
  }

  @computed
  get isSocialsValid() {
    return this.isInfluenceValid && this.isWebsiteValid && this.isFacebookValid && this.isLinkedinValid && this.isInstagramValid && this.isYoutubeValid;
  }

  // //////////////////////////

  @computed
  get isButtonDisabled() {
    switch (this.step) {
      case REGISTER_STEPS.Account:
        if (this.identity === USER_TYPES.Organization) {
          return !this.email || this.emailError
          || !this.password || this.passwordError
          || !this.passwordConfirm || this.passwordConfirmError
          || !this.displayName || !this.contact.name;
        }

        if (this.identity === USER_TYPES.Personal) {
          return !this.email || this.emailError
          || !this.password || this.passwordError
          || !this.passwordConfirm || this.passwordConfirmError
          || !this.name
          || this.jobsSelectViewModel.isEmpty
          || !this.company
          || !this.jobTitle;
        }

        return false;

      case REGISTER_STEPS.Info:
        return !this.name
        || !this.displayName
        || !this.taxId
        || !this.job
        || !this.type
        || !this.foundedIn
        || !this.location
        || !this.address
        || (this.needStaff && !this.staff)
        || (this.needCapitalAmount && (this.capitalAmount === null || this.capitalAmount === undefined))
        || this.uploadCertsVM?.postList?.length === 0
        || this.uploadLogoVM?.postList?.length === 0;

      case REGISTER_STEPS.Influence:
        return !this.introduction
        || !this.motto;

      default:
    }

    return false;
  }

  // ///////////////
  @computed
  get needStaff() {
    if (ORG_TYPES_DONT_NEED_PROPS.Staff.includes(this.type)) {
      return false;
    }
    return true;
  }

  @computed
  get needCapitalAmount() {
    if (ORG_TYPES_DONT_NEED_PROPS.CapitalAmount.includes(this.type)) {
      return false;
    }
    return true;
  }

  @computed
  get isAllowedToEditAll() {
    return !this.status || this.status === ORG_REVIEW_TYPES.Failed;
  }

}

export default RegisterPageViewModel;
