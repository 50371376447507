import { makeObservable, observable, action, runInAction } from 'mobx';
import i18n from 'src/i18n';

import ForgetPasswordModalVM from 'src/components/Modals/ForgetPasswordModal/vm';
import UserService from 'src/services/user';
import ErrorService from 'src/services/errors';
import IS from 'src/utils/is';
import { message } from 'antd';

class ResetPasswordPageViewModel {
  @observable token = null;
  @observable isSuccess = false;

  @observable email = '';
  @observable password = '';
  @observable passwordConfirm = '';
  @observable emailError = null;
  @observable passwordError = null;
  @observable passwordConfirmError = null;

  @observable forgetPasswordModalVM;

  @observable timerID = null;
  @observable timerCountdown = null;

  constructor(props) {
    makeObservable(this);
    this.props = props;
    console.log(props);
    runInAction(() => {
      this.email = props.router.location.state?.email ?? '';
    });
  }

  @action didMount = (props) => {
    console.log('ResetPasswordPage.didMount, params', props.router.params);
    this.token = props.router.location.search?.split('=')[1];
    console.log('token', this.token);
  };

  willUnmount = (props) => {
    console.log('ResetPasswordPage.willUnmount');
  };

  @action clickModifyEmail = () => {
    if (this.forgetPasswordModalVM) {
      this.forgetPasswordModalVM.isForgetPasswordModalOpen = true;
    } else {
      this.forgetPasswordModalVM = new ForgetPasswordModalVM({ ...this.props, onModifyEmail: this.onModifyEmail });
    }
  };

  resendEmail = async () => {
    if (this.email) {
      const res = await UserService.sendResetPasswordEmail(this.email);
      if (res.status === 201) {
        message.success(i18n.t('send_reset_password_email_success'));
      } else if (res.status === 429) {
        message.info(i18n.t('too_many_requests_in_a_short_time'));
        const millis = res.data.data.limitedAt - Date.now();
        this.createTimer(Math.floor(millis / 1000));
      }
    }
  };

  @action onModifyEmail = (value) => {
    this.email = value;
  };

  @action onEmailChange = (e) => {
    this.email = e.target.value;
  };

  onCheckPassword = () => {
    if (!IS.password(this.password)) {
      this.passwordError = i18n.t('register_passwordWrongFormat');
    } else {
      this.passwordError = null;
    }
  };

  onCheckPasswordConfirm = () => {
    if (this.password === this.passwordConfirm) {
      this.passwordConfirmError = null;
    } else {
      this.passwordConfirmError = i18n.t('register_passwordInconsistent');
    }
  };

  @action onPasswordChange = (e) => {
    this.password = e.target.value;
  };

  @action onPasswordConfirmChange = (e) => {
    this.passwordConfirm = e.target.value;
  };

  @action resetPassword = async () => {
    this.onCheckPassword();
    this.onCheckPasswordConfirm();
    if (!this.passwordError && !this.passwordConfirmError) {
      console.log('reset password');
      try {
        const res = await UserService.resetPassword(this.token, this.password);
        this.isSuccess = true;
      } catch (error) {
        if (error.response?.status === 401) {
          ErrorService.onCustomError(i18n.t('token_expired'));
        }
      }
    }
  };

  toLogin = () => {
    const { navigate } = this.props.router;
    navigate('/login');
  };

  @action createTimer = (initial) => {
    this.timerCountdown = initial;
    const timer = setInterval(() => {
      if (this.timerCountdown - 1 < 0) {
        this.timerCountdown = null;
        this.clearTimer();
      } else {
        this.timerCountdown -= 1;
      }
    }, 1000);
    this.timerID = timer;
  };

  @action clearTimer = () => {
    clearInterval(this.timerID);
  };

}

export default ResetPasswordPageViewModel;
