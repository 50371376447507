import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Button from 'src/components/Button';
import illustration from 'src/assets/illustrationCreateEvent.svg';

import CreateEventIntroPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class CreateEventIntroPage extends React.Component {
  constructor() {
    super();

    this.vm = new CreateEventIntroPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  handleLineBreak = (str) => {
    if (str.includes('\\n')) {
      return (
        <>
          {str.split('\\n')[0]}
          <br />
          {str.split('\\n')[1]}
        </>
      );
    }
    return str;
  };

  renderContent() {
    return (
      <div className={styles.content}>
        <div className={styles.title}>
          {this.handleLineBreak(t('create_event_intro_title'))}
        </div>

        <div className={clsx(styles.text, styles.marginBottom30)}>
          {t('create_event_intro_desc')}
        </div>

        <div className={styles.grayBlock}>
          <div className={styles.stepRow}>
            <span className={styles.stepName}>
              {t('create_step_keyFinding')}
            </span>

            <span className={styles.text}>
              {t('create_event_intro_keyFinding_desc')}
            </span>
          </div>

          <div className={styles.stepRow}>
            <span className={styles.stepName}>
              {t('create_step_vision')}
            </span>

            <span className={styles.text}>
              {t('create_event_intro_vision_desc')}
            </span>
          </div>

          <div className={styles.stepRow}>
            <span className={styles.stepName}>
              {t('create_step_keyItem')}
            </span>

            <span className={styles.text}>
              {t('create_event_intro_keyItem_desc')}
            </span>
          </div>
        </div>

        <div className={styles.grayBlock}>
          <div>
            {t('create_event_intro_estimated_time')}
          </div>

          <div className={styles.row}>
            <span className={styles.textSm}>
              {t('create_event_intro_estimated_time_type1')}
            </span>
            <span className={styles.time}>
              {t('create_event_intro_estimated_time_type1_bold')}
            </span>
            <span className={styles.textSm}>
              /
            </span>
            <span className={styles.textSm}>
              {t('create_event_intro_estimated_time_type2')}
            </span>
            <span className={styles.time}>
              {t('create_event_intro_estimated_time_type2_bold')}
            </span>
          </div>
        </div>

        <Button
          onClick={() => this.vm.toCreateEvent()}
          className={styles.nextButton}
          withRightArrow
        >
          {t('next_step')}
        </Button>
      </div>
    );
  }


  render() {

    return (
      <div className={styles.introPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideFooter
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_event'), path: '/create-event-overview' },
              { label: t('breadcrumb_create_event'), path: '' }
            ]}
            right={null}
          />

          <div className={styles.wrapper}>
            <div className={styles.purpleBg}>
              <div className={styles.imgContainer}>
                <img
                  className={styles.img}
                  src={illustration}
                  alt="city"
                />
              </div>
            </div>

            <div className={styles.mainWrapper}>
              {this.renderContent()}
            </div>

          </div>

        </MainLayout>
      </div>
    );
  }
}

CreateEventIntroPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(CreateEventIntroPage)), true);
