import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { SOCIALS_TYPE } from 'src/constants';
import IS from 'src/utils/is';

const lineUrl = 'https://line.me/ti/p/~';

class Socials {
  @observable socials; // string
  @observable socialsArr = [];
  @observable facebook = null;
  @observable instagram = null;
  @observable line = '';
  @observable lineId;
  @observable linkedin = null;
  @observable website = null;
  @observable youtube = null;
  @observable influence = null;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @action onChange = (key, value) => {
    this[key] = value;
  };

  @computed get isValid() {
    return {
      [SOCIALS_TYPE.Facebook]: !this.facebook || IS.facebook(this.facebook),
      [SOCIALS_TYPE.Instagram]: !this.instagram || IS.instagram(this.instagram),
      [SOCIALS_TYPE.Linkedin]: !this.linkedin || IS.linkedin(this.linkedin),
      [SOCIALS_TYPE.Influence]: !this.influence || IS.url(this.influence),
      [SOCIALS_TYPE.Website]: !this.website || IS.url(this.website),
      [SOCIALS_TYPE.Youtube]: !this.youtube || IS.youtube(this.youtube)
    };
  }

  static fromRes(data) {
    return new Socials(data);
  }

  @action deserialize = (data) => {
    const arr = data?.map((s) => {
      try {
        const obj = JSON.parse(s);
        if (obj.name === SOCIALS_TYPE.Line) {
          this.lineId = obj.url?.split('~')[1] ?? '';
        } else {
          this[obj.name] = obj.url;
        }
        return obj;
      } catch (e) {
        console.log(e);
        return {};
      }
    }) ?? [];

    this.socialsArr = arr;
  };

  serialize = () => {
    const arr = [
      JSON.stringify({ name: SOCIALS_TYPE.Influence, url: this.influence }),
      JSON.stringify({ name: SOCIALS_TYPE.Facebook, url: this.facebook }),
      JSON.stringify({ name: SOCIALS_TYPE.Instagram, url: this.instagram }),
      JSON.stringify({ name: SOCIALS_TYPE.Line, url: this.lineId ? lineUrl + this.lineId : null }),
      JSON.stringify({ name: SOCIALS_TYPE.Linkedin, url: this.linkedin }),
      JSON.stringify({ name: SOCIALS_TYPE.Website, url: this.website }),
      JSON.stringify({ name: SOCIALS_TYPE.Youtube, url: this.youtube })
    ];

    return arr;
  };

}

export default Socials;
