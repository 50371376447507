// register for an event
import API from 'src/apis';
import Application from 'src/models/response/Application';

export default class RegisterService {
  static async create(data) {
    const res = await API.register.create(data);
    return res;
  }

  static async getList(params, body) {
    const { data } = await API.register.getList(params, body);
    return {
      list: data.list.map((el) => Application.fromRes(el)),
      anchor: data.anchor
    };
  }

  static async update(id, data) {
    const res = await API.register.update(id, data);
    return res;

  }

  static async detail(id) {
    const { data } = await API.register.detail(id);
    return Application.fromRes(data);
  }

  static async exportList(id) {
    const { data } = await API.register.exportList(id);
    console.log(data);
    // const url = window.URL.createObjectURL(new Blob([data]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'applications.csv'); // or any other extension
    // document.body.appendChild(link);
    // link.click();
    return data;
  }

  static async withdraw(id) {
    const res = await API.register.withdraw(id);
    return res;
  }
}
