import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import iconArrow from 'src/assets/iconArrow.svg';
import Icon from 'src/components/Icon';
import { Collapse } from '@mui/material';

import ChildNode from '../ChildNode';
import Checkbox from '../Checkbox';

@observer
class ParentNode extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.vm.didMount();
  }

  render() {
    const { vm, searchText, showInputForOthers, containerStyle } = this.props;

    return (
      <div key={vm.label} style={{ marginLeft: 8, ...containerStyle }}>
        <ListItem
          onClick={(e) => {
            e.stopPropagation();
            vm.toggleChecked(!vm.checked);
          }}
          secondaryAction={(
            <Icon
              size={12}
              onClick={vm.handleClick}
              svg
              style={{
                transition: 'all 0.3s',
                transform: vm.collapsed ? 'rotate(180deg)' : 'unset',
                width: 40,
                height: 40,
                marginRight: -10,
                verticalAlign: 'center',
                backgroundPosition: 'center'
              }}
            >
              {iconArrow}
            </Icon>
          )}
          sx={{
            cursor: 'pointer'
          }}
        >
          <ListItemIcon sx={{ minWidth: 45 }}>
            <Checkbox
              indeterminate={vm.indeterminate}
              checked={vm.checked}
              edge="start"
            />
          </ListItemIcon>
          <ListItemText>{vm.label}</ListItemText>
        </ListItem>
        <Collapse in={vm.collapsed}>
          {
            vm.children.map((el) => {
              if (el.children) {
                return (
                  <ParentNode
                    key={el.label}
                    vm={el}
                    searchText={searchText}
                    showInputForOthers={showInputForOthers}
                    containerStyle={{ marginLeft: 25 }}
                  />
                );
              }
              return (
                <ChildNode
                  key={el.label}
                  vm={el}
                  searchText={searchText}
                  showInputForOthers={showInputForOthers}
                />
              );
            })
          }
        </Collapse>
      </div>
    );
  }
}

ParentNode.propTypes = {
  vm: PropTypes.object,
  showInputForOthers: PropTypes.bool,
  searchText: PropTypes.string,
  containerStyle: PropTypes.object
};

ParentNode.defaultProps = {
  vm: {},
  showInputForOthers: false,
  searchText: null,
  containerStyle: {}
};

export default ParentNode;
