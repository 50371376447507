import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Button from 'src/components/Button';
import illustration from 'src/assets/illustrationHand.jpg';
import BaseModal from '../BaseModal';

import styles from './styles.module.scss';

@observer
class ModalWithIllustration extends React.Component {

  constructor(props) {
    super(props);

    this.props = props;
  }

  renderContent() {
    const { title, content, onButtonClick, buttonText, contentNode, isMobile, showCloseIcon, onClose } = this.props;
    const getNewTitle = () => {
      if (title.includes('\\n')) {
        return (
          <>
            {title.split('\\n')[0]}
            <br />
            {title.split('\\n')[1]}
          </>
        );
      }
      return title;
    };
    return (
      <div className={styles.modal}>
        <img
          className={clsx(styles.marginBottom, styles.illustration)}
          src={illustration}
          alt="man looking at phone"
        />
        {title && (
          <h2 className={clsx(styles.title, styles.marginBottom)}>
            {getNewTitle()}
          </h2>
        )}
        {content && (
          <h3 className={clsx(styles.subtitle, styles.marginBottom)}>
            {content}
          </h3>
        )}

        {contentNode && contentNode}

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={onButtonClick}
          className={styles.button}
        >
          {buttonText}
        </Button>
      </div>
    );
  }

  render() {
    const { onClose, open, isMobile, showCloseIcon } = this.props;
    return (
      <BaseModal
        onClose={onClose}
        open={open}
        isMobile={isMobile}
        centered
        showCloseIcon={showCloseIcon}
      >
        {this.renderContent()}
      </BaseModal>
    );
  }
}

ModalWithIllustration.propTypes = {
  title: PropTypes.any,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isMobile: PropTypes.bool,
  contentNode: PropTypes.any,
  showCloseIcon: PropTypes.bool
};

ModalWithIllustration.defaultProps = {
  title: '',
  content: '',
  buttonText: '',
  onButtonClick: () => {},
  onClose: () => {},
  open: false,
  isMobile: false,
  contentNode: null,
  showCloseIcon: false
};

export default ModalWithIllustration;
