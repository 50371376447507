import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import dayjs from 'dayjs';

class RegistrationField {
  @observable type; // text, single, multiple
  @observable label;
  @observable optionsWithId = []; // { id: (timestamp), value: }
  @observable isEditing = false;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get options() {
    return this.optionsWithId?.map((opt) => opt.value)?.filter((v) => !!v) ?? [];
  }

  @computed get isComplete() {
    return !!this.type
      && !!this.label
      && (this.type === 'text' ? true : this.options.length !== 0);
  }

  @computed get isHalfComplete() {
    return !this.isComplete && !this.isEmpty;
  }

  @computed get isEmpty() {
    return !this.type && !this.label && this.options.length === 0;
  }

  @action onChange = (value, key) => {
    this[key] = value;
  };

  @action addOption = (value) => {
    this.optionsWithId.push({ id: dayjs().valueOf(), value: '' });
    console.log('added', this.optionsWithId);
  };

  @action onChangeOption = (value, index) => {
    this.optionsWithId[index].value = value;
    this.optionsWithId = [...this.optionsWithId];
    console.log(index, value, this.optionsWithId);
  };

  @action onRemoveOption = (id) => {
    this.optionsWithId = this.optionsWithId.filter((opt) => opt.id !== id);
  };

  @action removeEmptyOptions = () => {
    this.optionsWithId = this.optionsWithId.filter((opt) => !!opt.value);
  };

  @action onDragEnd = (event) => {
    const { source, destination } = event;

    if (!destination) {
      return;
    }

    // 拷貝新的 items (來自 state);
    const newItems = [...this.optionsWithId];

    // 用 splice 處理拖曳後資料, 組合出新的 items
    // splice(start, deleteCount, item )

    // 從 source.index 剪下被拖曳的元素
    const [remove] = newItems.splice(source.index, 1);

    // 在 destination.index 位置貼上被拖曳的元素
    newItems.splice(destination.index, 0, remove);

    // 設定新的 items
    this.optionsWithId = newItems;
  };

  @action toggleEdit = () => {
    this.isEditing = !this.isEditing;
  };

  // /////////////////////////////////////

  @action deserialize = (data) => {
    const {
      type,
      label,
      options,
      isEditing
    } = data ?? {};

    this.type = type;
    this.label = label;
    this.optionsWithId = options?.map((opt, i) => ({ id: (dayjs().valueOf() + Math.random()), value: opt })) ?? [];
    if (isEditing) {
      this.isEditing = isEditing;
    }
  };

  serialize = () => {
    const data = {
      type: this.type,
      label: this.label
    };

    if (this.options?.length !== 0) {
      data.options = this.options;
    }

    return data;
  };


  static fromRes(data) {
    return new RegistrationField(data);
  }

}

export default RegistrationField;

