import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import iconInfo from 'src/assets/iconInfo.svg';

import styles from '../styles.module.scss';

@observer
class CheckoutFail extends React.Component {
  constructor(props) {
    super();

    this.vm = props.vm;
  }

  render() {

    return (
      <>
        <div className={styles.title}>
          {t('checkout_fail_title')}
        </div>

        <Icon
          svg
          size={94}
          className={styles.icon}
        >
          {iconInfo}
        </Icon>

        <div className={styles.description}>
          {this.vm.detail.resultCode
            ? (
              <div>
                <span>{t('order_error_code')}</span>
                <span>{this.vm.detail.resultCode}</span>
              </div>
            )
            : null}

          {/* <div>
            {this.vm.detail.resultMsg}
          </div> */}
          <div>
            {t('order_error_desc')}
          </div>
        </div>

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={this.vm.toProduct}
          className={styles.button}
        >
          {t('checkout_fail_back_to_products_button')}
        </Button>
      </>
    );
  }
}

CheckoutFail.propTypes = {
  vm: PropTypes.object
};

CheckoutFail.defaultProps = {
  vm: {}
};

export default CheckoutFail;
