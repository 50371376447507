import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { TERMS_AND_POLICY_TABS } from 'src/constants';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import withErrorInput from 'src/components/withErrorInput';

import styles from '../../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class Step1 extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <div className={styles.onePageContainer}>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('register_organization_step1_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('register_organization_step1_subtitle')}
          </h3>
        </div>

        <div className={styles.inputGroup}>
          <div className={clsx(styles.flexCol, styles.inputs, styles.inputs1)}>
            <ErrorTextInput
              key="displayName"
              label={t('register_organization_step1_displayName_label')}
              onChange={(e) => this.vm.onChange('displayName', e.target.value)}
              defaultValue={this.vm.displayName}
              required
            />

            <ErrorTextInput
              key="contact name"
              label={t('register_organization_step3_name')}
              value={this.vm.contact.name ?? ''}
              onChange={(e) => this.vm.onContactChange('name', e.target.value)}
              required
            />
          </div>

          <div className={clsx(styles.flexCol, styles.inputs)}>
            <TextInput
              key="email"
              placeholder={t('register_organization_step1_email_placeholder')}
              label={!this.vm.email ? null : t('register_organization_step1_email_label')}
              onChange={(e) => this.vm.onChange('email', e.target.value)}
              onBlur={this.vm.onCheckEmail}
              defaultValue={this.vm.email}
              error={!!this.vm.emailError}
              required
            />
            <div className={styles.errorMessage}>
              {this.vm.emailError ? this.vm.emailError : ''}
            </div>
            <TextInput
              key="password"
              placeholder={t('set_password_placeholder')}
              label={!this.vm.password ? null : t('register_organization_step1_password_label')}
              type="password"
              onChange={(e) => this.vm.onChange('password', e.target.value)}
              onBlur={this.vm.onCheckPassword}
              defaultValue={this.vm.password}
              error={this.vm.passwordError}
              required
            />
            <div className={styles.errorMessage}>
              {this.vm.passwordError ? this.vm.passwordError : ''}
            </div>
            <TextInput
              key="passwordRepeat"
              placeholder={t('register_organization_step1_passwordConfirm_placeholder')}
              label={!this.vm.passwordConfirm ? null : t('register_organization_step1_passwordConfirm_label')}
              type="password"
              onChange={(e) => this.vm.onChange('passwordConfirm', e.target.value)}
              onBlur={this.vm.onCheckPasswordConfirm}
              defaultValue={this.vm.passwordConfirm}
              error={this.vm.passwordConfirmError}
              required
            />
            <div className={styles.errorMessage}>
              {this.vm.passwordConfirmError ? this.vm.passwordConfirmError : ''}
            </div>
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.onOrganizationSignUp}
            style={{ width: '100%' }}
            className={styles.nextButton}
            disabled={this.vm.isButtonDisabled}
          >
            {t('submit')}
          </Button>

          <div className={styles.bottomText}>
            <div className={styles.textContainer}>
              <span className={styles.text}>
                {`${t('register_agreement')} `}
              </span>
              <Link
                className={styles.link}
                to="/terms-and-policy#terms"
                state={{ tab: TERMS_AND_POLICY_TABS.Terms }}
                target="_blank"
              >
                {t('terms_of_use')}
              </Link>
              <span>{` ${t('and')} `}</span>
              <Link
                className={styles.link}
                to="/terms-and-policy#policy"
                state={{ tab: TERMS_AND_POLICY_TABS.Policy }}
                target="_blank"
              >
                {t('privacy_poliicy')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Step1.propTypes = {
  vm: PropTypes.object
};

Step1.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Step1)), false);
