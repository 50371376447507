import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Observer } from 'mobx-react';

import { Link } from 'react-router-dom';
import withRouter from 'src/components/withRouter';
import scrollDetector from 'src/stores/scrollDetector';
import iconBack from 'src/assets/iconHeaderBack.svg';
import styles from './styles.module.scss';
import Icon from '../Icon';

function Breadcrumb(props) {
  const { router, routes, right, colorType, onBackPress, withHeader, shouldBlockRouting, onClickRoute } = props;

  return (
    <Observer>
      {() => (
        <div className={clsx(styles.breadcrumbContainer, styles[colorType], (withHeader && !scrollDetector.isScrollingDown) ? styles.lower : '')}>
          <div className={styles.breadcrumb}>
            <div
              className={styles.iconBack}
              onClick={onBackPress || (() => router.navigate(-1))}
            >
              <Icon
                size={24}
                svg
              >
                {iconBack}
              </Icon>
            </div>
            <div className={styles.routes}>
              {
                routes ? (
                  routes.map((item, i) => {
                    if (i === routes.length - 1) {
                      return (
                        <span
                          key={item.label}
                          className={styles.current}
                        >
                          {item.label}
                        </span>
                      );
                    }

                    return shouldBlockRouting
                      ? (
                        <span key={item.label}>
                          <span
                            key={item.label}
                            className={styles.link}
                            onClick={onClickRoute}
                          >
                            {item.label}
                          </span>
                          <span className={styles.slash}>/</span>
                        </span>
                      )
                      : (
                        <span key={item.label}>
                          <Link className={styles.link} to={item.path} state={item.state}>{item.label}</Link>
                          <span className={styles.slash}>/</span>
                        </span>
                      );
                  })
                ) : (
                  <span key="back">
                    <span className={styles.link}>上一步</span>
                  </span>
                )
              }
            </div>
            <div style={{ flexGrow: 1 }} />
            <div
              className={styles.right}
            >
              {right}
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
}

Breadcrumb.propTypes = {
  router: PropTypes.object,
  onBackPress: PropTypes.func,
  right: PropTypes.any,
  routes: PropTypes.array,
  colorType: PropTypes.string,
  withHeader: PropTypes.bool,
  shouldBlockRouting: PropTypes.bool,
  onClickRoute: PropTypes.func
};

Breadcrumb.defaultProps = {
  router: {},
  onBackPress: null,
  right: null,
  routes: [],
  colorType: '',
  withHeader: false,
  shouldBlockRouting: false,
  onClickRoute: null
};

export default withRouter(Breadcrumb);
