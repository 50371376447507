import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { t } from 'i18next';
import Button from 'src/components/Button';
import OrderCard from 'src/components/OrderCard';
import Icon from 'src/components/Icon';
import iconCheckGreen from 'src/assets/iconCheckGreen.svg';

import styles from '../styles.module.scss';

@observer
class CheckoutSuccess extends React.Component {
  constructor(props) {
    super();

    this.vm = props.vm;
  }

  render() {

    return (
      <>
        <div className={styles.title}>
          {t('checkout_success_title')}
        </div>

        <Icon
          svg
          size={94}
          className={styles.icon}
        >
          {iconCheckGreen}
        </Icon>

        {this.vm.detail && (
          <OrderCard
            item={this.vm.detail}
            className={styles.orderCard}
          />
        )}

        <div style={{ flexGrow: 1 }} />

        <div className={styles.buttons}>
          <Button
            onClick={this.vm.toOrderHistory}
            className={clsx(styles.button, this.vm.isFromCreateEvent && styles.defaultBtn)}
            type={this.vm.isFromCreateEvent ? 'default' : 'primary'}
          >
            {t('checkout_success_back_to_credits_button')}
          </Button>

          {this.vm.isFromCreateEvent && (
            <Button
              onClick={this.vm.toCreateEvent}
              className={styles.button}
            >
              {t('checkout_back_to_create_event_button')}
            </Button>
          )}
        </div>
      </>
    );
  }
}

CheckoutSuccess.propTypes = {
  vm: PropTypes.object
};

CheckoutSuccess.defaultProps = {
  vm: {}
};

export default CheckoutSuccess;
