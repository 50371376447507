import { request, getHost, getHeader } from './utils';

export const getList = (type, eventId) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/demand/${type}/list`,
    params: { event: eventId },
    headers: getHeader()
  };
  return request(options);
};

export const detail = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/demand/${id}/detail`,
    headers: getHeader()
  };
  return request(options);
};

export const create = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/demand/create`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const update = (id, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/v1/demand/${id}`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const deleteDemand = (id) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/v1/demand/${id}`,
    headers: getHeader()
  };
  return request(options);
};
