import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { CREATE_EVENT_STEPS, CREATE_STEP_STATUS } from 'src/constants';

import menuBasic from 'src/assets/createEvent/menuBasic.svg';
import menuKeyFinding from 'src/assets/createEvent/menuKeyFinding.svg';
import menuTarget from 'src/assets/createEvent/menuTarget.svg';
import menuVision from 'src/assets/createEvent/menuVision.svg';
import menuDemands from 'src/assets/createEvent/menuDemands.svg';
import menuFaq from 'src/assets/createEvent/menuFaq.svg';
import menuFeatures from 'src/assets/createEvent/menuFeatures.svg';
import menuKeyItem from 'src/assets/createEvent/menuKeyItem.svg';
import menuRegistration from 'src/assets/createEvent/menuRegistration.svg';
import menuSubmit from 'src/assets/createEvent/menuSubmit.svg';

import styles from './styles.module.scss';

@observer
class StepIcon extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  icons = {
    [CREATE_EVENT_STEPS.Basic]: menuBasic,
    [CREATE_EVENT_STEPS.KeyFinding]: menuKeyFinding,
    [CREATE_EVENT_STEPS.Target]: menuTarget,
    [CREATE_EVENT_STEPS.Vision]: menuVision,
    [CREATE_EVENT_STEPS.KeyItem]: menuKeyItem,
    [CREATE_EVENT_STEPS.Features]: menuFeatures,
    [CREATE_EVENT_STEPS.Demand]: menuDemands,
    [CREATE_EVENT_STEPS.Faqs]: menuFaq,
    [CREATE_EVENT_STEPS.Registration]: menuRegistration,
    [CREATE_EVENT_STEPS.Submit]: menuSubmit
  };

  render() {
    const {
      status,
      step,
      className,
      withGreenDot
    } = this.props;

    return (
      <div className={clsx(styles.iconContainer, styles[status], withGreenDot && styles.greenDot, className)}>
        <div
          className={styles.icon}
          style={{ backgroundImage: `url(${this.icons[step]})` }}
        />
      </div>
    );
  }
}

StepIcon.propTypes = {
  step: PropTypes.string,
  status: PropTypes.string,
  className: PropTypes.string,
  withGreenDot: PropTypes.bool
};

StepIcon.defaultProps = {
  step: null,
  status: CREATE_STEP_STATUS.Pending,
  className: null,
  withGreenDot: false
};

export default StepIcon;
