import Usages from 'src/constants/gens/Usages.gen';
import Regions from 'src/constants/gens/Regions.gen';
import SDGs from 'src/constants/gens/SDGs.gen';
import store from 'src/stores/constants';

import Equipments from 'src/constants/gens/Equipments.gen';

export default class Space {
  constructor(params) {
    const {
      usages,
      size,
      sdgs,
      regions,
      location,
      openings,
      capacity,
      photos,
      address,
      equipments
    } = params ?? {};

    this.size = size;
    this.capacity = capacity;
    this.address = address;
    this.photos = photos;
    this.location = location;
    this.openings = openings ?? [];
    this.equipments = equipments ?? [];
    this.sdgs = sdgs ?? [];
    this.regions = regions ?? [];
    this.usages = usages ?? [];
  }


  get convertedUsages() {
    return this.usages.map((el) => {
      return Usages[store.language][el] ?? el;
    });
  }

  get convertedEquiments() {
    return this.equipments.map((el) => {
      return Equipments[store.language][el] ?? el;
    });
  }

  get convertedSdgs() {
    const Obj = this.sdgs.reduce((acc, val) => {
      const [key, value] = val.split('-');
      if (acc[key]) {
        acc[key].push(val);
      } else {
        acc[key] = [val];
      }
      return acc;
    }, {});

    const list = [];
    Object.entries(Obj).forEach((el) => {
      if (Object.keys(SDGs[store.language][el[0]].childs).length === el[1].length) {
        return list.push(el[0]);
      }
      return list.push(...el[1]);
    });

    return list;
  }

  get convertedRegions() {
    return store.getCombinedRegionsValuesArr(this.regions);
  }

  get convertedLocation() {
    const cid = this.location?.slice(0, 1);
    const aid = this.location?.slice(0, 3);
    return Regions[store.language][cid]?.area[aid]?.city[this.location] ?? '-';
  }

  get convertedOpenings() {
    return this.openings.map((el) => {
      const item = store.openings.find((o) => o.value === el);
      return item ? item.label : '-';
    });
  }

  static fromRes(data) {
    return new Space(data);
  }
}




