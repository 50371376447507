import { makeObservable, observable, action, computed } from 'mobx';
import { t } from 'i18next';

class ErrorModalViewModel {

  @observable content = null;
  @observable title = 'title';
  @observable onClose = () => {};
  @observable isShow = false;
  @observable buttonText = t('ok');
  @observable buttonProps = {};


  constructor() {
    makeObservable(this);
  }

  @action open = ({ title, content, onClose, buttonText, buttonProps }) => {
    this.title = title;
    this.content = content;
    this.onClose = onClose || this.onClose;
    this.buttonText = buttonText || t('ok');
    if (buttonProps) {
      this.buttonProps = buttonProps;
    }

    this.isShow = true;
  };

  @action close = () => {
    this.onClose();
    this.isShow = false;
  };
}

const errorModalViewModel = new ErrorModalViewModel();

export default errorModalViewModel;

