import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel } from 'swiper';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ConfigProvider from 'antd/es/config-provider';
import Spin from 'antd/es/spin';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import BaseModal from 'src/components/Modals/BaseModal';
import ResourceCard from 'src/components/ResourceCard';
import handshake from 'src/assets/handshake.png';
import iconAdd from 'src/assets/iconAddFill.svg';
import illustrationSearching from 'src/assets/illustrationSearching.svg';
import styles from './styles.module.scss';
import 'swiper/css';

SwiperCore.use([Mousewheel]);

@observer
class MyResourcesModal extends React.Component {
  targetElement = null;

  constructor(props) {
    super(props);
    this.props = props;
    this.vm = props.vm;
  }

  componentDidMount() {
    this.vm.didMount(this.props);
    this.targetElement = document.querySelector('.myResourcesModalContainer');
    disableBodyScroll(this.targetElement);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  renderNoResource() {
    return (
      <div className={styles.noApplicableResourcesContainer}>
        <img
          className={clsx(styles.marginBottom)}
          src={illustrationSearching}
          alt="searching"
        />
        <h2 className={clsx(styles.title, styles.marginBottom)}>
          {t('no_applicable_resource_title')}
        </h2>
        <h3 className={clsx(styles.subtitle, styles.marginBottom)}>
          {t('no_applicable_resource_content')}
        </h3>

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={this.vm.onAddResource}
          withRightArrow
          className={styles.button}
        >
          {t('no_applicable_resource_button')}
        </Button>
      </div>
    );
  }

  renderMyResources() {
    return (
      <div className={styles.myResourcesModal}>
        <img
          src={handshake}
          alt="handshake"
          className={clsx(styles.illustration)}
        />
        <div className={styles.title}>
          {t('demand_signup_title')}
        </div>
        <div className={styles.desc}>
          {t('demand_signup_desc')}
        </div>
        <div className={clsx(styles.resourcesContainer, styles[this.vm.type])}>
          <Swiper
            className={clsx(styles.listSwiper, 'myResourceSwiper')}
            centeredSlides
            slidesPerView="auto"
            spaceBetween={30}
            onReachEnd={this.vm.onScrollEnd}
            mousewheel={{
              releaseOnEdges: true
            }}
            direction="horizontal"
          >
            {this.vm.myResources?.map((item) => (
              <SwiperSlide key={item.id}>
                <ResourceCard
                  className={clsx(styles.card, this.vm.resourceSelected?.id === item.id && styles.selected)}
                  type={item.type}
                  item={item}
                  key={item.id}
                  onClick={() => this.vm.onChangeSelected(item)}
                />
              </SwiperSlide>
            ))}
            {!this.vm.anchor && (
              <SwiperSlide>
                <div
                  key="add"
                  className={clsx(styles.addCard, styles[this.vm.type])}
                  onClick={this.vm.onAddResource}
                >
                  <Icon
                    size={24}
                    svg
                    className={styles.icon}
                  >
                    {iconAdd}
                  </Icon>
                  <div>{t(`add_resource_${this.vm.type}`)}</div>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
        <Button
          onClick={this.vm.onConfirmSelect}
          className={clsx(styles.button, styles[this.vm.type])}
        >
          {t('demand_signup_next')}
        </Button>

      </div>
    );
  }

  renderContent() {
    if (this.vm.hasResource) return this.renderMyResources();
    return this.renderNoResource();
  }

  render() {
    const { isMobile } = this.props;
    return (
      <BaseModal
        onClose={() => {
          this.vm.onClose();
          enableBodyScroll(this.targetElement);
        }}
        open={this.vm.isOpen}
        isMobile={isMobile}
        height={550}
        className="myResourcesModalContainer"
      >
        {this.vm.isLoading
          ? (
            <div className={clsx(styles.resourcesContainer, styles.center, styles.loading)}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#414EE1'
                  }
                }}
              >
                <Spin />
              </ConfigProvider>
            </div>
          )
          : this.renderContent()}
      </BaseModal>
    );
  }
}

export default MyResourcesModal;

MyResourcesModal.propTypes = {
  vm: PropTypes.object,
  isMobile: PropTypes.bool
};

MyResourcesModal.defaultProps = {
  vm: {},
  isMobile: false
};
