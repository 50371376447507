import API from 'src/apis';

export default class CommonService {
  static async rechargeText() {
    const { data } = await API.common.rechargeText();
    const { text } = data;
    return text;
  }

  static async productText() {
    const { data } = await API.common.productText();
    const { text } = data;
    return text;
  }
}
