import React from 'react';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withErrorInput from 'src/components/withErrorInput';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import ConfigProvider from 'antd/es/config-provider';
import Checkbox from 'antd/es/checkbox/Checkbox';
import Button from 'src/components/Button';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);

@observer
class StepIntro extends React.Component {
  ref = React.createRef();

  constructor(props) {
    super(props);
    this.vm = this.props.vm;
  }

  renderApplication() {
    return (
      <div className={styles.flexCol}>
        <ErrorTextInput
          key="motivation"
          showError={!!this.vm.motivation && !this.vm.isMotivationValid}
          label={t('signup_motivation_placeholder')}
          onChange={(e) => this.vm.onChange('motivation', e.target.value)}
          multiline
          required
          validValue={this.vm.isMotivationValid}
          value={this.vm.motivation}
          errorMessage=""
        />
        <div className={clsx(styles.flexSpaceBetween, styles.hint)}>
          <div className={styles.note}>
            {t('signup_motivation_note')}
          </div>
          <div className={styles.text}>
            <span className={(this.vm.motivation && !this.vm.isMotivationValid) ? styles.red : ''}>
              {this.vm.motivation?.length ?? 0}
            </span>
            <span>/300</span>
          </div>
        </div>

        <ErrorTextInput
          key="cognition"
          showError={!!this.vm.cognition && !this.vm.isCognitionValid}
          label={t('signup_cognition_placeholder')}
          onChange={(e) => this.vm.onChange('cognition', e.target.value)}
          multiline
          required={!!this.vm.registrationRequires.cognition}
          validValue={this.vm.isCognitionValid}
          value={this.vm.cognition}
          errorMessage=""
        />
        <div className={clsx(styles.flexSpaceBetween, styles.hint)}>
          <div className={styles.note}>
            {t('signup_cognition_note')}
          </div>
          <div className={styles.text}>
            <span className={(this.vm.cognition && !this.vm.isCognitionValid) ? styles.red : ''}>
              {this.vm.cognition?.length ?? 0}
            </span>
            <span>/300</span>
          </div>
        </div>

        {this.vm.event?.registrationFields.map((field, i) => {
          const label = field.label;
          switch (field.type) {
            case 'text':
              return (
                <ErrorTextInput
                  key={`text_${label}`}
                  label={label}
                  onChange={(e) => this.vm.onChangeAdditionalFields(label, e.target.value)}
                  value={this.vm.additionalFields[label] ?? ''}
                />
              );
            case 'single':
              return (
                <ErrorSelect
                  key={`single_${label}`}
                  placeholder={label}
                  onChange={(value) => this.vm.onChangeAdditionalFields(label, value)}
                  showSearch
                  options={field.options.map((opt) => ({ value: opt }))}
                  value={this.vm.additionalFields[label]}
                />
              );
            case 'multiple':
              return (
                <ErrorSelect
                  key={`multiple_${label}`}
                  placeholder={label}
                  onChange={(value) => this.vm.onChangeAdditionalFields(label, value)}
                  showSearch
                  options={field.options.map((opt) => ({ value: opt }))}
                  mode="multiple"
                  value={this.vm.additionalFields[label]}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.stepIntro}>
        {this.renderApplication()}

        <div className={clsx(styles.marginBottom40, styles.marginTop10)}>
          <div className={styles.ruleTitle}>
            {t('demand_rule')}
          </div>
          <div
            className={clsx(styles.text, !this.vm.hasClickedRuleMore && styles.clamp)}
            ref={this.ref}
          >
            {this.vm.demandRule}
          </div>
          {this.vm.isClamped(this.ref) && !this.vm.hasClickedRuleMore && (
            <div
              className={styles.more}
              onClick={this.vm.clickRuleMore}
            >
              {t('expand_desc_more')}
            </div>
          )}
        </div>

        <div className={clsx(styles.flexCol, styles.bottomContainer)}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#414EE1'
              }
            }}
          >
            <Checkbox
              onChange={this.vm.onCheckAgree}
              className={styles.text}
              style={{ color: '#000' }}
            >
              {t('signup_checkbox')}
            </Checkbox>
          </ConfigProvider>

          <Button
            onClick={this.vm.onIntroClickNext}
            disabled={this.vm.isIntroButtonDisable}
            className={styles.button}
          >
            {t('signup_next')}
          </Button>
        </div>
      </div>
    );
  }
}

export default StepIntro;

StepIntro.propTypes = {
  vm: PropTypes.object
};

StepIntro.defaultProps = {
  vm: {}
};
