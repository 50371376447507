import { makeObservable, observable, action, computed } from 'mobx';

class WithdrawApplicationModalViewModel {

  @observable content = null;
  @observable eventName = '';
  // @observable onClose = () => {};
  @observable onConfirm = () => {};
  @observable isShow = false;


  constructor() {
    makeObservable(this);
  }

  @action open = ({ eventName, onConfirm }) => {
    this.eventName = eventName;
    this.onConfirm = () => {
      onConfirm();
      this.close();
    };

    this.isShow = true;
  };

  @action close = () => {
    // this.onClose();
    this.isShow = false;
  };
}

export default WithdrawApplicationModalViewModel;

