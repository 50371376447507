import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD } from 'src/constants';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import Icon from 'src/components/Icon';
import withErrorInput from 'src/components/withErrorInput';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import DeleteItemButton from 'src/pages/CreateEvent/CreateEventPage/components/DeleteItemButton';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import iconAdd from 'src/assets/iconAddPurple.svg';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class Faqs extends StepBase {
  constructor(props) {
    super();
    this.props = props;
    this.vm = props?.vm;
  }

  componentDidMount() {
    super.componentDidMount();
    this.vm?.faqDidmount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.vm?.faqUnmount();
  }

  renderFaq = (item, index) => {
    const {
      vm
    } = this.props;

    const fieldName = EVENT_FIELD.Faqs;

    return (
      <div
        key={`faq_${index}`}
        className={styles.sectionWrapper}
      >
        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>
            <div className={styles.itemTitle}>
              {`/ ${t('create_event_faq_title')} 0${index + 1} /`}
            </div>

            <ErrorTextInput
              key="faq_question"
              label={t('faq_question')}
              onChange={(e) => item.onChange(e.target.value, 'question')}
              value={item.question ?? ''}
              multiline
              onBlur={() => vm.onSave(fieldName)}
            />

            <ErrorTextInput
              key="faq_answer"
              label={t('faq_answer')}
              onChange={(e) => item.onChange(e.target.value, 'answer')}
              value={item.answer ?? ''}
              multiline
              onBlur={() => vm.onSave(fieldName)}
            />

            <DeleteItemButton
              onClick={() => {
                vm.event.removeItemByIndex(fieldName, index);
                vm.onSave(fieldName);
              }}
              text={`${t('create_event_faq_title')} 0${index + 1}`}
              generalModalViewModel={vm.generalModalViewModel}
            />
          </div>
          <Tips isHidden />
        </section>

      </div>
    );
  };

  renderContent = () => {
    const { vm } = this.props;
    const event = vm.event ?? {};

    const faqs = event[EVENT_FIELD.Faqs];

    if (!faqs) return null;

    return (
      <>
        {faqs?.map((item, i) => this.renderFaq(item, i))}

        <section className={styles.stepSection}>
          <div className={clsx(styles.stepMainContent, styles.flexCentered)}>
            <Button
              ghost
              onClick={() => event.addEmptyItemInArray(EVENT_FIELD.Faqs)}
              className={styles.addItemButton}
            >
              <div className={styles.flexRowCenter}>
                <Icon
                  size={28}
                  svg
                >
                  {iconAdd}
                </Icon>
                {t('button_add_faq')}
              </div>
            </Button>
          </div>
          <Tips isHidden />
        </section>
      </>
    );
  };

  render() {
    const { vm } = this.props;

    return !!vm && this.renderContent();
  }
}

Faqs.propTypes = {
  vm: PropTypes.object
};

Faqs.defaultProps = {
  vm: {}
};

export default Faqs;
