/* eslint-disable import/no-cycle */
import { makeObservable, observable, action, runInAction, computed, toJS } from 'mobx';
import { EVENT_FIELD, EVENT_REVIEW_STATUS, EVENT_STATUS, KEYWORD_TYPE } from 'src/constants';
import ConstantsStore from 'src/stores/constants';
import KeyFinding from 'src/models/response/Event/KeyFinding';
import Target from 'src/models/response/Event/Target';
import Vision from 'src/models/response/Event/Vision';
import KeyItem from 'src/models/response/Event/KeyItem';
import Feature from 'src/models/response/Event/Feature';
import Contact from 'src/models/response/Event/Contact';
import Faq from 'src/models/response/Event/Faq';
import RegistrationField from 'src/models/response/Event/RegistrationField';
import RelatedUsers from 'src/models/response/Event/RelatedUsers';
import dayjs from 'dayjs';

export const itemDataModel = (fieldName) => {
  switch (fieldName) {
    case EVENT_FIELD.Targets:
      return new Target();
    case EVENT_FIELD.Features:
      return new Feature();
    case EVENT_FIELD.Faqs:
      return new Faq();
    default:
      return null;
  }
};

class Event {
  @observable banner;
  @observable blueTickAt;
  @observable createdAt;
  @observable demandTypes;
  @observable description;
  @observable enableRecruit;
  @observable endAt;
  @observable id;
  @observable isAutoActive;
  @observable keywordsObj = {}; // { s: '優質教育_3-3', t: '消費者', k: '社群經營', c: 'custom' }
  @observable name;
  @observable privacy; // enum:listed,unlisted
  @observable releaseAt;
  @observable registrationRequires;
  @observable relatedUsers; // coInitiators 共同主辦, collaborators 合作單位, coOrganiser 協辦單位
  @observable review;
  @observable serviceTypes;
  @observable slogan; // 影響力金句
  @observable startAt;
  @observable status;
  @observable updatedAt;
  @observable user;
  @observable type; // normal ┃ sample

  // metas
  @observable faqs = [];
  @observable features = [];
  @observable keyFinding;
  @observable keyItem;
  @observable regions = [];
  @observable registrationFields = [];
  @observable sdgs = [];
  @observable targets = [];
  @observable vision;
  @observable contact; // 行動管理者（非公開）

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  get convertedRegions() {
    return ConstantsStore.getCombinedRegionsValuesArr(this.regions);
  }

  @action onChange = (fieldName, value, key) => {
    console.log('event change', fieldName, value, key);
    if (key) {
      this[fieldName][key] = value;
      // console.log(this[fieldName], this[fieldName][key]);
    } else {
      this[fieldName] = value;
    }
    // console.log(fieldName, value);
  };

  @action addEmptyItemInArray = (field) => {
    console.log('.....add empty item', field);
    if (field === EVENT_FIELD.KeyItem) {
      this.keyItem.addEmptyItem();
      return;
    }

    const emptyItem = itemDataModel(field);
    if (emptyItem) {
      this[field]?.push(emptyItem);
    }
  };

  @action initEmptyItemInArray = () => {
    if (this.keyItem?.items?.length === 0) {
      this.keyItem.initItem();
    }
    const fieldsNeedItem = ['targets', 'features', 'faqs'];
    fieldsNeedItem.forEach((field) => {
      if (this[field]?.length === 0) {
        this[field] = [];
        this.addEmptyItemInArray(field);
      }
    });
  };

  @action removeEmptyItems = (field, until = 0) => {
    console.log('.....remove empty items', field);
    if (field === EVENT_FIELD.KeyItem) {
      this.keyItem.removeEmptyItemsUntilOne();
      return;
    }

    // this[field] = this[field].filter((item, i) => i === 0 || (i !== 0 && !item.isEmpty)); // remove from index 1
    this[field] = this[field].every((item) => item.isEmpty)
      ? this[field].slice(0, until)
      : this[field].filter((item, i) => !item.isEmpty);
  };

  @action removeEmptyItemsUntilOne = (field) => {
    this.removeEmptyItems(field, 1);
  };

  @action removeItemByIndex = (field, index) => {
    if (field === EVENT_FIELD.KeyItem) {
      this.keyItem.removeItemByIndex(index);
      return;
    }

    this[field] = this[field].filter((item, i) => i !== index);
  };

  @action onChangeSdg = (value) => {
    if (this.sdgs.includes(value)) {
      this.sdgs = this.sdgs.filter((sdg) => sdg !== value);
    } else {
      this.sdgs.push(value);
    }
  };

  @action onChangeRegistrationRequires = (key, value) => {
    const obj = this.registrationRequiresObj;
    obj[key] = value;
    this.registrationRequires = JSON.stringify(this.registrationRequiresObj);
  };

  @computed get registrationRequiresObj() {
    return JSON.parse(this.registrationRequires) ?? {};
  }

  @action addRegistrationField = (type) => {
    this.registrationFields.push(new RegistrationField({ type, isEditing: true }));
  };

  @action removeRegistrationField = (index) => {
    this.registrationFields = this.registrationFields.filter((item, i) => i !== index);
  };

  @computed
  get serviceTypesTags() {
    const arr = this.serviceTypes.filter((type) => ConstantsStore.getServiceTypeTag(type)).map((type) => ConstantsStore.getServiceTypeTag(type).slice(0, 2));
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  @computed get keywordsForDisplay() {
    return Object.entries(this.keywordsObj)?.filter((v) => !!v[1]).map((k) => {
      if (k[0] === KEYWORD_TYPE.Sdg) {
        return k[1]?.split('_')[0];
      }

      return k[1];
    });
  }

  // NOTE: EventDetail頁點擊不同類型 keyword 的 hashtag 有不同的 filter 行為，所以必須帶前綴
  // read only
  // ['s_優質教育_3-3', 't_消費者', 'k_社群經營', 'custom']
  @computed get keywords() {
    return Object.entries(this.keywordsObj)
      .filter((value) => !!value[1])
      .map((item) => {
        if (item[0] === KEYWORD_TYPE.Custom) {
          return item[1];
        }

        return `${item[0]}_${item[1]}`;
      });
  }

  @computed get coInitiatorsNames() {
    return this.relatedUsers.coInitiatorsNames;
  }

  @computed get coInitiatorsValues() {
    return this.relatedUsers.coInitiatorsValues;
  }

  @computed get collaboratorsNames() {
    return this.relatedUsers.collaboratorsNames;
  }

  @computed get collaboratorsValues() {
    return this.relatedUsers.collaboratorsValues;
  }

  @computed get coOrganiserNames() {
    return this.relatedUsers.coOrganiserNames;
  }

  @computed get coOrganiserValues() {
    return this.relatedUsers.coOrganiserValues;
  }

  @computed get canSubmit() {
    return !this.review.status || this.review.status === EVENT_REVIEW_STATUS.Failed;
  }

  @computed
  get daysFromNow() {
    const diff = dayjs(this?.endAt) - dayjs();
    const cd = 24 * 60 * 60 * 1000;
    const days = Math.floor(diff / cd).toString();
    return (days.length === 1 && days !== '0') ? `0${days}` : days;
  }

  // ///////////////////////

  @action deserialize = (data) => {
    const {
      banner,
      blueTickAt,
      createdAt,
      demandTypes,
      description,
      enableRecruit,
      endAt,
      id,
      isAutoActive,
      keywords,
      metas,
      name,
      privacy, // enum:listed,unlisted
      releaseAt,
      registrationRequires,
      relatedUsers, // coInitiators 共同主辦, collaborators 合作單位, coOrganiser 協辦單位
      review,
      serviceTypes,
      slogan, // 影響力金句
      startAt,
      status,
      updatedAt,
      user,
      type
    } = data ?? {};

    const {
      faqs,
      features,
      keyFinding,
      keyItem,
      regions,
      registrationFields,
      sdgs,
      targets,
      vision,
      contact // 行動管理者（非公開）
    } = metas ?? {};

    this.id = id;
    this.type = type;
    this.banner = banner;
    this.blueTickAt = blueTickAt;
    this.createdAt = createdAt;
    this.description = description;
    this.demandTypes = demandTypes;
    this.enableRecruit = enableRecruit;
    this.isAutoActive = isAutoActive;
    this.keywordsObj = this.deserializeKeywords(keywords);
    this.review = review ?? {};
    this.serviceTypes = serviceTypes ?? [];
    this.status = status;
    this.name = name;
    this.privacy = privacy;
    this.releaseAt = releaseAt;
    this.registrationRequires = registrationRequires;
    this.relatedUsers = RelatedUsers.fromRes(relatedUsers);
    this.slogan = slogan;
    this.updatedAt = updatedAt;
    this.user = user ?? {};
    this.endAt = endAt;
    this.startAt = startAt;

    this.faqs = faqs?.map((item) => Faq.fromRes(item)) ?? [];
    this.features = features?.map((item) => Feature.fromRes(item)) ?? [];
    this.keyFinding = KeyFinding.fromRes(keyFinding) ?? {};
    this.keyItem = KeyItem.fromRes(keyItem) ?? {};
    this.regions = regions ?? [];
    this.registrationFields = registrationFields?.map((item) => RegistrationField.fromRes(item)) ?? [];
    this.sdgs = sdgs ?? [];
    this.targets = targets?.map((item) => Target.fromRes(item)) ?? [];
    this.vision = Vision.fromRes(vision);
    this.contact = Contact.fromRes(contact);
  };

  deserializeKeywords = (keywordsArr) => {
    const obj = {
      [KEYWORD_TYPE.Sdg]: null,
      [KEYWORD_TYPE.Target]: null,
      [KEYWORD_TYPE.KeyItem]: null,
      [KEYWORD_TYPE.Custom]: null
    };
    const keys = Object.values(KEYWORD_TYPE);
    const regex = /((^[^_]*)_)(.*)/; // {type}_{value}
    keywordsArr.forEach((k) => {
      const match = k.match(regex);
      if (match && keys.includes(match[2])) {
        obj[match[2]] = match[3];
      } else {
        obj[KEYWORD_TYPE.Custom] = k;
      }
    });

    return obj ?? {};
  };

  serialize = () => {
    const {
      id,
      type,
      name,
      banner,
      privacy,
      slogan,
      keywords,
      sdgs,
      registrationRequires,
      status,
      regions,
      keyFinding,
      targets,
      vision,
      keyItem,
      features,
      faqs,
      contact,
      registrationFields,
      coInitiators,
      collaborators,
      coOrganisers,
      isAutoActive,
      enableRecruit,
      startAt,
      endAt,
      releaseAt
    } = this;

    const data = {
      customId: this.id,
      type,
      name,
      // banner: {
      //   "name": "string",
      //   "size": 0
      // },
      banner,
      privacy,
      slogan,
      keywords,
      registrationRequires,
      // status,
      sdgs,
      regions,
      keyFinding: keyFinding?.serialize(),
      targets: targets?.map((item) => item.serialize()),
      vision,
      keyItem: keyItem?.serialize(),
      features: features?.filter((item) => item.isComplete)?.map((item) => item.serialize()),
      faqs: faqs?.filter((item) => item.isComplete)?.map((item) => item.serialize()),
      contact,
      registrationFields: registrationFields?.filter((item) => item.isComplete)?.map((item) => item.serialize()),
      coInitiators: this.coInitiatorsValues,
      collaborators: this.coInitiatorsValues,
      coOrganisers: this.coOrganiserValues,
      isAutoActive,
      enableRecruit,
      startAt,
      endAt,
      releaseAt
    };

    return data;
  };


  static fromRes(data) {
    return new Event(data);
  }
}


export default Event;
