import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import store, { KEY_OF_OTHER } from 'src/stores/constants';
import { RESOURCE_TYPES } from 'src/constants';
import i18n from 'src/i18n';
import withErrorInput from 'src/components/withErrorInput';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import TreeSelect from 'src/components/TreeSelect';
import UploadPhoto from 'src/components/UploadPhoto';

import styles from '../styles.module.scss';


const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);
const ErrorTreeSelect = withErrorInput(TreeSelect);

@observer
class Step1 extends React.Component {

  constructor(props) {
    super(props);
  }

  renderServiceInput() {
    const { vm } = this.props;
    return (
      <>
        <ErrorTextInput
          showError={vm.showError}
          label={i18n.t('myresource_form_service_name')}
          placeholder={i18n.t('myresource_form_service_name_placeholder')}
          onChange={(e) => {
            vm.onDataChange('name', e.target.value);
          }}
          inputProps={{
            maxLength: 15
          }}
          value={vm.data?.name ?? ''}
          required
          validValue={vm.isNameValid}
          errorMessage={(vm.data?.name && !vm.isNameValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
        />

        <ErrorSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_service_type')}
          onChange={(value) => vm.onDataChange('type', value)}
          options={store.serviceTypesSelectOptions}
          value={vm.data?.type}
          required
          showSearch
        />

        <ErrorSelect
          placeholder={i18n.t('myresource_form_service_seniority')}
          onChange={(value) => vm.onDataChange('seniority', value)}
          options={store.seniorityOptions?.filter((opt) => opt.value !== KEY_OF_OTHER.Seniority)}
          value={vm.data?.seniority}
        />

        <ErrorTreeSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_service_jobs')}
          type="jobs"
          vm={vm.jobsSelectViewModel}
          value={vm.jobsSelectViewModel.selectedItems}
          required
        />

      </>
    );
  }

  renderFundsInput() {
    const { vm } = this.props;
    return (
      <>
        <ErrorTextInput
          showError={vm.showError}
          label={i18n.t('myresource_form_funds_name')}
          placeholder={i18n.t('myresource_form_funds_name_placeholder')}
          onChange={(e) => {
            vm.onDataChange('name', e.target.value);
          }}
          inputProps={{
            maxLength: 15
          }}
          value={vm.data?.name ?? ''}
          required
          validValue={vm.isNameValid}
          errorMessage={(vm.data?.name && !vm.isNameValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
        />

        <ErrorTextInput
          type="number"
          showError={!!vm.data?.quota || vm.showError}
          label={i18n.t('myresource_form_funds_quota')}
          placeholder={i18n.t('myresource_form_funds_quota_placeholder')}
          onChange={(e) => vm.onDataChange('quota', e.target.value)}
          value={vm.data?.quota ?? ''}
          required
          validValue={vm.isQuotaValid}
          errorMessage={(vm.data?.quota && !vm.isQuotaValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
        />

        <ErrorSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_funds_type')}
          onChange={(value) => vm.onDataChange('type', value)}
          options={store.fundsTypesOptions}
          value={vm.data?.type}
          required
        />
      </>
    );
  }

  renderSpaceInput() {
    const { vm } = this.props;
    return (
      <>
        <ErrorTextInput
          showError={vm.showError}
          label={i18n.t('myresource_form_space_name')}
          placeholder={i18n.t('myresource_form_space_name_placeholder')}
          onChange={(e) => {
            vm.onDataChange('name', e.target.value);
          }}
          inputProps={{
            maxLength: 20
          }}
          value={vm.data?.name ?? ''}
          required
          validValue={vm.isSpaceNameValid}
          errorMessage={(vm.data?.name && !vm.isSpaceNameValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
        />

        <ErrorSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_space_location')}
          onChange={(value) => vm.onDataChange('location', value)}
          options={store.flattenedRegionsOptions}
          value={vm.data?.location}
          required
        />

        <ErrorTextInput
          showError={vm.showError}
          label={i18n.t('myresource_form_space_address')}
          placeholder={i18n.t('myresource_form_space_address')}
          onChange={(e) => vm.onDataChange('address', e.target.value)}
          value={vm.data?.address ?? ''}
          required
          validValue={vm.isAddressValid}
          errorMessage={(vm.data?.address && !vm.isAddressValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
        />

        <ErrorTreeSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_space_usages')}
          type="usages"
          vm={vm.usagesSelectViewModel}
          value={vm.usagesSelectViewModel.selectedItems}
          required
        />
      </>
    );
  }

  renderSpaceInfoInput() {
    const { vm, type } = this.props;

    return (
      <>
        <div className={styles.linkTitle} style={{ marginBottom: 20 }}>
          {i18n.t('myresource_form_space_separator')}
        </div>

        <ErrorTextInput
          type="number"
          showError={vm.showError}
          label={i18n.t('myresource_form_space_size')}
          placeholder={i18n.t('myresource_form_space_size_placeholder')}
          onChange={(e) => vm.onDataChange('size', e.target.value)}
          value={vm.data?.size ?? ''}
          required
          validValue={vm.isSizeValid}
          errorMessage={(vm.data?.size && !vm.isSizeValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
        />

        <ErrorTextInput
          type="number"
          showError={vm.showError}
          label={i18n.t('myresource_form_space_capacity')}
          placeholder={i18n.t('myresource_form_space_capacity_placeholder')}
          onChange={(e) => vm.onDataChange('capacity', e.target.value)}
          value={vm.data?.capacity ?? ''}
          required
          validValue={vm.isCapacityValid}
          errorMessage={(vm.data?.capacity && !vm.isCapacityValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
        />

        <ErrorTreeSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_space_equip')}
          type="equipments"
          vm={vm.equipmentsSelectViewModel}
          value={vm.equipmentsSelectViewModel.selectedItems}
          required
          showInputForOthers
        />

        <ErrorTreeSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_space_opening')}
          type="openings"
          vm={vm.openingsSelectViewModel}
          value={vm.openingsSelectViewModel.selectedItems}
          required
        />
      </>

    );
  }

  renderInput() {
    const { vm, type } = this.props;

    return (
      <>
        <ErrorTreeSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_sdgs')}
          type="sdgs"
          vm={vm.sdgsSelectViewModel}
          value={vm.sdgsSelectViewModel.selectedItems}
          required
        />

        <ErrorTreeSelect
          showError={vm.showError}
          placeholder={i18n.t('myresource_form_regions')}
          type="regions"
          vm={vm.regionsSelectViewModel}
          value={vm.regionsSelectViewModel.selectedItems}
          required
        />

        <ErrorTextInput
          showError={vm.showError}
          label={i18n.t('myresource_form_claim')}
          placeholder={i18n.t('myresource_form_claim_placeholder')}
          onChange={(e) => vm.onDataChange('claim', e.target.value)}
          value={vm.data?.claim ?? ''}
          required
          validValue={vm.isClaimValid}
          errorMessage={(vm.data?.claim && !vm.isClaimValid) ? i18n.t('format_is_invalid') : i18n.t('this_is_required')}
        />

        <UploadPhoto
          required={type === RESOURCE_TYPES.Space}
          showError={type === RESOURCE_TYPES.Space && vm.showError}
          vm={vm.photosVM}
          className="step_upload_photo"
          placeholder={i18n.t('myresource_form_photos')}
          tooltipTitle={i18n.t('myresource_success_upload_alert')}
          multiple
        />

      </>
    );

  }

  render() {

    const { vm, type } = this.props;

    return (
      <div className={styles.step}>
        <div className={styles.block}>
          <div className={styles.title}>{i18n.t(`myresource_intro_title_${type}`)}</div>
          <div className={styles.desc}>{i18n.t('myresource_intro_subtitle')}</div>
        </div>
        <div className={styles.flexCol} />
        { type === RESOURCE_TYPES.Service && this.renderServiceInput()}
        { type === RESOURCE_TYPES.Funds && this.renderFundsInput()}
        { type === RESOURCE_TYPES.Space && this.renderSpaceInput()}
        {this.renderInput()}
        { type === RESOURCE_TYPES.Space && this.renderSpaceInfoInput()}
      </div>
    );
  }
}

export default Step1;

Step1.propTypes = {
  vm: PropTypes.object,
  type: PropTypes.string
};

Step1.defaultProps = {
  vm: {},
  type: null
};
