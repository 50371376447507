import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import styles from '../../styles.module.scss';

@observer
class ModifyEmail extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <div className={styles.onePageContainer}>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('register_modifyEmail_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('register_modifyEmail_subtitle')}
          </h3>
        </div>

        <TextInput
          placeholder={t('register_organization_step1_email_placeholder')}
          label={!this.vm.email ? null : t('register_organization_step1_email_label')}
          onChange={(e) => this.vm.onChange('email', e.target.value)}
          onBlur={this.vm.onCheckEmail}
          value={this.vm.email}
          error={this.vm.emailError}
        />
        <div className={styles.modifyEmailErrorMessage}>
          {this.vm.emailError ? this.vm.emailError : ''}
        </div>

        <div style={{ flexGrow: 1 }} />

        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.onModifyEmail}
            className={styles.nextButton}
            disabled={this.vm.timerCountdown !== null}
            loading={this.vm.isLoading}
          >
            {t('register_modifyEmail_nextButton')}
            {this.vm.timerCountdown !== null && (
              <span className={styles.timer}>
                {`(${this.vm.timerCountdown})`}
              </span>
            )}
          </Button>
        </div>
      </div>
    );
  }
}

export default withProfile(withRouter(withTranslation()(ModifyEmail)), false);

ModifyEmail.propTypes = {
  vm: PropTypes.object
};

ModifyEmail.defaultProps = {
  vm: {}
};
