export default class Quota {
  constructor(params) {
    const {
      type, // point | activeEvent
      count
    } = params ?? {};

    this.type = type;
    this.count = count;
  }


  static fromRes(data) {
    return new Quota(data);
  }

}
