import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { t } from 'i18next';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Loading from 'src/components/Loading';

import ProposalPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class ProposalPage extends React.Component {
  constructor() {
    super();

    this.vm = new ProposalPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  render() {

    return (
      <div className={styles.proposalPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideFooter
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_proposal'), path: '' }
            ]}
            right={null}
          />
          <div className={clsx(styles.mainContainer)}>

            <iframe
              src="https://pamviwctybn.typeform.com/to/p63B346U"
              id="typeform-iframe"
              title="typeform"
              width="100%"
              height="100%"
              onLoad={this.vm.onLoad}
            />

          </div>

        </MainLayout>

        { this.vm.isLoading && <Loading /> }
      </div>
    );
  }
}

ProposalPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(ProposalPage)), false);
