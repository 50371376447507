import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD, EVENT_TIPS } from 'src/constants';
import Constants, { KEY_OF_OTHER } from 'src/stores/constants';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import Icon from 'src/components/Icon';
import UploadPhoto from 'src/components/UploadPhoto';
import withErrorInput from 'src/components/withErrorInput';
import DeleteItemButton from 'src/pages/CreateEvent/CreateEventPage/components/DeleteItemButton';
import StickyGroupContainer from 'src/pages/CreateEvent/CreateEventPage/components/StickyGroupContainer';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import iconAdd from 'src/assets/iconAddPurple.svg';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);

@observer
class KeyItem extends StepBase {
  constructor(props) {
    super();
    this.props = props;
    this.vm = props?.vm;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.vm?.removeEmptyItemsWhenUnmount(EVENT_FIELD.KeyItem);
  }

  renderItem = (item, index) => {
    const {
      vm
    } = this.props;

    const fieldName = EVENT_FIELD.KeyItem;

    return (
      <div
        key={`keyItem_${index}`}
        className={styles.sectionWrapper}
      >
        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>

            <div className={styles.itemTitle}>
              {`/ ${t('create_event_keyItem_item_title')} 0${index + 1} /`}
            </div>

            <ErrorSelect
              placeholder={t(`event_field_${fieldName}_option`)}
              onChange={(value) => item.onChange(value, 'option')}
              options={Constants.keyItemsOptions}
              value={item.optionKey}
              required
              showSearch
              onSelect={() => vm.onSave(fieldName)}
            />

            {item.optionKey === KEY_OF_OTHER.KeyItemsOptions && (
              <ErrorTextInput
                key="keyItem_option_other"
                label={t(`event_field_${fieldName}_option_other`)}
                onChange={(e) => item.onChange(e.target.value.trim(), 'optionOther')}
                value={item.optionOther ?? ''}
                required
                limit={10}
                onBlur={() => vm.onSave(fieldName)}
              />
            )}

            <ErrorTextInput
              key="keyItem_title"
              label={t(`event_field_${fieldName}_title`)}
              onChange={(e) => item.onChange(e.target.value, 'title')}
              value={item.title ?? ''}
              limit={14}
              required
              onBlur={() => vm.onSave(fieldName)}
            />

            <ErrorTextInput
              key="keyItem_description"
              label={t(`event_field_${fieldName}_description`)}
              onChange={(e) => item.onChange(e.target.value, 'description')}
              value={item.description ?? ''}
              limit={120}
              multiline
              required
              onBlur={() => vm.onSave(fieldName)}
            />

            {item.uploadPhotoVM && (
              <UploadPhoto
                placeholder={t('event_field_photo')}
                label={t('event_field_photo')}
                className={clsx(styles.uploadPhoto, vm.showError && vm.uploadPhotoVM.postList?.length === 0 && 'redBorder', 'uploadFullWidth')}
                vm={item.uploadPhotoVM}
                customUpload={() => vm.onUploadPhoto(item, fieldName)}
              />
            )}

            <ErrorTextInput
              key="keyItem_video"
              label={t('event_field_youtube_link')}
              onChange={(e) => item.onChange(e.target.value, 'video')}
              value={item.video ?? ''}
              showError={!item.isVideoValid}
              validValue={item.isVideoValid}
              errorMessage={t('url_is_not_valid')}
              onBlur={() => vm.onSave(fieldName)}
            />

            {index !== 0 && (
              <DeleteItemButton
                onClick={() => {
                  vm.event.removeItemByIndex(EVENT_FIELD.KeyItem, index);
                  vm.onSave(fieldName);
                }}
                text={`${t('create_event_keyItem_item_title')} 0${index + 1}`}
                generalModalViewModel={vm.generalModalViewModel}
              />
            )}

          </div>
          <Tips isHidden />
        </section>

      </div>
    );
  };

  renderContent = () => {
    const {
      vm
    } = this.props;

    const event = vm.event ?? {};
    const fieldName = EVENT_FIELD.KeyItem;
    const keyItem = event[fieldName];
    const items = event[fieldName]?.items;
    const isSticky = !vm.showPreview;

    if (!keyItem) return null;

    return (
      <>
        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={vm.tipHeight[EVENT_TIPS.KeyItem]}
            topOffset={vm.titleAreaHeight}
          >
            <ErrorTextInput
              key="keyItem_introduction"
              label={t(`event_field_${fieldName}_introduction`)}
              onChange={(e) => vm.onChange(fieldName, e.target.value, 'introduction')}
              value={keyItem.introduction ?? ''}
              limit={150}
              multiline
              required
              onBlur={() => vm.onSave(fieldName)}
            />
          </StickyGroupContainer>

          <Tips
            fieldName={EVENT_TIPS.KeyItem}
            isHidden={vm.showPreview}
            setTipHeight={vm.setTipHeight}
          />
        </section>

        {items?.map((item, i) => this.renderItem(item, i))}

        {items?.length < 5 && (
          <section className={styles.stepSection}>
            <div className={clsx(styles.stepMainContent, styles.flexCentered)}>
              <Button
                ghost
                onClick={() => event.addEmptyItemInArray(EVENT_FIELD.KeyItem)}
                className={styles.addItemButton}
              >
                <div className={styles.flexRowCenter}>
                  <Icon
                    size={28}
                    svg
                  >
                    {iconAdd}
                  </Icon>
                  {t('button_add_keyItem')}
                </div>
              </Button>
            </div>
            <Tips isHidden />
          </section>
        )}
      </>
    );
  };

  render() {
    const {
      vm
    } = this.props;

    return !!vm && this.renderContent();
  }
}

KeyItem.propTypes = {
  vm: PropTypes.object
};

KeyItem.defaultProps = {
  vm: {}
};

export default KeyItem;
