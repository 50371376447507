/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_TIPS } from 'src/constants';
import TextInput from 'src/components/TextInput';
import TreeSelect from 'src/components/TreeSelect';
import Icon from 'src/components/Icon';
import withErrorInput from 'src/components/withErrorInput';
import StickyGroupContainer from 'src/pages/CreateEvent/CreateEventPage/components/StickyGroupContainer';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import DeleteItemButton from 'src/pages/CreateEvent/CreateEventPage/components/DeleteItemButton';
import AddItemButton from 'src/pages/CreateEvent/CreateEventPage/components/AddItemButton';
import iconClose from 'src/assets/iconCloseRed.svg';
import styles from '../../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorTreeSelect = withErrorInput(TreeSelect);

@observer
class Base extends React.Component {

  constructor(props) {
    super();
    this.props = props;
    this.index = props?.index;
    this.isNotFirstItem = this.index !== 0;
  }

  componentDidMount() {
    this.props.demandVM?.didMount(this.props);
  }

  renderDescriptionSection = () => {
    const { demandVM, showPreview, setTipHeight, tipHeight, titleAreaHeight } = this.props;

    const {
      type,
      demand
    } = demandVM;

    const isSticky = !this.isNotFirstItem && !showPreview;

    return (
      <section className={styles.stepSection}>
        <StickyGroupContainer
          isSticky={isSticky}
          containerHeight={tipHeight[EVENT_TIPS[`D${type}Desc`]]}
          topOffset={titleAreaHeight}
        >
          <ErrorTextInput
            key="demand_desc"
            label={t(`demand_${type}_description`)}
            onChange={(e) => demandVM.onChange(e.target.value, 'description')}
            value={demand.description ?? ''}
            required
            limit={100}
            onBlur={demandVM.onSave}
            multiline
          />
        </StickyGroupContainer>

        <Tips
          isHidden={this.isNotFirstItem || showPreview}
          key={`demand_${type}_description`}
          fieldName={EVENT_TIPS[`D${type}Desc`]}
          setTipHeight={setTipHeight}
        />
      </section>
    );
  };

  renderKeyItemDescriptionsSection = () => {
    const { showPreview, setTipHeight, tipHeight, titleAreaHeight } = this.props;
    const isSticky = !this.isNotFirstItem && !showPreview;

    return (
      <section className={styles.stepSection}>
        <StickyGroupContainer
          isSticky={isSticky}
          containerHeight={tipHeight[EVENT_TIPS.DemandKeyItemDesc]}
          topOffset={titleAreaHeight}
        >
          {this.renderKeyItemDescriptions()}
        </StickyGroupContainer>

        <Tips
          isHidden={this.isNotFirstItem || showPreview}
          fieldName={EVENT_TIPS.DemandKeyItemDesc}
          setTipHeight={setTipHeight}
        />
      </section>
    );
  };

  renderAwardsAndDescriptionSections = () => {
    const { demandVM, showPreview, setTipHeight, tipHeight, titleAreaHeight } = this.props;
    const isSticky = !this.isNotFirstItem && !showPreview;
    return (
      <>
        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>

            {!!demandVM?.treeSelectVM?.awards && (
              <ErrorTreeSelect
                key="award_options"
                placeholder={t('demand_award_options')}
                type="awards"
                showSearch
                vm={demandVM.treeSelectVM.awards}
                value={demandVM.treeSelectVM.awards?.selectedItems}
                required
                showInputForOthers
              />
            )}

          </div>
          <Tips isHidden />
        </section>

        <section className={styles.stepSection}>
          <StickyGroupContainer
            isSticky={isSticky}
            containerHeight={tipHeight[EVENT_TIPS.DemandAwardDesc]}
            topOffset={titleAreaHeight}
          >
            {this.renderAwardDescriptions()}
          </StickyGroupContainer>

          <Tips
            isHidden={this.isNotFirstItem || showPreview}
            fieldName={EVENT_TIPS.DemandAwardDesc}
            setTipHeight={setTipHeight}
          />
        </section>
      </>
    );
  };

  renderDatesSection = () => {
    return (
      <section className={styles.stepSection}>
        <div className={styles.stepMainContent}>
          {this.renderDatesDescriptions()}
        </div>
        <Tips isHidden />
      </section>
    );
  };

  renderRegionsSection = () => {
    const { demandVM } = this.props;
    return (
      <section className={styles.stepSection}>
        <div className={styles.stepMainContent}>

          {demandVM?.treeSelectVM?.regions && (
            <ErrorTreeSelect
              key="demand_regions"
              placeholder={t('demand_regions')}
              type="regions"
              showSearch
              vm={demandVM.treeSelectVM.regions}
              value={demandVM.treeSelectVM.regions?.selectedItems}
              required
            />
          )}

        </div>
        <Tips isHidden />
      </section>
    );
  };

  renderRulesSection = () => {
    const { showPreview, setTipHeight, tipHeight, titleAreaHeight } = this.props;
    const isSticky = !this.isNotFirstItem && !showPreview;
    return (
      <section className={styles.stepSection}>
        <StickyGroupContainer
          isSticky={isSticky}
          containerHeight={tipHeight[EVENT_TIPS.DemandRules]}
          topOffset={titleAreaHeight}
        >
          {this.renderRules()}
        </StickyGroupContainer>

        <Tips
          isHidden={this.isNotFirstItem || showPreview}
          fieldName={EVENT_TIPS.DemandRules}
          setTipHeight={setTipHeight}
        />
      </section>
    );
  };

  renderDeleteButton = () => {
    const {
      demandVM,
      onDeleteDemand,
      index,
      generalModalViewModel
    } = this.props;

    const {
      type
    } = demandVM;

    return (
      <section
        className={clsx(styles.stepSection, styles.noMargin)}
        style={{ marginTop: -10, marginBottom: 40 }}
      >
        <div className={styles.stepMainContent}>
          <DeleteItemButton
            onClick={() => {
              onDeleteDemand(index);
              demandVM.onDelete();
            }}
            text={`${t(`create_event_demand_${type}_title`)} 0${index + 1}`}
            generalModalViewModel={generalModalViewModel}
          />
        </div>
        <Tips isHidden />
      </section>
    );
  };

  // //////////////////// item in array

  renderKeyItemDescriptions = () => {
    const {
      demandVM
    } = this.props;

    const { demand } = demandVM;

    const keyItemDescriptions = demand.keyItemDescriptions;

    return (
      <>
        {keyItemDescriptions?.map((item, index) => (
          <div
            className={clsx(styles.flexRowCenter, index !== 0 && styles.upper)}
            key={`demand_keyItemDescription_${index}`}
          >
            <ErrorTextInput
              label={t('demand_keyItemDescriptions')}
              onChange={(e) => demandVM.onChangeStringArray(e.target.value, 'keyItemDescriptions', index)}
              value={item ?? ''}
              limit={200}
              onBlur={demandVM.onSave}
              required={index === 0}
              multiline
            />
            {index !== 0 && (
              <Icon
                size={23}
                svg
                className={styles.itemCloseIcon}
                onClick={() => {
                  demandVM.removeItemInArray('keyItemDescriptions', index);
                  demandVM.onSave();
                }}
              >
                {iconClose}
              </Icon>
            )}
          </div>
        ))}

        {keyItemDescriptions?.length < 5 && (
          <AddItemButton
            onClick={() => demandVM.addEmptyItemInArray('keyItemDescriptions')}
            text={t('button_add_demand_keyItemDescription')}
          />
        )}
      </>
    );
  };

  renderAwardDescriptions = () => {
    const {
      demandVM
    } = this.props;

    const { demand } = demandVM;

    const awardDescriptions = demand.awardDescriptions;

    return (
      <>
        {awardDescriptions?.map((item, index) => (
          <div
            className={clsx(styles.flexRowCenter, index !== 0 && styles.upper)}
            key={`demand_awardDescriptions${index}`}
          >
            <ErrorTextInput
              label={t('demand_award_description')}
              onChange={(e) => demandVM.onChangeStringArray(e.target.value, 'awardDescriptions', index)}
              value={item ?? ''}
              limit={150}
              onBlur={demandVM.onSave}
              required={index === 0}
              multiline
            />

            {index !== 0 && (
              <Icon
                size={23}
                svg
                className={styles.itemCloseIcon}
                onClick={() => {
                  demandVM.removeItemInArray('awardDescriptions', index);
                  demandVM.onSave();
                }}
              >
                {iconClose}
              </Icon>
            )}
          </div>
        ))}

        {awardDescriptions?.length < 5 && (
          <AddItemButton
            onClick={() => demandVM.addEmptyItemInArray('awardDescriptions')}
            text={t('button_add_demand_awardDescription')}
          />
        )}
      </>
    );
  };

  renderDatesDescriptions = () => {
    const {
      demandVM
    } = this.props;

    const { demand } = demandVM;

    const dates = demand.dates;

    return (
      <>
        {dates?.map((item, index) => (
          <div
            className={clsx(styles.flexRowCenter, index !== 0 && styles.upper)}
            key={`demand_dates_${index}`}
          >
            <ErrorTextInput
              label={t('demand_dates')}
              onChange={(e) => demandVM.onChangeStringArray(e.target.value, 'dates', index)}
              value={item ?? ''}
              limit={50}
              onBlur={demandVM.onSave}
              required={index === 0}
              multiline
            />

            {index !== 0 && (
              <Icon
                size={23}
                svg
                className={styles.itemCloseIcon}
                onClick={() => {
                  demandVM.removeItemInArray('dates', index);
                  demandVM.onSave();
                }}
              >
                {iconClose}
              </Icon>
            )}
          </div>
        ))}

        {dates?.length < 10 && (
          <AddItemButton
            onClick={() => demandVM.addEmptyItemInArray('dates')}
            text={t('button_add_demand_date')}
          />
        )}
      </>
    );
  };

  renderRules = () => {
    const {
      demandVM
    } = this.props;

    const { demand } = demandVM;

    const rules = demand.rules;

    return (
      <>
        {rules?.map((item, index) => (
          <div
            className={clsx(styles.flexRowCenter, index !== 0 && styles.upper)}
            key={`demand_rules_${index}`}
          >
            <ErrorTextInput
              label={t('demand_rules')}
              onChange={(e) => demandVM.onChangeStringArray(e.target.value, 'rules', index)}
              value={item}
              limit={200}
              onBlur={demandVM.onSave}
              required={index === 0}
              multiline
            />

            {index !== 0 && (
              <Icon
                size={23}
                svg
                className={styles.itemCloseIcon}
                onClick={() => {
                  demandVM.removeItemInArray('rules', index);
                  demandVM.onSave();
                }}
              >
                {iconClose}
              </Icon>
            )}

          </div>
        ))}

        {rules?.length < 5 && (
          <AddItemButton
            onClick={() => demandVM.addEmptyItemInArray('rules')}
            text={t('button_add_demand_rule')}
          />
        )}
      </>
    );
  };

  // //////////

  render() {
    const {
      demandVM,
      index
    } = this.props;

    const { demand } = demandVM;

    if (!demand) return null;

    return (
      <div
        key={`demand_${index}`}
        className={styles.sectionWrapper}
      >

        {this.renderDescriptionSection()}

        {this.renderKeyItemDescriptionsSection()}

        {this.renderAwardsAndDescriptionSections()}

        {this.renderDatesSection()}

        {this.renderRegionsSection()}

        {this.renderRulesSection()}

        {this.renderDeleteButton()}

      </div>
    );
  }
}

Base.propTypes = {
  onDeleteDemand: PropTypes.func,
  demandVM: PropTypes.object,
  index: PropTypes.number,
  showPreview: PropTypes.bool,
  setTipHeight: PropTypes.func,
  tipHeight: PropTypes.object,
  titleAreaHeight: PropTypes.number,
  generalModalViewModel: PropTypes.object
};

Base.defaultProps = {
  onDeleteDemand: () => {},
  demandVM: {},
  index: 0,
  showPreview: false,
  setTipHeight: () => {},
  tipHeight: {},
  titleAreaHeight: 120,
  generalModalViewModel: null
};

export default Base;
