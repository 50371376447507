import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Mousewheel } from 'swiper';
import { Helmet } from 'react-helmet';
import { handleLineBreaks, getYoutubeEmbedUrl } from 'src/utils';
import Collapse from 'antd/es/collapse';
import Loading from 'src/components/Loading';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import Button from 'src/components/Button';
import CustomTabs from 'src/components/Tabs';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Icon from 'src/components/Icon';
import DemandCard from 'src/components/DemandCard';
import CardCarousel from 'src/components/CardCarousel';
import EventCard from 'src/components/EventCard';
import EventItemCard from 'src/components/EventItemCard';

import blueTick from 'src/assets/blueTick.png';
import fist from 'src/assets/fist.png';
import handshake from 'src/assets/handshake.png';
import faq from 'src/assets/faq.png';
import greenCheck from 'src/assets/greenCheck.png';
import iconService from 'src/assets/resourceServiceFill.svg';
import iconFunds from 'src/assets/resourceMoneyFill.svg';
import iconSpace from 'src/assets/resourceSpaceFill.svg';
import fakeAvatar from 'src/assets/fakeAvatar.png';


// sdg icon
import sdg1 from 'src/assets/sdg1.svg';
import sdg2 from 'src/assets/sdg2.svg';
import sdg3 from 'src/assets/sdg3.svg';
import sdg4 from 'src/assets/sdg4.svg';
import sdg5 from 'src/assets/sdg5.svg';
import sdg6 from 'src/assets/sdg6.svg';
import sdg7 from 'src/assets/sdg7.svg';
import sdg8 from 'src/assets/sdg8.svg';
import sdg9 from 'src/assets/sdg9.svg';
import sdg10 from 'src/assets/sdg10.svg';
import sdg11 from 'src/assets/sdg11.svg';
import sdg12 from 'src/assets/sdg12.svg';
import sdg13 from 'src/assets/sdg13.svg';
import sdg14 from 'src/assets/sdg14.svg';
import sdg15 from 'src/assets/sdg15.svg';
import sdg16 from 'src/assets/sdg16.svg';
import sdg17 from 'src/assets/sdg17.svg';

import { FILTERS_TYPES, RESOURCE_TYPES, EVENT_ELEMENT_ID, CREATE_EVENT_STEPS, KEYWORD_TYPE } from 'src/constants';
import ConstantsStore from 'src/stores/constants';
import ScrollArea from './ScrollArea';
import EventDetailPageViewModel from './vm';
import styles from './styles.module.scss';

const { Panel } = Collapse;
// install Swiper modules
SwiperCore.use([Mousewheel]);

const resourceIcons = {
  service: iconService,
  funds: iconFunds,
  space: iconSpace
};

const sdgIcon = {
  '1-1': sdg1,
  '1-2': sdg2,
  '1-3': sdg3,
  '1-4': sdg4,
  '2-1': sdg5,
  '2-2': sdg6,
  '2-3': sdg7,
  '2-4': sdg8,
  '2-5': sdg9,
  '3-1': sdg10,
  '3-2': sdg11,
  '3-3': sdg12,
  '3-4': sdg13,
  '3-5': sdg14,
  '3-6': sdg15,
  '3-7': sdg16,
  '3-8': sdg17
};

@observer
class EventDetailPage extends React.Component {
  descRef = React.createRef();
  keyFindingRef = React.createRef();
  detailRef = React.createRef();
  partnerRef = React.createRef();
  faqRef = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new EventDetailPageViewModel(this.props);
  }

  async componentDidMount() {
    await this.vm.didMount(this.props);
    this.vm.checkIsTextClamped(this.descRef, 'Desc');
    this.vm.checkIsTextClamped(this.keyFindingRef, 'KeyFinding');
  }

  async componentDidUpdate(prevProps) {
    await this.vm.didUpdate(prevProps, this.props);
    this.vm.checkIsTextClamped(this.descRef, 'Desc'); // call here prevent more missing
    this.vm.checkIsTextClamped(this.keyFindingRef, 'KeyFinding'); // call here prevent more missing
  }

  renderBasics() {
    const event = this.vm.event;
    const user = event.user;
    return (
      <section
        className={styles.wrapper728}
        id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.Basic]}
      >
        <div
          style={{ backgroundImage: `url(${event.banner})` }}
          className={styles.banner}
        />
        <div className={styles.contentBox}>
          <div className={styles.titleAndBlueTick}>
            <div className={styles.title}>
              {event.name}
            </div>
            {event.blueTickAt && (
              <img
                src={blueTick}
                alt="bluetick"
                className={styles.blueTick}
              />
            )}
          </div>

          <div className={clsx(styles.marginTop10, styles.marginBottom10)}>
            {[RESOURCE_TYPES.Space, RESOURCE_TYPES.Funds, RESOURCE_TYPES.Service].filter((type) => event.demandTypes?.includes(type)).map((type) => (
              <img
                key={type}
                src={resourceIcons[type]}
                alt="resource"
              />
            ))}
          </div>

          <div className={clsx(styles.flexRow, styles.marginTop10, styles.alignTop)}>
            <div className={clsx(styles.text, styles.marginRight5)}>{`${t('event_locations')}：`}</div>
            <div>
              {event.convertedRegions.map((r, i) => (
                <span
                  key={r}
                  className={styles.regionHashTag}
                  onClick={() => {
                    const values = ConstantsStore.reverseCombinedRegionValue(r);
                    this.vm.toEventPageWithKey(FILTERS_TYPES.Regions, values);
                  }}
                >
                  <span className={styles.normalText}>
                    {ConstantsStore.getRegionLabelByValue(r)}
                  </span>
                  {i < event.convertedRegions.length - 1 && (
                    <span className={styles.text}>•</span>
                  )}
                </span>
              ))}
            </div>
          </div>

          <div className={clsx(styles.flexRow, styles.alignTop)}>
            <div className={clsx(styles.text, styles.marginRight5)}>{`${t('event_fields')}：`}</div>
            <div>
              {this.vm.serviceTypesTags.map((tag, i) => (
                <span key={tag} className={styles.normalText}>
                  {tag}
                  {i < this.vm.serviceTypesTags.length - 1 && (
                    <span className={styles.x}>x</span>
                  )}
                </span>
              ))}
            </div>
          </div>

          <div className={clsx(styles.flexRow, styles.marginTop10, styles.flexWrap)}>
            {Object.entries(event.keywordsObj)?.filter((item) => !!item[1])?.map((k, i) => (
              <div
                key={k[0]}
                onClick={() => this.vm.onHashTagSearch(k[0], k[1])}
                className={styles.keywordWrapper}
              >
                <span className={styles.hashtag}>#</span>
                <span className={styles.boldText}>
                  {k[0] === KEYWORD_TYPE.Sdg ? k[1].split('_')[0] : k[1]}
                </span>
              </div>
            ))}
          </div>

          <div className={clsx(styles.sloganTitle)}>
            {t('event_slogan_title')}
          </div>
          <div className={clsx(styles.titlePurple)}>
            {event.slogan}
          </div>
          <div
            className={clsx(styles.text, styles.marginTop5, !this.vm.hasClickedDescMore ? styles.clamp : '')}
            ref={this.descRef}
            id="eventDesc"
          >
            {event.description}
          </div>
          {this.vm.isDescClamped && !this.vm.hasClickedDescMore && (
            <div
              className={styles.more}
              onClick={this.vm.clickDescMore}
            >
              {t('expand_desc_more')}
            </div>
          )}

          <div
            className={clsx(styles.flexRow, styles.userArea)}
            onClick={() => this.vm.toProfile(user.id)}
          >
            <div
              style={{ backgroundImage: `url(${user.avatar ?? fakeAvatar})` }}
              className={styles.avatar}
            >
              {user.blueTickAt && (
                <img
                  src={blueTick}
                  alt="bluetick"
                  className={styles.blueTick}
                />
              )}
            </div>
            <div className={styles.normalText}>{user.displayName || user.name}</div>
          </div>
        </div>
      </section>
    );
  }

  renderKeyFindingMedia() {
    const keyFinding = this.vm.event.keyFinding;

    if (keyFinding.video) {
      return (
        <div
          key={keyFinding.video}
          className={styles.video}
        >
          <iframe
            title={keyFinding.video}
            width="100%"
            height="100%"
            src={getYoutubeEmbedUrl(keyFinding.video)}
            allow="fullscreen"
            allowFullScreen
          />
        </div>
      );
    }

    if (keyFinding.photoUrl) {
      return (
        <img
          src={keyFinding.photoUrl}
          alt="keyfinding"
          className={styles.video}
        />
      );
    }

    return null;
  }

  renderKeyFinding() {
    const keyFinding = this.vm.event.keyFinding;
    return (
      <section
        className={clsx(styles.contentBox1, styles.marginBottom40, styles.wrapper728)}
        id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.KeyFinding]}
      >
        <div className={styles.sectionTitles}>
          <span>{t('event_keyFindings_title1')}</span>
          <span>{t('event_keyFindings_title2')}</span>
        </div>

        {this.renderKeyFindingMedia()}

        <div className={clsx(styles.subtitle, styles.marginTop10, styles.marginBottom10)}>
          {keyFinding.title}
        </div>
        <div
          className={clsx(styles.text, styles.marginBottom10, !this.vm.hasClickedKeyFindingMore ? styles.clamp6 : '')}
          ref={this.keyFindingRef}
        >
          {handleLineBreaks(keyFinding.description)}
        </div>
        {this.vm.isKeyFindingClamped && !this.vm.hasClickedKeyFindingMore && (
          <div
            className={styles.more}
            onClick={this.vm.clickKeyFindingMore}
          >
            {t('expand_desc_more')}
          </div>
        )}
      </section>
    );
  }

  renderTargets() {
    const targets = this.vm.event.targets;
    return (
      <section
        className={clsx(styles.section, styles.wrapper728)}
        id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.Target]}
      >
        <div className={clsx(styles.sectionTitles, styles.contentBox1)}>
          <span>{t('event_targets_title1')}</span>
          <span>{t('event_targets_title2')}</span>
        </div>
        <div className={styles.background} />
        {targets.length === 1
          ? (
            <EventItemCard
              item={targets[0]}
              title={targets[0].title}
              description={targets[0].description}
              option={ConstantsStore.serviceTargetsOptions.find((el) => el.value === targets[0].option)?.label ?? targets[0].option.slice(1)}
              hasOnlyOne
            />
          )
          : (
            <div className={styles.carouselWrapper}>
              <CardCarousel
                className="targetsSwiper"
                spaceBetween={10}
                items={targets.map((target) => (
                  <EventItemCard
                    key={target.title}
                    item={target}
                    title={target.title}
                    description={target.description}
                    option={ConstantsStore.serviceTargetsOptions.find((el) => el.value === target.option)?.label ?? target.option.slice(1)}
                  />
                ))}
              />
            </div>
          )}
      </section>
    );
  }

  renderVision() {
    const vision = this.vm.event.vision;
    return (
      <section
        className={clsx(styles.contentBox1, styles.marginTop40, styles.wrapper728)}
        id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.Vision]}
      >
        <div className={styles.sectionTitles}>
          <span>{t('event_vision_title1')}</span>
          <span>{t('event_vision_title2')}</span>
        </div>

        <div className={styles.sdgsContainer}>
          {vision.sdgs.map((s) => (
            <div
              key={s}
              className={clsx(styles.sdg, styles[`sdg${s.split('-')[0]}`])}
            >
              <Icon
                size={this.vm.isMobile ? 24 : 28}
                svg
                className={styles[`sdgIcon${s.split('-')[0]}`]}
              >
                {sdgIcon[s]}
              </Icon>
              {ConstantsStore.flattenedSdgsOptions.find((el) => el.value === s)?.label}
            </div>
          ))}
        </div>
        <div className={clsx(styles.text, styles.marginBottom10)}>
          {handleLineBreaks(vision.description)}
        </div>
      </section>
    );
  }

  renderKeyItems() {
    const keyItem = this.vm.event.keyItem;
    const items = this.vm.event.keyItem?.items;
    return (
      <>
        <div
          className={styles.wrapper728}
          id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.KeyItem]}
        >
          <img
            src={fist}
            alt="fist"
            className={clsx(styles.illustration, styles.marginTop10)}
          />
          <div className={clsx(styles.sectionTitles, styles.contentBox1)}>
            <span>{t('event_keyItems_title1')}</span>
            <span>{t('event_keyItems_title2')}</span>
          </div>
          <div className={clsx(styles.text, styles.contentBox1, styles.marginBottom10)}>
            {keyItem.introduction}
          </div>
        </div>
        {items.length === 1
          ? (
            <div className={clsx(styles.oneKeyItemsWrapper)}>
              <div className={styles.wrapper728}>
                <EventItemCard
                  item={items[0]}
                  title={items[0].title}
                  description={items[0].description}
                  option={ConstantsStore.keyItemsOptions.find((el) => el.value === items[0].option)?.label ?? items[0].option?.slice(1)}
                  className={styles.oneCardWrapper}
                  hasOnlyOne
                />
              </div>
            </div>
          )
          : (
            <div className={clsx(styles.carouselWrapper, styles.keyItemsCarouselWrapper)}>
              <div className={styles.wrapper728}>
                <CardCarousel
                  className="targetsSwiper keyItemsSwiper"
                  spaceBetween={10}
                  items={items.map((k) => (
                    <EventItemCard
                      key={k.title}
                      item={k}
                      title={k.title}
                      description={k.description}
                      option={ConstantsStore.keyItemsOptions.find((el) => el.value === k.option)?.label ?? k.option?.slice(1)}
                    />
                  ))}
                />
              </div>
            </div>
          )}
      </>
    );
  }

  renderFeatures() {
    const features = this.vm.event.features;
    return (
      <div
        className={styles.featuresContainer}
        id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.Features]}
      >
        <div className={styles.wrapper728}>
          <div className={styles.sectionTitles}>
            <span>{t('event_features_title1')}</span>
            <span>{t('event_features_title2')}</span>
          </div>
          <div>
            {features.map((f) => (
              <div key={f.title} className={styles.featureContainer}>
                <img
                  src={greenCheck}
                  alt="green check"
                  className={clsx(styles.check)}
                />
                <div>
                  <div className={styles.title}>
                    {f.title}
                  </div>
                  <div className={styles.subtitle}>
                    {f.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderKeyItemsAndFeatures() {
    return (
      <div
        className={styles.marginTop40}
      >
        {this.renderKeyItems()}

        {this.renderFeatures()}
      </div>
    );
  }

  renderEventDetail() {
    const event = this.vm.event;

    return (
      <section
        className={styles.paddingTop}
        ref={this.detailRef}
        key="eventDetail"
      >
        {event.keyFinding && this.renderKeyFinding()}
        {event.targets && this.renderTargets()}
        {event.vision && this.renderVision()}
        {event.keyItem && event.features && this.renderKeyItemsAndFeatures()}
      </section>
    );
  }

  renderDemandsAndPartners() {
    const relatedUsers = this.vm.event.relatedUsers;
    const coInitiators = relatedUsers.coInitiators;
    const collaborators = relatedUsers.collaborators;
    const coOrganiser = relatedUsers.coOrganiser;

    return (
      <section
        className={clsx(styles.paddingTop, styles.wrapper728)}
        id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.Demand]}
        key="demandsAndPartners"
      >
        <img
          src={handshake}
          alt="handshake"
          className={clsx(styles.illustration, styles.marginTop10)}
        />
        <div ref={this.partnerRef} />
        {this.vm.event.demandTypes.length > 0 && (
        <div
          className={clsx(styles.sectionTitles, styles.contentBox1, styles.marginBottom10)}
        >
          <span>{t('event_demands_title')}</span>
        </div>
        )}
        <CustomTabs
          colorMode={2}
          items={Object.values(RESOURCE_TYPES).filter((type) => this.vm[type]?.length !== 0).map((type) => {
            return {
              key: type,
              value: type,
              label: (
                <div className={styles.flexRow}>
                  <div>{t(`resources_${type}`)}</div>
                  <Icon
                    size={20}
                    svg
                  >
                    {resourceIcons[type]}
                  </Icon>
                </div>
              ),
              content: (
                <div className={styles.demandsContainer}>
                  <div className={styles.background} />
                  <div className={styles.carouselWrapper}>
                    <CardCarousel
                      className="partnersDemandsSwiper"
                      spaceBetween={10}
                      items={this.vm[type].map((d) => (
                        <DemandCard
                          key={d.id}
                          type={d.type}
                          onClick={() => this.vm.toDemand(d.id)}
                          demand={d}
                          className={styles.demandCard}
                          footer="join"
                        />
                      ))}
                    />
                  </div>
                </div>
              ) };
          })}
          tabClassName={styles.demandsTab}
          onSwitchTab={this.vm.changeDemandTab}
          currentValue={this.vm.currentDemandTab}
        />

        {this.vm.event.demandTypes.length > 0 && (
          <div
            className={styles.seeAll}
            onClick={this.vm.toAllDemands}
          >
            See all
          </div>
        )}

        {(coInitiators.length !== 0 || collaborators.length !== 0 || coOrganiser.length !== 0) && (
          <div className={clsx(styles.contentBox1, styles.marginTop40, styles.marginBottom40)}>
            <div className={styles.sectionTitles}>
              <span>{t('event_partners_title')}</span>
            </div>

            {coInitiators.length !== 0 && (
              <div>
                <span className={styles.text}>{t('event_coInitiators')}</span>
                <span className={styles.sepline}>|</span>
                {this._renderRelatedUsers(coInitiators)}
              </div>
            )}

            {collaborators.length !== 0 && (
              <div>
                <span className={styles.text}>{t('event_collaborators')}</span>
                <span className={styles.sepline}>|</span>
                {this._renderRelatedUsers(collaborators)}
              </div>
            )}

            {coOrganiser.length !== 0 && (
              <div>
                <span className={styles.text}>{t('event_coOrganiser')}</span>
                <span className={styles.sepline}>|</span>
                {this._renderRelatedUsers(coOrganiser)}
              </div>
            )}
          </div>
        )}
      </section>
    );
  }

  _renderRelatedUsers(arr) {
    return arr?.map((u, i) => (
      <span key={u.user?.id || u.name}>
        <Link
          className={clsx(styles.partner, u.user?.id && styles.clickable)}
          to={u.user?.id && `/user/profile/${u.user.id}`}
        >
          {u.name ?? u.user.displayName}
        </Link>
        {i < arr.length - 1 && (
          <span className={styles.dot}>•</span>
        )}
      </span>
    ));
  }

  renderFaq() {
    const event = this.vm.event;
    if (event?.faqs.length === 0) { return <div id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.Faqs]} />; }

    return (
      <section
        className={clsx(styles.paddingTop, styles.wrapper728)}
        ref={this.faqRef}
        id={EVENT_ELEMENT_ID[CREATE_EVENT_STEPS.Faqs]}
        key="faqs"
      >
        <img
          src={faq}
          alt="faq"
          className={clsx(styles.illustration, styles.marginTop10)}
        />
        <div className={styles.contentBox1}>
          <div className={styles.sectionTitles}>
            <span>{t('event_faq_title')}</span>
          </div>
          <Collapse
            defaultActiveKey={[0]}
            bordered={false}
            expandIconPosition="end"
            className={clsx(styles.collapse, 'faqCollapse')}
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon={({ isActive }) => (
              <div className={styles.expandIcons}>
                <span className={clsx(styles.expandIcon1)} />
                <span className={clsx(styles.expandIcon2, !isActive ? styles.active : '')} />
              </div>
            )}
            ghost
          >
            {event.faqs.map((f, i) => (
              <Panel
                header={(
                  <div className={styles.panelHeader}>
                    {f.question}
                  </div>
                )}
                key={f.question}
              >
                <p className={clsx(styles.ans)}>{f.answer}</p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </section>
    );
  }

  renderContent() {
    const event = this.vm.event;

    const anchorHeader = [
      {
        headerTitle: t('event_tab_detail'),
        headerRef: this.detailRef,
        headerID: 'detail'
      },
      {
        headerTitle: t('event_tab_partners'),
        headerRef: this.partnerRef,
        headerID: 'partner'
      }
    ];
    if (event?.faqs.length !== 0) {
      anchorHeader.push({
        headerTitle: t('event_tab_faq'),
        headerRef: this.faqRef,
        headerID: 'faq'
      });
    }

    return (
      <div className={styles.mainContainer}>
        <div className={styles.topBackground} />
        {this.renderBasics()}

        <ScrollArea
          anchorHeader={anchorHeader}
          sections={[this.renderEventDetail(), this.renderDemandsAndPartners(), this.renderFaq()]}
        />

        {this.renderBottomButtons()}
      </div>
    );
  }

  renderBottomButtons() {
    const disableText = () => {
      if (!this.vm.isStarted) return t('event_ctabutton_not_start');
      if (!this.vm.isEnded) return t('event_ctabutton_disable');
      return t('event_ctabutton_end');
    };

    return (
      <div className={styles.bottomButtons}>
        {this.vm.isFromCreateEvent && (
          <Button
            className={clsx(styles.ctaButton)}
            onClick={this.vm.onCopySample}
          >
            {t('event_ctabutton_copy_sample')}
          </Button>
        )}

        {(!this.vm.isFromCreateEvent || this.vm.isRecruiting) && (
          <Button
            type={this.vm.isFromCreateEvent ? 'default' : 'primary'}
            className={clsx(styles.ctaButton, !this.vm.isRecruiting ? styles.disabled : '')}
            onClick={() => this.vm.handleClickJoin(this.partnerRef)}
            disabled={!this.vm.isRecruiting}
          >
            {this.vm.isRecruiting
              ? (
                <div className={styles.buttonText}>
                  {`${t('event_ctabutton_last')} ${this.vm.remainedTime} ${t('day')}${t('event_ctabutton_join')}`}
                </div>
              )
              : disableText()}
          </Button>
        )}

      </div>
    );
  }

  renderSimilarEvents() {
    return (
      <div className={styles.similarEvents}>
        <hr className="dashedPurple" style={{ margin: '15px 0' }} />
        <div className={styles.titleLine}>
          <div className={styles.title}>
            {t('event_you_may_be_interested')}
          </div>
          <div
            className={styles.seeAllSimilar}
            onClick={this.vm.toAllEvents}
          >
            See all
          </div>
        </div>
        <div className={styles.similarList}>
          <Swiper
            className={clsx(styles.listSwiper, 'similarEventSwiper')}
            mousewheel={{
              releaseOnEdges: true,
              forceToAxis: true
            }}
            slidesPerView="auto"
            spaceBetween={40}
            direction="horizontal"
          >
            {this.vm.similarEvents.map((item) => (
              <SwiperSlide key={item.id}>
                <EventCard
                  key={item.id}
                  item={item}
                  onClick={() => this.vm.toEventDetail(item.id)}
                  colorMode="light"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  }

  render() {
    const { router } = this.props;

    return (
      <div className={clsx(styles.eventDetailPage, 'page')}>
        <Helmet>
          <title>{ this.vm.event ? `${this.vm.event?.name} | go action` : 'go action'}</title>
        </Helmet>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          footerStyle={{ marginBottom: 100 }}
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_events'), path: `/events?page=${router?.location?.state?.page ?? 1}&sort=${router?.location?.state?.sort ?? 1}` },
              { label: t('breadcrumb_event_detail'), path: '' }
            ]}
            right={null}
            colorType="purple"
            withHeader
          />
          {this.vm.event && this.renderContent()}
          {this.vm.event && this.vm.similarEvents?.length !== 0 && this.renderSimilarEvents()}
        </MainLayout>

        { this.vm.isLoading && <Loading /> }
      </div>
    );
  }
}

EventDetailPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  router: PropTypes.object
};

EventDetailPage.defaultProps = {
  router: {}
};

export default withProfile(withRouter(withTranslation()(EventDetailPage)), false);
