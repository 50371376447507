import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import SuccessComp from 'src/components/Success';
import { USER_TYPES } from 'src/constants';

@observer
class Success extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  toHomePage = () => {
    const { router } = this.props;
    router.navigate('/');
  };

  toProfilePage = () => {
    const { router } = this.props;
    router.navigate(`/user/profile/${this.vm.id}`);
  };

  render() {
    const { router } = this.props;
    const texts = () => {
      switch (this.vm.identity) {
        case USER_TYPES.Personal:
          return [t('register_success_subtitle')];
        case USER_TYPES.Organization:
          return [t('register_success_subtitle_org1'), t('register_success_subtitle_org2'), t('register_success_subtitle_org3')];
        default:
      }
      return [];
    };

    const props = this.vm.identity === USER_TYPES.Personal
      ? {
        bottomText: t('register_success_bottomText'),
        bottomLinkText: t('register_success_goToHomePage'),
        bottomLinkHref: '/'
      }
      : {
        button2Text: t('register_success_button2_org'),
        onClickButton2: this.toProfilePage
      };

    return (
      <SuccessComp
        title={this.vm.identity === USER_TYPES.Personal ? t('register_success_title') : t('register_success_title_org')}
        texts={texts()}
        buttonText={this.vm.identity === USER_TYPES.Personal ? t('register_success_button') : t('register_success_button_org')}
        onClickButton={this.vm.identity === USER_TYPES.Personal ? this.toProfilePage : this.toHomePage}
        containerStyle={{ padding: '0 20px', marginTop: -10 }}
        illustration={this.vm.identity === USER_TYPES.Personal ? 'congrats' : 'greenCheck'}
        {...props}
      />
    );
  }
}

Success.propTypes = {
  router: PropTypes.object,
  vm: PropTypes.object
};

Success.defaultProps = {
  router: {},
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Success)), false);
