
export default class Banner {
  constructor(params) {
    const {
      id,
      code,
      description,
      link,
      startAt,
      endAt,
      thumbnail
    } = params ?? {};

    this.id = id;
    this.code = code;
    this.description = description;
    this.link = link;
    this.startAt = startAt;
    this.endAt = endAt;
    this.thumbnail = thumbnail;
  }



  static fromRes(data) {
    return new Banner(data);
  }
}
