import API from 'src/apis';
import Banner from 'src/models/response/Banner';

export default class BannerService {
  static async getBannerList() {
    const { data } = await API.banner.bannerList();
    return {
      list: data.list.map((banner) => new Banner(banner))
    };
  }
}
