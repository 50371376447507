import Resource from './Resource';

export default class RecommendResource {
  constructor(params) {
    const {
      createdAt,
      id,
      resource
    } = params ?? {};

    this.createdAt = createdAt;
    this.id = id;
    this.resource = Resource.fromRes(resource);
  }

  static fromRes(data) {
    return new RecommendResource(data);
  }
}



