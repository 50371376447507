import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import withErrorInput from 'src/components/withErrorInput';
import { TERMS_AND_POLICY_TABS } from 'src/constants';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import styles from '../../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class Step3 extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <div className={styles.onePageContainer}>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('register_organization_step3_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('register_organization_step3_subtitle')}
          </h3>
        </div>

        <div className={clsx(styles.marginBottom, styles.inputs)}>
          <ErrorTextInput
            key="contact name"
            label={t('register_organization_step3_name')}
            value={this.vm.contact.name ?? ''}
            onChange={(e) => this.vm.onContactChange('name', e.target.value)}
            required
          />
          <ErrorTextInput
            key="contact job unit"
            label={t('register_organization_step3_jobUnit')}
            value={this.vm.contact.jobUnit ?? ''}
            onChange={(e) => this.vm.onContactChange('jobUnit', e.target.value)}
            required
          />
          <ErrorTextInput
            key="contact job title"
            label={t('register_organization_step3_jobTitle')}
            value={this.vm.contact.jobTitle ?? ''}
            onChange={(e) => this.vm.onContactChange('jobTitle', e.target.value)}
            required
          />
          <ErrorTextInput
            showError={this.vm.showError}
            key="contact phone"
            label={t('register_organization_step3_phone')}
            sx={{
              flexGrow: 1,
              marginLeft: 0
            }}
            onChange={(e) => this.vm.onContactChange('phone', e.target.value)}
            value={this.vm.contact.phone ?? ''}
            required
            validValue={this.vm.isContactPhoneValid}
            errorMessage={this.vm.contact.phone ? t('format_is_wrong') : t('this_is_required')}
          />

          <ErrorTextInput
            showError={this.vm.showError}
            key="contact email"
            label={t('register_organization_step3_email')}
            value={this.vm.contact.email ?? ''}
            onChange={(e) => this.vm.onContactChange('email', e.target.value)}
            required
            validValue={this.vm.isContactEmailValid}
            errorMessage={this.vm.contact.email ? t('format_is_wrong') : t('this_is_required')}
          />
        </div>

        <div className={clsx(styles.bottomContainer2, styles.withBottomText)}>
          <div className={clsx(styles.buttonsRow2, styles.twoButtons)}>
            <Button
              onClick={this.vm.onPreviousClick}
              style={{ width: '100%' }}
              className={styles.nextButton}
              type="default"
              withLeftArrow
            >
              {t('previous_step')}
            </Button>
            <Button
              onClick={this.vm.onFinishContactInfo}
              style={{ width: '100%' }}
              className={styles.nextButton}
              disabled={this.vm.isOrgStep3Disable}
              withRightArrow
            >
              {t('register_organization_step3_nextButton')}
            </Button>
          </div>

          <div className={styles.bottomText}>
            <h5>
              <span className={styles.text}>
                {`${t('register_agreement')} `}
              </span>
              <Link
                className={styles.link}
                to="/terms-and-policy#terms"
                state={{ tab: TERMS_AND_POLICY_TABS.Terms }}
                target="_blank"
              >
                {t('terms_of_use')}
              </Link>
              <span>{` ${t('and')} `}</span>
              <Link
                className={styles.link}
                to="/terms-and-policy#policy"
                state={{ tab: TERMS_AND_POLICY_TABS.Policy }}
                target="_blank"
              >
                {t('privacy_poliicy')}
              </Link>
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

Step3.propTypes = {
  vm: PropTypes.object
};

Step3.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Step3)), false);
