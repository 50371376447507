import { makeObservable, observable, action, runInAction, computed } from 'mobx';

export default class Vision {
  @observable sdgs = [];
  @observable description;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @action deserialize = (data) => {
    const {
      description,
      sdgs
    } = data ?? {};

    this.description = description;
    this.sdgs = sdgs ?? [];
  };

  serialize = () => {
    const data = {
      description: this.description,
      sdgs: this.sdgs
    };

    return data;
  };


  static fromRes(data) {
    return new Vision(data);
  }

  @computed get isComplete() {
    return this.sdgs?.length !== 0 && !!this.description;
  }

  @action onChangeSdg = (value) => {
    if (this.sdgs.includes(value)) {
      this.sdgs = this.sdgs.filter((sdg) => sdg !== value);
    } else {
      this.sdgs.push(value);
    }
  };

  @action onChangeSdgsArray = (array) => {
    this.sdgs = array;
  };
}
