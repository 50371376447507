import { request, getHost, getHeader } from './utils';

export const getList = ({ main, sub, pin }) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/topic/${main}/${sub}`,
    headers: {
      ...getHeader(),
      pin
    }
  };
  return request(options);
};
