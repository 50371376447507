import React from 'react';
import { observer } from 'mobx-react';
import BigHeroImage from './BigHeroImage';
import SmallHeroImage from './SmallHeroImage';

const HeroImage = (props) => {
  return (
    <>
      <BigHeroImage
        {...props}
        height="611"
        width="1043"
      />
      <SmallHeroImage
        {...props}
        height="984"
        width="584"
      />
    </>
  );
};

export default observer(HeroImage);
