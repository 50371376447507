import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import scrollDetector from 'src/stores/scrollDetector';
import Drawer from 'antd/es/drawer';
import Avatar from 'src//components/Avatar';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Tabs from 'src/components/Tabs';
import NotificationList from 'src/components/NotificationList';

import logo from 'src/assets/logo.svg';
import logoPurple from 'src/assets/logoPurple.svg';
import iconMenu from 'src/assets/iconMenu.svg';

import menuClose from 'src/assets/menuClose.svg';
import menuDiscover from 'src/assets/menuDiscover.svg';
import menuPartners from 'src/assets/menuPartners.svg';
import menuUser from 'src/assets/menuUser.svg';
import menuCredit from 'src/assets/menuCredit.svg';
import menuFlag from 'src/assets/menuFlag.svg';
import menuSparkle from 'src/assets/menuSparkle.svg';
import menuHeartHandshake from 'src/assets/menuHeartHandshake.svg';
import menuLogout from 'src/assets/menuLogout.svg';
import menuResource from 'src/assets/menuResource.svg';
import pretendTo from 'src/stores/pretendTo';
import { AppStateContext, isPro } from 'src/stores';

import styles from './styles.module.scss';
import Footer from '../Footer';
import Icon from '../Icon';
import HeaderViewModel, { MENU, TABS } from './vm';


const colorMode = {
  black: 1,
  white: 2,
  purple: 3,
  red: 4
};

@observer
class Header extends React.Component {
  containerRef = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new HeaderViewModel(props);
    this.colorMode = props.colorMode ?? 1;
    this.navigate = this.props.router.navigate;
  }

  static contextType = AppStateContext;

  componentDidMount() {
    this.vm.didMount(this.props, this.containerRef);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  componentWillUnmount() {
    this.vm.willUnmount();
  }

  mainMenuOptions = {
    Events: {
      onClick: () => this.navigate('/events'),
      icon: menuDiscover,
      label: t('header_main_find_event')
    },
    Resources: {
      onClick: () => this.navigate('/resources'),
      icon: menuPartners,
      label: t('header_main_find_resource')
    },
    Proposal: {
      onClick: () => this.navigate('/proposal'),
      icon: menuFlag,
      label: t('header_main_proposal')
    },
    Product: {
      onClick: () => this.navigate('/product'),
      icon: menuSparkle,
      label: t('header_main_add_value'),
      onlyForEventCreator: true,
      devOnly: true // phase 2 only on dev
    }
  };

  accountMenuOptions = {
    User: {
      name: 'user',
      onClick: () => this.navigate(`/user/profile/${this.vm.profile?.id ?? ''}`, { replace: true }),
      icon: menuUser,
      label: t('header_avatar_profile')
    },
    EventHosted: {
      name: 'eventHosted',
      onClick: () => this.navigate('/user/event-hosted'),
      icon: menuFlag,
      label: t('header_avatar_event_i_hosted'),
      onlyForEventCreator: true
    },
    EventJoined: {
      name: 'eventJoined',
      onClick: () => this.navigate('/user/event-joined'),
      icon: menuHeartHandshake,
      label: t('header_avatar_event_i_joined')
    },
    MyResource: {
      name: 'myResource',
      onClick: () => this.navigate('/user/my-resource'),
      icon: menuResource,
      label: t('header_avatar_my_resource')
    },
    Quota: {
      name: 'quota',
      onClick: () => this.navigate('/user/order-history'),
      icon: menuCredit,
      label: t('header_avatar_credit'),
      onlyForEventCreator: true,
      devOnly: true // phase 2 only on dev
    },
    Logout: {
      name: 'logout',
      onClick: () => this.vm?.logout(),
      icon: menuLogout,
      label: t('logout')
    }
  };

  get mainMenuItems() {
    const menusCandidates = isPro ? Object.values(this.mainMenuOptions)?.filter((item) => !item.devOnly) : Object.values(this.mainMenuOptions);
    const menuItems = this.vm.profile?.isEventCreator
      ? menusCandidates
      : menusCandidates.filter((item) => !item.onlyForEventCreator);

    return menuItems;
  }

  get accountMenuItems() {
    const menusCandidates = isPro ? Object.values(this.accountMenuOptions)?.filter((item) => !item.devOnly) : Object.values(this.accountMenuOptions);
    const menuItems = this.vm.profile?.isEventCreator
      ? menusCandidates
      : menusCandidates.filter((item) => !item.onlyForEventCreator);

    return menuItems;
  }

  // ///////////////////////////////////////////////////////////

  renderMenuItem = (item) => {
    return (
      <div
        key={item.label}
        className={styles.menuItem}
        onClick={item.onClick}
      >
        <Icon
          size={24}
          svg
          className={styles.icon}
        >
          {item.icon}
        </Icon>
        <span>
          {item.label}
        </span>
      </div>
    );
  };

  // ///////////////////////////////////////////////////////////

  renderMainMenuDesktop(menuItems) {
    const menuWrapperStyle = {
      top: this.vm.isMobile ? 50 : 55,
      height: 'fit-content',
      borderRadius: 20,
      width: '35%',
      minWidth: 320,
      minHeight: 130
    };

    return (
      <Drawer
        className={clsx(styles.avatarMenu, 'avatarMenu')}
        contentWrapperStyle={menuWrapperStyle}
        rootClassName={styles.root}
        bodyStyle={{ padding: 0, minHeight: 130 }}
        placement="right"
        closable={false}
        onClose={() => this.vm.toggleMenu()}
        open={this.vm.isMainMenuOpen}
        key="mainMenu"
        mask={false}
      >
        <div className={styles.menu}>
          {menuItems.map((item) => this.renderMenuItem(item))}
        </div>
      </Drawer>
    );
  }

  renderMainMenuMobile(menuItems) {
    const menuWrapperStyle = {
      width: '100%'
    };
    const menuHeaderStyle = {
      border: 'none',
      justifyContent: 'flex-end'
    };

    return (
      <Drawer
        className={clsx(styles.mainMenuMobile, 'mainMenuMobile')}
        headerStyle={menuHeaderStyle}
        contentWrapperStyle={menuWrapperStyle}
        rootClassName={styles.rootMobile}
        bodyStyle={{ paddingTop: 0, display: 'flex', flexDirection: 'column' }}
        placement="right"
        closable
        onClose={() => this.vm.toggleMenu()}
        open={this.vm.isMainMenuOpen}
        key="mainMenuMobile"
        mask={false}
        closeIcon={(
          <Icon
            size={24}
            svg
          >
            {menuClose}
          </Icon>
        )}
      >
        <img
          src={logoPurple}
          alt="logo"
          className={styles.logo}
        />
        <div className={styles.menu}>
          {menuItems.map((item) => this.renderMenuItem(item))}
        </div>
        <Footer className={styles.footer} isInMenu />
      </Drawer>
    );
  }

  // ///////////////////////////////////////////////////////////

  renderMainMenu() {
    return this.vm.isMobile
      ? this.renderMainMenuMobile(this.mainMenuItems)
      : this.renderMainMenuDesktop(this.mainMenuItems);
  }

  renderAvatarMenu() {
    const bodyMinHeight = 420;
    const menuWrapperStyle = {
      top: this.vm.isMobile ? 50 : 55,
      height: 'fit-content',
      borderRadius: 20,
      width: '35%',
      minWidth: 320,
      minHeight: bodyMinHeight
    };
    return (
      <Drawer
        className={clsx(styles.avatarMenu, 'avatarMenu')}
        maskStyle={{ zIndex: 800, touchAction: 'none', overscrollBehavior: 'none' }}
        contentWrapperStyle={menuWrapperStyle}
        rootClassName={styles.root}
        bodyStyle={{
          padding: 0,
          minHeight: bodyMinHeight,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
        placement="right"
        closable={false}
        onClose={() => this.vm.toggleMenu()}
        open={this.vm.isAvatarMenuOpen}
        key="avatarMenu"
        mask={false}
      >
        <Tabs
          colorMode={2}
          items={[
            {
              value: TABS.Account,
              label: t('header_tab_acccount'),
              content: (
                <>
                  <hr className="dashedGray" style={{ margin: '15px 20px' }} />
                  {this.renderAccountMenu()}
                </>
              )
            },
            {
              value: TABS.Notification,
              label: (
                <div>
                  {t('header_tab_notification')}
                  {this.vm.notificationListViewModel.unreadCount !== 0 && (
                    <span className={styles.badge}>
                      {this.vm.notificationListViewModel.unreadCount}
                    </span>
                  )}
                </div>
              ),
              content: (
                <>
                  <hr className="dashedGray" style={{ margin: '15px 20px' }} />
                  {this.renderNotifications()}
                </>
              )
            }
          ]}
          onSwitchTab={this.vm.handleChangeTabs}
          currentValue={this.vm.currentTab}
          className={styles.headerTab}
          tabClassName={styles.tab}
        />
      </Drawer>
    );
  }

  renderAccountMenu() {
    return (
      <div className={styles.menu}>
        {this.accountMenuItems.map((item) => this.renderMenuItem(item))}
      </div>
    );
  }

  renderNotifications() {
    return (
      <div className={styles.notificationContainer}>
        <NotificationList
          vm={this.vm.notificationListViewModel}
          router={this.props.router}
        />
      </div>
    );
  }

  renderMask() {
    return ReactDOM.createPortal(<div
      className={clsx(styles.drawerMask)}
      style={{ visibility: (this.vm.isMainMenuOpen || this.vm.isAvatarMenuOpen) ? 'visible' : 'hidden' }}
      onClick={(e) => {
        if (this.vm.isAvatarMenuOpen || this.vm.isMainMenuOpen) {
          e.stopPropagation();
          this.vm.toggleMenu();
        }
      }}
    />, document.body);
  }

  render() {
    return (
      <div
        className={clsx(
          styles.headerContainer,
          styles[`headerContainer${this.colorMode}`],
          scrollDetector.isScrollingDown && (!this.vm.isMainMenuOpen && !this.vm.isAvatarMenuOpen) && styles.hide,
          pretendTo.id && styles.headerContainer4
        )}
        ref={this.containerRef}
      >
        <div className={styles.header}>
          <Link to="/">
            <img
              src={this.colorMode === 2 ? logoPurple : logo}
              alt="logo"
              className={styles.logo}
            />
          </Link>
          <div style={{ flexGrow: 1 }} />

          {this.vm.profile
            ? (
              <Avatar
                url={this.vm.avatar}
                onClick={() => this.vm.toggleMenu(MENU.Avatar)}
                size={25}
                className={clsx(!!this.vm.notificationListViewModel.unreadCount && styles.withGreenMark)}
              />
            )
            : (
              <Link
                className={clsx(styles.login, this.colorMode === 2 ? styles.gray : styles.white)}
                to="/login"
                state={{ source: this.props.router?.location?.pathname }}
              >
                {t('login')}
              </Link>
            )}

          <Icon
            size={24}
            svg
            className={styles[`icon${this.colorMode}`]}
            onClick={() => this.vm.toggleMenu(MENU.Main)}
          >
            {iconMenu}
          </Icon>
        </div>

        {this.renderMainMenu()}

        {this.vm.profile && this.renderAvatarMenu()}

        {this.renderMask()}
      </div>
    );
  }
}

export default withProfile(withRouter(withTranslation()(Header)), false);

Header.propTypes = {
  router: PropTypes.object,
  colorMode: PropTypes.number
};

Header.defaultProps = {
  router: {},
  colorMode: 1
};
