import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import Select from 'antd/es/select';
import ConfigProvider from 'antd/es/config-provider';
import iconArrow from 'src/assets/iconSelectArrow.svg';
import iconUneditable from 'src/assets/iconUneditable.svg';
import iconSearch from 'src/assets/iconSelectSearch.svg';
import { makeObservable, observable, runInAction } from 'mobx';
import Icon from '../Icon';

import './styles.scss';

@observer
class CustomSelect extends React.Component {

  ref = React.createRef();
  iconRef = React.createRef();

  @observable iconSrc = iconArrow;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.props = props;
  }

  tagRender = (props) => {
    const { label, value, isLast } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <div
        onMouseDown={onPreventMouseDown}
        style={{ marginRight: 5 }}
        className={clsx('multipleSelectTag', !isLast && 'addComma')}
      >
        {label}
      </div>
    );
  };

  renderIcon = () => {
    const { readOnly } = this.props;
    if (readOnly) {
      return (
        <Icon
          size={24}
          svg
        >
          {iconUneditable}
        </Icon>
      );
    }

    return (
      <Icon
        size={24}
        svg
        style={{
          marginTop: 5,
          marginRight: 4
        }}
        ref={this.iconRef}
      >
        {this.iconSrc}
      </Icon>
    );
  };

  render() {
    const {
      options,
      placeholder,
      showSearch,
      required,
      readOnly,
      showLabel,
      withoutLabel,
      className,
      onDropdownVisibleChange,
      onFocus,
      onBlur,
      error,
      wrapperStyle,
      ...ps
    } = this.props;

    const props = this.props;

    const propsHandlingArrowIcon = readOnly ? {} : {
      onDropdownVisibleChange: (isVisible) => {
        this.iconRef.current?.classList.toggle('rotateSelectArrow');
        onDropdownVisibleChange();
        if (props.onSearch) {
          this.iconRef.current?.classList.toggle('rotateSelect360');
          runInAction(() => {
            if (isVisible) {
              this.iconSrc = iconSearch;
            } else {
              this.iconSrc = iconArrow;
            }
          });
        }
      },
      onFocus: props.value ? () => {
        this.ref.current?.classList.add('labelPurple');
        onFocus();
      } : null,
      onBlur: props.value ? () => {
        this.ref.current?.classList.remove('labelPurple');
        onBlur();
      } : null
    };

    const length = (props.defaultValue?.length || props.value?.length) ?? 0;
    const values = (props.defaultValue || props.value) ?? [];

    const icon = this.renderIcon();

    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#414EE1',
            controlHeight: 66,
            fontSize: 16
          }
        }}
      >
        <div
          className="selectWrapper"
          style={wrapperStyle}
        >
          {(showLabel || (props.value && props.value.length !== 0)) && !withoutLabel && (
            <div
              className={clsx('selectLabel', required ? 'custom-select-required' : '')}
              ref={this.ref}
            >
              {required && (
                <span className="requireMark">*</span>
              )}
              {placeholder}
            </div>
          )}
          <Select
            placeholder={placeholder}
            optionFilterProp="children"
            filterOption={showSearch ? (input, option) => (option?.label ?? '').includes(input) : null}
            options={options}
            showArrow
            suffixIcon={icon}
            showSearch={showSearch}
            tagRender={(tagRenderProps) => this.tagRender({
              ...tagRenderProps,
              isLast: tagRenderProps.value === values[length - 1] || tagRenderProps.value === values[length - 1]?.value
            })}
            required={false}
            open={readOnly ? false : undefined}
            className={clsx(className, 'customSelect', required ? 'custom-select-required-input' : '', readOnly && 'readOnly')}
            {...ps}
            {...propsHandlingArrowIcon}
          />
        </div>
      </ConfigProvider>
    );
  }
}

CustomSelect.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  options: PropTypes.array,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onDropdownVisibleChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showLabel: PropTypes.bool,
  withoutLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  showSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  onSearch: PropTypes.func,
  error: PropTypes.bool,
  wrapperStyle: PropTypes.object
};

CustomSelect.defaultProps = {
  children: null,
  options: [],
  className: null,
  disabled: false,
  onDropdownVisibleChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  showLabel: false,
  withoutLabel: false,
  readOnly: false,
  required: false,
  showSearch: false,
  placeholder: null,
  value: undefined,
  defaultValue: undefined,
  onSearch: null,
  error: false,
  wrapperStyle: {}
};

export default CustomSelect;
