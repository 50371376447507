/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD, RESOURCE_TYPES } from 'src/constants';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Tabs from 'src/components/Tabs';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import serviceIcon from 'src/assets/resourceServiceFill.svg';
import fundsIcon from 'src/assets/resourceMoneyFill.svg';
import sapceIcon from 'src/assets/resourceSpaceFill.svg';
import iconAdd from 'src/assets/iconAddPurple.svg';
import Service from './components/Service';
import Funds from './components/Funds';
import Space from './components/Space';
import styles from '../styles.module.scss';

const resourceIcons = {
  service: serviceIcon,
  funds: fundsIcon,
  space: sapceIcon
};

@observer
class Demand extends StepBase {
  constructor(props) {
    super();
    this.props = props;
    this.vm = props?.vm;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.vm?.onDemandStepUnmount) {
      this.vm.onDemandStepUnmount();
    }
  }

  renderTabs = () => {
    const { vm } = this.props;

    return (
      <div className={styles.demandTabsContainer}>
        <Tabs
          colorMode={2}
          items={Object.values(RESOURCE_TYPES).map((type) => {
            return {
              value: type,
              label: (
                <div className={styles.flexRow}>
                  <span>{t(`resources_${type}`)}</span>
                  <Icon
                    size={24}
                    svg
                    style={{ marginLeft: 2 }}
                  >
                    {resourceIcons[type]}
                  </Icon>
                </div>
              )
            };
          })}
          onSwitchTab={vm.switchDemandType}
          currentValue={vm.currentDemandTab}
          className={styles.demandTabWrapper}
        />
      </div>
    );
  };

  renderTabContent = () => {
    const { vm } = this.props;
    return vm.demands[vm.currentDemandTab]?.map((item, i) => {
      const props = {
        demandVM: item,
        index: i,
        key: `${vm.currentDemandTab}_${i}`,
        onDeleteDemand: vm.onDeleteDemand,
        showPreview: vm.showPreview,
        isMobile: vm.isMobile,
        resetIframe: vm.previewVM.resetIframe,
        setUpdatedAt: vm.setUpdatedAt,
        setTipHeight: vm.setTipHeight,
        tipHeight: vm.tipHeight,
        titleAreaHeight: vm.titleAreaHeight,
        generalModalViewModel: vm.generalModalViewModel
      };

      switch (vm.currentDemandTab) {
        case RESOURCE_TYPES.Service:
          return <Service {...props} />;
        case RESOURCE_TYPES.Funds:
          return <Funds {...props} />;
        case RESOURCE_TYPES.Space:
          return <Space {...props} />;
        default:
          return <div />;
      }
    });
  };

  render() {
    const {
      vm
    } = this.props;

    return (
      <>
        <section className={clsx(styles.stepSection, styles.fullWidth, styles.demandTabsSection)}>
          <div className={clsx(styles.stepMainContent, styles.fullWidth)}>
            <div className={styles.demandTopArea}>
              <div className={styles.flexGrow}>
                {this.renderTabs()}
              </div>
              <div className={styles.tipPlaceholder} />
            </div>
          </div>
          {/* position: absolute */}
          {!vm.isMobile && (
            <Tips
              key={`demand_${vm.currentDemandTab}_name`}
              fieldName={`demand_${vm.currentDemandTab}_name`}
              isHidden={vm.showPreview}
              className={styles.demandNameTipContainer}
              setTipHeight={vm.setTipHeight}
            />
          )}
        </section>

        {vm && this.renderTabContent()}

        <section className={styles.stepSection}>
          <div className={clsx(styles.stepMainContent, styles.flexCentered)}>

            <Button
              ghost
              onClick={vm.onAddDemand}
              className={styles.addItemButton}
            >
              <div className={styles.flexRowCenter}>
                <Icon
                  size={28}
                  svg
                >
                  {iconAdd}
                </Icon>
                {t(`button_add_demand_${vm.currentDemandTab}`)}
              </div>
            </Button>
          </div>
          <Tips isHidden />
        </section>
      </>
    );
  }
}

Demand.propTypes = {
  vm: PropTypes.object
};

Demand.defaultProps = {
  vm: {}
};

export default Demand;
