import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import iconUneditable from 'src/assets/iconUneditable.svg';
import iconVisible from 'src/assets/iconVisible.svg';
import iconInvisible from 'src/assets/iconInvisible.svg';
import Icon from '../Icon';
import styles from './styles.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#414EE1'
    }
  }
});

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    'label[data-shrink = true]': {
      color: '#333',
      border: '1px solid red'
    },
    '.MuiInputBase-input': {
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.6)',
        opacity: 1
      }
    },
    '& fieldset': {
      borderRadius: 16
    },
    '&:hover fieldset': {
      borderColor: '#414EE1'
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #414EE1'
    }
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#CFCFCF'
  },
  '.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F65353 !important'
  },
  '.Mui-error': {
    color: '#F65353 !important'
  }
});

function TextInput(props) {
  const {
    className,
    required,
    limit,
    hideLimit,
    value,
    caption,
    ...ps
  } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputProps = () => {
    if (props.type === 'password') {
      return {
        ...props.inputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword
                ? (
                  <Icon
                    size={24}
                    svg
                    style={{ cursor: 'pointer' }}
                  >
                    {iconVisible}
                  </Icon>
                )
                : (
                  <Icon
                    size={24}
                    svg
                    style={{ cursor: 'pointer' }}
                  >
                    {iconInvisible}
                  </Icon>
                )}
            </IconButton>
          </InputAdornment>
        )
      };
    }

    if (props.readOnly) {
      return {
        ...props.inputProps,
        readOnly: props.readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <Icon
              size={24}
              svg
            >
              {iconUneditable}
            </Icon>
          </InputAdornment>
        )
      };
    }

    return { ...props.inputProps };
  };

  const type = () => {
    if (props.type === 'password') {
      if (showPassword) {
        return 'text';
      }
      return 'password';
    }
    return props.type;
  };

  const renderBottomCaption = () => {

    return (
      <div className={styles.bottomCaption}>
        <div className={styles.desc}>
          {caption}
        </div>
        {(!!limit && !hideLimit) && (
          <div className={styles.limitContainer}>
            <span className={value?.length > limit ? styles.error : ''}>
              {value?.length || 0}
            </span>
            <span>/</span>
            <span>{limit}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssTextField
        {...ps}
        value={value}
        className={clsx('customInput', className, required ? 'custom-input-required' : '')}
        sx={{
          fontFamily: 'Noto Sans TC',
          width: '100%',
          ...props.sx
        }}
        type={type()}
        InputProps={{
          ...inputProps(),
          sx: { minHeight: 66 }
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          maxLength: limit
        }}
        required={required}
      />
      {(caption || limit) && renderBottomCaption()}
    </ThemeProvider>
  );
}

TextInput.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  limit: PropTypes.number,
  hideLimit: PropTypes.bool,
  caption: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  inputProps: PropTypes.object,
  sx: PropTypes.object
};

TextInput.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  required: false,
  value: null,
  limit: null,
  hideLimit: false,
  caption: null,
  type: 'text',
  readOnly: false,
  inputProps: {},
  sx: {}
};

export default TextInput;
