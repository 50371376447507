import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { t } from 'i18next';
import UserService from 'src/services/user';
import RegisterService from 'src/services/register';
import EventService from 'src/services/event';
import { USER_TYPES, SOCIALS_TYPE } from 'src/constants';

import ErrorService from 'src/services/errors';

class ApplicationPageViewModel {
  @observable id;
  @observable userId;
  @observable isMobile = false;
  @observable application;
  @observable user;
  @observable meta;
  @observable identity;
  @observable fields;
  @observable resource;
  @observable eventBanner;
  @observable isMyEvent = false;

  @observable hasClickedMotivationMore = false;
  @observable hasClickedCognitionMore = false;

  @observable isConfirmAcceptModalOpen = false;

  constructor(props) {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    console.log('ApplicationPage.didMount, params', props.router.params);
    console.log(props);
    const { id } = props.router.params;
    this.props = props;
    this.id = id;
    this.isMobile = props.context.state.isMobile;
    await this.getRegisterDetail();
    if (this.application.eventId.id) {
      await this.getEventDetail(this.application.eventId.id);
    }
  };

  @action getRegisterDetail = async () => {
    try {
      const res = await RegisterService.detail(this.id);
      console.log(res);
      runInAction(() => {
        this.application = res;
        this.user = res.user;
        this.userId = res.user.id;
        this.identity = res.profile?.type;
        this.meta = res.profile?.meta;
        this.resource = res.resource;
        this.unserializeFields(res);
      });
    } catch (error) {
      switch (error?.response?.status) {
        case 403:
          ErrorService.onCustomError(
            t('error_application_403'),
            null,
            this.toHomePage
          );
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  @action unserializeFields = (res) => {
    try {
      this.fields = JSON.parse(res.fields);
    } catch (e) {
      console.log(e);
    }
  };

  @action getEventDetail = async (eventId) => {
    try {
      const res = await EventService.detail(eventId);
      if (!this.props.profile) {
        await UserService.getProfile();
      }
      runInAction(() => {
        this.eventBanner = res.banner;
        this.isMyEvent = this.props.profile?.id === res.user.id;
      });
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  @computed
  get relatedRegions() {
    if (this.identity === USER_TYPES.Personal) {
      const arr = this.meta.cities
        .concat(this.meta.cityOfBirth)
        .concat(this.meta.cityOfResidence);

      return arr?.filter((city, i) => !!city && arr.indexOf(city) === i);
    }

    if (this.identity === USER_TYPES.Organization) {
      const arr = this.meta.cities
        .concat(this.meta.location);

      return arr?.filter((city, i) => !!city && arr.indexOf(city) === i);
    }

    return [];
  }

  @computed
  get socialLinks() {
    let links = [];
    if (this.meta?.socials?.length !== 0) {
      links = this.meta?.socials?.map((s) => {
        try {
          const obj = JSON.parse(s);
          if (obj.name === SOCIALS_TYPE.Line) {
            this.lineId = obj.url?.split('~')[1] ?? '';
          } else {
            this[obj.name] = obj.url;
          }
          return obj;
        } catch (e) {
          console.log(e);
          return {};
        }
      });
    }

    return links.filter((item) => item?.url) ?? [];
  }

  isDescClamped(ref) {
    const el = ref.current;
    if (el) {
      return el.scrollHeight > el.clientHeight;
    }
    return false;
  }

  @action clickMotivationMore = () => {
    this.hasClickedMotivationMore = true;
  };

  @action clickCognitionMore = () => {
    this.hasClickedCognitionMore = true;
  };

  toggleAcceptStatus = async () => {
    try {
      const res = await RegisterService.update(this.id, { isAccepted: !this.application.acceptedAt });
      await this.getRegisterDetail();
      this.toggleConfirmAcceptModal();
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  @action toggleConfirmAcceptModal = () => {
    console.log('toggle');
    this.isConfirmAcceptModalOpen = !this.isConfirmAcceptModalOpen;
  };

  toHomePage = () => {
    const { navigate } = this.props.router;
    navigate('/', { replace: true });
  };

}

export default ApplicationPageViewModel;
