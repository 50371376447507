import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import Button from 'src/components/Button';
import SDGsBubble from 'src/components/Bubble/SDGsBubble';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import styles from '../../styles.module.scss';

@observer
class SDGs extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <>
        <div className={styles.interestsTitles}>
          <h2 className={clsx(styles.title, styles.sdgTitle)}>
            {t('register_sdgInterested_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('register_sdgInterested_subtitle')}
          </h3>
        </div>
        <SDGsBubble
          containerClassName={styles.sdgBubbleOptionsContainer}
          sdgsDataKey="sdgsOptions"
          handleToggleSdg={this.vm.handleToggleSdg}
          sdgs={this.vm.sdgs}
        />
        <div className={clsx(styles.bottomContainer, styles.buttonsRow, styles.twoButtons)}>
          <Button
            onClick={this.vm.onPreviousClick}
            style={{ width: '100%' }}
            className={styles.nextButton}
            type="default"
            withLeftArrow
          >
            {t('previous_step')}
          </Button>
          <Button
            onClick={this.vm.onFinishSdgs}
            style={{ width: '100%' }}
            className={styles.nextButton}
            disabled={this.vm.sdgs.length === 0}
            loading={this.vm.isLoading}
            withRightArrow
          >
            {t('register_sdgInterested_next')}
          </Button>
        </div>
      </>
    );
  }
}

SDGs.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  vm: PropTypes.object
};

SDGs.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(SDGs)), false);
