import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Tabs from 'src/components/Tabs';
import Icon from 'src/components/Icon';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import DemandCard from 'src/components/DemandCard';
import MyResourceModal from 'src/components/Modals/MyResourceModal';
import ResourceAddModal from 'src/components/Modals/ResourceAddModal';
import MemberOnlyFeatureModal from 'src/components/Modals/MemberOnlyFeatureModal';
import handshake from 'src/assets/handshake.png';
import iconService from 'src/assets/resourceServiceFill.svg';
import iconFunds from 'src/assets/resourceMoneyFill.svg';
import iconSpace from 'src/assets/resourceSpaceFill.svg';
import { RESOURCE_TYPES } from 'src/constants';

import DemandsPageViewModel from './vm';

import styles from './styles.module.scss';


const resourceIcons = {
  service: iconService,
  funds: iconFunds,
  space: iconSpace
};

@observer
class DemandsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new DemandsPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  renderDemands(type) {
    const demands = this.vm[type];
    return (
      <div className={styles.demandsContainer}>
        <div className={styles.listContainer}>
          {demands.map((d) => (
            <DemandCard
              key={d.id}
              type={d.type}
              onClick={() => this.vm.toDemand(d.id)}
              demand={d}
              onClickButton={(e) => {
                e.stopPropagation();
                this.vm.onClickRegister(d.id, d.type, d.name);
              }}
              className={styles.demandCard}
              footer="join"
            />
          ))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={clsx(styles.demandDetailPage, 'page')}>
        <MainLayout
          {...this.props}
          hideHeader
          footerStyle={{ marginTop: 50 }}
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_event_detail'), path: `/events/${this.vm.eid}` },
              { label: t('demand_list'), path: '' }
            ]}
            right={null}
          />
          <div className={styles.mainContainer}>
            <img
              src={handshake}
              alt="handshake"
              className={clsx(styles.illustration, styles.marginTop10)}
            />
            <div className={clsx(styles.sectionTitles, styles.contentBox1)}>
              <span>{t('event_demands_title')}</span>
            </div>
            <div className={styles.tabsContainer}>
              <Tabs
                colorMode={2}
                items={Object.values(RESOURCE_TYPES).filter((type) => this.vm[type]?.length !== 0).map((type) => {
                  return {
                    value: type,
                    label: (
                      <div className={styles.flexRow}>
                        <span>{t(`resources_${type}`)}</span>
                        <Icon
                          size={24}
                          svg
                          style={{ marginLeft: 2 }}
                        >
                          {resourceIcons[type]}
                        </Icon>
                      </div>
                    ),
                    content: this.renderDemands(type)
                  };
                })}
                onSwitchTab={this.vm.switchTab}
                currentValue={this.vm.currentTab}
                className={styles.tabs}
                centered
              />
            </div>
          </div>

          {this.vm.myResourcesModalVM && (
          <MyResourceModal
            vm={this.vm.myResourcesModalVM}
            isMobile={this.props.context.state.isMobile}
            onClose={this.vm.onCloseMyResourcesModal}
          />
          )}

          <ResourceAddModal
            isOpen={this.vm.isAddResourceModalOpen}
            onModalToggle={this.vm.toggleAddResourceModal}
            isMobile={this.props.context.state.isMobile}
            vm={this.vm}
          />

          {this.vm.memberOnlyFeatureModalVM && (
          <MemberOnlyFeatureModal
            vm={this.vm.memberOnlyFeatureModalVM}
            isMobile={this.props.context.state.isMobile}
            centered
          />
          )}

        </MainLayout>
      </div>
    );
  }
}

DemandsPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(DemandsPage)), false);
