import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from 'src/components/Button';
import applicationQuestionMark from 'src/assets/illustrationApplication.svg';
import applicationReject from 'src/assets/illustrationReject.svg';
import BaseModal from '../BaseModal';

import styles from './styles.module.scss';

@observer
class ConfirmAcceptModal extends React.Component {

  constructor(props) {
    super(props);

    this.props = props;
  }

  renderContent() {
    const { isToAccept, name, onButtonClick } = this.props;
    return (
      <div className={styles.modal}>
        <img
          className={clsx(styles.marginBottom, styles.illustration)}
          src={isToAccept ? applicationQuestionMark : applicationReject}
          alt="application illustration"
        />
        <h2 className={clsx(styles.title, styles.marginBottom)}>
          {isToAccept ? t('application_confirm_modal_accept_title') : t('application_confirm_modal_deny_title')}
        </h2>
        <h3 className={clsx(styles.subtitle, styles.marginBottom)}>
          <span>{isToAccept ? t('application_confirm_modal_accept_desc_1') : t('application_confirm_modal_deny_desc_1')}</span>
          <span className={styles.name}>{name}</span>
          <span>{isToAccept ? t('application_confirm_modal_accept_desc_2') : t('application_confirm_modal_deny_desc_2')}</span>
        </h3>

        <div style={{ flexGrow: 1 }} />

        <Button
          onClick={onButtonClick}
          withRightArrow
          className={styles.button}
        >
          {t('application_confirm_modal_button')}
        </Button>
      </div>
    );
  }

  render() {
    const { onToggle, open, isMobile } = this.props;
    return (
      <BaseModal
        onClose={onToggle}
        open={open}
        isMobile={isMobile}
        centered
        height={450}
      >
        {this.renderContent()}
      </BaseModal>
    );
  }
}

export default withTranslation()(ConfirmAcceptModal);

ConfirmAcceptModal.propTypes = {
  isMobile: PropTypes.bool,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  isToAccept: PropTypes.bool,
  name: PropTypes.string,
  onButtonClick: PropTypes.func
};

ConfirmAcceptModal.defaultProps = {
  isMobile: false,
  onToggle: () => {},
  open: false,
  isToAccept: true,
  name: null,
  onButtonClick: () => {}
};
