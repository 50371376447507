import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { EVENT_TYPE } from 'src/constants';
import Pagination from 'antd/es/pagination';
import Spin from 'antd/es/spin';
import ConfigProvider from 'antd/lib/config-provider';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import EventCard from 'src/components/EventCard';
import DraftCard from 'src/components/EventCard/Draft';
import RectangleEventCard from 'src/components/EventCard/Rectangle';
import EventFilter from 'src/components/EventFilter';
import Loading from 'src/components/Loading';
import { Waypoint } from 'react-waypoint';

import CreateEventListPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class CreateEventListPage extends React.Component {
  constructor(props) {
    super();

    this.vm = new CreateEventListPageViewModel(props);
    this.props = props;
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderDrafts = () => {
    return (
      <div className={styles.list}>
        {this.vm.lists[EVENT_TYPE.Draft]?.map((item) => (
          <DraftCard
            key={item.id}
            item={item}
            className={styles.card}
            onClick={() => this.vm.onClickDraft(item.id)}
          />
        ))}
      </div>
    );
  };

  renderSubmitteds = () => {
    return (
      <div className={styles.list}>
        {this.vm.lists[EVENT_TYPE.Submitted]?.map((item) => (
          <RectangleEventCard
            key={item.id}
            item={item}
            className={styles.card}
            onClick={() => this.vm.onClickSubmitted(item.id)}
          />
        ))}
      </div>
    );
  };

  renderSamples = () => {
    return (
      <div className={styles.eventList}>
        {this.vm.lists[EVENT_TYPE.Sample].map((item) => (
          <EventCard
            key={item.id}
            onClick={() => this.vm.onViewSample(item.id)}
            item={item}
            className={styles.sampleCard}
            showCornerButton
            cornerButtonProps={{
              text: t('create_event_overview_sample_verb'),
              onClick: (e) => {
                e.stopPropagation();
                this.vm.onCopySample(item.id);
              }
            }}
          />
        ))}
      </div>
    );
  };

  renderList = () => {
    const type = this.vm.type;

    if (this.vm.lists[type].length === 0) {
      return this.renderEmpty(type);
    }

    switch (type) {
      case EVENT_TYPE.Draft:
        return (
          <>
            {this.renderDrafts()}

            {
              this.vm.AnchoredLists[type]?.anchor
                ? (
                  <>
                    <Waypoint
                      scrollableAncestor={window}
                      onEnter={this.vm.AnchoredLists[type].getNext}
                    />
                    {this.vm.AnchoredLists[type]?.isAwait && <Spin />}
                  </>
                )
                : null
            }
          </>
        );
      case EVENT_TYPE.Submitted:
        return (
          <>
            {this.renderSubmitteds()}

            {
              this.vm.AnchoredLists[type]?.anchor
                ? (
                  <>
                    <Waypoint
                      scrollableAncestor={window}
                      onEnter={this.vm.AnchoredLists[type].getNext}
                    />
                    {this.vm.AnchoredLists[type]?.isAwait && <Spin />}
                  </>
                )
                : null
            }
          </>
        );
      case EVENT_TYPE.Sample:
        return (
          <>
            {this.renderSamples()}

            <div style={{ flexGrow: 1 }} />

            {!!this.vm.lists[type]?.length && (
              <div className={styles.center}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#414EE1'
                    }
                  }}
                >
                  <Pagination
                    defaultCurrent={1}
                    pageSize={this.vm.limit}
                    current={this.vm.page}
                    total={this.vm.count}
                    onChange={this.vm.onPagingChange}
                  />
                </ConfigProvider>
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  renderEmpty = (type) => {
    if (this.vm.isListFetching) {
      return (
        <div className={styles.emptyMsg}>
          <Spin />
        </div>
      );
    }

    return (
      <div className={styles.emptyMsg}>
        {(this.vm.filterVM?.hasAppliedFilter || this.vm.filterVM?.filter?.keyword)
          ? t('filter_no_result')
          : t(`create_event_overview_${type}_empty`)}
      </div>
    );
  };

  render() {
    return (
      <div className={styles.listPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideFooter
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_event'), path: '/create-event-overview' },
              { label: t('breadcrumb_create_event'), path: '' }
            ]}
            right={null}
            withHeader
          />
          <div className={styles.wrapper}>
            <div className={styles.topBar}>
              <div className={styles.title}>
                {t(`create_event_list_title_${this.vm.type}`)}
              </div>

              {this.vm.filterVM && (
                <EventFilter
                  isMobile={this.props.context?.state?.isMobile}
                  vm={this.vm.filterVM}
                  hideFilter={this.vm.type === EVENT_TYPE.Draft}
                  className={styles.filterArea}
                />
              )}
            </div>

            {this.renderList()}

          </div>

        </MainLayout>

        { this.vm.isAwait && <Loading /> }
      </div>
    );
  }
}

CreateEventListPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(CreateEventListPage)), true);
