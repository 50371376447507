import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import fakeAvatar from 'src/assets/fakeAvatar.png';
import StepIntro from './components/StepIntro';
import StepDetail from './components/StepDetail';
import StepSuccess from './components/StepSuccess';

import SignUpForEventPageViewModel from './vm';
import styles from './styles.module.scss';

@observer
class SignUpForEventPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new SignUpForEventPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderAvatars() {
    return (
      <div className={styles.marginBottom20}>
        <div className={styles.avatarContainer}>
          <div
            style={{ backgroundImage: `url(${this.vm.user?.avatar ?? fakeAvatar})` }}
            className={clsx(styles.avatar)}
          />
          <div
            style={{ backgroundImage: `url(${this.vm.event?.banner ?? fakeAvatar})` }}
            className={clsx(styles.avatar, styles.avatar2)}
          />
        </div>
        <div className={styles.title}>
          {t('signup_title')}
        </div>
      </div>
    );
  }

  renderContent() {
    switch (this.vm.step) {
      case this.vm.STEPS.Intro:
        return (
          <>
            {this.renderAvatars()}
            <StepIntro vm={this.vm} />
          </>
        );
      case this.vm.STEPS.Detail:
        return (
          <>
            {this.renderAvatars()}
            <StepDetail vm={this.vm} />
          </>
        );
      case this.vm.STEPS.Success:
        return <StepSuccess vm={this.vm} />;
      default:
        return null;
    }
  }

  renderStepMark() {
    const currentStep = this.vm.step === this.vm.STEPS.Intro ? 1 : 2;
    switch (this.vm.step) {
      case this.vm.STEPS.Success:
        return null;
      default:
        return (
          <>
            <div>{currentStep}</div>
            <div style={{ margin: '0 5px' }}>of</div>
            <div>2</div>
          </>
        );
    }
  }

  render() {
    return (
      <div className={clsx(styles.signUpPage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideHeader
        >
          <Breadcrumb
            routes={[
              { label: t('signup_breadcrumb_demand'), path: `/events/${this.vm.eid}/demands${this.vm.did ? `/${this.vm.did}` : ''}` },
              { label: t('signup_breadcrumb_form'), path: '' }
            ]}
            right={this.renderStepMark()}
            onBackPress={this.vm.handleBackPress}
          />
          <div className={styles.mainContainer}>
            {this.renderContent()}
          </div>

        </MainLayout>
      </div>
    );
  }
}

SignUpForEventPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(SignUpForEventPage)), false);
