import { request, getHost, getHeader } from './utils';

export const recommend = (eventId, rid) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/admin/resource/${rid}/recommend`,
    data: { eventId },
    headers: getHeader()
  };
  return request(options);
};

