import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD, EVENT_TIPS } from 'src/constants';
import TextInput from 'src/components/TextInput';
import withErrorInput from 'src/components/withErrorInput';
import StickyGroupContainer from 'src/pages/CreateEvent/CreateEventPage/components/StickyGroupContainer';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class Vision extends StepBase {
  constructor(props) {
    super();
    this.props = props;
  }

  renderContent() {
    const { vm } = this.props;
    const event = vm.event ?? {};
    const fieldName = EVENT_FIELD.Vision;
    const vision = event[fieldName];
    const isSticky = !vm.showPreview;

    if (!vision) return null;

    return (
      <section className={styles.stepSection}>
        <StickyGroupContainer
          isSticky={isSticky}
          containerHeight={vm.tipHeight[EVENT_TIPS.Vision]}
          topOffset={vm.titleAreaHeight}
        >
          <ErrorTextInput
            key="vision_description"
            label={t(`event_field_${fieldName}_description`)}
            onChange={(e) => vm.onChange(fieldName, e.target.value, 'description')}
            value={vision.description ?? ''}
            limit={200}
            multiline
            required
            onBlur={() => vm.onSave(fieldName)}
          />
        </StickyGroupContainer>

        <Tips
          fieldName={EVENT_TIPS.Vision}
          isHidden={vm.showPreview}
          setTipHeight={vm.setTipHeight}
        />
      </section>
    );
  }

  render() {
    const { vm } = this.props;

    return !!vm && this.renderContent();
  }
}

Vision.propTypes = {
  vm: PropTypes.object
};

Vision.defaultProps = {
  vm: {}
};

export default Vision;
