import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { t } from 'i18next';
import { USER_TYPES, REGISTER_STEPS } from 'src/constants';

import Loading from 'src/components/Loading';
import MainLayout from 'src/components/MainLayout';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Breadcrumb from 'src/components/Breadcrumb';
import RegisterPageViewModel from './vm';
import Identity from './components/common/Identity';
import PersonalStep1 from './components/personal/Step1';
import OrganizationStep1 from './components/organization/Step1';
import OrganizationStep2 from './components/organization/Step2';
import Instruction from './components/organization/Instruction';
import OrganizationStep3 from './components/organization/Step3';
import Influence from './components/organization/Influence';
import ValidateEmail from './components/validation/ValidateEmail';
import ValidateEnterPhoneNumber from './components/validation/ValidateEnterPhoneNumber';
import ValidatePhone from './components/validation/ValidatePhone';
import ValidateSuccess from './components/validation/ValidateSuccess';
import TopicInterested from './components/interests/SDGs';
import RegionInterested from './components/interests/Region';
import Motto from './components/interests/Motto';
import Success from './components/common/Success';

import styles from './styles.module.scss';
import ModifyEmail from './components/validation/ModifyEmail';

@observer
class RegisterPage extends React.Component {
  constructor() {
    super();

    this.vm = new RegisterPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderSteps() {
    console.log('render steps', this.vm.step);
    switch (this.vm.step) {
      case REGISTER_STEPS.Identity:
        return <Identity {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.Account:
        if (this.vm.identity === USER_TYPES.Personal) {
          return <PersonalStep1 {...this.props} vm={this.vm} />;
        }
        return <OrganizationStep1 {...this.props} vm={this.vm} />;

      // organization
      case REGISTER_STEPS.Instruction:
        return <Instruction {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.Info:
        return <OrganizationStep2 {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.Contact:
        return <OrganizationStep3 {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.Influence:
        return <Influence {...this.props} vm={this.vm} />;

      // validation
      case REGISTER_STEPS.ModifyEmail:
        return <ModifyEmail {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.ValidateEmail:
        return <ValidateEmail {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.ValidateEnterPhoneNumber:
        return <ValidateEnterPhoneNumber {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.ValidatePhone:
        return <ValidatePhone {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.ValidateSuccess:
        return <ValidateSuccess {...this.props} vm={this.vm} />;

      // interests
      case REGISTER_STEPS.Sdgs:
        return <TopicInterested {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.Cities:
        return <RegionInterested {...this.props} vm={this.vm} />;

      case REGISTER_STEPS.Motto:
        return <Motto {...this.props} vm={this.vm} />;

      // success
      case REGISTER_STEPS.AllSuccess:
        return <Success {...this.props} vm={this.vm} />;
      default:
    }

    return null;
  }

  renderBreadcrumb() {
    const step = this.vm.step;
    const routes = [
      { label: 'go action', path: '/' },
      { label: t(`breadcrumb_register_${this.vm.step}`) }
    ];
    const stepCount = (current, all) => (
      <>
        <div>{current}</div>
        <div style={{ margin: '0 5px' }}>of</div>
        <div>{all}</div>
      </>
    );
    const rightArea = () => {
      switch (step) {
        case REGISTER_STEPS.Account:
          if (this.vm.identity === USER_TYPES.Personal) {
            return stepCount(1, 3);
          }
          return null;
        case REGISTER_STEPS.ValidateEmail:
          if (this.vm.identity === USER_TYPES.Personal) {
            return stepCount(2, 3);
          }
          return null;
        case REGISTER_STEPS.ValidateEnterPhoneNumber:
          if (this.vm.identity === USER_TYPES.Personal) {
            return stepCount(3, 3);
          }
          return null;
        case REGISTER_STEPS.ValidatePhone:
          if (this.vm.identity === USER_TYPES.Personal) {
            return stepCount(3, 3);
          }
          return null;

        case REGISTER_STEPS.Identity:
        case REGISTER_STEPS.Instruction:
        case REGISTER_STEPS.AllSuccess:
          return null;

        case REGISTER_STEPS.Info:
          return stepCount(1, 4);
        case REGISTER_STEPS.Contact:
          return stepCount(2, 4);
        case REGISTER_STEPS.Influence:
          return stepCount(3, 4);

        case REGISTER_STEPS.Sdgs:
          if (this.vm.identity === USER_TYPES.Personal) {
            return stepCount(1, 3);
          }
          return stepCount(4, 4);
        case REGISTER_STEPS.Cities:
          if (this.vm.identity === USER_TYPES.Personal) {
            return stepCount(2, 3);
          }
          return stepCount(4, 4);
        case REGISTER_STEPS.Motto:
          if (this.vm.identity === USER_TYPES.Personal) {
            return stepCount(3, 3);
          }
          return null;

        case REGISTER_STEPS.ValidateSuccess:
        case REGISTER_STEPS.ModifyEmail:
          return (
            <div
              style={{ marginLeft: 10, color: '#696B74', cursor: 'pointer' }}
              onClick={this.vm.onPreviousClick}
            >
              {t('back')}
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <Breadcrumb
        routes={routes}
        right={rightArea()}
        onBackPress={this.vm.onPreviousClick}
      />
    );
  }

  render() {
    return (
      <div className="page">
        <MainLayout
          {...this.props}
          hideHeader
        >
          {this.renderBreadcrumb()}
          <div className={styles.RegisterPage}>
            <div className={styles.container}>
              {this.renderSteps()}
            </div>
          </div>
        </MainLayout>

        { this.vm.isAwait && <Loading />}
      </div>
    );
  }
}

RegisterPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(RegisterPage)), false);
