import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD, EVENT_TIPS } from 'src/constants';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import Icon from 'src/components/Icon';
import withErrorInput from 'src/components/withErrorInput';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import DeleteItemButton from 'src/pages/CreateEvent/CreateEventPage/components/DeleteItemButton';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import iconAdd from 'src/assets/iconAddPurple.svg';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class Features extends StepBase {
  constructor(props) {
    super();
    this.props = props;
    this.vm = props?.vm;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.vm?.removeEmptyItemsWhenUnmount(EVENT_FIELD.Features);
  }

  renderFeature = (item, index) => {
    const {
      vm
    } = this.props;

    const fieldName = EVENT_FIELD.Features;

    return (
      <div
        key={`feature_${index}`}
        className={styles.featureItem}
      >
        <div>
          <div className={styles.itemTitle}>
            {`/ ${t('create_event_features_title')} 0${index + 1} /`}
          </div>

          <ErrorTextInput
            key="features_title"
            label={t(`event_field_${fieldName}_title`)}
            onChange={(e) => item.onChange(e.target.value, 'title')}
            value={item.title ?? ''}
            limit={14}
            required
            onBlur={() => vm.onSave(fieldName)}
          />

          <ErrorTextInput
            key="features_description"
            label={t(`event_field_${fieldName}_description`)}
            onChange={(e) => item.onChange(e.target.value, 'description')}
            value={item.description ?? ''}
            limit={100}
            required
            multiline
            onBlur={() => vm.onSave(fieldName)}
          />
        </div>

        {index !== 0 && (
          <DeleteItemButton
            onClick={() => {
              vm.event.removeItemByIndex(EVENT_FIELD.Features, index);
              vm.onSave(fieldName);
            }}
            text={`${t('create_event_features_title')} 0${index + 1}`}
            generalModalViewModel={vm.generalModalViewModel}
          />
        )}
      </div>
    );
  };

  renderContent = () => {
    const { vm } = this.props;
    const event = vm.event ?? {};

    const features = event[EVENT_FIELD.Features];

    if (!features) return null;

    return (
      <section className={styles.stepSection}>
        <div className={clsx(styles.stepMainContent, styles.marginBottom20)}>
          <div>
            {features?.map((item, i) => this.renderFeature(item, i))}

            {features?.length < 5 && (
              <div>
                <Button
                  ghost
                  onClick={() => event.addEmptyItemInArray(EVENT_FIELD.Features)}
                  className={styles.addItemButton}
                >
                  <div className={styles.flexRowCenter}>
                    <Icon
                      size={28}
                      svg
                    >
                      {iconAdd}
                    </Icon>
                    {t('button_add_feature')}
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>

        <Tips
          isHidden={vm.showPreview}
          fieldName={EVENT_TIPS.Features}
          setTipHeight={vm.setTipHeight}
        />
      </section>
    );
  };

  render() {
    const { vm } = this.props;

    return !!vm && this.renderContent();
  }
}

Features.propTypes = {
  vm: PropTypes.object
};

Features.defaultProps = {
  vm: {}
};

export default Features;
