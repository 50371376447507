import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Mousewheel } from 'swiper';

import { RESOURCE_TYPES } from 'src/constants';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ResourceCard from 'src/components/ResourceCard';
import EventCarousel from 'src/components/EventCarousel';
import Icon from 'src/components/Icon';

import iconResourceService from 'src/assets/resourceService.svg';
import iconResourceFunds from 'src/assets/resourceMoney.svg';
import iconResourceSpace from 'src/assets/resourceSpace.svg';
import illustration from 'src/assets/illustrationPreservedFlower.svg';

import RecommendedResourcesPageViewModel from './vm';
import styles from './styles.module.scss';

SwiperCore.use([Mousewheel]);

const resourceIcons = {
  service: iconResourceService,
  funds: iconResourceFunds,
  space: iconResourceSpace
};

@observer
class RecommendedResourcesPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = new RecommendedResourcesPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderMyEvents() {
    if (!this.vm.myEvents || this.vm.myEvents?.length === 0) return null;
    return (
      <EventCarousel
        items={this.vm.myEvents}
        initialIndex={this.vm.currentEventIndex}
        onChange={this.vm.onEventSwiperChange}
      />
    );
  }

  renderList(type) {
    if (this.vm.isMobile) {
      return (
        <div className={styles.listContainerFlex}>
          {this.vm.list?.filter((item) => item?.resource?.type === type)?.map((item) => (
            <ResourceCard
              className={styles.card}
              type={item.resource?.type}
              item={item.resource}
              key={item.id}
              onClick={() => this.vm.toResourceDetail(item.resource?.type, item.resource?.id)}
            />
          ))}
        </div>
      );
    }
    return (
      <div className={styles.listContainer}>
        <Swiper
          className={clsx(styles.listSwiper, 'recommendResourceSwiper')}
          onReachEnd={this.vm.onScrollEnd}
          mousewheel={{
            releaseOnEdges: true,
            forceToAxis: true
          }}
          slidesPerView="auto"
          spaceBetween={20}
          direction="horizontal"
        >
          {this.vm.list?.filter((item) => item?.resource?.type === type)?.map((item) => (
            <SwiperSlide key={item.id}>
              <ResourceCard
                className={styles.card}
                type={item.resource?.type}
                item={item.resource}
                key={item.id}
                onClick={() => this.vm.toResourceDetail(item.resource?.type, item.resource?.id)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }

  renderSection(type) {
    return (
      <section
        className={styles.section}
        key={type}
      >
        <div className={styles.resourceTitle}>
          <Icon
            size={this.vm.isMobile ? 22 : 36}
            svg
            className={styles.icon}
          >
            {resourceIcons[type]}
          </Icon>
          <div className={styles.subtitle}>
            {`/ ${t(`resources_${type}`)} /`}
          </div>
        </div>
        {this.renderList(type)}
      </section>
    );
  }

  renderEmptyMessage() {
    return (
      <div className={styles.emptyMessageContainer}>
        {t('no_recommended_resources')}
      </div>
    );
  }

  render() {

    return (
      <div className={clsx(styles.recommendedResourcesPage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          isDarkMode
        >
          <Breadcrumb
            routes={[
              { label: t('manage_applications'), path: '' }
            ]}
            right={null}
            colorType="green"
            withHeader
          />
          <div className={styles.top}>
            {this.renderMyEvents()}
            <div className={clsx(styles.flexRow, styles.center, styles.pageTitle)}>
              <img
                alt="painter"
                src={illustration}
                className={styles.painter}
              />
              <div className={styles.titleGroup}>
                <div>{t('recommended_resources_title_1')}</div>
                <div>{t('recommended_resources_title_2')}</div>
              </div>
            </div>
          </div>
          {this.vm.list?.length === 0
            ? (this.renderEmptyMessage())
            : (
              <div className={styles.mainContainer}>
                {this.vm.hasService && this.renderSection(RESOURCE_TYPES.Service)}
                {this.vm.hasFunds && this.renderSection(RESOURCE_TYPES.Funds)}
                {this.vm.hasSpace && this.renderSection(RESOURCE_TYPES.Space)}
              </div>
            )}
        </MainLayout>
      </div>
    );
  }
}

RecommendedResourcesPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(RecommendedResourcesPage)), true);
