import API from 'src/apis';
import Notification from 'src/models/response/Notification';

export default class NotificationService {
  static async list(params) {
    const { data } = await API.notification.list(params);
    return {
      list: data.list.map((d) => Notification.fromRes(d)),
      anchor: data.anchor
    };
  }

  static async unreadCount() {
    const res = await API.notification.unreadCount();
    return res;
  }

  static async read(action, id) {
    const res = await API.notification.read(action, id);
    return res;
  }
}
