/* eslint-disable */
const SDGs = {
  "zh-TW": {
    "1": {
      "name": "經濟成長",
      "childs": {
        "1-1": "SDG 2 消除飢餓",
        "1-2": "SDG 7 永續能源",
        "1-3": "SDG 8 合適工作",
        "1-4": "SDG 9 基礎建設"
      }
    },
    "2": {
      "name": "環境保護",
      "childs": {
        "2-1": "SDG 6 淨水衛生",
        "2-2": "SDG 12 產消責任",
        "2-3": "SDG 13 氣候行動",
        "2-4": "SDG 14 海洋保育",
        "2-5": "SDG 15 陸域保育"
      }
    },
    "3": {
      "name": "社會進步",
      "childs": {
        "3-1": "SDG 1 消除貧窮",
        "3-2": "SDG 3 健康福祉",
        "3-3": "SDG 4 優質教育",
        "3-4": "SDG 5 性別平權",
        "3-5": "SDG 10 促進平等",
        "3-6": "SDG 11 永續城鄉",
        "3-7": "SDG 16 和平正義",
        "3-8": "SDG 17 夥伴關係"
      }
    }
  },
  "en-US": {
    "1": {
      "name": "經濟成長",
      "childs": {
        "1-1": "SDG 2 消除飢餓",
        "1-2": "SDG 7 永續能源",
        "1-3": "SDG 8 合適工作",
        "1-4": "SDG 9 基礎建設"
      }
    },
    "2": {
      "name": "環境保護",
      "childs": {
        "2-1": "SDG 6 淨水衛生",
        "2-2": "SDG 12 產消責任",
        "2-3": "SDG 13 氣候行動",
        "2-4": "SDG 14 海洋保育",
        "2-5": "SDG 15 陸域保育"
      }
    },
    "3": {
      "name": "社會進步",
      "childs": {
        "3-1": "SDG 1 消除貧窮",
        "3-2": "SDG 3 健康福祉",
        "3-3": "SDG 4 優質教育",
        "3-4": "SDG 5 性別平權",
        "3-5": "SDG 10 促進平等",
        "3-6": "SDG 11 永續城鄉",
        "3-7": "SDG 16 和平正義",
        "3-8": "SDG 17 夥伴關係"
      }
    }
  }
}

export const SDGsOrder = {
  "1": 1,
  "2": 2,
  "3": 3,
  "1-1": 1,
  "1-2": 2,
  "1-3": 3,
  "1-4": 4,
  "2-1": 2,
  "2-2": 6,
  "2-3": 7,
  "2-4": 8,
  "2-5": 9,
  "3-1": 3,
  "3-2": 11,
  "3-3": 12,
  "3-4": 13,
  "3-5": 14,
  "3-6": 15,
  "3-7": 16,
  "3-8": 17
}

export default SDGs
