/* eslint-disable import/no-cycle */
import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import Item from './Item';

class KeyItem {
  @observable introduction;
  @observable items = [];

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get isComplete() {
    return !!this.introduction
     && this.items?.every((item) => item.isComplete);
  }

  @action initItem = () => {
    if (this.items?.length === 0) {
      this.items = [new Item()];
    }
  };

  @action addEmptyItem = () => {
    if (this.items?.length < 5) {
      this.items.push(new Item());
    }
  };

  @action removeEmptyItems = (until = 0) => {
    this.items = this.items.every((item) => item.isEmpty)
      ? this.items.slice(0, until)
      : this.items.filter((item, i) => !item.isEmpty);
  };

  @action removeEmptyItemsUntilOne = () => {
    this.removeEmptyItems(1);
  };

  @action removeItemByIndex = (index) => {
    this.items = this.items.filter((item, i) => i !== index);
  };

  // /////////////////////////////////

  @action deserialize = (data) => {
    const {
      introduction,
      items
    } = data ?? {};

    this.introduction = introduction;
    this.items = items?.map((obj) => Item.fromRes(obj)) ?? [];
  };

  serialize = () => {
    const data = {
      introduction: this.introduction,
      items: this.items?.map((item) => item.serialize()) ?? []
    };

    return data;
  };


  static fromRes(data) {
    return new KeyItem(data);
  }
}

export default KeyItem;
