import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Upload from 'antd/es/upload';
import Tooltip from 'antd/es/tooltip';

import i18n from 'src/i18n';
import Icon from 'src/components/Icon';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import iconUpload from 'src/assets/iconUpload.svg';

import UploadedItem from './UploadedItem';

import styles from './styles.module.scss';

@observer
class UploadPhoto extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;

    this.vm = props.vm;

  }

  renderItems() {
    const { readOnly } = this.props;
    return this.vm?.fileList?.map((item, i) => (
      <UploadedItem
        key={item.id || item.uid || i}
        item={item}
        readOnly={readOnly}
        onRemoveFile={this.vm.onRemoveFile}
      />
    ));
  }

  renderUploadArea() {
    const {
      className,
      required,
      placeholder,
      label,
      acceptPDF,
      onUploadFileInvalid,
      showError,
      tooltipTitle,
      multiple,
      customUpload
    } = this.props;

    if (this.vm.fileList?.length === 0) {
      return (
        <Tooltip title={`${tooltipTitle}${acceptPDF ? `,${t('pdf_upload_guideline')}` : ''}`}>
          <div className={clsx(styles.marginBottom, 'uploadArea', className)}>
            <Upload
              className={styles.uploadArea}
              maxCount={this.vm.maxCount}
              customRequest={async (e) => {
                await this.vm.onUpload(e, { onUploadFileInvalid });
                if (customUpload) {
                  console.log('------upload here', customUpload);
                  customUpload();
                }
              }}
              accept={`image/jpg,image/jpeg,image/png,image/bmp${acceptPDF ? ',application/pdf' : ''}`}
              showUploadList={false}
              beforeUpload={this.vm.type === 'resource' && this.vm.beforeUpload}
              multiple={multiple}
            >
              <div className={clsx(styles.uploadButton, showError && styles.errorBorder)}>
                <div>
                  {required && (
                  <span className="requireMark">*</span>
                  )}
                  {placeholder || t('register_organization_step2_certificateUploadButton')}
                </div>

                <Icon
                  size={24}
                  svg
                >
                  {iconUpload}
                </Icon>

                {showError && <div className={styles.error}>{t('this_is_required')}</div>}
              </div>
            </Upload>
          </div>
        </Tooltip>
      );
    }


    return (
      <div className={clsx(styles.uploadedItemsContainer, this.vm.type !== 'resource' && 'uploadedItemsContainer', className)}>
        <div className={clsx(styles.title, required && styles.requiredMark)}>
          { label || (this.vm.type === 'resource' ? t('register_organization_step2_certificateUploadButton') : t('register_organization_step2_certificate'))}
        </div>
        <div>
          {this.renderItems()}
        </div>
        <Tooltip title={`${tooltipTitle}${acceptPDF ? `,${t('pdf_upload_guideline')}` : ''}`}>
          <Upload
            maxCount={this.vm.maxCount}
            customRequest={async (e) => {
              await this.vm.onUpload(e, { onUploadFileInvalid });
            }}
            accept={`image/jpg,image/jpeg,image/png,image/bmp${acceptPDF ? ',application/pdf' : ''}`}
            showUploadList={false}
            beforeUpload={this.vm.type === 'resource' && this.vm.beforeUpload}
            multiple={multiple}
          >
            {this.vm.fileList?.length < this.vm.maxCount && (
            <div className={clsx(styles.uploadButton, styles.uploadOtherButton)}>
              <div>
                {t('register_organization_step2_certificateUploadOtherButton')}
              </div>

              <Icon
                size={24}
                svg
              >
                {iconUpload}
              </Icon>
            </div>
            )}
          </Upload>
        </Tooltip>
      </div>
    );
  }

  renderReadOnly() {
    const { className } = this.props;

    return (
      <div className={clsx(styles.uploadedItemsContainer, 'uploadedItemsContainer', className)}>
        <div className={styles.title}>
          {t('register_organization_step2_certificate')}
        </div>
        <div>
          {this.renderItems()}
        </div>
      </div>
    );
  }

  render() {
    const { readOnly } = this.props;

    if (readOnly) {
      return this.renderReadOnly();
    }
    return this.renderUploadArea();
  }
}

UploadPhoto.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  vm: PropTypes.object,
  acceptPDF: PropTypes.bool,
  onUploadFileInvalid: PropTypes.func,
  showError: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  multiple: PropTypes.bool,
  customUpload: PropTypes.func
};

UploadPhoto.defaultProps = {
  className: '',
  required: false,
  readOnly: false,
  placeholder: null,
  label: null,
  vm: null,
  acceptPDF: false,
  onUploadFileInvalid: null,
  showError: false,
  tooltipTitle: i18n.t('image_upload_guideline'),
  multiple: false,
  customUpload: null
};

export default withProfile(withRouter(withTranslation()(UploadPhoto)), false);
