import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import withErrorInput from 'src/components/withErrorInput';

import ConstantsStore from 'src/stores/constants';

import styles from '../../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);

@observer
class ValidateEnterPhoneNumber extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  componentDidMount() {
    this.vm.clearTimer();
  }

  render() {
    return (
      <div className={styles.validateContainer}>
        <h2 className={clsx(styles.title, styles.emailToPhoneTitle)}>
          {t('register_validate_personal_step2_title1')}
          <span className={styles.purple}>{` ${t('register_validate_personal_step2_title2')} `}</span>
          {t('register_validate_personal_step2_title3')}
        </h2>

        <h3 className={styles.subtitle}>
          {t('register_validate_personal_step2_subtitle')}
        </h3>

        <div className={styles.phoneArea}>
          <div>
            <Select
              placeholder={t('register_validate_personal_step2_countryCodePlaceholder')}
              className={clsx(styles.marginBottom, styles.countryCode)}
              defaultValue="+886"
              value={this.vm.countryCode}
              showSearch
              onChange={(value) => this.vm.onChange('countryCode', value)}
              options={ConstantsStore.countryCodesOptions}
              required
            />
          </div>

          <ErrorTextInput
            key="phoneNumber"
            label={t('register_validate_personal_step2_phoneInputPlaceholder')}
            sx={{
              flexGrow: 1,
              marginLeft: 1
            }}
            onChange={(e) => this.vm.onChange('phone9Digit', e.target.value)}
            type="number"
            value={this.vm.phone9Digit ?? ''}
            required
            showError={this.vm.showError}
            validValue={this.vm.isPhoneValid}
            errorMessage={this.vm.phone9Digit ? t('format_is_wrong') : t('this_is_required')}
          />
        </div>

        <div style={{ flexGrow: 1 }} />

        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.onSendAuthPhone}
            disabled={!this.vm.isPhoneValid || this.vm.timerCountdown !== null}
            loading={this.vm.isLoading}
            className={styles.nextButton}
          >
            {t('register_validate_personal_step2_nextButton')}
            {this.vm.timerCountdown !== null && (
              <span className={styles.timer}>
                {`(${this.vm.timerCountdown})`}
              </span>
            )}
          </Button>
        </div>

      </div>
    );
  }
}

ValidateEnterPhoneNumber.propTypes = {
  vm: PropTypes.object
};

ValidateEnterPhoneNumber.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(ValidateEnterPhoneNumber)), false);
