import axios from 'axios';

import pretendTo from 'src/stores/pretendTo';


axios.defaults.withCredentials = true;

export async function request(options) {
  try {
    console.log(`[API] request: ${JSON.stringify(options, null, 2)}`);
    const result = await axios(options);
    console.log(`[API] status: ${result?.status}`);

    return result;
  } catch (err) {
    const status = err?.response?.status;
    // const data = err?.response?.data;
    const msg = err?.message ?? err;

    const log = [
      status ? `status: ${status}` : null,
      // data ? `data: ${JSON.stringify(data)}` : null,
      msg ? `msg: ${msg}` : null
    ]
      .filter((el) => !!el)
      .join(', ');

    console.log(`[API] error ${log}`);

    throw err;
  }
}

export const getHost = () => {
  // local
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      return `${r[0]}:5000/api`;
    }
  }

  // dev
  if (/^5-plqrs/.test(window.location.host)) {
    return 'https://5-plqrs.miraclemobile.com.tw/api';
  }

  // production
  return 'https://goaction.5percent-design-action.com/api';
};


export const getHeader = () => {
  return {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    ...pretendTo.id && { 'pretend-to': pretendTo.id }
  };
};
