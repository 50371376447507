import {
  makeObservable, computed, observable, action
} from 'mobx';
import ChildNodeViewModel from '../ChildNode/vm';

class ParentNodeViewModel {
  type = 'parent';
  @observable label;
  @observable value;
  @observable children = [];
  @observable collapsed = false;

  constructor(data) {
    makeObservable(this);
    this.init(data);
  }

  @computed get checked() {
    return this.children.every((el) => el.checked);
  }

  @computed get indeterminate() {
    return this.children.some((el) => el.checked || el.indeterminate) && !this.checked;
  }

  @computed get selectedItems() {
    return this.getCheckedNodes(this.children);
  }

  @action init = (data) => {
    const {
      value,
      label,
      children
    } = data;

    this.label = label;
    this.value = value;
    this.children = children?.map((el) => {
      if (el.children) {
        return new ParentNodeViewModel(el);
      }
      return new ChildNodeViewModel(el, this.label);
    });
  };

  @action didMount = () => {
    if (this.value === '1' && !this.collapsed) {
      this.collapsed = true;
    }
  };

  @action handleClick = (e) => {
    e.stopPropagation();
    this.collapsed = !this.collapsed;
  };

  @action toggleChecked = (bool) => {
    this.children.forEach((el) => {
      el.toggleChecked(bool);
    });
  };

  @action initCheckState = (arr) => {
    this.children.forEach((el) => {
      el.initCheckState(arr);
    });
  };

  getCheckedNodes = (data) => {
    const checkedNodes = [];

    function traverse(node) {
      if (node.checked && !node.children) {
        checkedNodes.push(node);
      }

      if (node.children) {
        node.children.forEach((child) => traverse(child));
      }
    }

    data.forEach((node) => traverse(node));

    return checkedNodes;
  };

}

export default ParentNodeViewModel;


