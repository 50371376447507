import { makeObservable, observable, action, runInAction } from 'mobx';
import i18n from 'src/i18n';

import UserService from 'src/services/user';
import IS from 'src/utils/is';

import message from 'antd/es/message';

class ForgetPasswordModalVM {

  @observable isForgetPasswordModalOpen = true;

  @observable email = '';
  @observable emailError = null;
  @observable isMobile;

  @observable timerID = null;
  @observable timerCountdown = null;

  @observable isLoading = false;

  constructor(props) {
    makeObservable(this);
    this.props = props;
    runInAction(() => {
      this.isForgetPasswordModalOpen = true;
      this.email = props.email ?? '';
      this.isMobile = props.context.state.isMobile;
    });
  }

  @action toggleForgetPasswordModal = () => {
    this.isForgetPasswordModalOpen = !this.isForgetPasswordModalOpen;
  };

  @action onSendForgetPasswordEmail = async () => {
    this.checkEmail();
    if (this.email.length !== 0 && this.emailError === null) {
      this.isLoading = true;
      const res = await UserService.sendResetPasswordEmail(this.email);
      this.isLoading = false;
      if (res.status === 201) {
        message.success(i18n.t('send_reset_password_email_success'));
        this.toggleForgetPasswordModal();
        this.toResetPassword();
      } else if (res.status === 429) {
        message.error(i18n.t('too_many_requests_in_a_short_time'));
        const millis = res.data.data.limitedAt - Date.now();
        this.createTimer(Math.floor(millis / 1000));
      } else {
        message.error(i18n.t('account_does_not_exist'));
      }
      if (this.props.onModifyEmail) {
        this.props.onModifyEmail(this.email);
      }
    }
  };

  @action checkEmail = () => {
    if (this.email.replace(/\s+/g, '').length === 0) {
      this.emailError = i18n.t('login_enter_email');
    } else if (!IS.email(this.email)) {
      this.emailError = i18n.t('login_email_error');
    } else {
      this.emailError = null;
    }
  };

  @action onEmailChange = (e) => {
    this.email = e.target.value;
  };

  toResetPassword = () => {
    const { navigate } = this.props.router;
    navigate('/reset-password', { state: { email: this.email } });
  };

  @action createTimer = (initial) => {
    this.timerCountdown = initial;
    const timer = setInterval(() => {
      if (this.timerCountdown - 1 < 0) {
        runInAction(() => {
          this.timerCountdown = null;
        });
        this.clearTimer();
      } else {
        runInAction(() => {
          this.timerCountdown -= 1;
        });
      }
    }, 1000);
    this.timerID = timer;
  };

  @action clearTimer = () => {
    clearInterval(this.timerID);
  };
}

export default ForgetPasswordModalVM;
