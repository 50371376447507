import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

import i18n from 'src/i18n';
import { RESOURCE_TYPES } from 'src/constants';
import blueTick from 'src/assets/blueTick.png';
import moreIcon from 'src/assets/more.png';
import fakeAvatar from 'src/assets/fakeAvatar.png';
import store from 'src/stores/constants';

import styles from './styles.module.scss';
import ImageCarousel from '../ImageCarousel';

export default function ResourceCard(props) {

  const { item, type, onClick, isEdited, onEditPressed, onTogglePressed } = props;

  const renderService = () => {
    return (
      <div className={styles.resourceCard}>
        <div className={styles.topArea}>
          <div
            style={{ backgroundImage: `url(${item.user?.avatar ?? fakeAvatar})` }}
            className={styles.avatar}
          />
          {item.user?.blueTickAt && (
            <img
              className={clsx(styles.bluetick)}
              src={blueTick}
              alt="bluetick"
              width={24}
              height={24}
            />
          )}
          <div className={styles.tag}>
            {item.meta?.convertedType?.tag ?? ''}
          </div>
        </div>
        <div className={styles.middleArea}>
          <div className={styles.title}>
            {item.name}
          </div>
          <div className={styles.subtitle}>
            {item.meta?.convertedType?.name ?? ''}
          </div>
          <div className={styles.desc}>
            {item.expectance}
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.info}>
            <div className={styles.title}>
              {i18n.t('myresource_detail_service_type')}
            </div>
            <div className={styles.content}>
              {item.meta?.convertedJobs.map((j, i) => (
                <span key={j}>
                  {j}
                  {i < item.meta.convertedJobs.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.title}>
              {i18n.t('myresource_detail_regions')}
            </div>
            <div className={styles.content}>
              {item.meta?.convertedRegions?.map((j, i) => (
                <span key={j}>
                  {store.getRegionLabelByValue(j)}
                  {i < item.meta.convertedRegions.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFunds = () => {
    return (
      <div className={styles.resourceCard}>
        <div className={styles.topArea}>
          <div
            style={{ backgroundImage: `url(${item.user?.avatar ?? fakeAvatar})` }}
            className={styles.avatar}
          />
          {item.user?.blueTickAt && (
            <img
              className={clsx(styles.bluetick)}
              src={blueTick}
              alt="bluetick"
              width={24}
              height={24}
            />
          )}
          <div className={styles.tag}>
            {`$ ${item.meta?.displayQuota}`}
          </div>
        </div>
        <div className={styles.middleArea}>
          <div className={styles.title}>
            {item.name}
          </div>
          <div className={styles.subtitle}>
            {item.meta?.convertedType}
          </div>
          <div className={styles.desc}>
            {item.expectance}
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.info}>
            <div className={styles.title}>
              {i18n.t('myresource_detail_unit')}
            </div>
            <div className={styles.content}>
              {item.user.displayName}
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.title}>
              {i18n.t('myresource_detail_regions')}
            </div>
            <div className={styles.content}>
              {item.meta?.convertedRegions?.map((j, i) => (
                <span key={j}>
                  {store.getRegionLabelByValue(j)}
                  {i < item.meta.convertedRegions.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSpace = () => {
    return (
      <div className={styles.resourceCard}>
        <div className={styles.topArea}>
          <div className={styles.title}>
            {item.name}
          </div>
          <div className={styles.tag}>
            {`${item.meta?.capacity} 人`}
          </div>
        </div>
        <div className={styles.middleSpaceArea}>
          <div className={styles.desc}>
            {item.expectance}
          </div>

          <div className={styles.photos}>
            <ImageCarousel photos={item.meta?.photos} />
            {/* <CardCarousel
            spaceBetween={0}
            items={item.meta?.photos?.map((target) => (
              <img src={target.url} alt="img" width="100%" className={styles.imgCarousel} />
            ))}
          /> */}
          </div>
        </div>
        <div className={styles.spaceInfo}>
          <div className={styles.info}>
            <div className={styles.title}>{i18n.t('myresource_detail_location')}</div>
            <div className={styles.content}>{item.meta?.convertedLocation}</div>
          </div>
          <div className={styles.separator} />
          <div className={styles.info}>
            <div className={styles.title}>{i18n.t('myresource_detail_size')}</div>
            <div className={styles.content}>
              {`${Number(item.meta?.size).toFixed()}坪`}
            </div>
          </div>

        </div>
      </div>
    );
  };

  const renderCard = () => {
    switch (type) {
      case RESOURCE_TYPES.Service:
        return renderService();
      case RESOURCE_TYPES.Funds:
        return renderFunds();
      case RESOURCE_TYPES.Space:
      default:
        return renderSpace();
    }
  };

  const items = [
    {
      key: 'edit',
      label: i18n.t('myresource_card_edit')
    },
    {
      key: 'toggle',
      label: item.isActive ? i18n.t('myresource_card_cancel') : i18n.t('myresource_card_release')
    }
  ];

  const onItemClick = (ev) => {
    ev.domEvent.stopPropagation();

    if (ev.key === 'edit') {
      onEditPressed();
    } else {
      onTogglePressed();
    }
  };

  return (
    <div
      className={clsx(
        styles.card,
        props.className,
        type === RESOURCE_TYPES.Space && styles.spaceCard,
        (isEdited && type !== RESOURCE_TYPES.Space) && styles.isEdited,
        (isEdited && type === RESOURCE_TYPES.Space) && styles.isEditedSpace
      )}
      onClick={onClick}
      key={item.id}
      style={props.style}
    >
      {renderCard()}
      {
        isEdited && (
          <div className={styles.editedBlock}>
            <hr className="dashedGray" style={{ margin: '15px 0' }} />
            <div className={styles.block}>
              <div className={styles.content}>
                {item.isActive ? i18n.t('myresource_card_active') : i18n.t('myresource_card_inactive')}
              </div>
              <Dropdown
                trigger="click"
                overlayClassName="moreDropdown"
                menu={{
                  items,
                  onClick: onItemClick
                }}
              >
                <div onClick={(e) => e.stopPropagation()} className="action-button-container">
                  <img className={styles.btn} src={moreIcon} alt="" />
                </div>
              </Dropdown>

            </div>
          </div>
        )
      }
    </div>
  );
}


ResourceCard.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string,
  onClick: PropTypes.func,
  isEdited: PropTypes.bool,
  onEditPressed: PropTypes.func,
  onTogglePressed: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object
};

ResourceCard.defaultProps = {
  item: {},
  type: 'service',
  onClick: () => {},
  isEdited: false,
  onEditPressed: () => {},
  onTogglePressed: () => {},
  className: '',
  style: {}
};
