import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import placeholder from 'src/assets/Resource/funds.svg';

import 'swiper/css';
import 'swiper/css/pagination';

function ImageCarousel(props) {
  const { photos } = props;
  return (
    <Swiper
      slidesPerView={1}
      pagination
      modules={[Pagination]}
      style={{
        width: '100%',
        height: '100%',
        '--swiper-pagination-color': '#52E90B',
        '--swiper-pagination-bullet-inactive-color': '#ffffff',
        '--swiper-pagination-bullet-inactive-opacity': '1'
      }}
    >
      {photos.map((el) => (
        <SwiperSlide key={el.name}>
          <img
            src={el.url}
            alt="img"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', aspectRatio: 2.1 }}
          />
        </SwiperSlide>
      ))}

      {
        photos.length === 0
        && (
        <SwiperSlide key="placeholder">
          <img
            src={placeholder}
            alt="img"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', aspectRatio: 2.1 }}
          />
        </SwiperSlide>
        )

      }
    </Swiper>
  );
}

ImageCarousel.propTypes = {
  photos: PropTypes.array
};

ImageCarousel.defaultProps = {
  photos: []
};

export default ImageCarousel;
