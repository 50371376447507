import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Bubble from 'src/components/Bubble';

import ConstantsStore from 'src/stores/constants';
import { computed } from 'mobx';
import './styles.scss';

@observer
class SDGs extends React.Component {
  constructor(props) {
    super(props);
  }

  @computed
  get isUsedAtHP() {
    const { sdgsDataKey } = this.props;
    if (sdgsDataKey === 'sdgsForHP') return true;
    return false;
  }

  render() {
    const { containerClassName, sdgsDataKey, handleToggleSdg, sdgs } = this.props;
    return (
      <div className={clsx('sdgBubbleOptionsContainer', containerClassName)}>
        {ConstantsStore[sdgsDataKey || 'sdgsForHP'].map((type) => type.children.map((sdg) => {
          const regex = /(?<sdgNumber>SDG\s?\d{1,2})\s?(?<label>.+)/;
          const { groups: { sdgNumber, label } } = regex.exec(sdg?.label);

          return (
            <Bubble
              key={sdg.value}
              value={sdg.value}
              label={(
                <>
                  <div className="subtitle">
                    {sdgNumber}
                  </div>
                  <div>
                    {label ?? sdg?.label}
                  </div>
                </>
              )}
              className={`${this.isUsedAtHP ? `sdgHP${sdg.value} sdg${type.value}HP` : `sdg${type.value} sdg${sdg.value}`} ${!sdgs.includes(sdg.value) ? 'sdgInactive' : 'sdgActive'}`}
              isVisible
              onClick={() => { handleToggleSdg(sdg.value, !sdgs.includes(sdg.value)); }}
            />
          );
        }))}
      </div>
    );
  }
}

SDGs.propTypes = {
  containerClassName: PropTypes.string,
  sdgsDataKey: PropTypes.string,
  handleToggleSdg: PropTypes.func,
  sdgs: PropTypes.array
};

SDGs.defaultProps = {
  containerClassName: null,
  sdgsDataKey: null,
  handleToggleSdg: () => {},
  sdgs: []
};


export default (withTranslation()(SDGs));
