import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import PropTypes from 'prop-types';
import Button from 'src/components/Button';
import Select from 'src/components/Select';
import ErrorService from 'src/services/errors';
import BaseModal from 'src/components/Modals/BaseModal';
import handshake from 'src/assets/handshake.png';
import arrowIcon from 'src/assets/arrow.png';
import UserService from 'src/services/user';

import styles from './styles.module.scss';

export default function InviteModal(props) {
  const { t } = i18n;
  const { onToggle, isOpen, isMobile, userId, onConfirm, isPretend, resourceId } = props;

  const [value, setValue] = useState(null);
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const options = list?.map((ev) => ({ value: ev.id, label: ev.name, disabled: ev.refs?.isInvited }));

  useEffect(
    () => {
      const getMyEvents = async () => {
        await getList(true);
      };
      getMyEvents();
    },
    [userId]
  );

  const getList = useCallback(async (shouldReset = false) => {
    if (!userId || isLoading) return;

    if (shouldReset) {
      setList([]);
    }

    const body = { refs: { resourceId } };
    const params = {
      limit: 10,
      anchor
    };
    setLoading(true);
    try {
      const res = await UserService.createdEvents(userId, params, body);
      const newList = list.concat(res.list);
      setList(newList);
      setAnchor(res.anchor);

    } catch (error) {
      ErrorService.onDefaultError(error);
    } finally {
      setLoading(false);
    }
  }, [anchor, isLoading, list, userId, resourceId]);

  const handleScroll = async (e) => {
    const target = e.target;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      console.log('...on scroll end');
      if (anchor) {
        setLoading(true);
        getList();
      }
      setLoading(false);
    }
  };

  return (
    <BaseModal
      onClose={onToggle}
      open={isOpen}
      isMobile={isMobile}
    >
      <div className={styles.container}>
        <img
          src={handshake}
          alt="handshake"
          className={clsx(styles.illustration)}
        />
        <div className={styles.title}>
          {isPretend ? t('resource_detail_suggest_title') : t('resource_detail_invite_title')}
        </div>
        <div className={styles.desc}>
          {t('resource_detail_invite_info1')}
        </div>
        <div className={styles.desc}>
          {t('resource_detail_invite_info2')}
        </div>

        <div className={styles.selectBox}>
          <Select
            className={styles.select}
            key="event"
            options={options}
            onChange={(ev) => {
              console.log('ev', ev);
              setValue(ev);
            }}
            onPopupScroll={handleScroll}
          />
          <div className={styles.selectInfo}>
            {t('resource_detail_invite_select')}
          </div>
        </div>

        <Button
          disabled={!value}
          onClick={() => {
            const selected = list.find((el) => el.id === value);
            onConfirm(selected);
          }}
          className={styles.button}
        >
          {isPretend ? t('resource_detail_suggest_btn') : t('resource_detail_invite_send')}
          <img src={arrowIcon} alt="" width={15} height={20} />
        </Button>
      </div>
    </BaseModal>
  );
}

InviteModal.propTypes = {
  isMobile: PropTypes.bool,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  userId: PropTypes.string,
  onConfirm: PropTypes.func,
  isPretend: PropTypes.bool,
  resourceId: PropTypes.string
};

InviteModal.defaultProps = {
  isMobile: false,
  onToggle: () => {},
  isOpen: false,
  userId: null,
  onConfirm: () => {},
  isPretend: false,
  resourceId: null
};
