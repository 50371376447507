import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import scrollDetector from 'src/stores/scrollDetector';
import styles from './styles.module.scss';

function StickyGroupContainer({ children, isSticky, containerHeight, topOffset }) {
  return (
    <div
      className={clsx(styles.stepMainContent, isSticky && styles.withSticky)}
      style={isSticky && containerHeight ? { minHeight: containerHeight + 65 } : {}}
    >
      <div
        className={isSticky && styles.sticky}
        style={isSticky ? { top: scrollDetector.isScrollingDown ? topOffset + 100 : topOffset + 150 } : {}}
      >
        {children}
      </div>
    </div>
  );
}

StickyGroupContainer.propTypes = {
  children: PropTypes.node,
  isSticky: PropTypes.bool,
  containerHeight: PropTypes.number,
  topOffset: PropTypes.number
};

StickyGroupContainer.defaultProps = {
  children: null,
  isSticky: false,
  containerHeight: null,
  topOffset: 80
};

export default observer(StickyGroupContainer);
