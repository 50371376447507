import React from 'react';
import PropTypes from 'prop-types';
import ScrollHighlightNavbar from './scrollBar';

export default function ScrollArea(props) {
  const { sections, anchorHeader } = props;

  return (
    <>
      <ScrollHighlightNavbar anchorHeader={anchorHeader} />
      {sections}
    </>
  );
}

ScrollArea.propTypes = {
  sections: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  anchorHeader: PropTypes.array
};

ScrollArea.defaultProps = {
  sections: null,
  anchorHeader: []
};
