import {
  makeObservable, computed, observable, action
} from 'mobx';
import ErrorService from 'src/services/errors';
import { t } from 'i18next';

class CreateEventIntroPageViewModel {

  constructor(props) {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    console.log('CreateEventIntro.didMount, params', props.router.params);
    window.scrollTo(0, 0);
    console.log(props);
    this.props = props;
    this.profile = props.profile;

    if (!this.profile.isEventCreator) {
      ErrorService.onCustomError(
        t('error_not_event_creator'),
        null,
        () => this.props.router.navigate('/')
      );
    }
  };

  toCreateEvent = () => {
    const { navigate } = this.props.router;
    navigate('/create-event');

    window.gtag('event', 'Create_Event_Start');
  };

}

export default CreateEventIntroPageViewModel;
