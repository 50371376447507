import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

function CardCarousel(props) {
  const {
    items,
    className,
    slideClassName,
    spaceBetween,
    breakpoints,
    paginationAlignWithImage,
    slidesPerView,
    autoplay,
    swiperRef,
    ...ps
  } = props;

  const modules = [Pagination];
  if (autoplay) {
    modules.push(Autoplay);
  }

  return (
    <Swiper
      className={`${paginationAlignWithImage ? 'customSwiper2' : 'customSwiper'} ${className}`}
      slidesPerView={slidesPerView || 'auto'}
      pagination={{
        clickable: true
      }}
      spaceBetween={spaceBetween ?? 20}
      modules={modules}
      breakpoints={breakpoints}
      direction="horizontal"
      autoplay={autoplay}
      ref={swiperRef}
      {...ps}
    >
      {items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={i} className={slideClassName}>
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

CardCarousel.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  spaceBetween: PropTypes.number,
  breakpoints: PropTypes.object,
  paginationAlignWithImage: PropTypes.bool,
  slideClassName: PropTypes.string,
  slidesPerView: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  autoplay: PropTypes.object,
  swiperRef: PropTypes.object
};

CardCarousel.defaultProps = {
  items: [],
  className: '',
  spaceBetween: 20,
  breakpoints: {},
  paginationAlignWithImage: false,
  slideClassName: '',
  slidesPerView: 'auto',
  autoplay: null,
  swiperRef: null
};

export default CardCarousel;
