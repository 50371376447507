import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer, Observer } from 'mobx-react';
import { t } from 'i18next';
import IS from 'src/utils/is';
import { USER_TYPES } from 'src/constants';
import TextInput from 'src/components/TextInput';
import Button from 'src/components/Button';
import TreeSelect from 'src/components/TreeSelect';
import BaseModal from 'src/components/Modals/BaseModal';
import withErrorInput from 'src/components/withErrorInput';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from 'src/components/Icon';
import Belong from 'src/pages/User/ProfileEditPage/components/Belong';

import iconReset from 'src/assets/iconReset.svg';

import { TABS } from '../vm';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorTreeSelect = withErrorInput(TreeSelect);
@observer
class Base extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.isMobile = props.isMobile;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderInterests() {
    if (!this.vm.regionsSelectViewModel || !this.vm.sdgsSelectViewModel) {
      return null;
    }

    return (
      <>
        <h3 className={clsx(styles.profileSubtitle)}>{`/ ${t('profile_interests')} /`}</h3>

        <Observer>
          {() => (
            <div>
              <ErrorTreeSelect
                key="region"
                showError={this.vm.showError && this.vm.regionsSelectViewModel.isEmpty}
                placeholder="地區"
                type="regions"
                vm={this.vm.regionsSelectViewModel}
                value={this.vm.regionsSelectViewModel.selectedItems}
                required
              />

              <ErrorTreeSelect
                key="sdg"
                showError={this.vm.showError && this.vm.sdgsSelectViewModel.isEmpty}
                placeholder="SDGs"
                type="sdgs"
                vm={this.vm.sdgsSelectViewModel}
                value={this.vm.sdgsSelectViewModel.selectedItems}
                required
              />
            </div>
          )}
        </Observer>
      </>
    );
  }

  renderResetPasswordModal() {
    return (
      <BaseModal
        onClose={this.vm.toggleResetPasswordModal}
        open={this.vm.isResetPasswordModalOpen}
        isMobile={this.props.isMobile}
        height={420}
      >
        <div className={styles.resetPwd}>
          <h3 className={styles.resetTitle}>
            {t('profile_resetPassword_title')}
          </h3>

          <ErrorTextInput
            key="current-password"
            label={t('profile_resetPassword_current')}
            onChange={(e) => this.vm.onChange('passwordCurrent', e.target.value)}
            type="password"
            showError={this.vm.showPasswordError}
            value={this.vm.passwordCurrent}
            // validValue={IS.password(this.vm.passwordCurrent)}
            validValue={this.vm.passwordCurrent?.length >= 8}
            errorMessage={`＊ ${t('resetPassword_current_password_error')}`}
          />

          <ErrorTextInput
            key="new-password"
            label={t('profile_resetPassword_new')}
            onChange={(e) => this.vm.onChange('passwordNew', e.target.value)}
            type="password"
            showError={this.vm.showPasswordError}
            value={this.vm.passwordNew}
            validValue={IS.password(this.vm.passwordNew)}
            errorMessage={`＊ ${t('register_passwordWrongFormat')}`}
          />

          <ErrorTextInput
            key="confirm-password"
            label={t('profile_resetPassword_reconfirm')}
            onChange={(e) => this.vm.onChange('passwordConfirm', e.target.value)}
            type="password"
            showError={this.vm.showPasswordError}
            value={this.vm.passwordConfirm}
            validValue={this.vm.isPasswordConsistent}
            errorMessage={`＊ ${t('register_passwordInconsistent')}`}
          />

          <Button
            style={{
              width: '100%', maxWidth: 180
            }}
            withRightArrow
            onClick={this.vm.handleResetPassword}
            disabled={!this.vm.passwordCurrent || !this.vm.passwordNew || !this.vm.passwordConfirm}
          >
            {t('profile_resetPassword_save')}
          </Button>

        </div>
      </BaseModal>
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderCommonField(fieldName, identity) {
    const profile = this.vm.profile ?? {};
    const info = this.vm.info ?? {};

    switch (fieldName) {
      case 'displayName':
        return (
          <ErrorTextInput
            showError={this.vm.showError}
            key="displayName"
            label={identity === USER_TYPES.Personal ? t('profile_personal_displayName') : t('register_organization_step1_displayName_label')}
            onChange={(e) => this.vm.onChange('displayName', e.target.value)}
            value={profile.displayName}
            required
          />
        );

      case 'email':
        return (
          <ErrorTextInput
            showError={this.vm.showError}
            key="email"
            label={t('register_organization_step1_email_label')}
            value={profile?.email}
            readOnly
            required
          />
        );

      case 'password':
        return (
          <TextInput
            key="password"
            label={t('register_organization_step1_password_label')}
            defaultValue="********"
            className={styles.marginBottom}
            inputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    size={24}
                    svg
                    onClick={this.vm.toggleResetPasswordModal}
                  >
                    {iconReset}
                  </Icon>
                </InputAdornment>
              )
            }}
            required
          />
        );

      case 'motto':
        return (
          <ErrorTextInput
            showError={this.vm.showError}
            key="motto"
            label={t('register_motto_placeholder')}
            onChange={(e) => this.vm.onChange('motto', e.target.value)}
            value={info.motto}
            multiline
            required
            validValue={info.isMottoValid}
            errorMessage={info.motto ? t('register_motto_errorMessage') : t('this_is_required')}
          />
        );

      default:
    }

    return null;
  }

  render() {
    if (!this.vm.profile) {
      return null;
    }

    switch (this.vm.currentTab) {
      case TABS.Basics:
        return this.renderBasic();

      case TABS.Social:
        return this.renderSocial();

      case TABS.Private:
        return (
          <>
            {this.renderResetPasswordModal()}
            {this.renderPrivate()}
          </>
        );

      case TABS.Belong:
        return (
          <Belong
            identity={this.vm.identity}
            userId={this.vm.id}
            generalModalViewModel={this.vm.generalModalViewModel}
            isMobile={this.isMobile}
          />
        );
      default:
    }
    return null;
  }
}

export default Base;

Base.propTypes = {
  vm: PropTypes.object,
  isMobile: PropTypes.bool
};

Base.defaultProps = {
  vm: {},
  isMobile: false
};
