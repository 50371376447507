import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { EVENTCARD_TYPE, EVENT_STATUS } from 'src/constants';
import Dropdown from 'antd/es/dropdown';
import blueTick from 'src/assets/blueTick.png';
import blackGradient from 'src/assets/black-gradient.png';
import service from 'src/assets/resourceService.svg';
import serviceFill from 'src/assets/resourceServiceFill.svg';
import funds from 'src/assets/resourceMoney.svg';
import fundsFill from 'src/assets/resourceMoneyFill.svg';
import space from 'src/assets/resourceSpace.svg';
import spaceFill from 'src/assets/resourceSpaceFill.svg';
import iconMoreButton from 'src/assets/moreButton.svg';
import iconMoreButtonVertical from 'src/assets/moreButtonVertical.svg';

import styles from './styles.module.scss';
import EventCardViewModel from '../vm';

export const DRAFT_STATUS_STRING = {
  [EVENT_STATUS.Draft]: t('draft_status_draft'),
  [EVENT_STATUS.Rejected]: t('draft_status_rejected'),
  [EVENT_STATUS.Submitted]: t('draft_status_submitted'),
  [EVENT_STATUS.Failed]: t('draft_status_failed')
};

@observer
class EventCardForManagement extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.isDesktop = window.innerWidth >= 1024;
    this.vm = new EventCardViewModel(props);
  }

  renderBanner = () => {
    const { item } = this.props;
    return item.banner
      ? <img src={item.banner} alt="alt text" className={styles.img} />
      : <div className={styles.img} />;
  };

  renderTitle = () => {
    const { item } = this.props;
    return (
      <div className={styles.title}>
        {item.name || t('no_title')}
      </div>
    );
  };

  renderCities = () => {
    return this.vm.citiesStr?.length !== 0
      ? (
        <span className={clsx(styles.info, styles.clamp1)}>
          {this.vm.citiesStr}
        </span>
      )
      : <span className={styles.info}>{t('no_cities')}</span>;
  };

  renderServiceTypesTags() {
    if (!this.vm.hasServiceDemand) {
      return null;
    }

    return (
      <div className={clsx(styles.infoList, styles.clamp1)}>
        {this.vm.serviceTypesTags.map((tag, i) => (
          <span key={tag}>
            <span className={styles.info}>
              {tag}
            </span>
            {i < this.vm.serviceTypesTags.length - 1 && (
            <span className={clsx(styles.info, styles.x)}>
              x
            </span>
            )}
          </span>
        ))}
      </div>
    );
  }

  renderRecruitTime = () => {
    const { item } = this.props;
    const color = this.isDesktop ? 'white' : 'black';

    if (item.status === EVENT_STATUS.Active || item.status === EVENT_STATUS.Inactive) {
      return (
        <div className={clsx(styles.marginRight3, styles[color])}>
          <span className={styles.marginRight3}>{t('recruiting_until')}</span>
          {item.endAt ? dayjs(item.endAt).format('YYYY.MM.DD') : '-'}
        </div>
      );
    }
    return (
      <div className={clsx(styles.marginRight3, styles[color])}>
        <span className={clsx(styles.marginRight3, item.status === EVENT_STATUS.Rejected ? '' : styles.primary)}>
          {DRAFT_STATUS_STRING[item.status]}
        </span>
      </div>
    );
  };

  renderApplicationStatus() {
    const { acceptedAt, createdAt } = this.props;
    return acceptedAt ? (
      <div className={styles.marginRight3}>
        <span className={styles.marginRight3}>
          {t('event_joined_accepted_at')}
        </span>
        {dayjs(acceptedAt).format('YYYY.MM.DD')}
      </div>
    ) : (
      <div className={styles.marginRight3}>
        <span className={styles.marginRight3}>
          {t('event_joined_applied_at')}
        </span>
        {dayjs(createdAt).format('YYYY.MM.DD')}
      </div>
    );
  }

  renderLeftCol() {
    const { colorMode, item } = this.props;
    const fontColor = colorMode === 'dark' ? '#fff' : '';

    return (
      <div className={styles.flexCol}>
        <div
          className={styles.time}
          style={{ color: fontColor }}
        >
          <div className={styles.status}>
            {this.vm.getRecruitStatusString(item)}
          </div>
        </div>

        <div className={styles.flexCol2}>
          {this.vm.hasSpaceDemand && (
            <div className={styles.flexCol2__item}>
              <img
                src={colorMode === 'dark' ? space : spaceFill}
                alt="resource space"
                className={styles.image6}
              />
            </div>
          )}
          {this.vm.hasFundsDemand && (
            <div className={styles.flexCol2__item}>
              <img
                src={colorMode === 'dark' ? funds : fundsFill}
                alt="resource money"
                className={styles.image6}
              />
            </div>
          )}
          {this.vm.hasServiceDemand && (
            <div className={styles.flexCol2__item}>
              <img
                src={colorMode === 'dark' ? service : serviceFill}
                alt="resource service"
                className={styles.image6}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderFooter() {
    const { item, dropdownMenu, type } = this.props;

    return (
      <div
        className={styles.footer}
      >
        <div className={styles.flexRow}>
          <div className={styles.marginRight3}>
            {item.privacy === 'listed' ? t('privacy_listed') : t('privacy_unlisted')}
          </div>
          <div className={styles.marginRight3}>|</div>
          <div className={clsx(styles.marginRight3, styles.black)}>
            {type === EVENTCARD_TYPE.Joined ? this.renderApplicationStatus() : this.renderRecruitTime()}
          </div>
        </div>

        {!this.isDesktop && (
          <div>
            <Dropdown
              overlayClassName="moreDropdown"
              menu={dropdownMenu}
            >
              <img
                src={iconMoreButton}
                alt="button"
              />
            </Dropdown>
          </div>
        )}
      </div>
    );
  }

  renderMobile() {
    const { item, onClick, className, style } = this.props;

    return (
      <div
        className={clsx(styles.eventCard, className, onClick && styles.clickable)}
        style={style}
      >
        <div
          className={styles.flexRow}
          onClick={onClick}
        >

          {this.renderLeftCol()}

          <div className={styles.flexRow__spacer} />

          <div className={styles.box3}>
            <div className={styles.content_box}>
              {this.renderBanner()}
              <div style={{ '--src': `url(${blackGradient})` }} className={styles.mask} />

              <div className={styles.flexCol3}>
                {this.renderTitle()}

                <div className={clsx(styles.infoList, styles.clamp2)}>
                  {this.renderCities()}
                </div>

                {this.renderServiceTypesTags()}
              </div>

              {item.blueTickAt && (
                <img
                  src={blueTick}
                  alt="blue tick"
                  className={styles.check}
                />
              )}
            </div>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderDesktop() {
    const { item, onClick, dropdownMenu, type } = this.props;

    return (
      <div
        className={clsx(styles.eventCard, this.props.className, styles.desktop, onClick && styles.clickable)}
        style={this.props.style}
      >
        <div className={styles.outerContainer}>
          <div
            className={styles.flexRow}
            onClick={onClick}
          >

            {this.renderLeftCol()}

            <div className={styles.flexRow__spacer} />

            <div className={styles.box3}>
              <div className={styles.content_box}>
                {this.renderBanner()}
                <div style={{ '--src': `url(${blackGradient})` }} className={styles.mask} />

                {item.blueTickAt && (
                  <img
                    src={blueTick}
                    alt="blue tick"
                    className={styles.check}
                  />
                )}

                {this.renderFooter()}
              </div>
            </div>

            <div className={styles.flexCol3}>
              {this.renderTitle()}

              {this.renderServiceTypesTags()}

              <div className={clsx(styles.infoList)}>
                {this.renderCities()}
              </div>
            </div>

            {/* phase 3 */}
            {/* {type === EVENTCARD_TYPE.Created && (
              <div className={styles.demandsRegistration}>
                {this.vm.hasSpaceDemand && (
                  <div className={styles.flexCol2__item}>
                    <img
                      src={spaceFill}
                      alt="resource space"
                      className={styles.image6}
                    />
                  </div>
                )}
                {this.vm.hasFundsDemand && (
                  <div className={styles.flexCol2__item}>
                    <img
                      src={fundsFill}
                      alt="resource space"
                      className={styles.image6}
                    />
                  </div>
                )}
                {this.vm.hasServiceDemand && (
                  <div className={styles.flexCol2__item}>
                    <img
                      src={serviceFill}
                      alt="resource space"
                      className={styles.image6}
                    />
                  </div>
                )}
              </div>
            )} */}
          </div>

          <div className={styles.dropdown}>
            <Dropdown
              overlayClassName="moreDropdown"
              menu={dropdownMenu}
              onClick={(e) => e.stopPropagation()}
            >
              <img
                className={styles.moreBtn}
                src={iconMoreButtonVertical}
                alt="button"
              />
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.isDesktop ? this.renderDesktop() : this.renderMobile();
  }
}

export default EventCardForManagement;

EventCardForManagement.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  colorMode: PropTypes.string,
  dropdownMenu: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  acceptedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  createdAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

EventCardForManagement.defaultProps = {
  item: {},
  onClick: null,
  colorMode: null,
  dropdownMenu: { items: [] },
  className: '',
  style: {},
  type: EVENTCARD_TYPE.Created,
  acceptedAt: '',
  createdAt: ''
};
