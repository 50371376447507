import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CREATE_EVENT_STEPS } from 'src/constants';
import Dropdown from 'antd/es/dropdown';
import Icon from 'src/components/Icon';
import iconArrow from 'src/assets/iconArrowUpPurple.svg';
import MenuItem from '../MenuItem';
import styles from './styles.module.scss';

function MenuSelect({ step, onSwitchStep, isDone, isEventCreated, isVisionDone }) {
  const [isOpen, setIsOpen] = useState(false);
  const steps = Object.values(CREATE_EVENT_STEPS).filter((label) => label !== step);
  return (
    <div className={styles.menuSelectContainer} id="dropdownWrapper">
      <Dropdown
        overlayClassName=""
        trigger="click"
        menu={{
          items: steps.map((s) => ({
            key: s,
            label: (
              <MenuItem
                label={s}
                onClick={() => onSwitchStep(s)}
                isDone={s === CREATE_EVENT_STEPS.Vision ? isVisionDone : isDone[s]}
                isDisabled={!isEventCreated && s !== CREATE_EVENT_STEPS.Basic}
                className={styles.itemInSelect}
              />
            ),
            disabled: !isEventCreated && s !== CREATE_EVENT_STEPS.Basic
          }))
        }}
        onOpenChange={(value) => setIsOpen(value)}
        getPopupContainer={() => document.getElementById('dropdownWrapper')}
      >
        <div className={clsx(styles.flexRow, styles.selectItem)}>
          <MenuItem
            label={step}
            isActive
            className={styles.currentStep}
            isDone={step === CREATE_EVENT_STEPS.Vision ? isVisionDone : isDone[step]}
          />
          <Icon
            size={16}
            svg
            style={{
              marginTop: 5,
              marginLeft: -4,
              flex: '1 0'
            }}
            className={!isOpen ? styles.rotate : ''}
          >
            {iconArrow}
          </Icon>
        </div>
      </Dropdown>
    </div>
  );
}

MenuSelect.propTypes = {
  step: PropTypes.string,
  onSwitchStep: PropTypes.func,
  isDone: PropTypes.object,
  isEventCreated: PropTypes.bool,
  isVisionDone: PropTypes.bool
};

MenuSelect.defaultProps = {
  step: CREATE_EVENT_STEPS.Basic,
  onSwitchStep: () => {},
  isDone: {},
  isEventCreated: false,
  isVisionDone: false
};

export default MenuSelect;
