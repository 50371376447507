import Applicant from './Applicant';
import Resource from './Resource';

export default class Application {
  constructor(params) {
    const {
      acceptedAt,
      cognition,
      eventId,
      fields,
      createdAt,
      id,
      motivation,
      profile,
      readedAt,
      resource,
      updatedAt,
      user
    } = params ?? {};

    this.acceptedAt = acceptedAt;
    this.cognition = cognition;
    this.eventId = eventId ?? {};
    this.fields = fields;
    this.createdAt = createdAt;
    this.id = id;
    this.motivation = motivation;
    this.readedAt = readedAt;
    this.resource = resource;
    this.updatedAt = updatedAt;
    this.user = user;
    this.profile = {
      meta: Applicant.fromRes(profile.meta),
      type: profile.type
    };

    this.resource = Resource.fromRes(resource);
  }

  static fromRes(data) {
    return new Application(data);
  }
}
