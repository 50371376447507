import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import clsx from 'clsx';
import Button from 'src/components/Button';
import Avatar from 'src/components/Avatar';
import quotationMark from 'src/assets/quotationMark.png';
import styles from './styles.module.scss';

function BottomBar(props) {
  const {
    sloganForDisplay,
    avatar,
    submitButtonProps,
    nextStepButtonProps
  } = props;

  const renderLeftArea = () => sloganForDisplay
    ? (
      <div className={styles.leftArea}>
        <Avatar url={avatar} size={60} className={styles.avatar} />
        <div className={styles.sloganContainer}>
          <img
            className={styles.quotationMark}
            src={quotationMark}
            alt="quotation mark"
          />
          <div>
            {sloganForDisplay}
          </div>
        </div>
      </div>
    ) : (
      <div />
    );

  const submitButton = () => {
    const {
      disabled,
      text,
      onClick
    } = submitButtonProps;

    return (
      <Button
        type={disabled ? 'primary' : 'default'}
        onClick={onClick}
        className={clsx(
          styles.button,
          disabled ? styles.disabled : styles.submitButton
        )}
        disabled={disabled}
      >
        {text}
      </Button>
    );
  };

  const nextStepButton = () => {
    const {
      disabled,
      onClick,
      isHidden
    } = nextStepButtonProps;

    if (isHidden) {
      return null;
    }

    return (
      <Button
        onClick={onClick}
        className={clsx(
          styles.button,
          disabled && styles.disabled
        )}
        withRightArrow
        disabled={disabled}
      >
        {t('next_step')}
      </Button>
    );
  };

  return (
    <div
      className={styles.barContainer}
    >
      <div className={styles.content}>
        {renderLeftArea()}

        <div className={styles.buttons}>
          {submitButton()}

          {nextStepButton()}
        </div>
      </div>
    </div>
  );
}

BottomBar.propTypes = {
  avatar: PropTypes.string,
  sloganForDisplay: PropTypes.string,
  submitButtonProps: PropTypes.object,
  nextStepButtonProps: PropTypes.object
};

BottomBar.defaultProps = {
  avatar: null,
  sloganForDisplay: null,
  submitButtonProps: {},
  nextStepButtonProps: {}
};

export default BottomBar;
