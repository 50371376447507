import { request, getHost, getHeader } from './utils';

export const list = (type) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/product/list`,
    headers: getHeader(),
    params: {
      type
    }
  };
  return request(options);
};
