import pretendTo from 'src/stores/pretendTo';
import { request, getHost, getHeader } from './utils';


export const profile = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/user/me/profile`,
    headers: getHeader()
  };
  return request(options);
};

export const detail = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/user/me/detail`,
    headers: getHeader()
  };
  return request(options);
};

export const detailById = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/user/${id}/detail`,
    headers: getHeader()
  };
  return request(options);
};

export const login = (email, password) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/login`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    data: {
      email,
      password
    }
  };
  return request(options);
};

export const logout = () => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/logout`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  return request(options);
};

export const exists = (email) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/exists`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    data: {
      email
    }
  };
  return request(options);
};

export const signUp = (type, email, password) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/signup`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    data: {
      type,
      email,
      password
    }
  };
  return request(options);
};

export const sendAuthEmail = (email) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/send-auth-email`,
    headers: getHeader(),
    data: {
      email
    }
  };
  return request(options);
};

export const sendAuthPhone = (phone) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/send-auth-phone`,
    headers: getHeader(),
    data: {
      phone
    }
  };
  return request(options);
};

export const authPhone = (code) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/auth-phone`,
    headers: getHeader(),
    data: {
      code
    }
  };
  return request(options);
};

export const updateProfile = (data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/v1/user/profile`,
    headers: getHeader(),
    data
  };
  return request(options);
};

// 組織送審
export const submit = () => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/submit`,
    headers: getHeader()
  };
  return request(options);
};

export const sendResetPasswordEmail = (email) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/forget-password`,
    headers: getHeader(),
    data: {
      email
    }
  };
  return request(options);
};

export const resetPassword = (token, password) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/reset-password`,
    headers: getHeader(),
    data: {
      token,
      password
    }
  };
  return request(options);
};

export const changePassword = (previous, next) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/change-password`,
    headers: getHeader(),
    data: {
      previous,
      next
    }
  };
  return request(options);
};

export const genFilesPresignedUrls = (userId, files) => {
  const uid = pretendTo.id ?? userId;

  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/${uid}/files`,
    headers: getHeader(),
    data: {
      files
    }
  };
  return request(options);
};

export const resourceList = (userId, type, params) => {
  const uid = pretendTo.id ?? userId;

  const options = {
    method: 'get',
    url: `${getHost()}/v1/user/${uid}/resource/${type}/list`,
    headers: getHeader(),
    params
  };
  return request(options);
};

export const createdEvents = (userId, params, data) => {
  const uid = pretendTo.id ?? userId;

  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/${uid}/event/created`,
    headers: getHeader(),
    params,
    data
  };
  return request(options);
};

export const joinedEvents = (userId, params, data) => {
  const uid = pretendTo.id ?? userId;

  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/${uid}/event/joined`,
    headers: getHeader(),
    params,
    data
  };
  return request(options);
};

export const quota = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/user/quota`,
    headers: getHeader()
  };
  return request(options);
};

export const orderHistory = (params, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/user/order/history`,
    headers: getHeader(),
    params,
    data
  };
  return request(options);
};

export const suggest = ({ type, keyword }) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/user/suggest`,
    headers: getHeader(),
    params: {
      type,
      keyword
    }
  };
  return request(options);
};

export const members = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/user/${id}/members`,
    headers: getHeader()
  };
  return request(options);
};
