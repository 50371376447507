import {
  makeObservable, computed, observable, action, runInAction, reaction, toJS
} from 'mobx';
import diff from 'deep-diff';
import { message } from 'antd';
import { t } from 'i18next';
import { RESOURCE_TYPES } from 'src/constants';
import ConstantsStore from 'src/stores/constants';
import ErrorService from 'src/services/errors';
import DemandService from 'src/services/demand';
import Service from 'src/models/response/Demand/Service';
import Funds from 'src/models/response/Demand/Funds';
import Space from 'src/models/response/Demand/Space';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';
import IS from 'src/utils/is';

class DemandVM {
  @observable type;
  @observable eventId;
  @observable demand;
  @observable id;

  @observable treeSelectVM = {};

  @observable resetIframe = () => {};
  @observable setUpdatedAt = () => {};

  @observable disposers = {};

  constructor(props) {
    makeObservable(this);
    const {
      type,
      eventId,
      id,
      demand
    } = props;

    runInAction(() => {
      this.type = type;
      this.eventId = eventId;
      this.id = id;

      if (demand) {
        this.demand = demand;
        this.demand.initTemplateToEdit();
      } else {
        this.demand = this.newDataModel();
        this.demand.initTemplateToEdit();
      }

    });
    this.initTreeSelectVMs();
  }

  @action didMount = (props) => {
    this.resetIframe = props.resetIframe;
    this.setUpdatedAt = props.setUpdatedAt;
  };

  newDataModel = () => {
    switch (this.type) {
      case RESOURCE_TYPES.Service:
        return new Service();
      case RESOURCE_TYPES.Funds:
        return new Funds();
      case RESOURCE_TYPES.Space:
        return new Space();
      default:
        return {};
    }
  };

  @action initTreeSelectVMs = () => {
    this._initTreeSelectVM('awards', ConstantsStore.awardsOptions);
    this._initTreeSelectVM('regions', ConstantsStore.regionsOptions);

    switch (this.type) {
      case RESOURCE_TYPES.Service:
        this._initTreeSelectVM('jobs', ConstantsStore.jobsOptions);
        break;
      case RESOURCE_TYPES.Funds:
        this._initTreeSelectVM('types', ConstantsStore.fundsTypesOptions);
        break;
      case RESOURCE_TYPES.Space:
        this._initTreeSelectVM('usages', ConstantsStore.usagesOptions);
        this._initTreeSelectVM('equipments', ConstantsStore.equipmentsOptions);
        break;
      default:
    }
  };

  @action _initTreeSelectVM = (key, options) => {
    this.treeSelectVM[key] = new TreeSelectViewModel(options);
    this.disposers[key] = reaction(
      () => this.treeSelectVM[key].selectedItems,
      () => {
        this.onTreeSelectChange(this.treeSelectVM[key].selectedItems, key);
      }
    );
    this.treeSelectVM[key].initCheckState(toJS(this.demand[key]) ?? []);
  };

  dispose = () => {
    Object.values(this.disposers)?.forEach((disposer) => {
      if (disposer) {
        disposer();
      }
    });
  };

  onChange = (value, key) => {
    if ((key === 'count' || key === 'capacity') && (!!value && !IS.int(value))) {
      return;
    }
    if ((key === 'quota' || key === 'size') && (!!value && !IS.numeric(value))) {
      return;
    }

    this.demand.onChange(value, key);
  };

  onChangeStringArray = (value, key, index) => {
    this.demand.onChangeStringArray(value, key, index);
  };

  addEmptyItemInArray = (key) => {
    this.demand.addEmptyItemInArray(key);
  };

  removeItemInArray = (key, index) => {
    this.demand.removeItemInArray(key, index);
  };

  @action resetEventId = (id) => {
    this.eventId = id;
  };

  onCreate = async (demandData) => {
    const data = {
      eventId: this.eventId,
      type: this.type,
      demand: demandData
    };
    console.log('..on create demand', data);
    try {
      const res = await DemandService.create(data);
      console.log('created', res);
      runInAction(() => {
        this.id = res.id;
      });
    } catch (error) {
      throw error?.response?.status;
    }
  };

  onUpdate = async (data) => {
    console.log('..on update', this.id, data);
    try {
      const res = await DemandService.update(this.id, data);
    } catch (error) {
      throw error?.response?.status;
    }
  };

  onSave = async () => {
    console.log('..on save');
    if (!this.isComplete) {
      return;
    }
    const data = this.demand.serialize();
    console.log(data);

    try {
      if (this.id) {
        await this.onUpdate(data);
        message.success(t('save_success'));
      } else {
        await this.onCreate(data);
        message.success(t('create_success'));
      }

      if (this.resetIframe) {
        this.resetIframe();
      }

      if (this.setUpdatedAt) {
        this.setUpdatedAt();
      }

    } catch (errorCode) {
      switch (errorCode) {
        case 400:
          ErrorService.onCustomError(t('create_event_error_400'));
          break;
        default:
          ErrorService.onDefaultError(errorCode);
      }
    }
  };

  onTreeSelectChange = (selectedItems, key) => {
    const values = selectedItems.map((item) => item.idValue);
    const isChanged = diff(values, this.demand[key]);
    if (isChanged) {
      this.onChange(values, key);
      console.log(this.demand[key]);
      this.onSave();
    }
  };

  onDelete = async () => {
    if (!this.id) {
      return;
    }

    try {
      await DemandService.delete(this.id);
      message.success(t('save_success'));
      if (this.resetIframe) {
        this.resetIframe();
      }
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  @action filterEmptyStringInArrs = () => {
    ['keyItemDescriptions', 'dates', 'awardDescriptions', 'rules'].forEach((key) => {
      const items = this.demand[key];
      this.demand[key] = items.every((str) => !str)
        ? items.slice(0, 1)
        : items.filter((str) => !!str);
    });
  };

  // /////////////////////////////

  @computed get isDone() {
    const event = this.event ?? {};

    console.log('isdone', event.features, event.features?.every((f) => f.isComplete));

    return false;
  }

  @computed get isComplete() {
    return this.demand.isComplete;
  }

  @computed get isEmpty() {
    return this.demand.isEmpty;
  }

  @computed get demandAmount() {
    if (!this.demand) {
      return 0;
    }

    switch (this.type) {
      case RESOURCE_TYPES.Service:
        return this.demand.count;
      case RESOURCE_TYPES.Funds:
        return this.demand.quota;
      case RESOURCE_TYPES.Space:
        return this.demand.capacity;
      default:
        return 0;
    }
  }

  // /////////////////////////

  static fromRes = (type, eventId, data) => {
    return new DemandVM(
      {
        type,
        eventId,
        id: data?.id,
        demand: data?.meta
      }
    );
  };
}

export default DemandVM;
