import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { t } from 'i18next';
import ConstantsStore from 'src/stores/constants';
import ImageCarousel from 'src/components/ImageCarousel';
import Avatar from 'src/components/Avatar';
import Dropdown from 'antd/es/dropdown';
import purpleCheck from 'src/assets/purpleCheck.svg';
import moreButton from 'src/assets/moreButton.svg';
import { RESOURCE_TYPES } from 'src/constants';
import ConfirmAcceptModal from '../Modals/ConfirmAcceptModal';
import styles from './styles.module.scss';

export default function ApplicationCard(props) {
  const { item, type, onClick, onUpdateRegister, onToApplicationPage } = props;
  const { resource } = item;

  const [isModalOpen, setModalOpen] = useState(false);

  const avatar = (
    <Avatar
      size={50}
      url={item.user?.avatar}
      showBlueTick={!!item.user?.blueTickAt}
      blueTickSize={24}
    />
  );

  const renderService = () => {
    return (
      <>
        <div className={styles.topArea}>
          {avatar}
          <div className={styles.tagContainer}>
            <div className={styles.tag}>
              {ConstantsStore.getServiceTypeTag(resource.meta.type)}
            </div>
            {!item.readedAt && (
              <div className={styles.greenDot} />
            )}
          </div>
        </div>

        <div className={styles.title}>
          {resource.name}
        </div>

        <div className={styles.subtitle}>
          {ConstantsStore.serviceTypesOptions?.find((o) => o.children.find((c) => c.value === resource.meta.type))?.children.find((o) => o.value === resource.meta.type)?.label}
        </div>

        <div className={clsx(styles.desc, styles.clamp2)}>
          {resource.expectance}
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.info}>
            <div className={styles.title}>
              {t('demand_profession')}
            </div>
            <div className={clsx(styles.content, styles.clamp)}>
              {resource.meta?.jobs.map((j, i) => (
                <span key={j}>
                  {ConstantsStore.jobsOptions.find((el) => el.value === j).label}
                  {i < resource.meta.jobs.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.title}>
              {t('myresource_detail_regions')}
            </div>
            <div className={clsx(styles.content, styles.clamp)}>
              {resource.meta?.regions.map((r, i) => (
                <span key={r}>
                  {ConstantsStore.getRegionLabelByValue(r)}
                  {i < resource.meta.regions.length - 1 && (
                    <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </div>

        </div>
      </>
    );
  };

  const renderFunds = () => {
    return (
      <>
        <div className={styles.topArea}>
          {avatar}
          <div className={styles.tagContainer}>
            <div className={styles.tag}>
              {`$ ${resource.meta?.displayQuota}`}
            </div>
            {!item.readedAt && (
              <div className={styles.greenDot} />
            )}
          </div>
        </div>

        <div className={styles.title}>
          {resource.name}
        </div>

        <div className={styles.subtitle}>
          {resource.meta?.type}
        </div>

        <div className={clsx(styles.desc, styles.clamp2)}>
          {resource.description}
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.info}>
            <div className={styles.title}>
              {t('myresource_detail_unit')}
            </div>
            <div className={clsx(styles.content, styles.clamp)}>
              {item.user?.displayName}
            </div>
          </div>

          <div className={styles.info}>
            <div className={styles.title}>
              {t('myresource_detail_regions')}
            </div>
            <div className={clsx(styles.content, styles.clamp)}>
              {resource.meta?.regions.map((r, i) => (
                <span key={r}>
                  {ConstantsStore.getRegionLabelByValue(r)}
                  {i < resource.meta.regions.length - 1 && (
                  <span className={styles.dot}>•</span>
                  )}
                </span>
              ))}
            </div>
          </div>

        </div>
      </>
    );
  };

  const renderSpace = () => {
    return (
      <>
        <div className={clsx(styles.topArea, styles.space)}>
          <div className={clsx(styles.title, styles.space)}>
            {resource.name}
          </div>

          <div className={styles.tagContainer}>
            <div className={styles.tag}>
              {`${resource.meta?.capacity} ${t('people')}`}
            </div>
            {!item.readedAt && (
              <div className={styles.greenDot} />
            )}
          </div>
        </div>

        <div className={clsx(styles.desc, styles.clamp2)}>
          {resource.description}
        </div>

        <div className={styles.photos}>
          <ImageCarousel photos={resource.meta?.photos} />
        </div>

        <div className={styles.spaceInfo}>

          <div className={styles.info}>
            <div className={styles.title}>
              {t('myresource_detail_location')}
            </div>
            <div className={clsx(styles.content, styles.clamp)}>
              {resource.meta?.address}
            </div>
          </div>

          <div className={styles.separator} />

          <div className={styles.info}>
            <div className={styles.title}>
              {t('myresource_detail_capacity')}
            </div>
            <div className={clsx(styles.content, styles.clamp)}>
              {`${Number(resource.meta?.size).toFixed()}${t('demand_space_size_unit')}`}
            </div>
          </div>

        </div>
      </>
    );
  };

  const renderCard = () => {
    switch (type) {
      case RESOURCE_TYPES.Service:
        return renderService();
      case RESOURCE_TYPES.Funds:
        return renderFunds();
      case RESOURCE_TYPES.Space:
        return renderSpace();
      default:
    }
    return null;
  };

  return (
    <div
      className={clsx(styles.card, styles.resourceCard, props.className)}
      onClick={onClick}
      key={item.id}
      style={props.style}
    >
      <div className={styles.mainContent}>
        {renderCard()}
      </div>
      <hr className="dashedGray" style={{ margin: '15px 0' }} />
      <div className={styles.footer}>
        <div className={styles.flexRow}>
          <span className={styles.date}>
            <span className={styles.marginRight}>
              {dayjs(item.createdAt).format('YYYY.MM.DD')}
            </span>
            {t('application_card_signed_at')}
          </span>
          <span className={styles.separator}>|</span>
          <span className={clsx(styles.status, item.acceptedAt ? styles.purple : '')}>
            {item.acceptedAt
              ? (
                <div className={styles.flexRow}>
                  <img
                    alt="check"
                    src={purpleCheck}
                    style={{ marginRight: 3 }}
                  />
                  <span>{t('application_accepted')}</span>
                </div>
              )
              : t('application_to_reply')}
          </span>
        </div>
        <Dropdown
          overlayClassName="moreDropdown"
          onClick={(e) => e.stopPropagation()}
          menu={{
            items: [
              {
                key: 'viewDetail',
                label: (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onToApplicationPage(item.id);
                    }}
                  >
                    {t('application_card_dropdown_view_detail')}
                  </div>
                )
              },
              item.acceptedAt
                ? {
                  key: 'cancel',
                  label: (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        // onUpdateRegister(item.id, { isAccepted: false });
                        setModalOpen(!isModalOpen);
                      }}
                    >
                      {t('application_card_dropdown_cancel')}
                    </div>
                  )
                }
                : {
                  key: 'confirm',
                  label: (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        // onUpdateRegister(item.id, { isAccepted: true });
                        setModalOpen(!isModalOpen);
                      }}
                    >
                      {t('application_card_dropdown_confirm')}
                    </div>
                  )
                }
            ]
          }}
        >
          <div
            className={styles.buttonContainer}
          >
            <img
              alt="more"
              src={moreButton}
            />
          </div>
        </Dropdown>
      </div>

      <ConfirmAcceptModal
        onToggle={(e) => {
          e.stopPropagation();
          setModalOpen(!isModalOpen);
        }}
        open={isModalOpen}
        isMobile={props.isMobile}
        name={item?.profile?.meta?.name}
        isToAccept={!item?.acceptedAt}
        onButtonClick={(e) => {
          e.stopPropagation();
          onUpdateRegister(item.id, { isAccepted: !item?.acceptedAt });
          setModalOpen(!isModalOpen);
        }}
      />
    </div>
  );
}

ApplicationCard.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onUpdateRegister: PropTypes.func,
  onToApplicationPage: PropTypes.func,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object
};

ApplicationCard.defaultProps = {
  item: null,
  type: null,
  onClick: () => {},
  onUpdateRegister: () => {},
  onToApplicationPage: () => {},
  isMobile: false,
  className: null,
  style: null
};
