/* eslint-disable */
const KeyItems = {
  "zh-TW": {
    "1": "社群經營",
    "2": "倡議宣導",
    "3": "產品開發創新",
    "4": "服務開發創新",
    "5": "人員發展訓練",
    "6": "職工福利",
    "7": "陪伴培力",
    "8": "研究紀錄",
    "9": "生活文化傳承",
    "10": "工作坊",
    "11": "講座、對談",
    "12": "展覽",
    "13": "市集",
    "14": "志工活動",
    "15": "創新提案",
    "16": "發表會",
    "17": "導覽、走讀",
    "18": "課程設計",
    "19": "諮詢輔導",
    "20": "顧問服務",
    "21": "工業設計",
    "22": "人機介面設計",
    "23": "包裝設計",
    "24": "服裝設計",
    "25": "企業識別設計",
    "26": "多媒體設計",
    "27": "視覺傳達設計",
    "28": "食物設計",
    "29": "生態保育",
    "30": "產業發展",
    "31": "經營建設",
    "32": "其他"
  },
  "en-US": {
    "1": "社群經營",
    "2": "倡議宣導",
    "3": "產品開發創新",
    "4": "服務開發創新",
    "5": "人員發展訓練",
    "6": "職工福利",
    "7": "陪伴培力",
    "8": "研究紀錄",
    "9": "生活文化傳承",
    "10": "工作坊",
    "11": "講座、對談",
    "12": "展覽",
    "13": "市集",
    "14": "志工活動",
    "15": "創新提案",
    "16": "發表會",
    "17": "導覽、走讀",
    "18": "課程設計",
    "19": "諮詢輔導",
    "20": "顧問服務",
    "21": "工業設計",
    "22": "人機介面設計",
    "23": "包裝設計",
    "24": "服裝設計",
    "25": "企業識別設計",
    "26": "多媒體設計",
    "27": "視覺傳達設計",
    "28": "食物設計",
    "29": "生態保育",
    "30": "產業發展",
    "31": "經營建設",
    "32": "其他"
  }
}

export const KeyItemsOrder = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "13": 13,
  "14": 14,
  "15": 15,
  "16": 16,
  "17": 17,
  "18": 18,
  "19": 19,
  "20": 20,
  "21": 21,
  "22": 22,
  "23": 23,
  "24": 24,
  "25": 25,
  "26": 26,
  "27": 27,
  "28": 28,
  "29": 29,
  "30": 30,
  "31": 31,
  "32": 999
}

export default KeyItems
