import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import ScrollToTopButton from '../ScrollToTopButton';

function MainLayout(props) {
  const {
    children,
    headerColorMode,
    footerStyle,
    footerWrapperClassName,
    hideHeader,
    hideScrollToTopButton,
    hideFooter,
    isDarkMode,
    isMobileShowFooter,
    ...ps
  } = props;

  return (
    <>
      {!hideHeader
      && <Header {...ps} colorMode={headerColorMode} />}

      {children}

      {(!props.context?.state?.isMobile || isMobileShowFooter)
      && !hideFooter
      && <Footer style={footerStyle} isDarkMode={isDarkMode} footerWrapperClassName={footerWrapperClassName} />}

      {!hideScrollToTopButton
      && <ScrollToTopButton />}
    </>
  );
}

MainLayout.propTypes = {
  context: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  headerColorMode: PropTypes.number,
  footerStyle: PropTypes.object,
  hideHeader: PropTypes.bool,
  hideScrollToTopButton: PropTypes.bool,
  hideFooter: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isMobileShowFooter: PropTypes.bool,
  footerWrapperClassName: PropTypes.string
};

MainLayout.defaultProps = {
  context: {},
  children: null,
  headerColorMode: 1,
  footerStyle: {},
  hideHeader: false,
  hideScrollToTopButton: false,
  hideFooter: false,
  isDarkMode: false,
  isMobileShowFooter: false,
  footerWrapperClassName: ''
};

export default MainLayout;
