import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { handleLineBreaks } from 'src/utils';
import withRouter from 'src/components/withRouter';
import MainLayout from 'src/components/MainLayout';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import iconDownArrow from 'src/assets/iconDownArrow.svg';
import iconSquareDot from 'src/assets/iconSquareDot.svg';
import iconSmile from 'src/assets/iconSmile.svg';
import earthmanLifting from 'src/assets/earthManLifting.svg';
import TopicsPageViewModel from './vm';
import styles from './styles.module.scss';
import EventCarousel from './components/EventCarousel';

@observer
class TopicsPage extends React.Component {
  eventSwiperRef = React.createRef();
  descRef = React.createRef();

  constructor() {
    super();
    this.vm = new TopicsPageViewModel(this.props);
  }

  async componentDidMount() {
    await this.vm.didMount(this.props);
    this.vm.checkIsTextClamped(this.descRef, 'Desc');
  }

  async componentDidUpdate(prevProps) {
    await this.vm.didUpdate(prevProps, this.props);
    this.vm.checkIsTextClamped(this.descRef, 'Desc'); // call here prevent more missing
  }

  renderTopSection() {
    const topic = this.vm.topic;
    const images = this.vm.topic?.images;

    return (
      <section className={clsx(styles.topBar)}>
        <div className={styles.contentWrapper}>
          <div className={styles.illusWrapper}>
            <img
              src={earthmanLifting}
              alt="illustration"
              className={styles.illus}
            />
            {images.themeImg && (
              <img
                src={images.themeImg.url}
                alt="slogan"
                className={styles.illusSlogan}
              />
            )}
          </div>
          <div className={styles.rightContent}>
            {!!topic.sponsorLogos.length && (
              <div className={styles.sponsorLogosContainer}>
                {topic.sponsorLogos.map((logo) => (
                  (
                    <img
                      key={logo.url}
                      src={logo.url}
                      alt="sponsor"
                      className={styles.sponsorLogo}
                    />
                  )
                ))}
              </div>
            )}
            <div className={styles.texts}>
              <div className={styles.titles}>
                <h3 className={styles.title}>
                  {handleLineBreaks(topic.title)}
                </h3>

              </div>
              <p className={styles.desc}>
                {handleLineBreaks(topic.description)}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderIntroSection() {
    const topic = this.vm.topic;
    const texts = this.vm.topic.texts;
    const images = this.vm.topic.images;

    return (
      <section className={clsx(styles.intro)}>
        <div className={clsx(styles.contentWrapper, styles.overflowHidden)}>
          <div className={styles.leftCol}>
            <div className={styles.stats}>
              <div className={styles.statBlock}>
                <div className={styles.titleLine}>
                  {texts.iconText1 && (
                    <img
                      className={styles.introIcon}
                      src={images.featureIcon1?.url || iconSquareDot}
                      alt="icon"
                    />
                  )}
                  <h4 className={styles.title}>
                    {texts.iconText1}
                  </h4>
                </div>

                <p className={styles.subtitle}>
                  {texts.featureTitle1}
                </p>
              </div>

              {(texts.iconText2) && (
                <>
                  <span className={styles.separator} />

                  <div className={styles.statBlock}>
                    <div className={styles.titleLine}>
                      <img
                        className={styles.introIcon}
                        src={images.featureIcon2?.url || iconSmile}
                        alt="icon"
                      />
                      <h4 className={styles.title}>
                        {texts.iconText2}
                      </h4>
                    </div>

                    <p className={styles.subtitle}>
                      {texts.featureTitle2}
                    </p>
                  </div>
                </>
              )}


            </div>

            <p className={styles.desc}>
              {handleLineBreaks(texts.featureDescription)}
            </p>

            {texts.highlight && (
              <div
                className={styles.highlightBlock}
                onClick={() => {
                  this.eventSwiperRef?.current?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                <div>
                  {handleLineBreaks(texts.highlight)}
                </div>

                <div className={styles.bottom}>
                  <div>
                    {t('topics_hightlight_desc')}
                  </div>
                  <img
                    src={iconDownArrow}
                    alt="arrow icon"
                  />
                </div>
              </div>
            )}
          </div>

          {(images.mainImg?.url || topic.thumbnail?.url) && (
            <div className={styles.illusContainer}>
              <img
                src={images.mainImg?.url || topic.thumbnail?.url}
                alt="main"
              />
            </div>
          )}
        </div>
      </section>
    );
  }

  renderSpecialThanksSection() {
    const specialThanksImgs = this.vm.topic?.specialThanksImgs ?? [];
    if (!specialThanksImgs.length) {
      return null;
    }

    return (
      <section className={styles.specialThanks}>
        <h3 className={styles.sectionTitle}>
          {this.vm.topic?.texts?.specialThanksTitle || t('topics_special_thanks_title')}
        </h3>

        <div className={clsx(styles.logos, this.vm.topic.specialThanksHasAnyDesc && styles.columns)}>
          {specialThanksImgs.map((item) => (
            <div className={clsx(styles.specialThanksItem)}>
              <img
                src={item.url}
                key={item.url}
                alt="logo"
                className={styles.logo}
              />
              {item.description && (
                <p className={styles.desc}>
                  <span>{handleLineBreaks(item.description)}</span>
                  {item.link && (
                    <a
                      className={styles.link}
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      KnowMore
                    </a>
                  )}
                </p>
              )}

              {(!item.description && item.link) && (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.hiddenLink}
                >
                  link
                </a>
              )}
            </div>
          ))}
        </div>
      </section>
    );
  }

  renderPartnersSection() {
    const partnersImgs = this.vm.topic?.partnersImgs ?? [];
    if (!partnersImgs.length) return null;

    return (
      <section className={styles.logosSection}>
        <h3 className={styles.sectionTitle}>
          {t('topics_partners_title')}
        </h3>

        <div className={styles.logos}>
          {partnersImgs.map((item) => (
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className={styles.logoLink}
            >
              <img
                src={item.url}
                alt="partner logo"
                key={item.name}
              />
            </a>
          ))}
        </div>
      </section>
    );
  }

  renderInitiatorsSection() {
    const initiatorsImgs = this.vm.topic?.initiatorsImgs ?? [];
    if (!initiatorsImgs.length) return null;

    return (
      <section className={styles.logosSection}>
        <h3 className={styles.sectionTitle}>
          {t('topics_hosts_title')}
        </h3>

        <div className={styles.logos}>
          {initiatorsImgs.map((item) => (
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className={styles.logoLink}
            >
              <img
                src={item.url}
                alt="initiator logo"
                key={item.name}
              />
            </a>
          ))}
        </div>
      </section>
    );
  }

  renderPasswordView() {
    return (
      <div className={styles.passwordView}>
        <div className={styles.passwordContainer}>
          <h3 className={styles.title}>
            {t('topic_password_title')}
          </h3>

          <p className={styles.desc}>
            {handleLineBreaks(t('topic_password_description'))}
            <br />
            {this.vm?.title}
          </p>

          <div>
            <TextInput
              label={this.vm.password ? null : t('topic_password')}
              error={!!this.vm.passwordError}
              onChange={this.vm.onPasswordChange}
              type="password"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.vm.getList();
                }
              }}
            />
            <div className={styles.errorMessage}>
              {this.vm.passwordError ? this.vm.passwordError : ''}
            </div>
          </div>

          <Button
            onClick={this.vm.getList}
            style={{ width: '100%', maxWidth: 335, alignSelf: 'center' }}
            loading={this.vm.isFetching}
          >
            {t('topic_password_enter')}
          </Button>
        </div>
      </div>
    );
  }

  renderMainContent() {
    if (this.vm.needPassword) {
      return this.renderPasswordView();
    }

    return this.vm.topic
      ? (
        <>
          {this.renderTopSection()}

          {this.renderIntroSection()}

          <EventCarousel
            vm={this.vm}
            eventSwiperRef={this.eventSwiperRef}
          />

          {this.renderSpecialThanksSection()}

          {this.renderPartnersSection()}

          {this.renderInitiatorsSection()}
        </>
      )
      : null;
  }

  render() {
    return (
      <div className={clsx(styles.topicsPage)}>
        <Helmet>
          <title>{ this.vm.title ? `${this.vm.title ?? ''} | go action` : 'go action'}</title>
        </Helmet>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          isMobileShowFooter
          footerWrapperClassName={styles.footerWrapper}
          hideFooter={!this.vm.topic || this.vm.needPassword}
          hideScrollToTopButton={this.vm.needPassword}
        >
          {this.renderMainContent()}
        </MainLayout>
      </div>
    );
  }
}

export default withRouter(withTranslation()(TopicsPage));
