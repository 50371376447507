/* eslint-disable import/no-cycle */
import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import RelatedUser from './RelatedUser';

class RelatedUsers {
  @observable coInitiators = [];
  @observable collaborators = [];
  @observable coOrganiser = [];

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get coInitiatorsNames() {
    return this.coInitiators?.map((item) => item.nameForDisplay);
  }

  @computed get coInitiatorsValues() {
    return this.coInitiators?.map((item) => item.value);
  }

  @computed get collaboratorsNames() {
    return this.collaborators?.map((item) => item.nameForDisplay);
  }

  @computed get collaboratorsValues() {
    return this.collaborators?.map((item) => item.value);
  }

  @computed get coOrganiserNames() {
    return this.coOrganiser?.map((item) => item.nameForDisplay);
  }

  @computed get coOrganiserValues() {
    return this.coOrganiser?.map((item) => item.value);
  }

  // /////////////////////////////////

  @action deserialize = (data) => {
    const {
      coInitiators,
      collaborators,
      coOrganiser
    } = data ?? {};

    this.coInitiators = coInitiators?.map((u) => RelatedUser.fromRes(u)) ?? [];
    this.collaborators = collaborators?.map((u) => RelatedUser.fromRes(u)) ?? [];
    this.coOrganiser = coOrganiser?.map((u) => RelatedUser.fromRes(u)) ?? [];
  };


  static fromRes(data) {
    return new RelatedUsers(data);
  }
}

export default RelatedUsers;
