/* eslint-disable */
const ServiceTypes = {
  "zh-TW": {
    "1": {
      "name": "設計及藝術類",
      "childs": {
        "1-1": {
          "name": "建築、工程設計類",
          "tag": "設計行銷"
        },
        "1-2": {
          "name": "室內設計類",
          "tag": "設計行銷"
        },
        "1-3": {
          "name": "工業設計類",
          "tag": "設計行銷"
        },
        "1-4": {
          "name": "視覺傳達設計類",
          "tag": "設計行銷"
        },
        "1-5": {
          "name": "互動及體驗設計類",
          "tag": "設計行銷"
        },
        "1-6": {
          "name": "其他設計、社會設計類",
          "tag": "設計行銷"
        },
        "1-7": {
          "name": "攝影類",
          "tag": "設計行銷"
        },
        "1-8": {
          "name": "創作及藝術表演類",
          "tag": "設計行銷"
        }
      }
    },
    "2": {
      "name": "專業技術服務類",
      "childs": {
        "2-1": {
          "name": "法律及會計服務類",
          "tag": "商業營運"
        },
        "2-2": {
          "name": "策略及管理顧問類",
          "tag": "商業營運"
        },
        "2-3": {
          "name": "科學研發服務類",
          "tag": "技術開發"
        },
        "2-4": {
          "name": "行銷、廣告及市場研究類",
          "tag": "設計行銷"
        },
        "2-5": {
          "name": "獸醫類",
          "tag": "大眾服務"
        },
        "2-6": {
          "name": "其他專業技術服務類",
          "tag": "技術開發"
        }
      }
    },
    "3": {
      "name": "金融及保險類",
      "childs": {
        "3-1": {
          "name": "金融服務類",
          "tag": "商業營運"
        },
        "3-2": {
          "name": "保險類",
          "tag": "商業營運"
        },
        "3-3": {
          "name": "證券期貨及金融輔助類",
          "tag": "商業營運"
        }
      }
    },
    "4": {
      "name": "資訊及通訊服務類",
      "childs": {
        "4-1": {
          "name": "電信類",
          "tag": "技術開發"
        },
        "4-2": {
          "name": "電腦程式設計及相關服務類",
          "tag": "技術開發"
        },
        "4-3": {
          "name": "資訊服務類",
          "tag": "技術開發"
        }
      }
    },
    "5": {
      "name": "醫療保健及社會工作服務類",
      "childs": {
        "5-1": {
          "name": "醫療保健類",
          "tag": "大眾服務"
        },
        "5-2": {
          "name": "居住型照顧服務類",
          "tag": "大眾服務"
        },
        "5-3": {
          "name": "其他社會工作服務類",
          "tag": "大眾服務"
        }
      }
    },
    "6": {
      "name": "教育類",
      "childs": {
        "6-1": {
          "name": "學前教育類",
          "tag": "大眾服務"
        },
        "6-2": {
          "name": "小學教育類",
          "tag": "大眾服務"
        },
        "6-3": {
          "name": "國民中學教育類",
          "tag": "大眾服務"
        },
        "6-4": {
          "name": "高級中等教育類",
          "tag": "大眾服務"
        },
        "6-5": {
          "name": "大專校院教育類",
          "tag": "大眾服務"
        },
        "6-6": {
          "name": "特殊教育類",
          "tag": "大眾服務"
        },
        "6-7": {
          "name": "教育輔助類",
          "tag": "大眾服務"
        },
        "6-8": {
          "name": "其他教育類",
          "tag": "大眾服務"
        }
      }
    },
    "7": {
      "name": "影音出版類",
      "childs": {
        "7-1": {
          "name": "出版類",
          "tag": "設計行銷"
        },
        "7-2": {
          "name": "影片及電視節目類；音樂錄製及發行類",
          "tag": "設計行銷"
        },
        "7-3": {
          "name": "廣播、電視節目編排及傳播類",
          "tag": "設計行銷"
        }
      }
    },
    "8": {
      "name": "公共行政及國防類",
      "childs": {
        "8-1": {
          "name": "公共行政類",
          "tag": "政府治理"
        },
        "8-2": {
          "name": "國防事務類",
          "tag": "政府治理"
        }
      }
    },
    "9": {
      "name": "農、林、漁、牧類",
      "childs": {
        "9-1": {
          "name": "農作物栽培業",
          "tag": "生產製造"
        },
        "9-2": {
          "name": "畜牧業",
          "tag": "生產製造"
        },
        "9-3": {
          "name": "林類",
          "tag": "生產製造"
        },
        "9-4": {
          "name": "漁撈及水產養殖類",
          "tag": "生產製造"
        }
      }
    },
    "10": {
      "name": "飲食製造類",
      "childs": {
        "10-1": {
          "name": "食品及飼品製造類",
          "tag": "生產製造"
        },
        "10-2": {
          "name": "飲料製造類",
          "tag": "生產製造"
        }
      }
    },
    "11": {
      "name": "衣飾製造類",
      "childs": {
        "11-1": {
          "name": "紡織類",
          "tag": "生產製造"
        },
        "11-2": {
          "name": "成衣及服飾品製造類",
          "tag": "生產製造"
        },
        "11-3": {
          "name": "皮革、毛皮及其製品製造類",
          "tag": "生產製造"
        }
      }
    },
    "12": {
      "name": "批發及零售類",
      "childs": {
        "12-1": {
          "name": "批發類",
          "tag": "大眾服務"
        },
        "12-2": {
          "name": "零售類",
          "tag": "大眾服務"
        }
      }
    },
    "13": {
      "name": "娛樂及休閒服務類",
      "childs": {
        "13-1": {
          "name": "圖書館、博物館、檔案保存類",
          "tag": "大眾服務"
        },
        "13-2": {
          "name": "博弈類",
          "tag": "大眾服務"
        },
        "13-3": {
          "name": "運動、娛樂及休閒服務類",
          "tag": "大眾服務"
        }
      }
    },
    "14": {
      "name": "住宿及餐飲類",
      "childs": {
        "14-1": {
          "name": "住宿類",
          "tag": "大眾服務"
        },
        "14-2": {
          "name": "餐飲類",
          "tag": "大眾服務"
        }
      }
    },
    "15": {
      "name": "產品製造類",
      "childs": {
        "15-1": {
          "name": "菸草製造類",
          "tag": "生產製造"
        },
        "15-2": {
          "name": "木竹製品製造類",
          "tag": "生產製造"
        },
        "15-3": {
          "name": "紙漿、紙及紙製品製造類",
          "tag": "生產製造"
        },
        "15-4": {
          "name": "印刷及資料儲存媒體複製類",
          "tag": "生產製造"
        },
        "15-5": {
          "name": "石油及煤製品製造類",
          "tag": "生產製造"
        },
        "15-6": {
          "name": "化學材料及肥料製造類",
          "tag": "生產製造"
        },
        "15-7": {
          "name": "其他化學製品製造類",
          "tag": "生產製造"
        },
        "15-8": {
          "name": "藥品及醫用化學製品製造類",
          "tag": "生產製造"
        },
        "15-9": {
          "name": "橡膠製品製造類",
          "tag": "生產製造"
        },
        "15-10": {
          "name": "塑膠製品製造類",
          "tag": "生產製造"
        },
        "15-11": {
          "name": "非金屬礦物製品製造類",
          "tag": "生產製造"
        },
        "15-12": {
          "name": "基本金屬製造類",
          "tag": "生產製造"
        },
        "15-13": {
          "name": "金屬製品製造類",
          "tag": "生產製造"
        },
        "15-14": {
          "name": "電子零組件製造類",
          "tag": "生產製造"
        },
        "15-15": {
          "name": "電腦、電子產品及光學製品製造類",
          "tag": "生產製造"
        },
        "15-16": {
          "name": "電力設備及配備製造類",
          "tag": "生產製造"
        },
        "15-17": {
          "name": "機械設備製造類",
          "tag": "生產製造"
        },
        "15-18": {
          "name": "汽車及其零件製造類",
          "tag": "生產製造"
        },
        "15-19": {
          "name": "其他運輸工具及其零件製造類",
          "tag": "生產製造"
        },
        "15-20": {
          "name": "家具製造類",
          "tag": "生產製造"
        },
        "15-21": {
          "name": "其他製造類",
          "tag": "生產製造"
        },
        "15-22": {
          "name": "產類用機械設備維修及安裝類",
          "tag": "生產製造"
        }
      }
    },
    "16": {
      "name": "營建工程類",
      "childs": {
        "16-1": {
          "name": "建築工程類",
          "tag": "技術開發"
        },
        "16-2": {
          "name": "土木工程類",
          "tag": "技術開發"
        },
        "16-3": {
          "name": "專門營造類",
          "tag": "技術開發"
        }
      }
    },
    "17": {
      "name": "運輸及倉儲類",
      "childs": {
        "17-1": {
          "name": "陸上運輸類",
          "tag": "大眾服務"
        },
        "17-2": {
          "name": "水上運輸類",
          "tag": "大眾服務"
        },
        "17-3": {
          "name": "航空運輸類",
          "tag": "大眾服務"
        },
        "17-4": {
          "name": "運輸輔助類",
          "tag": "大眾服務"
        },
        "17-5": {
          "name": "倉儲類",
          "tag": "大眾服務"
        },
        "17-6": {
          "name": "郵政及遞送服務類",
          "tag": "大眾服務"
        }
      }
    },
    "18": {
      "name": "支援服務類",
      "childs": {
        "18-1": {
          "name": "租賃類",
          "tag": "大眾服務"
        },
        "18-2": {
          "name": "人力仲介及供應類",
          "tag": "大眾服務"
        },
        "18-3": {
          "name": "旅行及其他相關服務類",
          "tag": "大眾服務"
        },
        "18-4": {
          "name": "保全及偵探類",
          "tag": "大眾服務"
        },
        "18-5": {
          "name": "建築物及綠化服務類",
          "tag": "大眾服務"
        },
        "18-6": {
          "name": "行政支援服務類",
          "tag": "大眾服務"
        }
      }
    },
    "19": {
      "name": "不動產類",
      "childs": {
        "19-1": {
          "name": "不動產開發類",
          "tag": "商業營運"
        },
        "19-2": {
          "name": "不動產經營及相關服務類",
          "tag": "商業營運"
        }
      }
    },
    "20": {
      "name": "礦類及土石採取類",
      "childs": {
        "20-1": {
          "name": "石油及天然氣礦類",
          "tag": "生產製造"
        },
        "20-2": {
          "name": "砂、石採取及其他礦類",
          "tag": "生產製造"
        }
      }
    },
    "21": {
      "name": "電力及燃氣供應類",
      "childs": {
        "21-1": {
          "name": "電力及燃氣供應類",
          "tag": "生產製造"
        }
      }
    },
    "22": {
      "name": "用水供應及污染整治類",
      "childs": {
        "22-1": {
          "name": "用水供應類",
          "tag": "大眾服務"
        },
        "22-2": {
          "name": "廢水及污水處理類",
          "tag": "大眾服務"
        },
        "22-3": {
          "name": "廢棄物處理及資源物回收類",
          "tag": "大眾服務"
        },
        "22-4": {
          "name": "污染整治類",
          "tag": "大眾服務"
        }
      }
    },
    "23": {
      "name": "其他服務類",
      "childs": {
        "23-1": {
          "name": "宗教、職業團體類",
          "tag": "大眾服務"
        },
        "23-2": {
          "name": "個人及家庭用品維修類",
          "tag": "大眾服務"
        },
        "23-3": {
          "name": "其他服務類",
          "tag": "大眾服務"
        },
        "23-4": {
          "name": "志願服務類",
          "tag": "大眾服務"
        }
      }
    },
    "24": {
      "name": "不分專業",
      "childs": {
        "24-1": {
          "name": "不分專業",
          "tag": "不分專業"
        }
      }
    }
  },
  "en-US": {
    "1": {
      "name": "設計及藝術類",
      "childs": {
        "1-1": {
          "name": "建築、工程設計類",
          "tag": "設計行銷"
        },
        "1-2": {
          "name": "室內設計類",
          "tag": "設計行銷"
        },
        "1-3": {
          "name": "工業設計類",
          "tag": "設計行銷"
        },
        "1-4": {
          "name": "視覺傳達設計類",
          "tag": "設計行銷"
        },
        "1-5": {
          "name": "互動及體驗設計類",
          "tag": "設計行銷"
        },
        "1-6": {
          "name": "其他設計、社會設計類",
          "tag": "設計行銷"
        },
        "1-7": {
          "name": "攝影類",
          "tag": "設計行銷"
        },
        "1-8": {
          "name": "創作及藝術表演類",
          "tag": "設計行銷"
        }
      }
    },
    "2": {
      "name": "專業技術服務類",
      "childs": {
        "2-1": {
          "name": "法律及會計服務類",
          "tag": "商業營運"
        },
        "2-2": {
          "name": "策略及管理顧問類",
          "tag": "商業營運"
        },
        "2-3": {
          "name": "科學研發服務類",
          "tag": "技術開發"
        },
        "2-4": {
          "name": "行銷、廣告及市場研究類",
          "tag": "設計行銷"
        },
        "2-5": {
          "name": "獸醫類",
          "tag": "大眾服務"
        },
        "2-6": {
          "name": "其他專業技術服務類",
          "tag": "技術開發"
        }
      }
    },
    "3": {
      "name": "金融及保險類",
      "childs": {
        "3-1": {
          "name": "金融服務類",
          "tag": "商業營運"
        },
        "3-2": {
          "name": "保險類",
          "tag": "商業營運"
        },
        "3-3": {
          "name": "證券期貨及金融輔助類",
          "tag": "商業營運"
        }
      }
    },
    "4": {
      "name": "資訊及通訊服務類",
      "childs": {
        "4-1": {
          "name": "電信類",
          "tag": "技術開發"
        },
        "4-2": {
          "name": "電腦程式設計及相關服務類",
          "tag": "技術開發"
        },
        "4-3": {
          "name": "資訊服務類",
          "tag": "技術開發"
        }
      }
    },
    "5": {
      "name": "醫療保健及社會工作服務類",
      "childs": {
        "5-1": {
          "name": "醫療保健類",
          "tag": "大眾服務"
        },
        "5-2": {
          "name": "居住型照顧服務類",
          "tag": "大眾服務"
        },
        "5-3": {
          "name": "其他社會工作服務類",
          "tag": "大眾服務"
        }
      }
    },
    "6": {
      "name": "教育類",
      "childs": {
        "6-1": {
          "name": "學前教育類",
          "tag": "大眾服務"
        },
        "6-2": {
          "name": "小學教育類",
          "tag": "大眾服務"
        },
        "6-3": {
          "name": "國民中學教育類",
          "tag": "大眾服務"
        },
        "6-4": {
          "name": "高級中等教育類",
          "tag": "大眾服務"
        },
        "6-5": {
          "name": "大專校院教育類",
          "tag": "大眾服務"
        },
        "6-6": {
          "name": "特殊教育類",
          "tag": "大眾服務"
        },
        "6-7": {
          "name": "教育輔助類",
          "tag": "大眾服務"
        },
        "6-8": {
          "name": "其他教育類",
          "tag": "大眾服務"
        }
      }
    },
    "7": {
      "name": "影音出版類",
      "childs": {
        "7-1": {
          "name": "出版類",
          "tag": "設計行銷"
        },
        "7-2": {
          "name": "影片及電視節目類；音樂錄製及發行類",
          "tag": "設計行銷"
        },
        "7-3": {
          "name": "廣播、電視節目編排及傳播類",
          "tag": "設計行銷"
        }
      }
    },
    "8": {
      "name": "公共行政及國防類",
      "childs": {
        "8-1": {
          "name": "公共行政類",
          "tag": "政府治理"
        },
        "8-2": {
          "name": "國防事務類",
          "tag": "政府治理"
        }
      }
    },
    "9": {
      "name": "農、林、漁、牧類",
      "childs": {
        "9-1": {
          "name": "農作物栽培業",
          "tag": "生產製造"
        },
        "9-2": {
          "name": "畜牧業",
          "tag": "生產製造"
        },
        "9-3": {
          "name": "林類",
          "tag": "生產製造"
        },
        "9-4": {
          "name": "漁撈及水產養殖類",
          "tag": "生產製造"
        }
      }
    },
    "10": {
      "name": "飲食製造類",
      "childs": {
        "10-1": {
          "name": "食品及飼品製造類",
          "tag": "生產製造"
        },
        "10-2": {
          "name": "飲料製造類",
          "tag": "生產製造"
        }
      }
    },
    "11": {
      "name": "衣飾製造類",
      "childs": {
        "11-1": {
          "name": "紡織類",
          "tag": "生產製造"
        },
        "11-2": {
          "name": "成衣及服飾品製造類",
          "tag": "生產製造"
        },
        "11-3": {
          "name": "皮革、毛皮及其製品製造類",
          "tag": "生產製造"
        }
      }
    },
    "12": {
      "name": "批發及零售類",
      "childs": {
        "12-1": {
          "name": "批發類",
          "tag": "大眾服務"
        },
        "12-2": {
          "name": "零售類",
          "tag": "大眾服務"
        }
      }
    },
    "13": {
      "name": "娛樂及休閒服務類",
      "childs": {
        "13-1": {
          "name": "圖書館、博物館、檔案保存類",
          "tag": "大眾服務"
        },
        "13-2": {
          "name": "博弈類",
          "tag": "大眾服務"
        },
        "13-3": {
          "name": "運動、娛樂及休閒服務類",
          "tag": "大眾服務"
        }
      }
    },
    "14": {
      "name": "住宿及餐飲類",
      "childs": {
        "14-1": {
          "name": "住宿類",
          "tag": "大眾服務"
        },
        "14-2": {
          "name": "餐飲類",
          "tag": "大眾服務"
        }
      }
    },
    "15": {
      "name": "產品製造類",
      "childs": {
        "15-1": {
          "name": "菸草製造類",
          "tag": "生產製造"
        },
        "15-2": {
          "name": "木竹製品製造類",
          "tag": "生產製造"
        },
        "15-3": {
          "name": "紙漿、紙及紙製品製造類",
          "tag": "生產製造"
        },
        "15-4": {
          "name": "印刷及資料儲存媒體複製類",
          "tag": "生產製造"
        },
        "15-5": {
          "name": "石油及煤製品製造類",
          "tag": "生產製造"
        },
        "15-6": {
          "name": "化學材料及肥料製造類",
          "tag": "生產製造"
        },
        "15-7": {
          "name": "其他化學製品製造類",
          "tag": "生產製造"
        },
        "15-8": {
          "name": "藥品及醫用化學製品製造類",
          "tag": "生產製造"
        },
        "15-9": {
          "name": "橡膠製品製造類",
          "tag": "生產製造"
        },
        "15-10": {
          "name": "塑膠製品製造類",
          "tag": "生產製造"
        },
        "15-11": {
          "name": "非金屬礦物製品製造類",
          "tag": "生產製造"
        },
        "15-12": {
          "name": "基本金屬製造類",
          "tag": "生產製造"
        },
        "15-13": {
          "name": "金屬製品製造類",
          "tag": "生產製造"
        },
        "15-14": {
          "name": "電子零組件製造類",
          "tag": "生產製造"
        },
        "15-15": {
          "name": "電腦、電子產品及光學製品製造類",
          "tag": "生產製造"
        },
        "15-16": {
          "name": "電力設備及配備製造類",
          "tag": "生產製造"
        },
        "15-17": {
          "name": "機械設備製造類",
          "tag": "生產製造"
        },
        "15-18": {
          "name": "汽車及其零件製造類",
          "tag": "生產製造"
        },
        "15-19": {
          "name": "其他運輸工具及其零件製造類",
          "tag": "生產製造"
        },
        "15-20": {
          "name": "家具製造類",
          "tag": "生產製造"
        },
        "15-21": {
          "name": "其他製造類",
          "tag": "生產製造"
        },
        "15-22": {
          "name": "產類用機械設備維修及安裝類",
          "tag": "生產製造"
        }
      }
    },
    "16": {
      "name": "營建工程類",
      "childs": {
        "16-1": {
          "name": "建築工程類",
          "tag": "技術開發"
        },
        "16-2": {
          "name": "土木工程類",
          "tag": "技術開發"
        },
        "16-3": {
          "name": "專門營造類",
          "tag": "技術開發"
        }
      }
    },
    "17": {
      "name": "運輸及倉儲類",
      "childs": {
        "17-1": {
          "name": "陸上運輸類",
          "tag": "大眾服務"
        },
        "17-2": {
          "name": "水上運輸類",
          "tag": "大眾服務"
        },
        "17-3": {
          "name": "航空運輸類",
          "tag": "大眾服務"
        },
        "17-4": {
          "name": "運輸輔助類",
          "tag": "大眾服務"
        },
        "17-5": {
          "name": "倉儲類",
          "tag": "大眾服務"
        },
        "17-6": {
          "name": "郵政及遞送服務類",
          "tag": "大眾服務"
        }
      }
    },
    "18": {
      "name": "支援服務類",
      "childs": {
        "18-1": {
          "name": "租賃類",
          "tag": "大眾服務"
        },
        "18-2": {
          "name": "人力仲介及供應類",
          "tag": "大眾服務"
        },
        "18-3": {
          "name": "旅行及其他相關服務類",
          "tag": "大眾服務"
        },
        "18-4": {
          "name": "保全及偵探類",
          "tag": "大眾服務"
        },
        "18-5": {
          "name": "建築物及綠化服務類",
          "tag": "大眾服務"
        },
        "18-6": {
          "name": "行政支援服務類",
          "tag": "大眾服務"
        }
      }
    },
    "19": {
      "name": "不動產類",
      "childs": {
        "19-1": {
          "name": "不動產開發類",
          "tag": "商業營運"
        },
        "19-2": {
          "name": "不動產經營及相關服務類",
          "tag": "商業營運"
        }
      }
    },
    "20": {
      "name": "礦類及土石採取類",
      "childs": {
        "20-1": {
          "name": "石油及天然氣礦類",
          "tag": "生產製造"
        },
        "20-2": {
          "name": "砂、石採取及其他礦類",
          "tag": "生產製造"
        }
      }
    },
    "21": {
      "name": "電力及燃氣供應類",
      "childs": {
        "21-1": {
          "name": "電力及燃氣供應類",
          "tag": "生產製造"
        }
      }
    },
    "22": {
      "name": "用水供應及污染整治類",
      "childs": {
        "22-1": {
          "name": "用水供應類",
          "tag": "大眾服務"
        },
        "22-2": {
          "name": "廢水及污水處理類",
          "tag": "大眾服務"
        },
        "22-3": {
          "name": "廢棄物處理及資源物回收類",
          "tag": "大眾服務"
        },
        "22-4": {
          "name": "污染整治類",
          "tag": "大眾服務"
        }
      }
    },
    "23": {
      "name": "其他服務類",
      "childs": {
        "23-1": {
          "name": "宗教、職業團體類",
          "tag": "大眾服務"
        },
        "23-2": {
          "name": "個人及家庭用品維修類",
          "tag": "大眾服務"
        },
        "23-3": {
          "name": "其他服務類",
          "tag": "大眾服務"
        },
        "23-4": {
          "name": "志願服務類",
          "tag": "大眾服務"
        }
      }
    },
    "24": {
      "name": "不分專業",
      "childs": {
        "24-1": {
          "name": "不分專業",
          "tag": "不分專業"
        }
      }
    }
  }
}

export const ServiceTypesOrder = {
  "1": 3,
  "2": 12,
  "3": 19,
  "4": 23,
  "5": 27,
  "6": 31,
  "7": 40,
  "8": 44,
  "9": 47,
  "10": 52,
  "11": 55,
  "12": 59,
  "13": 62,
  "14": 66,
  "15": 69,
  "16": 92,
  "17": 96,
  "18": 103,
  "19": 110,
  "20": 113,
  "21": 116,
  "22": 118,
  "23": 123,
  "24": 1,
  "1-1": 4,
  "1-2": 5,
  "1-3": 6,
  "1-4": 7,
  "1-5": 8,
  "1-6": 9,
  "1-7": 10,
  "1-8": 11,
  "2-1": 13,
  "2-2": 14,
  "2-3": 15,
  "2-4": 16,
  "2-5": 17,
  "2-6": 18,
  "3-1": 20,
  "3-2": 21,
  "3-3": 22,
  "4-1": 24,
  "4-2": 25,
  "4-3": 26,
  "5-1": 28,
  "5-2": 29,
  "5-3": 30,
  "6-1": 32,
  "6-2": 33,
  "6-3": 34,
  "6-4": 35,
  "6-5": 36,
  "6-6": 37,
  "6-7": 38,
  "6-8": 39,
  "7-1": 41,
  "7-2": 42,
  "7-3": 43,
  "8-1": 45,
  "8-2": 46,
  "9-1": 48,
  "9-2": 49,
  "9-3": 50,
  "9-4": 51,
  "10-1": 53,
  "10-2": 54,
  "11-1": 56,
  "11-2": 57,
  "11-3": 58,
  "12-1": 60,
  "12-2": 61,
  "13-1": 63,
  "13-2": 64,
  "13-3": 65,
  "14-1": 67,
  "14-2": 68,
  "15-1": 70,
  "15-2": 71,
  "15-3": 72,
  "15-4": 73,
  "15-5": 74,
  "15-6": 75,
  "15-7": 76,
  "15-8": 77,
  "15-9": 78,
  "15-10": 79,
  "15-11": 80,
  "15-12": 81,
  "15-13": 82,
  "15-14": 83,
  "15-15": 84,
  "15-16": 85,
  "15-17": 86,
  "15-18": 87,
  "15-19": 88,
  "15-20": 89,
  "15-21": 90,
  "15-22": 91,
  "16-1": 93,
  "16-2": 94,
  "16-3": 95,
  "17-1": 97,
  "17-2": 98,
  "17-3": 99,
  "17-4": 100,
  "17-5": 101,
  "17-6": 102,
  "18-1": 104,
  "18-2": 105,
  "18-3": 106,
  "18-4": 107,
  "18-5": 108,
  "18-6": 109,
  "19-1": 111,
  "19-2": 112,
  "20-1": 114,
  "20-2": 115,
  "21-1": 117,
  "22-1": 119,
  "22-2": 120,
  "22-3": 121,
  "22-4": 122,
  "23-1": 124,
  "23-2": 125,
  "23-3": 126,
  "23-4": 127,
  "24-1": 2
}

export default ServiceTypes
