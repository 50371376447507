import { makeObservable, observable, action, runInAction, computed, override, toJS } from 'mobx';
import ConstantsStore from 'src/stores/constants';
import DemandMeta from './DemandMeta';

class Service extends DemandMeta {
  @observable count;
  @observable jobs;
  @observable seniority;
  @observable type;

  constructor(params) {
    super(params);
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get isComplete() {
    return this.isCommonAttrComplete
      && !!this.count && this.isCountValid
      && !!this.type;
  }

  @computed get isEmpty() {
    return this.isCommonAttrEmpty
      && !this.count
      && !this.type;
  }

  @computed get isCountValid() {
    return /^\d+$/.test(this.count);
  }

  @computed get seniorityLabel() {
    return ConstantsStore.seniorityOptions.find((opt) => opt.value === this.seniority)?.label ?? this.seniority;
  }

  @computed get typeLabel() {
    return ConstantsStore.getServiceTypeTag(this.type);
  }

  @computed get jobsLabels() {
    return this.jobs?.map((job) => ConstantsStore.jobsOptions.find((opt) => opt.value === job)?.label ?? '-') ?? [];
  }

  // /////////////////////////

  @action deserialize = (params) => {
    const {
      count,
      jobs,
      seniority,
      type
    } = params ?? {};

    this.count = count;
    this.jobs = jobs ?? [];
    this.seniority = seniority;
    this.type = type;
  };

  serialize = () => {
    return {
      ...this.serializeCommonAttr(),
      count: this.count,
      jobs: toJS(this.jobs),
      seniority: this.seniority,
      type: this.type
    };
  };

  static fromRes(data) {
    return new Service(data);
  }
}

export default Service;




