/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { handleLineBreaks } from 'src/utils';
import clsx from 'clsx';
import { t } from 'i18next';
import ConstantsStore from 'src/stores/constants';
import { CREATE_EVENT_STEPS, KEYWORD_TYPE } from 'src/constants';
import Button from 'src/components/Button';
import BaseModal from 'src/components/Modals/BaseModal';
import styles from './styles.module.scss';

const convertArrayToString = (arr) => {
  let result = '';
  arr?.forEach((str, i) => {
    result += str;
    if (i !== arr.length - 1) {
      result += ConstantsStore.language === 'zh-TW' ? '、' : ', ';
    }
  });
  return result;
};


@observer
class ReviewTextModal extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  switchStep = (step) => {
    const {
      onSwitchStep,
      toggleReviewTextModal
    } = this.props;

    toggleReviewTextModal();
    onSwitchStep(step);
  };

  mediaString = (item) => {
    if (item.videos?.length !== 0) {
      return item.videos[0];
    }

    if (item.photos?.length !== 0) {
      return item.photos[0]?.url ?? t('none');
    }

    return t('none');
  };


  renderSectionTitle(title) {
    return (
      <div className={styles.sectionTitle}>
        {`/ ${title} /`}
      </div>
    );
  }

  renderLine(key, text) {
    return (
      <div className={styles.content} key={key}>
        <span className={styles.label}>
          {`${key}：`}
        </span>
        <span>
          {text}
        </span>
      </div>
    );
  }

  renderBasic() {
    const { event, releaseMethodUIString } = this.props;

    const keywordsObj = event.keywordsObj ?? {};
    const regions = event.convertedRegions?.map((r) => ConstantsStore.getRegionLabelByValue(r));

    const basicContents = [
      [t('event_field_name'), event.name],
      [t('event_field_slogan'), event.slogan],
      [t('event_field_customId'), event.id]
    ];


    const submitContents = [
      [t('event_description'), event.description],
      [t('event_keyword_sdg'), keywordsObj[KEYWORD_TYPE.Sdg]?.split('_')[0]],
      [t('event_keyword_target'), keywordsObj[KEYWORD_TYPE.Target]],
      [t('event_keyword_keyItem'), keywordsObj[KEYWORD_TYPE.KeyItem]],
      [t('event_keyword_custom'), keywordsObj[KEYWORD_TYPE.Custom]],
      [t('event_banner'), event.banner],
      [t('event_coInitiators'), convertArrayToString(event.coInitiatorsNames)],
      [t('event_collaborators'), event.collaboratorsNames?.length !== 0 ? convertArrayToString(event.collaboratorsNames) : t('none')],
      [t('event_coOrganiser'), event.coOrganiserNames?.length !== 0 ? convertArrayToString(event.coOrganiserNames) : t('none')],
      [t('event_regions'), convertArrayToString(regions)],
      [t('event_privacy'), t(`privacy_${event.privacy}`)],
      [t('create_event_review_text_modal_release_info'), releaseMethodUIString]
    ];

    return (
      <section className={clsx(styles.basicSection)}>
        {this.renderSectionTitle(t('create_step_basic'))}

        <div
          className={styles.subSection}
          onClick={() => this.switchStep(CREATE_EVENT_STEPS.Basic)}
        >
          {basicContents.map((c) => this.renderLine(c[0], c[1]))}
        </div>

        <div
          className={styles.subSection}
          onClick={() => this.switchStep(CREATE_EVENT_STEPS.Submit)}
        >
          {submitContents.filter((c) => !!c)?.map((c) => this.renderLine(c[0], c[1]))}
        </div>
      </section>
    );
  }

  renderContact() {
    const contact = this.props.event.contact;

    const contents = [
      [t('contact_name'), contact.name],
      [t('contact_jobTitle'), contact.jobTitle],
      [t('contact_phone'), contact.phone],
      [t('contact_email'), contact.email]
    ];

    return (
      <section
        className={styles.stepSection}
        onClick={() => {
          this.switchStep(CREATE_EVENT_STEPS.Basic);
          window.scrollTo(0, document.body.scrollHeight);
        }}
      >
        {this.renderSectionTitle(t('create_event_contact_title'))}
        {contents.map((c) => this.renderLine(c[0], c[1]))}
      </section>
    );
  }

  renderKeyFinding() {
    const { event } = this.props;
    const keyFinding = event.keyFinding ?? {};

    const sdgs = event.sdgs?.map((s) => ConstantsStore.getSdgLabelByValue(s));

    const contents = [
      [t('event_field_sdgs'), convertArrayToString(sdgs)],
      [t('event_field_keyFinding_title'), keyFinding.title],
      [t('event_field_keyFinding_description'), keyFinding.description],
      [t('event_field_photo_or_video'), this.mediaString(keyFinding)]
    ];

    return (
      <section
        className={styles.stepSection}
        onClick={() => this.switchStep(CREATE_EVENT_STEPS.KeyFinding)}
      >
        {this.renderSectionTitle(t('create_step_keyFinding'))}
        {contents.map((c) => this.renderLine(c[0], c[1]))}
      </section>
    );
  }

  renderTargets() {
    const { event } = this.props;
    const targets = event.targets;

    return (
      <section
        className={styles.stepSection}
        onClick={() => this.switchStep(CREATE_EVENT_STEPS.Target)}
      >
        {this.renderSectionTitle(t('create_step_target'))}
        {targets.map((ta, i) => {
          const contents = [
            [t('event_field_targets_title'), ta.title],
            [t('event_field_targets_description'), ta.description],
            [t('event_field_targets_option_other'), ta.optionName],
            [t('event_field_targets_option'), ta.optionKey],
            [t('event_field_photo_or_video'), this.mediaString(ta)]
          ];

          return (
            <div key={ta.title} className={styles.item}>
              <div className={styles.highlight}>
                {`${t('create_event_targets_title')} 0${i + 1}`}
              </div>
              {contents.map((c) => this.renderLine(c[0], c[1]))}
            </div>
          );
        })}
      </section>
    );
  }

  renderVision() {
    const { event } = this.props;
    const vision = event.vision;

    const contents = [
      [t('event_field_vision_description'), vision.description]
    ];

    return (
      <section
        className={styles.stepSection}
        onClick={() => this.switchStep(CREATE_EVENT_STEPS.Vision)}
      >
        {this.renderSectionTitle(t('create_step_vision'))}
        {contents.map((c) => this.renderLine(c[0], c[1]))}
      </section>
    );
  }

  renderKeyItem() {
    const { event } = this.props;
    const items = event.keyItem.items;

    return (
      <section
        className={styles.stepSection}
        onClick={() => this.switchStep(CREATE_EVENT_STEPS.KeyItem)}
      >
        {this.renderSectionTitle(t('create_step_keyItem'))}
        {this.renderLine(t('event_field_keyItem_introduction'), event.keyItem.introduction)}
        <div className={styles.gap} />
        {items.map((item, i) => {
          const contents = [
            [t('event_field_keyItem_title'), item.title],
            [t('event_field_keyItem_description'), item.description],
            [t('event_field_keyItem_option'), item.optionName],
            [t('event_field_photo_or_video'), this.mediaString(item)]
          ];

          return (
            <div key={item.title}>
              <div className={styles.highlight}>
                {`${t('create_step_keyItem')} 0${i + 1}`}
              </div>
              {contents.map((c) => this.renderLine(c[0], c[1]))}
            </div>
          );
        })}
      </section>
    );
  }

  renderFeatures() {
    const { event } = this.props;
    const features = event.features;

    return (
      <section
        className={styles.stepSection}
        onClick={() => this.switchStep(CREATE_EVENT_STEPS.Features)}
      >
        {this.renderSectionTitle(t('create_step_features'))}
        {features.map((item, i) => {
          const contents = [
            [t('event_field_features_title'), item.title],
            [t('event_field_features_description'), item.description]
          ];

          return (
            <div key={item.title} className={styles.item}>
              <div className={styles.highlight}>
                {`${t('create_step_features')} 0${i + 1}`}
              </div>
              {contents.map((c) => this.renderLine(c[0], c[1]))}
            </div>
          );
        })}
      </section>
    );
  }

  renderDemands() {
    const { demands } = this.props;
    const { service, funds, space } = demands;

    return (
      <section
        className={styles.stepSection}
        onClick={() => this.switchStep(CREATE_EVENT_STEPS.Demand)}
      >
        {this.renderSectionTitle(t('create_step_demand'))}

        {service?.length !== 0 && this.renderServiceDemands(service)}
        {funds?.length !== 0 && this.renderFundsDemands(funds)}
        {space?.length !== 0 && this.renderSpaceDemands(space)}
      </section>
    );
  }

  renderServiceDemands(items) {
    return items?.filter((item) => !item.isEmpty)?.map((item, i) => {
      const contents = [
        [t('demand_service_name'), item.name],
        [t('demand_service_type_placeholder'), item.typeLabel],
        [t('demand_service_count'), item.count],
        [t('demand_service_description'), item.description],
        [t('demand_prefer_profession'), convertArrayToString(item.jobsLabels)],
        [t('demand_seniority'), item.seniorityLabel],
        [t('demand_keyItemDescriptions'), convertArrayToString(item.keyItemDescriptions)],
        [t('demand_award_options'), convertArrayToString(item.awardsLabels)],
        [t('demand_award_description'), convertArrayToString(item.awardDescriptions)],
        [t('demand_dates'), convertArrayToString(item.dates)],
        [t('demand_regions'), convertArrayToString(item.combinedRegionsLabels)],
        [t('demand_rules'), convertArrayToString(item.rules)]
      ];

      return (
        <div key={item.title} className={styles.item}>
          <div className={styles.highlight}>
            {`${t('create_event_demand_service_title')} 0${i + 1}`}
          </div>
          {contents.map((c) => this.renderLine(c[0], c[1]))}
        </div>
      );
    });
  }

  renderFundsDemands(items) {
    return items?.filter((item) => !item.isEmpty)?.map((item, i) => {
      const contents = [
        [t('demand_funds_name'), item.name],
        [t('demand_funds_types_placeholder'), convertArrayToString(item.typesLabels)],
        [t('demand_funds_quota'), item.quota],
        [t('demand_funds_description'), item.description],
        [t('demand_keyItemDescriptions'), convertArrayToString(item.keyItemDescriptions)],
        [t('demand_award_options'), convertArrayToString(item.awardsLabels)],
        [t('demand_award_description'), convertArrayToString(item.awardDescriptions)],
        [t('demand_dates'), convertArrayToString(item.dates)],
        [t('demand_regions'), convertArrayToString(item.combinedRegionsLabels)],
        [t('demand_rules'), convertArrayToString(item.rules)]
      ];

      return (
        <div key={item.title} className={styles.item}>
          <div className={styles.highlight}>
            {`${t('create_event_demand_funds_title')} 0${i + 1}`}
          </div>
          {contents.map((c) => this.renderLine(c[0], c[1]))}
        </div>
      );
    });
  }

  renderSpaceDemands(items) {
    return items?.filter((item) => !item.isEmpty)?.map((item, i) => {
      const contents = [
        [t('demand_space_name'), item.name],
        [t('demand_space_usages_placeholder'), convertArrayToString(item.usagesLabels)],
        [t('demand_size'), item.size],
        [t('demand_space_description'), item.description],
        [t('demand_capacity'), item.capacity],
        [t('demand_equipments'), convertArrayToString(item.equipsLabels)],
        [t('demand_keyItemDescriptions'), convertArrayToString(item.keyItemDescriptions)],
        [t('demand_award_options'), convertArrayToString(item.awardsLabels)],
        [t('demand_award_description'), convertArrayToString(item.awardDescriptions)],
        [t('demand_dates'), convertArrayToString(item.dates)],
        [t('demand_regions'), convertArrayToString(item.combinedRegionsLabels)],
        [t('demand_rules'), convertArrayToString(item.rules)]
      ];

      return (
        <div key={item.title} className={styles.item}>
          <div className={styles.highlight}>
            {`${t('create_event_demand_space_title')} 0${i + 1}`}
          </div>
          {contents.map((c) => this.renderLine(c[0], c[1]))}
        </div>
      );
    });
  }

  renderFaqs() {
    const { event } = this.props;
    const faqs = event.faqs?.filter((f) => f.isComplete) ?? [];

    return (
      <section
        className={styles.stepSection}
        onClick={() => this.switchStep(CREATE_EVENT_STEPS.Faqs)}
      >
        {this.renderSectionTitle(t('create_step_faqs'))}
        {faqs.length !== 0
          ? faqs?.map((item, i) => {
            const contents = [
              [t('faq_question'), item.question],
              [t('faq_answer'), item.answer]
            ];

            return (
              <div key={`${item.title}_${i}`} className={styles.item}>
                <div className={styles.highlight}>
                  {`${t('create_event_faq_title')} 0${i + 1}`}
                </div>
                {contents.map((c) => this.renderLine(c[0], c[1]))}
              </div>
            );
          })
          : t('none')}
      </section>
    );
  }

  renderRegistration() {
    const { event } = this.props;
    const requires = Object.entries(event.registrationRequiresObj).filter((opt) => opt[1])?.map((opt) => t(`reg_${opt[0]}`)) ?? [];
    const fields = event.registrationFields?.map((f) => f.label) ?? [];
    const allFields = requires.concat(fields);

    const contents = [
      [t('registrationRequires_label'), allFields.length !== 0 ? convertArrayToString(allFields) : t('none')]
    ];

    return (
      <section
        className={styles.stepSection}
        onClick={() => this.switchStep(CREATE_EVENT_STEPS.Registration)}
      >
        {this.renderSectionTitle(t('create_step_registration'))}
        {contents.map((c) => this.renderLine(c[0], c[1]))}
      </section>
    );
  }

  render() {
    const {
      event,
      demands,
      toggleReviewTextModal,
      isReviewTextModalOpen,
      isMobile,
      onSubmit,
      isLoading
    } = this.props;

    return (
      <BaseModal
        onClose={toggleReviewTextModal}
        open={isReviewTextModalOpen}
        isMobile={isMobile}
        height="90%"
        className={styles.reviewTextModal}
        centered
        zIndex={999}
      >
        <div className={styles.reviewTextModalContent}>
          <div className={styles.title}>
            {t('create_event_review_text_modal_title')}
          </div>
          <div className={styles.subtitle}>
            {handleLineBreaks(t('create_event_review_text_modal_subtitle'))}
          </div>

          {/* ----------- main content ------------ */}
          {this.renderBasic()}
          {event.contact && this.renderContact()}
          {event.keyFinding && this.renderKeyFinding()}
          {event.targets && this.renderTargets()}
          {event.vision && this.renderVision()}
          {event.keyItem && this.renderKeyItem()}
          {event.features && this.renderFeatures()}
          {demands && this.renderDemands()}
          {event.faqs && this.renderFaqs()}
          {(event.registrationFields?.length !== 0 || event.registrationRequires) && this.renderRegistration()}
          {/* ----------------------- */}

          <div className={styles.buttons}>
            <Button
              onClick={toggleReviewTextModal}
              className={styles.backButton}
              type="default"
            >
              {t('back')}
            </Button>

            <Button
              onClick={onSubmit}
              className={styles.button}
              loading={isLoading}
            >
              {t('confirm_and_submit')}
            </Button>
          </div>

        </div>
      </BaseModal>
    );
  }

}

ReviewTextModal.propTypes = {
  event: PropTypes.object,
  toggleReviewTextModal: PropTypes.func,
  isReviewTextModalOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSwitchStep: PropTypes.func,
  demands: PropTypes.object,
  isLoading: PropTypes.bool,
  releaseMethodUIString: PropTypes.string
};

ReviewTextModal.defaultProps = {
  event: null,
  toggleReviewTextModal: null,
  isReviewTextModalOpen: false,
  isMobile: false,
  onSubmit: null,
  onSwitchStep: null,
  demands: {},
  isLoading: false,
  releaseMethodUIString: null
};

export default ReviewTextModal;
