import { request } from './utils';

export const putGoogleStorage = ({
  url,
  mimetype,
  size,
  file
}) => {
  const options = {
    method: 'put',
    url,
    headers: {
      'Content-Type': mimetype,
      'X-Upload-Content-Length': size
    },
    withCredentials: false,
    data: file
  };

  return request(options);
};
