import Product from './Product';

export default class Trade {
  constructor(params) {
    const {
      id,
      amount,
      product,
      refund,
      resultCode,
      resultMsg,
      status,
      transationId
    } = params ?? {};

    this.id = id; // T202309Hv6EXCVJVYPQJ
    this.amount = amount;
    this.product = Product.fromRes(product);
    this.refund = refund;
    this.resultCode = resultCode;
    this.resultMsg = resultMsg;
    this.status = status;
    this._transationId = transationId; // 2309201205442911
  }

  static fromRes(data) {
    return new Trade(data);
  }

  get transactionId() {
    return this._transationId ? this._transationId : null;
  }
}



