import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { TERMS_AND_POLICY_TABS } from 'src/constants';
import Button from 'src/components/Button';
import Tabs from 'src/components/Tabs';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Breadcrumb from 'src/components/Breadcrumb';
import MainLayout from 'src/components/MainLayout';
import ballMan from 'src/assets/illustrationBallMan.svg';
import earthMan from 'src/assets/illustrationEarthMan.svg';
import writer from 'src/assets/illustrationWriter.svg';
import logo from 'src/assets/logo.svg';

import TermsAndPolicyPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class TermsAndPolicyPage extends React.Component {
  constructor(props) {
    super(props);

    this.vm = new TermsAndPolicyPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderText(string) {
    const arr = string.split('\\n');
    return arr.map((str, i) => {
      if (str === '') {
        return <br />;
      }

      if (/^\n?===/.test(str)) {
        return (
          <div key={str} className={styles.sectionTitle}>
            {str.split('===')[1]}
          </div>
        );
      }

      return (
        <div key={str} className={styles.text}>
          {str}
        </div>
      );
    });
  }

  renderTerms() {
    return (
      <div className={styles.content}>
        <div className={styles.updatedAt}>
          {t('terms_and_policy_terms_updated_at')}
        </div>
        <img
          src={writer}
          alt="writer"
          className={styles.writer}
        />
        {this.renderText(t('terms_and_policy_terms_content'))}
      </div>
    );
  }

  renderPolicy() {
    return (
      <div className={styles.content}>
        <div className={styles.updatedAt}>
          {t('terms_and_policy_policy_updated_at')}
        </div>
        <img
          src={writer}
          alt="writer"
          className={styles.writer}
        />
        {this.renderText(t('terms_and_policy_policy_content'))}
      </div>
    );
  }

  render() {
    return (
      <div className={clsx(styles.TermsAndPolicyPage, 'page')}>
        <MainLayout
          {...this.props}
          hideHeader
          footerStyle={{ marginBottom: 100 }}
        >
          <Breadcrumb
            colorType="purple"
            routes={[]}
            right={(
              <div
                style={{ marginLeft: 10, cursor: 'pointer' }}
                onClick={this.vm.closeTab}
              >
                {t('back')}
              </div>
            )}
            onBackPress={this.vm.closeTab}
          />
          <div className={styles.top}>
            <div className={styles.center}>
              <img
                src={logo}
                alt="logo"
                className={styles.logo}
              />
              <div className={styles.title}>
                {t('terms_and_policy')}
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.main}>
              {/* <img
                src={ballMan}
                alt="ballMan"
                className={styles.illustration1}
              />
              <img
                src={earthMan}
                alt="earthMan"
                className={styles.illustration2}
              /> */}
              <Tabs
                colorMode={2}
                items={[
                  {
                    value: TERMS_AND_POLICY_TABS.Terms,
                    label: t('terms_and_policy_tab_terms'),
                    content: (
                      <>
                        <hr className="dashedPurple" style={{ margin: '0px 20px 20px' }} />
                        {this.renderTerms()}
                      </>
                    )
                  },
                  {
                    value: TERMS_AND_POLICY_TABS.Policy,
                    label: t('terms_and_policy_tab_policy'),
                    content: (
                      <>
                        <hr className="dashedPurple" style={{ margin: '0px 20px 20px' }} />
                        {this.renderPolicy()}
                      </>
                    )
                  }
                ]}
                onSwitchTab={this.vm.switchTab}
                currentValue={this.vm.currentTab}
                centered
                className={styles.tab}
              />
            </div>
          </div>

        </MainLayout>

        <Button
          onClick={this.vm.closeTab}
          withRightArrow
          className={styles.button}
        >
          {t('terms_and_policy_button')}
        </Button>
      </div>
    );
  }
}

export default withProfile(withRouter(withTranslation()(TermsAndPolicyPage)), false);
