import i18n from 'src/i18n';
import countryCodes from 'src/constants/gens/countryCodes.json';
import Jobs, { JobsOrder } from 'src/constants/gens/Jobs.gen';
import OrganizationTypes, { OrganizationTypesOrder } from 'src/constants/gens/OrganizationTypes.gen';
import Regions, { RegionsOrder } from 'src/constants/gens/Regions.gen';
import SDGs, { SDGsOrder } from 'src/constants/gens/SDGs.gen';
import ServiceTypes, { ServiceTypesOrder } from 'src/constants/gens/ServiceTypes.gen';
import FundsTypes, { FundsTypesOrder } from 'src/constants/gens/FundsTypes.gen';
import Usages, { UsagesOrder } from 'src/constants/gens/Usages.gen';
import Equipments, { EquipmentsOrder } from 'src/constants/gens/Equipments.gen';
import ServiceTargets, { ServiceTargetsOrder } from 'src/constants/gens/ServiceTargets.gen';
import KeyItems, { KeyItemsOrder } from 'src/constants/gens/KeyItems.gen';
import Awards, { AwardsOrder } from 'src/constants/gens/Awards.gen';

export const KEY_OF_OTHER = {
  ServiceTargets: '59',
  KeyItemsOptions: '32',
  Awards: '9',
  Seniority: '35'
};


class ConstantsStore {
  // 人員數
  staffOptions = [
    { value: 1, label: i18n.t('staff_1') },
    { value: 2, label: i18n.t('staff_2') },
    { value: 3, label: i18n.t('staff_3') },
    { value: 4, label: i18n.t('staff_4') },
    { value: 5, label: i18n.t('staff_5') },
    { value: 6, label: i18n.t('staff_6') },
    { value: 7, label: i18n.t('staff_7') },
    { value: 8, label: i18n.t('staff_8') }
  ];

  capitalAmount = [
    { value: '0', label: i18n.t('capitalAmount_0') },
    { value: '101', label: i18n.t('capitalAmount_101') },
    { value: '501', label: i18n.t('capitalAmount_501') },
    { value: '1001', label: i18n.t('capitalAmount_1001') },
    { value: '3001', label: i18n.t('capitalAmount_3001') },
    { value: '10000', label: i18n.t('capitalAmount_10000') }
  ];

  gender = [
    { value: 'male', label: i18n.t('gender_male') },
    { value: 'female', label: i18n.t('gender_female') },
    { value: 'others', label: i18n.t('gender_others') }
  ];

  openings = [
    { value: 'weekday', label: i18n.t('opening_weekday') },
    { value: 'weekend', label: i18n.t('opening_weekend') },
    { value: 'morning', label: i18n.t('opening_morning') },
    { value: 'afternoon', label: i18n.t('opening_afternoon') },
    { value: 'night', label: i18n.t('opening_night') }
  ];

  // 年資
  seniorityOptions = [
    { value: '0', label: i18n.t('seniority_0') },
    { value: '1', label: i18n.t('seniority_1') },
    { value: '3', label: i18n.t('seniority_3') },
    { value: '5', label: i18n.t('seniority_5') },
    { value: '10', label: i18n.t('seniority_10') },
    { value: '15', label: i18n.t('seniority_15') },
    { value: '20', label: i18n.t('seniority_20') },
    { value: '25', label: i18n.t('seniority_25') },
    { value: '35', label: i18n.t('seniority_35') }
  ];

  sdgsShowAtHP = [
    '1-1', '1-2', '1-3', '2-1', '2-2', '2-3', '3-2', '3-3', '3-4', '3-6'
  ];

  regionsShowAtHP = {
    1: ['1-1', '1-2', '1-3', '1-4', '1-5'],
    2: []
  };

  get language() {
    switch (i18n.language) {
      case 'zh-TW':
      case 'zh':
      case 'zh_CN':
      case 'zh-HK':
        return 'zh-TW';
      case 'en':
      case 'en-US':
      case 'en-UK':
        return 'en-US';
      default:
        // TODO: 未來改en
        return 'zh-TW';
    }
  }

  get equipsOptions() {
    const equips = Equipments[this.language];
    return Object.keys(equips)
      .sort((a, b) => EquipmentsOrder[a] - EquipmentsOrder[b])
      .map((k) => ({ value: k, label: equips[k] }));
  }

  get equipsOptionsForResource() { // 沒有「不指定」
    const equips = Equipments[this.language];
    return Object.keys(equips)
      .filter((key) => key !== '35')
      .sort((a, b) => EquipmentsOrder[a] - EquipmentsOrder[b])
      .map((k) => ({ value: k, label: equips[k] }));
  }

  get jobsOptions() {
    const jobs = Jobs[this.language];
    return Object.keys(jobs)
      .sort((a, b) => JobsOrder[a] - JobsOrder[b])
      .map((k) => ({ value: k, label: jobs[k] }));
  }

  get jobsOptionsForUser() { // 沒有「不分專業」
    const jobs = Jobs[this.language];
    return Object.keys(jobs)
      .filter((key) => key !== '24')
      .sort((a, b) => JobsOrder[a] - JobsOrder[b])
      .map((k) => ({ value: k, label: jobs[k] }));
  }

  get organizationTypesOptions() {
    const orgs = OrganizationTypes[this.language];
    return Object.keys(orgs)
      .sort((a, b) => OrganizationTypesOrder[a] - OrganizationTypesOrder[b])
      .map((k) => ({ value: k, label: orgs[k] }));
  }

  get fundsTypesOptions() {
    const funds = FundsTypes[this.language];
    return Object.keys(funds)
      .sort((a, b) => FundsTypesOrder[a] - FundsTypesOrder[b])
      .map((k) => ({ value: k, label: funds[k] }));
  }

  get usagesOptions() {
    const usages = Usages[this.language];
    return Object.keys(usages)
      .sort((a, b) => UsagesOrder[a] - UsagesOrder[b])
      .map((k) => ({ value: k, label: usages[k] }));
  }

  get serviceTargetsOptions() {
    const targets = ServiceTargets[this.language];
    return Object.keys(targets)
      .sort((a, b) => ServiceTargetsOrder[a] - ServiceTargetsOrder[b])
      .map((k) => ({ value: k, label: targets[k] }));
  }

  get keyItemsOptions() {
    const keyItems = KeyItems[this.language];
    return Object.keys(keyItems)
      .sort((a, b) => KeyItemsOrder[a] - KeyItemsOrder[b])
      .map((k) => ({ value: k, label: keyItems[k] }));
  }

  get regionsOptions() {
    const regions = Regions[this.language];
    return (Object.keys(regions).sort((a, b) => RegionsOrder[a] - RegionsOrder[b]).map((reg) => ({
      value: reg,
      label: regions[reg].name,
      children: Object.keys(regions[reg].area).sort((a, b) => RegionsOrder[a] - RegionsOrder[b]).map((ar) => ({
        value: ar,
        label: regions[reg].area[ar].name,
        children: Object.keys(regions[reg].area[ar].city).sort((a, b) => RegionsOrder[a] - RegionsOrder[b]).map((c) => ({
          value: c,
          label: regions[reg].area[ar].city[c]
        }))
      }))
    })));
  }

  get regionsForHP() { // without platform
    const regions = Regions[this.language];
    return (Object.keys(regions).sort((a, b) => RegionsOrder[a] - RegionsOrder[b]).map((reg) => {
      if (this.regionsShowAtHP[reg].length) {
        return {
          value: reg,
          label: regions[reg].name,
          children: Object.keys(regions[reg].area).sort((a, b) => RegionsOrder[a] - RegionsOrder[b]).map((ar) => ({
            value: ar,
            label: regions[reg].area[ar].name,
            children: Object.keys(regions[reg].area[ar].city).sort((a, b) => RegionsOrder[a] - RegionsOrder[b]).map((c) => ({
              value: c,
              label: regions[reg].area[ar].city[c]
            }))
          }))
        };
      }
      return null;
    }).filter((el) => !!el));
  }

  get sdgsOptions() {
    const sdgs = SDGs[this.language];
    return (Object.keys(sdgs).sort((a, b) => SDGsOrder[a] - SDGsOrder[b]).map((el) => ({
      value: el,
      label: sdgs[el].name,
      children: Object.keys(sdgs[el].childs).sort((a, b) => SDGsOrder[a] - SDGsOrder[b]).map((item) => ({
        value: item,
        label: sdgs[el].childs[item]
      }))
    })));
  }

  get sdgsForHP() {
    const sdgs = SDGs[this.language];
    return Object.keys(sdgs).map((el) =>
      ({
        value: el,
        label: sdgs[el].name,
        children: Object.keys(sdgs[el].childs).sort((a, b) => SDGsOrder[a] - SDGsOrder[b]).map((item) => {
          if (this.sdgsShowAtHP.includes(item)) {
            return {
              value: item,
              label: sdgs[el].childs[item]
            };
          }
          return null;
        }).filter((e) => !!e)
      }));
  }

  get serviceTypesOptions() {
    const servicesType = ServiceTypes[this.language];
    return (Object.keys(servicesType)
      .sort((a, b) => ServiceTypesOrder[a] - ServiceTypesOrder[b])
      .map((el) => ({
        value: el,
        label: servicesType[el].name,
        children: Object.keys(servicesType[el].childs).sort((a, b) => ServiceTypesOrder[a] - ServiceTypesOrder[b]).map((item) => ({
          value: item,
          label: servicesType[el].childs[item].name,
          tag: servicesType[el].childs[item].tag
        }))
      })));
  }

  get serviceTypesSelectOptions() {
    const servicesType = ServiceTypes[this.language];
    return (Object.keys(servicesType)
      .filter((obj) => obj !== '24')
      .sort((a, b) => ServiceTypesOrder[a] - ServiceTypesOrder[b])
      .map((el) => ({
        value: el,
        label: servicesType[el].name,
        options: Object.keys(servicesType[el].childs).sort((a, b) => ServiceTypesOrder[a] - ServiceTypesOrder[b]).map((item) => ({
          value: item,
          label: servicesType[el].childs[item].name
        }))
      })));
  }

  countryCodes = [
    {
      name: 'Taiwan',
      dial_code: '+886',
      code: 'TW'
    }
  ];

  get countryCodesOptions() {
    return this.countryCodes.map((e) => (
      { value: e.dial_code, label: `${e.code} ${e.dial_code}` }
    ));
  }

  get equipmentsOptions() {
    const equipments = Equipments[this.language];
    return Object.keys(equipments)
      .sort((a, b) => EquipmentsOrder[a] - EquipmentsOrder[b])
      .map((k) => ({ value: k, label: equipments[k] }));
  }

  get awardsOptions() {
    const awards = Awards[this.language];
    return Object.keys(awards)
      .sort((a, b) => AwardsOrder[a] - AwardsOrder[b])
      .map((k) => ({ value: k, label: awards[k] }));
  }

  /// ======================================== ///
  /// ======================================== ///
  /// ======================================== ///

  get flattenedRegionsOptions() {
    const regions = Regions[this.language];
    const map = new Map();
    Object.keys(regions).forEach((key) => {
      const childrenKeys = Object.keys(regions[key].area);

      childrenKeys.forEach((childrenKey) => {
        const grandChildrenKeys = Object.keys(regions[key].area[childrenKey].city);

        grandChildrenKeys.forEach((grandChildrenKey) => {
          map.set(grandChildrenKey, regions[key].area[childrenKey].city[grandChildrenKey]);
        });
      });
    });

    const flattened = Array.from(map).map((e) => {
      return ({
        value: e[0],
        label: e[1]
      });
    });

    return flattened;
  }

  get flattenedSdgsOptions() {
    const sdgs = SDGs[this.language];
    const map = new Map();

    Object.keys(sdgs).forEach((key) => {
      const childrenKeys = Object.keys(sdgs[key].childs);

      childrenKeys.forEach((childrenKey) => {
        map.set(childrenKey, sdgs[key].childs[childrenKey]);
      });
    });

    const flattened = Array.from(map).map((e) => {
      return ({
        value: e[0],
        label: e[1]
      });
    });

    return flattened;
  }

  getServiceTypeTag(value) {
    const tag = this.serviceTypesOptions?.find((el) => el.children.find((child) => child.value === value))
      ?.children.find((child) => child.value === value)?.tag;
    return tag ?? '-';
  }

  getCityLabel(value) {
    return this.flattenedRegionsOptions.find((opt) => opt.value === value)?.label ?? '';
  }

  // for ui
  getSdgTypeKey(label) {
    return this.sdgsOptions.find((opt) => opt.label === label)?.value ?? '';
  }

  convertedSDGs = (el) => {
    const arr = el.split('-');

    if (el.length === 3) {
      return SDGs[this.language][arr[0]]?.childs[el] ?? '-';
    }
    return SDGs[this.language][arr[0]]?.name ?? '-';
  };

  convertedSDGsValues = (el) => {
    const arr = el.split('-');
    if (el.length === 1) {
      return Object.keys(SDGs[this.language][arr[0]]?.childs);
    }
    return el;
  };

  getSdgLabelByValue = (value) => {
    return this.flattenedSdgsOptions.find((opt) => opt.value === value)?.label ?? '-';
  };

  getRegionLabelByValue = (value) => {
    if (!value) return '-';
    const cid = value.slice(0, 1);
    const aid = value.slice(0, 3);

    if (value.length === 5) {
      return Regions[this.language][cid]?.area[aid]?.city[value] ?? '-';
    }
    return Regions[this.language][cid]?.area[aid]?.name ?? '-';
  };

  // 1-2 -> ['1-2-1', '1-2-2', '1-2-3', '1-2-4', '1-2-5']
  reverseCombinedRegionValue = (string) => {
    const cid = string.slice(0, 1);
    const aid = string.slice(0, 3);
    if (string.length === 3) {
      return Object.keys(Regions[this.language][cid]?.area[aid]?.city);
    }
    return [string];
  };

  // ['1-1-1', '1-2-1', '1-2-2', '1-2-3', '1-2-4', '1-2-5'] -> ['1-1-1', '1-2']
  getCombinedRegionsValuesArr(regions) {
    const Obj = regions?.reduce((acc, val) => {
      const [key, value] = val.split('-').slice(0, 2);
      if (acc[`${key}-${value}`]) {
        acc[`${key}-${value}`].push(val);
      } else {
        acc[`${key}-${value}`] = [val];
      }
      return acc;
    }, {});

    const list = [];
    Object.entries(Obj).forEach((el) => {
      const key = el[0].slice(0, 1);
      if (Object.keys(Regions[this.language][key].area[el[0]].city).length === el[1].length) {
        return list.push(el[0]);
      }
      return list.push(...el[1]);
    });
    return list;
  }

  // ['1-1-1', '1-2-1', '1-2-2', '1-2-3', '1-2-4', '1-2-5'] -> '台北市 • 中部'
  getCombinedRegionsLabelString(regions) {
    let str = '';
    const combined = this.getCombinedRegionsValuesArr(regions);
    combined?.forEach((city, i) => {
      str += this.getRegionLabelByValue(city);
      if (i < combined.length - 1) {
        str += ' • ';
      }
    });
    return str;
  }

}

const Constants = new ConstantsStore();

export default Constants;
