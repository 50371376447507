import { request, getHost } from './utils';

export const constants = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/constants`,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  };
  return request(options);
};
