/* eslint-disable */
const ServiceTargets = {
  "zh-TW": {
    "1": "消費者",
    "2": "社會大眾",
    "3": "男性",
    "4": "女性",
    "5": "多元性別者",
    "6": "新生兒",
    "7": "孩子",
    "8": "青少年",
    "9": "青年",
    "10": "成年人",
    "11": "壯年人",
    "12": "婦女",
    "13": "中高齡者",
    "14": "高齡者",
    "15": "原住民",
    "16": "客家族群",
    "17": "閩南族群",
    "18": "外省族群",
    "19": "新住民",
    "20": "都市",
    "21": "鄉村",
    "22": "社區",
    "23": "偏遠地區",
    "24": "移工",
    "25": "外國人",
    "26": "移民",
    "27": "貧窮者",
    "28": "弱勢者",
    "29": "職業傷害者",
    "30": "就業者",
    "31": "求職者",
    "32": "雇主",
    "33": "失業者",
    "34": "性工作者",
    "35": "小家庭",
    "36": "隔代家庭",
    "37": "三代家庭",
    "38": "單親家庭",
    "39": "兩人家庭",
    "40": "孕產家庭",
    "41": "身心障礙者",
    "42": "疾病患者",
    "43": "事故傷者",
    "44": "海洋生態系",
    "45": "海洋動物",
    "46": "海洋植物",
    "47": "水環境",
    "48": "陸域生態系",
    "49": "實驗動物",
    "50": "農場動物",
    "51": "野生動物",
    "52": "同伴動物",
    "53": "展演動物",
    "54": "陸域植物",
    "55": "熟齡者",
    "56": "健康體系關係人",
    "57": "教育工作者",
    "58": "學習者",
    "59": "其他"
  },
  "en-US": {
    "1": "消費者",
    "2": "社會大眾",
    "3": "男性",
    "4": "女性",
    "5": "多元性別者",
    "6": "新生兒",
    "7": "孩子",
    "8": "青少年",
    "9": "青年",
    "10": "成年人",
    "11": "壯年人",
    "12": "婦女",
    "13": "中高齡者",
    "14": "高齡者",
    "15": "原住民",
    "16": "客家族群",
    "17": "閩南族群",
    "18": "外省族群",
    "19": "新住民",
    "20": "都市",
    "21": "鄉村",
    "22": "社區",
    "23": "偏遠地區",
    "24": "移工",
    "25": "外國人",
    "26": "移民",
    "27": "貧窮者",
    "28": "弱勢者",
    "29": "職業傷害者",
    "30": "就業者",
    "31": "求職者",
    "32": "雇主",
    "33": "失業者",
    "34": "性工作者",
    "35": "小家庭",
    "36": "隔代家庭",
    "37": "三代家庭",
    "38": "單親家庭",
    "39": "兩人家庭",
    "40": "孕產家庭",
    "41": "身心障礙者",
    "42": "疾病患者",
    "43": "事故傷者",
    "44": "海洋生態系",
    "45": "海洋動物",
    "46": "海洋植物",
    "47": "水環境",
    "48": "陸域生態系",
    "49": "實驗動物",
    "50": "農場動物",
    "51": "野生動物",
    "52": "同伴動物",
    "53": "展演動物",
    "54": "陸域植物",
    "55": "熟齡者",
    "56": "健康體系關係人",
    "57": "教育工作者",
    "58": "學習者",
    "59": "其他"
  }
}

export const ServiceTargetsOrder = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "13": 13,
  "14": 14,
  "15": 16,
  "16": 17,
  "17": 18,
  "18": 19,
  "19": 20,
  "20": 21,
  "21": 22,
  "22": 23,
  "23": 24,
  "24": 25,
  "25": 26,
  "26": 27,
  "27": 28,
  "28": 29,
  "29": 32,
  "30": 33,
  "31": 34,
  "32": 35,
  "33": 36,
  "34": 37,
  "35": 38,
  "36": 39,
  "37": 40,
  "38": 41,
  "39": 42,
  "40": 44,
  "41": 45,
  "42": 46,
  "43": 47,
  "44": 48,
  "45": 49,
  "46": 50,
  "47": 51,
  "48": 52,
  "49": 53,
  "50": 54,
  "51": 55,
  "52": 56,
  "53": 57,
  "54": 58,
  "55": 15,
  "56": 43,
  "57": 30,
  "58": 31,
  "59": 999
}

export default ServiceTargets
