import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';
import UploadResourcePhotoVM from 'src/components/UploadPhoto/resourceVM';
import { RESOURCE_TYPES, RESOURCE_MODE } from 'src/constants';
import ErrorService from 'src/services/errors';
import ResourceService from 'src/services/resource';
import store from 'src/stores/constants';
import IS from 'src/utils/is';

class MyResourceFormPageViewModel {

    @observable isAwait = false;

    @observable isInit = false;
    @observable showError = false;
    @observable mode = RESOURCE_MODE.Add; // add | edit
    @observable type = RESOURCE_TYPES.Service; // service | funds | space
    @observable step = 1; // 1 | 2 | 3 | 4
    @observable data = {
      creations: [{ url: '', name: '' }],
      certs: [{ url: '', name: '' }],
      links: [{ url: '', name: '' }]
    };

    sdgsSelectViewModel = new TreeSelectViewModel(store.sdgsOptions);
    regionsSelectViewModel = new TreeSelectViewModel(store.regionsOptions);
    jobsSelectViewModel = new TreeSelectViewModel(store.jobsOptionsForUser);
    usagesSelectViewModel = new TreeSelectViewModel(store.usagesOptions);
    equipmentsSelectViewModel = new TreeSelectViewModel(store.equipsOptionsForResource);
    openingsSelectViewModel = new TreeSelectViewModel(store.openings);

    // photos VM
    photosVM = new UploadResourcePhotoVM();

    @computed get postData() {
      const sdgs = this.sdgsSelectViewModel.selectedItems?.map((el) => el.value);
      const regions = this.regionsSelectViewModel.selectedItems?.map((el) => el.value);
      const jobs = this.jobsSelectViewModel.selectedItems?.map((el) => el.value);
      const usages = this.usagesSelectViewModel.selectedItems?.map((el) => el.value);
      const equipments = this.equipmentsSelectViewModel.selectedItems?.map((el) => el.value);
      const openings = this.openingsSelectViewModel.selectedItems?.map((el) => el.value);

      const links = this.data.links?.filter((el) => IS.url(el.url) && !!el.name);
      const certs = this.data.certs?.filter((el) => IS.url(el.url) && !!el.name);
      const creations = this.data.creations?.filter((el) => IS.url(el.url) && !!el.name);

      return {
        ...this.data,
        sdgs,
        regions,
        jobs: this.type === RESOURCE_TYPES.Service ? jobs : undefined,
        usages: this.type === RESOURCE_TYPES.Space ? usages : undefined,
        equipments: this.type === RESOURCE_TYPES.Space ? equipments : undefined,
        openings: this.type === RESOURCE_TYPES.Space ? openings : undefined,
        links: this.type === RESOURCE_TYPES.Funds ? links : undefined,
        certs: this.type === RESOURCE_TYPES.Service ? certs : undefined,
        creations: this.type === RESOURCE_TYPES.Service ? creations : undefined
      };
    }


    // column validation

    @computed get isNameValid() {
      return this.data.name?.length > 0 && this.data.name?.length <= 15;
    }

    @computed get isClaimValid() {
      return this.data.claim?.length > 0 && this.data.claim?.length <= 30;
    }

    @computed get isExpectanceValid() {
      return this.data.expectance?.length > 0 && this.data.expectance?.length <= 300;
    }

    @computed get isDescriptionValid() {
      return !this.data.description || this.data.description?.length <= 300;
    }

    @computed get isSpaceNameValid() {
      return this.data.name?.length > 0 && this.data.name?.length <= 20;
    }

    @computed get isQuotaValid() {
      const n = Number(this.data.quota);
      return !!n && n > 0 && n < 999999999999;
    }

    @computed get isAddressValid() {
      return this.data.address?.length > 0 && this.data.address?.length <= 300;
    }

    @computed get isSizeValid() {
      const n = Number(this.data.size);
      return !!n && n > 0 && n < 10000000;
    }

    @computed get isCapacityValid() {
      const n = Number(this.data.capacity);
      return !!n && n > 0 && n < 100000000000 && IS.int(n);
    }

    @computed get isLinksValid() {
      const linkValidation = (el) => {
        if (!el.name && !el.url) return true;
        if (!!el.name && IS.url(el.url)) return true;
        return false;
      };

      switch (this.type) {
        case RESOURCE_TYPES.Service:
          return this.data.certs.every(linkValidation) && this.data.creations.every(linkValidation);
        case RESOURCE_TYPES.Funds:
          return this.data.links.every(linkValidation);
        default:
          return true;
      }
    }


    constructor(props) {
      makeObservable(this);
      this.props = props;
    }

    get stepVerified() {
      if (this.step === 1) {
        switch (this.type) {
          case RESOURCE_TYPES.Service:
            return (
              this.isNameValid
              && this.data.type
              && this.isClaimValid
              && !!this.jobsSelectViewModel.selectedItems?.length
              && !!this.sdgsSelectViewModel.selectedItems?.length
              && !!this.regionsSelectViewModel.selectedItems?.length
            );
          case RESOURCE_TYPES.Funds:
            return (
              this.isNameValid
              && this.isQuotaValid
              && this.data.type
              && this.isClaimValid
              && !!this.sdgsSelectViewModel.selectedItems?.length
              && !!this.regionsSelectViewModel.selectedItems?.length
            );
          case RESOURCE_TYPES.Space:
          default:
            return (
              this.isSpaceNameValid
              && this.isAddressValid
              && this.isSizeValid
              && this.isCapacityValid
              && this.isClaimValid
              && !!this.usagesSelectViewModel.selectedItems?.length
              && !!this.sdgsSelectViewModel.selectedItems?.length
              && !!this.regionsSelectViewModel.selectedItems?.length
              && !!this.equipmentsSelectViewModel.selectedItems?.length
              && !!this.openingsSelectViewModel.selectedItems?.length
              && !!this.photosVM.fileList.length

            );
        }
      } else if (this.step === 2) {
        return this.isExpectanceValid && this.isLinksValid;
      } else {
        return this.isDescriptionValid;
      }
    }

    @action didMount = async (props) => {
      console.log('MyResourcePage.didMount, params', props.router.params);
      console.log(props);
      this.isMobile = props.context.state.isMobile;
      this.profile = props.profile;
      this.type = props.router.location?.state?.type ?? RESOURCE_TYPES.Service;

      // EDIT DATA
      const { state } = props.router.location;
      if (state?.data) {
        this.mode = RESOURCE_MODE.Edit;
        this.deserialize(state.data);
      }
      this.isInit = true;

      this.scollToTop();
    };

    @action deserialize = (data) => {

      console.log('data', data);
      const { meta, ...ps } = data;

      this.data = {
        ...ps,
        ...meta
      };

      // update tree select with certain key
      switch (this.type) {
        case RESOURCE_TYPES.Service:
          this.jobsSelectViewModel.initCheckState(this.data.jobs);
          if (!this.data.certs.length) {
            this.data.certs = [{ url: '', name: '' }];
          }
          if (!this.data.creations.length) {
            this.data.creations = [{ url: '', name: '' }];
          }
          break;
        case RESOURCE_TYPES.Funds:
          if (!this.data.links.length) {
            this.data.links = [{ url: '', name: '' }];
          }
          break;
        case RESOURCE_TYPES.Space:
          this.equipmentsSelectViewModel.initCheckState(this.data.equipments);
          this.usagesSelectViewModel.initCheckState(this.data.usages);
          this.openingsSelectViewModel.initCheckState(this.data.openings);
          break;
        default:
      }
      this.sdgsSelectViewModel.initCheckState(this.data.sdgs);
      this.regionsSelectViewModel.initCheckState(this.data.regions);
      this.photosVM.onFileListUpdate(this.data.photos);
    };


    @action nextStep = () => {
      if (this.stepVerified) {
        this.showError = false;
        if (this.step === 3) {
          if (this.mode === RESOURCE_MODE.Add) {
            this.createResource();
          } else {
            this.updateResource();
          }
        } else {
          this.step += 1;
          this.scollToTop();
        }
      } else {
        this.showError = true;
      }
    };

    @action backStep = () => {
      this.step -= 1;
      this.scollToTop();
    };

    scollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    @action onDataChange = (field, value) => {
      this.data = { ...this.data, [`${field}`]: value };
      console.log('data', JSON.stringify(this.data, null, 2));
    };

    @action updatePhotos = (list) => {
      this.data.photos = list;
      console.log('data', JSON.stringify(this.data, null, 2));

    };

    @action onLinksChange = (key, value, col, index) => {
      const el = this.data[col][index];
      this.data[col][index] = { ...el, [key]: value };
    };

    @action onLinksAdd = (key) => {
      this.data[key].push({
        name: '',
        url: ''
      });
    };

    @action createResource = async () => {
      this.isAwait = true;

      try {
        // create
        const id = await ResourceService.create(this.type, this.postData);
        // upload photo
        await this.photosVM.onPhotosUpload(id);
        const photos = this.photosVM.postList;
        // update
        const res = await ResourceService.update(id, { photos: photos.length ? photos : [] });
        // update active
        await ResourceService.updateActive(id, true);

        runInAction(() => {
          this.step = 4;
          this.result = res.data;
        });
      } catch (error) {
        ErrorService.onDefaultError(error);
        console.log(error);
      } finally {
        runInAction(() => {
          setTimeout(() => {
            this.isAwait = false;
          }, 500);
        });
      }
    };

    @action updateResource = async () => {
      this.isAwait = true;

      try {
        // upload photo
        await this.photosVM.onPhotosUpload(this.data.id);
        const photos = this.photosVM.postList;
        // update
        const res = await ResourceService.update(this.data.id, { ...this.postData, ...{ photos: photos.length ? photos : [] } });
        runInAction(() => {
          this.step = 4;
          this.result = res.data;
        });
      } catch (error) {
        ErrorService.onDefaultError(error);
        console.log(error);
      } finally {
        runInAction(() => {
          setTimeout(() => {
            this.isAwait = false;
          }, 500);
        });
      }
    };

    toMyResource = () => {
      const { navigate } = this.props.router;
      navigate('/user/my-resource');
    };

    toEventList = () => {
      const { navigate } = this.props.router;
      navigate('/events', {
        state: {
          filter: {
            type: this.result.type,
            sdgs: this.result.meta?.sdgs,
            regions: this.result.meta?.regions
          }
        }
      });
    };

}

export default MyResourceFormPageViewModel;

