import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import { t } from 'i18next';
import DemandService from 'src/services/demand';
import EventService from 'src/services/event';
import { RESOURCE_TYPES, TABLE_LIMIT } from 'src/constants';
import ErrorService from 'src/services/errors';
import tabsState from 'src/stores/tabsState';
import UserService from 'src/services/user';

class EventDemandsPageViewModel {
  @observable userId;
  @observable eventId;
  @observable initialEvent;
  @observable demands = [];
  @observable service = [];
  @observable funds = [];
  @observable space = [];

  @observable initialIndex = 0;
  @observable hasRecommendResource = false;

  @observable isMobile = false;
  @observable isRecommendPopupOpen = true;

  // for event carousel
  @observable anchor = null;
  @observable isAwait = false;
  @observable list = [];
  @observable filter;

  constructor(props) {
    makeObservable(this);
    this.props = props;
    console.log(props);
  }

  @computed get currentTab() {
    return tabsState.eventDemandsTab;
  }

  @action didMount = async (props) => {
    console.log('EventDemandsPage.didMount, params', props.router.params);
    window.scroll(0, 0);
    await this.init(props);
  };

  @action didUpdate = async (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile)) {
      await this.init(props);
    }
  };

  @action init = async (props) => {
    const { router, context, profile } = props;
    this.eventId = router.params.eid;
    this.isMobile = context.state.isMobile;
    this.userId = profile.id;
    this.filter = router.location?.state?.filter ? JSON.parse(router.location.state.filter) : {};

    await this.getEventDetail();
    await Promise.all([
      this.getMyEvents(),
      this.getDemands()
    ]);
    await this.checkIfHasRecommendResource();

  };

  @computed get myEvents() {
    return this.list;
  }

  getEventDetail = async () => {
    try {
      const res = await EventService.detail(this.eventId);
      runInAction(() => {
        this.initialEvent = res;
      });

    } catch (error) {
      const { navigate } = this.props.router;
      switch (error.response?.status) {
        case 401:
          ErrorService.onCustomError(
            t('general_error_content_401'),
            null,
            () => navigate(0)
          );
          break;
        case 403:
        case 404:
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  @action getMyEvents = async (isFirstFetch = true) => {
    if (this.isAwait) { return; }

    const params = {
      limit: TABLE_LIMIT,
      anchor: isFirstFetch ? null : this.anchor
    };

    this.isAwait = true;

    if (isFirstFetch) {
      if (this.initialEvent) {
        this.list = [this.initialEvent]; // the initial event is always at the begining of the list
      } else {
        this.list = [];
      }
    }

    try {
      const { count, list, anchor } = await UserService.createdEvents(this.userId, params, this.filter);
      runInAction(() => {
        const filtered = list.filter((item) => item.id !== this.initialEvent?.id);
        this.anchor = anchor;
        this.list.push(...filtered);
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action onEventScrollEnd = async () => {
    if (this.isAwait || !this.anchor) { return; }
    await this.getMyEvents(false);
  };

  // @action findInitialIndex = async () => {
  //   this.initialIndex = this.myEvents.findIndex((item) => item.id === this.eventId);

  //   if (this.initialIndex === 0) {
  //     return;
  //   }

  //   if (this.initialIndex > -1) {
  //     await this.onEventSwiperChange({ realIndex: this.initialIndex, isEnd: false });

  //   } else if (this.eventsAnchoredList.anchor && !this.eventsAnchoredList.isAwait) {
  //     await this.eventsAnchoredList.getNext();
  //     this.findInitialIndex();
  //   }
  // };

  getDemands = async () => {
    try {
      const res = await Promise.all(
        Object.values(RESOURCE_TYPES).map(async (type) => {
          const dres = await DemandService.getList(type, this.eventId);
          runInAction(() => {
            this[type] = dres;
          });
          return dres;
        })
      );
      runInAction(() => {
        this.demands = [].concat(...res);
      });
    } catch (error) {
      switch (error?.response?.status) {
        case 403:
        case 404:
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  checkIfHasRecommendResource = async () => {
    try {
      const { list } = await EventService.recommendResourcesList(this.eventId, { limit: 1 });
      runInAction(() => {
        this.hasRecommendResource = list?.length !== 0;
        if (this.hasRecommendResource) {
          this.isRecommendPopupOpen = true;
        }
      });
    } catch (error) {
      const { navigate } = this.props.router;
      switch (error?.response?.status) {
        case 403:
        case 404:
          if (this.props?.profile.isEventCreator) {
            runInAction(() => {
              this.initialEvent = null;
            });
            await this.getMyEvents(true);
            ErrorService.onCustomError(
              error?.response?.status === 403 ? t('error_create_event_detail_403') : t('error_create_event_detail_404')
            );
            this.onEventSwiperChange({ realIndex: 0, isEnd: false });

          } else {
            ErrorService.onCustomError(
              t('error_create_event_detail_403'),
              null,
              () => navigate('/')
            );
          }
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  @action switchTab = (value) => {
    tabsState.updateEventDemandsTab(value);
  };

  toApplications = (did) => {
    const { navigate } = this.props.router;
    navigate(`/user/event-hosted/${this.eventId}/${did}/applications`);
  };

  toRecommendedResources = () => {
    const { navigate } = this.props.router;
    navigate(`/user/event-hosted/${this.eventId}/recommend`, { state: { filter: JSON.stringify(this.filter) } });
  };

  toEventHosted = () => {
    const { navigate } = this.props.router;
    navigate('/user/event-hosted');
  };

  @action onEventSwiperChange = async ({ realIndex, isEnd }) => {
    console.log('on event swiper change', realIndex, this.initialIndex, this.myEvents, this.myEvents.length, this.myEvents[0]);
    this.initialIndex = realIndex;
    this.eventId = this.myEvents[realIndex]?.id ?? this.eventId;
    this.props.router.navigate(`/user/event-hosted/${this.eventId}/demands`, { replace: true });
    await this.getDemands();
    await this.checkIfHasRecommendResource(true);

    if (isEnd) {
      await this.onEventScrollEnd();
    }
  };

  @action closeRecommendPopup = (e) => {
    e.stopPropagation();
    this.isRecommendPopupOpen = false;
  };

}

export default EventDemandsPageViewModel;
