import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import illustrationCongrats from 'src/assets/illustrationCongrats.svg';
import illustrationGreenCheck from 'src/assets/illustrationGreenCheck.svg';
import illustrationPhone from 'src/assets/illustrationPhone.svg';

import styles from './styles.module.scss';
import Button from '../Button';

const illustrations = {
  congrats: illustrationCongrats,
  greenCheck: illustrationGreenCheck,
  phone: illustrationPhone
};


function Success(props) {
  const {
    title,
    texts,
    textNewLine,
    buttonText,
    button2Text,
    onClickButton,
    onClickButton2,
    bottomText,
    bottomLinkHref,
    bottomLinkText,
    containerStyle,
    illustration
  } = props;

  return (
    <div className={clsx(styles.successContainer)} style={containerStyle}>
      <div className={styles.wrapper}>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {title}
          </h2>

          <h3>
            {texts.map((text, i) => (
              <span className={clsx(styles.subtitle, i === 1 && styles.purple)} key={text}>
                {text}
              </span>
            ))}
          </h3>
          {textNewLine && (
            <h3 className={clsx(styles.newLine)}>
              {textNewLine}
            </h3>
          )}
        </div>

        <div className={styles.imgContainer}>
          <img
            alt=""
            src={illustrations[illustration]}
            className={styles.mainImg}
          />
        </div>
        <div className={styles.bottomContainer}>
          <Button
            onClick={onClickButton}
            className={styles.nextButton}
          >
            {buttonText}
          </Button>

          {button2Text && (
            <Button
              onClick={onClickButton2}
              className={styles.button2}
            >
              {button2Text}
            </Button>
          )}

          <div className={styles.bottomText}>
            <span className={styles.text}>
              {bottomText}
            </span>
            <Link to={bottomLinkHref}>
              <span className={clsx(styles.link)}>
                {bottomLinkText}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Success.propTypes = {
  title: PropTypes.string,
  texts: PropTypes.array,
  textNewLine: PropTypes.string,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func,
  button2Text: PropTypes.string,
  onClickButton2: PropTypes.func,
  bottomText: PropTypes.string,
  bottomLinkText: PropTypes.string,
  bottomLinkHref: PropTypes.string,
  containerStyle: PropTypes.object,
  illustration: PropTypes.string
};

Success.defaultProps = {
  title: [],
  texts: '',
  textNewLine: null,
  buttonText: '',
  onClickButton: () => {},
  button2Text: '',
  onClickButton2: () => {},
  bottomText: '',
  bottomLinkText: '',
  bottomLinkHref: '',
  containerStyle: {},
  illustration: 'congrats'
};

export default Success;
