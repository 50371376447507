import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import ConstantsStore from 'src/stores/constants';
import { TERMS_AND_POLICY_TABS, USER_TYPES } from 'src/constants';

import withErrorInput from 'src/components/withErrorInput';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import TreeSelect from 'src/components/TreeSelect';
import DatePicker from 'src/components/DatePicker';
import Button from 'src/components/Button';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);
const ErrorDatePicker = withErrorInput(DatePicker);
const ErrorTreeSelect = withErrorInput(TreeSelect);

@observer
class StepDetail extends React.Component {
  constructor(props) {
    super(props);
    this.vm = this.props.vm;
  }

  renderInput(field) {
    const props = {
      showError: this.vm.showError && !!this.vm.registrationRequires[field],
      required: !!this.vm.registrationRequires[field]
    };
    switch (field) {
      case 'jobs':
        if (this.vm.identity !== USER_TYPES.Personal) return null;
        return (
          <ErrorTreeSelect
            key="jobs"
            showError={this.vm.showError}
            placeholder={t('register_personal_step1_job')}
            type="jobs"
            showSearch
            vm={this.vm.jobsSelectViewModel}
            value={this.vm.jobsSelectViewModel.selectedItems}
            required
          />
        );
      case 'company':
      case 'jobTitle':
        if (this.vm.identity !== USER_TYPES.Personal) return null;
        return (
          <ErrorTextInput
            key={field}
            label={t(`register_personal_step1_${field}`)}
            onChange={(e) => this.vm.onChange(field, e.target.value)}
            value={this.vm[field]}
            {...props}
          />
        );
      case 'birthday':
        if (this.vm.identity !== USER_TYPES.Personal) return null;
        return (
          <ErrorDatePicker
            key="birthday"
            label={t('profile_info_birthday')}
            placeholder={t('profile_info_birthday')}
            onChange={(date, dateString) => {
              this.vm.onChange('birthday', date ? dayjs(date).toISOString() : null);
            }}
            style={{ width: '100%' }}
            value={this.vm.birthday ? dayjs(this.vm.birthday) : null}
            isMobile={this.vm.isMobile}
            {...props}
          />
        );
      case 'gender':
        if (this.vm.identity !== USER_TYPES.Personal) return null;
        return (
          <ErrorSelect
            key="gender"
            placeholder={t('profile_info_gender')}
            onChange={(value) => this.vm.onChange('gender', value)}
            options={ConstantsStore.gender}
            value={this.vm.gender}
            {...props}
          />
        );
      case 'cityOfBirth':
        if (this.vm.identity !== USER_TYPES.Personal) return null;
        return (
          <ErrorSelect
            key="cityOfBirth"
            placeholder={t('profile_info_cityOfBirth')}
            onChange={(value) => this.vm.onChange('cityOfBirth', value)}
            style={{ flexGrow: 1 }}
            options={ConstantsStore.flattenedRegionsOptions.filter((item) => item.value.slice(0, 1) !== '2')}
            value={this.vm.cityOfBirth}
            {...props}
          />
        );
      case 'cityOfResidence':
        if (this.vm.identity !== USER_TYPES.Personal) return null;
        return (
          <ErrorSelect
            key="cityOfResidence"
            placeholder={t('profile_info_cityOfResidence')}
            onChange={(value) => this.vm.onChange('cityOfResidence', value)}
            style={{ flexGrow: 1 }}
            options={ConstantsStore.flattenedRegionsOptions.filter((item) => item.value.slice(0, 1) !== '2')}
            value={this.vm.cityOfResidence}
            {...props}
          />
        );
      // organization
      case 'job':
        if (this.vm.identity !== USER_TYPES.Organization) return null;
        return (
          <ErrorSelect
            key="job"
            placeholder={t('register_organization_step2_industry')}
            onChange={(value) => this.vm.onChange('job', value)}
            showSearch
            options={ConstantsStore.jobsOptionsForUser}
            value={this.vm.job}
            {...props}
          />
        );
      case 'type':
        if (this.vm.identity !== USER_TYPES.Organization) return null;
        return (
          <ErrorSelect
            key="type"
            placeholder={t('register_organization_step2_type')}
            onChange={(value) => this.vm.onChange('type', value)}
            options={ConstantsStore.organizationTypesOptions}
            value={this.vm.type}
            {...props}
          />
        );
      case 'location':
        if (this.vm.identity !== USER_TYPES.Organization) return null;
        return (
          <ErrorSelect
            key="location"
            placeholder={t('register_organization_step2_location')}
            onChange={(value) => this.vm.onChange('location', value)}
            options={ConstantsStore.flattenedRegionsOptions.filter((item) => item.value.slice(0, 1) !== '2')}
            value={this.vm.location}
            {...props}
          />
        );
      case 'foundedIn':
        if (this.vm.identity !== USER_TYPES.Organization) return null;
        return (
          <ErrorDatePicker
            key="foundedIn"
            label={t('register_organization_step2_foundedIn')}
            placeholder={t('register_organization_step2_foundedIn')}
            onChange={(date, dateString) => {
              this.vm.onChange('foundedIn', date ? dayjs(date).toISOString() : null);
            }}
            style={{ width: '100%' }}
            value={this.vm.foundedIn ? dayjs(this.vm.foundedIn) : null}
            isMobile={this.vm.isMobile}
            {...props}
          />
        );
      // 想貢獻地區
      case 'cities':
        return (
          <ErrorTreeSelect
            key="cities"
            showError={this.vm.showError}
            placeholder={t('profile_cities_label')}
            type="regions"
            showSearch
            vm={this.vm.citiesSelectViewModel}
            value={this.vm.citiesSelectViewModel.selectedItems}
            required
          />
        );
      // socials
      case 'line':
        return (
          <ErrorTextInput
            key={field}
            label={t(`${field}_placeholder`)}
            onChange={(e) => this.vm.onChange('lineId', e.target.value)}
            value={this.vm.lineId}
          />
        );
      case 'facebook':
      case 'instagram':
      case 'linkedin':
        return (
          <ErrorTextInput
            key={field}
            label={t(`${field}_placeholder`)}
            onChange={(e) => this.vm.onChange(field, e.target.value)}
            value={this.vm[field]}
            showError={this.vm.showError && !this.vm[`is${field.charAt(0).toUpperCase() + field.slice(1)}Valid`]}
            validValue={this.vm[`is${field.charAt(0).toUpperCase() + field.slice(1)}Valid`]}
            errorMessage={t('url_is_not_valid')}
          />
        );
      case 'website':
      case 'influence':
      case 'youtube':
        if (this.vm.identity !== USER_TYPES.Organization) return null;
        return (
          <ErrorTextInput
            key={field}
            label={t(`${field}_placeholder`)}
            onChange={(e) => this.vm.onChange(field, e.target.value)}
            value={this.vm[field]}
            showError={this.vm.showError && !this.vm[`is${field.charAt(0).toUpperCase() + field.slice(1)}Valid`]}
            validValue={this.vm[`is${field.charAt(0).toUpperCase() + field.slice(1)}Valid`]}
            errorMessage={t('url_is_not_valid')}
          />
        );
      default:
        return null;
    }
  }

  renderInfo() {

    return (
      <div className={styles.inputsColumn}>
        <ErrorTextInput
          key="displayName"
          label={t('profile_personal_displayName')}
          onChange={(e) => this.vm.onChange('displayName', e.target.value)}
          value={this.vm.displayName}
          showError={this.vm.showError}
          required
        />

        <ErrorTextInput
          key="email"
          label={t('signup_email_label')}
          onChange={(e) => this.vm.onChange('email', e.target.value)}
          value={this.vm.email}
          required
          validValue={this.vm.isEmailValid}
          showError={this.vm.showError}
          errorMessage={this.vm.email ? t('register_emailWrongFormat') : t('this_is_required')}
        />

        <div className={styles.phoneArea}>
          <div className={clsx(styles.countryCode)}>
            <ErrorSelect
              placeholder={t('register_validate_personal_step2_countryCodePlaceholder')}
              value={this.vm.countryCode}
              showSearch
              onChange={(value) => this.vm.onChange('countryCode', value)}
              options={ConstantsStore.countryCodesOptions}
              showError={this.vm.showError}
              validValue={this.vm.isCountryCodeValid}
              required
              errorMessage={this.vm.countryCode ? t('format_is_wrong') : t('this_is_required')}
            />
          </div>

          <div style={{ width: 15 }} />

          <ErrorTextInput
            type="number"
            key="phone 9 digit"
            label={t('register_validate_personal_step2_phoneInputPlaceholder')}
            sx={{
              flexGrow: 1
            }}
            onChange={(e) => this.vm.onChange('phone9Digit', e.target.value)}
            value={this.vm.phone9Digit ?? ''}
            validValue={this.vm.isPhoneValid}
            showError={this.vm.showError}
            required
            errorMessage={this.vm.phone9Digit ? t('format_is_wrong') : t('this_is_required')}
          />
        </div>
        <div className={styles.inputsColumn}>
          {this.vm.customFields?.map((field) => this.renderInput(field))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.stepDetail}>
        <div className={styles.inputsContainer}>
          {this.renderInfo()}
        </div>

        <div style={{ flexGrow: 1 }} />

        <div className={clsx(styles.flexCol, styles.bottomContainer)}>
          <Button
            onClick={this.vm.onSubmit}
            loading={this.vm.isLoading}
            className={styles.button}
            disabled={!this.vm.did || !this.vm.rid}
          >
            {t('signup_submit')}
          </Button>

          <div className={styles.bottomText}>
            <div>
              <span className={styles.text}>
                {`${t('register_agreement')} `}
              </span>
              <Link
                className={styles.link}
                to="/terms-and-policy#terms"
                state={{ tab: TERMS_AND_POLICY_TABS.Terms }}
                target="_blank"
              >
                {t('terms_of_use')}
              </Link>
              <span className={styles.text}>{` ${t('and')} `}</span>
              <Link
                className={styles.link}
                to="/terms-and-policy#policy"
                state={{ tab: TERMS_AND_POLICY_TABS.Policy }}
                target="_blank"
              >
                {t('privacy_poliicy')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StepDetail;

StepDetail.propTypes = {
  vm: PropTypes.object
};

StepDetail.defaultProps = {
  vm: {}
};
