import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import IS from 'src/utils/is';

class Contact {
  @observable name;
  @observable phone;
  @observable email;
  @observable jobTitle;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @action deserialize = (data) => {
    const {
      name,
      phone,
      email,
      jobTitle
    } = data ?? {};

    this.name = name;
    this.phone = phone;
    this.email = email;
    this.jobTitle = jobTitle;
  };

  serialize = () => {
    const data = {
      name: this.name,
      phone: this.phone,
      email: this.email,
      jobTitle: this.jobTitle
    };

    return data;
  };


  static fromRes(data) {
    return new Contact(data);
  }

  @computed get isComplete() {
    return !!this.name
     && !!this.phone
     && this.isPhoneValid
     && !!this.email
     && this.isEmailValid
     && !!this.jobTitle;
  }

  @computed get isEmpty() {
    return !this.name
     && !this.phone
     && !this.email
     && !this.jobTitle;
  }

  @computed get isEmailValid() {
    return IS.email(this.email);
  }

  @computed get isPhoneValid() {
    return IS.phone(this.phone);
  }
}

export default Contact;
