import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { USER_TYPES } from 'src/constants';
import { t } from 'i18next';
import Button from 'src/components/Button';
import RegionBubble from 'src/components/Bubble/RegionBubble';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import styles from '../../styles.module.scss';

@observer
class Region extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <>
        <div className={styles.interestsTitles}>
          <h2 className={styles.title}>
            {t('register_regionInterested_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('register_regionInterested_subtitle')}
          </h3>
        </div>
        <RegionBubble vm={this.vm} containerClassName={styles.bubbleOptionsContainer} />
        <div className={clsx(styles.bottomContainer, styles.buttonsRow, styles.twoButtons)}>
          <Button
            onClick={this.vm.onPreviousClick}
            style={{ width: '100%' }}
            className={styles.nextButton}
            type="default"
            withLeftArrow
          >
            {t('previous_step')}
          </Button>
          <Button
            onClick={this.vm.onFinishCities}
            style={{ width: '100%' }}
            disabled={this.vm.cities.length === 0}
            className={styles.nextButton}
            loading={this.vm.isLoading}
            withRightArrow
          >
            {this.vm.identity === USER_TYPES.Personal ? t('register_regionInterested_next') : t('finish')}
          </Button>
        </div>
      </>
    );
  }
}

Region.propTypes = {
  vm: PropTypes.object
};

Region.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Region)), false);
