/* eslint-disable */
const Regions = {
  "zh-TW": {
    "1": {
      "name": "台灣",
      "area": {
        "1-1": {
          "name": "北部",
          "city": {
            "1-1-1": "台北市",
            "1-1-2": "新北市",
            "1-1-3": "宜蘭縣",
            "1-1-4": "基隆市",
            "1-1-5": "桃園市",
            "1-1-6": "新竹市",
            "1-1-7": "新竹縣"
          }
        },
        "1-2": {
          "name": "中部",
          "city": {
            "1-2-1": "苗栗縣",
            "1-2-2": "台中市",
            "1-2-3": "彰化縣",
            "1-2-4": "南投縣",
            "1-2-5": "雲林縣"
          }
        },
        "1-3": {
          "name": "南部",
          "city": {
            "1-3-1": "嘉義市",
            "1-3-2": "嘉義縣",
            "1-3-3": "台南市",
            "1-3-4": "高雄市",
            "1-3-5": "屏東縣"
          }
        },
        "1-4": {
          "name": "東部",
          "city": {
            "1-4-1": "花蓮縣",
            "1-4-2": "台東縣"
          }
        },
        "1-5": {
          "name": "離島",
          "city": {
            "1-5-1": "澎湖縣",
            "1-5-2": "連江縣",
            "1-5-3": "金門縣"
          }
        }
      }
    },
    "2": {
      "name": "通用",
      "area": {
        "2-1": {
          "name": "平台",
          "city": {
            "2-1-1": "線上"
          }
        }
      }
    }
  },
  "en-US": {
    "1": {
      "name": "Taiwan",
      "area": {
        "1-1": {
          "name": "North",
          "city": {
            "1-1-1": "Taipei City",
            "1-1-2": "New Taipei City",
            "1-1-3": "Yilan County",
            "1-1-4": "Keelung City",
            "1-1-5": "Taoyuan City",
            "1-1-6": "Hsinchu City",
            "1-1-7": "Hsinchu County"
          }
        },
        "1-2": {
          "name": "Central",
          "city": {
            "1-2-1": "Miaoli County",
            "1-2-2": "Taichung City",
            "1-2-3": "Changhua County",
            "1-2-4": "Nantou County",
            "1-2-5": "Yunlin County"
          }
        },
        "1-3": {
          "name": "South",
          "city": {
            "1-3-1": "Chiayi City",
            "1-3-2": "Chiayi County",
            "1-3-3": "Tainan City",
            "1-3-4": "Kaohsiung City",
            "1-3-5": "Pingtung City"
          }
        },
        "1-4": {
          "name": "East",
          "city": {
            "1-4-1": "Hualien County",
            "1-4-2": "Taitung County"
          }
        },
        "1-5": {
          "name": "Outlying Islands",
          "city": {
            "1-5-1": "Penghu County",
            "1-5-2": "Lienchiang County",
            "1-5-3": "Kinmen County"
          }
        }
      }
    },
    "2": {
      "name": "Common",
      "area": {
        "2-1": {
          "name": "Platform",
          "city": {
            "2-1-1": "Online"
          }
        }
      }
    }
  }
}

export const RegionsOrder = {
  "1": 1,
  "2": 2,
  "1-1": 1,
  "1-1-1": 1,
  "1-1-2": 2,
  "1-1-3": 3,
  "1-1-4": 4,
  "1-1-5": 5,
  "1-1-6": 6,
  "1-1-7": 7,
  "1-2": 2,
  "1-2-1": 8,
  "1-2-2": 9,
  "1-2-3": 10,
  "1-2-4": 11,
  "1-2-5": 12,
  "1-3": 3,
  "1-3-1": 13,
  "1-3-2": 14,
  "1-3-3": 15,
  "1-3-4": 16,
  "1-3-5": 17,
  "1-4": 4,
  "1-4-1": 18,
  "1-4-2": 19,
  "1-5": 5,
  "1-5-1": 20,
  "1-5-2": 21,
  "1-5-3": 22,
  "2-1": 6,
  "2-1-1": 23
}

export default Regions
