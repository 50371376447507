import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { SOCIALS_TYPE } from 'src/constants';
import ConstantsStore from 'src/stores/constants';
import TextInput from 'src/components/TextInput';
import Select from 'src/components/Select';
import DatePicker from 'src/components/DatePicker';
import UploadPhoto from 'src/components/UploadPhoto';
import withErrorInput from 'src/components/withErrorInput';

import Base from './Base';
import styles from '../styles.module.scss';

const ErrorTextInput = withErrorInput(TextInput);
const ErrorSelect = withErrorInput(Select);
const ErrorDatePicker = withErrorInput(DatePicker);

@observer
class Organization extends Base {

  renderBasic() {
    const info = this.vm.info ?? {};

    return (
      <div className={styles.wrapper}>
        <div className={clsx(styles.flexCol)}>

          {this.renderCommonField('displayName', this.vm.profile?.type)}

          <ErrorTextInput
            showError={this.vm.showError}
            key="personInCharge"
            label={t('register_organization_step2_personInCharge')}
            onChange={(e) => this.vm.onPersonalInChargeChange('name', e.target.value)}
            value={this.vm.personInCharge.name}
            required
          />

          <ErrorSelect
            showError={this.vm.showError}
            key="industry"
            placeholder={t('register_organization_step2_industry')}
            onChange={(value) => this.vm.onChange('job', value)}
            showSearch
            options={ConstantsStore.jobsOptionsForUser}
            value={info.job}
            required
          />

          <ErrorSelect
            showError={this.vm.showError}
            key="type"
            placeholder={t('register_organization_step2_type')}
            onChange={(value) => this.vm.onChange('type', value)}
            options={ConstantsStore.organizationTypesOptions}
            value={info.type}
            required
          />

          {info.needStaff && (
          <ErrorSelect
            showError={this.vm.showError}
            key="staff"
            placeholder={t('register_organization_step2_staff')}
            onChange={(value) => this.vm.onChange('staff', value)}
            options={ConstantsStore.staffOptions}
            value={info.staff}
            required
          />
          )}

          {info.needCapitalAmount && (
          <ErrorSelect
            showError={this.vm.showError}
            key="capitalAmount"
            placeholder={t('register_organization_step2_capitalAmount')}
            onChange={(value) => this.vm.onChange('capitalAmount', value)}
            options={ConstantsStore.capitalAmount}
            value={info.capitalAmount}
            required
          />
          )}

          <ErrorSelect
            showError={this.vm.showError}
            key="location"
            placeholder={t('register_organization_step2_location')}
            onChange={(value) => this.vm.onChange('location', value)}
            options={ConstantsStore.flattenedRegionsOptions.filter((item) => item.value.slice(0, 1) !== '2')}
            value={info.location}
            readOnly={!this.vm.isAllowedToEditAll}
            required
          />

          <ErrorDatePicker
            showError={this.vm.showError}
            key="foundedIn"
            label={t('register_organization_step2_foundedIn')}
            placeholder={t('register_organization_step2_foundedIn')}
            onChange={(date, dateString) => {
              this.vm.onChange('foundedIn', date ? dayjs(date).toISOString() : null);
            }}
            style={{ width: '100%' }}
            value={info.foundedIn ? dayjs(info.foundedIn) : null}
            readOnly={!this.vm.isAllowedToEditAll}
            required
            isMobile={this.props.isMobile}
          />

          {this.renderInterests()}
        </div>
      </div>
    );
  }

  renderSocial() {
    const socials = this.vm.info?.socialsObj;
    const info = this.vm.info ?? {};
    if (!socials) { return null; }

    return (
      <div className={clsx(styles.flexCol, styles.center, styles.marginTop30)}>
        <ErrorTextInput
          showError={this.vm.showError}
          key="introduction"
          label={t('register_influence_introduction_placeholder')}
          onChange={(e) => this.vm.onChange('introduction', e.target.value)}
          value={info.introduction ?? ''}
          multiline
          required
          validValue={info.isIntroductionValid}
          errorMessage={info.introduction ? t('register_introduction_errorMessage') : t('this_is_required')}
        />

        {this.renderCommonField('motto', this.vm.profile?.type)}

        <ErrorTextInput
          key="influence"
          label={t('register_influence_result_placeholder')}
          value={socials.influence ?? ''}
          onChange={(e) => this.vm.onSocialChange('influence', e.target.value)}
          showError={!!socials.influence && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Influence]}
          errorMessage={t('url_is_not_valid')}
        />

        <ErrorTextInput
          key="facebook"
          label={t('facebook_placeholder')}
          value={socials.facebook ?? ''}
          onChange={(e) => this.vm.onSocialChange('facebook', e.target.value)}
          showError={!!socials.facebook && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Facebook]}
          errorMessage={t('url_is_not_valid')}
        />

        <ErrorTextInput
          key="instagram"
          label={t('instagram_placeholder')}
          value={socials.instagram ?? ''}
          onChange={(e) => this.vm.onSocialChange('instagram', e.target.value)}
          showError={!!socials.instagram && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Instagram]}
          errorMessage={t('url_is_not_valid')}
        />

        <TextInput
          key="line"
          className={styles.marginBottom}
          label={t('line_placeholder')}
          onChange={(e) => this.vm.onSocialChange('lineId', e.target.value)}
          value={socials.lineId ?? ''}
        />

        <ErrorTextInput
          key="linkedIn"
          label={t('linkedin_placeholder')}
          value={socials.linkedin ?? ''}
          onChange={(e) => this.vm.onSocialChange('linkedin', e.target.value)}
          showError={!!socials.linkedin && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Linkedin]}
          errorMessage={t('url_is_not_valid')}
        />

        <ErrorTextInput
          key="website"
          label={t('website_placeholder')}
          value={socials.website ?? ''}
          onChange={(e) => this.vm.onSocialChange('website', e.target.value)}
          showError={!!socials.website && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Website]}
          errorMessage={t('url_is_not_valid')}
        />

        <ErrorTextInput
          key="youtube"
          label={t('youtube_placeholder')}
          value={socials.youtube ?? ''}
          onChange={(e) => this.vm.onSocialChange('youtube', e.target.value)}
          showError={!!socials.youtube && this.vm.showError}
          validValue={socials.isValid[SOCIALS_TYPE.Youtube]}
          errorMessage={t('url_is_not_valid')}
        />
      </div>
    );
  }

  renderPrivate() {
    const profile = this.vm.profile ?? {};
    const info = this.vm.info ?? {};

    return (
      <div className={styles.wrapper}>
        <div className={clsx(styles.flexCol)}>

          {this.renderCommonField('email')}

          {this.renderCommonField('password')}

          <ErrorTextInput
            showError={this.vm.showError}
            key="name"
            label={t('register_organization_step2_name')}
            onChange={(e) => this.vm.onChange('name', e.target.value)}
            value={profile.name}
            readOnly={!this.vm.isAllowedToEditAll}
            required
          />

          <ErrorTextInput
            showError={this.vm.showError}
            key="address"
            label={t('register_organization_step2_address')}
            onChange={(e) => this.vm.onChange('address', e.target.value)}
            value={info.address}
            readOnly={!this.vm.isAllowedToEditAll}
            required
          />

          <ErrorTextInput
            showError={this.vm.showError}
            key="taxId"
            label={t('register_organization_step2_taxId')}
            onChange={(e) => this.vm.onChange('taxId', e.target.value, 8)}
            value={info.taxId}
            readOnly={!this.vm.isAllowedToEditAll}
            required
            type="number"
            validValue={info.isTaxIdValid}
            errorMessage={info.taxId ? t('format_is_wrong') : t('this_is_required')}
          />

          {this.vm.uploadCertsVM && (
            <UploadPhoto
              placeholder={t('register_organization_step2_upload_certs')}
              label={t('register_organization_step2_certificate')}
              required
              className={clsx(styles.marginBottom, this.vm.showError && this.vm.uploadCertsVM.postList?.length === 0 && 'redBorder')}
              vm={this.vm.uploadCertsVM}
              readOnly={!this.vm.isAllowedToEditAll}
              onUploadFileInvalid={() => this.vm.toggleCommonModal(t('profile_image_not_valid_modal_title'))}
              acceptPDF
              multiple
            />
          )}

          {this._renderContact()}
        </div>
      </div>
    );
  }

  _renderContact() {
    if (!this.vm.info || !this.vm.info?.contact) {
      return null;
    }

    const contact = this.vm.info.contact;

    return (
      <>
        <h3 className={clsx(styles.profileSubtitle)}>{`/ ${t('profile_contact')} /`}</h3>
        <ErrorTextInput
          showError={this.vm.showError}
          key="contact-name"
          label={t('profile_contact_name')}
          value={contact.name}
          onChange={(e) => this.vm.onContactChange('name', e.target.value)}
          required
        />
        <ErrorTextInput
          showError={this.vm.showError}
          key="contact-jobUnit"
          label={t('profile_contact_jobUnit')}
          value={contact.jobUnit}
          onChange={(e) => this.vm.onContactChange('jobUnit', e.target.value)}
          required
        />
        <ErrorTextInput
          showError={this.vm.showError}
          key="contact-jobTitle"
          label={t('profile_contact_jobTitle')}
          value={contact.jobTitle}
          onChange={(e) => this.vm.onContactChange('jobTitle', e.target.value)}
          required
        />
        <ErrorTextInput
          showError={this.vm.showError}
          key="contact phone"
          label={t('profile_contact_phone')}
          onChange={(e) => this.vm.onContactChange('phone', e.target.value)}
          value={contact.phone}
          required
          validValue={contact.isPhoneValid}
          errorMessage={contact.phone ? t('format_is_wrong') : t('this_is_required')}
        />
        <ErrorTextInput
          showError={this.vm.showError}
          key="contact-email"
          label={t('profile_contact_email')}
          value={contact.email}
          validValue={contact.isEmailValid}
          onChange={(e) => this.vm.onContactChange('email', e.target.value)}
          required
          errorMessage={contact.email ? t('format_is_wrong') : t('this_is_required')}
        />
      </>
    );
  }
}

export default Organization;
