import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { EVENT_TYPE } from 'src/constants';
import Spin from 'antd/es/spin';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Button from 'src/components/Button';
import EventCard from 'src/components/EventCard';
import DraftCard from 'src/components/EventCard/Draft';
import RectangleEventCard from 'src/components/EventCard/Rectangle';
import CardCarousel from 'src/components/CardCarousel';
import Loading from 'src/components/Loading';

import CreateEventOverviewPageViewModel from './vm';

import styles from './styles.module.scss';

@observer
class CreateEventOverviewPage extends React.Component {
  constructor() {
    super();

    this.vm = new CreateEventOverviewPageViewModel(this.props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderDrafts = () => {
    return (
      <div className={styles.list}>
        {this.vm.lists[EVENT_TYPE.Draft]?.map((item) => (
          <DraftCard
            key={item.id}
            item={item}
            onClick={() => this.vm.onClickDraft(item.id)}
          />
        ))}
      </div>
    );
  };

  renderSubmitteds = () => {
    return (
      <div className={styles.list}>
        {this.vm.lists[EVENT_TYPE.Submitted]?.map((item) => (
          <RectangleEventCard
            key={item.id}
            item={item}
            onClick={() => this.vm.onClickSubmitted(item.id)}
          />
        ))}
      </div>
    );
  };

  renderSamples = () => {
    return (
      <div className={styles.carouselWrapper}>
        <CardCarousel
          className="eventSamplesSwiper"
          slidesPerView={(window.innerWidth <= 500 || window.innerWidth > 830) ? 1 : 'auto'}
          spaceBetween={30}
          items={this.vm.lists[EVENT_TYPE.Sample].map((item) => (
            <EventCard
              key={item.id}
              onClick={() => this.vm.onViewSample(item.id)}
              item={item}
              className={styles.sampleCard}
              showCornerButton
              cornerButtonProps={{
                text: t('create_event_overview_sample_verb'),
                onClick: (e) => {
                  e.stopPropagation();
                  this.vm.onCopySample(item.id);
                }
              }}
            />
          ))}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
        />
      </div>
    );
  };

  renderEmpty = (panel) => {
    if (this.vm.AnchoredLists[panel]?.isAwait) {
      return (
        <div className={styles.emptyMsg}>
          <Spin />
        </div>
      );
    }

    return (
      <div className={styles.emptyMsg}>
        {t(`create_event_overview_${panel}_empty`)}
      </div>
    );
  };

  renderPanel = (panel) => {
    return (
      <section className={styles.panel}>
        <div className={clsx(styles.row, styles.spaceBtw, styles.marginBottom20)}>
          <div className={styles.row}>
            <span className={clsx(styles.text, styles.bold)}>
              {t(`create_event_overview_${panel}_verb`)}
            </span>
            <span className={styles.text}>
              {t(`create_event_overview_${panel}`)}
            </span>
          </div>

          <div
            className={styles.seeAll}
            onClick={() => this.vm.toList(panel)}
          >
            See all
          </div>

        </div>

        <div className={styles.content}>
          {this.renderPanelContent(panel)}
        </div>
      </section>
    );
  };

  renderPanelContent = (panel) => {
    if (this.vm.lists[panel].length === 0) {
      return this.renderEmpty(panel);
    }

    switch (panel) {
      case EVENT_TYPE.Draft:
        return this.renderDrafts();
      case EVENT_TYPE.Submitted:
        return this.renderSubmitteds();
      case EVENT_TYPE.Sample:
        return this.renderSamples();
      default:
        return null;
    }
  };

  renderContent() {
    return (
      <>
        <div className={styles.title}>
          <span>
            {t('create_event_overview_title_1')}
          </span>
          <span>
            {t('create_event_overview_title_2')}
          </span>
        </div>

        <div className={styles.panels}>
          {this.renderPanel(EVENT_TYPE.Draft)}
          {this.renderPanel(EVENT_TYPE.Submitted)}
          {this.renderPanel(EVENT_TYPE.Sample)}
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            onClick={() => this.vm.toCreateEventIntro()}
            className={styles.createButton}
            withRightArrow={!this.vm.noEventToCopy}
          >
            {this.vm.noEventToCopy ? t('create_event_overview_button_create_empty') : t('create_event_overview_button_create')}
          </Button>
        </div>
      </>
    );
  }

  render() {

    return (
      <div className={styles.overviewPage}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
          hideFooter
        >
          <Breadcrumb
            routes={[
              { label: t('breadcrumb_event'), path: '' },
              { label: t('breadcrumb_create_event'), path: '' }
            ]}
            right={null}
            withHeader
          />
          <div className={styles.wrapper}>
            {this.renderContent()}
          </div>

        </MainLayout>

        { this.vm.isAwait && <Loading /> }
      </div>
    );
  }
}

CreateEventOverviewPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(CreateEventOverviewPage)), true);
