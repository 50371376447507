/* eslint-disable */
const Jobs = {
  "zh-TW": {
    "1": "設計及藝術業",
    "2": "專業技術服務業",
    "3": "金融及保險業",
    "4": "資訊及通訊服務業",
    "5": "醫療保健及社會工作服務業",
    "6": "教育業",
    "7": "影音出版業",
    "8": "公共行政及國防",
    "9": "農、林、漁、牧業",
    "10": "飲食製造業",
    "11": "衣飾製造業",
    "12": "批發及零售業",
    "13": "娛樂及休閒服務業",
    "14": "住宿及餐飲業",
    "15": "產品製造業",
    "16": "營建工程業",
    "17": "運輸及倉儲業",
    "18": "支援服務業",
    "19": "不動產業",
    "20": "礦業及土石採取業",
    "21": "電力及燃氣供應業",
    "22": "用水供應及污染整治業",
    "23": "其他服務業",
    "24": "不分專業"
  },
  "en-US": {
    "1": "設計及藝術業",
    "2": "專業技術服務業",
    "3": "金融及保險業",
    "4": "資訊及通訊服務業",
    "5": "醫療保健及社會工作服務業",
    "6": "教育業",
    "7": "影音出版業",
    "8": "公共行政及國防",
    "9": "農、林、漁、牧業",
    "10": "飲食製造業",
    "11": "衣飾製造業",
    "12": "批發及零售業",
    "13": "娛樂及休閒服務業",
    "14": "住宿及餐飲業",
    "15": "產品製造業",
    "16": "營建工程業",
    "17": "運輸及倉儲業",
    "18": "支援服務業",
    "19": "不動產業",
    "20": "礦業及土石採取業",
    "21": "電力及燃氣供應業",
    "22": "用水供應及污染整治業",
    "23": "其他服務業",
    "24": "不分專業"
  }
}

export const JobsOrder = {
  "1": 2,
  "2": 3,
  "3": 4,
  "4": 5,
  "5": 6,
  "6": 7,
  "7": 8,
  "8": 9,
  "9": 10,
  "10": 11,
  "11": 12,
  "12": 13,
  "13": 14,
  "14": 15,
  "15": 16,
  "16": 17,
  "17": 18,
  "18": 19,
  "19": 20,
  "20": 21,
  "21": 22,
  "22": 23,
  "23": 24,
  "24": 1
}

export default Jobs
