/* eslint-disable */
const Awards = {
  "zh-TW": {
    "1": "證書",
    "2": "聯名開發",
    "3": "培訓計畫",
    "4": "資金",
    "5": "服務",
    "6": "空間",
    "7": "策略合作",
    "8": "課程",
    "9": "其他"
  },
  "en-US": {
    "1": "證書",
    "2": "聯名開發",
    "3": "培訓計畫",
    "4": "資金",
    "5": "服務",
    "6": "空間",
    "7": "策略合作",
    "8": "課程",
    "9": "其他"
  }
}

export const AwardsOrder = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9
}

export default Awards
