import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import i18n from 'src/i18n';
import { RESOURCE_TYPES } from 'src/constants';
import MainLayout from 'src/components/MainLayout';
import Breadcrumb from 'src/components/Breadcrumb';
import Icon from 'src/components/Icon';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Tabs from 'src/components/Tabs';
import ResourceCard from 'src/components/ResourceCard';
import ResourceAddModal from 'src/components/Modals/ResourceAddModal';
import MemberOnlyFeatureModal from 'src/components/Modals/MemberOnlyFeatureModal';
import GeneralModal from 'src/components/Modals/GeneralModal';
import Spin from 'antd/es/spin';

import serviceIcon from 'src/assets/resourceServiceBlue.svg';
import fundsIcon from 'src/assets/resourceMoneyBlue.svg';
import sapceIcon from 'src/assets/resourceSpaceBlue.svg';

import MyResourcePageViewModel from './vm';
import styles from './styles.module.scss';

const resourceIcons = {
  service: serviceIcon,
  funds: fundsIcon,
  space: sapceIcon
};

@observer
class MyResourcePage extends React.Component {
  constructor(props) {
    super(props);

    this.vm = new MyResourcePageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderTopArea() {
    return (
      <div className={styles.topArea}>
        <div className={styles.topContainer}>
          <div className={styles.title}>
            <div>{i18n.t('myresource_title_1')}</div>
            <div>{i18n.t('myresource_title_2')}</div>
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    const isEmpty = this.vm.list.length === 0;

    return isEmpty
      ? this.renderEmpty()
      : (
        <div className={styles.list}>
          {this.vm.list?.map((item) => (
            <ResourceCard
              className={clsx(styles.card)}
              type={item.type}
              item={item}
              key={item.id}
              onClick={() => this.vm.toDetail(item.type, item.id)}
              onEditPressed={() => this.vm.onEditPressed(item)}
              onTogglePressed={() => this.vm.onTogglePressed(item)}
              isEdited
            />
          ))}
        </div>
      );
  }

  renderEmpty() {
    if (this.vm.isAwait) {
      return (
        <div className={styles.emptyContainer}>
          <Spin />
        </div>
      );
    }

    return (
      <div className={styles.emptyContainer}>
        <div className={styles.emptyMessage}>
          <div className={styles.title}>
            {i18n.t('myresource_placeholder_1')}
          </div>
          <div className={styles.text}>
            {i18n.t('myresource_placeholder_2')}
          </div>
        </div>
        <div className={styles.addBtn} onClick={this.vm.onAddResource}>{`+ 新增${i18n.t(`resources_${this.vm.type}`)}資源`}</div>
      </div>
    );
  }

  render() {
    return (
      <div className={clsx(styles.myResourcePage, 'page')}>
        <MainLayout
          {...this.props}
          headerColorMode={2}
        >
          <Breadcrumb
            routes={[
              { label: i18n.t('my_account'), path: `/user/profile/${this.vm.profile?.id ?? ''}` },
              { label: '', path: '' }
            ]}
            right={(
              <div className={styles.newBtn} onClick={this.vm.onAddResource}>
                {i18n.t('resources_add_btn')}
              </div>
          )}
            colorType="black"
            withHeader
          />
          {this.renderTopArea()}
          <div className={styles.mainContainer}>
            <Tabs
              colorMode={2}
              items={Object.values(RESOURCE_TYPES).map((type) => {
                return {
                  value: type,
                  label: (
                    <div className={styles.flexRow}>
                      <div className={styles.tabTitle}>{i18n.t(`resources_${type}`)}</div>
                      <Icon
                        size={20}
                        svg
                      >
                        {resourceIcons[type]}
                      </Icon>
                    </div>
                  ),
                  content: this.renderList()
                };
              })}
              onSwitchTab={this.vm.switchType}
              currentValue={this.vm.type}
              className={styles.resourceTabs}
            />
            {
              this.vm.anchor
                ? (
                  <>
                    {this.vm.isAwait && <Spin style={{ margin: '0 auto' }} />}
                    <Waypoint
                      scrollableAncestor={window}
                      onEnter={this.vm.onScrollEnd}
                    />
                  </>
                )
                : null
            }
          </div>
          <ResourceAddModal
            isOpen={this.vm.isOpen}
            onModalToggle={this.vm.onModalToggle}
            isMobile={this.vm.isMobile}
            vm={this.vm}
          />

          {this.vm.memberOnlyFeatureModalVM && (
            <MemberOnlyFeatureModal
              vm={this.vm.memberOnlyFeatureModalVM}
              isMobile={this.vm.isMobile}
              centered
            />
          )}
        </MainLayout>

        {this.vm.generalModalViewModel && (
          <GeneralModal vm={this.vm.generalModalViewModel} isMobile={this.vm.isMobile} />
        )}
      </div>
    );
  }
}

MyResourcePage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(MyResourcePage)), true);
