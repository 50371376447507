import {
  makeObservable, computed, observable, action, runInAction, toJS
} from 'mobx';
import dayjs from 'dayjs';
import diff from 'deep-diff';
import qs from 'qs';
import pretendTo from 'src/stores/pretendTo';
import i18n from 'src/i18n';
import UserService from 'src/services/user';
import { TABLE_LIMIT, TABLE_LIMIT_MOBILE, LIST_MODEL_TYPE, EVENT_STATUS, EVENT_PRIVACY } from 'src/constants';
import EventService from 'src/services/event';
import ErrorService from 'src/services/errors';
import tabsState from 'src/stores/tabsState';
import GeneralModalViewModel from 'src/components/Modals/GeneralModal/vm';
import AnchoredList from 'src/services/anchoredList';
import MemberOnlyFeatureModalVM, { getBlockReason } from 'src/components/Modals/MemberOnlyFeatureModal/vm';

export const RECRUIT_STATUS = {
  Ongoing: i18n.t('recruit_status_ongoing'),
  Pause: i18n.t('recruit_status_pause'),
  Incoming: i18n.t('recruit_status_incoming'),
  End: i18n.t('recruit_status_end')
};

export const RELEASE_METHOD = {
  Auto: 'auto',
  Manual: 'manual',
  Cancel: 'cancel'
};

export const MENU_MODAL = {
  Privacy: 'privacy',
  StopRecruit: 'stopRecruit',
  Deactivate: 'deactivate',
  Activate: 'activate',
  Prolong: 'prolong',
  Delete: 'delete'
};

class EventHostedPageViewModel {
  // filter
  @observable isMobile = false;
  @observable isModalOpen = false;
  @observable recruitStatus;
  @observable releaseMethod;
  @observable filter = {};
  @observable startAt = {};
  @observable endAt = {};
  @observable statuses = [this.currentTab];
  @observable hasAppliedFilter = false;

  // sort
  @observable sort; // release / register
  @observable order = 'desc'; // asc / desc

  @observable draftsStatuses = [];

  generalModalViewModel = new GeneralModalViewModel();

  @observable memberOnlyFeatureModalVM = null;

  @observable AnchoredLists = {
    [EVENT_STATUS.Active]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Event,
      path: 'v1/user/{uid}/event/created'
    }),
    [EVENT_STATUS.Inactive]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Event,
      path: 'v1/user/{uid}/event/created'
    }),
    [EVENT_STATUS.Draft]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Event,
      path: 'v1/user/{uid}/event/created'
    })
  };

  @observable isAwait = false;

  @observable menuModal = null;
  @observable dataInMenuModal = {
    item: null,
    [MENU_MODAL.Privacy]: EVENT_PRIVACY.Listed,
    endAt: null
  };

  constructor(props) {
    makeObservable(this);
    console.log(props);
    this.props = props;
    this.profile = props.profile;
  }

  @computed get currentTab() {
    return tabsState.eventHostedTab;
  }

  @action didMount = async (props) => {
    console.log('HostedEventPage.didMount, params', props.router.params);
    await this.init(props);
  };

  @action didUpdate = async (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (diff(prev.profile, cur.profile) || (UserService.isLogin && !this.profile)) {
      await this.init(props);
    }
  };

  @action init = async (props) => {
    this.isMobile = props.context.state.isMobile;
    this.setFilter('statuses', [this.currentTab]);

    const uid = pretendTo.id ?? this.profile.id;
    if (!uid) return;

    Object.values(this.AnchoredLists).forEach((l) => l.setPath(`v1/user/${uid}/event/created`));
    await this.getLists();
  };

  @computed get list() {
    return this.lists[this.currentTab];
  }

  @computed get anchor() {
    return this.AnchoredLists[this.currentTab].anchor;
  }

  @computed get lists() {
    return {
      [EVENT_STATUS.Active]: this.AnchoredLists[EVENT_STATUS.Active].list,
      [EVENT_STATUS.Inactive]: this.AnchoredLists[EVENT_STATUS.Inactive].list,
      [EVENT_STATUS.Draft]: this.AnchoredLists[EVENT_STATUS.Draft].list
    };
  }

  @computed
  get hasFilter() {
    const keys = Object.keys(this.filter);
    return (keys.length !== 0 && (!(keys.every((k) => k === 'statuses' || k === 'keyword')))) || this.draftsStatuses.length !== 0;
  }

  @computed
  get listFilters() {
    const commonFilter = {
      keyword: this.filter.keyword ? this.filter.keyword : undefined,
      privacy: this.filter.privacy ? this.filter.privacy : undefined
    };

    return {
      [EVENT_STATUS.Active]: {
        ...commonFilter,
        startAt: this.filter.startAt ? this.filter.startAt : undefined,
        endAt: this.filter.endAt ? this.filter.endAt : undefined
      },
      [EVENT_STATUS.Inactive]: {
        ...commonFilter,
        isAutoActive: this.filter.isAutoActive ? this.filter.isAutoActive : undefined
      },
      [EVENT_STATUS.Draft]: {
        ...commonFilter
      }
    };
  }

  @computed
  get filterCount() {
    let count = 0;
    if (this.filter.privacy) count += 1;
    if (this.currentTab === EVENT_STATUS.Active && this.recruitStatus) count += 1;
    if (this.currentTab === EVENT_STATUS.Inactive && this.releaseMethod) count += 1;
    if (this.currentTab === EVENT_STATUS.Draft && this.draftsStatuses.length !== 0) count += 1;
    return count;
  }

  @action getLists = async () => {
    await Promise.all([
      this.getListByStatus(EVENT_STATUS.Active),
      this.getListByStatus(EVENT_STATUS.Inactive),
      this.getListByStatus(EVENT_STATUS.Draft)
    ]);
  };

  @action getListByStatus = async (tab) => {
    const params = {
      sort: tab === EVENT_STATUS.Active ? this.sort : undefined,
      order: this.order,
      limit: this.isMobile ? TABLE_LIMIT_MOBILE : TABLE_LIMIT
    };

    let statusForFilter = [];
    if (tab === EVENT_STATUS.Draft) {
      statusForFilter = this.draftsStatuses.length === 0
        ? [EVENT_STATUS.Draft, EVENT_STATUS.Submitted, EVENT_STATUS.Rejected, EVENT_STATUS.Failed]
        : this.draftsStatuses;
    } else {
      statusForFilter = [tab];
    }

    await this.AnchoredLists[tab].getList({ ...this.listFilters[tab], statuses: statusForFilter }, params);
  };

  onScrollEnd = async () => {
    console.log('on scroll end');
    await this.AnchoredLists[this.currentTab].getNext();
  };

  @action switchTab = (value) => {
    tabsState.updateEventHostedTab(value);
    this.setFilter('statuses', [this.currentTab]);

    if (this.lists[this.currentTab].length === 0) {
      this.getListByStatus(this.currentTab);
    }
  };

  @action setFilter = (type, value) => {
    this.filter[type] = value;

    if (type === 'keyword' && value.length === 0) {
      this.getLists();
    }
  };

  @action setFilterPrivacy = (value) => {
    if (this.filter.privacy === value) {
      this.filter.privacy = null;
    } else {
      this.filter.privacy = value;
    }
  };

  @action setFilterRecruitStatus = (value) => {
    if (this.recruitStatus === value) {
      this.recruitStatus = null;
    } else {
      this.recruitStatus = value;
      switch (this.recruitStatus) {
        case RECRUIT_STATUS.Ongoing:
          this.startAt = {
            lte: dayjs().toISOString()
          };
          this.endAt = {
            gte: dayjs().toISOString()

          };
          break;
        case RECRUIT_STATUS.Incoming:
          this.startAt = {
            gt: dayjs().toISOString()
          };
          this.endAt = {};
          break;
        case RECRUIT_STATUS.End:
          this.startAt = {};
          this.endAt = {
            lt: dayjs().toISOString()
          };
          break;
        default:
      }
    }

    this.filter.startAt = this.startAt;
    this.filter.endAt = this.endAt;
  };

  @action setFilterReleaseMethod = (value) => {
    if (this.releaseMethod === value) {
      this.releaseMethod = null;
    } else {
      this.releaseMethod = value;
      switch (this.releaseMethod) {
        case RELEASE_METHOD.Auto:
          this.filter.isAutoActive = true;
          break;
        case RELEASE_METHOD.Manual:
          this.filter.isAutoActive = false;
          break;
        // case RELEASE_METHOD.Cancel:
        //   break;
        default:
      }
    }
  };

  @action setFilterDraftStatus = (value) => {
    if (this.draftsStatuses.includes(value)) {
      this.draftsStatuses = this.draftsStatuses.filter((s) => s !== value);
    } else {
      this.draftsStatuses.push(value);
    }
  };

  @action applyFilter = async () => {
    console.log('applyFilter', this.filter);
    this.toggleFilterModal();
    this.hasAppliedFilter = this.hasFilter;
    await this.getLists();
  };

  @action resetFilter = () => {
    this.recruitStatus = null;
    this.releaseMethod = null;
    this.draftsStatuses = [];
    this.filter = { statuses: [this.currentTab] };
  };

  @action handleSort = (sort, order) => {
    this.sort = sort;
    this.order = order;
    this.getListByStatus(this.currentTab);
  };

  @action toggleFilterModal = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  onToggleRecruit = async (item) => {
    try {
      const res = await EventService.update(item.id, {
        enableRecruit: !item.enableRecruit
      });
      runInAction(() => {
        this.AnchoredLists[this.currentTab].updateItem(res);
      });

      this.generalModalViewModel.open({
        title: i18n.t(`event_hosted_modal_toggle_recruit_status_${res.enableRecruit}`)
      });
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  onChangeStatus = async (id, value) => {
    try {
      const res = await EventService.update(id, {
        status: value
      });
      await this.getListByStatus(this.currentTab);
      this.generalModalViewModel.open({
        title: i18n.t(`event_hosted_modal_toggle_status_${value}`)
      });

      await Promise.all([
        this.getListByStatus(EVENT_STATUS.Inactive),
        this.getListByStatus(EVENT_STATUS.Active)
      ]);
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }
  };

  @action openMenuModal = (type, item) => {
    this.menuModal = type;
    this.dataInMenuModal.item = item;
    this.dataInMenuModal[MENU_MODAL.Privacy] = item.privacy;
    if (type === MENU_MODAL.Activate) {
      this.dataInMenuModal.endAt = item.endAt ? dayjs(item.endAt) : null;
    }
  };

  @action closeMenuModal = () => {
    this.menuModal = null;
    this.dataInMenuModal.item = null;
    this.dataInMenuModal.endAt = null;
  };

  @action setDataInMenuModal = (key, value) => {
    if (key === 'endAt') {
      const date = dayjs(value).endOf('day');
      this.dataInMenuModal[key] = date;
    } else {
      this.dataInMenuModal[key] = value;
    }
  };

  onChangePrivacy = async () => {
    const item = this.dataInMenuModal.item;
    try {
      const res = await EventService.update(item.id, {
        privacy: this.dataInMenuModal[MENU_MODAL.Privacy]
      });
      console.log(res);
      this.AnchoredLists[this.currentTab].updateItem(res);
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  // TODO: phase 3 扣點
  onActivateEvent = async (id) => {
    const endAtString = this.dataInMenuModal.endAt.toISOString();
    const item = this.dataInMenuModal.item;
    try {
      if (this.dataInMenuModal.endAt.isBefore(dayjs())) {
        ErrorService.onCustomError(i18n.t('error_event_end_date_is_before_today'));
        return;
      }

      if (item.endAt) {
        await EventService.update(id, {
          status: EVENT_STATUS.Active
        });

      } else {
        await EventService.update(id, {
          status: EVENT_STATUS.Active,
          endAt: endAtString
        });
      }

      await Promise.all([
        this.getListByStatus(EVENT_STATUS.Inactive),
        this.getListByStatus(EVENT_STATUS.Active)
      ]);

      this.closeMenuModal();

      this.generalModalViewModel.open({
        title: i18n.t(`event_hosted_modal_toggle_status_${EVENT_STATUS.Active}`)
      });

    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }
  };

  // TODO: phase 3 扣點
  onProlongEvent = async (id) => {
    try {
      const res = await EventService.update(id, {
        status: EVENT_STATUS.Active,
        endAt: this.dataInMenuModal.endAt.toISOString()
      });
      console.log(res);
      this.AnchoredLists[this.currentTab].updateItem(res);
      this.generalModalViewModel.open({
        title: i18n.t('event_hosted_modal_prolong_recruit')
      });
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  @action onCopyEvent = async (id) => {
    this.isAwait = true;
    console.log(id);
    try {
      const event = await EventService.copy(id);
      this.toEditEvent(event.id);
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action onDeleteDraft = async (id) => {
    console.log('delete', id);
    this.isAwait = true;
    try {
      const res = await EventService.delete(id);
      console.log(res);
      await this.getListByStatus(this.currentTab);
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action addEvent = () => {
    const blockReason = getBlockReason(this.profile);

    if (!blockReason) {
      this.toCreateEvent();
    } else if (this.memberOnlyFeatureModalVM) {
      this.memberOnlyFeatureModalVM.toggleModal();
    } else {
      this.memberOnlyFeatureModalVM = new MemberOnlyFeatureModalVM({ blockReason, router: this.props.router });
    }
  };

  // //////////////////////////////////////////////////////

  toCreateEvent = () => {
    const { navigate } = this.props.router;
    navigate('/create-event-overview');
  };

  toDemands = (id) => {
    const { navigate } = this.props.router;
    const filter = this.currentTab === EVENT_STATUS.Active ? toJS(this.listFilters[EVENT_STATUS.Active]) : null;
    navigate(`/user/event-hosted/${id}/demands`, { state: { eventId: id, filter: JSON.stringify(filter) } });
  };

  toEventDetail = (id) => {
    const { navigate } = this.props.router;
    navigate(`/events/${id}`);
  };

  toHomePage = () => {
    const { navigate } = this.props.router;
    navigate('/');
  };

  toEditEvent = (id = null) => {
    const { navigate } = this.props.router;
    if (id) {
      navigate(`/create-event/${id}`);
    }
  };

}

export default EventHostedPageViewModel;
