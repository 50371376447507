import { request, getHost, getHeader } from './utils';

export const resourceList = (type, params, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/resource/${type}/list`,
    headers: getHeader(),
    params,
    data
  };
  return request(options);
};

export const resourceDetail = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/v1/resource/${id}/detail`,
    headers: getHeader()
  };
  return request(options);
};

export const create = (type, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/resource/${type}/create`,
    headers: getHeader(),
    data
  };
  return request(options);
};


export const update = (id, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/v1/resource/${id}`,
    headers: getHeader(),
    data
  };
  return request(options);
};

export const updateActive = (id, isActive) => {
  const options = {
    method: 'put',
    url: `${getHost()}/v1/resource/${id}/active`,
    headers: getHeader(),
    data: {
      isActive
    }
  };
  return request(options);
};

export const genFilesPresignedUrls = (id, files) => {
  const options = {
    method: 'post',
    url: `${getHost()}/v1/resource/${id}/files`,
    headers: getHeader(),
    data: {
      files
    }
  };
  return request(options);
};
