import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';

import styles from '../../styles.module.scss';

@observer
class Motto extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <div className={styles.onePageContainer}>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('register_motto_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('register_motto_subtitle')}
          </h3>
        </div>

        <TextInput
          label={t('register_motto_placeholder')}
          onChange={(e) => this.vm.onChange('motto', e.target.value)}
          defaultValue={this.vm.motto}
          error={this.vm.motto?.length > 20}
          multiline
          sx={{
            maxWidth: 500
          }}
          required
        />
        <div className={styles.mottoErrorMessage} style={{ marginLeft: 15, marginTop: 5 }}>
          {this.vm.motto?.length > 20 ? `${t('register_motto_errorMessage')}` : ''}
        </div>

        <div style={{ flexGrow: 1 }} />

        <div className={clsx(styles.bottomContainer, styles.buttonsRow, styles.twoButtons)}>
          <Button
            onClick={this.vm.onPreviousClick}
            style={{ width: '100%' }}
            className={styles.nextButton}
            type="default"
            withLeftArrow
          >
            {t('previous_step')}
          </Button>
          <Button
            onClick={this.vm.onFinishMotto}
            style={{ width: '100%' }}
            disabled={!this.vm.motto || this.vm.motto?.length > 20}
            className={styles.nextButton}
            loading={this.vm.isLoading}
            withRightArrow
          >
            {t('register_motto_next')}
          </Button>
        </div>
      </div>
    );
  }
}

Motto.propTypes = {
  vm: PropTypes.object
};

Motto.defaultProps = {
  vm: {}
};

export default withProfile(withRouter(withTranslation()(Motto)), false);
