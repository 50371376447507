import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import Icon from 'src/components/Icon';
import CardCarousel from 'src/components/CardCarousel';
import DemandCard from 'src/components/DemandCard';
import Button from 'src/components/Button';
import Avatar from 'src/components/Avatar';
import iconCarouselPrev from 'src/assets/iconCarouselPrev.svg';
import iconCarouselNext from 'src/assets/iconCarouselNext.svg';
import iconSpace from 'src/assets/resourceSpace.svg';
import iconFunds from 'src/assets/resourceMoney.svg';
import iconService from 'src/assets/resourceService.svg';
import fist from 'src/assets/fist.png';
import withRouter from 'src/components/withRouter';
import styles from './styles.module.scss';

const demandsIcons = {
  space: iconSpace,
  service: iconService,
  funds: iconFunds
};

@observer
class EventCarousel extends React.Component {
  descRef = React.createRef();

  constructor(props) {
    super(props);
    this.props = props;
    this.vm = props.vm;
  }

  async componentDidMount() {
    await this.vm.didMount(this.props);
    this.vm.checkIsTextClamped(this.descRef, 'Desc');
  }

  async componentDidUpdate(prevProps) {
    await this.vm.didUpdate(prevProps, this.props);
    this.vm.checkIsTextClamped(this.descRef, 'Desc'); // call here prevent more missing
  }

  renderEventCarouselSection() {
    if (!this.vm.events?.length) {
      return null;
    }

    return (
      <section className={clsx(styles.eventCarousel)}>
        <div className={styles.titleArea}>
          <img src={fist} alt="fist" />
          <h3 className={styles.titlePurple}>
            {t('topics_event_section_title_1')}
          </h3>
          <h3 className={styles.title}>
            {t('topics_event_section_title_2')}
          </h3>
        </div>
        <Swiper
          spaceBetween={30}
          centeredSlides
          ref={this.props.eventSwiperRef}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          modules={[Autoplay]}
          onActiveIndexChange={this.vm.onEventSwiperChange}
        >
          {this.vm.events?.map((item, i) => this.renderEvent(item, i))}

          <div className={clsx(styles.indexContainer, styles.textShadow)}>
            <div className={styles.current}>
              {`0${this.vm.currentIndex + 1}`}
            </div>
            <div className={styles.total}>
              {`/ 0${this.vm.events.length}`}
            </div>
          </div>

          {this.vm.events.length > 1 && (
            <div className={styles.controls}>
              <Icon
                size={30}
                svg
                className={styles.icon}
                onClick={() => {
                  const swiper = this.props.eventSwiperRef?.current?.swiper;
                  if (swiper && !swiper.isBeginning) {
                    swiper.slidePrev();
                  } else {
                    swiper.slideTo(swiper.slides.length - 1);
                  }
                }}
              >
                {iconCarouselPrev}
              </Icon>
              <div className={styles.separatorContainer}>
                <span className={clsx(styles.separator)} />
              </div>
              <div
                className={clsx(styles.nextBtn)}
                onClick={() => {
                  const swiper = this.props.eventSwiperRef?.current?.swiper;
                  console.log(swiper.allowSlideNext, swiper);
                  if (swiper && !swiper.isEnd) {
                    swiper.slideNext();
                  } else {
                    swiper.slideTo(0);
                  }
                }}
              >
                <div className={styles.next}>
                  {t('topics_carousel_next')}
                </div>
                <Icon
                  size={30}
                  svg
                >
                  {iconCarouselNext}
                </Icon>
              </div>

              <div className={styles.eventName}>
                {this.vm.nextEvent?.name}
              </div>
            </div>
          )}
        </Swiper>
      </section>
    );
  }

  renderEvent(item, index) {
    const timeLabel = (() => {
      if (item.type === 'sample') {
        return (
          <div
            className={clsx(styles.timeLabel)}
          >
            {t('event_sample')}
          </div>
        );
      }

      if (!item.startAt) {
        return (
          <div
            className={clsx(styles.timeLabel)}
          >
            {t('events_incoming')}
          </div>
        );
      }

      return (
        <div
          className={clsx(styles.timeLabel)}
        >
          {item.daysFromNow < 0
            ? (
              <div className={styles.eventEnd}>
                {t('event_end')}
              </div>
            )
            : (
              <>
                <div className={styles.countdown}>
                  {t('countdown')}
                </div>
                <div className={styles.number}>
                  {item.daysFromNow}
                </div>
                <div className={styles.day}>
                  {t('day')}
                </div>
              </>
            )}
        </div>
      );
    })();

    return (
      <SwiperSlide key={item.id}>
        <div
          style={{ backgroundImage: `url(${item.banner})` }}
          className={clsx(styles.eventItem)}
        >
          <div className={styles.contentWrapper}>
            <div className={styles.leftCol}>
              <div className={styles.textShadow}>
                {timeLabel}
              </div>

              <div className={styles.demandsTypes}>
                {this.vm.demandTypes?.map((demand) => (
                  <img
                    key={demand}
                    src={demandsIcons[demand]}
                    alt="demand icon"
                    className={styles.demandIcon}
                  />
                ))}
              </div>

              <span />
            </div>

            <div className={styles.rightCol}>
              <div
                className={clsx(styles.hashtags, styles.textShadow)}
              >
                {item.keywordsForDisplay?.map((word, i) => (
                  <span
                    className={clsx(styles.hashtag)}
                    key={word}
                  >
                    {`#${word}`}
                  </span>
                ))}
              </div>
              <h3 className={styles.title}>
                {item.name}
              </h3>

              <div className={styles.buttonContainer}>
                <Button
                  className={styles.learnMoreBtn}
                  onClick={() => this.vm.toEventDetail(item.id)}
                >
                  {t('learn_more')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    );
  }

  renderEventDetailSection() {
    if (!this.vm.currentEvent) {
      return null;
    }

    const user = this.vm.currentEvent?.user;

    return (
      <section className={styles.eventDetail}>
        <div className={styles.introCol}>
          <h5 className={styles.title}>
            {t('topics_event_detail_title')}
          </h5>
          <h4 className={styles.slogan}>
            {this.vm.currentEvent?.slogan}
          </h4>
          <div
            className={clsx(styles.desc, styles.text, !this.vm.hasClickedDescMore ? styles.clamp : '')}
            ref={this.descRef}
            id="eventDesc"
          >
            {this.vm.currentEvent?.description}
          </div>
          {this.vm.isDescClamped && !this.vm.hasClickedDescMore
            ? (
              <div
                className={styles.more}
                onClick={this.vm.clickDescMore}
              >
                {t('expand_desc_more')}
              </div>
            )
            : (
              <div style={{ height: 18 }} />
            )}


          <div className={clsx(styles.space)} />

          {user && (
            <div
              className={styles.user}
              onClick={this.vm.toUserProfile}
            >
              <Avatar
                size={54}
                url={user?.avatar}
                showBlueTick={user?.blueTickAt}
                blueTickSize={18}
              />
              <h5 className={styles.name}>
                {user.displayName || user.name}
              </h5>

            </div>
          )}

          <div className={clsx(styles.bottomSpace, this.vm.hasClickedDescMore && styles.hide)} />
        </div>

        {this.vm.demands?.length !== 0 && this.renderDemands()}
      </section>
    );
  }

  renderDemands() {
    return (
      <div className={styles.demandsContainer}>
        <div className={styles.background} />
        <div className={styles.carouselWrapper}>
          <CardCarousel
            className={clsx('partnersDemandsSwiper', 'topicsPageDemandsSwiper')}
            spaceBetween={10}
            swiperRef={this.vm.demandsSwiperRef}
            items={this.vm.demands.map((d) => (
              <DemandCard
                key={d.id}
                type={d.type}
                onClick={() => this.vm.toDemand(d.id)}
                demand={d}
                className={styles.demandCard}
                footer="join"
              />
            ))}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderEventCarouselSection()}

        {this.renderEventDetailSection()}
      </>
    );
  }
}

export default withRouter(withTranslation()(EventCarousel));
