import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Modal from 'antd/es/modal';
import Drawer from 'src/components/Drawer';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import iconClose from 'src/assets/iconModalClose.svg';
import clsx from 'clsx';

import styles from './styles.module.scss';

@observer
class BaseModal extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { onClose, open, children, isMobile, className, height, showCloseIcon } = this.props;
    return isMobile ? (
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={open}
        className={className}
        height={height || undefined}
      >
        {children}
      </Drawer>
    ) : (
      <Modal
        closable={false}
        onCancel={onClose}
        open={open}
        footer={null}
        maskClosable
        className={clsx(styles.baseModal, className)}
        {...this.props}
      >
        {showCloseIcon
          && (
          <div
            className={styles.closeIcon}
            onClick={onClose}
          >
            <img
              src={iconClose}
              alt="close modal icon"
            />
          </div>
          )}
        {children}
      </Modal>
    );
  }
}

export default withProfile(withRouter(withTranslation()(BaseModal)), false);

BaseModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  showCloseIcon: PropTypes.bool
};

BaseModal.defaultProps = {
  onClose: () => {},
  open: false,
  children: null,
  isMobile: false,
  className: '',
  height: undefined,
  showCloseIcon: false
};

