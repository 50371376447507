import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import { t } from 'i18next';
import { LIST_MODEL_TYPE, PRODUCT_TYPE, QUOTA_TYPE } from 'src/constants';
import UserService from 'src/services/user';
import ErrorService from 'src/services/errors';
import AnchoredList from 'src/services/anchoredList';
import CommonService from 'src/services/common';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';
import dayjs from 'dayjs';

export const TABS = {
  Order: 'order', // 訂單紀錄
  Purchase: 'purchase' // 使用紀錄
};

class OrderHistoryPageViewModel {
  @observable userId = null;
  @observable profile = null;
  @observable activeEventCount = 0;
  @observable orderHistory = null;
  @observable purchaseHistory = null;
  @observable currentTab = TABS.Order;
  @observable promotionText = null;

  @observable filter = {};
  @observable hasAppliedFilter = false;
  @observable keyword = null;

  @observable anchoredLists = {
    [TABS.Order]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Order,
      path: 'v1/user/order/history'
    }),

    [TABS.Purchase]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Purchase,
      path: 'v1/user/purchase/history'
    })
  };

  @observable isIntroModalOpen = false;
  @observable isFilterModalOpen = false;

  @observable typeTreeSelectVM = new TreeSelectViewModel([
    // { value: PRODUCT_TYPE.Point, label: t('purchase_type_point') },
    { value: PRODUCT_TYPE.ActiveEvent, label: t('purchase_type_activeEvent') }
  ]);

  constructor(props) {
    makeObservable(this);
  }

  @action didMount = async (props) => {
    console.log('OrderHistoryPage.didMount, params', props.router.params);
    this.props = props;
    this.profile = props.profile;
    this.userId = props.profile?.userId;

    await this.getAllData();
  };

  @action didUpdate = async (prevProps, props) => {
    this.userId = props.profile?.userId;
    const prevId = prevProps.profile?.userId;
    if (prevId !== this.userId) {
      await this.getAllData();
    }
  };

  getAllData = async () => {
    await Promise.all([
      this.getQuota(),
      this.getOrderHistory(),
      this.getPurchaseHistory(),
      this.getPromotionText()
    ]);
  };

  @action getList = async () => {
    switch (this.currentTab) {
      case TABS.Order:
        await this.getOrderHistory();
        break;
      case TABS.Purchase:
        await this.getPurchaseHistory();
        break;
      default:
    }
  };

  @action getQuota = async () => {
    try {
      const quota = await UserService.quota();
      console.log('quota', quota);
      runInAction(() => {
        this.activeEventCount = quota.find((item) => item.type === QUOTA_TYPE.ActiveEvent)?.count || 0;
      });
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }
  };

  @action getOrderHistory = async () => {
    const params = {
      limit: 6,
      order: 'desc'
    };
    await this.anchoredLists[TABS.Order].getList(this.listFilter, params);
  };

  @action getPurchaseHistory = async () => {
    const params = {
      limit: 6,
      order: 'desc'
    };
    await this.anchoredLists[TABS.Purchase].getList(this.listFilter, params);

    console.log('purchase', this.purchaseList);
  };

  @action getPromotionText = async () => {
    try {
      const text = await CommonService.rechargeText();
      runInAction(() => {
        this.promotionText = text;
      });
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }
  };

  @computed get orderList() {
    return this.anchoredLists[TABS.Order].list;
  }

  @computed get purchaseList() {
    return this.anchoredLists[TABS.Purchase].list;
  }

  @computed get orderListByDate() {
    const obj = {};
    this.anchoredLists[TABS.Order].list?.forEach((item) => {
      const date = dayjs(item.createdAt).format('YYYY/MM');
      if (obj[date]) {
        obj[date].push(item);
      } else {
        obj[date] = [item];
      }
    });
    return obj;
  }

  @computed get purchaseListByDate() {
    const obj = {};
    this.anchoredLists[TABS.Purchase].list?.forEach((item) => {
      const date = dayjs(item.createdAt).format('YYYY/MM');
      if (obj[date]) {
        obj[date].push(item);
      } else {
        obj[date] = [item];
      }
    });
    return obj;
  }

  @action onSwitchTab = async (key) => {
    this.currentTab = key;
    await this.getList();
  };

  @action toggleIntroModal = () => {
    this.isIntroModalOpen = !this.isIntroModalOpen;
  };

  // ///////////////////////////////////

  @computed get listFilter() {
    const filter = {
      ...this.filter,
      types: this.typeTreeSelectVM.selectedItems?.map((item) => item.idValue) ?? []
    };
    if (this.keyword) {
      filter.keyword = this.keyword;
    }

    console.log('listfilter', this.typeTreeSelectVM.selectedItems);

    return filter;
  }

  @action toggleFilterModal = () => {
    this.isFilterModalOpen = !this.isFilterModalOpen;
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
    console.log('keyword', this.keyword);
    if (e.target.value.length === 0) {
      this.getList();
    }
  };

  @action onSearch = async () => {
    console.log('on search');
    const length = this.keyword?.trim()?.length;
    if (length !== 1) {
      await this.getList();
    }
  };

  @action onClearKeyword = () => {
    this.onKeywordChange({ target: { value: '' } });
    console.log('clear');
    this.getList();
  };

  @action setFilterTime = (key, date) => {
    if (date) {
      if (key === 'startAt') {
        const start = dayjs(date).startOf('day').toISOString();
        this.filter.startAt = {
          gte: start
        };
      } else if (key === 'endAt') {
        const end = dayjs(date).endOf('day').toISOString();
        this.filter.endAt = {
          lte: end
        };
      }
    } else {
      this.filter[key] = {};
    }

    console.log('set filter time', this.filter);
  };

  @computed
  get filterCount() {
    let count = 0;
    if (this.listFilter.startAt || this.listFilter.endAt) count += 1;
    if (this.listFilter.types.length !== 0) count += 1;
    return count;
  }

  @action applyFilter = async () => {
    await this.getList();
    runInAction(() => {
      this.toggleFilterModal();
      this.hasAppliedFilter = this.filterCount !== 0;
    });
  };

  @action resetFilter = () => {
    this.typeTreeSelectVM.resetAll();
    this.filter = {};
  };

  @computed get startAtDayJs() {
    return dayjs(this.filter.startAt.gte);
  }

  @computed get endAtDayJs() {
    return dayjs(this.filter.endAt.lte);
  }

  @computed get isFiltered() {
    return this.hasAppliedFilter || !!this.listFilter.keyword;
  }

  // ///////////////////////////////////

  toProduct = () => {
    const { navigate } = this.props.router;
    navigate('/product', { state: { test: 'test' } });
  };

}

export default OrderHistoryPageViewModel;
