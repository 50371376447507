import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import ErrorService from 'src/services/errors';
import { RESOURCE_TYPES } from 'src/constants';
import ConstantsStore from 'src/stores/constants';
import RegisterService from 'src/services/register';
import serviceIcon from 'src/assets/advocateAvatar.svg';
import fundsIcon from 'src/assets/resourceMoneyFill.svg';
import spaceIcon from 'src/assets/resourceSpaceFill.svg';
import rightArrowIcon from 'src/assets/iconArrowRightFill.svg';
import purpleCheck from 'src/assets/purpleCheck.svg';
import styles from './styles.module.scss';
import Icon from '../Icon';

const demandIcons = {
  service: serviceIcon,
  funds: fundsIcon,
  space: spaceIcon
};

export default function DemandCard(props) {
  const { type, demand, onClick, onClickButton, className, footer, showAccepted } = props;
  const [acceptCount, setAcceptCount] = useState(0);
  useEffect(
    () => {
      if (!showAccepted) {
        return;
      }
      const createdEvents = async () => {
        try {
          const data = {
            demandId: demand?.id,
            isAccepted: true
          };
          const { list } = await RegisterService.getList({}, data);
          const accepted = list?.length ?? 0;
          setAcceptCount(accepted);

        } catch (error) {
          console.log('Demand Card error', error);
          // ErrorService.onDefaultError(error);
        }
      };
      createdEvents();
    },
    [demand, showAccepted]
  );

  if (!demand) return null;
  const meta = demand.meta;

  const icon = () => {
    switch (type) {
      case RESOURCE_TYPES.Service:
        return (
          <div>
            <div
              className={clsx(styles.demandIcon, styles.service)}
              style={{ backgroundImage: `url(${demandIcons[type]})` }}
            />
            <span className={styles.x}>x</span>
            <span className={styles.count}>{demand.meta.count}</span>
          </div>
        );
      case RESOURCE_TYPES.Funds:
      case RESOURCE_TYPES.Space:
        return (
          <div className={clsx(styles.demandIcon, styles.other)}>
            <img
              src={demandIcons[demand.type]}
              alt="demand"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const tag = () => {
    switch (type) {
      case RESOURCE_TYPES.Service:
        return (
          <div className={styles.tag}>
            {ConstantsStore.getServiceTypeTag(meta.type)}
          </div>
        );

      case RESOURCE_TYPES.Funds:
        return (
          <div className={styles.tag}>
            <span className={styles.mark}>$</span>
            {meta.displayQuota}
          </div>
        );

      case RESOURCE_TYPES.Space:
        return (
          <div className={styles.tag}>
            {t('demand_space_fit')}
            {meta.capacity}
            {t('demand_people_unit')}
          </div>
        );

      default:
        return null;
    }
  };

  const info = () => {
    switch (type) {
      case RESOURCE_TYPES.Service:
        return (
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <div className={styles.title}>
                {t('demand_profession')}
              </div>
              <div className={clsx(styles.content, styles.clamp)}>
                {meta.jobs.map((j, i) => (
                  <span key={j}>
                    {ConstantsStore.jobsOptions.find((el) => el.value === j).label}
                    {i < meta.jobs.length - 1 && (
                      <span className={styles.dot}>•</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                {t('demand_collab_regions')}
              </div>
              <div className={clsx(styles.content, styles.clamp)}>
                <span>{meta.regionsString}</span>
              </div>
            </div>
          </div>
        );

      case RESOURCE_TYPES.Funds:
        return (
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <div className={styles.title}>
                {t('demand_funds_source')}
              </div>
              <div className={clsx(styles.content, styles.clamp)}>
                {meta.types.map((ft, i) => (
                  <span key={ft}>
                    {ConstantsStore.fundsTypesOptions.find((opt) => opt.value === ft)?.label ?? ''}
                    {i < meta.types.length - 1 && (
                      <span className={styles.dot}>•</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                {t('demand_regions')}
              </div>
              <div className={clsx(styles.content, styles.clamp)}>
                {meta.regions.map((r, i) => (
                  <span key={r}>
                    {ConstantsStore.getCityLabel(r)}
                    {i < meta.regions.length - 1 && (
                      <span className={styles.dot}>•</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          </div>
        );

      case RESOURCE_TYPES.Space:
        return (
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <div className={styles.title}>
                {t('demand_purpose')}
              </div>
              <div className={clsx(styles.content, styles.clamp)}>
                {meta.usages.map((r, i) => (
                  <span key={r}>
                    {ConstantsStore.usagesOptions.find((el) => el.value === r)?.label ?? ''}
                    {i < meta.usages.length - 1 && (
                      <span className={styles.dot}>•</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                {t('demand_regions')}
              </div>
              <div className={clsx(styles.content, styles.clamp)}>
                {meta.regions.map((r, i) => (
                  <span key={r}>
                    {ConstantsStore.getCityLabel(r)}
                    {i < meta.regions.length - 1 && (
                      <span className={styles.dot}>•</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const footerContent = () => {
    switch (footer) {
      case 'join':
        return (
          <>
            <hr className="dashedGray" style={{ margin: '15px 0' }} />
            <div>
              <div
                className={styles.joinBtn}
                onClick={onClickButton}
              >
                <span>
                  {t('join')}
                </span>
                <Icon
                  size={18}
                  svg
                  style={{ marginLeft: 3 }}
                >
                  {rightArrowIcon}
                </Icon>
              </div>
            </div>
          </>
        );
      case 'review':
        return (
          <>
            <hr className="dashedGray" style={{ margin: '15px 0' }} />
            <div className={styles.flexRow}>
              <div className={styles.flexRow}>
                <img
                  className={styles.check}
                  alt="check"
                  src={purpleCheck}
                />
                <div className={styles.numbers}>
                  <span className={styles.purple}>
                    {acceptCount}
                  </span>
                  <span className={styles.slash}>/</span>
                  <span>{type === RESOURCE_TYPES.Service ? meta.count : '∞'}</span>
                </div>
              </div>
              <div
                className={styles.joinBtn}
                onClick={onClickButton}
              >
                <span>
                  {t('review')}
                </span>
                <Icon
                  size={18}
                  svg
                  style={{ marginLeft: 3 }}
                >
                  {rightArrowIcon}
                </Icon>
              </div>
            </div>
          </>
        );
      default:
    }

    return null;
  };

  return (
    <div
      className={clsx(styles.demandCard, className)}
      onClick={onClick}
      style={props.style}
    >
      <div className={styles.topArea}>
        {icon()}
        {tag()}
      </div>
      <div className={styles.title}>
        {demand.name}
      </div>
      <div className={clsx(styles.desc, styles.clamp2)}>
        {demand.description}
      </div>

      {info()}

      {footer && footerContent()}
    </div>
  );
}

DemandCard.propTypes = {
  type: PropTypes.string,
  demand: PropTypes.object,
  onClick: PropTypes.func,
  onClickButton: PropTypes.func,
  className: PropTypes.string,
  footer: PropTypes.any,
  showAccepted: PropTypes.bool,
  style: PropTypes.object
};

DemandCard.defaultProps = {
  type: null,
  demand: null,
  onClick: () => {},
  onClickButton: () => {},
  className: null,
  footer: null,
  showAccepted: false,
  style: {}
};
