import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import i18n from 'src/i18n';
import Button from 'src/components/Button';
import illustrationOneBoy from 'src/assets/illustrationOneBoy.svg';

import styles from './styles.module.scss';
import BaseModal from '../BaseModal';

function WithdrawApplicationModal(props) {

  const { vm, isMobile } = props;

  return (
    <BaseModal
      closable={false}
      open={vm.isShow}
      maskClosable
      isMobile={isMobile}
      centered
      onClose={vm.close}
      height={420}
    >
      <div className={styles.modal}>
        <img
          className={clsx(styles.marginBottom, styles.illustration)}
          src={illustrationOneBoy}
          alt="a boy"
        />
        <h2 className={clsx(styles.title, styles.marginBottom)}>
          {i18n.t('withdraw_application_modal_title')}
        </h2>
        <div className={clsx(styles.subtitle, styles.marginBottom)}>
          <span>{i18n.t('withdraw_application_modal_desc1')}</span>
          <span className={styles.name}>{vm.eventName}</span>
          <span>{i18n.t('withdraw_application_modal_desc2')}</span>
        </div>

        <div style={{ flexGrow: 1 }} />

        <div className={styles.buttonGroup}>
          <Button
            onClick={vm.close}
            className={styles.button}
            type="default"
          >
            {i18n.t('withdraw_application_modal_cancel')}
          </Button>

          <Button
            onClick={vm.onConfirm}
            withRightArrow
            className={styles.button}
          >
            {i18n.t('withdraw_application_modal_confirm')}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}


WithdrawApplicationModal.propTypes = {
  vm: PropTypes.object.isRequired,
  isMobile: PropTypes.bool
};

WithdrawApplicationModal.defaultProps = {
  isMobile: false
};

export default observer(WithdrawApplicationModal);
