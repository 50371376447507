import IS from 'src/utils/is';

export default class Product {
  constructor(params) {
    const {
      id,
      key, // ga key
      name,
      discountedName,
      description,
      type, // point | day | service
      price,
      count,
      taxType, // taxable | zero | free
      isActive,
      remark,
      startAt, // ISO8601
      endAt // ISO8601
    } = params ?? {};

    this.id = id;
    this.key = key;
    this.name = name;
    this.discountedName = discountedName;
    this.description = description;
    this.type = type;
    this.price = price;
    this.count = count;
    this.taxType = taxType;
    this.isActive = isActive;
    this.remark = remark;
    this.startAt = startAt;
    this.endAt = endAt;
  }


  static fromRes(data) {
    return new Product(data);
  }

  get priceForDisplay() {
    return IS.numeric(this.price) ? `TWD ${Math.round(this.price)}` : this.price;
  }

}
