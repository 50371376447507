import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import Config from 'src/config';
import i18n from 'src/i18n';
import DemandService from 'src/services/demand';
import RegisterService from 'src/services/register';
import EventService from 'src/services/event';
import { RESOURCE_TYPES, TABLE_LIMIT, LIST_MODEL_TYPE } from 'src/constants';
import ErrorService from 'src/services/errors';
import AnchoredList from 'src/services/anchoredList';
import { getHost } from 'src/apis/utils';
import ConstantsStore from 'src/stores/constants';
import IS from 'src/utils/is';

class EventApplicationsPageViewModel {
  @observable eid;
  @observable did;
  @observable demand;
  @observable event;
  @observable type;
  @observable anchoredList = new AnchoredList({
    modelType: LIST_MODEL_TYPE.Application,
    path: 'v1/register/list'
  });
  @observable currentTab = RESOURCE_TYPES.Service;
  @observable recommendedResourcesCount = 0;
  @observable startDayToNow = 0;

  @observable isMobile = false;
  @observable isModalOpen = false;

  // filter
  @observable filter = {};
  @observable hasAppliedFilter = false;

  // sort
  @observable order; // asc / desc

  @observable isReaded = null;
  @observable isAccepted = null;

  constructor(props) {
    makeObservable(this);
    this.props = props;
    console.log(props);
  }

  @action didMount = async (props) => {
    console.log('EventApplicationPage.didMount, params', props.router.params);
    this.eid = props.router.params.eid;
    this.did = props.router.params.did;
    this.isMobile = props.context.state.isMobile;
    console.log(props);
    await this.getDemandDetail();
    await this.getList();
    await this.getRecommendedResourcesCount();
    await this.getEventDetail();
    if (this.list?.length === 0) {
      this.getStartDayToNow();
    }
  };

  @action getDemandDetail = async () => {
    try {
      const res = await DemandService.detail(this.did);
      this.demand = res;
      this.type = res.type;
      console.log(res);

    } catch (error) {
      ErrorService.onDefaultError(error);
    }
  };

  @computed
  get listFilter() {
    const data = {
      demandId: this.did,
      keyword: this.keyword
    };
    if (this.filter.isAccepted !== null) {
      data.isAccepted = this.filter.isAccepted;
    }
    if (this.filter.isReaded !== null) {
      data.isReaded = this.filter.isReaded;
    }

    return data;
  }

  @computed get list() {
    return this.anchoredList?.list ?? [];
  }

  @computed get isAwait() {
    return this.anchoredList?.isAwait;
  }

  @computed get anchor() {
    return this.anchoredList?.anchor;
  }

  @action getList = async () => {
    const params = {
      limit: TABLE_LIMIT,
      order: this.order
    };

    this.anchoredList?.getList(this.listFilter, params);
  };

  onScrollEnd = async () => {
    await this.anchoredList?.getNext();
  };

  getRecommendedResourcesCount = async () => {
    try {
      const { list } = await EventService.recommendResourcesList(this.eid, { limit: 1 });
      runInAction(() => {
        this.recommendedResourcesCount = list.length;
      });
    } catch (error) {
      const { navigate } = this.props.router;
      switch (error?.response?.status) {
        case 403:
        case 404:
          ErrorService.onCustomError(
            error?.response?.status === 403 ? i18n.t('error_create_event_detail_403') : i18n.t('error_create_event_detail_404'),
            null,
            () => navigate('/')
          );
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  getEventDetail = async () => {
    try {
      const res = await EventService.detail(this.eid);
      runInAction(() => {
        this.event = res;
      });
    } catch (error) {
      const { navigate } = this.props.router;
      switch (error?.response?.status) {
        case 404:
          ErrorService.onCustomError(
            i18n.t('general_error_content_404'),
            null,
            () => navigate('/')
          );
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  @action getStartDayToNow = async () => {
    if (!this.event.startAt) return;
    const diff = dayjs() - dayjs(this.event.startAt);
    const cd = 24 * 60 * 60 * 1000;
    const days = Math.floor(diff / cd).toString();
    runInAction(() => {
      this.startDayToNow = days;
    });
  };

  @action switchTab = (value) => {
    this.currentTab = value;
  };

  toDemandPage = () => {
    const { navigate } = this.props.router;
    navigate(`/events/${this.eid}/demands/${this.did}`, { state: { isFromApplication: true } });
  };

  toResourcePage = (item) => {
    const { navigate } = this.props.router;
    navigate(`/resources/${this.type}/${item.resource.id}`, { state: { isFromApplication: true, applicationId: item.id } });
  };

  toResourceListPage = (item) => {
    const { navigate } = this.props.router;
    navigate('/resources');
  };

  toApplicationPage = (id) => {
    const { navigate } = this.props.router;
    navigate(`/application/${id}`);
  };

  toRecommendedResources = () => {
    const { navigate } = this.props.router;
    navigate(`/user/event-hosted/${this.eid}/recommend`);
  };

  updateRegister = async (id, body) => {
    try {
      const { data } = await RegisterService.update(id, body);
      const index = this.list.indexOf(this.list.find((item) => item.id === data.id));
      if (index !== -1) {
        this.list[index] = data;
      }
    } catch (error) {
      ErrorService.onDefaultError(error);
    }
    // this.getList(true);
  };

  @computed
  get acceptedCount() {
    return this.list?.filter((item) => item.acceptedAt).length;
  }

  @action handleSort = async (order) => {
    this.order = order;
    this.getList(true);
  };

  @action toggleModal = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  @action setFilter = (field, value) => {
    this.filter[field] = value;
  };

  @action resetFilter = () => {
    this.filter = {};
  };

  @action applyFilter = () => {
    if (this.isValid(this.filter.isAccepted) || this.isValid(this.filter.isReaded)) {
      this.hasAppliedFilter = true;
    } else {
      this.hasAppliedFilter = false;
    }
    this.getList(true);
    this.toggleModal();
  };

  @computed
  get filterCount() {
    let count = 0;
    if (this.isValid(this.filter.isAccepted)) count += 1;
    if (this.isValid(this.filter.isReaded)) count += 1;
    return count;
  }

  handleDownload = async () => {
    const host = () => {
      // local
      if (/:\d{4,}$/.test(window.location.origin)) {
        const r = window.location.origin.split(/:\d{4,}/);
        if (r[0]) {
          return `${r[0]}:3000`;
        }
      }
      return getHost();
    };

    const parsedSocials = (data) => {
      const parsed = [];
      data?.forEach((s) => {
        try {
          const obj = JSON.parse(s);
          if (obj.url) {
            parsed.push(obj);
          }
          return {};
        } catch (e) {
          console.log(e);
          return {};
        }
      });
      if (parsed.length !== 0) {
        return parsed.map((s) => `${i18n.t(`${s.name}`)}: ${s.url}`);
      }
      return '-';
    };

    const convertJob = (type, data) => {
      if (type === 'job' && IS.string(data)) {
        return ConstantsStore.jobsOptions.find((opt) => opt.value === data)?.label ?? '-';
      }

      if (type === 'jobs' && IS.array(data) && data.length !== 0) {
        return data.map((j) => ConstantsStore.jobsOptions.find((opt) => opt.value === j)?.label);
      }

      return '-';
    };

    const output = this.list.map((item) => {
      const resource = {
        [i18n.t('column_resource_name')]: item.resource.name,
        [i18n.t('column_resource_link')]: `${host()}/resources/${item.resource.type}/${item.resource.id}`
      };

      const profile = () => {
        const result = {};
        Object.entries(item.profile.meta).forEach((el) => {
          switch (el[0]) {
            case 'birthday':
            case 'foundedIn':
              result[i18n.t(`column_${el[0]}`)] = dayjs(el[1]).format('YYYY.MM.DD') ?? '-';
              break;
            case 'socials':
              result[i18n.t(`column_${el[0]}`)] = el[1].length !== 0 ? parsedSocials(el[1]) : '-';
              break;
            case 'type':
              result[i18n.t(`column_${el[0]}`)] = ConstantsStore.organizationTypesOptions.find((opt) => opt.value === el[1])?.label ?? '-';
              break;
            case 'cities':
              result[i18n.t(`column_${el[0]}`)] = el[1].length !== 0
                ? el[1].map((c) => ConstantsStore.getRegionLabelByValue(c))
                : '-';
              break;
            case 'location':
            case 'cityOfBirth':
            case 'cityOfResidence':
              result[i18n.t(`column_${el[0]}`)] = ConstantsStore.getRegionLabelByValue(el[1]) ?? '-';
              break;
            case 'job':
            case 'jobs':
              if (!result[i18n.t(`column_${el[0]}`)] || result[i18n.t(`column_${el[0]}`)] === '-') {
                result[i18n.t(`column_${el[0]}`)] = convertJob(el[0], el[1]);
              }
              break;
            case 'gender':
              result[i18n.t(`column_${el[0]}`)] = i18n.t(`gender_${el[1]}`) ?? '-';
              break;
            default:
              result[i18n.t(`column_${el[0]}`)] = el[1] ?? '-';
          }
        });

        return result;
      };

      const fields = () => {
        try {
          const parsed = JSON.parse(item.fields);
          const result = {};
          Object.entries(parsed).forEach((el) => {
            result[el[0]] = el[1] ?? '-';
          });
          return result;
        } catch (e) {
          console.log(e);
          return {};
        }
      };

      return {
        ...resource,
        ...profile(),
        ...fields()
      };
    });

    console.log(output);
    const csv = await Papa.unparse(output, Config.csv.export);
    this.downloadCSV(csv);
  };

  downloadCSV = (csvStr) => {
    const eventName = this.event?.name ?? this.eid;
    const demandName = this.demand?.name ?? this.did;
    const fileName = `${eventName.replace(/\s+/g, '')}_${demandName.replace(/\s+/g, '')}_${i18n.t('applications')}_${dayjs().format('YYYYMMDD')}.csv`;
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName;
    hiddenElement.click();
  };

  isValid = (value) => {
    return value !== null && value !== undefined;
  };
}

export default EventApplicationsPageViewModel;
