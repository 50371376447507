import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { t } from 'i18next';
import { Waypoint } from 'react-waypoint';
import { NOTIFICATION_TYPES } from 'src/constants';
import Icon from 'src/components/Icon';
import fakeAvatar from 'src/assets/fakeAvatar.png';
import iconMessage from 'src/assets/iconMessage.svg';
import { AppStateContext } from 'src/stores';
import Spin from 'antd/es/spin';

import styles from './styles.module.scss';

@observer
class NotificationList extends React.Component {
  ref = React.createRef();

  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.props = props;
  }

  static contextType = AppStateContext;

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderIcon(item) {
    switch (item.type) {
      case NOTIFICATION_TYPES.Event:
        return (
          <div
            className={styles.avatar}
            style={{ backgroundImage: `url(${item.payload.event.user.avatar ?? fakeAvatar})` }}
          />
        );
      case NOTIFICATION_TYPES.System:
      default:
        return (
          <Icon
            size={16}
            svg
            className={styles.icon}
          >
            {iconMessage}
          </Icon>
        );
    }
  }

  renderEmpty() {
    return (
      <div className={clsx(styles.emptyMessage, this.vm.isFetching && styles.spin)}>
        {this.vm.isFetching ? <Spin /> : t('header_notification_is_empty')}
      </div>
    );
  }

  renderList() {
    return (
      <div className={styles.list} ref={this.ref}>
        {this.vm.list?.map((item) => (
          <div
            key={item.id}
            className={styles.item}
          >
            <div className={styles.picContainer}>
              {this.renderIcon(item)}
            </div>
            <div className={styles.textContent}>
              <div className={clsx(styles.title, styles.bold)}>
                {item.content}
              </div>

              {!!item.payload.target
                && (
                  <div
                    className={styles.eventLearnMore}
                    onClick={() => this.vm.toDetailPage(item, this.context)}
                  >
                    {t('header_notification_learn_more')}
                  </div>
                )}

              <div className={styles.content}>
                {this.vm.getTimeStr(item.createdAt)}
              </div>
            </div>
          </div>
        ))}

        {
          this.vm.anchor
            ? (
              <>
                <Waypoint
                  scrollableAncestor={this.ref.current}
                  onEnter={this.vm.onScrollEnd}
                />
                {this.vm.isFetching && <Spin />}
              </>
            )
            : null
        }
      </div>
    );
  }

  render() {
    return (
      <div className={styles.wrapper} id="notificationList">
        {this.vm.list?.length !== 0 ? this.renderList() : this.renderEmpty()}
      </div>
    );
  }
}

NotificationList.propTypes = {
  vm: PropTypes.object
};

NotificationList.defaultProps = {
  vm: {}
};

export default NotificationList;
