import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Drawer from 'antd/es/drawer';

import './styles.scss';

function CustomDrawer(props) {
  const { children } = props;
  return (
    <Drawer
      placement="bottom"
      closable={false}
      {...props}
      className={props.className ? clsx(props.className, 'customDrawer') : 'customDrawer'}
    >
      {children}
    </Drawer>
  );
}

CustomDrawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

CustomDrawer.defaultProps = {
  children: null,
  className: null
};

export default CustomDrawer;
