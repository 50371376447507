import { makeObservable, observable, action, runInAction, computed, toJS } from 'mobx';
import { RESOURCE_TYPES } from 'src/constants';
import Service from 'src/models/response/Demand/Service';
import Space from 'src/models/response/Demand/Space';
import Funds from 'src/models/response/Demand/Funds';

class Demand {
  @observable id;
  @observable type;
  @observable name;
  @observable description;
  @observable meta;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  // //////////////////////

  @action deserialize = (params) => {
    const {
      id,
      type,
      name,
      description,
      meta
    } = params ?? {};

    this.id = id;
    this.type = type;
    this.name = name;
    this.description = description;
    meta.name = name;
    meta.description = description;
    this.meta = (() => {
      switch (type) {
        case RESOURCE_TYPES.Service:
          return Service.fromRes(meta);
        case RESOURCE_TYPES.Space:
          return Space.fromRes(meta);
        case RESOURCE_TYPES.Funds:
          return Funds.fromRes(meta);
        default:
          return {};
      }
    })();
  };

  static fromRes(data) {
    return new Demand(data);
  }
}

export default Demand;
