import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { PURCHASE_SOURCE } from 'src/constants';
import Icon from 'src/components/Icon';
import iconDoubleCheckPurple from 'src/assets/iconDoubleCheckPurple.svg';
import iconCopy from 'src/assets/iconCopy.svg';

import styles from './styles.module.scss';

function PurchaseCard(props) {
  const {
    item
  } = props;

  const cardTitle = () => {
    switch (item.source) {
      case PURCHASE_SOURCE.Trade:
        return (
          <div>
            <div className={styles.title}>
              {`${t('buy')} ${item.trade.product.name}`}
            </div>
            <div className={styles.tradeRemark}>
              {item.remark}
            </div>
          </div>
        );
      case PURCHASE_SOURCE.Manual:
        return (
          <div className={styles.title}>
            {`${t('purchase_source_manual_title')} - ${item.remark}`}
          </div>
        );
      case PURCHASE_SOURCE.Consume:
        return (
          <div className={styles.title}>
            {`${t('purchase_source_consume_title')} - ${item.remark}`}
          </div>
        );
      case PURCHASE_SOURCE.Restore:
        return (
          <div className={styles.title}>
            {`${t('purchase_source_restore_title')} - ${item.remark}`}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={styles.cardContainer}
    >
      <div className={styles.infoRow}>
        {cardTitle()}
      </div>
      <div className={clsx(styles.row, styles.alignRight)}>
        <Icon
          size={20}
          svg
          className={styles.icon}
        >
          {iconDoubleCheckPurple}
        </Icon>
        <div className={clsx(styles.days)}>
          <span>
            {t('quota_activeEvents')}
          </span>
          <span>
            {item.count}
          </span>
          <span>
            {t('quota_unit')}
          </span>
        </div>
      </div>

      <div className={styles.infoContainer}>
        <div className={styles.infoRow}>
          <span className={styles.infoText}>
            {t('order_created_at')}
          </span>
          <span className={styles.infoText}>
            {dayjs(item.createdAt).format('YYYY/MM/DD HH:mm')}
          </span>
        </div>

        {(item.source === 'trade' && item?.trade?.id) && (
          <div className={styles.infoRow}>
            <span className={styles.infoText}>
              {t('order_id')}
            </span>
            <div className={styles.row}>
              <span className={clsx(styles.infoText, styles.orderId)}>
                {item?.trade?.id}
              </span>
              <Icon
                size={20}
                svg
                onClick={() => { navigator.clipboard.writeText(item?.trade?.id ?? ''); }}
                className={styles.iconCopy}
              >
                {iconCopy}
              </Icon>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

PurchaseCard.propTypes = {
  item: PropTypes.object
};

PurchaseCard.defaultProps = {
  item: {}
};

export default PurchaseCard;
