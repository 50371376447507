import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import cn from 'classnames';
import i18n from 'src/i18n';

import styles from './styles.module.scss';

// 讓 ant input 有 error message, error border.
function withErrorInput(OutsideForm) {
  // 外層拿到的 tag, 所以會有 props.
  @observer
  class WithErrorInputHOC extends React.Component {
    // 是否顯示警告.
    @computed
    get showError() {
      const hasValue = !!this.props.value;
      const isString = typeof this.props.value === 'string';
      const isArray = this.props.value instanceof Array;

      // // value 可能是 null, undefined.
      if (this.props.showError && !hasValue) {
        return true;
      }

      // // // 沒有輸入內容, input, select 單選.
      if (this.props.showError && isString && !this.props.value?.trim()) {
        return true;
      }
      // // // 沒有選擇選項, array, ant select 多選.
      if (this.props.showError && isArray && !this.props.value.length) {
        return true;
      }

      // // // 外部自訂的驗證格式.
      if (this.props.showError && this.props.validValue === false) {
        return true;
      }

      return false;
    }

    // // ant input 紅色邊框.
    @computed
    get errorStatus() {
      return this.showError ? 'error' : undefined;
    }

    // 警告.
    renderError = () => {
      return <div className={styles.error}>{this.props.errorMessage}</div>;
    };

    render() {
      // 刪除 ant Input 不用的 props.
      const { validValue, showError, errorMessage, className, ...ps }
        = this.props;

      return (
        <div className={cn([styles.container, className])}>
          {/* ant 的 input, select... */}
          <OutsideForm
            className={cn([styles.fullWidth])}
            error={this.showError}
            status={this.errorStatus}
            {...ps}
          />

          {/* 錯誤提示. */}
          {this.showError && this.renderError()}
        </div>
      );
    }
  }

  WithErrorInputHOC.propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.string,
      PropTypes.array,
      PropTypes.oneOf([null, undefined])
    ]),
    validValue: PropTypes.bool,
    showError: PropTypes.bool,
    errorMessage: PropTypes.string,
    className: PropTypes.string
  };

  WithErrorInputHOC.defaultProps = {
    value: undefined,
    validValue: undefined,
    showError: false,
    errorMessage: i18n.t('this_is_required'),
    className: ''
  };

  return WithErrorInputHOC;
}

export default withErrorInput;

