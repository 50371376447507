import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { EVENT_STATUS, RESOURCE_TYPES } from 'src/constants';
import ConstantsStore from 'src/stores/constants';

export const RECRUIT_STATUS_STRING = {
  Ongoing: t('recruit_status_ongoing'),
  Pause: t('recruit_status_pause'),
  Incoming: t('recruit_status_incoming'),
  End: t('recruit_status_end'),
  Inactive: t('recruit_status_inactive'),
  Draft: t('recruit_status_draft')
};


class EventCardViewModel {
  @observable item = null;
  @observable enableRecruit = false;
  @observable serviceTypes = [];

  constructor(props) {
    makeObservable(this);
    this.props = props;
    runInAction(() => {
      this.item = props.item;
      this.enableRecruit = props.enableRecruit;
      this.serviceTypes = props.item?.serviceTypes;
    });
  }

  @action didMount = (props) => {

  };

  @computed
  get hasSpaceDemand() {
    return this.item?.demandTypes.includes(RESOURCE_TYPES.Space);
  }

  @computed
  get hasFundsDemand() {
    return this.item?.demandTypes.includes(RESOURCE_TYPES.Funds);
  }

  @computed
  get hasServiceDemand() {
    return this.item?.demandTypes.includes(RESOURCE_TYPES.Service);
  }

  @computed
  get daysFromNow() {
    const diff = dayjs(this.item?.endAt) - dayjs();
    const cd = 24 * 60 * 60 * 1000;
    const days = Math.floor(diff / cd).toString();
    return (days.length === 1 && days !== '0') ? `0${days}` : days;
  }

  @computed
  get serviceTypesTags() {
    return this.item?.serviceTypesTags ?? [];
  }

  @computed
  get recruitStatus() {
    if (!this.item) return '';

    return this.getRecruitStatusString(this.item);
  }

  getRecruitStatusString = (item) => {
    switch (item.status) {
      case EVENT_STATUS.Active:
        if (item.enableRecruit && dayjs(item.startAt).isAfter(dayjs())) { // 即將招募
          return RECRUIT_STATUS_STRING.Incoming;
        }

        if (item.enableRecruit && dayjs(item.endAt).isSameOrAfter(dayjs())) { // 招募中
          return RECRUIT_STATUS_STRING.Ongoing;
        }

        if (!item.enableRecruit && dayjs(item.endAt).isSameOrAfter(dayjs())) { // 暫停招募
          return RECRUIT_STATUS_STRING.Pause;
        }

        if (dayjs(item.endAt).isBefore(dayjs())) { // 結束招募
          return RECRUIT_STATUS_STRING.End;
        }
        break;

      case EVENT_STATUS.Inactive:
        return RECRUIT_STATUS_STRING.Inactive;

      case EVENT_STATUS.Draft:
        return RECRUIT_STATUS_STRING.Draft;

      default:
    }

    return RECRUIT_STATUS_STRING.Inactive;
  };

  @computed
  get citiesStr() {
    return ConstantsStore.getCombinedRegionsLabelString(this.item.regions);
  }
}

export default EventCardViewModel;
