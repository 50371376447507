import {
  makeObservable, computed, observable, action, runInAction, reaction
} from 'mobx';
import diff from 'deep-diff';
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { t } from 'i18next';
import UserService from 'src/services/user';
import GoogleService from 'src/services/google';
import { ORG_REVIEW_TYPES, USER_TYPES, VERIFY_STATUS } from 'src/constants';
import IS from 'src/utils/is';
import ConstantsStore from 'src/stores/constants';
import TreeSelectViewModel from 'src/components/TreeSelect/vm';
import UploadPhotoVM from 'src/components/UploadPhoto/vm';
import ErrorService from 'src/services/errors';
import GeneralModalViewModel from 'src/components/Modals/GeneralModal/vm';

dayjs.extend(isSameOrBefore);

const MAX_IMAGE_MB = 5;

export const TABS = {
  Basics: 'basics',
  Social: 'social',
  Private: 'private',
  Belong: 'belong'
};

class ProfileEditPageViewModel {
  @observable currentTab = TABS.Basics;
  @observable profile = null;

  @observable id = null;
  @observable identity = null;
  @observable status = null;
  @observable avatar = null; // url for ui

  @observable avatarData = null;

  @observable passwordCurrent = null;
  @observable passwordNew = null;
  @observable passwordConfirm = null;
  @observable passwordConfirmError = null;

  // info
  @observable info;

  // org
  @observable certs = [];
  @observable personInCharge = {};

  @observable showError = false;
  @observable showPasswordError = false;

  regionsSelectViewModel;
  sdgsSelectViewModel;
  jobsSelectViewModel;
  @observable uploadCertsVM;

  @observable isAwait = false;
  @observable shouldSave = false;

  @observable isImageLoading = false; // for upload image

  // modals
  @observable isResetPasswordModalOpen = false;
  generalModalViewModel = new GeneralModalViewModel();

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props) => {
    console.log('ProfileEditPage.didMount, params', props.router.params);
    console.log(props, this.props);

    await this.getDetail();
  };

  @action didUpdate = (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;
    const differences = diff(prev.profile, cur.profile);

    if (differences && !differences[0]?.lhs) {
      this.getDetail();
    }
  };

  @action getDetail = async () => {
    try {
      const res = await UserService.getDetail();
      runInAction(() => {
        this.deserialize(res);
        this.initTreeSelects();
        this.uploadCertsVM = new UploadPhotoVM({ maxCount: 3, items: this.info?.certs });
      });
    } catch (error) {
      console.log(error);
      ErrorService.onDefaultError(error);
    }
  };

  initTreeSelects = () => {
    this.regionsSelectViewModel = new TreeSelectViewModel(ConstantsStore.regionsOptions);
    this.regionsSelectViewModel.initCheckState(this.info?.cities ?? []);
    this.regionsSelectDisposer = reaction(
      () => this.regionsSelectViewModel.selectedItems,
      () => {
        runInAction(() => {
          this.shouldSave = true;
        });
      }
    );

    this.sdgsSelectViewModel = new TreeSelectViewModel(ConstantsStore.sdgsOptions);
    this.sdgsSelectViewModel.initCheckState(this.info?.sdgs ?? []);
    this.sdgsSelectDisposer = reaction(
      () => this.sdgsSelectViewModel.selectedItems,
      () => {
        runInAction(() => {
          this.shouldSave = true;
        });
      }
    );

    this.jobsSelectViewModel = new TreeSelectViewModel(ConstantsStore.jobsOptionsForUser);
    this.jobsSelectViewModel.initCheckState(this.info?.jobs ?? []);
    this.jobsSelectDisposer = reaction(
      () => this.jobsSelectViewModel.selectedItems,
      () => {
        runInAction(() => {
          this.shouldSave = true;
        });
      }
    );
  };

  disposeReactions = () => {
    if (this.regionsSelectDisposer) {
      this.regionsSelectDisposer();
    }
    if (this.sdgsSelectDisposer) {
      this.sdgsSelectDisposer();
    }
    if (this.jobsSelectDisposer) {
      this.jobsSelectDisposer();
    }
  };

  @computed
  get isSaveDisable() {
    return this.checkIfBasicError() || this.isPrivateError || this.isSocialsError;
  }

  checkIfBasicError() {
    const isInterestsUnfinished = this.sdgsSelectViewModel.isEmpty || this.regionsSelectViewModel.isEmpty;
    if (isInterestsUnfinished) return true;

    if (this.identity === USER_TYPES.Personal) {
      return !this.info?.isMottoValid || !this.profile?.displayName || this.jobsSelectViewModel.isEmpty;
    }
    return !this.profile?.displayName
    || !this.personInCharge.name
    || !this.info?.job
    || !this.info?.type
    || (this.info?.needStaff && !this.info?.staff)
    || (this.info?.needCapitalAmount && (this.info?.capitalAmount === null || this.info?.capitalAmount === undefined))
    || !this.info?.location
    || !this.info?.foundedIn;
  }

  @computed
  get isPrivateError() {
    if (this.identity === USER_TYPES.Personal) {
      return !this.info?.company
      || !this.profile?.phone9Digit
      || !this.profile?.isCountryCodeValid
      || !this.profile?.isPhoneValid
      || !this.info?.jobTitle;
    }
    return !this.profile?.name
    || !this.info?.address
    || !this.info?.taxId
    || !this.info?.isTaxIdValid
    || this.uploadCertsVM.postList?.length === 0
    || !this.info?.contact?.isComplete;
  }

  @computed
  get isSocialsError() {
    return (this.identity === USER_TYPES.Organization && (!this.info?.isIntroductionValid || !this.info?.isMottoValid))
    || Object.values(this.info?.socialsObj?.isValid).some((v) => !v);
  }

  @action onChange = (field, value, limit = null) => {
    const PROFILE_KEYS = ['name', 'gender', 'displayName', 'phone', 'phone9Digit', 'countryCode'];

    if (field.includes('password')) {
      this[field] = value;
      return;
    }

    if (PROFILE_KEYS.includes(field)) {
      this.profile.onChange(field, value, limit);
    } else {
      this.info.onChange(field, value);
    }

    this.shouldSave = true;
  };

  @action onContactChange = (field, value) => {
    this.info?.onContactChange(field, value);
    this.shouldSave = true;
  };

  @action onPersonalInChargeChange = (field, value) => {
    this.personInCharge[`${field}`] = value;
    this.shouldSave = true;
  };

  @action onSocialChange = (key, value) => {
    this.info?.onSocialChange(key, value);
    this.shouldSave = true;
  };

  @action switchTab = (value) => {
    this.currentTab = value;
  };

  @action onSave = async (showSuccessModal = true) => {
    this.isAwait = true;

    await this.uploadCertsVM.onPhotosUpload(this.id);
    const data = this.serialize();

    try {
      const res = await UserService.updateProfile(data);

      setTimeout(() => {
        runInAction(() => {
          this.isAwait = false;
          if (showSuccessModal) {
            this.generalModalViewModel.open({
              title: t('save_success')
            });
          }
          this.shouldSave = false;
        });
      }, 500);

    } catch (error) {
      console.log(error);

      setTimeout(() => {
        runInAction(() => {
          this.isAwait = false;
        });
      }, 500);

      switch (error.response?.status) {
        case 423:
          this.generalModalViewModel.open({
            title: t('profile_org_auth_pending_cannot_edit')
          });
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }

  };

  @action checkRequireAndSave = async (showSuccessModal = true) => {
    this.showError = true;
    if (this.isSaveDisable) {
      this.generalModalViewModel.open({
        title: t('profile_fill_required_fields_before_save')
      });
    } else {
      await this.onSave(showSuccessModal);
    }
  };

  compressImage = async (file) => {
    return new Promise((resolve) => {
      const compressor = new Compressor(file, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          console.log('compress success', compressedResult);
          resolve(compressedResult);
        },
        error: (err) => {
          console.log('compress error', err);
          resolve();
        }
      });
    });
  };

  @action onUploadAvatar = async (event) => {
    console.log('on upload');
    const file = event.file;
    // 自用的索引.
    const uid = String(new Date().valueOf());
    // 將檔案下載為圖片, 顯示到 ui.
    const targetFile = new FileReader();
    const imageValid = await this.onCheckAndDownloadAvatar(targetFile, file);

    if (imageValid) {

      const result = await this.compressImage(file);
      const theFile = result || file;

      const newData = {
        name: null,
        uid,
        file: theFile,
        size: theFile.size,
        mimetype: theFile.type,
        url: null // for uploading to Google
      };

      runInAction(() => {
        this.avatarData = newData;
      });


      await this.getUploadUrl();
      await this.putGoogleStorageAPI();
      this.shouldSave = true;
    } else {
      ErrorService.onCustomError(t('profile_image_not_valid_modal_title'));
    }
  };


  @action onCheckAndDownloadAvatar = async (targetFile, file) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-param-reassign
      targetFile.onload = () => {
        const img = new Image();
        img.onload = async () => {
          const width = img.width;
          const height = img.height;
          this.isImageLoading = false;
          const fileSizeValid = file.size < MAX_IMAGE_MB * 1024 * 1024;
          if (width < 200 || height < 200 || !fileSizeValid) {
            resolve(false);
          } else {
            this.avatar = targetFile.result; // for ui preview
            resolve(true);
          }
        };
        img.src = targetFile.result;
      };
      targetFile.readAsDataURL(file);
    });
  };

  @action getUploadUrl = async () => {
    this.isAwait = true;

    const fileToUpload = {
      size: this.avatarData.size,
      mimetype: this.avatarData.mimetype
    };

    const res = await UserService.genFilesPresignedUrls(this.id, [fileToUpload]);
    runInAction(() => {
      this.avatarData.name = res.data.urls[0].id;
      this.avatarData.url = res.data.urls[0].url;
    });

    setTimeout(() => {
      runInAction(() => {
        this.isAwait = false;
      });
    }, 1000);

  };

  // 將照片上傳至 google storage.
  @action putGoogleStorageAPI = async () => {
    try {
      const res = await GoogleService.putGoogleStorage({
        id: this.avatarData.name,
        uid: this.avatarData.uid,
        url: this.avatarData.url,
        mimetype: this.avatarData.mimetype,
        size: this.avatarData.size,
        file: this.avatarData.file,
        name: this.avatarData.name
      });

    } catch (error) {
      console.log('putGoogleStorage', error);
    } finally {
      setTimeout(() => {
        runInAction(() => {
          this.isAwait = false;
        });
      }, 500);
    }
  };

  @action toggleResetPasswordModal = () => {
    this.isResetPasswordModalOpen = !this.isResetPasswordModalOpen;
  };

  @action handleResetPassword = async () => {
    this.showPasswordError = true;
    if (this.passwordCurrent?.length < 8 || !IS.password(this.passwordNew) || !this.isPasswordConsistent) { return; }

    try {
      const res = await UserService.changePassword(this.passwordCurrent, this.passwordNew);
      this.toggleResetPasswordModal();
      this.generalModalViewModel.open({
        title: t('change_password_success')
      });
    } catch (error) {
      this.toggleResetPasswordModal();
      switch (error.response?.status) {
        case 403:
          ErrorService.onCustomError(t('login_password_error'));
          break;
        case 400:
        case 401:
          ErrorService.onCustomError(t('cant_change_password'));
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    } finally {
      this.showPasswordError = false;
    }
  };

  @action requestOrgAuth = async () => {
    await this.checkRequireAndSave(false);
    if (this.isSaveDisable) return;

    if (this.profile?.emailStatus !== VERIFY_STATUS.Verified) {
      ErrorService.onCustomErrorAdvanced({
        title: t('org_send_auth_412'),
        content: null,
        buttonText: t('memberOnlyModal_need_auth_email_button'),
        onClose: () => {
          this.props.router?.navigate('/register');
        },
        buttonProps: {
          withRightArrow: true
        }
      });
      return;
    }

    try {
      await UserService.submit();

      runInAction(() => {
        this.status = VERIFY_STATUS.Pending;
        this.generalModalViewModel.open({
          title: t('profile_org_auth_send_success')
        });
      });

    } catch (error) {
      switch (error.response?.status) {
        case 412:
          ErrorService.onCustomError(t('org_send_auth_412'));
          break;
        case 409:
          ErrorService.onCustomError(t('org_send_auth_409'));
          break;
        default:
          ErrorService.onDefaultError(error);
      }
    }
  };

  onBackPress = () => {
    const { navigate } = this.props.router;
    if (this.shouldSave) {
      this.generalModalViewModel.open({
        title: t('modal_title_confirm_leaving_before_save'),
        onConfirm: () => navigate(-1),
        showCancelButton: true
      });
    } else {
      navigate(-1);
    }
  };

  onClickRoute = (e) => {
    e.stopPropagation();
    if (this.shouldSave) {
      this.generalModalViewModel.open({
        title: t('modal_title_confirm_leaving_before_save'),
        onConfirm: this.toProfilePage,
        showCancelButton: true
      });
    } else {
      this.toProfilePage();
    }
  };

  // 欄位驗證 /////////////////////////////

  @computed
  get isPasswordConsistent() {
    return this.passwordNew === this.passwordConfirm;
  }

  @computed
  get isAllowedToEditAll() {
    return !this.status || this.status === ORG_REVIEW_TYPES.Failed;
  }

  // ///////////////////////////////////////////////////////////
  @action deserialize = (res) => {
    console.log(res);
    const {
      id,
      type,
      status,
      avatar,
      info
    } = res;

    this.profile = res;
    this.info = info;

    this.id = id;
    this.avatar = avatar;

    this.status = status;
    this.identity = type;

    this.sdgs = info.sdgs;
    this.cities = info.cities;
    this.socials = this.info.socialsArr;

    if (this.identity === USER_TYPES.Organization) {
      this.certs = info.certs;
      this.personInCharge = info.personInCharge;
    }
  };

  serialize = () => {
    const avatar = this.avatarData
      ? {
        avatar: {
          name: this.avatarData.name,
          size: this.avatarData.size
        }
      }
      : {};

    if (this.identity === USER_TYPES.Personal) {
      return {
        ...avatar,
        ...this.profile.serialize(),
        jobs: this.jobsSelectViewModel.selectedValue,
        sdgs: this.sdgsSelectViewModel.selectedValue,
        cities: this.regionsSelectViewModel.selectedValue
      };
    }

    const orgProfile = this.isAllowedToEditAll
      ? {
        ...avatar,
        ...this.profile.serialize(false),
        certs: this.uploadCertsVM.postList,
        sdgs: this.sdgsSelectViewModel.selectedValue,
        cities: this.regionsSelectViewModel.selectedValue
      } : {
        ...avatar,
        ...this.profile.serialize(true),
        sdgs: this.sdgsSelectViewModel.selectedValue,
        cities: this.regionsSelectViewModel.selectedValue
      };

    return orgProfile;
  };

  // ///////////////////////////////////////////////////////////
  toProfilePage = () => {
    const { navigate } = this.props.router;
    navigate(`/user/profile/${this.id ?? ''}`);
  };

}

export default ProfileEditPageViewModel;
