import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Input from 'antd/es/input';
import Pagination from 'antd/es/pagination';
import { Dropdown } from 'antd';
import IconButton from '@mui/material/IconButton';
import Spin from 'antd/es/spin';
import scrollDetector from 'src/stores/scrollDetector';
import i18n from 'src/i18n';
import { RESOURCE_TYPES } from 'src/constants';
import MainLayout from 'src/components/MainLayout';
import Icon from 'src/components/Icon';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Tabs from 'src/components/Tabs';
import ResourceCard from 'src/components/ResourceCard';
import ResourceFilter from 'src/components/ResourceFilter';
import iconSearch from 'src/assets/iconSearch.svg';
import iconFilter from 'src/assets/iconFilter.svg';
import iconFilterOn from 'src/assets/iconFilterOn.svg';
import iconSort from 'src/assets/iconSort.svg';
import serviceIcon from 'src/assets/resourceServiceFill.svg';
import fundsIcon from 'src/assets/resourceMoneyFill.svg';
import sapceIcon from 'src/assets/resourceSpaceFill.svg';

import ResourcesPageViewModel from './vm';
import styles from './styles.module.scss';

const resourceIcons = {
  service: serviceIcon,
  funds: fundsIcon,
  space: sapceIcon
};

@observer
class ResourcesPage extends React.Component {
  constructor(props) {
    super(props);

    this.vm = new ResourcesPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.vm.didUpdate(prevProps, this.props);
  }

  componentWillUnmount() {
    this.vm.willUnmount(this.props);
  }

  renderTopArea() {
    return (
      <>
        <div className={styles.topArea}>
          <div className={styles.topContainer}>
            <div className={styles.title}>
              <span>{i18n.t('resources_title1')}</span>
              <span className={styles.green}>{i18n.t('resources_title2')}</span>
              <span>{i18n.t('resources_title3')}</span>
            </div>
          </div>
        </div>
        <div className={clsx(styles.filterArea, !scrollDetector.isScrollingDown && styles.lower)}>
          <div className={styles.container}>
            <Input.Search
              placeholder={i18n.t('search')}
              className="searchInput"
              onChange={this.vm.onKeywordChange}
              onSearch={this.vm.filter.keyword?.length !== 1 ? this.vm.getList : null}
              value={this.vm.filter.keyword}
              size="large"
              prefix={(
                <Icon
                  size={24}
                  svg
                  style={{ marginRight: 5 }}
                >
                  {iconSearch}
                </Icon>
                )}
              suffix={this.vm.filter.keyword && (
              <IconButton
                aria-label="clear search input"
                onClick={() => {
                  this.vm.onKeywordChange({ target: { value: '' } });
                  this.vm.getList();
                }}
                sx={{
                  width: 12,
                  height: 12,
                  fontSize: 12
                }}
              >
                x
              </IconButton>
              )}
            />
            <div className={styles.filterIconContainer}>
              <Icon
                size={24}
                svg
                onClick={this.vm.toggleModal}
              >
                {this.vm.hasAppliedFilter ? iconFilterOn : iconFilter}
              </Icon>
              {this.vm.hasAppliedFilter && (
                <span className={styles.filterCount}>
                  {this.vm.filterCount}
                </span>
              )}
            </div>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'desc',
                    label: (
                      <div
                        onClick={() => this.vm.handleOrder('desc')}
                      >
                        {i18n.t('sort_release_desc')}
                      </div>
                    )
                  },
                  {
                    key: 'asc',
                    label: (
                      <div
                        onClick={() => this.vm.handleOrder('asc')}
                      >
                        {i18n.t('sort_release_asc')}
                      </div>
                    )
                  }
                ]
              }}
              overlayClassName="sortDropdown"
            >
              <Icon
                size={24}
                svg
                className={styles.icon}
              >
                {iconSort}
              </Icon>
            </Dropdown>
          </div>
        </div>
        <div className={styles.fakeFilterBottomBar} />
      </>
    );
  }

  renderList() {
    return (
      <div className={styles.list}>
        {this.vm.list.map((item) => (
          <ResourceCard
            className={styles.card}
            type={item.type}
            item={item}
            key={item.id}
            onClick={() => this.vm.toDetail(item.type, item.id)}
            // colorMode="light"
          />
        ))}
      </div>
    );
  }

  renderNoContentHint() {
    return (
      <div className={styles.emptyContainer}>
        {this.vm.isFetching
          ? <Spin />
          : (
            <div className={styles.emptyMessage}>
              <div className={styles.title}>
                {i18n.t('empty_message_title')}
              </div>
              <div className={styles.text}>
                {(this.vm.hasAppliedFilter || this.vm.filter?.keyword)
                  ? i18n.t('filter_no_result')
                  : i18n.t('resources_empty')}
              </div>
            </div>
          )}
      </div>
    );
  }


  render() {
    return (
      <div className={clsx(styles.page, 'page')}>
        <MainLayout
          {...this.props}
        >
          {this.renderTopArea()}
          <div className={styles.mainContainer}>
            <Tabs
              colorMode={2}
              items={Object.values(RESOURCE_TYPES).map((type) => {
                return {
                  value: type,
                  label: (
                    <div className={styles.flexRow}>
                      <div className={styles.tabTitle}>{i18n.t(`resources_${type}`)}</div>
                      <Icon
                        size={20}
                        svg
                      >
                        {resourceIcons[type]}
                      </Icon>
                    </div>
                  ),
                  content: this.vm.list?.length ? this.renderList() : this.renderNoContentHint()
                };
              })}
              onSwitchTab={this.vm.switchType}
              currentValue={this.vm.type}
              className={styles.resourceTabs}
              centered
            />
            <div className={styles.center}>
              <Pagination
                defaultCurrent={1}
                pageSize={this.vm.limit}
                current={this.vm.page}
                total={this.vm.count}
                onChange={this.vm.onPagingChange}
              />
            </div>
          </div>
          <ResourceFilter vm={this.vm} />

        </MainLayout>
      </div>
    );
  }
}

ResourcesPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default withProfile(withRouter(withTranslation()(ResourcesPage)), false);
