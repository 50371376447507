import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, makeObservable, action, computed, reaction } from 'mobx';
import { t } from 'i18next';
import clsx from 'clsx';
import TipsData from 'src/constants/gens/Tips.gen';
import Constants from 'src/stores/constants';
import Icon from 'src/components/Icon';
import iconArrow from 'src/assets/iconArrowUpPurple.svg';
import flower from 'src/assets/createEvent/flower.svg';
import { handleLineBreaksAndBoldTags } from 'src/utils';
import styles from './styles.module.scss';

const tipsData = TipsData[Constants.language];

@observer
class Tips extends React.Component {
  @observable isFolded = false;
  @observable contentRef = React.createRef();

  constructor(props) {
    super();
    makeObservable(this);
    this.props = props;
  }

  componentDidMount() {
    const { fieldName, setTipHeight } = this.props;
    setTimeout(() => {
      if (fieldName && this.height) {
        setTipHeight(fieldName, this.height);
      }

    }, 200);

    this.disposer = reaction(
      () => this.props.isHidden,
      () => {
        if (!this.props.isHidden) {
          // NOTE: tip 隱藏又顯示後要強制展開才拿得到展開後高度
          this.onExpand();
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  @computed get height() {
    return this.contentRef?.current?.offsetHeight;
  }

  @action onToggle = () => {
    const { fieldName, setTipHeight } = this.props;
    this.isFolded = !this.isFolded;
    if (this.isFolded) {
      setTipHeight(fieldName, null);
    } else {
      setTimeout(() => {
        if (fieldName && this.height) {
          setTipHeight(fieldName, this.height);
        }
      }, 200);
    }
  };

  @action onExpand = () => {
    const { fieldName, setTipHeight } = this.props;
    this.isFolded = false;
    setTimeout(() => {
      if (fieldName && this.height) {
        setTipHeight(fieldName, this.height);
      }
    }, 200);
  };

  renderBulletPoints = (obj) => {
    const values = Object.values(obj) ?? [];
    if (values.length === 1) {
      return (
        <div>{handleLineBreaksAndBoldTags(values[0])}</div>
      );
    }
    return Object.values(obj).map((str, i) => (
      <div key={str} className={styles.bulletPoint}>
        <div>{`${i + 1}.`}</div>
        <div>{handleLineBreaksAndBoldTags(str)}</div>
      </div>
    ));
  };

  renderExamples = (arr) => {
    return arr?.map((item) => (
      <div key={item.name} className={styles.example}>
        <span>{item.name}</span>
        {!!item.url && (
          <a
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            🔗
          </a>
        )}
      </div>
    ));
  };

  renderContent = () => {
    const { fieldName } = this.props;
    const data = tipsData[fieldName];

    if (!data) {
      return null;
    }

    return (
      <>
        <div
          className={clsx(styles.contentContainer, this.isFolded && styles.folded)}
          ref={this.contentRef}
          style={(this.height && !this.isFolded) ? { height: this.height } : {}}
        >
          <div className={styles.titleWrapper}>
            <div>
              <div className={styles.title}>
                {data.title}
              </div>
              <div className={styles.text}>
                {handleLineBreaksAndBoldTags(data.description)}
              </div>
            </div>
            {this.isFolded && <div className={styles.flowerPlaceholder} />}
          </div>

          {Object.values(data.aboutContents)?.some((v) => !!v) && (
            <>
              <div className={styles.sectionTitle}>
                {t('tips_about_content')}
              </div>
              <div className={styles.text}>
                {this.renderBulletPoints(data.aboutContents)}
              </div>
            </>
          )}

          {Object.values(data.aboutTexts)?.some((v) => !!v) && (
            <>
              <div className={styles.sectionTitle}>
                {t('tips_about_text')}
              </div>
              <div className={styles.text}>
                {this.renderBulletPoints(data.aboutTexts)}
              </div>
            </>
          )}

          {Object.values(data.examples)?.some((v) => !!v.name) && (
            <>
              <div className={styles.sectionTitle}>
                {t('tips_example')}
              </div>
              <div className={styles.text}>
                {this.renderExamples(data.examples)}
              </div>
            </>
          )}

        </div>

        <div className={styles.bottomBar}>
          <Icon
            size={16}
            svg
            style={{
              marginTop: 5,
              marginRight: 4
            }}
            onClick={this.onToggle}
            className={clsx(styles.iconArrow, this.isFolded && styles.rotate)}
          >
            {iconArrow}
          </Icon>
        </div>
      </>
    );
  };

  render() {
    const { isHidden, fieldName, className } = this.props;

    return (
      <div
        className={clsx(styles.tipWrapper, isHidden && styles.isHidden, this.isFolded && styles.folded)}
      >
        <div
          key={fieldName}
          className={clsx(
            styles.tipContainer,
            isHidden && styles.isHidden,
            this.isFolded && styles.folded,
            className
          )}
        >
          {isHidden ? null : this.renderContent()}
        </div>

        {!isHidden && (
          <img
            alt="flower"
            src={flower}
            className={clsx(styles.outerflower, this.isFolded && styles.folded)}
          />
        )}
      </div>
    );
  }
}

Tips.propTypes = {
  isHidden: PropTypes.bool,
  fieldName: PropTypes.string,
  className: PropTypes.any,
  setTipHeight: PropTypes.func
};

Tips.defaultProps = {
  isHidden: false,
  fieldName: null,
  className: null,
  setTipHeight: () => {}
};

export default Tips;
