import { makeObservable, observable, action, runInAction } from 'mobx';
import { throttle } from 'throttle-debounce';

class ScrollDetector {
  @observable lastScrollTop = 0;
  @observable isScrollingDown = false;

  constructor(props) {
    this.props = props;
    makeObservable(this);
  }

  @action checkScrollDirection = () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    // console.log(st, this.lastScrollTop);
    if (st > this.lastScrollTop) {
      runInAction(() => {
        this.isScrollingDown = true;
      });
    } else if (st < this.lastScrollTop) {
      runInAction(() => {
        this.isScrollingDown = false;
      });
    }
    runInAction(() => {
      this.lastScrollTop = st;
    });
  };

  onListenScrollDirection = throttle(400, this.checkScrollDirection);
}

const scrollDetector = new ScrollDetector();
export default scrollDetector;


