import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import iconAdd from 'src/assets/iconAddPurple.svg';
import styles from './styles.module.scss';

function AddItemButton({ onClick, text }) {
  return (
    <Button
      type="text"
      onClick={onClick}
      size="small"
      className={styles.addItemButton}
    >
      <div className={clsx(styles.flexRowCenter, styles.borderlessBtnText)}>
        <Icon
          size={23}
          svg
        >
          {iconAdd}
        </Icon>
        <span>
          {text}
        </span>
      </div>
    </Button>
  );
}

AddItemButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string
};

AddItemButton.defaultProps = {
  onClick: null,
  text: null
};

export default AddItemButton;
