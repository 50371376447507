import errorModalViewModel from 'src/components/Modals/ErrorModal/vm';
import i18n from 'src/i18n';


export default class ErrorService {

  static onDefaultError(error, cb = () => {}) {
    errorModalViewModel.open({
      title: ErrorService.getErrorContent(error),
      onClose: cb
    });
  }

  static onCustomError(title, content, cb = () => {}) {
    errorModalViewModel.open({
      title,
      content,
      onClose: cb
    });
  }

  static onCustomErrorAdvanced(params) {
    errorModalViewModel.open(params);
  }

  static getErrorContent(error) {
    switch (error.response?.status) {
      case 400:
        return i18n.t('general_error_content_400');
      case 401:
        return i18n.t('general_error_content_401');
      case 403:
        return i18n.t('general_error_content_403');
      case 404:
        return i18n.t('general_error_content_404');
      case 409:
        return i18n.t('general_error_content_409');
      case 412:
        return i18n.t('general_error_content_412');
      case 429:
        return i18n.t('general_error_content_429');
      case 500:
        return i18n.t('general_error_content_500');
      default:
        return i18n.t('general_error_other');
    }
  }
}


