import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import diff from 'deep-diff';
import qs from 'qs';
import { t } from 'i18next';

import pretendTo from 'src/stores/pretendTo';
import UserService from 'src/services/user';
import { RESOURCE_TYPES, USER_TYPES, TABLE_LIMIT, TABLE_LIMIT_MOBILE, SOCIALS_TYPE, ORG_REVIEW_TYPES, LIST_MODEL_TYPE, EVENT_PRIVACY } from 'src/constants';
import MemberOnlyFeatureModalVM, { getBlockReason } from 'src/components/Modals/MemberOnlyFeatureModal/vm';
import ErrorService from 'src/services/errors';
import store from 'src/stores/constants';
import AnchoredList from 'src/services/anchoredList';

export const PANEL = {
  Event: 'event',
  Resource: 'resource'
};

export const LIST_TYPE = {
  CreatedEvents: 'createdEvents',
  JoinedEvents: 'joinedEvents',
  Service: 'service',
  Space: 'space',
  Funds: 'funds',
  Socials: 'socials',
  Belongs: 'belongs'
};

class ProfilePageViewModel {
  @observable isMobile = false;
  @observable isMe = false;
  @observable isPretend = false;

  @observable data = null;
  @observable id = null;
  @observable profile = null;
  @observable identity = null; // USER_TYPE
  @observable status = null;

  // modal
  @observable isMoreInfoModalOpen = false;
  @observable isAddEventModalOpen = false;
  @observable isAddResourceModalOpen = false;
  @observable isOrgAuthModalOpen = false;
  @observable isBelongModalOpen = false;

  @observable memberOnlyFeatureModalVM = null;

  @observable modalType = RESOURCE_TYPES.Service; // add resource type

  @observable AnchoredLists = {
    [LIST_TYPE.CreatedEvents]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Event,
      path: 'v1/user/{uid}/event/created'
    }),

    [LIST_TYPE.JoinedEvents]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.EventJoined,
      path: 'v1/user/{uid}/event/joined'
    }),

    [LIST_TYPE.Funds]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Resource,
      path: 'v1/user/{uid}/resource/funds/list',
      method: 'get'
    }),

    [LIST_TYPE.Service]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Resource,
      path: 'v1/user/{uid}/resource/service/list',
      method: 'get'
    }),

    [LIST_TYPE.Space]: new AnchoredList({
      modelType: LIST_MODEL_TYPE.Resource,
      path: 'v1/user/{uid}/resource/space/list',
      method: 'get'
    })
  };

  @observable anchoredMemberList;

  @observable actives = {
    panel: null,
    event: LIST_TYPE.CreatedEvents,
    resource: LIST_TYPE.Service,
    moreModal: LIST_TYPE.Socials
  };

  @computed get service() {
    return this.AnchoredLists[LIST_TYPE.Service].list;
  }

  @computed get space() {
    return this.AnchoredLists[LIST_TYPE.Space].list;
  }

  @computed get funds() {
    return this.AnchoredLists[LIST_TYPE.Funds].list;
  }

  @computed get created() {
    return this.AnchoredLists[LIST_TYPE.CreatedEvents].list;
  }

  @computed get joined() {
    return this.AnchoredLists[LIST_TYPE.JoinedEvents].list;
  }

  @computed get belongs() {
    switch (this.identity) {
      case USER_TYPES.Personal:
        return this.profile?.belongs ?? [];
      case USER_TYPES.Organization:
        return this.anchoredMemberList?.list ?? [];
      default:
        return [];
    }
  }

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @action didMount = async (props) => {
    this.isMobile = props.context.state.isMobile;

    await this.updateData(props);
  };

  @action didUpdate = (prevProps, props) => {
    const prev = prevProps.context.state;
    const cur = props.context.state;

    if (
      diff(prev.profile, cur.profile)
        || props.router.params.id !== prevProps.router.params.id
    ) {
      this.updateData(props, true);
    }
  };

  // ////////////////////

  @action updateData = async (props, redirect = false) => {
    this.id = props.router.params.id || props.context.state.profile?.id;
    this.isMe = !props.router.params.id
      || (props.context.state.profile?.id && props.router.params.id === props.context.state.profile.id);

    if (!this.id) {
      if (redirect) {
        this.toHomePage();
        props.router.navigate(0);
      }
      return;
    }

    if (!props.router.params.id && !pretendTo.id) {
      this.props.router.navigate(`/user/profile/${this.id}`, { replace: true });
    }

    this.setAnchoredListsPaths();

    await Promise.all([
      this.getDetail(),
      this.getResource(this.actives.resource),
      this.getCreatedEvents(),
      this.getJoinedEvents()
    ]);

    if (this.status === ORG_REVIEW_TYPES.Pending || this.status === ORG_REVIEW_TYPES.Failed || this.status === ORG_REVIEW_TYPES.Rejected) {
      this.isOrgAuthModalOpen = true;
    }

    if (this.identity === USER_TYPES.Organization) {
      runInAction(() => {
        this.anchoredMemberList = new AnchoredList({
          modelType: LIST_MODEL_TYPE.Belong,
          path: `v1/user/${this.id}/members`,
          method: 'get'
        });
      });

      await this.getMembers();
    }
  };

  @action setAnchoredListsPaths = () => {
    this.AnchoredLists[LIST_TYPE.CreatedEvents].setPath(`v1/user/${this.id}/event/created`);
    this.AnchoredLists[LIST_TYPE.JoinedEvents].setPath(`v1/user/${this.id}/event/joined`);
    this.AnchoredLists[LIST_TYPE.Funds].setPath(`v1/user/${this.id}/resource/funds/list`);
    this.AnchoredLists[LIST_TYPE.Service].setPath(`v1/user/${this.id}/resource/service/list`);
    this.AnchoredLists[LIST_TYPE.Space].setPath(`v1/user/${this.id}/resource/space/list`);
  };

  @action getDetail = async () => {
    try {
      const res = this.isMe
        ? await UserService.getDetail()
        : await UserService.getDetailById(this.id);

      this.unserializeData(res);

    } catch (error) {
      ErrorService.onDefaultError(error);
      console.log('error', error);
    }
  };

  @action getCreatedEvents = async () => {
    const params = {
      limit: this.isMobile ? TABLE_LIMIT_MOBILE : TABLE_LIMIT
    };

    await this.AnchoredLists[LIST_TYPE.CreatedEvents].getList({ privacy: EVENT_PRIVACY.Listed }, params);
  };

  @action getJoinedEvents = async () => {
    const params = {
      limit: this.isMobile ? TABLE_LIMIT_MOBILE : TABLE_LIMIT
    };

    await this.AnchoredLists[LIST_TYPE.JoinedEvents].getList({ isAccepted: true }, params);
  };

  @action getResource = async (type) => {
    const params = {
      limit: this.isMobile ? TABLE_LIMIT_MOBILE : TABLE_LIMIT
    };

    await this.AnchoredLists[type].getList(null, params);
  };

  onScrollEnd = async (type) => {
    const anchor = this.AnchoredLists[type].anchor;
    console.log(type, anchor);

    if (!anchor) { return; }

    switch (type) {
      case LIST_TYPE.CreatedEvents:
      case LIST_TYPE.JoinedEvents:
      case LIST_TYPE.Service:
      case LIST_TYPE.Space:
      case LIST_TYPE.Funds:
      default:
        await this.AnchoredLists[type].getNext();
    }
  };

  getMembers = async () => {
    const params = {
      limit: 10
    };

    await this.anchoredMemberList?.getList(null, params);
  };

  // ////////////////

  @computed get hasSocialLink() {
    return this.socials?.some((s) => (s.name !== SOCIALS_TYPE.Line && s.url) || (s.name === SOCIALS_TYPE.Line && s.url?.split('~')[1]));
  }

  @computed get resourcesTotalCount() {
    return this.AnchoredLists[LIST_TYPE.Service].count
      + this.AnchoredLists[LIST_TYPE.Space].count
      + this.AnchoredLists[LIST_TYPE.Funds].count;
  }

  @computed get eventsTotalCount() {
    return this.AnchoredLists[LIST_TYPE.CreatedEvents].count
      + this.AnchoredLists[LIST_TYPE.JoinedEvents].count;
  }

  @computed get shouldShowMoreInfoButton() {
    switch (this.identity) {
      case USER_TYPES.Personal:
        return this.isMe && this.hasSocialLink;
      case USER_TYPES.Organization:
        return this.hasSocialLink || this.totalMembers !== 0;
      default:
        return false;
    }
  }

  @computed get shouldShowBelongButton() {
    return this.identity === USER_TYPES.Personal && this.belongs?.length !== 0;
  }

  // ////////////////////

  @action onPanelClick = (e, type) => {
    e.stopPropagation();

    if (this.actives.panel === type) { // same
      switch (type) {
        case PANEL.Resource:
          this.actives.panel = PANEL.Event;
          break;

        case PANEL.Event:
        default:
          this.actives.panel = null;
      }
    } else {
      this.actives.panel = type;
    }
  };

  @action switchEventTab = (type) => {
    this.actives.event = type;
  };

  @action switchResourceTab = async (type) => {
    this.actives.resource = type;

    // get list if no data
    if (!this[type].length) {
      await this.getResource(type, true);
    }
  };

  @action addEvent = (e) => {
    e.stopPropagation();

    const blockReason = getBlockReason(this.profile);

    if (!blockReason) {
      this.toCreateEventOverview();
    } else if (this.memberOnlyFeatureModalVM) {
      this.memberOnlyFeatureModalVM.toggleModal();
    } else {
      this.memberOnlyFeatureModalVM = new MemberOnlyFeatureModalVM({ blockReason, router: this.props.router });
    }
  };

  @action addResource = (e) => {
    e.stopPropagation();

    const blockReason = getBlockReason(this.profile);

    if (!blockReason) {
      this.isAddResourceModalOpen = true;
    } else if (this.memberOnlyFeatureModalVM) {
      this.memberOnlyFeatureModalVM.toggleModal();
    } else {
      this.memberOnlyFeatureModalVM = new MemberOnlyFeatureModalVM({ blockReason, router: this.props.router });
    }
  };

  // modals ///////////////
  @action toggleMoreInfoModal = () => {
    this.isMoreInfoModalOpen = !this.isMoreInfoModalOpen;
  };

  @action toggleAddEventModal = () => {
    this.isAddEventModalOpen = !this.isAddEventModalOpen;
  };

  @action toggleAddResourceModal = () => {
    this.isAddResourceModalOpen = !this.isAddResourceModalOpen;
  };

  @action toggleOrgAuthModal = () => {
    this.isOrgAuthModalOpen = !this.isOrgAuthModalOpen;
  };

  @action toggleBelongModal = () => {
    this.isBelongModalOpen = !this.isBelongModalOpen;
  };

  @action switchMoreModalTab = (type) => {
    this.actives.moreModal = type;
  };

  // //////////////

  @computed
  get relatedRegions() {
    const arr = this.info?.cities ? [...this.info.cities] : [];
    if (arr.length === 0) {
      return [];
    }

    if (this.identity === USER_TYPES.Personal) {
      if (this.info?.cityOfBirth) arr.push(this.info?.cityOfBirth);
      if (this.info?.cityOfResidence) arr.push(this.info?.cityOfResidence);
    }

    if (this.identity === USER_TYPES.Organization) {
      if (this.info?.location) arr?.push(this.info?.location);
    }

    return arr?.filter((city, i) => arr.indexOf(city) === i);
  }

  get combinedRelatedRegionsString() {
    return store.getCombinedRegionsLabelString(this.relatedRegions);
  }

  @action onModalChange = (type) => {
    this.modalType = type;
  };

  clickEdit = () => {
    console.log(this.status);
    if (this.status === ORG_REVIEW_TYPES.Pending) {
      this.toggleOrgAuthModal();
    } else {
      const { navigate } = this.props.router;
      navigate('/user/profile/edit');
    }
  };

  clickDropdownEventHosted = (e) => {
    e.stopPropagation();
    if (this.profile.isEventCreator) {
      this.toEventHosted();
    } else {
      ErrorService.onCustomError(t('profile_modal_event_creator_only'), t('profile_modal_event_creator_only_content'));
    }
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  // /////////////////////////////

  toEventDetail = (id) => {
    const { navigate } = this.props.router;
    navigate(`/events/${id}`);
  };

  toResourceDetail = (type, id) => {
    const { navigate } = this.props.router;
    navigate(`/resources/${type}/${id}`);
  };

  toFormPage = () => {
    const { navigate } = this.props.router;
    navigate('/user/my-resource/form', { state: { type: this.modalType } });
  };

  toProfileEditPage = () => {
    const { navigate } = this.props.router;
    navigate('/user/profile/edit');
  };

  toEventHosted = () => {
    const { navigate } = this.props.router;
    navigate('/user/event-hosted');
  };

  toCreateEventOverview = () => {
    const { navigate } = this.props.router;
    navigate('/create-event-overview');
  };

  toHomePage = () => {
    const { navigate } = this.props.router;
    navigate('/');
  };

  toProfile = (id) => {
    const { navigate } = this.props.router;
    navigate(`/user/profile/${id}`);
  };

  // ///////////////
  @action unserializeData = (res) => {
    this.data = res;
    this.profile = res;
    this.identity = res.type;
    this.status = res.status;
  };

  @computed get totalMembers() {
    return this.profile?.totalMembers ?? 0;
  }

  @computed get info() {
    return this.profile?.info ?? {};
  }

  @computed get socials() {
    return this.info.socialsArr ?? [];
  }

}

export default ProfilePageViewModel;
