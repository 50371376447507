import { makeObservable, observable, action, runInAction, computed } from 'mobx';

class Faq {
  @observable question;
  @observable answer;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get isComplete() {
    return !!this.question && !!this.answer;
  }

  @computed get isHalfComplete() {
    return !this.isComplete && !this.isEmpty;
  }

  @computed get isEmpty() {
    return !this.question && !this.answer;
  }

  @action onChange = (value, key) => {
    this[key] = value;
  };

  // /////////////////////////////////////

  @action deserialize = (data) => {
    const {
      question,
      answer
    } = data ?? {};

    this.question = question;
    this.answer = answer;
  };

  serialize = () => {
    const data = {
      question: this.question,
      answer: this.answer
    };

    return data;
  };


  static fromRes(data) {
    return new Faq(data);
  }

}

export default Faq;

