import { makeObservable, observable, action, runInAction, computed } from 'mobx';

class Feature {
  @observable title;
  @observable description;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);
  }

  @computed get isComplete() {
    return !!this.title && !!this.description;
  }

  @computed get isHalfComplete() {
    return !this.isComplete && !this.isEmpty;
  }

  @computed get isEmpty() {
    return !this.title && !this.description;
  }

  @action onChange = (value, key) => {
    this[key] = value;
  };

  // /////////////////////////////////////

  @action deserialize = (data) => {
    const {
      title,
      description
    } = data ?? {};

    this.title = title;
    this.description = description;
  };

  serialize = () => {
    const data = {
      title: this.title,
      description: this.description
    };

    return data;
  };


  static fromRes(data) {
    return new Feature(data);
  }

}

export default Feature;

