import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import i18n from 'src/i18n';
import decImg from 'src/assets/oneGirlOneBoy.svg';
import illustrationCongrats from 'src/assets/illustrationCongrats.svg';
import illustrationGreenCheck from 'src/assets/illustrationGreenCheck.svg';
import { RESOURCE_MODE } from 'src/constants';

import styles from '../styles.module.scss';

@observer
class Step4 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { vm } = this.props;
    const isEditing = vm.mode === RESOURCE_MODE.Edit;

    return (
      <div className={styles.step}>
        <div className={styles.block}>
          <div className={styles.title}>
            {isEditing ? i18n.t('myresource_success_title_edit') : i18n.t('myresource_success_title')}
          </div>
          <div style={{ marginTop: 10 }}>
            <span className={styles.desc}>
              {isEditing ? i18n.t('myresource_success_subtitle_1_edit') : i18n.t('myresource_success_subtitle_1')}
            </span>
            <Link to={`/resources/service/${vm.result.id}`} className={styles.serviceName}>
              {` ${vm.data?.name} `}
            </Link>
            <span className={styles.desc}>{i18n.t('myresource_success_subtitle_2')}</span>
          </div>
          <div className={styles.imgContainer}>
            <img
              src={isEditing ? illustrationGreenCheck : illustrationCongrats}
              alt=""
              className={styles.mainImg}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Step4;

Step4.propTypes = {
  vm: PropTypes.object
};

Step4.defaultProps = {
  vm: {}
};
