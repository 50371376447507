/* eslint-disable import/no-cycle */
import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import Compressor from 'compressorjs';
import UserService from 'src/services/user';
import EventService from 'src/services/event';
import GoogleService from 'src/services/google';
import ErrorService from 'src/services/errors';
import { delay } from 'src/utils';
import { t } from 'i18next';

const MAX_IMAGE_MB = 5;
const MAX_PDF_MB = 10;

const isFileValid = (file) => {
  let valid = false;
  switch (file.type) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/bmp':
      if (file.size < MAX_IMAGE_MB * 1024 * 1024) {
        valid = true;
      }
      break;
    case 'application/pdf':
      if (file.size < MAX_PDF_MB * 1024 * 1024) {
        valid = true;
      }
      break;
    default:
  }
  return valid;
};

class UploadPhotoVM {
  @observable maxCount = 3;
  @observable items = [];
  @observable fileList = [];
  @observable googleUrls = [];
  @observable results = [];
  @observable type = 'user';

  @observable isLoading = false;

  constructor(props) {
    makeObservable(this);
    this.props = props;
    runInAction(() => {
      this.items = props.items ?? [];
      this.fileList = this.unserializeItems() ?? [];
      this.maxCount = props.maxCount ?? 3;
      this.type = props.type ?? 'user';
    });
  }

  @computed
  get service() {
    switch (this.type) {
      case 'event':
        return EventService;
      case 'user':
      default:
        return UserService;
    }
  }

  // 需要上傳的檔案.
  @computed
  get localFiles() {
    return this.fileList.filter((item) => item.origin === 'local');
  }

  // 最終結果
  @computed
  get postList() {
    if (this.fileList.length === 0) return [];

    return this.fileList.map((el) => {
      if (el.origin === 'server') {
        return {
          name: el.name,
          size: el.size
        };
      }
      const item = this.results?.find((o) => o?.data?.uid === el.uid);
      if (!item) return {};
      return {
        name: item.data.id,
        size: item.data.size
      };
    });
  }

  // 準備上傳
  @action onPhotosUpload = async (id) => {
    await delay(500);
    console.log('on upload files url, fileList', this.fileList);

    const res1 = await this.getUploadFilesUrl(id);
    const res2 = await this.putGoogleStorageAPI();
    return res1 && res2;
  };

  // ///////////////////////////////

  unserializeItems = () => {
    return this.items?.map((e) => {
      const suffix = e.name.split('.')[1];
      const mimetype = (suffix === 'jpeg' || suffix === 'png' || suffix === 'jpg') ? `image/${suffix}` : 'file';

      return {
        id: e.name,
        name: e.name,
        size: e.size,
        src: e.url, // for ui
        url: e.url,
        mimetype,
        origin: 'server'
      };
    });
  };

  serializeItems = (list) => {
    return list.map((item) => ({
      name: item.name,
      size: item.size,
      url: item.url
    }));
  };

  compressImage = async (file) => {
    return new Promise((resolve) => {
      const compressor = new Compressor(file, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          console.log('compress success', compressedResult);
          resolve(compressedResult);
        },
        error: (err) => {
          console.log('compress error', err);
          resolve();
        }
      });
    });
  };


  // 上傳照片, 給 ant ui 使用的事件函式.
  @action onUpload = async (event, context) => {
    const file = event.file;
    const valid = isFileValid(file);

    if (!valid) {
      ErrorService.onCustomError(t('profile_image_upload_guideline'));
      return false;
    }

    if (valid) {
      // 將檔案下載為圖片, 顯示到 ui.
      let compressedImg;
      if (/^image/.test(file.type)) {
        compressedImg = await this.compressImage(file);
        compressedImg.uid = file.uid;
      }
      const theFile = compressedImg || file;

      const targetFile = new FileReader();
      targetFile.addEventListener('load', (onloadEvent) => {
        this.onDownloadFile(onloadEvent, theFile.uid);
      });

      targetFile.readAsDataURL(file);
      const newData = {
        id: null,
        src: null,
        // 上傳專用.
        origin: 'local',
        // 自用的索引.
        uid: theFile.uid,
        // google 需要的檔案.
        file: theFile,
        size: theFile.size,
        mimetype: theFile.type,
        name: theFile.name
      };

      this.fileList.push(newData);

      console.log('this.fileList', this.fileList);

      this.fileList = this.fileList.slice(-this.maxCount);
    }
    return true;
  };

  // download for ui preview
  @action onDownloadFile = (event, uid) => {
    this.fileList = this.fileList.map((item) => {
      return item.uid === uid ? { ...item, src: event.target.result } : item;
    });
  };

  @action onRemoveFile = async (target) => {
    this.fileList = this.fileList.filter((item) => item.id !== target.id || item.uid !== target.uid);
    console.log('remove uploaded file', this.fileList, this.postList);
  };

  // 跟 server 要上傳至 google 的網址.
  @action getUploadFilesUrl = async (id) => {
    // console.log('.......get url');
    this.isLoading = true;

    const filesToUpload = this.localFiles.map((item) => {
      return {
        size: item.size,
        mimetype: item.mimetype
      };
    });

    console.log('.......get url', this.localFiles, filesToUpload);

    // 有新的照片, 才索取網址.
    if (filesToUpload.length) {
      const res = await Promise.all(
        filesToUpload.map(async (item, index) => {
          try {
            const obj = this.service.genFilesPresignedUrls(id, [item]);
            return obj;
          } catch (error) {
            ErrorService.onDefaultError(error);
            return null;
          }
        })
      );
      this.googleUrls = res.map((e) => e.data.urls[0]);
      console.log('urls', this.googleUrls);
      return true;
    }

    return false;
  };

  // 將照片上傳至 google storage.
  @action putGoogleStorageAPI = async () => {
    try {
      const res = await Promise.all(
        this.localFiles.map(async (item, index) => {
          const id = this.googleUrls[index].id;
          const url = this.googleUrls[index].url;
          const resGoogle = await GoogleService.putGoogleStorage({
            id,
            uid: item.uid,
            url,
            mimetype: item.mimetype,
            size: item.size,
            file: item.file,
            name: item.name
          });
          console.log('--uploaded', item.name);
          return resGoogle;
        })
      );

      runInAction(() => {
        this.results = res;
      });

      return true;

    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        console.log('putGoogleStorage', error);
      });

      return false;
    }
  };
}

export default UploadPhotoVM;
