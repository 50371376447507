import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { EVENT_FIELD } from 'src/constants';
import Button from 'src/components/Button';
import Checkbox from 'src/components/Checkbox';
import Icon from 'src/components/Icon';
import Tips from 'src/pages/CreateEvent/CreateEventPage/components/Tips';
import StepBase from 'src/pages/CreateEvent/CreateEventPage/components/Step/StepBase';
import iconAdd from 'src/assets/iconAddPurple.svg';
import Field from './components/Field';
import styles from '../styles.module.scss';

@observer
class Registration extends StepBase {
  constructor(props) {
    super();
    this.props = props;
    this.vm = props?.vm;
  }

  componentDidMount() {
    super.componentDidMount();
    this.vm?.registrationDidmount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.vm?.registrationUnmount();
  }

  renderRegistrationRequires = () => {
    const { vm } = this.props;
    if (!this.vm.isEventCreated) return null;

    return (
      <div className={styles.registrationRequiresCheckboxes}>
        {vm.registrationOptions?.map((opt) => (
          <div key={`${opt}_wrapper`}>
            <Checkbox
              key={opt}
              onChange={({ target }) => vm.onRegistrationRequiresChange(target.checked, opt)}
              checked={vm.event.registrationRequiresObj[opt]}
              size={30}
              className={clsx(styles.checkboxItem)}
            >
              <span className={styles.label}>
                {t(`reg_${opt}`)}
              </span>
            </Checkbox>
          </div>
        ))}
      </div>
    );
  };

  renderRegistrationFieldsSection = () => {
    const { event } = this.vm;
    const registrationFields = event[EVENT_FIELD.RegistrationFields];
    if (!this.vm.isEventCreated) return null;

    return (
      <section className={styles.stepSection}>
        <div className={styles.stepMainContent}>
          {registrationFields.map((item, i) => (
            <Field
              // eslint-disable-next-line react/no-array-index-key
              key={`registrationField_${i}`}
              item={item}
              onSave={() => this.vm.onSave(EVENT_FIELD.RegistrationFields)}
              onRemove={() => event.removeRegistrationField(i)}
              generalModalViewModel={this.vm.generalModalViewModel}
            />
          ))}

          <Button
            ghost
            onClick={() => event.addRegistrationField()}
            className={styles.addItemButton}
          >
            <div className={styles.flexRowCenter}>
              <Icon
                size={28}
                svg
              >
                {iconAdd}
              </Icon>
              {t('button_add_registration_field')}
            </div>
          </Button>
        </div>
        <Tips isHidden />
      </section>
    );
  };

  render() {
    const {
      vm
    } = this.props;

    return (
      <>
        <section className={styles.stepSection}>
          <div className={styles.stepMainContent}>
            {this.renderRegistrationRequires()}
          </div>
          <Tips isHidden />
        </section>

        {vm?.event && this.renderRegistrationFieldsSection()}
      </>
    );
  }
}

Registration.propTypes = {
  vm: PropTypes.object
};

Registration.defaultProps = {
  vm: {}
};

export default Registration;
