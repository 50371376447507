import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.scss';

// const colorMode = {
//   1: 'white',
//   2: 'black'
// }

function Tabs(props) {
  const { items, onSwitchTab, currentValue, tabClassName, centered, className, colorMode } = props;
  return (
    <>
      <div className={clsx(styles.flexRow, styles.wrapper, className, centered ? styles.centered : styles.alignStart, !centered && styles.marginLeft)}>
        {items?.map((item) => (
          <div
            className={clsx(styles.flexRow, styles.tabContainer)}
            key={item.value}
            onClick={(e) => {
              e.stopPropagation();
              onSwitchTab(item.value);
            }}
          >
            <h5
              className={clsx(styles.tab, currentValue === item.value && styles.tabActive, styles[`colorMode${colorMode}`], tabClassName)}
            >
              {item.label}
            </h5>
            {(!!item.badge || item.badge === 0) && (
              <div className={clsx(styles.badge, styles[`colorMode${colorMode}`])}>
                {item.badge}
              </div>
            )}
          </div>
        ))}
      </div>
      {items?.find((item) => item.value === currentValue)?.content}
    </>
  );
}

Tabs.propTypes = {
  items: PropTypes.array,
  onSwitchTab: PropTypes.func,
  currentValue: PropTypes.string,
  centered: PropTypes.bool,
  tabClassName: PropTypes.string,
  className: PropTypes.string,
  colorMode: PropTypes.number
};

Tabs.defaultProps = {
  items: [],
  onSwitchTab: () => {},
  currentValue: '',
  centered: false,
  tabClassName: '',
  className: '',
  colorMode: 1
};

export default Tabs;
