import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Button from 'src/components/Button';
import illustration from 'src/assets/illustrationGreenCheck.svg';

import styles from '../styles.module.scss';

@observer
class Success extends React.Component {
  constructor(props) {
    super(props);

    this.vm = this.props.vm;
  }

  render() {
    return (
      <>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('forgetPassword_success_title')}
          </h2>

          <h3 className={styles.subtitle}>
            {t('forgetPassword_success_subtitle')}
          </h3>
        </div>

        <img
          src={illustration}
          alt="personal"
          className={styles.decImg}
        />
        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.toLogin}
            className={styles.nextButton}
          >
            {t('forgetPassword_success_backToLoginButton')}
          </Button>
        </div>
      </>
    );
  }
}

Success.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  vm: PropTypes.object
};

Success.defaultProps = {
  vm: {}
};

export default (withTranslation()(Success));
