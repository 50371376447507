import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Button from 'src/components/Button';
import ForgetPasswordModal from 'src/components/Modals/ForgetPasswordModal';
import illus from 'src/assets/illustrationEmail.svg';

import styles from '../styles.module.scss';

@observer
class ValidateEmail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.vm = props.vm;
  }

  render() {
    return (
      <>
        <div className={styles.titleGroup}>
          <h2 className={styles.title}>
            {t('forgetPassword_validateEmail_title')}
          </h2>
          <h3 className={styles.subtitle}>
            <span>{t('forgetPassword_validateEmail_subtitle1')}</span>
            <span className={styles.purple}>{` ${this.vm.email} `}</span>
            <span>{t('forgetPassword_validateEmail_subtitle2')}</span>
          </h3>
        </div>

        <img
          src={illus}
          alt="personal"
          className={styles.decImg}
        />

        <div className={styles.bottomContainer}>
          <Button
            onClick={this.vm.resendEmail}
            className={styles.nextButton}
            disabled={!this.vm.email || this.vm.timerCountdown !== null}
          >
            {t('forgetPassword_validateEmail_resendButton')}
            {this.vm.timerCountdown !== null && (
              <span className={styles.timer}>
                {`(${this.vm.timerCountdown})`}
              </span>
            )}
          </Button>

          <div className={styles.bottomText}>
            <h5 style={{ display: 'inline' }}>
              {`${t('forgetPassword_validateEmail_stillNotReceive')} `}
            </h5>
            <div
              style={{ display: 'inline', cursor: 'pointer' }}
              onClick={this.vm.clickModifyEmail}
              className={styles.linkText}
            >
              {t('forgetPassword_validateEmail_modifyEmail')}
            </div>
          </div>
        </div>

        {this.vm.forgetPasswordModalVM
          && (
          <ForgetPasswordModal
            vm={this.vm.forgetPasswordModalVM}
            isMobile={this.props.context.state.isMobile}
          />
          )}
      </>
    );
  }
}

ValidateEmail.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  vm: PropTypes.object
};

ValidateEmail.defaultProps = {
  vm: {}
};

export default (withTranslation()(ValidateEmail));
